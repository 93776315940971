import { LocationType } from 'operations/models/Location';
import {
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_PORT_ICD,
  LOCATION_TYPE_CFS_YARD,
} from 'operations/baseConstants';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { RoutingLegRoutingType } from 'operations/models/RoutingLeg';

import { RoutingNodeTag } from 'operations/models/RoutingNode';
import { getNewRoutingNode } from './RoutingDetails';

export const getInitialNodes = (
  routing_type?: RoutingLegRoutingType
): { origin: RoutingNodeValue; destination: RoutingNodeValue } => {
  let origin_node_tags: Array<RoutingNodeTag> = [],
    destination_node_tags: Array<RoutingNodeTag> = [];
  if (routing_type === 'main_carriage') {
    origin_node_tags = ['port_of_loading', 'place_of_carrier_receipt'];
    destination_node_tags = ['port_of_discharge', 'place_of_carrier_delivery'];
  } else if (routing_type === 'pre_carriage' || routing_type === 'pickup') {
    origin_node_tags = ['place_of_carrier_receipt'];
    destination_node_tags = ['port_of_loading'];
  } else if (routing_type === 'on_carriage' || routing_type === 'delivery') {
    origin_node_tags = ['port_of_discharge'];
    destination_node_tags = ['place_of_carrier_delivery'];
  } else {
    origin_node_tags = [];
    destination_node_tags = [];
  }
  const origin = getNewRoutingNode({ tags: origin_node_tags } as RoutingNodeValue);
  const destination = getNewRoutingNode({ tags: destination_node_tags } as RoutingNodeValue);
  return { origin, destination };
};

export const locationToLocationTypeMap: { [key: string]: LocationType[] } = {
  [LOCATION_TYPE_PORT_ICD]: ['Seaport', 'Airport', 'ICD', 'PortTerminal'],
  [LOCATION_TYPE_CITY]: ['City'],
  [LOCATION_TYPE_CFS_YARD]: ['CFS'],
};

export const getSeparatedLocationSearchType = (
  locationType: string,
  locationSearchType: string[] = []
): LocationType[] => {
  const validTypes = locationToLocationTypeMap[locationType] || [];
  const c = validTypes.filter((type) => locationSearchType.includes(type)) as LocationType[];
  return c;
};

export declare type RoutingLegCallback = (
  key: string,
  value: RoutingLegValue[keyof RoutingLegValue],
  route_order: number
) => void;
export declare type RoutingNodeCallback = (
  key: string,
  value: RoutingNodeValue[keyof RoutingNodeValue],
  id: string
) => void;

export declare type RoutingNodeKeys = keyof RoutingNodeValue;
