import { useMutation } from '@apollo/client';
import { message, Modal, Radio } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';
import { get, omit as _omit } from 'lodash';
import { CONVERT_B2B_TO_CONSOL } from 'operations/graphql/shipment';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  CARGO_TYPES_AIR,
  CARGO_TYPE_AIR_LOOSE,
  LOAD_TYPE,
} from 'operations/modules/reports/constants';
import React, { useEffect, useState } from 'react';
import { errorMessageHandlerGraphQLString } from 'common';
import {
  BUSINESS_TYPE_DIRECT,
  BUYER_CONSOLIDATION,
  FREIGHT_TYPE_AIR,
  SELF_CONSOLIDATION,
  SELLER_CONSOLIDATION,
  SHIPMENT_TYPE_HOUSE,
} from '../constants';
import { useLocation } from 'wouter';

interface AddNewHouseShipmentProps {
  shipment: ShipmentValue;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function AddNewHouseShipment(props: AddNewHouseShipmentProps) {
  const [location, navigate] = useLocation();
  const { shipment, onClose, onSuccess } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [consolType, setConsolType] = useState(BUYER_CONSOLIDATION.key);
  const [houseLoadType, setHouseLoadType] = useState(
    get(props, ['shipment', 'freight_type']) === FREIGHT_TYPE_AIR
      ? CARGO_TYPE_AIR_LOOSE.key
      : get(props, ['shipment', 'load_type'], LOAD_TYPE_LCL)
  );
  let link = `~/form/new_shipment/new?shipment_type=${SHIPMENT_TYPE_HOUSE}&master_shipment_id=${shipment?.id}&trade_type=${shipment?.trade_type}&freight_type=${shipment?.freight_type}`;
  if (shipment.isAirShipment()) {
    link = `${process.env.SHIPMNTS_WEB_URL}/shipment/${get(
      shipment,
      'id'
    )}/create/${SHIPMENT_TYPE_HOUSE}`;
  }
  const [convertB2bToConsole, { data, loading, error }] = useMutation(CONVERT_B2B_TO_CONSOL, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (error) {
      message.error(errorMessageHandlerGraphQLString(error));
      return;
    }
    if (data?.convert_b2b_to_consol) {
      message.success('Successfully converted to console');
      onSuccess && onSuccess();
      onClose && onClose();
      navigate(`${location}?tab=attached_houses`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, link, onClose]);

  useEffect(() => {
    if (shipment.isConsolShipment()) {
      if (shipment.isOceanShipment()) {
        navigate(link, {
          state: {
            initialValue: JSON.stringify({
              involved_branch: shipment?.involved_branch,
              involved_branch_id: shipment?.involved_branch?.id,
              shipment_type: SHIPMENT_TYPE_HOUSE,
              port_of_loading: shipment?.port_of_loading,
              port_of_discharge: shipment?.port_of_discharge,
              load_type: shipment?.load_type,
              trade_type: shipment?.trade_type,
              master_shipment_id: shipment?.id,
              cargo_type: shipment?.cargo_type,
              sales_agent: shipment?.sales_agent,
              movement_mode: shipment?.movement_mode,
              consignee_party_name: 'consignee',
              business_type: shipment?.business_type || BUSINESS_TYPE_DIRECT,
              customer_company: shipment?.customer_company,
              party: {
                shipper: _omit(shipment?.getShipmentPartyByName('shipper'), 'id'),
                consignee: _omit(shipment?.getShipmentPartyByName('consignee'), 'id'),
              },
              shipment_parties: shipment.shipment_parties,
              services: shipment.services,
              cargos: shipment?.cargos,
              routing_legs: shipment?.routing_legs,
              incoterms: shipment?.incoterms,
            }),
          },
        });
      } else {
        window.open(link, '_blank');
      }
    }
  });

  useEffect(() => {
    if (
      !shipment.isShipmentCancelled() &&
      shipment.isBackToBackShipment() &&
      !shipment.isExportMasterExecuted() &&
      shipment.isForwardingShipment()
    ) {
      setModalVisible(true);
      return;
    }
    if (!shipment.isConsolShipment()) {
      window.open(link, '_self');
    }
  }, [shipment, link]);

  const onAddHouseConfirm = async () => {
    await convertB2bToConsole({
      variables: {
        shipment_id: shipment.id,
        consol_type: consolType,
        house_load_type: houseLoadType,
      },
    });
  };

  return (
    <Modal
      open={modalVisible}
      onCancel={onClose}
      onOk={onAddHouseConfirm}
      confirmLoading={loading}
      title="Are you sure you want to convert it to Consol Shipment?"
    >
      <div>
        Select Consol Type <br />
        <Radio.Group
          name="consol_type"
          onChange={(e) => {
            setConsolType(e.target.value);
          }}
          value={consolType}
        >
          <Radio value={BUYER_CONSOLIDATION.key}>{BUYER_CONSOLIDATION.name}</Radio>
          <Radio value={SELLER_CONSOLIDATION.key}>{SELLER_CONSOLIDATION.name}</Radio>
          <Radio value={SELF_CONSOLIDATION.key}>{SELF_CONSOLIDATION.name}</Radio>
        </Radio.Group>
        <br />
        <br />
        Select House Load Type <br />
        <Radio.Group
          name="house_load_type"
          onChange={(e) => {
            setHouseLoadType(e.target.value);
          }}
          value={houseLoadType}
        >
          {(get(shipment, 'freight_type') === FREIGHT_TYPE_AIR ? CARGO_TYPES_AIR : LOAD_TYPE).map(
            (load_type) => {
              return (
                <Radio key={load_type.key} value={load_type.key}>
                  {load_type.name}
                </Radio>
              );
            }
          )}
        </Radio.Group>
      </div>
    </Modal>
  );
}
