import React, { lazy, useEffect, useState } from 'react';

import { Row, Col, Form, Input, InputNumber, Select, DatePicker, Card } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import { VesselSearch, CompanySearch, RichTextEditorWrapper, useSession } from 'common';

import {
  CARRIER_TYPE_AIR,
  CARRIER_TYPE_OCEAN,
  CARRIER_TYPE_NVOCC,
  CARRIER_TYPE_COLOADER,
  CarrierType,
} from 'sales_hub/models/Carrier';
import { InquiryValue } from 'sales_hub/models/inquiry';

import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  SERVICE_TYPE_DESTINATION_CLEARANCE,
  SERVICE_TYPE_FREIGHT,
  SERVICE_TYPE_ORIGIN_CLEARANCE,
  TERMS_AND_COND_TEMPLATE_TYPE,
} from 'sales_hub/utils/constants';

import { InquiryOptionDependedContext } from './InquiryOptionDependedContext';
import { baseConstants } from 'common';
import { LocationType } from 'sales_hub/models/Location';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const { LOAD_TYPE_LCL } = baseConstants;

const { TextArea } = Input;
const ChargesTable = lazy(() => import('sales_hub/components/InquiryOption/ChargesTable'));
const { RangePicker } = DatePicker;

interface InquiryOptionFormContentProp {
  form: FormInstance;
  inquiry: InquiryValue;
  estimatesRef?: any;
  disabled?: boolean;
  id?: string;
}

function InquiryOptionFormContent(props: InquiryOptionFormContentProp) {
  const { inquiry, estimatesRef, disabled, form, id } = props;
  const [showChargeTable, setShowChargeTable] = useState(false);
  const { freight_type: freightType, services } = inquiry;
  const isAir = freightType === FREIGHT_TYPE_AIR;
  const isOcean = freightType === FREIGHT_TYPE_OCEAN;
  const isLand = freightType === FREIGHT_TYPE_ROAD;
  const isFF = services?.includes(SERVICE_TYPE_FREIGHT);
  const isOriginClearance = services?.includes(SERVICE_TYPE_ORIGIN_CLEARANCE);
  const isDestinationClearance = services?.includes(SERVICE_TYPE_DESTINATION_CLEARANCE);
  const session = useSession();
  const locationType: LocationType[] = ['ICD'];
  if (isOcean) locationType.push('Seaport');
  if (isAir) locationType.push('Airport');

  const carrierSearchProp: {
    carrier_type?: Array<CarrierType>;
  } = {
    carrier_type: isOcean
      ? [CARRIER_TYPE_OCEAN, CARRIER_TYPE_NVOCC, CARRIER_TYPE_COLOADER]
      : [CARRIER_TYPE_AIR],
  };

  useEffect(() => {
    setTimeout(() => setShowChargeTable(true), 0.1);
  }, []);

  return (
    <>
      <Card title="Charge Details">
        <Row gutter={32}>
          <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <Form.Item
              labelCol={{ span: 24 }}
              style={{ width: '100%' }}
              name="quote_currency"
              label="Quote Currency"
              required={true}
            >
              <GlobalSearch doc_type="Global::Currency" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <Form.Item noStyle dependencies={['quote_currency']}>
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    extra={`1 ${getFieldValue('quote_currency')} = [?] ${
                      session?.company_account?.default_currency
                    }`}
                    labelCol={{ span: 24 }}
                    name="exchange_rate"
                    required={true}
                    label="Exchange Rate"
                  >
                    <InputNumber min={1} precision={5} style={{ width: '100%' }} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        {showChargeTable && (
          <Row>
            <Form.Item
              noStyle
              dependencies={['chargeable_weight', 'quote_currency', 'exchange_rate']}
            >
              {({ getFieldValue }) => {
                return (
                  <InquiryOptionDependedContext.Provider
                    value={{
                      inquiryOptionId: id || 'new',
                      chargeableWeight: getFieldValue('chargeable_weight'),
                      inquiryOptionCurrency: getFieldValue('quote_currency'),
                      inquiryOptionExchangeRate: getFieldValue('exchange_rate'),
                    }}
                  >
                    <ChargesTable
                      ref={estimatesRef}
                      inquiry={inquiry}
                      disabled={disabled}
                      shipmentEstimates={getFieldValue('shipment_estimates')}
                    />
                  </InquiryOptionDependedContext.Provider>
                );
              }}
            </Form.Item>
          </Row>
        )}
      </Card>
      <Card title="Quote Details">
        <Row gutter={32}>
          {isOcean && isFF && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="place_of_carrier_receipt"
                label="Place Of Carrier Receipt"
              >
                <GlobalSearch
                  doc_type="Global::Location"
                  searchProps={{ type: locationType }}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          )}
          {!isLand && (
            <>
              <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Form.Item labelCol={{ span: 24 }} name="port_of_loading" label="Port Of Loading">
                  <GlobalSearch
                    doc_type="Global::Location"
                    disabled={disabled}
                    searchProps={{
                      type: freightType === FREIGHT_TYPE_AIR ? ['Airport'] : ['Seaport'],
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="port_of_discharge"
                  label="Port Of Discharge"
                >
                  <GlobalSearch
                    doc_type="Global::Location"
                    disabled={disabled}
                    searchProps={{
                      type: freightType === FREIGHT_TYPE_AIR ? ['Airport'] : ['Seaport'],
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          {isOcean && isFF && (
            <>
              <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="place_of_carrier_delivery"
                  label="Place Of Carrier Delivery"
                >
                  <GlobalSearch
                    doc_type="Global::Location"
                    searchProps={{ type: locationType }}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          {!isLand && isFF && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item labelCol={{ span: 24 }} name="carrier" label="Carrier">
                <GlobalSearch
                  doc_type="Global::Carrier"
                  disabled={disabled}
                  searchProps={carrierSearchProp}
                />
              </Form.Item>
            </Col>
          )}
          {isAir && isFF && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item noStyle dependencies={['carrier']}>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      labelCol={{ span: 24 }}
                      name="carrier_product"
                      label="Carrier Product"
                    >
                      <GlobalSearch
                        doc_type="Global::CarrierProduct"
                        selectProps={{ popupClassName: 'test' }}
                        extraProps={{
                          carrier_code: getFieldValue('carrier')?.iata_carrier_code,
                        }}
                        disabled={disabled}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          )}

          {isOcean && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item labelCol={{ span: 24 }} name="ocean_vessel" label="Ocean Vessel">
                <VesselSearch disabled={disabled} />
              </Form.Item>
            </Col>
          )}

          {!isLand && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="voyage_number"
                label={isAir ? 'Flight #' : 'Voyage Number'}
              >
                <Input placeholder={isAir ? 'Flight Number' : 'Voyage Number'} />
              </Form.Item>
            </Col>
          )}

          {isFF && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item noStyle shouldUpdate={true}>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      labelCol={{ span: 24 }}
                      name="transit_days"
                      label="Transit Days"
                      dependencies={['estimated_time_of_arrival', 'estimated_time_of_departure']}
                    >
                      <Input
                        disabled={
                          getFieldValue('estimated_time_of_arrival') &&
                          getFieldValue('estimated_time_of_departure')
                        }
                        style={{ width: '100%' }}
                        placeholder="Number of Transit Days"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          )}

          <Col span={6} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <Form.Item labelCol={{ span: 24 }} name="routing" label="Routing">
              <Input placeholder="Routing remarks" />
            </Form.Item>
          </Col>

          {!isLand && (
            <>
              {!isDestinationClearance && (
                <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    name="estimated_time_of_departure"
                    label="Estimated Time Of Departure"
                  >
                    <DatePicker disabled={disabled} showTime style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )}

              {!isOriginClearance && (
                <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    name="estimated_time_of_arrival"
                    label="Estimated Time Of Arrival"
                  >
                    <DatePicker disabled={disabled} showTime style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )}
            </>
          )}

          {isAir && (
            <>
              <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Form.Item labelCol={{ span: 24 }} name="chargeable_weight" label="Ch. Wt">
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Form.Item labelCol={{ span: 24 }} name="tariff_rate" label="Tariff Rate">
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </>
          )}
          {isOcean && inquiry?.load_type === LOAD_TYPE_LCL && (
            <>
              <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Form.Item labelCol={{ span: 24 }} name="chargeable_weight" label="Ch. Vol">
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <Form.Item label="Validity" required rules={[{ required: true }]} name="validity">
              <RangePicker
                style={{ width: '100%' }}
                format={(value: any) => value.format('DD-MM-YYYY')}
              />
            </Form.Item>
          </Col>

          {isOcean && isFF && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item labelCol={{ span: 24 }} name="validity_basis" label="Validity Basis">
                <Select
                  placeholder="Validity Basis"
                  options={[
                    {
                      label: 'Booking Date',
                      value: 'Booking Date',
                    },
                    {
                      label: 'Vessel Sailing',
                      value: 'Vessel Sailing',
                    },
                    {
                      label: 'Container Gate in',
                      value: 'Container Gate in',
                    },
                    {
                      label: 'Container Pickup',
                      value: 'Container Pickup',
                    },
                    {
                      label: 'Document Handover',
                      value: 'Document Handover',
                    },
                    {
                      label: 'ICD Document Handover',
                      value: 'ICD Document Handover',
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <Form.Item labelCol={{ span: 24 }} name="booking_vendor" label="Vendor Company">
              <CompanySearch disabled={disabled} searchProps={{ is_vendor: true }} />
            </Form.Item>
          </Col>

          {isFF && (
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="vendor_rate_agreement_number"
                label="Rate Agreement Number "
              >
                <Input placeholder="Enter RA#" />
              </Form.Item>
            </Col>
          )}
        </Row>
        {isOcean && isFF && (
          <>
            <Row gutter={32}>
              <Col>Free Days </Col>
            </Row>
            <Row gutter={32}>
              <Col sm={{ span: 12 }} lg={{ span: 4 }}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="origin_carrier_free_days"
                  label="Origin Carrier"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 12 }} lg={{ span: 4 }}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="destination_port_free_days"
                  label="Destination Port"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col sm={{ span: 12 }} lg={{ span: 4 }}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="destination_carrier_free_days"
                  label="Destination Carrier"
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Card title="Additional Details">
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="remarks"
              label="Remarks"
              style={{ width: '100%' }}
            >
              <TextArea rows={2} placeholder="Please enter remarks" minLength={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="terms_and_cond"
              label="Terms And Condition"
              style={{ width: '100%' }}
            >
              <GlobalSearch
                doc_type="Network::Template"
                disabled={disabled}
                extraProps={{
                  prefillDefault: !form.getFieldValue('id'),
                }}
                searchProps={{
                  template_types: [TERMS_AND_COND_TEMPLATE_TYPE],
                  resource_type: 'SalesHub::InquiryOption',
                }}
                showCreation={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.Item
            labelCol={{ span: 24 }}
            name="terms_and_cond_desc"
            label="Terms And Condition Description"
            style={{ width: '100%' }}
          >
            <RichTextEditorWrapper
              height={'200px'}
              toolbarOptions={{
                container: [
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ color: [] }, { background: [] }],
                  ['emoji'],
                  [{ indent: '-1' }, { indent: '+1' }],
                ],
              }}
            />
          </Form.Item>
        </Row>
      </Card>
    </>
  );
}

export default InquiryOptionFormContent;
