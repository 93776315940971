import { RowNode } from '@ag-grid-community/core';
import getDoctypeEnableDisableHelperAction from '../doctypeEnableDisableHelper';
import { Action } from 'operations/modules/actionHelper/models';
import { PERMISSION_SALES_PERSON_DISABLE } from 'network/permissions';
import { hasPermission } from '@shipmnts/pixel-hub';

const getSalesPersonBulkActions = (selectedNodes: RowNode[], permissions: any) => {
  if (selectedNodes.length === 0) return [];
  const actions: Action[] = [];
  const disableAction = getDoctypeEnableDisableHelperAction('Network::SalesPerson', selectedNodes);
  if (
    disableAction &&
    hasPermission(permissions, {
      docType: 'Network::SalesPerson',
      name: PERMISSION_SALES_PERSON_DISABLE,
    })
  ) {
    actions.push(disableAction);
  }

  return actions;
};

export default getSalesPersonBulkActions;
