import { startCase as _startCase } from 'lodash';
import { RadioOptionProps } from 'operations/commonTypeDefs';
export const ROW_GUTTER = 16;

export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export const FREIGHT_TYPE_ROAD = 'road';
export type FreightType = 'air' | 'ocean' | 'road';

export const TRADE_TYPE_EXPORT = 'export';
export const TRADE_TYPE_IMPORT = 'import';
export const TRADE_TYPE_DOMESTIC = 'domestic';
export const TRADE_TYPE_CROSS_TRADE = 'cross_trade';
export type TradeType = 'export' | 'import' | 'domestic' | 'cross_trade';

export const TRADE_TYPES: RadioOptionProps[] = [
  { key: TRADE_TYPE_EXPORT, name: 'Export' },
  { key: TRADE_TYPE_IMPORT, name: 'Import' },
];

export const CARGO_TYPE_CONTAINER = 'container';
export const CARGO_TYPE_BREAK_BULK = 'breakbulk';
export const CARGO_TYPE_LIQUID = 'liquid';
export const CARGO_TYPE_BULK = 'bulk';

export const ASSET_TYPE_OWN = 'own';
export const ASSET_TYPE_HIRE = 'hire';

export const MOVEMENT_MODE_CONTAINER_STUFFING = 'container_stuffing';
export const MOVEMENT_MODE_CONTAINER_DESTUFFING = 'container_destuffing';
export const MOVEMENT_MODE_AIRPORT_DELIVERY = 'air_delivery';
export const MOVEMENT_MODE_AIRPORT_PICKUP = 'air_pickup';
export const MOVEMENT_MODE_PICKUP_AND_DROP = 'pickup_and_drop';
export const MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP = 'container_pickup_and_drop';

export const SHIPMENT_TYPE_DIRECT = 'direct';
export const SHIPMENT_TYPE_BACK_TO_BACK = 'back_to_back';
export const SHIPMENT_TYPE_CONSOL = 'consol';
export const SHIPMENT_TYPE_HOUSE = 'house';
export const SHIPMENT_TYPE_INDEPENDENT_HOUSE = 'independent_house';
export const CUSTOMER_ORDER_TYPE = 'customer_order';

export type ShipmentType = 'direct' | 'back_to_back' | 'consol' | 'house' | 'general';

export const DOCUMENT_TYPE_HOUSE = 'house';
export const DOCUMENT_TYPE_MASTER = 'master';

export type DocumentType = 'master' | 'house';

export const ACTION_STATUS_SHIPMENT_CANCELLED = 'Shipment Cancelled';

export const ACCOUNTING_STATUS_PENDING = 'pending';
export const ACCOUNTING_STATUS_CLOSED = 'closed';
export const ACCOUNTING_STATUS_BILLING_CLOSED = 'billing_closed';

export const accountingStatusMapping: { [key: string]: string } = {
  [ACCOUNTING_STATUS_PENDING]: 'Pending',
  [ACCOUNTING_STATUS_CLOSED]: 'Job Closed',
  [ACCOUNTING_STATUS_BILLING_CLOSED]: 'Billing Closed',
};

export const SERVICE_TYPE_PORT_TO_PORT = 'port_to_port';
export const SERVICE_TYPE_PORT_TO_DOOR = 'port_to_door';
export const SERVICE_TYPE_DOOR_TO_PORT = 'door_to_port';
export const SERVICE_TYPE_DOOR_TO_DOOR = 'door_to_door';
export const SERVICE_TYPE_PORT_TO_ICD = 'port_to_icd';
export const SERVICE_TYPE_ICD_TO_PORT = 'icd_to_port';
export const SERVICE_TYPE_ICD_TO_ICD = 'icd_to_icd';

export const SERVICE_TYPE_FREIGHT = 'freight_forwarding';
export const SERVICE_TYPE_ORIGIN = 'origin';
export const SERVICE_TYPE_DESTINATION = 'destination';

export const SHIPPING_LINE_SERVICE_TYPES: RadioOptionProps[] = [
  { key: SERVICE_TYPE_PORT_TO_PORT, name: _startCase(SERVICE_TYPE_PORT_TO_PORT) },
  { key: SERVICE_TYPE_PORT_TO_DOOR, name: _startCase(SERVICE_TYPE_PORT_TO_DOOR) },
  { key: SERVICE_TYPE_DOOR_TO_PORT, name: _startCase(SERVICE_TYPE_DOOR_TO_PORT) },
  { key: SERVICE_TYPE_DOOR_TO_DOOR, name: _startCase(SERVICE_TYPE_DOOR_TO_DOOR) },
  { key: SERVICE_TYPE_PORT_TO_ICD, name: 'Port To ICD / Ramp' },
  { key: SERVICE_TYPE_ICD_TO_PORT, name: 'ICD / Ramp to Port' },
  { key: SERVICE_TYPE_ICD_TO_ICD, name: 'ICD / Ramp to ICD / Ramp' },
];

export const BUSINESS_TYPE_DIRECT = 'direct';
export const BUSINESS_TYPE_SUBAGENT = 'subagent';
export const BOOKING_THROUGH_OVERSEAS_AGENT = 'agent_nomination';

export const BOOKING_THROUGH_TYPES: RadioOptionProps[] = [
  { key: BUSINESS_TYPE_DIRECT, name: 'Direct' },
  { key: BUSINESS_TYPE_SUBAGENT, name: 'Subagent' },
  {
    key: BOOKING_THROUGH_OVERSEAS_AGENT,
    name: 'Overseas Nomination',
  },
];

export const BUYER_CONSOLIDATION = { key: 'consignee', name: "Buyer's Consolidation" };
export const SELLER_CONSOLIDATION = { key: 'shipper', name: "Seller's Consolidation" };
export const SELF_CONSOLIDATION = { key: 'agent', name: 'Self Consolidation' };
export const SELF_CONSOLIDATION_KEY = 'agent';

export const CONSOLIDATION_TYPES = [BUYER_CONSOLIDATION, SELLER_CONSOLIDATION];

export const BL_TYPE_EXPRESS = 'express';
export const BL_TYPE_ORIGINAL = 'original';
export const BL_TYPE_E_BL = 'ebl';

export const BL_TYPE_ARRAY = [
  { key: BL_TYPE_ORIGINAL, name: 'Original' },
  { key: BL_TYPE_EXPRESS, name: 'Seaway BL' },
  { key: BL_TYPE_E_BL, name: 'eBL' },
];

export const HOME_BILL = 'Home Consumption';
export const SEZ_BILL = 'SEZ';
export const INBOND_BILL = 'Inbond (Warehouse)';
export const EXBOND_BILL = 'Exbond';

export const BILL_OF_ENTRY_TYPE = [HOME_BILL, SEZ_BILL, INBOND_BILL, EXBOND_BILL];

export const SB_HOME_BILL = 'Drawback';
export const DEPB = 'DEPB';
export const DUTY_FREE = 'Duty-Free';
export const EXBOND_DUTY_FREE = 'ExBond Duty-Free';
export const DUTIABLE = 'Dutiable';
export const SHIPPING_BILL_TYPE = [SB_HOME_BILL, DEPB, DUTY_FREE, EXBOND_DUTY_FREE, DUTIABLE];

export const blTypeMapping: { [key: string]: string } = {
  [BL_TYPE_ORIGINAL]: 'ORIGINAL',
  [BL_TYPE_EXPRESS]: 'SEAWAY BILL - EXPRESS',
  [BL_TYPE_E_BL]: 'e-BL',
};

export const RELEASE_TYPE_RFS = 'received_for_shipment';
export const RELEASE_TYPE_SOB = 'shipped_on_board';
export const RELEASE_TYPE_PLAIN = 'plain_bl';

export const RELEASE_TYPE_ARRAY = [
  {
    key: RELEASE_TYPE_RFS,
    name: 'Received For Shipment (RFS)',
  },
  {
    key: RELEASE_TYPE_SOB,
    name: 'Shipped On Board (SOB)',
  },
  {
    key: RELEASE_TYPE_PLAIN,
    name: 'Plain',
  },
];

export const CHARGE_AMOUNT_PREPAID = 'prepaid';
export const CHARGE_AMOUNT_COLLECT = 'collect';

export const CHARGE_TERMS = [
  { key: CHARGE_AMOUNT_PREPAID, name: 'PP-prepaid' },
  { key: CHARGE_AMOUNT_COLLECT, name: 'CC-collect' },
];

export const CONSIGNEE_TYPE_ACTUAL_CONSIGNEE = {
  key: 'consignee',
  name: 'Actual Consignee',
};
export const CONSIGNEE_TYPE_TO_ORDER = {
  key: 'to_order',
  name: 'To Order',
};
export const CONSIGNEE_TYPE_TO_ORDER_BANK = {
  key: 'to_order_bank',
  name: 'To Order Bank',
};

export const CONSIGNEE_TYPES = [
  CONSIGNEE_TYPE_ACTUAL_CONSIGNEE,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
];

export const INCOTERMS = [
  {
    key: 'FOB',
    name: 'Free On Board',
    display_name: 'FOB Free On Board',
  },
  {
    key: 'CIF',
    name: 'Cost Insurance and Freight',
    display_name: 'CIF Cost Insurance and Freight',
  },
  {
    key: 'EXW',
    name: 'Ex Works',
    display_name: 'EXW Ex Works',
  },
  {
    key: 'DDP',
    name: 'Delivered Duty Paid',
    display_name: 'DDP Delivered Duty Paid',
  },
  {
    key: 'DDU',
    name: 'Delivered Duty Unpaid',
    display_name: 'DDU Delivered Duty Unpaid',
  },
  {
    key: 'DAP',
    name: 'Delivered at Place',
    display_name: 'DAP Delivered at Place',
  },
  {
    key: 'FCA',
    name: 'Free Carrier',
    display_name: 'FCA Free Carrier',
  },
  {
    key: 'CFR',
    name: 'Cost and Freight',
    display_name: 'CFR Cost and Freight',
  },
  {
    key: 'CPT',
    name: 'Carriage Paid To',
    display_name: 'CPT Carriage Paid To',
  },
  {
    key: 'CIP',
    name: 'Carriage and Insurance Paid to',
    display_name: 'CIP Carriage and Insurance Paid to',
  },
  {
    key: 'FAS',
    name: 'Free Alongside Ship',
    display_name: 'FAS Free Alongside Ship',
  },
  {
    key: 'DAT',
    name: 'Delivered at Terminal',
    display_name: 'DAT Delivered at Terminal',
  },
];

export const FIELD_TYPE_STATIC = 'static';
export const FIELD_TYPE_VARIABLE = 'variable';

export const DOCUMENT_DISPATCH_DIGITAL = 'digital';
export const DOCUMENT_DISPATCH_COURIER = 'courier';
export const DOCUMENT_DISPATCH_COURIER_VIA_BANK = 'courier_via_bank';

export const dispatchModeMapping: { [key: string]: string } = {
  [DOCUMENT_DISPATCH_DIGITAL]: 'Digital',
  [DOCUMENT_DISPATCH_COURIER]: 'Courier',
  [DOCUMENT_DISPATCH_COURIER_VIA_BANK]: 'Courier via Bank',
};

export const CREDIT_STATUS_APPROVED = 'manual_approved';
export const CREDIT_STATUS_ON_HOLD = 'manual_on_hold';
export const CREDIT_STATUS_ON_AUTO_HOLD = 'auto_on_hold';
export const CREDIT_STATUS_PENDING = 'pending';
export const CREDIT_STATUS_APPROVED_EVENT = 'manually_approve_credit';
export const CREDIT_STATUS_ON_HOLD_EVENT = 'manually_on_hold_credit';

export const CHANGE_EVENT_CREDIT_AUTO_HOLD = 'automated_on_hold';
export const CHANGE_EVENT_CREDIT_AUTO_APPROVE = 'automated_approval';
export const CHANGE_EVENT_CREDIT_MANUAL_HOLD = 'manually_on_hold';
export const CHANGE_EVENT_CREDIT_MANUAL_APPROVE = 'manually_approve';
export const CHANGE_EVENT_PENDING = 'pending';

export const CREDIT_STATUS_EVENT_DISPLAY: { [key: string]: { [key: string]: string } } = {
  [CHANGE_EVENT_CREDIT_AUTO_APPROVE]: {
    label: 'Credit Auto Approved',
  },
  [CHANGE_EVENT_CREDIT_AUTO_HOLD]: {
    label: 'Credit Auto On Hold',
  },
  [CHANGE_EVENT_CREDIT_MANUAL_APPROVE]: {
    label: 'Credit Manually Approved',
  },
  [CHANGE_EVENT_CREDIT_MANUAL_HOLD]: {
    label: 'Credit Manually On Hold',
  },
  [CHANGE_EVENT_PENDING]: {
    label: 'Pending',
  },
};

export const CREDIT_STATUS_DISPLAY: { [key: string]: { [key: string]: string } } = {
  [CREDIT_STATUS_APPROVED]: {
    label: 'Credit Approved',
    color: 'success',
  },
  [CREDIT_STATUS_ON_HOLD]: {
    label: 'Credit On Hold',
    color: 'error',
  },
  [CREDIT_STATUS_PENDING]: {
    label: 'Pending',
    color: 'default',
  },
  [CREDIT_STATUS_ON_AUTO_HOLD]: {
    label: 'Credit On Auto Hold',
    color: 'error',
  },
};

export const FREIGHT_CHARGE_TAG = 'freight_charge';
export const REBATE_CHARGE_TAG = 'rebate_charge';
export const ENUM_BUY_SELL_STATUS = {
  UNBILLED: 'unbilled',
  BILLED: 'billed',
  DRAFT: 'draft',
  CANCELLED: 'cancelled',
  IGNORED: 'ignored',
};
export type ENUM_BUY_SELL_STATUS_TYPE = 'unbilled' | 'billed' | 'draft' | 'ignored';

export const FIELD_TAG = 'tag';
export const FIELD_PRODUCT_RATING_ID = 'product_rating_id';

export const MESSAGE_TYPE_OPS_PRE_ALERT = 'ops_pre_alert';
export const MESSAGE_TYPE_DOCUMENT_DISPATCH = 'document_dispatch';

export const ACCOUNTING_CURRENT_STATUS_PENDING = 'shipment_accounting:pending';
export const ACCOUNTING_CURRENT_STATUS_CLOSED = 'shipment_accounting:closed';
export const ACCOUNTING_CURRENT_STATUS_BILLING_CLOSED = 'shipment_accounting:billing_closed';

export const ACCOUNTING_NEW_STATUS_BILLING_COMPLETED = 'Billing Completed';
export const ACCOUNTING_NEW_STATUS_BILLING_REOPEN = 'Billing Reopen';
export const ACCOUNTING_NEW_STATUS_JOB_CLOSED = 'Job Closed';
export const ACCOUNTING_NEW_STATUS_JOB_REOPEN = 'Job Reopen';
export const ACCOUNTING_NEW_STATUS_PENDING = 'Pending';

export const REWARD_SCHEMES = [
  {
    name: '00 - Free SB Involving Remittance Of Foreign Exchange',
    value: '00 - Free SB Involving Remittance Of Foreign Exchange',
  },
  {
    name: '01 - Advance License With Actual User Condition',
    value: '01 - Advance License With Actual User Condition',
  },
  {
    name: '02 - Advance License For Intermediate Supplies',
    value: '02 - Advance License For Intermediate Supplies',
  },
  {
    name: '03 - Advance License',
    value: '03 - Advance License',
  },
  {
    name: '04 - Advance Release Order',
    value: '04 - Advance Release Order',
  },
  {
    name: '05 - Advance License For Deemed Exports',
    value: '05 - Advance License For Deemed Exports',
  },
  {
    name: '06 - DEPB Post Export',
    value: '06 - DEPB Post Export',
  },
  {
    name: '07 - DEPB-Pre Export',
    value: '07 - DEPB-Pre Export',
  },
  {
    name: '08 - Replenishment Licence',
    value: '08 - Replenishment Licence',
  },
  {
    name: '09 - Diamond Imprest Licence',
    value: '09 - Diamond Imprest Licence',
  },
  {
    name: '10 - Bulk License',
    value: '10 - Bulk License',
  },
  {
    name: '11 - Concessional Duty EPCG Scheme',
    value: '11 - Concessional Duty EPCG Scheme',
  },
  {
    name: '12 - Zero Duty EPCG Scheme',
    value: '12 - Zero Duty EPCG Scheme',
  },
  {
    name: '13 - CCP',
    value: '13 - CCP',
  },
  {
    name: '14 - Import License For Restricted Items Of Imports',
    value: '14 - Import License For Restricted Items Of Imports',
  },
  {
    name: '15 - Special Import License',
    value: '15 - Special Import License',
  },
  {
    name: '16 - Export License',
    value: '16 - Export License',
  },
  {
    name: '17 - Advance Licence for annual requirement',
    value: '17 - Advance Licence for annual requirement',
  },
  {
    name: '19 - Drawback',
    value: '19 - Drawback',
  },
  {
    name: '20 - Jobbing(JBG)',
    value: '20 - Jobbing(JBG)',
  },
  {
    name: '21 - EOU/EPZ/SEZ/EHTP/STP',
    value: '21 - EOU/EPZ/SEZ/EHTP/STP',
  },
  {
    name: '22 - Duty Free Credit Entitlement Certificate',
    value: '22 - Duty Free Credit Entitlement Certificate',
  },
  {
    name: '23 - Target Plus Scheme',
    value: '23 - Target Plus Scheme',
  },
  {
    name: '24 - Vishesh Krishi Upaj Yojana (VKUY)',
    value: '24 - Vishesh Krishi Upaj Yojana (VKUY)',
  },
  {
    name: '25 - DFCE for Status holder',
    value: '25 - DFCE for Status holder',
  },
  {
    name: '26 - Duty Free Import Authorization',
    value: '26 - Duty Free Import Authorization',
  },
  {
    name: '27 - Focus Market Scheme',
    value: '27 - Focus Market Scheme',
  },
  {
    name: '28 - Focus Product Scheme',
    value: '28 - Focus Product Scheme',
  },
  {
    name: '29 - High Tech Products Export Promotion Scheme',
    value: '29 - High Tech Products Export Promotion Scheme',
  },
  {
    name: '30 - EPCG Duty Based',
    value: '30 - EPCG Duty Based',
  },
  {
    name: '31 - Status Holder Incentive Scheme',
    value: '31 - Status Holder Incentive Scheme',
  },
  {
    name: '32 - Tariff Quota',
    value: '32 - Tariff Quota',
  },
  {
    name: '35 - Incremental Incentivisation Scheme',
    value: '35 - Incremental Incentivisation Scheme',
  },
  {
    name: '36 - Merchandise Export Incentive Scheme',
    value: '36 - Merchandise Export Incentive Scheme',
  },
  {
    name: '37 - Service Export Incentive Scheme',
    value: '37 - Service Export Incentive Scheme',
  },
  {
    name: '39 - Rebate of State and Central Taxes Levies',
    value: '39 - Rebate of State and Central Taxes Levies',
  },
  {
    name: '40 - DBK and Advance Licence for annual requirement',
    value: '40 - DBK and Advance Licence for annual requirement',
  },
  {
    name: '41 - Drawback And Advance License',
    value: '41 - Drawback And Advance License',
  },
  {
    name: '43 - Drawback And Zero Duty EPCG',
    value: '43 - Drawback And Zero Duty EPCG',
  },
  {
    name: '44 - Drawback And Concessional Duty EPCG',
    value: '44 - Drawback And Concessional Duty EPCG',
  },
  {
    name: '45 - Drawback And Pre Export DEPB',
    value: '45 - Drawback And Pre Export DEPB',
  },
  {
    name: '46 - Drawback And Post Export DEPB',
    value: '46 - Drawback And Post Export DEPB',
  },
  {
    name: '47 - Drawback And JBG',
    value: '47 - Drawback And JBG',
  },
  {
    name: '48 - Drawback And Diamond Imprest Licence',
    value: '48 - Drawback And Diamond Imprest Licence',
  },
  {
    name: '49 - Drawback And EOU/EPZ/SEZ',
    value: '49 - Drawback And EOU/EPZ/SEZ',
  },
  {
    name: '50 - EPCG And Advance Licence',
    value: '50 - EPCG And Advance Licence',
  },
  {
    name: '52 - EPCG and JBG',
    value: '52 - EPCG and JBG',
  },
  {
    name: '53 - EPCG And Diamond Imprest Licence',
    value: '53 - EPCG And Diamond Imprest Licence',
  },
  {
    name: '54 - EPCG And Replenishment Licence',
    value: '54 - EPCG And Replenishment Licence',
  },
  {
    name: '55 - EPCG and DEPB(Post Exports)',
    value: '55 - EPCG and DEPB(Post Exports)',
  },
  {
    name: '56 - EPCG and Advance Licence for annual requirement',
    value: '56 - EPCG and Advance Licence for annual requirement',
  },
  {
    name: '59 - EPCG and DFIA',
    value: '59 - EPCG and DFIA',
  },
  {
    name: '60 - Drawback,and ROSCTL',
    value: '60 - Drawback,and ROSCTL',
  },
  {
    name: '61 - EPCG,Drawback and ROSCTL',
    value: '61 - EPCG,Drawback and ROSCTL',
  },
  {
    name: '62 - Drawback and special DEEC(4.04A)',
    value: '62 - Drawback and special DEEC(4.04A)',
  },
  {
    name: '63 - EPCG,Drawback and special DEEC(4.04A)',
    value: '63 - EPCG,Drawback and special DEEC(4.04A)',
  },
  {
    name: '64 - Drawback,special DEEC(4.04A) and ROSCTL',
    value: '64 - Drawback,special DEEC(4.04A) and ROSCTL',
  },
  {
    name: '65 - EPCG,Drawback,special DEEC(4.04A) and ROSCTL',
    value: '65 - EPCG,Drawback,special DEEC(4.04A) and ROSCTL',
  },
  {
    name: '71 - EPCG,Drawback And DEEC',
    value: '71 - EPCG,Drawback And DEEC',
  },
  {
    name: '73 - EPCG,DRAWBACK And Jobbing',
    value: '73 - EPCG,DRAWBACK And Jobbing',
  },
  {
    name: '74 - EPCG Drawback And Diamond Imprest Licence',
    value: '74 - EPCG Drawback And Diamond Imprest Licence',
  },
  {
    name: '75 - EPCG Drawback And DEPB Post Export',
    value: '75 - EPCG Drawback And DEPB Post Export',
  },
  {
    name: '76 - Epcg, Drawback And Depb (Pre-export)',
    value: '76 - Epcg, Drawback And Depb (Pre-export)',
  },
  {
    name: '79 - EPCG ,Drawback and DFIA',
    value: '79 - EPCG ,Drawback and DFIA',
  },
  {
    name: '80 - EPCG,Drawback and Advance Licence for annual requirement',
    value: '80 - EPCG,Drawback and Advance Licence for annual requirement',
  },
  {
    name: '81 - DFIA and Drawback',
    value: '81 - DFIA and Drawback',
  },
  {
    name: '91 - Merchandise Export Incentive Scheme for eCommerce Shipments',
    value: '91 - Merchandise Export Incentive Scheme for eCommerce Shipments',
  },
  {
    name: '98 - Free Shipping Bill involving Indian Currency',
    value: '98 - Free Shipping Bill involving Indian Currency',
  },
  {
    name: '99 - NFEI',
    value: '99 - NFEI',
  },
  {
    name: 'PB - Prohibited Imports',
    value: 'PB - Prohibited Imports',
  },
  {
    name: 'PI - Project Import',
    value: 'PI - Project Import',
  },
  {
    name: 'RD - RoDTEP Scheme',
    value: 'RD - RoDTEP Scheme',
  },
  {
    name: 'RS - RoSCTL Scheme',
    value: 'RS - RoSCTL Scheme',
  },
];

export const SERVICES_NAME_IMPORT_CLEARANCE = [
  {
    value: 'origin_service_agent',
    label: 'Origin Service',
    subcategories: [
      {
        value: 'origin_clearance_agent',
        label: 'Origin Export Clearance',
      },
      {
        value: 'nominee',
        label: 'Nominee',
      },

      {
        value: 'origin_transport_agent',
        label: 'Pickup',
      },
      {
        value: 'origin_warehousing_agent',
        label: 'Warehousing',
      },
      {
        value: 'origin_palletization_agent',
        label: 'Palletization',
      },
      {
        value: 'origin_fumigation_agent',
        label: 'Fumigation',
      },
    ],
  },
  {
    value: 'value_add_service',
    label: 'Value Add Service',
    subcategories: [
      {
        value: 'cargo_insurance_agent',
        label: 'Cargo Insurance',
      },
      {
        value: 'empty_container_insurance_agent',
        label: 'Empty Container Insurance',
      },
    ],
  },
];

export const SERVICES_NAME_EXPORT_CLEARANCE = [
  {
    value: 'destination_service_agent',
    label: 'Destination Service',
    subcategories: [
      {
        value: 'destination_clearance_agent',
        label: 'Import Clearance',
      },
      {
        value: 'nominee',
        label: 'Nominee',
      },
      {
        value: 'destination_palletization_agent',
        label: 'Palletization',
      },
      {
        value: 'destination_transport_agent',
        label: 'Delivery',
      },
    ],
  },
  {
    value: 'value_add_service',
    label: 'Value Add Service',
    subcategories: [
      {
        value: 'cargo_insurance_agent',
        label: 'Cargo Insurance',
      },
      {
        value: 'empty_container_insurance_agent',
        label: 'Empty Container Insurance',
      },
    ],
  },
];

export const SERVICES_NAME_EXPORT_IMPORT = [
  {
    value: 'freight_forwarding',
    label: 'Freight Forwarding',
    subcategories: [
      {
        value: 'freight_forwarding_agent',
        label: 'Freight Forwarding',
      },
    ],
  },
  {
    value: 'origin_service_agent',
    label: 'Origin Service',
    subcategories: [
      {
        value: 'origin_clearance_agent',
        label: 'Origin Customs Clearance',
      },
      {
        value: 'nominee',
        label: 'Nominee',
      },

      {
        value: 'origin_transport_agent',
        label: 'Pickup',
      },
      {
        value: 'origin_palletization_agent',
        label: 'Origin Palletization',
      },
      {
        value: 'origin_fumigation_agent',
        label: 'Origin Fumigation',
      },
    ],
  },
  {
    value: 'destination_service_agent',
    label: 'Destination Service',
    subcategories: [
      {
        value: 'destination_clearance_agent',
        label: 'Destination Customs Clearance',
      },
      {
        value: 'destination_transport_agent',
        label: 'Delivery',
      },
      {
        value: 'nominee',
        label: 'Nominee',
      },
      {
        value: 'destination_palletization_agent',
        label: 'Origin Palletization',
      },
      {
        value: 'destination_fumigation_agent',
        label: 'Origin Fumigation',
      },
    ],
  },
  {
    value: 'value_add_service',
    label: 'Value Add Service',
    subcategories: [
      {
        value: 'cargo_insurance_agent',
        label: 'Cargo Insurance',
      },
      {
        value: 'empty_container_insurance_agent',
        label: 'Empty Container Insurance',
      },
    ],
  },
];

export const servicePartyMapping: { [key: string]: string } = {
  origin_clearance_agent: 'origin_clearance',
  origin_transport_agent: 'origin_transport',
  origin_fumigation_agent: 'origin_fumigation',
  origin_palletization_agent: 'origin_palletization',
  destination_clearance_agent: 'destination_clearance',
  destination_transport_agent: 'destination_transport',
  freight_forwarding_agent: 'freight_forwarding',
};
export const getShipperOptions = (shipper_name: string, origin_agent_name: string) => [
  { key: 'shipper', name: shipper_name ? `Shipper (${shipper_name})` : 'Shipper' },
  {
    key: 'origin_agent',
    name: origin_agent_name ? `Origin Agent (${origin_agent_name})` : 'Origin Agent',
  },
];

export const getShipperOptionsForConsol = (
  shipper_name: string,
  origin_agent_name: string,
  consolidation_type: string
) => {
  const option_1_shipper = {
    key: 'shipper',
    name: shipper_name ? `Shipper (${shipper_name})` : 'Shipper',
  };
  const option_2_origin_agent = {
    key: 'origin_agent',
    name: origin_agent_name ? `Origin Agent (${origin_agent_name})` : 'Origin Agent',
  };
  switch (consolidation_type) {
    case BUYER_CONSOLIDATION.key:
      return [option_2_origin_agent];
    case SELLER_CONSOLIDATION.key:
      return [option_1_shipper, option_2_origin_agent];
    case SELF_CONSOLIDATION.key:
      return [option_2_origin_agent];
    default:
      break;
  }
  return [];
};

export const getConsigneeOptionsBackToBackAndConsol = (
  consignee_name: string,
  destination_agent_name: string
) => [
  { key: 'consignee', name: consignee_name ? `Consignee (${consignee_name})` : 'Consignee' },
  {
    key: 'destination_agent',
    name: destination_agent_name
      ? `Destination Agent (${destination_agent_name})`
      : 'Destination Agent',
  },
];

export const getConsigneeOptionsConsol = (
  consignee_name: string,
  destination_agent_name: string,
  consolidation_type: string
) => {
  const option_1_consignee = {
    key: 'consignee',
    name: consignee_name ? `Consignee (${consignee_name})` : 'Consignee',
  };
  const option_2_destination_agent = {
    key: 'destination_agent',
    name: destination_agent_name
      ? `Destination Agent (${destination_agent_name})`
      : 'Destination Agent',
  };
  switch (consolidation_type) {
    case BUYER_CONSOLIDATION.key:
      return [option_1_consignee, option_2_destination_agent];
    case SELLER_CONSOLIDATION.key:
      return [option_2_destination_agent];
    case SELF_CONSOLIDATION.key:
      return [option_2_destination_agent];
    default:
      break;
  }
  return [];
};

export const getConsigneeOptionsDirect = (name: string) => [
  { key: 'consignee', name: name ? `Consignee (${name})` : 'Consignee' },
  { key: 'to_order', name: 'To Order' },
  { key: 'to_order_bank', name: 'To Order Bank' },
];

export const SHIPMENT_ACTION_SPLIT = 'split';
export const SHIPMENT_ACTION_MERGE = 'merge';
export const SHIPMENT_ACTION_MERGE_AND_SPLIT = 'merge_and_split';
export const SHIPMENT_ACTION_CREATE = 'create';
export const FILING_TYPE = [
  {
    key: 'Normal',
    value: 'Normal',
  },
  {
    name: 'Advance',
    value: 'Advance',
  },
  {
    name: 'Prior',
    value: 'Prior',
  },
];

export const priorityOptions = [
  { value: 'Highest', label: 'Highest' },
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'Lowest', label: 'Lowest' },
];

export const FREIGHT_CHARGE_AIR_EXPORT = 'Air Export Freight';
export const FREIGHT_CHARGE_AIR_IMPORT = 'Air Import Freight';
export const FREIGHT_CHARGE_OCEAN_EXPORT = 'Ocean Export Freight';
export const FREIGHT_CHARGE_OCEAN_IMPORT = 'Ocean Import Freight';

export const ITEM_CHARGE_MAPPING = {
  [FREIGHT_TYPE_AIR]: {
    [TRADE_TYPE_EXPORT]: FREIGHT_CHARGE_AIR_EXPORT,
    [TRADE_TYPE_IMPORT]: FREIGHT_CHARGE_AIR_IMPORT,
  },
  [FREIGHT_TYPE_OCEAN]: {
    [TRADE_TYPE_EXPORT]: FREIGHT_CHARGE_OCEAN_EXPORT,
    [TRADE_TYPE_IMPORT]: FREIGHT_CHARGE_OCEAN_IMPORT,
  },
};
