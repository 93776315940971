import React, { forwardRef, useState } from 'react';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails/RoutingDetails';
import { AddressCompanySearch } from 'common';
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  FormInstance,
  Radio,
  Row,
  VesselSearch,
  Input,
  DatePicker,
} from '@shipmnts/pixel-hub';
import {
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FreightType,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  TradeType,
} from 'operations/modules/shipment/constants';
import { STUFFING_LOCATION_TYPE_CFS, stuffing_location_types } from 'operations/baseConstants';
import {
  LOCATION_TYPE_CFS,
  LOCATION_TYPE_ICD,
  LOCATION_TYPE_PORT_TERMINAL,
  LocationType,
  LocationValue,
} from 'operations/models/Location';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  MOVEMENT_TYPE_CFS,
  MOVEMENT_TYPE_DPD,
  MOVEMENT_TYPE_DPD_CFS,
  MOVEMENT_TYPE_ICD,
} from 'operations/modules/reports/constants';
import { ShipmentCustomDetailValue } from 'operations/models/ShipmentCustomDetail';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const { Panel } = Collapse;

interface CustomClearanceShipmentRoutingDetailsProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  disableNodes?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  bookingType?: string | null;
  isReeferContainer?: boolean;
  allowVoyageScheduleSearch?: boolean;
  onlyTranshipmentHopEditable?: boolean;
  showPickupSection?: boolean;
  showStuffingSection?: boolean;
  showDeStuffingSection?: boolean;
  showMovementSection?: boolean;
  showDeliverySection?: boolean;
  freightType?: FreightType;
  loadType?: string;
  form: FormInstance;
  tradeType?: TradeType;
  shipment?: ShipmentValue;
}

const updateCustomDetails = (
  form: FormInstance,
  custom_details: ShipmentCustomDetailValue[],
  val: LocationValue,
  field_name: string
) => {
  if (Array.isArray(custom_details) && custom_details.length > 0) {
    const firstEntry = custom_details[0];
    if (!firstEntry.custom_clearance_location && !firstEntry.country) {
      form.setFieldsValue({
        [field_name]: [
          {
            ...firstEntry,
            country: val?.country_code,
          },
          ...custom_details.slice(1),
        ],
      });
    }
  }
};

const CustomClearanceShipmentRoutingDetailsComp = forwardRef(
  function CustomClearanceShipmentRoutingDetails(
    props: CustomClearanceShipmentRoutingDetailsProps,
    ref
  ) {
    const {
      disableNodes,
      onlyTranshipmentHopEditable,
      freightType,
      loadType,
      form,
      tradeType,
      shipment,
    } = props;

    const [showStuffing, setShowStuffing] = useState(true);
    const [showDeStuffing, setShowDeStuffing] = useState(true);
    const [showPreCarriage, setShowPreCarriage] = useState(true);
    const [showOnCarriage, setShowOnCarriage] = useState(true);
    const [showMovement, setShowMovement] = useState(true);
    const trade_type = tradeType || shipment?.trade_type;
    const freight_type = freightType || shipment?.freight_type;
    const portOfLoading = Form.useWatch('port_of_loading', form);
    const portOfDischarge = Form.useWatch('port_of_discharge', form);
    const movementType = Form.useWatch('movement_type', form);
    return (
      <Collapse
        activeKey={[
          showStuffing ? 'stuffing' : '',
          showPreCarriage ? 'pre_carriage' : '',
          'main_carriage',
          showOnCarriage ? 'on_carriage' : '',
          showDeStuffing ? 'destuffing' : '',
          showMovement ? 'movement' : '',
        ]}
        bordered={false}
        className="routing-detail-custom-collapse"
      >
        {freight_type === FREIGHT_TYPE_OCEAN && trade_type === TRADE_TYPE_EXPORT && (
          <Panel
            showArrow={false}
            collapsible={'disabled'}
            forceRender
            header={
              <Checkbox
                checked={showStuffing}
                disabled={disableNodes || onlyTranshipmentHopEditable}
                onChange={(e: any) => {
                  const checked = e.target.checked;
                  setShowStuffing(checked);
                }}
              >
                Stuffing Location
              </Checkbox>
            }
            key="stuffing"
          >
            {showStuffing && (
              <>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="Stuffing Location Type" name="stuffing_type">
                      <Radio.Group
                        options={stuffing_location_types(loadType as string)}
                        onChange={(val: any) => {
                          form.setFieldsValue({ stuffing_location: null });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item noStyle shouldUpdate={true}>
                      {({ getFieldValue }) => {
                        const destuffingType = getFieldValue('stuffing_type');
                        return (
                          <Form.Item name="stuffing_location" label="Stuffing Location">
                            {destuffingType === 'factory' ? (
                              <AddressCompanySearch />
                            ) : (
                              <GlobalSearch
                                doc_type="Global::Location"
                                searchProps={{
                                  type: [
                                    destuffingType === STUFFING_LOCATION_TYPE_CFS
                                      ? LOCATION_TYPE_CFS
                                      : LOCATION_TYPE_ICD,
                                  ],
                                }}
                              />
                            )}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Panel>
        )}

        {freight_type === FREIGHT_TYPE_OCEAN && (
          <Panel
            showArrow={false}
            collapsible={'disabled'}
            forceRender
            header={
              <Checkbox
                checked={showPreCarriage}
                disabled={disableNodes || onlyTranshipmentHopEditable}
                onChange={(e: any) => {
                  const checked = e.target.checked;
                  setShowPreCarriage(checked);
                }}
              >
                Pre carriage
              </Checkbox>
            }
            key="pre_carriage"
          >
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item name="place_of_carrier_receipt" label="Place Of Carrier Receipt">
                  <GlobalSearch
                    doc_type="Global::Location"
                    searchProps={{ type: ['ICD', 'Seaport'] }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        )}

        <Panel
          collapsible={'disabled'}
          forceRender
          showArrow={false}
          header={
            <Checkbox checked={true} disabled={false}>
              Main Carriage
            </Checkbox>
          }
          key="main_carriage"
        >
          {freight_type === FREIGHT_TYPE_AIR && (
            <>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      const ShippingBillDetails = getFieldValue('shipping_bill_details');
                      return (
                        <Form.Item name="port_of_loading" label="Port Of Loading">
                          <GlobalSearch
                            doc_type="Global::Location"
                            searchProps={{ type: ['Airport'] }}
                            disabled={false}
                            onChange={(val: any) => {
                              updateCustomDetails(
                                form,
                                ShippingBillDetails,
                                val,
                                'shipping_bill_details'
                              );
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="estimated_time_of_departure" label="ETD At Port Of Loading">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      const ImportCustomDetails = getFieldValue('import_custom_details');
                      return (
                        <Form.Item name="port_of_discharge" label="Port Of Discharge">
                          <GlobalSearch
                            doc_type="Global::Location"
                            searchProps={{ type: ['Airport'] }}
                            onChange={(val: any) => {
                              updateCustomDetails(
                                form,
                                ImportCustomDetails,
                                val,
                                'import_custom_details'
                              );
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="voyage_number" label="Flight #">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="estimated_time_of_arrival" label="ETA At Port Of Discharge">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                {trade_type === TRADE_TYPE_IMPORT && (
                  <Col span={6}>
                    <Form.Item name="actual_time_of_arrival" label="ATA At Port Of Discharge">
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </>
          )}
          {freight_type === FREIGHT_TYPE_OCEAN && (
            <>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      const placeOfCarrierReceipt = getFieldValue('place_of_carrier_receipt');
                      const ShippingBillDetails = getFieldValue('shipping_bill_details');
                      return (
                        <Form.Item name="port_of_loading" label="Port Of Loading">
                          <GlobalSearch
                            doc_type="Global::Location"
                            searchProps={{ type: ['Seaport'] }}
                            disabled={false}
                            onChange={(val: any) => {
                              if (!placeOfCarrierReceipt) {
                                form.setFieldsValue({ place_of_carrier_receipt: val });
                              }
                              updateCustomDetails(
                                form,
                                ShippingBillDetails,
                                val,
                                'shipping_bill_details'
                              );
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="POL Terminal" name="port_of_loading_terminal">
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{
                        type: ['PortTerminal'],
                        parent_id: portOfLoading?.location?.id,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="estimated_time_of_departure" label="ETD At Port Of Loading">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label="Vessel" name="ocean_vessel">
                    <VesselSearch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Voyage Number" name="voyage_number">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      const placeOfCarrierDelivery = getFieldValue('place_of_carrier_delivery');
                      const ImportCustomDetails = getFieldValue('import_custom_details');
                      return (
                        <Form.Item name="port_of_discharge" label="Port Of Discharge">
                          <GlobalSearch
                            doc_type="Global::Location"
                            searchProps={{ type: ['Seaport'] }}
                            onChange={(val: any) => {
                              if (!placeOfCarrierDelivery) {
                                form.setFieldsValue({ place_of_carrier_delivery: val });
                              }
                              updateCustomDetails(
                                form,
                                ImportCustomDetails,
                                val,
                                'import_custom_details'
                              );
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="POD Terminal" name="port_of_discharge_terminal">
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{
                        type: ['PortTerminal'],
                        parent_id: portOfDischarge?.location?.id,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="estimated_time_of_arrival" label="ETA At Port Of Discharge">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Panel>
        {freight_type === FREIGHT_TYPE_OCEAN && (
          <Panel
            collapsible={'disabled'}
            forceRender
            showArrow={false}
            header={
              <Checkbox
                checked={showOnCarriage}
                disabled={disableNodes || onlyTranshipmentHopEditable}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setShowOnCarriage(checked);
                }}
              >
                On Carriage
              </Checkbox>
            }
            key="on_carriage"
          >
            {showOnCarriage && (
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item name="place_of_carrier_delivery" label="Place Of Carrier Delivery">
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{ type: ['ICD', 'Seaport'] }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Panel>
        )}
        {freight_type === FREIGHT_TYPE_OCEAN && trade_type === TRADE_TYPE_IMPORT && (
          <Panel
            showArrow={false}
            collapsible={'disabled'}
            forceRender
            header={
              <Checkbox
                checked={showMovement}
                disabled={disableNodes || onlyTranshipmentHopEditable}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setShowMovement(checked);
                }}
              >
                Movement Location
              </Checkbox>
            }
            key="movement"
          >
            {showMovement && (
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label="Movement Type" name="movement_type" style={{ width: '100%' }}>
                    <Radio.Group
                      onChange={(val: any) => {
                        form.setFieldsValue({ movement_location: null });
                      }}
                      options={[
                        {
                          label: 'DPD',
                          value: 'dpd',
                        },
                        {
                          label: 'DPD-CFS',
                          value: 'dpd-cfs',
                        },
                        {
                          label: 'CFS',
                          value: 'cfs',
                        },
                        {
                          label: 'ICD',
                          value: 'icd',
                        },
                      ]}
                      disabled={false}
                    />
                  </Form.Item>
                </Col>
                {movementType && (
                  <Col span={6}>
                    <Form.Item noStyle shouldUpdate={true}>
                      {({ getFieldValue }) => {
                        const movementType = getFieldValue('movement_type');
                        let locationSearchType: LocationType | undefined = undefined;
                        if (
                          movementType === MOVEMENT_TYPE_DPD_CFS ||
                          movementType === MOVEMENT_TYPE_CFS
                        ) {
                          locationSearchType = LOCATION_TYPE_CFS;
                        } else if (movementType === MOVEMENT_TYPE_DPD) {
                          locationSearchType = LOCATION_TYPE_PORT_TERMINAL;
                        } else if (movementType === MOVEMENT_TYPE_ICD) {
                          locationSearchType = LOCATION_TYPE_ICD;
                        }
                        return (
                          <Form.Item name="movement_location" label="Movement Location">
                            <GlobalSearch
                              doc_type="Global::Location"
                              searchProps={{
                                type: locationSearchType ? [locationSearchType] : undefined,
                              }}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )}
          </Panel>
        )}
        {freight_type === FREIGHT_TYPE_OCEAN && trade_type === TRADE_TYPE_IMPORT && (
          <Panel
            showArrow={false}
            collapsible={'disabled'}
            forceRender
            header={
              <Checkbox
                checked={showDeStuffing}
                disabled={disableNodes || onlyTranshipmentHopEditable}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setShowDeStuffing(checked);
                }}
              >
                Destuffing Location
              </Checkbox>
            }
            key="destuffing"
          >
            {showDeStuffing && (
              <>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="Destuffing Location Type" name="destuffing_type">
                      <Radio.Group
                        options={stuffing_location_types(loadType as string)}
                        onChange={(val: any) => {
                          form.setFieldsValue({ destuffing_location: null });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item noStyle shouldUpdate={true}>
                      {({ getFieldValue }) => {
                        const destuffingType = getFieldValue('destuffing_type');
                        return (
                          <Form.Item name="destuffing_location" label="Destuffing Location">
                            {destuffingType === 'factory' ? (
                              <AddressCompanySearch />
                            ) : (
                              <GlobalSearch
                                doc_type="Global::Location"
                                searchProps={{
                                  type: [
                                    destuffingType === STUFFING_LOCATION_TYPE_CFS
                                      ? LOCATION_TYPE_CFS
                                      : LOCATION_TYPE_ICD,
                                  ],
                                }}
                              />
                            )}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Panel>
        )}
      </Collapse>
    );
  }
);

const CustomsClearanceShipmentRouting = React.memo(CustomClearanceShipmentRoutingDetailsComp);

export default CustomsClearanceShipmentRouting;
