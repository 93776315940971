import React, { useCallback, useEffect } from 'react';
import { message, CreditLimitCheckWrapper, CreditLimitCheckContext } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { transformPartyDetails } from 'operations/models/ShipmentParty';

import {
  BUSINESS_TYPE_DIRECT,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  SHIPMENT_TYPE_DIRECT,
  DOCUMENT_TYPE_MASTER,
  DOCUMENT_TYPE_HOUSE,
  DocumentType,
  TradeType,
} from 'operations/modules/shipment/constants';
import { CreateShipmentType } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import { CREATE_SHIPMENT_FROM_BR } from 'operations/modules//booking/graphql/bookingRequest';
import CreateShipmentFormContent, {
  CreateShipmentFormValue,
  CreateShipmentFormCommonProps,
  CreateShipmentFormBRValue,
} from '../CreateShipmentFormContent';
import { useSession } from 'common';
import { constants } from 'network';

interface CreateShipmentFromBookingRequestProps extends CreateShipmentFormCommonProps {
  shipmentType: CreateShipmentType;
  onClose: () => void;
  onSuccess?: () => void;
  bookingRequest: CreateShipmentFormBRValue;
}

const get_document_field = (documentType: DocumentType, shipmentType?: CreateShipmentType) => {
  if (!shipmentType) return {};
  if (documentType === DOCUMENT_TYPE_HOUSE || shipmentType === SHIPMENT_TYPE_DIRECT) {
    return {
      shipper_on_document: 'shipper',
      consignee_on_document: 'consignee',
    };
  } else {
    return {
      shipper_on_document: 'origin_agent',
      consignee_on_document: 'destination_agent',
    };
  }
};

const getCreateShipmentPayload = (
  values: CreateShipmentFormValue,
  bookingRequestId: string,
  tradeType?: TradeType
) => {
  const {
    shipment_type,
    job_date,
    customer,
    billing_party,
    party,
    master,
    house,
    final_place_of_delivery,
    destination_clearance,
    shipment_invoices,
    shipping_bill_details,
    import_custom_details,
    ...restVal
  } = values;
  const agentName =
    values.business_type === BOOKING_THROUGH_OVERSEAS_AGENT
      ? 'overseas_agent'
      : values.business_type || '';
  const agent_party =
    agentName && agentName !== BUSINESS_TYPE_DIRECT
      ? {
          [agentName]: customer,
        }
      : {};

  const payload = {
    booking_request_id: bookingRequestId,
    shipment: {
      ...restVal,
      shipment_type,
      job_date: job_date?.format('YYYY-MM-DD'),
      customer_company_id: billing_party?.party_company?.id,
      customer_address_id: billing_party?.party_address?.id,
      shipment_parties: transformPartyDetails(
        Object.assign({}, master?.party || {}, house?.party || {}, party, agent_party)
      ),
      final_place_of_delivery_id: final_place_of_delivery?.id,
      shipment_invoices: (shipment_invoices || []).map((si) => ({
        invoice_number: si.document_number,
        invoice_date: si.document_date?.format('YYYY-MM-DD'),
      })),
      shipment_custom_details: (shipping_bill_details || [])
        .concat(import_custom_details || [])
        .map((sb) => ({
          custom_document_number: sb.document_number,
          custom_document_date: sb.document_date?.format('YYYY-MM-DD'),
          country: sb.country || sb.custom_clearance_location?.country_code,
          custom_clearance_location_id: sb.custom_clearance_location?.id,
          trade_type: sb.trade_type,
        })),
      consignee_party_name:
        shipment_type === SHIPMENT_TYPE_DIRECT
          ? master?.consignee_party_name
          : house?.consignee_party_name,
    },
    shipment_document_master: {
      ...master?.shipment_document,
      ...get_document_field(DOCUMENT_TYPE_MASTER, shipment_type),
      document_status_event_date: master?.shipment_document?.document_status_event_date?.unix(),
      shipment_date: master?.shipment_document?.shipment_date?.unix(),
    },
    ...(shipment_type !== SHIPMENT_TYPE_DIRECT
      ? {
          shipment_document_house: {
            ...house?.shipment_document,
            ...get_document_field(DOCUMENT_TYPE_HOUSE, shipment_type),
            document_status_event_date:
              house?.shipment_document?.document_status_event_date?.unix(),
            shipment_date: house?.shipment_document?.shipment_date?.unix(),
          },
        }
      : {}),
  };

  return payload;
};

const CreateShipmentFromBookingRequest = React.memo(function CreateShipmentFromBookingRequest(
  props: CreateShipmentFromBookingRequestProps
): JSX.Element {
  const { shipmentType, onClose, onSuccess, bookingRequest, tradeType, ...restProps } = props;
  const sessionData = useSession();
  const [createShipmentFromBr, { data, loading, error }] = useMutation(CREATE_SHIPMENT_FROM_BR);

  useEffect(() => {
    const responseKey = 'create_shipment_from_br';
    if (!error && data?.[responseKey]) {
      message.success('Shipment Successfully Created !');
      onClose();
      const shipment_id = data?.create_shipment_from_br?.id;
      if (shipment_id) {
        window.open(
          `${process.env.OPERATIONS_URL}/view/shipment/${shipment_id}/documents?first_load=1`
        );
      }
      if (onSuccess) onSuccess();
    }
  }, [error, data, onClose, onSuccess, shipmentType]);
  const onFormSubmit = useCallback(
    (values: CreateShipmentFormValue, throw_error_on_credit_fail = true) => {
      const payload: any = getCreateShipmentPayload(values, bookingRequest.id, tradeType);
      if (sessionData.isFeatureEnabled(constants.CREDIT_CONTROL_FEATURE))
        payload['throw_error_on_credit_fail'] = throw_error_on_credit_fail;
      createShipmentFromBr({ variables: payload });
    },
    [bookingRequest.id, createShipmentFromBr, sessionData, tradeType]
  );
  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <CreditLimitCheckContext.Consumer>
        {(contextProps: any) => {
          return (
            <CreateShipmentFormContent
              {...restProps}
              bookingRequests={[bookingRequest]}
              shipmentType={shipmentType}
              onClose={onClose}
              showCreditPopupWithAction={contextProps?.showCreditPopupWithAction}
              onFormSubmit={onFormSubmit}
              error={error}
              loading={loading}
              tradeType={tradeType}
            />
          );
        }}
      </CreditLimitCheckContext.Consumer>
    </CreditLimitCheckWrapper>
  );
});

export default CreateShipmentFromBookingRequest;
