import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Action, PerformAction } from 'operations/modules/actionHelper/models';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import {
  ApartmentOutlined,
  ColorPickerTool,
  EditOutlined,
  GlobalSearch,
  hasPermission,
  StopOutlined,
} from '@shipmnts/pixel-hub';
import editInquiryFieldRenderer from './InquiryBulkActionRenderer';
import getTeamsBulkAction from '../teamsBulkActionHelper';
import { INQUIRY_CANCELED_STATUS } from 'operations/models/Inquiry';
import { PERMISSOIN_ADMIN } from 'operations/modules/reports/constants';
import { startCase } from 'lodash';
import { RenderEventsForm } from 'common';
import { SessionDataValue } from 'network/models/SessionData';
import { LostInquiryLayout } from 'sales_hub';

const EVENT_CANCELED = 'Inquiry Canceled';
const EVENT_WON = 'Won';
const EVENT_LOST = 'Lost';
const INQ_SYSTEM_EVENTS_ARR = [EVENT_CANCELED, EVENT_WON, EVENT_LOST];

/**
 * Generates a list of actions available for selected inquiries based on their status and the user's session permissions.
 * @param {RowNode[]} selectedNodes - The selected inquiries for bulk actions.
 * @param {SessionDataValue} session - The session data for the current user, including permissions.
 * @returns {Action[]} - A list of actions the user can perform on the selected inquiries.
 */
const getInquiryBulkAction = (selectedNodes: RowNode[], session: SessionDataValue) => {
  const { permissions } = session;
  if (selectedNodes.length === 0) return [];
  const selectedData = selectedNodes.map((node) => node.data);

  // Check if editing color is allowed based on inquiry status
  const isEditAllowed = selectedData.every(
    (data) => !INQ_SYSTEM_EVENTS_ARR.includes(data.inquiry_global_last_action_status)
  );

  // Check if marking as lost is allowed based on inquiry status
  const isLostAllowed = selectedData.every(
    (data) => ![EVENT_CANCELED, EVENT_LOST].includes(data.inquiry_global_last_action_status)
  );

  // Check if canceling is allowed based on inquiry status
  const isCancelAllowed = selectedData.every(
    (data) => ![EVENT_CANCELED, EVENT_WON].includes(data.inquiry_global_last_action_status)
  );

  // Check if all selected inquiries share the same "next actions"
  let nextActions: string[] = selectedData[0].inquiry_global_next_actions || [];
  selectedData.forEach((row) => {
    nextActions = (nextActions || []).filter((action: string) =>
      (row.inquiry_global_next_actions || []).includes(action)
    );
  });

  const actions: Action[] = [];

  // Edit color action
  actions.push(
    inquiryFieldEditAction('color_code', 'Edit Colour', ColorPickerTool, {
      value: '#FFFFFF',
    })
  );

  // Add teams action if editing is allowed
  if (isEditAllowed) actions.push(getTeamsBulkAction('SalesHub::Inquiry'));

  // Add collaborator action
  actions.push(
    inquiryFieldEditAction('collaborator_id', 'Add Collaborator', GlobalSearch, {
      doc_type: 'Network::UserContact',
    })
  );

  // Cancel inquiry action if user has admin permissions
  if (
    isCancelAllowed &&
    hasPermission(permissions, { name: PERMISSOIN_ADMIN, docType: 'SalesHub::Inquiry' })
  )
    actions.push(
      performInquiryWorkflowAction('cancel', 'Cancel Inquiry', {
        last_action_status: INQUIRY_CANCELED_STATUS,
      })
    );

  // Workflow actions based on "next actions" available for selected inquiries
  const childComponents: Action[] = (nextActions || []).map((action: string) => ({
    key: action,
    type: PRIMARY_TYPE,
    displayComponent: startCase(action),
    isEnable: true,
    performAction: performInquiryAction,
    description: action,
    extraProps: {
      event_name: action,
      ids: selectedNodes.map((n) => parseInt(n.data.inquiry_global_id)),
      sessionData: session,
      Komponent: RenderEventsForm,
      referenceType: 'SalesHub::InquiryGlobal',
    },
  }));

  // Workflow action
  actions.push({
    key: 'Workflow',
    type: PRIMARY_TYPE,
    icon: <ApartmentOutlined />,
    displayComponent: 'Workflow',
    isEnable: childComponents && childComponents.length > 0 && isEditAllowed,
    childComponents: childComponents,
  });

  // Mark inquiry as lost
  actions.push({
    key: 'lost',
    type: PRIMARY_TYPE,
    displayComponent: 'Mark Lost',
    isEnable:
      isEditAllowed ||
      (isLostAllowed &&
        hasPermission(permissions, { name: PERMISSOIN_ADMIN, docType: 'SalesHub::Inquiry' })),
    icon: <StopOutlined />,
    performAction: performInquiryAction,
    description: 'Mark Lost',
    extraProps: {
      event_name: 'lost',
      Komponent: LostInquiryLayout,
    },
  });

  return actions;
};

/**
 * Action creator for editing an inquiry field.
 * This function returns an action that renders a modal for editing a specified field.
 * @param {string} field_name - The name of the field to be edited.
 * @param {string} label - The label displayed for the action.
 * @param {React.ElementType} Komponent - The component used to edit the field.
 * @param {any} KomponentProps - Additional props to be passed to the component.
 * @returns {Action} - The action object that acts as payload for bulk mutation. (For actions where form is not required)
 */
const inquiryFieldEditAction = (
  field_name: string,
  label: string,
  Komponent: React.ElementType,
  KomponentProps: any = {}
): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <EditOutlined />,
    description: label,
    performAction: editInquiryFieldRenderer,
    isEnable: true,
    extraProps: {
      field_name: field_name,
      label: label,
      Komponent: Komponent,
      KomponentProps: KomponentProps,
    },
  };
};

/**
 * Action creator for performing an inquiry workflow action such as canceling.
 * @param {string} field_name - The name of the workflow action.
 * @param {string} label - The label for the action.
 * @param {any} actionValue - The value to be applied to the action.
 * @returns {Action} - The action object that acts as payload for bulk mutation. (For actions where form is not required)
 */
const performInquiryWorkflowAction = (
  field_name: string,
  label: string,
  actionValue: any
): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <StopOutlined />,
    description: label,
    performAction: editInquiryFieldRenderer,
    isEnable: true,
    extraProps: {
      field_name: field_name,
      label: label,
      actionValue: actionValue,
    },
  };
};

/**
 * Performs a bulk inquiry action, such as marking as lost or updating a field.
 * @param {RowNode[]} selectedNodes - The selected inquiries.
 * @param {() => void} onSuccess - Callback to execute after success.
 * @param {number} selectedNodeCounts - The number of selected inquiries.
 * @param {any} extraProps - Additional properties related to the action.
 * @returns {PerformAction} - The action performed on the selected inquiries.
 */
export const performInquiryAction = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any
): PerformAction => {
  return {
    actionParams: {
      id: extraProps?.ids || selectedNodes.map((n) => n.data.id),
      referenceType: extraProps?.referenceType,
      eventName: extraProps?.event_name,
      session: extraProps?.sessionData,
      onSuccess: onSuccess,
    },
    component: extraProps?.Komponent,
  };
};

export default getInquiryBulkAction;
