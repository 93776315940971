import React, { useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Action } from 'operations/modules/actionHelper/models';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import { EditOutlined, GlobalSearch, Select } from '@shipmnts/pixel-hub';
import { useLazyQuery } from '@apollo/client';
import { FETCH_COMPANY } from 'operations/modules/actionHelper/Companies/queries';
import editTicketFieldRenderer from './TicketBulkActionsRenderer';
import getPipelineActions from '../pipelineBulkActionHelper';

/**
 * Generates a list of bulk actions for selected ticket nodes.
 * These actions include editing ticket fields such as priority, assignee, and raised by company/user.
 * Additionally, pipeline actions are appended based on the selected nodes.
 */
const getTicketBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];
  const actions: Action[] = [];
  const selectedData = selectedNodes.map((node) => node.data);

  // Add action for editing ticket priority
  actions.push(
    ticketFieldEditAction('priority', 'Priority', Select, {
      options: [
        { label: 'High', value: 'High' },
        { label: 'Medium', value: 'Medium' },
        { label: 'Low', value: 'Low' },
      ],
      placeholder: 'Select Priority',
    })
  );

  // Add action for assigning ticket to a user
  actions.push(
    ticketFieldEditAction('assigned_to_id', 'Ticket Assignee', GlobalSearch, {
      doc_type: 'Network::UserContact',
    })
  );

  // Add action for setting the company that raised the ticket
  actions.push(
    ticketFieldEditAction('raised_by_company_id', 'Raised By Company', GlobalSearch, {
      doc_type: 'Network::Company',
    })
  );

  // Add action for setting the user that raised the ticket if all selected tickets have the same company
  if (
    selectedData[0]?.raised_by_company_id &&
    selectedData.every((data) => data.raised_by_company_id === selectedData[0].raised_by_company_id)
  ) {
    actions.push(
      ticketFieldEditAction('raised_by_user_id', 'Raised By User', RaisedByUser, {
        company_id: selectedData[0].raised_by_company_id,
      })
    );
  }

  // Add pipeline actions for tickets
  getPipelineActions('ServiceManagement::Ticket::Ticket', selectedNodes).forEach((action: Action) =>
    actions.push(action)
  );

  return actions;
};

/**
 * Helper function to create an action for editing a ticket field.
 * @param field_name - The name of the field to edit.
 * @param label - The label for the action.
 * @param Komponent - The component to render for editing the field.
 * @param KomponentProps - Additional props to pass to the component.
 */
const ticketFieldEditAction = (
  field_name: string,
  label: string,
  Komponent: React.ElementType,
  KomponentProps: any = {}
): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <EditOutlined />,
    description: label,
    performAction: editTicketFieldRenderer,
    isEnable: true,
    extraProps: {
      field_name: field_name,
      label: label,
      Komponent: Komponent,
      KomponentProps: KomponentProps,
    },
  };
};

/**
 * Component for selecting a user who raised the ticket.
 * Fetches the company data based on the provided company ID (i.e, raised_by_company_id).
 */
const RaisedByUser = React.memo(function RaisedByUser(props: {
  company_id: number | string;
  value?: any;
  onChange?: (value: any) => void;
}): JSX.Element {
  const { company_id } = props;
  const [company, setCompany] = React.useState<any>();
  const [fetchCompanyData, { data: companyData }] = useLazyQuery(FETCH_COMPANY, {
    fetchPolicy: 'no-cache',
  });

  // Fetch company data when company_id changes
  useEffect(() => {
    fetchCompanyData({
      variables: {
        id: company_id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  // Update the company state when new company data is fetched
  useEffect(() => {
    if (companyData) {
      setCompany(companyData.company);
    }
  }, [companyData]);

  return (
    <GlobalSearch
      value={props.value}
      onChange={props.onChange}
      doc_type="Network::UserContact"
      extraProps={{ company: company, showSelf: false }}
    />
  );
});

export default getTicketBulkActions;
