import { gql } from '@apollo/client';

export const BULK_UPSERT_TEAM_DETAILS = gql`
  mutation bulk_upsert_team_details(
    $ids: [ID!]!
    $reference_type: String!
    $input: TeamInputType!
  ) {
    bulk_upsert_team_details(ids: $ids, reference_type: $reference_type, input: $input) {
      id
    }
  }
`;

export const BULK_DISABLE_RESOURCE_TYPE = gql`
  mutation bulk_disable_resource_type($ids: [ID!]!, $doc_type: String!, $is_disabled: Boolean!) {
    bulk_disable_resource_type(ids: $ids, doc_type: $doc_type, is_disabled: $is_disabled)
  }
`;

export const BULK_UPDATE_TICKET_FIELD = gql`
  mutation bulk_update_ticket_field($ids: [ID!]!, $input: TicketBulkActionInputType) {
    bulk_update_ticket_field(ids: $ids, input: $input) {
      id
    }
  }
`;

export const BULK_UPSERT_COMPANY_FIELD = gql`
  mutation bulk_upsert_company_field($ids: [ID!]!, $input: BulkUpsertCompanyInputType) {
    bulk_upsert_company_field(ids: $ids, input: $input) {
      id
    }
  }
`;

export const BULK_UPSERT_INQUIRY_FIELD = gql`
  mutation bulk_upsert_inquiry_field($ids: [ID!]!, $input: BulkUpsertInquiryInputType) {
    bulk_upsert_inquiry_field(ids: $ids, input: $input) {
      id
    }
  }
`;
