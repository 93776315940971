import React, { useMemo, useCallback, useState, useEffect } from 'react';
import SummaryCard from './SummaryCard';
import { Divider, Form, FormTable, Skeleton, Tabs, TagsRender } from '@shipmnts/pixel-hub';
import RateExplorerFilter from './RateExplorerFilter';
import { getQuoteTableDate, getRateExplorerColumn } from 'sales_hub/utils/utils';
import { RateExplorerData, RateExplorerResourceType } from './RateExplorer.type';
import ResourceDetails from './ResourceDetails';
import { useSession } from 'common';
import { useLazyQuery } from '@apollo/client';
import { GET_RATE_EXPLORER_DATA } from 'sales_hub/graphql/inquiry';
import { getDefaultFilters, getInitialFilters, getRateExplorerResouce } from './helper';

interface RateExplorerLayoutProps {
  resource: RateExplorerResourceType;
  onSelection?: (param: any) => void;
}

export default function RateExplorerLayout(props: RateExplorerLayoutProps) {
  // props & hooks
  const { resource, onSelection } = props;
  const [filterForm] = Form.useForm();
  const sessionData = useSession();

  // states
  const [activeTab, setActiveTab] = useState('live');
  const [firstLoad, setFirstLoad] = useState(false);
  const [rowData, setRowData] = useState<RateExplorerData[]>([]);
  const [resourceItem, setResourceItem] = useState<RateExplorerData | undefined>();
  const [openResourceDetails, setOpenResourceDetails] = useState(false);

  // constants
  const resource_obj = getRateExplorerResouce(resource);

  // query
  const [getRateExplorerData, { data: rateExploreData, loading: loadingData }] =
    useLazyQuery(GET_RATE_EXPLORER_DATA);

  const columns = useMemo(
    () =>
      getRateExplorerColumn(
        rateExploreData?.get_rate_explorer_data,
        resource_obj,
        sessionData,
        (v) => {
          setResourceItem(v);
          setOpenResourceDetails(true);
        }
      ),
    [rateExploreData?.get_rate_explorer_data, resource_obj, setResourceItem, sessionData]
  );

  useEffect(() => {
    if (rateExploreData?.get_rate_explorer_data) {
      setRowData(getQuoteTableDate(rateExploreData.get_rate_explorer_data));
    }
  }, [rateExploreData?.get_rate_explorer_data]);

  useEffect(() => {
    setFirstLoad(true);
    return () => {
      setFirstLoad(false);
    };
  }, []);

  useEffect(() => {
    if (
      firstLoad &&
      rateExploreData?.get_rate_explorer_data?.length === 0 &&
      activeTab === 'live'
    ) {
      setActiveTab('historical');
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateExploreData]);

  const fetchData = useCallback(
    (variables: any) => {
      getRateExplorerData({
        variables: {
          ...variables,
          filters: { type: activeTab, ...variables.filters },
        },
      });
    },
    [activeTab, getRateExplorerData]
  );

  const initialFilter = useMemo(() => {
    const initial = getInitialFilters(resource);
    return {
      ...initial,
      filters: {
        ...initial.filters,
        rate_type: activeTab === 'live' ? ['quote'] : ['quote', 'job'],
      },
      sort: [{ direction: 'desc' }],
    };
  }, [resource, activeTab]);

  const defaultFilter = useMemo(() => getDefaultFilters(resource), [resource]);

  return (
    <div
      style={{
        height: '80vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      className={'custom-scrollbar'} // to hide scrollbar
    >
      <SummaryCard resource={resource} />
      <Divider style={{ margin: '10px 0px' }} />
      <Tabs
        activeKey={activeTab}
        onTabClick={(key: string) => {
          setActiveTab(key);
          setRowData([]);
        }}
        items={[
          { key: 'live', label: 'Live Rates' },
          { key: 'historical', label: 'Historical Rates' },
        ]}
      ></Tabs>
      <RateExplorerFilter
        form={filterForm}
        fetchData={fetchData}
        resource={resource_obj}
        initialFormValue={initialFilter}
        defaultFilter={defaultFilter}
      />
      {!!resource && !!resourceItem && (
        <ResourceDetails
          onClose={() => setOpenResourceDetails(false)}
          resourceItem={resourceItem}
          resource={resource}
          open={openResourceDetails}
        />
      )}
      {!loadingData && (
        <div
          style={{ marginBlock: '2em', flexGrow: '1', overflowY: 'scroll' }}
          className={'custom-scrollbar'}
        >
          <FormTable
            rowData={rowData}
            columns={columns}
            height={rowData.length * 40 + 120}
            reportName="inquiry_options"
            checkbox_always_visible={false}
            showCheckBoxOnHeader
            rowSelection="multiple"
            onSelectionChanged={(params: any) => {
              const selectedRows = params.api?.getSelectedRows() || [];
              if (onSelection) onSelection(selectedRows);
            }}
            reportConfig={{
              rowMultiSelectWithClick: true,
              rowHeight: 40,
              defaultColDef: {
                suppressMovable: true,
              },
              components: { TagsRender },
            }}
          />
        </div>
      )}
      {loadingData && <Skeleton paragraph={{ rows: 10 }} />}
    </div>
  );
}
