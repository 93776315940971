import { Form, GlobalSearch, RichTextEditorWrapper } from '@shipmnts/pixel-hub';
import React from 'react';

export default function BookingTermsAndConditions() {
  return (
    <>
      <Form.Item
        labelCol={{ span: 24 }}
        name="terms_and_condition"
        label="Terms And Condition"
        style={{ width: '100%' }}
      >
        <GlobalSearch
          doc_type="Network::Template"
          disabled={false}
          searchProps={{
            template_type: ['terms_and_condition'],
            resource_type: 'Shipment::OceanTransportOrder',
          }}
          extraProps={{
            prefillDefault: true,
          }}
        />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        name="terms_and_condition_description"
        label="Terms And Condition Description"
        style={{ width: '100%' }}
      >
        <RichTextEditorWrapper
          toolbarOptions={{
            container: [
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ color: [] }, { background: [] }],
              ['emoji'],
              [{ indent: '-1' }, { indent: '+1' }],
            ],
          }}
        />
      </Form.Item>
    </>
  );
}
