import {
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  message,
  DrawerFooter,
  InputNumber,
  Card,
  Layout,
  Skeleton,
  FormOutlined,
  Space,
  Button,
  AppHelmet,
  ViewTableContext,
  Checkbox,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { omit as _omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  FETCH_WAREHOUSE_UNIT,
  CREATE_WAREHOUSE_UNIT,
  UPDATE_WAREHOUSE_UNIT,
} from './graphql/warehouseUnit';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  NODE_TYPE_WAREHOUSE,
  NODE_TYPE_STORAGE,
  NODE_TYPE_BIN,
  NODE_TYPE_RACK,
  NODE_TYPE_SHELF,
  WarehouseUnitValue,
} from 'operations/models/WarehouseUnit';
import { useParams } from 'wouter';

const { Option } = Select;

const renderInputWithUnit = (
  key: string | Array<string>,
  unit_key: string | Array<string>,
  label: string,
  options: Array<{ key: string; name: string }>,
  disabledUnitKey?: boolean,
  inputNumberProps?: object,
  defaultSelect?: string
) => {
  return (
    <Input.Group compact style={{ width: '100%' }}>
      <Form.Item name={key} label={label} style={{ width: '70%' }}>
        <InputNumber style={{ width: '100%' }} placeholder={label} {...inputNumberProps} />
      </Form.Item>
      <Form.Item name={unit_key} label="Unit" style={{ width: '30%' }}>
        <Select disabled={disabledUnitKey} defaultValue={defaultSelect}>
          {options.map((unit) => (
            <Option key={unit.key} value={unit.key}>
              {unit.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Input.Group>
  );
};

interface CreateWarehouseUnitProps {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  id?: string;
  parentData?: WarehouseUnitValue;
  editable?: boolean;
  type?: 'warehouse' | 'storage' | 'rack' | 'shelf' | 'bin' | undefined;
  formType?: 'new' | 'edit' | 'add';
  onSuccess?: () => void;
  refetch?: () => void;
}

const CreateWarehouseForm = (props: any) => {
  const { parentData, formType = 'new', editable = true, type = 'warehouse', form } = props;
  const id = parentData?.id;
  const { refetchReport } = useContext<any>(ViewTableContext);

  const session = useSession();

  let node_type_options = [
    { label: 'Warehouse', value: NODE_TYPE_WAREHOUSE },
    { label: 'Storage', value: NODE_TYPE_STORAGE },
    { label: 'Rack', value: NODE_TYPE_RACK },
    { label: 'Shelf', value: NODE_TYPE_SHELF },
    { label: 'Bin', value: NODE_TYPE_BIN },
  ];

  if (type !== 'warehouse' || (type === 'warehouse' && formType === 'add')) {
    node_type_options = node_type_options.filter(
      (option) => !(option.value === NODE_TYPE_WAREHOUSE)
    );
  }
  const warehouse_type = [
    { label: 'Fulfillment Center', value: 'fulfillment_center' },
    { label: 'FTZ', value: 'ftz' },
    { label: 'Bonded', value: 'bonded' },
    { label: 'CFS', value: 'cfs' },
  ];
  const type_of_storage = [
    { label: 'General', value: 'general' },
    { label: 'Perishable', value: 'perishable' },
    { label: 'Temp-Controlled', value: 'temp_controlled' },
    { label: 'Hazardous', value: 'hazardous' },
  ];
  const weight_unit_options = [
    { key: 'kgs', name: 'kgs' },
    { key: 'tons', name: 'tons' },
    { key: 'lbs', name: 'lbs' },
  ];
  const volume_unit_options = [{ key: 'CBM', name: 'CBM' }];
  const storage_area_unit_options = [{ key: 'SqFt', name: 'SqFt' }];

  const getRequiredLabel = (label: string | undefined) => {
    return (
      <>
        <span style={{ color: '#D4380D' }}>{label}</span>
      </>
    );
  };

  const autoGenerateCode = (name: string) => {
    const words = name?.trim().split(/[ \-_/]+/);
    let code = '';
    words?.forEach((word: string) => {
      code += word[0];
    });
    return code?.toUpperCase();
  };

  const [createUnit, { data }] = useMutation(CREATE_WAREHOUSE_UNIT);
  const [updateUnit, { data: updateUnitData }] = useMutation(UPDATE_WAREHOUSE_UNIT);

  useEffect(() => {
    if (!!data?.create_warehouse_unit?.id) {
      if (data.create_warehouse_unit.node_type === NODE_TYPE_WAREHOUSE) {
        message.success('Warehouse Created Successfully');
      } else message.success('Location Created Successfully');
      props.closeDrawer && props.closeDrawer();
      props.refetch && props.refetch();
      props.onSuccess && props.onSuccess();
      props.onClose && props.onClose();
      refetchReport && refetchReport();
    }
    if (!!updateUnitData?.update_warehouse_unit?.id) {
      if (updateUnitData.update_warehouse_unit.node_type === NODE_TYPE_WAREHOUSE) {
        message.success('Warehouse Updated Successfully');
      } else message.success('Location Updated Successfully');
      props.closeDrawer && props.closeDrawer();
      props.refetch && props.refetch();
      props.onSuccess && props.onSuccess();
      props.onClose && props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updateUnitData]);

  const getFormInitialValues = () => {
    let initialValue: any = {
      max_gross_weight_unit: 'kgs',
      max_volume_unit: 'CBM',
      max_storage_area_unit: 'sqft',
      warehouse_type: 'fulfillment_center',
      type_of_storage: 'general',
      node_type: type,
    };
    if (formType === 'add') {
      initialValue = {
        ...initialValue,
        node_type: type === 'warehouse' ? 'storage' : type,
        branch: parentData?.branch,
        parent_unit: parentData,
      };
    }
    if (formType === 'edit') {
      initialValue = {
        ...initialValue,
        ...parentData,
        branch: parentData?.branch,
      };
    }
    return initialValue;
  };
  return (
    <Form
      disabled={!editable}
      form={form}
      layout={'vertical'}
      initialValues={getFormInitialValues()}
      onValuesChange={(changedValues, _allValues) => {
        if ('parent_unit' in changedValues || 'node_name' in changedValues) {
          if (_allValues.parent_unit)
            form.setFieldValue(
              'node_code',
              `${_allValues.parent_unit.node_code}-${autoGenerateCode(_allValues.node_name)}`
            );
          else form.setFieldValue('node_code', `${autoGenerateCode(_allValues.node_name)}`);
        }
      }}
      onFinish={(values: any) => {
        const payload = {
          ..._omit(values, ['branch', 'parent_unit', 'parent_unit', 'storage_capacity']),
          storage_capacity: {
            ...values?.storage_capacity,
            max_gross_weight_unit: values?.storage_capacity?.max_gross_weight_unit || 'kgs',
            max_storage_area_unit: values?.storage_capacity?.max_storage_area_unit || 'SqFt',
            max_volume_unit: values?.storage_capacity?.max_volume_unit || 'CBM',
          },
          branch_id: values?.branch?.id,
          parent_unit_id: values?.parent_unit?.id,
        };
        if (formType === 'edit' && !!id) {
          updateUnit({
            variables: {
              id: id,
              warehouse_unit: {
                ..._omit(payload, [
                  'default_receiving_location',
                  'default_delivery_location',
                  'default_damage_location',
                ]),
                default_receiving_location_id: values?.default_receiving_location?.id,
                default_delivery_location_id: values?.default_delivery_location?.id,
                default_damage_location_id: values?.default_damage_location?.id,
              },
            },
          });
        } else
          createUnit({
            variables: {
              warehouse_unit: {
                ...payload,
              },
            },
          });
      }}
      style={{ paddingBottom: '20px' }}
    >
      <Card
        style={{ marginBottom: '10px' }}
        title={<span style={{ color: '#0a4fca' }}>Basic Detail</span>}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="node_name"
              label={getRequiredLabel('Location Name')}
              required={true}
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Location Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="node_type"
              label={getRequiredLabel('Location Type')}
              required={true}
              rules={[{ required: true }]}
            >
              <Select
                options={node_type_options}
                placeholder="Select Location Type"
                disabled={type === 'warehouse' && formType !== 'add'}
              />
            </Form.Item>
          </Col>
          <Form.Item noStyle dependencies={['node_type']}>
            {({ getFieldValue }) => {
              const node_type = getFieldValue('node_type');
              if (node_type === NODE_TYPE_WAREHOUSE) {
                return (
                  <Col span={12}>
                    <Form.Item
                      name="warehouse_type"
                      label={'Warehouse Type'}
                      style={{ marginBottom: '5px' }}
                    >
                      <Select options={warehouse_type} placeholder="Select Warehouse Type" />
                    </Form.Item>
                    <Form.Item noStyle dependencies={['warehouse_type']}>
                      {({ getFieldValue }) => {
                        const warehouse_type = getFieldValue('warehouse_type');
                        if (warehouse_type !== 'cfs') {
                          return (
                            <Form.Item
                              valuePropName="checked"
                              name="is_two_step"
                              style={{ marginBottom: '5px' }}
                            >
                              <Checkbox style={{ width: '100%' }}>
                                2 Step Receipt & Delivery
                              </Checkbox>
                            </Form.Item>
                          );
                        }
                        return <></>;
                      }}
                    </Form.Item>
                  </Col>
                );
              }
              return <></>;
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['node_type']}>
            {({ getFieldValue }) => {
              const node_type = getFieldValue('node_type');
              if (node_type === NODE_TYPE_STORAGE) {
                return (
                  <Col span={12}>
                    <Form.Item name="type_of_storage" label={'Type Of Storage'}>
                      <Select options={type_of_storage} placeholder="Select Type of Storage" />
                    </Form.Item>
                  </Col>
                );
              }
              return <></>;
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={['warehouse_type', 'node_type']}>
            {({ getFieldValue }) => {
              const warehouse_type = getFieldValue('warehouse_type');
              const node_type = getFieldValue('node_type');
              if (
                node_type === NODE_TYPE_WAREHOUSE &&
                (warehouse_type === 'ftz' || warehouse_type === 'bonded')
              ) {
                return (
                  <Col span={12}>
                    <Form.Item label={'Custom’s Registration Code #'} name={'customs_ref_code'}>
                      <Input placeholder="Enter Custom’s Registration Code #" />
                    </Form.Item>
                  </Col>
                );
              }
              return <></>;
            }}
          </Form.Item>
          <Col span={12}>
            <Form.Item
              name="branch"
              label={getRequiredLabel('Linked To Branch')}
              required={true}
              rules={[{ required: true }]}
            >
              <GlobalSearch
                doc_type="Network::BranchAccount"
                disabled={formType !== 'new'}
                extraProps={{
                  options: session.branch_accounts,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="node_code"
              label={getRequiredLabel('Location Code')}
              required={true}
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Location Code" />
            </Form.Item>
          </Col>
          {/* <Col span={12}> */}
          <Form.Item noStyle dependencies={['node_type', 'branch']}>
            {({ getFieldValue }) => {
              const node_type = getFieldValue('node_type');
              const branch_id = getFieldValue('branch')?.id;
              if (node_type === NODE_TYPE_WAREHOUSE) return <></>;
              return (
                <Col span={12}>
                  <Form.Item
                    name="parent_unit"
                    label={getRequiredLabel('Parent Location')}
                    required={true}
                    rules={[{ required: true }]}
                  >
                    <GlobalSearch
                      doc_type="Wms::WarehouseUnit"
                      extraProps={{ branch_ids: [branch_id] }}
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>
          {/* </Col> */}
          <Col span={12}>
            <Form.Item noStyle dependencies={['node_type', 'branch']}>
              {({ getFieldValue }) => {
                const node_type = getFieldValue('node_type');
                const branch_id = getFieldValue('branch')?.id;
                if (node_type !== NODE_TYPE_WAREHOUSE) return <></>;
                if (formType === 'new') {
                  return <></>;
                }
                return (
                  <Form.Item
                    name="default_receiving_location"
                    label={getRequiredLabel('Default Receiving Location')}
                    required={true}
                    rules={[{ required: true }]}
                  >
                    <GlobalSearch
                      doc_type="Wms::WarehouseUnit"
                      extraProps={{ branch_ids: [branch_id] }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item noStyle dependencies={['node_type', 'branch']}>
              {({ getFieldValue }) => {
                const node_type = getFieldValue('node_type');
                const branch_id = getFieldValue('branch')?.id;
                if (node_type !== NODE_TYPE_WAREHOUSE) return <></>;
                if (formType === 'new') {
                  return <></>;
                }
                return (
                  <Form.Item
                    name="default_delivery_location"
                    label={getRequiredLabel('Default Delivery Location')}
                    required={true}
                    rules={[{ required: true }]}
                  >
                    <GlobalSearch
                      doc_type="Wms::WarehouseUnit"
                      extraProps={{ branch_ids: [branch_id] }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item noStyle dependencies={['node_type', 'branch']}>
              {({ getFieldValue }) => {
                const node_type = getFieldValue('node_type');
                const branch_id = getFieldValue('branch')?.id;
                if (node_type !== NODE_TYPE_WAREHOUSE) return <></>;
                if (formType === 'new') {
                  return <></>;
                }
                return (
                  <Form.Item
                    name="default_damage_location"
                    label={getRequiredLabel('Default Damage Location')}
                    required={true}
                    rules={[{ required: true }]}
                  >
                    <GlobalSearch
                      doc_type="Wms::WarehouseUnit"
                      extraProps={{ branch_ids: [branch_id] }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Form.Item noStyle dependencies={['node_type']}>
        {({ getFieldValue }) => {
          const node_type = getFieldValue('node_type');
          if (node_type === NODE_TYPE_WAREHOUSE || node_type === NODE_TYPE_STORAGE) {
            return (
              <Card
                style={{ marginBottom: '10px' }}
                title={<span style={{ color: '#0a4fca' }}>Storage Capacity</span>}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    {renderInputWithUnit(
                      ['storage_capacity', 'max_gross_weight'],
                      ['storage_capacity', 'max_gross_weight_unit'],
                      'Max Gross Weight',
                      weight_unit_options,
                      false,
                      { min: 0 },
                      'kgs'
                    )}
                  </Col>
                  <Col span={12}>
                    {renderInputWithUnit(
                      ['storage_capacity', 'max_volume'],
                      ['storage_capacity', 'max_volume_unit'],
                      'Max Volume',
                      volume_unit_options,
                      false,
                      { min: 0 },
                      'CBM'
                    )}
                  </Col>
                  <Col span={12}>
                    {renderInputWithUnit(
                      ['storage_capacity', 'max_storage_area'],
                      ['storage_capacity', 'max_storage_area_unit'],
                      'Max Storage Area',
                      storage_area_unit_options,
                      false,
                      { min: 0 },
                      'sqft'
                    )}
                  </Col>
                  <Col span={12}>
                    <Form.Item noStyle dependencies={['node_type', 'warehouse_type']}>
                      {({ getFieldValue }) => {
                        const node_type = getFieldValue('node_type');
                        const warehouse_type = getFieldValue('warehouse_type');
                        if (node_type !== NODE_TYPE_WAREHOUSE && node_type !== NODE_TYPE_STORAGE)
                          return <></>;
                        if (node_type === NODE_TYPE_WAREHOUSE && warehouse_type !== 'cfs')
                          return <></>;
                        return (
                          <Form.Item
                            name={['storage_capacity', 'teu']}
                            label={'TEU (Twenty Foot Equivalent Unit)'}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder={'Enter TEU'}
                              min={0}
                              precision={0}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            );
          }
          return <></>;
        }}
      </Form.Item>
    </Form>
  );
};

export default function CreateWarehouseUnit(props: CreateWarehouseUnitProps) {
  const { visible, setVisible, formType = 'new', editable = true } = props;
  const [form] = Form.useForm();

  const closeDrawer = () => {
    form.resetFields();
    setVisible && setVisible(false);
    if (props.onSuccess) props.onSuccess();
  };

  return (
    <Drawer
      open={visible}
      onClose={closeDrawer}
      title={formType === 'edit' ? 'Update Location' : 'Create Location'}
      width="60%"
      footer={
        editable && (
          <DrawerFooter
            saveText={formType === 'edit' ? 'Update' : 'Create'}
            onSave={() => {
              form.submit();
            }}
            onClose={closeDrawer}
          />
        )
      }
    >
      <CreateWarehouseForm form={form} closeDrawer={closeDrawer} {...props} />
    </Drawer>
  );
}

export function CreateWarehouseFormLayout(props: any) {
  const { onClose, externalLink } = props;

  const [fetchWMSWarehouseUnit, { data, error, loading }] = useLazyQuery(FETCH_WAREHOUSE_UNIT, {
    fetchPolicy: 'no-cache',
  });
  const params = useParams<{ id: string }>();
  const id = props?.id || params?.id;
  const { Header, Content, Footer } = Layout;
  const [form] = Form.useForm();
  const [warehouseUnit, setWarehouseUnit] = useState<WarehouseUnitValue>();
  useEffect(() => {
    if (id !== 'new') fetchWMSWarehouseUnit({ variables: { id: id } });
  }, [fetchWMSWarehouseUnit, id]);

  useEffect(() => {
    if (error) message.error(error?.message);
    if (data) setWarehouseUnit(data?.get_warehouse_unit);
  }, [error, data]);

  const getTitle = () => {
    return id ? 'Edit Location' : 'Create Location';
  };

  return (
    <>
      {loading && <Skeleton />}
      {warehouseUnit && !loading && (
        <Layout style={{ minHeight: '100vh' }}>
          <AppHelmet
            titleTemplate={`#${warehouseUnit?.node_name || ''} | Warehouse | Master [WMS]`}
          />
          <Header
            style={{
              backgroundColor: 'white',
              padding: '0 16px',
              display: 'flex',
              alignItems: 'center',
              zIndex: 1,
              width: '100%',
              position: 'fixed',
              borderBottom: '1px solid #ddd',
            }}
          >
            <div>
              <FormOutlined style={{ fontSize: '18px' }} /> &nbsp;{' '}
              <span style={{ fontWeight: '24px', fontSize: '16px' }}>{getTitle()}</span>
            </div>
          </Header>
          <Content
            style={{
              padding: '0 16px',
              marginTop: 74,
              marginBottom: 64,
            }}
          >
            {warehouseUnit && !loading && (
              <CreateWarehouseForm
                {...props}
                parentData={warehouseUnit}
                type={warehouseUnit.node_type}
                formType={'edit'}
                form={form}
              />
            )}
          </Content>
          <Footer
            style={{
              padding: '16px',
              position: 'fixed',
              bottom: 0,
              width: externalLink ? '85%' : '100%',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid #ddd',
            }}
          >
            <Space>
              <Button type="primary" htmlType="submit" onClick={form.submit}>
                {id ? 'Update' : 'Create'}
              </Button>
              <Button type="default" onClick={onClose}>
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      )}
    </>
  );
}
