import { gql } from '@apollo/client';

export const invoiceAddressFields = gql`
  fragment invoiceAddressFields on AddressObjectType {
    name
    address_line_1
    address_line_2
    state_tax_code
    postal_code
    entity_type
    company_id
    id
    print_address
    state_code
    state_name
    city_name
    city {
      id
      name
      city_code
    }
    country_code
    country_name
  }
`;

export const branchAccountFields = gql`
  fragment branchAccountFields on BranchAccount {
    id
    name
    entity_type
    default_address {
      ...invoiceAddressFields
    }
    address_types
    city {
      name
      code
    }
    state {
      name
      code
    }
    country {
      name
      code
    }
    company_account_id
    postal_code
    print_address
    state_tax_code
    tax_registration_number
    tax_registration_type
    erp_cost_center_id
  }
  ${invoiceAddressFields}
`;

export const GET_USER_CONTACTS = gql`
  query user_contact_search($query: String!, $reference_id: [String!]!) {
    user_contact_search(query: $query, reference_id: $reference_id) {
      department
      email
      name
      first_name
      id
      last_name
      mobile_number
      branch_accounts {
        ...branchAccountFields
      }
      companies {
        registered_name
      }
    }
  }
  ${branchAccountFields}
`;

export const GET_DASHBOARDS = gql`
  query get_dashboards($dashboard_type: String) {
    get_dashboards(dashboard_type: $dashboard_type) {
      id
      title
      superset_dashboard_id
      primary_doctype
    }
  }
`;

export const GET_DASHBOARD = gql`
  query get_dashboard($id: ID!) {
    get_dashboard(id: $id) {
      id
      title
      superset_dashboard_id
      primary_doctype
    }
  }
`;
