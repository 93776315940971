import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Drawer,
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  FormTable,
  Form,
  FormInstance,
  FormListFieldData,
  DrawerFooter,
} from '@shipmnts/pixel-hub';

import {
  DIMENSION_CMS,
  DIMENTION_INCH,
  WEIGHT_UNIT_KGS,
  WEIGHT_UNIT_MTS,
} from 'operations/baseConstants';
import { GridOptions, RowNode, ValueSetterParams } from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import allFields from 'operations/modules/shipment/components/ShipmentForm/allFields';

interface PackageDetailsProps {
  form: FormInstance;
  cargoField: FormListFieldData;
  rowIndex: number;
  node?: RowNode<any>;
  setCargos: React.Dispatch<React.SetStateAction<any[]>>;
  cargos: any[];
  disabled?: boolean;
}

const dimensionConversion: { [key: string]: number } = {
  [DIMENSION_CMS]: 1,
  [DIMENTION_INCH]: 2.54,
};

const PackageDetailsDrawerForm = function (props: PackageDetailsProps) {
  const { cargos, setCargos, disabled } = props;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  const components = {
    DateEditor,
    PackageDetailsDrawerForm,
    StringEditor,
    FloatEditor,
    EnumEditor,
  };
  const cargoIndex = props.rowIndex;

  const [packageDetails, setPackageDetails] = useState([{}]);
  useEffect(() => {
    const packageDetails =
      cargos && cargoIndex !== undefined && cargos?.[cargoIndex || 0]?.shipment_packages
        ? cargos[cargoIndex].shipment_packages
        : [{}];
    setPackageDetails(packageDetails);
  }, [cargos, cargoIndex]);

  const packageValidation = (data: any) => {
    const errors: { [key: string]: string } = {};
    if (!data?.type_of_package) errors['type_of_package'] = 'Package Type is required.';
    if (!data?.total_number_of_packages)
      errors['total_number_of_packages'] = 'No. of pieces should be greater then 0.';
    if (!data?.length) errors['length'] = 'Length should be greater then 0.';
    if (!data?.width) errors['width'] = 'Width should be greater then 0.';
    if (!data?.height) errors['height'] = 'Height should be greater then 0.';
    if (!data?.per_piece_weight)
      errors['per_piece_weight'] = 'Per piece Wt. should be greater then 0.';
    return errors;
  };

  const onSavePackages = () => {
    const updatedPackages: any[] = [];
    let packages_sum = 0;
    let gross_weight = 0;
    let gross_volume = 0;
    let requiredNotSet = false;
    let weight_unit: string;

    const updatePackageDetails: any[] = [];
    gridRef.current?.api?.forEachNode((node) => {
      const data = node?.data || {};
      // all required value not set then continue next
      if (
        !data?.type_of_package &&
        !data?.total_number_of_packages &&
        !data?.length &&
        !data?.width &&
        !data?.height &&
        !data?.per_piece_weight
      ) {
        return;
      }

      // check all required should be set
      if (
        !data?.type_of_package ||
        !data?.total_number_of_packages ||
        !data?.length ||
        !data?.width ||
        !data?.height ||
        !data?.per_piece_weight
      ) {
        updatePackageDetails.push({ ...data, errors: packageValidation(data) });
        requiredNotSet = true;
        return;
      }

      weight_unit = data.weight_unit;
      updatedPackages.push(node.data);
      packages_sum += node?.data?.total_number_of_packages || 0;
      gross_weight +=
        (node?.data?.total_number_of_packages || 0) * (node?.data?.per_piece_weight || 0);
      const dimensionUnit = node.data?.dimension_unit || DIMENSION_CMS;
      if (dimensionUnit) {
        gross_volume +=
          (node?.data?.total_number_of_packages || 0) *
          ((node?.data?.length || 0) * dimensionConversion[dimensionUnit]) *
          ((node?.data?.width || 0) * dimensionConversion[dimensionUnit]) *
          ((node?.data?.height || 0) * dimensionConversion[dimensionUnit]);
      }
    });

    setPackageDetails(updatePackageDetails);
    if (requiredNotSet) return;
    gross_volume = gross_volume / 1000000;

    if (updatedPackages.length) {
      const package_type = updatedPackages[0]?.type_of_package;
      const updatedCargos = cargos?.map((cargo: any, index: number) => {
        if (index === cargoIndex) {
          return {
            ...cargo,
            shipment_packages: updatedPackages,
            gross_volume,
            total_packages: packages_sum,
            gross_weight,
            package_type,
            weight_unit: weight_unit || cargo.weight_unit,
          };
        }
        return {
          ...cargo,
          weight_unit: weight_unit || cargo.weight_unit,
          shipment_packages: cargo?.shipment_packages?.map((pkg: { weight_unit: string }) => ({
            ...pkg,
            weight_unit: weight_unit || pkg.weight_unit,
          })),
        };
      });
      setCargos(updatedCargos);
    }

    setOpenDrawer(false);
  };

  const gridRef = useRef<GridOptions>();
  const columnDefs: Column[] = [
    allFields.type_of_package(),
    allFields.total_number_of_packages(),
    allFields.length(),
    allFields.width(),
    allFields.height(),
    allFields.dimension_unit(),
    allFields.per_piece_weight(),

    allFields.weight_unit({
      minWidth: 200,
      editable: true,

      valueGetter: (params: { data: { weight_unit: any } }) => {
        if (!params.data.weight_unit) return WEIGHT_UNIT_KGS;
        return params.data.weight_unit;
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data.weight_unit = params.newValue || WEIGHT_UNIT_MTS;
        return true;
      },
    }),
  ];

  return (
    <>
      {!props?.node?.isRowPinned() ? (
        <div>
          <Button size="small" onClick={() => setOpenDrawer(true)} disabled={disabled}>
            Add Packages
          </Button>
          <Drawer
            forceRender
            title={`Cargo Packages`}
            width="80%"
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            footer={
              <DrawerFooter
                saveText="Save Packages"
                closeText="Cancel"
                onClose={() => setOpenDrawer(false)}
                onSave={onSavePackages}
              />
            }
          >
            <Form.Item name={'shipment_packages'}>
              <FormTable
                reportName={'shipment_form_cargo_packages_details'}
                rowSelection="multiple"
                gridRef={gridRef}
                columns={columnDefs}
                rowData={packageDetails}
                hideTotals={true}
                allowProcessDataFromClipboard={true}
                newRowOnPaste={true}
                reportConfig={{
                  onRowSelected: (event: any) => {
                    const rowsSelected = gridRef.current?.api?.getSelectedRows();
                    setSelectedRowsCount(rowsSelected?.length || 0);
                  },
                  onCellValueChanged: (params: any) => {
                    let weight_unit: string;
                    const changedField = params.colDef.field || '';
                    if (changedField === 'weight_unit') {
                      weight_unit = params.newValue;
                    }
                    if (params.rowIndex === null) return;

                    const updatedPackages = packageDetails.map(
                      (packageData: any, index: number) => {
                        const updatedPackageData = { ...packageData };
                        if (packageData.errors?.[changedField]) {
                          delete updatedPackageData.errors[changedField];
                        }
                        if (index === params.rowIndex) {
                          return updatedPackageData;
                        }
                        return {
                          ...updatedPackageData,
                          weight_unit: weight_unit || packageData.weight_unit,
                        };
                      }
                    );
                    setPackageDetails(updatedPackages);
                  },
                  defaultColDef: {
                    minWidth: 100,
                    resizable: true,
                    suppressMenu: true,
                  },
                  onRowDataUpdated: (params: any) => {
                    const updatedRows: any[] = [];
                    params.api.forEachNode((rowNode: any) => {
                      updatedRows.push(rowNode.data);
                    });
                    setPackageDetails(updatedRows);
                  },
                  components: components,
                  stopEditingWhenCellsLoseFocus: true,
                  enableCellChangeFlash: true,
                }}
                checkbox_always_visible={false}
              />
              <div style={{ marginTop: '10px', display: 'flex' }}>
                <Button
                  size="small"
                  style={{ marginRight: '16px' }}
                  onClick={() => {
                    gridRef.current?.api?.updateRowData({ add: [{}] });
                  }}
                >
                  {' '}
                  Add Package
                </Button>

                <Button
                  danger
                  disabled={selectedRowsCount === 0}
                  size="small"
                  onClick={() => {
                    const sel = gridRef.current?.api?.getSelectedRows();
                    gridRef.current?.api?.applyTransaction({ remove: sel });
                    setSelectedRowsCount(0);
                  }}
                >
                  {' '}
                  Delete Package
                </Button>
              </div>
            </Form.Item>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PackageDetailsDrawerForm;
