import { Col, DatePicker, Form, FormInstance, GlobalSearch } from '@shipmnts/pixel-hub';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';
import {
  getCarriageWiseRoutingLegs,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';
import React from 'react';
import { getCutoffDateDisableFunction } from '../../helpers/CutoffDateHelper';
import { LOCATION_TYPE_CFS, LOCATION_TYPE_ICD } from 'operations/models/Location';
import { TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import { dayjs, Dayjs } from '@shipmnts/pixel-hub';

interface BookingOrderNewDesignProps {
  form?: FormInstance<any>;
  loadType: string | undefined;
  bookingType: string | undefined | null;
  tradeType?: string | undefined | null;
  colSpan?: number;
  areCutoffRequired?: boolean;
  disableEmptyPickupLocation?: boolean;
}

const cutoffDateDisable = getCutoffDateDisableFunction();

const BookingOrderNewDesign = ({
  bookingType,
  loadType,
  colSpan,
  disableEmptyPickupLocation,
  areCutoffRequired,
  tradeType,
}: BookingOrderNewDesignProps) => {
  const showValidTillDate = loadType !== LOAD_TYPE_LCL && tradeType !== TRADE_TYPE_IMPORT;
  const showEmptyReturnLocation =
    bookingType !== BOOKING_TYPE_VENDOR && bookingType !== BOOKING_TYPE_SHIPPING_LINE;

  return (
    <>
      {showValidTillDate && (
        <Col span={colSpan ?? 24 / 4}>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.confirmed_booking !== currentValues.confirmed_booking ||
              prevValues.routing_details !== currentValues.routing_details
            }
          >
            {({ getFieldValue }) => {
              const routing_legs = getFieldValue('routing_details')?.routing_legs;
              const carriageWiseRoutingLegs = routing_legs
                ? getCarriageWiseRoutingLegs(routing_legs)
                : {};
              const etd =
                carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0]
                  ?.estimated_time_of_departure;
              const estimated_time_of_departure = etd;

              return (
                <Form.Item
                  required={areCutoffRequired}
                  rules={[{ required: areCutoffRequired }]}
                  name="valid_till_date"
                  label="Validity Date"
                >
                  <DatePicker
                    disabledDate={(current: Dayjs) =>
                      cutoffDateDisable['valid_till_date'](current, {
                        estimated_time_of_departure,
                      })
                    }
                    minDate={dayjs()}
                    style={{ width: '100%' }}
                    defaultOpenValue={dayjs()}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      )}
      {tradeType !== TRADE_TYPE_IMPORT && (
        <Col span={colSpan ?? 24 / 4}>
          <Form.Item
            required={areCutoffRequired}
            rules={[{ required: areCutoffRequired }]}
            name="empty_pickup_location"
            label="Empty Pickup Location"
          >
            <GlobalSearch
              doc_type="Global::Location"
              disabled={disableEmptyPickupLocation}
              searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
            />
          </Form.Item>
        </Col>
      )}
      {showEmptyReturnLocation && (
        <Col span={colSpan ?? 24 / 4}>
          <Form.Item
            required={areCutoffRequired}
            rules={[{ required: areCutoffRequired }]}
            name="empty_return_location"
            label="Empty Return Location"
          >
            <GlobalSearch
              doc_type="Global::Location"
              searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  );
};

export default BookingOrderNewDesign;
