import { map as _map, intersection as _intersection } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import { SHIPMENT_TYPE_HOUSE } from 'operations/modules/shipment/constants';
import { SourceData } from './documentPrintHelper';
import { containerSettingsMap } from 'operations/baseConstants';
import { Rule } from 'operations/apis/document';
import { GraphQLError } from 'graphql';

const getShipmentDestinationCountry = (shipment: ShipmentValue) => {
  const pod = shipment.port_of_discharge?.id,
    pcd = shipment?.place_of_carrier_delivery?.id,
    fpod = shipment?.final_place_of_delivery?.id;
  let destination_country = shipment?.port_of_discharge?.country?.code;
  if (pod !== pcd && pcd === fpod)
    destination_country = shipment?.place_of_carrier_delivery?.country?.code;
  if (fpod && pod !== fpod && pcd !== fpod)
    destination_country = shipment?.final_place_of_delivery?.country_code;

  return destination_country;
};

export const getDataForRuleEngine = (data: SourceData): { [key: string]: string | boolean } => {
  const { shipment, shipment_document, master_shipment } = data;
  const container_types = _map(shipment?.shipment_containers, (sc) => sc.container_type_code);
  const is_reefer_container =
    _intersection(container_types, Object.keys(containerSettingsMap.reefer)).length > 0;
  const is_odo_container = _intersection(container_types, ['22ODO', '42ODO']).length > 0;
  const is_general_container =
    _intersection(container_types, Object.keys(containerSettingsMap.standard_dry)).length > 0 &&
    !is_odo_container;

  const destination_country = shipment && getShipmentDestinationCountry(shipment);

  return {
    load_type: shipment?.load_type || '',
    shipping_line_service_type:
      (shipment?.shipment_type === SHIPMENT_TYPE_HOUSE
        ? master_shipment?.getOtoBookingFromShipment()?.service_type
        : shipment?.getOtoBookingFromShipment()?.service_type) || '',
    incoterms: shipment?.incoterms || '',
    freight_terms: shipment_document?.charge_terms?.freight_terms || '',
    is_reefer_container,
    is_odo_container,
    is_general_container,
    destination_country: destination_country || '',
  };
};

export const getAutoApplyClause = (facts: { [key: string]: string | boolean }, rules: Rule[]) => {
  let clause_id;
  rules.forEach((rule) => {
    const result = (rule?.conditions?.all || []).reduce((finalResult, condition) => {
      let condition_result = true;
      if (condition.operator === 'equal')
        condition_result = facts?.[condition?.fact] === condition?.value;
      else if (condition.operator === 'notEqual')
        condition_result = facts?.[condition?.fact] !== condition?.value;
      else if (condition.operator === 'in') {
        condition_result = condition?.value?.includes(facts?.[condition?.fact] as string);
      } else if (condition.operator === 'notIn') {
        condition_result = !condition?.value?.includes(facts?.[condition?.fact] as string);
      }
      return condition_result && finalResult;
    }, true);
    if (result) clause_id = rule?.document_clause_id;
  });
  return clause_id;
};

export const getJsonFromError = (errors: readonly GraphQLError[]) => {
  if (errors && errors.length > 0 && errors[0]?.message) {
    try {
      const result = JSON.parse(errors[0]?.message);
      return result;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  return null;
};
