import React, { useEffect, useState } from 'react';
import { useShipmentDetail } from '../ShipmentDetailLayout';
import { get as _get, pick as _pick } from 'lodash';
import { ShipmentValue } from 'operations/models/Shipment';
import AddHouseShipment from './AddHouseShipment';
import AttachedHousesTable, { HouseShipmentRowDataInterface } from './AttachedHousesTable';
import LinkHouseButton from './LinkHouseShipment';
import {
  DOCUMENT_TYPE_HOUSE,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_ROAD,
  TRADE_TYPE_IMPORT,
  SHIPMENT_TYPE_CONSOL,
} from 'operations/modules/shipment/constants';
import Shipment from 'operations/models/Shipment';
import OceanLclUtilisationProgress from './OceanLclUtilizationProgress';
import { getLastEvent } from '../Containers/ContainerDetails';
import { Card, Button, Select } from '@shipmnts/pixel-hub';
import AddNewHouseShipment from '../../AddNewHouseShipment';
import { CONVERT_B2B_TO_DIRECT, CONVERT_DIRECT_TO_B2B } from 'operations/graphql/shipment';
import { useMutation } from '@apollo/client';
import {
  Modal,
  DatePicker,
  Checkbox,
  Form,
  Row,
  Col,
  Input,
  Popconfirm,
} from '@shipmnts/pixel-hub';
import DuplicateAwbModal from 'operations/modules/reports/components/ShipmentDocumentReports/DuplicateAwbModal';
import { useSession } from 'common';
import {
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  document_status_to_display_name_mapping,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
  ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE,
  DOCUMENT_TYPE_NEW_HAWB,
} from 'operations/modules/reports/constants';
import {
  LOAD_TYPE_FTL_BREAK_BULK,
  LOAD_TYPE_FTL_BULK,
  WEIGHT_UNIT_MTS,
} from 'operations/baseConstants';
import { getDateFromUnix, message } from '@shipmnts/pixel-hub';
import CreateAndAssignLoad from '../../ShipmentForm/CreateAndAssignLoad';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { LOAD_TYPE_FCL } from 'network/baseConstants';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';

const renderPartyDetailByName = (params: { name: string; shipment: ShipmentValue }): any => {
  const { shipment, name } = params;
  const party = shipment.getShipmentPartyByName(name);
  if (!party) return '';
  return _get(party, 'party_company.registered_name', '');
};

const AttachedHousesTab = () => {
  const { shipment, refetchShipments } = useShipmentDetail();
  const [showAssignLoad, setShowAssignLoad] = useState(false);
  const [hawbModalVisible, setHawbModalVisible] = useState(false);
  const [showDuplicateAwbModal, setShowDuplicateAwbModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const session = useSession();
  const [convertedShipment, setConvertedShipment] = useState(shipment);

  useEffect(() => {
    if (refetchShipments) {
      refetchShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderHawbModal = () => {
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
      if (values.isChecked) {
        setChecked(true);
      }
      convertDirectToB2b(values);
      setHawbModalVisible(false);
      form.resetFields();
    };

    const convertDirectToB2b = (values: any) => {
      convertDirectToB2BMutation({
        variables: {
          shipment_id: shipment?.id,
          house_document_shipment_number: values.shipmentNumber?.toString(),
          house_document_shipment_date: values.shipmentDate?.toString(),
          bl_status: values.BlStatus?.toString(),
          document_status_event_date: values.document_status_event_date?.toString(),
        },
      });
    };
    const label = shipment?.freight_type === FREIGHT_TYPE_AIR ? 'HAWB' : 'HBL';
    const isAirImport =
      shipment?.freight_type === FREIGHT_TYPE_AIR && shipment?.trade_type === TRADE_TYPE_IMPORT;
    const isOceanImport =
      shipment?.freight_type !== FREIGHT_TYPE_AIR && shipment?.trade_type === TRADE_TYPE_IMPORT;
    const freightType = shipment?.freight_type || FREIGHT_TYPE_AIR;

    const ConvertB2BModal = () => {
      const [documentStatus, setDocumentStatus] = useState('');
      return (
        <Modal
          open={hawbModalVisible}
          title="Are you sure you want to convert it to Back to Back Shipment?"
          width={650}
          onCancel={() => {
            setHawbModalVisible(false);
          }}
          footer={[
            <Button key="cancel" type="default" onClick={() => setHawbModalVisible(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loadingB2B}
              disabled={!shipment?.canEdit()}
              onClick={() => form.submit()}
            >
              Attach House Shipment
            </Button>,
          ]}
        >
          <div style={{ marginTop: '35px' }}>
            <Form
              form={form}
              name="Form"
              onFinish={onFinish}
              layout="vertical"
              initialValues={{
                shipmentNumber: undefined,
                shipmentDate: null,
                isChecked: false,
                BlStatus: documentStatus,
              }}
            >
              <Row gutter={24}>
                <Col style={{ paddingTop: '5px' }} span={4}>
                  <div>{`${label} No:`}</div>
                </Col>
                <Col span={8}>
                  <Form.Item name="shipmentNumber">
                    <Input min={0} style={{ width: '100%' }} placeholder={`Enter ${label} No`} />
                  </Form.Item>
                </Col>
                <Col style={{ paddingTop: '5px' }} span={4}>
                  <div>{`${label} Date:`}</div>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="shipmentDate"
                    rules={[{ required: isAirImport, message: `Please select a ${label} date` }]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      format="DD-MM-YYYY"
                      placeholder={`Select ${label} Date`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {isOceanImport && (
                <Row gutter={24}>
                  <Col style={{ paddingTop: '5px' }} span={4}>
                    <div>B/L Status</div>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="BlStatus"
                      rules={[{ required: true, message: 'Please select B/L Status' }]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        onChange={(value) => setDocumentStatus(value)}
                      >
                        {ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE[freightType].map(
                          (option: string, index: number) => (
                            <Select.Option key={index} value={option}>
                              {document_status_to_display_name_mapping[option]}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  {[
                    DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
                    DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
                  ].includes(documentStatus) && (
                    <>
                      <Col span={6}>
                        {documentStatus === DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN
                          ? 'Surrendered At Origin Date'
                          : 'Received At Destination Date'}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          required
                          rules={[{ required: true }]}
                          name={'document_status_event_date'}
                        >
                          <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              {shipment?.freight_type === FREIGHT_TYPE_AIR && !isAirImport && (
                <Row gutter={16}>
                  <Col style={{ paddingTop: '4px' }}>Create HAWB</Col>
                  <Col>
                    <Form.Item name="isChecked" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Modal>
      );
    };

    return (
      <>
        <ConvertB2BModal />
        {shipment && showDuplicateAwbModal && (
          <DuplicateAwbModal
            docType={DOCUMENT_TYPE_NEW_HAWB}
            onClose={() => {
              setShowDuplicateAwbModal(false);
              setChecked(false);
              if (refetchShipments) refetchShipments();
            }}
            shipment={convertedShipment || shipment}
          />
        )}
      </>
    );
  };
  const [convertB2BToDirectMutation, { data: convertToDirectData, loading: loadingDirect }] =
    useMutation(CONVERT_B2B_TO_DIRECT, {
      fetchPolicy: 'no-cache',
    });
  const [convertDirectToB2BMutation, { data: convertToB2BData, loading: loadingB2B }] = useMutation(
    CONVERT_DIRECT_TO_B2B,
    {
      fetchPolicy: 'no-cache',
    }
  );
  useEffect(() => {
    const onSuccess = async () => {
      if (convertToB2BData && convertToB2BData.convert_direct_to_back_to_back) {
        if (convertToB2BData.convert_direct_to_back_to_back.shipment)
          setConvertedShipment(
            Shipment.create(convertToB2BData.convert_direct_to_back_to_back.shipment)
          );
        message.success('Successfully converted to back to back');
        setHawbModalVisible(false);
        if (checked) setShowDuplicateAwbModal(true);
        else if (refetchShipments && !checked) refetchShipments();
      }
    };
    onSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertToB2BData]);
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    if (convertToDirectData) {
      if (convertToDirectData.convert_back_to_back_to_direct) {
        message.success('Successfully converted to direct');
        if (refetchShipments) refetchShipments();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertToDirectData]);

  const getDocumentData = (document?: ShipmentDocumentValue, shipment?: ShipmentValue) => {
    let shipmentNumber = document?.shipment_number || '';
    if (!shipmentNumber && shipment?.freight_type === FREIGHT_TYPE_ROAD) {
      shipmentNumber = shipment?.job_number || '';
    }
    return {
      id: document?.document_id || '',
      name: shipmentNumber,
      shipment_id: document?.shipment_id || '',
    };
  };

  const isOceanConsol = shipment?.isOceanShipment() && shipment.isConsolShipment();
  if (!shipment) return;
  const data: HouseShipmentRowDataInterface[] = [];
  const attachShipments: ShipmentValue[] =
    (shipment?.isRoadCustomerOrder()
      ? shipment?.trips?.filter((sp) =>
          ['back_to_back', 'consol'].includes(sp?.shipment_type || '')
        )
      : shipment?.house_shipments) || [];
  attachShipments.forEach((attachShipment) => {
    const attachShipmentRowData: any = _pick(attachShipment, ['id', 'next_actions']);
    attachShipmentRowData.job_number = {
      value: attachShipment.job_number || '',
      extra_fields: { id: attachShipment.id },
    };
    attachShipmentRowData.incoterms = attachShipment.incoterms || '';
    attachShipmentRowData.shipper = renderPartyDetailByName({
      shipment: attachShipment,
      name: 'shipper',
    });
    attachShipmentRowData.consignee = renderPartyDetailByName({
      shipment: attachShipment,
      name: 'consignee',
    });
    const documentType = DOCUMENT_TYPE_HOUSE;

    const shipmentNumber: { id: string; name: string; shipment_id: string }[] = [];
    const shipmentDate: string[] = [];
    if (attachShipment?.shipment_type === SHIPMENT_TYPE_CONSOL) {
      (attachShipment?.house_shipments || []).forEach((houseShipment) => {
        (houseShipment.shipment_documents || [])
          .filter((doc: ShipmentDocumentValue) => !!doc.document_id)
          .forEach((doc: ShipmentDocumentValue) => {
            shipmentNumber.push(getDocumentData(doc, houseShipment));
            if (doc?.shipment_date) shipmentDate.push(getDateFromUnix(doc?.shipment_date));
          });
      });
    } else {
      (attachShipment.shipment_documents || []).forEach((doc: ShipmentDocumentValue) => {
        shipmentNumber.push(getDocumentData(doc, attachShipment));
        if (doc?.shipment_date) shipmentDate.push(getDateFromUnix(doc?.shipment_date));
      });
    }

    attachShipmentRowData.shipment_document = {
      ...attachShipment.getShipmentDocument(documentType),
      shipment_number: shipmentNumber,
      shipment_date: shipmentDate,
    };
    attachShipmentRowData.shipment_document_eawb_status =
      attachShipment?.getShipmentDocument(DOCUMENT_TYPE_HOUSE)?.eawb_status || '';
    attachShipmentRowData.vehicle = attachShipment.vehicle || undefined;
    attachShipmentRowData.driver = attachShipment.driver || undefined;
    if (attachShipment.tracking_events) {
      attachShipmentRowData.last_event = getLastEvent(
        attachShipment.tracking_events,
        attachShipment.last_action_status || ''
      );
    }
    attachShipmentRowData.load = attachShipment.load_type?.toUpperCase() || '';

    // extract name of the sales person from attachShipment
    let sales_agent_name = '';
    if (attachShipment?.sales_agent) sales_agent_name = attachShipment.sales_agent.name;

    attachShipmentRowData.sales_agent = sales_agent_name;
    attachShipmentRowData.number_of_container = attachShipment.shipment_containers?.length || 0;
    if (attachShipment?.gross_volume)
      attachShipmentRowData.gross_volume = attachShipment.gross_volume;
    if (attachShipment?.total_number_of_packages)
      attachShipmentRowData.total_number_of_packages = attachShipment.total_number_of_packages;
    if (attachShipment?.gross_weight)
      attachShipmentRowData.gross_weight = attachShipment.gross_weight;
    let totalNetweight = 0;
    attachShipment.cargos.forEach((cargo) => {
      totalNetweight += cargo?.net_weight || 0;
    });
    attachShipmentRowData.net_weight = totalNetweight;
    const container_numbers: string[] = [];
    attachShipment.shipment_containers?.forEach((container) => {
      if (container.container_number) {
        container_numbers.push(container.container_number);
      }
    });
    attachShipmentRowData.container_numbers = container_numbers;
    attachShipmentRowData.weight_unit = attachShipment.weight_unit || WEIGHT_UNIT_MTS;
    attachShipmentRowData.final_place_of_delivery =
      attachShipment.final_place_of_delivery?.name || '';
    data.push(attachShipmentRowData);
  });

  const convertB2BToDirect = () => {
    convertB2BToDirectMutation({
      variables: {
        shipment_id: shipment.id,
      },
    });
  };
  const isDirectShipment = shipment.isDirectShipment();

  const isBackToBackShipment = shipment.isBackToBackShipment();

  const isManufacturerOrTrader =
    session?.company_account?.primary_business === 'manufacturer' ||
    session?.company_account?.primary_business === 'trader';

  const disableConversionButton =
    shipment.isShipmentCancelled() ||
    shipment.freight_type === FREIGHT_TYPE_ROAD ||
    isManufacturerOrTrader;

  const roadAttachedHouseTable = ['house_shipment', 'shipment_number', 'shipment_date'];
  if (!shipment?.split_from_order_id) {
    roadAttachedHouseTable.push('vehicle_license_plate_number', 'driver_name');
  }
  roadAttachedHouseTable.push('gross_weight');

  if (shipment?.isFcl()) {
    roadAttachedHouseTable.push('number_of_container');
    roadAttachedHouseTable.push('container_numbers');
  }
  if (
    shipment?.load_type === LOAD_TYPE_FTL_BREAK_BULK ||
    shipment?.load_type === LOAD_TYPE_FTL_BULK
  ) {
    roadAttachedHouseTable.push('net_weight');
    roadAttachedHouseTable.push('total_number_of_packages');
  }

  roadAttachedHouseTable.push('action');
  if (
    !shipment.isRoadSplitShipmentExecuted() &&
    shipment.isRoadSplitShipment() &&
    shipment.isBackToBackShipment() &&
    (shipment?.cargos?.length || shipment?.shipment_containers?.length)
  ) {
    return (
      <div>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type="primary" onClick={() => setShowAssignLoad(true)}>
              Add New CN
            </Button>
            {showAssignLoad && (
              <CreateAndAssignLoad
                shipment={shipment}
                onSuccess={() => {
                  if (refetchShipments) refetchShipments();
                  setShowAssignLoad(false);
                }}
                onClose={() => setShowAssignLoad(false)}
                isAddNewHouse={true}
              />
            )}
          </div>
        </Card>
      </div>
    );
  } else if (isDirectShipment) {
    return (
      <div>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              type="primary"
              onClick={() => setHawbModalVisible(true)}
              disabled={disableConversionButton || !shipment?.canEdit()}
            >
              Attach House Shipment
            </Button>
            <RenderHawbModal />
          </div>
        </Card>
      </div>
    );
  } else if (isBackToBackShipment) {
    return (
      <div>
        <Card>
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}
          >
            <Popconfirm
              title="Are you sure you want to convert it to Direct Shipment?"
              onConfirm={convertB2BToDirect}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="default"
                loading={loadingDirect}
                disabled={!shipment?.canEdit() || disableConversionButton}
              >
                Convert to Direct
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              disabled={!shipment.isForwardingShipment() || disableConversionButton}
              onClick={() => setModalVisible(true)}
            >
              Attach House Shipment
            </Button>
            {modalVisible && (
              <AddNewHouseShipment
                shipment={shipment}
                onClose={() => setModalVisible(false)}
                onSuccess={() => {
                  setModalVisible(false);
                  if (refetchShipments) refetchShipments();
                }}
              />
            )}
          </div>
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <div className="flex-row" style={{ justifyContent: 'space-between' }}>
          <h3>
            {shipment.freight_type === FREIGHT_TYPE_ROAD ? 'Linked Shipments' : 'Attached Houses'}
          </h3>
          <div>
            {allowAddHouse(shipment) && (
              <AddHouseShipment masterShipment={shipment} refetchShipments={refetchShipments} />
            )}
            {allowLinkHouse(
              shipment,
              shipment?.isRoadCustomerOrder() ? shipment?.trips : shipment?.house_shipments
            ) &&
              refetchShipments && (
                <LinkHouseButton
                  shipment={shipment}
                  existingHouseShipments={
                    shipment?.isRoadCustomerOrder() ? shipment?.trips : shipment?.house_shipments
                  }
                />
              )}
          </div>
        </div>
        {refetchShipments &&
          (shipment.isRoadCustomerOrder() ||
          (shipment.isRoadShipment() && shipment.isConsolShipment()) ? (
            <AttachedHousesTable
              columns={roadAttachedHouseTable}
              shipment={shipment}
              refetchData={refetchShipments}
              rowData={data}
            />
          ) : (
            <AttachedHousesTable
              columns={[
                'house_shipment',
                'shipper',
                'consignee',
                'shipment_number',
                'shipment_document_status',
                'shipment_document_eawb_status',
                'incoterm',
                'load',
                'sales_person',
                'gross_weight',
                'gross_volume',
                'last_action_status',
                'action',
                'final_place_of_delivery',
                'remarks',
                'services',
              ]}
              shipment={shipment}
              refetchData={refetchShipments}
              rowData={data}
            />
          ))}
        {isOceanConsol && shipment.isLcl() && (
          <OceanLclUtilisationProgress
            masterShipment={shipment}
            selectedNodes={[]}
            existingHouseShipments={attachShipments}
            isInline
          />
        )}
      </div>
    );
  }
};

const allowAddHouse = (shipment: ShipmentValue) => {
  return shipment.canEdit();
};
const allowLinkHouse = (shipment: ShipmentValue, attachShipments: any) => {
  const linkedFCLHouses =
    attachShipments?.filter((sp: any) => sp?.load_type === LOAD_TYPE_FCL).length ?? 0;
  const totalContainers = shipment?.shipment_containers?.length ?? 0;
  return (
    (shipment.load_type !== LOAD_TYPE_FCL ||
      (shipment.freight_type === FREIGHT_TYPE_OCEAN && linkedFCLHouses < totalContainers)) &&
    (shipment.is_from_consol_helper ||
      (shipment.isOceanShipment() && (attachShipments || []).every((hs: any) => hs.isLcl()))) &&
    shipment.isConsolShipment() &&
    (shipment.isImportShipment() ||
      (shipment.isExportShipment() && !shipment.isDocumentFinalised('master'))) &&
    !shipment.isShipmentCancelled()
  );
};

export default AttachedHousesTab;
