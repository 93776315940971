import React, { Fragment, MutableRefObject } from 'react';
import { Column } from 'operations/models/Report';
import {
  BaseTable,
  LinkRender,
  StringWithToolTip,
  NumberTypeRenderer,
  ActivityRenderer,
  EtaStatusRenderer,
  FloatEditor,
  getDateTimeFromUnix,
} from '@shipmnts/pixel-hub';

import { GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import {
  DocumentRenderer,
  PaymentStatusRenderer,
} from 'operations/modules/shipment/components/DetailLayout/Accounts/components/renderers';
import { ProductOrderValue } from 'operations/models/ProductOrder';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { countBy as _countBy, startCase as _startCase, compact as _compact } from 'lodash';
import {
  ContainerStatusRenderer,
  QtyUomTypeRenderer,
} from 'common/report_manager/components/Renderer/cellRenderer';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { ActionRendererDetailReport } from 'operations';

interface ShipmentsTabProps {
  productOrder: ProductOrderValue;
}

interface ShipmentItemTableProps {
  productOrder: ProductOrderValue;
  isColHide?: boolean;
  editable?: boolean;
  gridRef?: MutableRefObject<GridOptions | undefined>;
  onChange?: (value: Array<any>) => void;
}

const getContainerSummary = (shipment_containers: any) => {
  if (!shipment_containers) return '';
  const containersByCode = _countBy(shipment_containers, 'container_type_code');
  return Object.keys(containersByCode)
    .map((container_type) => {
      return `${containersByCode[container_type]} X ${container_type}`;
    })
    .join(', ');
};

export const ShipmentItemTable = (props: ShipmentItemTableProps) => {
  const { productOrder, isColHide = false, editable, gridRef } = props;
  const productOrderItem: ProductOrderItemValue[] = productOrder?.product_order_items || [];

  const tableData: any[] = [];
  if (productOrderItem) {
    productOrderItem.forEach((product_order_item: ProductOrderItemValue) => {
      (product_order_item.linked_product_order_items || []).forEach(
        (shipment_product_order_item: ProductOrderItemValue) => {
          const shipment = shipment_product_order_item?.shipment;
          let buyer, seller;
          shipment?.shipment_parties?.forEach((sp: ShipmentPartyValue) => {
            if (sp.name === 'buyer') {
              buyer = sp?.party_company?.registered_name;
            } else if (sp.name === 'seller') {
              seller = sp?.party_company?.registered_name;
            }
          });
          const containerNumbers: any = [];
          const allContainerStatusHash: any = {};
          shipment?.shipment_containers?.forEach((ele: any) => {
            if (ele.container_number) {
              containerNumbers.push(ele.container_number);
              allContainerStatusHash[ele.container_number] = ele.last_action_status;
            }
          });

          const customsNumbers: any = [];
          shipment?.shipment_custom_details?.forEach((ele: any) => {
            customsNumbers.push(ele?.custom_document_number);
          });

          tableData.push({
            id: shipment_product_order_item?.id,
            shipment_id: shipment?.id,
            uom: shipment_product_order_item?.uom,
            job_number: shipment?.job_number,
            product_name: shipment_product_order_item?.product_name,
            shipment_planned_qty: shipment_product_order_item?.shipment_planned_qty || 0,
            shipped_qty: shipment_product_order_item?.shipped_qty || 0,
            delivered_qty: shipment_product_order_item?.delivered_qty || 0,
            dispatched_qty: shipment_product_order_item?.dispatched_qty || 0,
            prioritized_qty: shipment_product_order_item?.prioritized_qty || 0,
            ready_qty: shipment_product_order_item?.ready_qty || 0,
            gross_weight: shipment?.gross_weight,
            net_weight: shipment?.net_weight,
            unit_rate: shipment_product_order_item?.unit_rate,
            total_sell_amount: Number(
              (shipment_product_order_item?.unit_rate || 0) *
                (shipment_product_order_item?.shipment_planned_qty || 0)
            ),
            estimated_time_of_arrival: shipment?.estimated_time_of_arrival
              ? getDateTimeFromUnix(shipment?.estimated_time_of_arrival)
              : '',
            estimated_time_of_departure: shipment?.estimated_time_of_departure
              ? getDateTimeFromUnix(shipment?.estimated_time_of_departure)
              : '',
            shipment_status: shipment?.status,
            freight_forwarding_agent: shipment?.shipment_parties?.find(
              (party: any) => party?.name === 'freight_forwarding_agent'
            )?.party_company?.registered_name,
            clearance_agent: shipment?.shipment_parties?.find(
              shipment?.trade_type === 'export'
                ? (party: any) => party.name === 'origin_clearance_agent'
                : (party: any) => party.name === 'destination_clearance_agent'
            )?.party_company?.registered_name,
            carrier: shipment?.carrier?.name,
            container_details: getContainerSummary(shipment?.shipment_containers),
            container_numbers: containerNumbers,
            all_container_status: allContainerStatusHash,
            customs_numbers: customsNumbers,
            booking_number: shipment?.getOtoBookingFromShipment?.booking_number,
            shipment_number: shipment?.shipment_documents
              ? shipment?.shipment_documents[0]?.shipment_number
              : '',
            bl_status: shipment?.shipment_documents
              ? _startCase(shipment?.shipment_documents[0]?.document_status || '')
              : '-',
            port_of_loading: shipment?.port_of_loading?.name,
            port_of_discharge: shipment?.port_of_discharge?.name,
            eta_status: shipment?.eta_status,
            last_action_status: shipment?.last_action_status,
            status_update: shipment?.last_status_update,
            comment: shipment?.last_comment,
            buyer: buyer,
            seller: seller,
          });
        }
      );
    });
  }

  const columnDefs: Column[] = _compact([
    {
      headerName: 'Job Number #',
      field: 'job_number',
      colId: 'job_number',
      // hide: true,
      // rowGroup: true,
      // getQuickFilterText: () => '',
      valueGetter: ({ data }) => {
        return data?.job_number;
      },
      // suppressColumnsToolPanel: true,
      cellRenderer: (params: any) => {
        let link, job_number;
        tableData.forEach((value) => {
          if (value.job_number === params.value) {
            link = `/view/shipment/${value.shipment_id}`;
            job_number = value.job_number;
          }
        });
        return (
          <>
            <a href={link} target="_blank" rel="noreferrer">
              {job_number}
            </a>
          </>
        );
      },
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      columnType: 'String',
    },
    {
      headerName: 'Shipment Planned Units',
      field: 'shipment_planned_qty',
      colId: 'shipment_planned_qty',
      columnType: 'Float',
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params.data.shipment_planned_qty,
            uom: params.data.uom,
          },
        };
      },
      valueGetter: (params: any) => {
        return params.data.shipment_planned_qty || 0;
      },
    },

    {
      headerName: 'Prioritized Qty',
      field: 'prioritized_qty',
      colId: 'prioritized_qty',
      columnType: 'Float',
      editable: editable,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params.data.prioritized_qty,
            uom: params.data.uom,
          },
        };
      },
      valueSetter: (params) => {
        params.data.prioritized_qty = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.prioritized_qty || 0;
      },
      cellEditor: 'FloatEditor',
      cellEditorParams: {
        precision: 3,
        min: 0,
      },
    },
    {
      headerName: 'Ready Qty',
      field: 'ready_qty',
      colId: 'ready_qty',
      columnType: 'Float',
      editable: editable,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params.data.ready_qty,
            uom: params.data.uom,
          },
        };
      },
      valueSetter: (params) => {
        params.data.ready_qty = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.ready_qty || 0;
      },
      cellEditor: 'FloatEditor',
      cellEditorParams: {
        precision: 3,
        min: 0,
      },
    },
    {
      headerName: 'Dispatched Qty',
      field: 'dispatched_qty',
      colId: 'dispatched_qty',
      columnType: 'Float',
      editable: editable,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params.data.dispatched_qty,
            uom: params.data.uom,
          },
        };
      },
      valueSetter: (params) => {
        params.data.dispatched_qty = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.dispatched_qty || 0;
      },
      cellEditor: 'FloatEditor',
      cellEditorParams: {
        precision: 3,
        min: 0,
      },
    },
    {
      headerName: 'Shipped Units',
      field: 'shipped_qty',
      colId: 'shipped_qty',
      columnType: 'Float',
      editable: editable,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params.data.shipped_qty,
            uom: params.data.uom,
          },
        };
      },
      valueSetter: (params) => {
        params.data.shipped_qty = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.shipped_qty || 0;
      },
      cellEditor: 'FloatEditor',
      cellEditorParams: {
        precision: 3,
        min: 0,
      },
    },
    {
      headerName: 'Delivered Qty',
      field: 'delivered_qty',
      colId: 'delivered_qty',
      columnType: 'Float',
      editable: editable,
      cellRendererSelector: (params) => {
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: params.data.delivered_qty,
            uom: params.data.uom,
          },
        };
      },
      valueSetter: (params) => {
        params.data.delivered_qty = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.delivered_qty || 0;
      },
      cellEditor: 'FloatEditor',
      cellEditorParams: {
        precision: 3,
        min: 0,
      },
    },
    {
      headerName: 'Total Sell Amount',
      field: 'total_sell_amount',
      colId: 'total_sell_amount',
      cellEditor: 'FloatEditor',
      columnType: 'Float',
    },
    !isColHide && {
      headerName: 'Booking #',
      field: 'booking_number',
      colId: 'booking_number',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Carrier',
      field: 'carrier',
      colId: 'carrier',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Container Details',
      field: 'container_details',
      colId: 'container_details',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Container Numbers',
      field: 'container_numbers',
      colId: 'container_numbers',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'ETD at PoL',
      field: 'estimated_time_of_departure',
      colId: 'estimated_time_of_departure',
      columnType: 'Date',
    },
    !isColHide && {
      headerName: 'ETA at PoD',
      field: 'estimated_time_of_arrival',
      colId: 'estimated_time_of_arrival',
      columnType: 'Date',
    },
    !isColHide && {
      headerName: 'ETA Status',
      field: 'eta_status',
      colId: 'eta_status',
      columnType: 'String',
      cellRenderer: 'eta_status_render',
      cellRendererParams: (params: any) => ({
        value: params?.data?.eta_status,
        workflow_type: 'main',
      }),
      // lockVisible: true,
    },
    !isColHide && {
      headerName: 'Shipment Status',
      field: 'status_update',
      colId: 'status_update',
      width: 200,
      // pinned: 'right',
      // lockPosition: true,
      // suppressMovable: true,
      // suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'StatusUpdate',
        doc_type_id: 'Shipment::Shipment',
        showDrawer: true,
      },
      // lockVisible: true,
    },
    !isColHide && {
      headerName: 'Last Action Status',
      field: 'last_action_status',
      colId: 'last_action_status',
      columnType: 'String',
      // cellRenderer: 'event_renderer',
      // cellRendererParams: {
      //   doc_type_id: 'Shipment::Shipment',
      //   workflow_type: 'main',
      // },
      // lockVisible: true,
    },
    !isColHide && {
      headerName: 'Container Status',
      field: 'all_container_status',
      colId: 'all_container_status',
      columnType: 'String',
      cellRenderer: ContainerStatusRenderer,
      // cellRendererParams: {
      //   value: 'main',
      // },
      // lockVisible: true,
    },
    !isColHide && {
      headerName: 'Shipping Bill #',
      field: 'customs_numbers',
      colId: 'customs_numbers',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'BL #',
      field: 'shipment_number',
      colId: 'shipment_number',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'BL Status',
      field: 'bl_status',
      colId: 'bl_status',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Freight Forwarding Agent',
      field: 'freight_forwarding_agent',
      colId: 'freight_forwarding_agent',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Clearance Agent',
      field: 'clearance_agent',
      colId: 'clearance_agent',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Buyer',
      field: 'buyer',
      colId: 'buyer',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Seller',
      field: 'seller',
      colId: 'seller',
      columnType: 'String',
    },
    !isColHide && {
      headerName: 'Comment',
      field: 'comment',
      colId: 'comment',
      width: 200,
      pinned: 'right',
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      cellRenderer: 'render_activity',
      cellRendererParams: {
        type: 'Comment',
        doc_type_id: 'Shipment::Shipment',
        showDrawer: true,
      },
    },

    // {
    //   headerName: 'Actions',
    //   suppressMovable: true,
    //   suppressNavigable: true,
    //   colId: 'actions',
    //   field: 'actions',
    //   valueGetter: 'data',
    //   cellRenderer: 'ActionRendererDetailReport',
    //   cellRendererParams: {
    //     doc_type_id: 'NewAccounting::Invoices',
    //   },
    //   // minWidth: 55,
    //   pinned: 'right',
    //   headerComponent: 'StaticHeaderComponent',
    // },
  ]);

  const setDefaultFilters = (grid: GridReadyEvent) => {
    if (gridRef) gridRef.current = grid;
    const filter = {
      status: {
        type: 'set',
        values: ['Draft', 'Submit'],
      },
    };
    grid.api.setFilterModel(filter);
    grid.api.onFilterChanged();
  };

  const components = {
    accounts_link_render: LinkRender,
    document_status_renderer: DocumentRenderer,
    payment_status_renderer: PaymentStatusRenderer,
    string_with_tool_tip: StringWithToolTip,
    number_type_renderer: NumberTypeRenderer,
    eta_status_render: EtaStatusRenderer,
    render_activity: ActivityRenderer,
    QtyUomTypeRenderer: QtyUomTypeRenderer,
    FloatEditor,
    ActionRendererDetailReport,
  };

  return (
    <Fragment>
      <div style={{ paddingTop: '0px' }}>
        <BaseTable
          reportName={'shipment_tab'}
          columns={columnDefs}
          rowData={tableData}
          gridRef={gridRef}
          onGridReady={setDefaultFilters}
          reportConfig={{
            // groupDefaultExpanded: 1,
            components: components,
            // autoGroupColumnDef: {
            //   headerName: 'Job Number #',
            //   pinned: 'left',
            //   field: 'job_number',
            //   valueGetter: (params: any) => params.data?.job_number,
            // },
          }}
        />
      </div>
    </Fragment>
  );
};

function ShipmentsTab(props: ShipmentsTabProps) {
  const { productOrder } = props;
  return <ShipmentItemTable productOrder={productOrder} isColHide={false} />;
}

export default ShipmentsTab;
