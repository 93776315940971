import PerformActionComponenent from 'network/components/connectionRequest/PerformActionComponent';
import { PerformAction } from '../models';
import { UserContactValue } from 'common';

export const performAcceptConnectionRequest = (payload: any): PerformAction => {
  const { company, setEmailProps, setVisible } = payload;
  const connectionRequest = company?.connection_request;

  return {
    actionParams: {
      connectionRequest: connectionRequest,
      actionName: 'ACCEPT',
      onSuccess: () => {
        setEmailProps({
          title: `Send Confirmation Email`,
          action_name: 'connection_request_status_change',
          resource_ids: [connectionRequest.id],
          defaultEmails: {
            recipient_emails: company?.contacts?.map((contact: UserContactValue) => contact.email),
          },
        });
        setVisible(true);
      },
    },
    component: PerformActionComponenent,
  };
};

export const performRejectConnectionRequest = (payload: any): PerformAction => {
  const { company } = payload;
  const connectionRequest = company?.connection_request;

  return {
    actionParams: {
      connectionRequest: connectionRequest,
      actionName: 'REJECT',
    },
    component: PerformActionComponenent,
  };
};

export const performRemoveConnectionRequest = (payload: any): PerformAction => {
  const { company } = payload;
  const connectionRequest = company?.connection_request;

  return {
    actionParams: {
      connectionRequest: connectionRequest,
      actionName: 'REMOVE',
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: PerformActionComponenent,
  };
};

export const performBlockConnectionRequest = (payload: any): PerformAction => {
  const { company } = payload;
  const connectionRequest = company?.connection_request;
  return {
    actionParams: {
      connectionRequest: connectionRequest,
      actionName: 'BLOCK',
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: PerformActionComponenent,
  };
};

export const performUnBlockConnectionRequest = (payload: any): PerformAction => {
  const { company } = payload;
  const connectionRequest = company?.connection_request;
  return {
    actionParams: {
      connectionRequest: connectionRequest,
      actionName: 'UNBLOCK',
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: PerformActionComponenent,
  };
};
