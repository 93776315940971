import React from 'react';
import { ShipmentValue } from 'operations/models/Shipment';
import { PERMISSION_ROLLOVER_CONTAINER } from 'operations/permissions';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE, TERTIARY_TYPE } from '../constants';
import {
  EditOutlined,
  CompassOutlined,
  WhatsAppOutlined,
  MailOutlined,
  CheckCircleOutlined,
  FileExclamationOutlined,
  DisconnectOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  FolderOpenOutlined,
  IssuesCloseOutlined,
  AuditOutlined,
  ApartmentOutlined,
  DollarCircleOutlined,
  ShoppingCartOutlined,
  LinkOutlined,
  FileAddOutlined,
  SolutionOutlined,
  ShareAltOutlined,
  FilePdfOutlined,
  TagsOutlined,
  TeamOutlined,
} from '@shipmnts/pixel-hub';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import { get, startCase } from 'lodash';
import {
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
  DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
  DOCUMENT_STATUS_PENDING_CREATION,
  DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
  PERMISSION_ACCOUNTING_CLOSE,
  PERMISSION_BILLING_CLOSE,
  PERMISSION_CANCEL_SHIPMENT,
  PERMISSION_REOPEN_JOB,
  PERMISSION_REOPEN_BILLING,
  SHIPMENT_STATUS_BACK_TO_TOWN,
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_EXECUTED,
  SHIPMENT_STATUS_PLANNED,
  SHIPMENT_TYPE_GENERAL,
  STATUS_PICKUP_PENDING,
  STATUS_CANCELLED,
  STATUS_CONFIRMED,
  DOCUMENT_STATUS_EXECUTED,
} from 'operations/modules/reports/constants';
import { Action } from '../models';
import { PermissionValue, SessionDataValue } from 'operations/models/SessionData';
import { hasPermission } from '@shipmnts/pixel-hub';
import {
  addNewHouseShipmentRenderer,
  addShipmentContainerDetailsActionRenderer,
  backtoTownRenderer,
  cancelShipmentRenderer,
  creditControlRenderer,
  deleteHouseShipmentRenderer,
  delinkHouseShipmentRenderer,
  dispatchDocketRenderer,
  finaliseConsolRenderer,
  jobCloseRenderer,
  performShipmentEvent,
  reopenJobRenderer,
  shareJobRenderer,
  sendPreAlertRenderer,
  createContainerActionRenderer,
  linkRoadShipmentRenderer,
  createNewRoadShipmentRenderer,
  performUpdateProductsQty,
  addCollaboratorsRenderer,
  addCreateShipmentFromBrRenderer,
  CreateBookingOrderFromShipmentRenderer,
  shareCroRenderer,
  offloadContainersFormRenderer,
  changeAllocationRenderer,
  mergeShipmentRenderer,
  allocateOTOReportRenderer,
  assignVehicleRender,
  assignLoadRender,
  rolloverOrShutoutRenderer,
  updateTeamRenderer,
  addNewHouseForRoadShipmentRender,
  performShipmentSharePDF,
} from './shipmentActionRenderer';

import { updateOrSplitRollOverRenderer } from './shipmentActionRenderer';

import { ApolloClient } from '@apollo/client';
import {
  ACCOUNTING_STATUS_BILLING_CLOSED,
  ACCOUNTING_STATUS_PENDING,
  CREDIT_STATUS_APPROVED_EVENT,
  CREDIT_STATUS_ON_HOLD,
  CREDIT_STATUS_ON_HOLD_EVENT,
  CREDIT_STATUS_ON_AUTO_HOLD,
  CREDIT_STATUS_PENDING,
  FREIGHT_TYPE_OCEAN,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_HOUSE,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  FREIGHT_TYPE_ROAD,
  ShipmentType,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  ACCOUNTING_STATUS_CLOSED,
  ACTION_STATUS_SHIPMENT_CANCELLED,
} from 'operations/modules/shipment/constants';
import { UPDATE_SHIPMENT_ACCOUNTING_STATUS } from 'operations/graphql/shipmentDocument';
import { Badge, message, CustomIcon } from '@shipmnts/pixel-hub';
import { CopyOutlined, UsergroupAddOutlined } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_LCL, LOAD_TYPE_FCL } from 'operations/baseConstants';
import { EmailProps } from 'operations/components/ChangeEvent/EventEmailDrawer';
import {
  BOOKING_TYPE_SELF,
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
} from 'common/baseConstants';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { updateOto } from '../OTOActions/otoActionHelper';
import { getFetchDocumentFunction } from 'operations/modules/helpers';
import { getIsCustomClearanceByServices } from 'operations/modules/shipment/components/NewShipmentForm/helpers';

export const PERMISSION_CREDIT_CONTROL_WRITE = 'credit_control';

const isHouse = (shiment_type: string | undefined | null) => {
  return shiment_type === SHIPMENT_TYPE_HOUSE;
};

export const getMasterDocument = (shipment: ShipmentValue) => {
  return shipment.shipment_documents?.find((sd: any) => sd.document_type === 'master');
};

export const getHouseDocument = (shiment: ShipmentValue) => {
  return shiment.shipment_documents?.find((sd: any) => sd.document_type === 'house');
};

export const isShipmentCancelled = (shipment: ShipmentValue) => {
  return (
    shipment.status === SHIPMENT_STATUS_CANCELLED ||
    shipment.status === SHIPMENT_STATUS_BACK_TO_TOWN
  );
};

export const masterDocumentFinalizedOrHandOver = (shipment: ShipmentValue) => {
  const masterDocument = shipment.shipment_documents?.find((sd) => sd.document_type === 'master');
  return [
    SHIPMENT_STATUS_EXECUTED,
    DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
    DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
    DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
  ].includes(get(masterDocument, 'document_status') || '');
};

export const houseDocumentFinalized = (shipment: ShipmentValue) => {
  const houseDocument = getHouseDocument(shipment);
  return [
    SHIPMENT_STATUS_EXECUTED,
    DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
    DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
    DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
  ].includes(get(houseDocument, 'document_status') || '');
};

export const canEditAirline = (shipment: ShipmentValue) => {
  const doc_master = shipment?.shipment_documents?.find(
    (r: ShipmentDocumentValue) => r.document_type === 'master'
  );
  if (!doc_master) return false;
  const stock_order_item_id = get(doc_master, 'stock_order_item.id');
  const doc_status = get(doc_master, 'document_status');
  if (doc_status) {
    return (
      shipment.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      stock_order_item_id &&
      [
        DOCUMENT_STATUS_PENDING_CREATION,
        DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
        DOCUMENT_STATUS_DRAFT,
      ].includes(doc_status)
    );
  } else {
    return false;
  }
};

export const isEnableRoadAction = (shipment: ShipmentValue) => {
  const status = startCase(shipment?.last_action_status || '');
  const isReleasedLR = (shipment?.shipment_documents || []).some(
    (document) => document.document_status === DOCUMENT_STATUS_EXECUTED
  );
  if (
    shipment.freight_type === FREIGHT_TYPE_ROAD &&
    shipment.accounting_status !== ACCOUNTING_STATUS_CLOSED &&
    status !== ACTION_STATUS_SHIPMENT_CANCELLED &&
    !isReleasedLR
  )
    return true;
  return false;
};

export const isEnableLoadAction = (shipment: ShipmentValue) => {
  const allocationPendingQuantity = get(shipment, 'allocation_pending_quantity');
  if (
    isEnableRoadAction(shipment) &&
    !(shipment?.load_type === LOAD_TYPE_FCL && allocationPendingQuantity === 0) &&
    ([SHIPMENT_TYPE_BACK_TO_BACK, SHIPMENT_TYPE_CONSOL].includes(shipment.shipment_type || '') ||
      !!(!shipment.job_number && shipment.freight_type === FREIGHT_TYPE_ROAD))
  )
    return true;
  return false;
};

export const editAirline = (shipment: ShipmentValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: `booking_edit_airline`,
    icon: <EditOutlined />,
    displayComponent: 'Change Airline Information',
    onClick: () => {
      window.open(`${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment?.id}/edit_airline`, '_self');
    },
    isEnable: (shipment.status !== SHIPMENT_STATUS_CANCELLED && canEditAirline(shipment)) || false,
  };
};

export const cancelShipment = (shipment: ShipmentValue, permissions: PermissionValue[]): Action => {
  const hasPickedUpContainers = shipment?.shipment_containers?.find(
    (container) => container.container_number
  );
  const getCancelLabel = (shipment: ShipmentValue) => {
    if (!shipment.job_number) return 'Cancel Customer Order';
    if (shipment.isRoadSplitShipment()) {
      if (isHouse(shipment.shipment_type)) return 'Cancel Consignment Note';
      else return 'Cancel Trip';
    }

    return `Cancel ${isHouse(shipment?.shipment_type) ? 'House' : ''} Shipment`;
  };
  const isEnable = () => {
    if (
      !hasPermission(permissions, {
        name: PERMISSION_CANCEL_SHIPMENT,
        docType: 'Shipment::Shipment',
      })
    )
      return false;
    if (shipment.shipment_type === SHIPMENT_TYPE_GENERAL) {
      return shipment && shipment.isMasterShipment() && !shipment.isShipmentCancelled();
    }
    if (hasPickedUpContainers) {
      return false;
    }
    if (!shipment.isAccountingOpen()) return false;
    if (shipment.isRoadSplitShipmentExecuted()) {
      return false;
    }

    return (
      shipment &&
      (shipment.isMasterShipment() ||
        (shipment.isHouseShipment() && !shipment.master_shipment_id)) &&
      !shipment.isShipmentCancelled() &&
      !(shipment.isOceanShipment() && shipment.any_house_linked_with_lcl_booking)
    );
  };
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_shipment',
    icon: <CustomIcon icon="FileTimesIcon" />,
    displayComponent: getCancelLabel(shipment),
    isEnable: isEnable(),
    performAction: cancelShipmentRenderer,
  };
};

export const backtoTownShipment = (
  shipment: ShipmentValue,
  permissions: PermissionValue[]
): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'back_to_town',
    icon: <IssuesCloseOutlined />,
    displayComponent: 'Back To Town',
    isEnable:
      ![SHIPMENT_TYPE_HOUSE].includes(shipment.shipment_type || '') &&
      shipment.status !== SHIPMENT_STATUS_CANCELLED &&
      shipment.status !== SHIPMENT_STATUS_BACK_TO_TOWN &&
      hasPermission(permissions, {
        name: PERMISSION_CANCEL_SHIPMENT,
        docType: 'Shipment::Shipment',
      }) &&
      shipment.freight_type !== 'ocean',
    performAction: backtoTownRenderer,
  };
};

export const dispatchDocket = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'dispatch_docket',
    icon: shipment?.document_dispatch_date ? (
      <CustomIcon icon="ResendIcon" />
    ) : (
      <CustomIcon icon="ShareFileIcon" />
    ),
    displayComponent: shipment?.document_dispatch_date ? 'Redispatch' : 'Dispatch',
    isEnable:
      shipment?.shipment_type !== SHIPMENT_TYPE_CONSOL &&
      shipment.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      !!shipment.job_number &&
      shipment.status !== SHIPMENT_STATUS_CANCELLED,
    performAction: dispatchDocketRenderer,
  };
};

export const offloadContainers = (shipment: ShipmentValue): Action => {
  // let isEnable = shipment.load_type === LOAD_TYPE_FCL && shipment?.status !== STATUS_CANCELLED;

  // const containers =
  //   shipment?.ocean_transport_orders &&
  //   shipment?.ocean_transport_orders
  //     .filter((oto) => oto.is_independent)
  //     .map((oto) => oto.shipment_containers)
  //     .flat();
  // if (containers && containers.filter((container) => container.status === 'active').length === 0) {
  //   isEnable = false;
  // }
  const hasActiveContainers = shipment?.shipment_containers?.some(
    (container) => container.status === 'active'
  );
  return {
    type: NEGATIVE_TYPE,
    key: 'offload_containers',
    displayComponent: 'Offload / Cancel',
    icon: <CustomIcon icon="FileTimesIcon" />,
    isEnable: hasActiveContainers || false,
    performAction: offloadContainersFormRenderer,
  };
};

const createMaterialIssue = (shipment: ShipmentValue, sessionData: SessionDataValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'create_material_issue',
    icon: <ShoppingCartOutlined />,
    displayComponent: (
      <a
        href={`${
          sessionData?.company_account?.subdomain
        }/desk#Form/Stock%20Entry/New%20Stock%20Entry%201?category=Shipment&project=${encodeURIComponent(
          shipment.job_number || shipment.shipment_booking_number || ''
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        Create Material Issue
      </a>
    ),
    isEnable: !!sessionData.isFeatureEnabled('inventory_management'),
  };
};

export const createShipmentFromCOForEachType = (
  type: string,
  shipment_id: string,
  shipmentDocument: ShipmentDocumentValue | undefined
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: type,
    displayComponent: (
      <a
        href={`${process.env.SHIPMNTS_WEB_URL}/shipment/create/from_customer_order?shipment_id=${shipment_id}&&shipment_type=${type}`}
        target="_blank"
        rel="noreferrer"
      >
        Create {startCase(type)}
      </a>
    ),
    isEnable: type === 'house' ? !shipmentDocument?.shipment_number : true,
  };
};

const addCreateShipmentActions = (shipment: ShipmentValue, shipmentType: ShipmentType): Action => {
  return {
    type: PRIMARY_TYPE,
    key: shipmentType,
    displayComponent: startCase(shipmentType),
    isEnable: true,
    performAction: addCreateShipmentFromBrRenderer,
    extraProps: { shipmentType, action: 'create' },
  };
};

export const splitShipmentActions = (
  shipment: ShipmentValue,
  shipmentType: string | null | undefined
): Action => {
  const isSplitEnable = (shipment: ShipmentValue) => {
    const containers = shipment.shipment_containers || [];
    return (
      shipment.isFcl() &&
      shipment.shipment_type !== SHIPMENT_TYPE_CONSOL &&
      containers.length > 1 &&
      !containers.find((sc: any) => sc.container_number == null)
    );
  };
  return {
    type: SECONDARY_TYPE,
    key: 'split_shipment',
    icon: <FileAddOutlined />,
    displayComponent: 'Split Shipment',
    isEnable:
      isSplitEnable(shipment) &&
      !shipment.isAccountingClosed() &&
      !shipment.isAccountingBillingClosed() &&
      !shipment.isExportHouseExecuted() &&
      !shipment.isExportMasterExecuted(),
    performAction: addCreateShipmentFromBrRenderer,
    extraProps: { shipmentType, action: 'split' },
  };
};

const createShipmentFromBR = (shipment: ShipmentValue): Action => {
  // let renderCreateShipmentButton = false;
  // if (shipment?.load_type === LOAD_TYPE_FCL) {
  //   Array.isArray(shipment?.shipment_containers) &&
  //     shipment?.shipment_containers.forEach((sc) => {
  //       if (sc?.status === 'active' && sc?.container_cargo_details?.length === 0) {
  //         renderCreateShipmentButton = true;
  //       }
  //     });
  // }
  // else {
  //   if (shipment?.shipments?.length === 0) renderCreateShipmentButton = true;
  // }
  // TODO: add condition for LCL

  return {
    type: PRIMARY_TYPE,
    key: 'create_shipment',
    displayComponent: 'Create Shipment',
    icon: <CompassOutlined />,
    isEnable:
      shipment.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      shipment.status === SHIPMENT_STATUS_PLANNED &&
      shipment.isOceanShipment() &&
      (shipment.isLcl() ||
        shipment.isImportShipment() ||
        shipment.hasMinimumOneContainerAllocated()),
    childComponents: [
      addCreateShipmentActions(shipment, SHIPMENT_TYPE_DIRECT),
      // addCreateShipmentActionsPo(bookingRequest, `From SO or PO`),
      addCreateShipmentActions(shipment, SHIPMENT_TYPE_BACK_TO_BACK),
      addCreateShipmentActions(shipment, SHIPMENT_TYPE_HOUSE),
    ],
  };
};

const allocationAction = (shipment: ShipmentValue): Action => {
  const notAllowedBookingTypes = [BOOKING_TYPE_SELF, BOOKING_TYPE_VENDOR];
  return {
    type: PRIMARY_TYPE,
    key: `allocate_booking`,
    displayComponent: 'Allocate Booking',
    icon: <TagsOutlined />,
    isEnable:
      !shipment.ocean_transport_orders?.some((oto) =>
        notAllowedBookingTypes.includes(oto.booking_type)
      ) &&
      shipment.trade_type !== TRADE_TYPE_IMPORT &&
      shipment.load_type === LOAD_TYPE_FCL &&
      // shipment?.is_unfulfilled &&
      shipment.status !== STATUS_CANCELLED,
    performAction: allocateOTOReportRenderer,
  };
};

const createCarrierBooking = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_carrier_booking',
    displayComponent: 'Create Shipping Line Booking',
    icon: <CustomIcon icon="ShipIconNew" />,
    isEnable:
      shipment.trade_type !== TRADE_TYPE_IMPORT &&
      shipment.load_type === LOAD_TYPE_FCL &&
      // shipment?.is_unfulfilled &&
      shipment.status !== STATUS_CANCELLED &&
      !shipment.ocean_transport_orders?.some(
        (order: any) =>
          order?.booking_type === BOOKING_TYPE_SELF || order?.booking_type === BOOKING_TYPE_VENDOR
      ),
    performAction: CreateBookingOrderFromShipmentRenderer,
    extraProps: { type: BOOKING_TYPE_SHIPPING_LINE },
  };
};

const createVendorBooking = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_vendor_booking',
    displayComponent: 'Create Forwarder FCL Booking',
    icon: <SolutionOutlined />,
    isEnable:
      shipment.status !== STATUS_CANCELLED &&
      !shipment.ocean_transport_orders?.some(
        (order: any) =>
          order?.booking_type === BOOKING_TYPE_SELF ||
          order?.booking_type === BOOKING_TYPE_SHIPPING_LINE
      ),
    performAction: CreateBookingOrderFromShipmentRenderer,
    extraProps: { type: BOOKING_TYPE_VENDOR },
  };
};

const createSelfBooking = (shipment: ShipmentValue, sessionData: SessionDataValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'create_self_booking',
    displayComponent: 'Create CRO / Self Booking',
    icon: <SolutionOutlined />,
    isEnable:
      shipment.trade_type !== TRADE_TYPE_IMPORT &&
      shipment.load_type === LOAD_TYPE_FCL &&
      // shipment?.is_unfulfilled &&
      shipment.status !== STATUS_CANCELLED &&
      !shipment.ocean_transport_orders?.some(
        (order: any) =>
          order?.booking_type === BOOKING_TYPE_SELF ||
          order?.booking_type === BOOKING_TYPE_SHIPPING_LINE ||
          order?.booking_type === BOOKING_TYPE_VENDOR
      ) &&
      sessionData?.company_account?.business_type.includes('non_vessel_owner_container_carrier'),
    performAction: CreateBookingOrderFromShipmentRenderer,
    extraProps: { type: BOOKING_TYPE_SELF },
  };
};

export const createOrAllocateBooking = (
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  let otoQty = 0;
  shipment.ocean_transport_orders?.forEach((oto: OceanTransportOrderValue) => {
    (oto.container_requests || []).forEach((cr) => {
      otoQty += cr.quantity_fulfilled;
    });
  });
  const isManufacturerOrTrader =
    sessionData?.company_account?.primary_business === 'manufacturer' ||
    sessionData?.company_account?.primary_business === 'trader';

  const isLCLWithOneBooking =
    shipment.ocean_transport_orders?.length && shipment.load_type === LOAD_TYPE_LCL;

  return {
    type: PRIMARY_TYPE,
    key: 'create_or_allocate_booking',
    displayComponent: 'Create Or Allocate Booking',
    icon: <FileAddOutlined />,
    isEnable:
      !isLCLWithOneBooking &&
      otoQty < (shipment.shipment_containers || []).length &&
      !shipment.master_shipment_id &&
      !shipment.isAccountingClosed() &&
      !shipment.isAccountingBillingClosed() &&
      !isManufacturerOrTrader &&
      shipment.status !== SHIPMENT_STATUS_CANCELLED,
    childComponents: [
      allocationAction(shipment),
      createCarrierBooking(shipment),
      createVendorBooking(shipment),
      createSelfBooking(shipment, sessionData),
    ],
  };
};

export const createShipmentCustomerOrder = (shipment: ShipmentValue): Action => {
  const master_document = getMasterDocument(shipment);
  return {
    type: PRIMARY_TYPE,
    key: 'create_shipment_customer_order',
    displayComponent: 'Create Shipment',
    icon: <CompassOutlined />,
    isEnable:
      shipment.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      shipment.status === SHIPMENT_STATUS_PLANNED &&
      !shipment.isOceanShipment() &&
      !shipment.job_number,
    childComponents: [
      createShipmentFromCOForEachType('direct', shipment.id, master_document),
      createShipmentFromCOForEachType('back_to_back', shipment.id, master_document),
      createShipmentFromCOForEachType('house', shipment.id, master_document),
    ],
  };
};

export const createNewRoadShipment = (shipment: ShipmentValue): Action => {
  const pickupPending =
    shipment.shipment_containers?.filter((c) => c.status === STATUS_PICKUP_PENDING) || [];
  const allocationPending = (shipment?.allocation_pending_quantity || 0) > 0;
  const showCreateRoad =
    allocationPending &&
    ((shipment?.shipment_containers?.length || 0) > 0
      ? pickupPending.length === 0 || pickupPending.length === shipment.shipment_containers?.length
      : true);

  return {
    type: PRIMARY_TYPE,
    key: 'create_new_road_shipment',
    displayComponent: 'Create New Road Shipment',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable:
      shipment.freight_type === FREIGHT_TYPE_ROAD &&
      shipment.shipment_type === SHIPMENT_TYPE_CONSOL &&
      !!showCreateRoad,
    performAction: createNewRoadShipmentRenderer,
  };
};

export const assignVehicle = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'assign_vehicle',
    displayComponent: 'Assign Vehicle',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable: isEnableRoadAction(shipment) && shipment.isRoadCustomerOrder(),
    performAction: assignVehicleRender,
  };
};

export const assignLoad = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'assign_load',
    displayComponent: 'Assign Load',
    icon: <CustomIcon icon="Package" />,
    isEnable: isEnableLoadAction(shipment),
    performAction: assignLoadRender,
  };
};

export const notifyOnEmail = (
  shipment: ShipmentValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue,
  client: ApolloClient<object>,
  config_data?: any
): Action => {
  const shipmentObj = shipment;
  const default_company = sessionData?.company_account?.default_company;

  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_email',
    displayComponent: 'Notify On Email',
    icon: <MailOutlined />,
    isEnable:
      shipment.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      shipment.status !== SHIPMENT_STATUS_CANCELLED,
    onClick: () => {
      setEmailProps({
        fetchDocuments: getFetchDocumentFunction(shipment, client, sessionData, config_data),
        title: 'Send Email Notification',
        docTypeId: 'Shipment::Shipment',
        resource_ids: [shipment.id],
        fetchDocumentParents: [
          {
            parent_type: 'shipment',
            parent_ids: [shipment.id],
          },
          {
            parent_type: 'ocean_transport_order',
            parent_ids: [shipment?.ocean_transport_orders?.[0]?.id],
          },
        ],
        companies_roles_mapping: default_company
          ? shipmentObj.getAllPartiesRolesMapping(sessionData)
          : [],
      });
      setVisible(true);
    },
  };
};

export const notifyOnWhatsApp = (
  shipment: ShipmentValue,
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
): Action => {
  const shipmentObj = shipment;
  const default_company = sessionData?.company_account?.default_company;
  return {
    type: TERTIARY_TYPE,
    key: 'notify_on_wa',
    displayComponent: 'Notify On WhatsApp',
    icon: <WhatsAppOutlined />,
    isEnable:
      shipment.shipment_type !== SHIPMENT_TYPE_GENERAL &&
      shipment.status !== SHIPMENT_STATUS_CANCELLED,
    onClick: () => {
      setEmailProps({
        title: 'Send WhatsApp Notification',
        docTypeId: 'Shipment::Shipment',
        resource_ids: [shipment.id],
        companies_roles_mapping: default_company
          ? shipmentObj.getAllPartiesRolesMapping(sessionData)
          : [],
        showMarkDown: true,
        showEmail: false,
      });
      setVisible(true);
    },
  };
};

export const billingClose = (
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Close Billing',
    key: 'billing_close',
    icon: <CustomIcon icon="CloseBillingIcon" />,
    isEnable:
      !shipment.master_shipment_id &&
      hasPermission(permissions, {
        name: PERMISSION_BILLING_CLOSE,
        docType: 'Shipment::Shipment',
      }) &&
      shipment.accounting_status === ACCOUNTING_STATUS_PENDING,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_SHIPMENT_ACCOUNTING_STATUS,
        variables: {
          id: shipment.id,
          next_status: ACCOUNTING_STATUS_BILLING_CLOSED,
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data) {
        message.success(
          'Shipment status changed to ' +
            startCase(data?.update_accounting_status?.accounting_status)
        );
        if (onSuccess) onSuccess();
      }
    },
  };
};

export const reopenBilling = (
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  return {
    type: NEGATIVE_TYPE,
    displayComponent: 'Reopen Billing',
    isEnable:
      !shipment.master_shipment_id &&
      shipment.accounting_status === ACCOUNTING_STATUS_BILLING_CLOSED &&
      hasPermission(permissions, {
        name: PERMISSION_REOPEN_BILLING,
        docType: 'Shipment::Shipment',
      }),
    key: 'reopen_billing',
    icon: <CustomIcon icon="FileContractIcon" />,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: UPDATE_SHIPMENT_ACCOUNTING_STATUS,
        variables: {
          id: shipment.id,
          next_status: ACCOUNTING_STATUS_PENDING,
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data) {
        message.success(
          'Shipment status changed to ' +
            startCase(data?.update_accounting_status?.accounting_status)
        );
        if (onSuccess) onSuccess();
      }
    },
  };
};
export const workflow = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'Workflow',
    icon: <ApartmentOutlined />,
    displayComponent: 'Workflow',
    isEnable:
      shipment.status !== SHIPMENT_STATUS_CANCELLED &&
      shipment.accounting_status !== ACCOUNTING_STATUS_CLOSED,
    childComponents: getShipmentNextEvents(shipment),
  };
};

export const getShipmentNextEvents = (shipment: ShipmentValue): Action[] => {
  const { next_actions } = shipment;
  const actions: Action[] = [];
  (next_actions || []).forEach((action: string) => {
    actions.push({
      key: action,
      type: PRIMARY_TYPE,
      displayComponent: startCase(action),
      icon: <CompassOutlined />,
      isEnable: true,
      performAction: performShipmentEvent,
      description: action,
      extraProps: { event_name: action, event_type: 'UPDATE' },
    });
  });
  return actions;
};

export const creditApprove = (shipment: ShipmentValue, permissions: PermissionValue[]): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'approve_credit_hold',
    displayComponent: 'Approve Credit Hold',
    icon: <CheckCircleOutlined />,
    isEnable:
      shipment &&
      ![SHIPMENT_STATUS_CANCELLED, SHIPMENT_STATUS_BACK_TO_TOWN].includes(shipment.status || '') &&
      [CREDIT_STATUS_ON_HOLD, CREDIT_STATUS_PENDING, CREDIT_STATUS_ON_AUTO_HOLD].includes(
        shipment.credit_status || ''
      ) &&
      hasPermission(permissions, {
        name: PERMISSION_CREDIT_CONTROL_WRITE,
        docType: 'Network::Company',
      }),
    performAction: creditControlRenderer,
    extraProps: {
      action_name: CREDIT_STATUS_APPROVED_EVENT,
    },
  };
};

export const creditOnHold = (shipment: ShipmentValue, permissions: PermissionValue[]): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'credit_on_hold',
    displayComponent: 'Credit On Hold',
    icon: <FileExclamationOutlined />,
    isEnable:
      shipment &&
      ![SHIPMENT_STATUS_CANCELLED, SHIPMENT_STATUS_BACK_TO_TOWN, SHIPMENT_STATUS_PLANNED].includes(
        shipment.status || ''
      ) &&
      shipment.credit_status === CREDIT_STATUS_PENDING &&
      hasPermission(permissions, {
        name: PERMISSION_CREDIT_CONTROL_WRITE,
        docType: 'Network::Company',
      }),
    performAction: creditControlRenderer,
    extraProps: {
      action_name: CREDIT_STATUS_ON_HOLD_EVENT,
    },
  };
};

export const finance = (
  shipment: ShipmentValue,
  client: ApolloClient<object>,
  permissions: PermissionValue[]
): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'finance',
    displayComponent: 'Finance',
    isEnable: true,
    icon: <DollarCircleOutlined />,
    childComponents: [
      dispatchDocket(shipment),
      billingClose(shipment, client, permissions),
      reopenBilling(shipment, client, permissions),
      jobClose(shipment, permissions),
      reopenJob(shipment, permissions),
      creditApprove(shipment, permissions),
      creditOnHold(shipment, permissions),
    ],
  };
};

export const delinkHouseShipment = (shipment: ShipmentValue): Action => {
  const isEnable = () => {
    if (shipment.master_shipment_id === null) {
      return false;
    }
    if (![SHIPMENT_TYPE_HOUSE, SHIPMENT_TYPE_CONSOL].includes(shipment?.shipment_type || ''))
      return false;

    if (shipment.isAccountingClosed()) return false;
    if (shipment.isShipmentCancelled()) return false;
    if (shipment?.master_shipment && masterDocumentFinalizedOrHandOver(shipment?.master_shipment))
      return false;
    if (shipment.trade_type === TRADE_TYPE_EXPORT && houseDocumentFinalized(shipment)) {
      return false;
    }
    if (
      !!shipment.is_from_consol_helper ||
      (shipment.freight_type === FREIGHT_TYPE_OCEAN && shipment.trade_type === TRADE_TYPE_IMPORT)
    )
      return true;
    if (
      !!shipment.is_from_consol_helper ||
      (shipment.freight_type === FREIGHT_TYPE_OCEAN &&
        shipment.trade_type === TRADE_TYPE_EXPORT &&
        shipment.load_type === LOAD_TYPE_LCL &&
        !!shipment.is_linked_with_booking)
    )
      return true;
    return false;
  };
  return {
    type: NEGATIVE_TYPE,
    key: 'delink_house_shipment',
    displayComponent: 'Delink House Shipment',
    icon: <DisconnectOutlined />,
    isEnable: isEnable(),
    performAction: delinkHouseShipmentRenderer,
  };
};

export const deleteHouseShipment = (shipment: ShipmentValue): Action => {
  const isEnable = () => {
    if (shipment.shipment_type !== SHIPMENT_TYPE_HOUSE) return false;
    if (shipment.isAccountingClosed()) return false;
    if (isShipmentCancelled(shipment)) return false;

    if (shipment?.master_shipment && masterDocumentFinalizedOrHandOver(shipment.master_shipment))
      return false;

    if (shipment.trade_type === TRADE_TYPE_EXPORT && houseDocumentFinalized(shipment)) {
      return false;
    }
    if (shipment.is_linked_with_booking) {
      return false;
    }
    if (!shipment.is_from_consol_helper) return true;
    return false;
  };
  return {
    type: NEGATIVE_TYPE,
    key: 'delete_house_shipment',
    displayComponent: 'Delete House Shipment',
    icon: <DeleteOutlined />,
    isEnable: isEnable(),
    performAction: deleteHouseShipmentRenderer,
  };
};

//handles pickup container and add new container
export const addContainerDetails = (shipment: ShipmentValue): Action => {
  const pickupPending =
    shipment.shipment_containers?.some((c) => c.status === STATUS_PICKUP_PENDING) || false;
  const hasMinOneConfirmedBooking =
    shipment.ocean_transport_orders?.some((oto) => oto.status === STATUS_CONFIRMED) || false;
  const isEnable = () => {
    if (shipment.trade_type === TRADE_TYPE_IMPORT && shipment.load_type === LOAD_TYPE_LCL) {
      if (
        shipment.status === SHIPMENT_STATUS_PLANNED ||
        shipment.shipment_type === SHIPMENT_TYPE_HOUSE
      ) {
        return false;
      }
    }
    return (
      (hasMinOneConfirmedBooking || shipment?.freight_type === FREIGHT_TYPE_ROAD) && pickupPending
    );
  };

  return {
    type: PRIMARY_TYPE,
    key: 'add_container_details',
    displayComponent: 'Add Containers Details',
    icon: <CustomIcon icon="ContainerIcon" />,
    isEnable: isEnable(),
    performAction: addShipmentContainerDetailsActionRenderer,
  };
};

export const createContainer = (shipment: ShipmentValue): Action => {
  const pickupPending =
    shipment.shipment_containers?.some((c) => c.status === STATUS_PICKUP_PENDING) || false;
  const isEnable = () => {
    if (shipment.trade_type === TRADE_TYPE_IMPORT && shipment.load_type === LOAD_TYPE_LCL) {
      if (
        shipment.status === SHIPMENT_STATUS_PLANNED ||
        shipment.shipment_type === SHIPMENT_TYPE_HOUSE
      ) {
        return false;
      }
    }
    return (
      !shipment.master_shipment_id &&
      !pickupPending &&
      !shipment.isContainerAddDisabled() &&
      (shipment.load_type !== LOAD_TYPE_FCL || shipment.ocean_transport_orders?.length === 1)
    );
  };
  return {
    type: PRIMARY_TYPE,
    key: 'create_container',
    displayComponent: 'Add Container',
    icon: <CustomIcon icon="ContainerIcon" />,
    isEnable: isEnable(),
    performAction: createContainerActionRenderer,
  };
};

export const finaliseConsol = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'finalise_consol',
    displayComponent: 'Finalise Consol',
    icon: <AuditOutlined />,
    isEnable:
      !!shipment?.is_from_consol_helper &&
      shipment.isConsolShipment() &&
      shipment.isImportShipment() &&
      !shipment.isDocumentFinalised('master') &&
      !shipment.isShipmentCancelled() &&
      shipment.isAccountingOpen(),
    performAction: finaliseConsolRenderer,
  };
};

export const preAlertPending = (shipment: ShipmentValue): Action => {
  const isEnable = () => {
    return (
      shipment?.trade_type === TRADE_TYPE_EXPORT &&
      !Boolean(shipment.master_shipment_id) &&
      !shipment.isPlanned()
    );
  };
  return {
    type: SECONDARY_TYPE,
    key: 'send_pre_alert',
    icon: shipment.ops_pre_alert_sent_date ? (
      <CustomIcon icon="DoneIcon" />
    ) : (
      <CustomIcon icon="EnvelopeUploadIcon" />
    ),
    displayComponent: shipment.ops_pre_alert_sent_date ? 'Resend Pre-Alert' : 'Send Pre-Alert',
    isEnable: isEnable(),
    performAction: sendPreAlertRenderer,
  };
};

export const addNewHouseShipment = (shipment: ShipmentValue): Action => {
  const linkedFCLHouses =
    shipment?.house_shipments?.filter((sp) => sp.load_type === LOAD_TYPE_FCL).length ?? 0;
  const totalContainers = shipment?.shipment_containers?.length ?? 0;
  return {
    type: PRIMARY_TYPE,
    key: 'add_new_house_shipment',
    icon: <PlusCircleOutlined />,
    displayComponent:
      shipment.isRoadShipment() && shipment.split_from_order_id
        ? 'Add New CN'
        : 'Add New House Shipment',
    isEnable:
      (shipment.load_type !== LOAD_TYPE_FCL ||
        (shipment.freight_type === FREIGHT_TYPE_OCEAN && linkedFCLHouses < totalContainers)) &&
      !shipment.isShipmentCancelled() &&
      (shipment.freight_type !== FREIGHT_TYPE_ROAD || !!shipment.split_from_order_id) &&
      shipment.isConsolShipment() &&
      shipment.canEdit(),
    performAction:
      shipment.isRoadShipment() && shipment.split_from_order_id
        ? addNewHouseForRoadShipmentRender
        : addNewHouseShipmentRenderer,
  };
};

export const jobClose = (shipment: ShipmentValue, permissions: PermissionValue[]): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'close_job',
    icon: <CloseCircleOutlined />,
    displayComponent: 'Close Job',
    isEnable:
      !shipment.master_shipment_id &&
      hasPermission(permissions, {
        name: PERMISSION_ACCOUNTING_CLOSE,
        docType: 'Shipment::Shipment',
      }) &&
      !shipment.isAccountingClosed(),
    performAction: jobCloseRenderer,
  };
};

export const reopenJob = (shipment: ShipmentValue, permissions: PermissionValue[]): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'reopen_job',
    icon: <FolderOpenOutlined />,
    displayComponent: 'Reopen Job',
    isEnable:
      !shipment.master_shipment_id &&
      hasPermission(permissions, {
        name: PERMISSION_REOPEN_JOB,
        docType: 'Shipment::Shipment',
      }) &&
      shipment.isAccountingClosed(),
    performAction: reopenJobRenderer,
  };
};

export const printShipment = (shipment: ShipmentValue): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'print_shipment',
    icon: <FilePdfOutlined />,
    displayComponent: 'Print',
    isEnable: true,
    performAction: performShipmentSharePDF,
    description: 'Print Shipment',
  };
};

export const duplicate = (shipment: ShipmentValue, sessionData: SessionDataValue): Action => {
  const isManufacturerOrTrader =
    sessionData?.company_account?.primary_business === 'manufacturer' ||
    sessionData?.company_account?.primary_business === 'trader';
  const isHouseShipment = shipment.shipment_type === SHIPMENT_TYPE_HOUSE;
  const childComp =
    isManufacturerOrTrader || isHouseShipment
      ? [duplicateShipment(shipment, sessionData)]
      : [duplicateShipment(shipment, sessionData), duplicateOrder(shipment)];
  return {
    type: SECONDARY_TYPE,
    key: 'duplicate',
    displayComponent: 'Duplicate',
    isEnable:
      shipment.status !== SHIPMENT_STATUS_CANCELLED &&
      (![SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_HOUSE].includes(shipment.shipment_type || '') ||
        (shipment.shipment_type === SHIPMENT_TYPE_HOUSE && shipment.master_shipment)),
    icon: <CopyOutlined />,
    childComponents: childComp,
  };
};

export const duplicateRoadShipment = (shipment: ShipmentValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'duplicate_shipment',
    displayComponent: `Duplicate ${shipment?.getDisplayName() || 'Shipment'}`,
    icon: <CopyOutlined />,
    isEnable:
      !shipment?.isRoadHouseShipment() &&
      !shipment?.isRoadConsolShipment() &&
      !shipment?.isRoadBackToBackShipment(),
    onClick: () => {
      window.open(`/form/shipment/duplicate/${shipment.id}`);
    },
  };
};

export const duplicateOrder = (shipment: ShipmentValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'duplicate_order',
    displayComponent: 'Customer Order',
    icon: <CopyOutlined />,
    isEnable: shipment?.status !== SHIPMENT_STATUS_CANCELLED,
    onClick: () => {
      if (shipment.isOceanShipment()) {
        window.open(`/form/new_shipment/duplicate/${shipment.id}?is_customer_order=true`);
      } else {
        window.open(
          `${process.env.SHIPMNTS_WEB_URL}/shipment/create_duplicate/customer_order/${shipment.id}`
        );
      }
    },
  };
};

export const duplicateShipment = (
  shipment: ShipmentValue,
  sessionData: SessionDataValue
): Action => {
  const isCustomClearance = getIsCustomClearanceByServices(shipment?.services);
  return {
    type: SECONDARY_TYPE,
    key: 'duplicate_shipment',
    displayComponent: 'Shipment',
    icon: <CopyOutlined />,
    isEnable: shipment?.status !== SHIPMENT_STATUS_PLANNED,
    onClick: () => {
      if (shipment.isOceanShipment()) {
        window.open(
          sessionData?.company_account?.primary_business === 'manufacturer' ||
            sessionData?.company_account?.primary_business === 'trader'
            ? `/form/shipment/duplicate/${shipment.id}`
            : `/form/new_shipment/duplicate/${shipment.id}${
                isCustomClearance ? '?clearance_shipment=true' : ''
              }`
        );
      } else {
        window.open(
          sessionData?.company_account?.primary_business === 'manufacturer' ||
            sessionData?.company_account?.primary_business === 'trader'
            ? `/form/shipment/duplicate/${shipment.id}`
            : `${process.env.SHIPMNTS_WEB_URL}/shipment/create_duplicate/${shipment.shipment_type}/${shipment.id}`
        );
      }
    },
  };
};
export const shareJob = (shipment: ShipmentValue): Action => {
  return {
    type: TERTIARY_TYPE,
    key: 'share_job',
    icon: <UsergroupAddOutlined />,
    displayComponent: (
      <>
        Share Job{' '}
        {shipment?.accessible_by_branches && shipment?.accessible_by_branches.length > 0 && (
          <Badge style={{ marginLeft: '10px' }} count={shipment?.accessible_by_branches?.length} />
        )}
      </>
    ),
    isEnable: shipment.shipment_type !== SHIPMENT_TYPE_HOUSE,
    performAction: shareJobRenderer,
  };
};

export const linkRoadShipment = (shipment: ShipmentValue): Action => {
  const isNotCustomerOrder = shipment?.status !== SHIPMENT_STATUS_PLANNED;
  return {
    type: PRIMARY_TYPE,
    key: 'link_road',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable:
      shipment.freight_type !== FREIGHT_TYPE_ROAD &&
      isNotCustomerOrder &&
      !shipment.isConsolShipment(),
    displayComponent: 'Create Road Shipment',
    performAction: linkRoadShipmentRenderer,
  };
};

const updateProductsQty = (shipment: ShipmentValue, sessionData: SessionDataValue): Action => {
  return {
    key: 'update_products_qty',
    type: PRIMARY_TYPE,
    displayComponent: 'Update Products Qty',
    isEnable:
      sessionData?.company_account?.primary_business === 'manufacturer' ||
      sessionData?.company_account?.primary_business === 'trader',
    icon: <LinkOutlined />,
    performAction: performUpdateProductsQty,
    description: 'Update Products Qty',
  };
};
export const addCollaborators = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'add_collaborators',
    icon: <ShareAltOutlined />,
    isEnable: !shipment.isShipmentCancelled(),
    displayComponent: 'Add Collaborators',
    performAction: addCollaboratorsRenderer,
  };
};

export const changeAllocation = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'change_allocation',
    displayComponent: 'Change Allocation',
    icon: <FileAddOutlined />,
    isEnable:
      shipment?.trade_type !== TRADE_TYPE_IMPORT &&
      !getIsCustomClearanceByServices(shipment?.services) &&
      shipment.isOceanShipment() &&
      !shipment.isAccountingClosed() &&
      !shipment.isShipmentCancelled() &&
      !shipment.isAccountingBillingClosed() &&
      !shipment.isExportMasterExecuted() &&
      !shipment.isExportHouseExecuted() &&
      shipment.isFcl(),
    performAction: changeAllocationRenderer,
  };
};

export const mergeShipment = (shipment: ShipmentValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'merge_shipment',
    displayComponent: 'Merge Shipment',
    icon: <FileAddOutlined />,
    isEnable:
      shipment.isFcl() &&
      shipment.shipment_type !== SHIPMENT_TYPE_CONSOL &&
      !(shipment.shipment_containers || []).find((sc: any) => sc.container_number == null) &&
      !shipment.isAccountingClosed() &&
      !shipment.isAccountingBillingClosed() &&
      !shipment.isExportHouseExecuted() &&
      !shipment.isExportMasterExecuted() &&
      !shipment.isShipmentCancelled() &&
      !shipment.master_shipment_id,
    performAction: mergeShipmentRenderer,
  };
};

export const rolloverOrShutOut = (shipment: ShipmentValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'rollover_shutout',
    displayComponent: 'Rollover / Shutout',
    icon: <CustomIcon icon="EditRoadIcon" />,
    isEnable:
      shipment.isFcl() &&
      shipment.shipment_type !== SHIPMENT_TYPE_CONSOL &&
      !shipment.isShipmentCancelled() &&
      (shipment?.shipment_containers?.length || 0) > 1 &&
      shipment.hasMinimumOneContainerAllocated(),
    performAction: rolloverOrShutoutRenderer,
  };
};

export const routingUpdateDetailsOcean = (shipment: ShipmentValue): Action => {
  return {
    type: SECONDARY_TYPE,
    key: 'update_routing_details_ocean',
    displayComponent: 'Amend Booking',
    icon: <CustomIcon icon="EditRoadIcon" />,
    isEnable:
      !shipment.isShipmentCancelled() &&
      shipment.isOceanShipment() &&
      shipment.trade_type === TRADE_TYPE_EXPORT
        ? !shipment.isDocumentFinalised('house') &&
          !shipment.isMasterDocumentExecuted() &&
          !shipment.isHouseDocumentExecuted()
        : true,
    childComponents: shipment?.ocean_transport_orders?.map((oto) =>
      updateOto(oto, shipment, false)
    ),
  };
};

export const getShipmentActions = (
  shipment: ShipmentValue,
  permissions: PermissionValue[],
  setEmailProps: (emailProps: EmailProps) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue,
  client: ApolloClient<object>,
  navigate: any,
  config_data?: any
) => {
  if (shipment.freight_type === FREIGHT_TYPE_ROAD) {
    return [
      workflow(shipment),
      updateProductsQty(shipment, sessionData),
      duplicateRoadShipment(shipment),
      addContainerDetails(shipment),
      printShipment(shipment),
      shareJob(shipment),
      finance(shipment, client, permissions),
      createMaterialIssue(shipment, sessionData),
      cancelShipment(shipment, permissions),
      delinkHouseShipment(shipment),
      // createNewRoadShipment(shipment),
      assignVehicle(shipment),
      assignLoad(shipment),
      addCollaborators(shipment),
      updateTeam(shipment),
    ];
  }
  return [
    createOrAllocateBooking(shipment, sessionData),
    createMaterialIssue(shipment, sessionData),
    editAirline(shipment),
    cancelShipment(shipment, permissions),
    backtoTownShipment(shipment, permissions),
    duplicate(shipment, sessionData),
    createShipmentCustomerOrder(shipment),
    changeAllocation(shipment),
    offloadContainers(shipment),
    mergeShipment(shipment),
    splitShipmentActions(shipment, shipment?.shipment_type),
    notifyOnEmail(shipment, setEmailProps, setVisible, sessionData, client, config_data),
    notifyOnWhatsApp(shipment, setEmailProps, setVisible, sessionData),
    deleteHouseShipment(shipment),
    delinkHouseShipment(shipment),
    addContainerDetails(shipment),
    finaliseConsol(shipment),
    preAlertPending(shipment),
    printShipment(shipment),
    shareJob(shipment),
    finance(shipment, client, permissions),
    workflow(shipment),
    updateProductsQty(shipment, sessionData),
    linkRoadShipment(shipment),
    updateRoutingDetails(shipment, permissions),
    rolloverOrShutOut(shipment),
    routingUpdateDetailsOcean(shipment),
    addCollaborators(shipment),
    createShipmentFromBR(shipment),
    shareCroDocument(shipment, sessionData),
    updateTeam(shipment),
  ];
};

export const addContainerActions: any = (shipment: ShipmentValue) => {
  return [addContainerDetails(shipment)].filter((action) => action.isEnable);
};

export const assignLoadAction = (shipment: ShipmentValue) => {
  return [assignLoad(shipment)].filter((action) => action.isEnable);
};
export const containerActions: any = (shipment: ShipmentValue) => {
  return [addContainerDetails(shipment), createContainer(shipment)].filter(
    (action) => action.isEnable
  );
};

export const addManifestAction = (shipment: ShipmentValue) => {
  return [addContainerDetails(shipment)].filter((action) => action.isEnable);
};

export const updateRoutingDetails = (
  shipment: ShipmentValue,
  permissions: PermissionValue[]
): Action => {
  const isEnable = () => {
    if (shipment.isLcl()) return false;

    if (shipment.isHouseShipment()) return false;

    if (shipment.isAccountingClosed()) return false;

    if (shipment.isAccountingBillingClosed()) return false;

    if (shipment.isShipmentCancelled()) return false;

    if (shipment.master_shipment && masterDocumentFinalizedOrHandOver(shipment.master_shipment))
      return false;

    if (shipment.trade_type === TRADE_TYPE_EXPORT && houseDocumentFinalized(shipment)) return false;

    if (!shipment.ocean_transport_order_id) return false;

    if (
      !hasPermission(permissions, {
        name: PERMISSION_ROLLOVER_CONTAINER,
        docType: 'Shipment::ShipmentContainer',
      })
    )
      return false;

    return true;
  };

  return {
    type: SECONDARY_TYPE,
    key: 'update_routing_details',
    displayComponent: 'Update Routing Details',
    icon: <CustomIcon icon="EditRoadIcon" />,
    isEnable: isEnable(),
    performAction: updateOrSplitRollOverRenderer,
  };
};

export const addNewHouseOrLinkShipmentActions = (shipment: ShipmentValue) => {
  return [
    addNewHouseShipment(shipment),
    assignVehicle(shipment),
    // createNewRoadShipment(shipment)
  ];
};

const shareCroDocument = (shipment: ShipmentValue, sessionData: SessionDataValue): Action => {
  const booking_types = shipment?.ocean_transport_orders?.map((oto) => oto.booking_type);
  return {
    type: TERTIARY_TYPE,
    key: 'share_cro',
    displayComponent: 'Share CRO',
    icon: <FilePdfOutlined />,
    isEnable:
      !shipment.job_number &&
      shipment.isOceanShipment() &&
      shipment.load_type === LOAD_TYPE_FCL &&
      shipment.status !== STATUS_CANCELLED &&
      sessionData?.company_account?.business_type.includes('non_vessel_owner_container_carrier') &&
      booking_types &&
      booking_types?.includes(BOOKING_TYPE_SELF),
    performAction: shareCroRenderer,
    description: 'Share Container Release Order Document',
  };
};

export const updateTeam = (shipment: ShipmentValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'update_team',
    icon: <TeamOutlined />,
    isEnable: shipment.canEdit() && shipment.accounting_status !== ACCOUNTING_STATUS_BILLING_CLOSED,
    displayComponent: 'Update Team Details',
    performAction: updateTeamRenderer,
  };
};

export const cargoActions: any = (shipment: ShipmentValue) => {
  const actions = [];
  if (!shipment.isFcl()) {
    actions.push(assignLoad(shipment));
  }
  return actions.filter((action) => action.isEnable);
};
