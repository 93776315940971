import { BaseTable, Button, RenderLinkByDocType } from '@shipmnts/pixel-hub';
import { Column } from 'operations/models/Report';
import React, { useState } from 'react';
import ProductPropertyDrawer from '../ProductPropertyDrawer';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { omit } from 'lodash';
import {
  DimensionsWithUOM,
  QtyUomTypeRenderer,
} from 'common/report_manager/components/Renderer/cellRenderer';

interface WMSProductsProps {
  warehouseTransactionProducts: WarehouseTransactionValue[];
  type: string | undefined;
}

const WMSProducts = (props: WMSProductsProps) => {
  const { warehouseTransactionProducts, type } = props;
  const [showProductPropertyDrawer, setShowProductPropertyDrawer] = useState<boolean>(false);
  const [productPropertyParams, setProductPropertyParams] = useState<any>({});
  const tableData: any[] = [];

  if (warehouseTransactionProducts) {
    warehouseTransactionProducts?.forEach((element: any) => {
      tableData.push({
        ...omit(element, ['linked_to']),
        id: element?.linked_to?.warehouse_transaction?.id,
        transaction_number: element?.linked_to?.warehouse_transaction?.transaction_number,
      });
    });
  }

  const openPropertyDrawer = (params: any) => {
    setProductPropertyParams(params);
    setShowProductPropertyDrawer(true);
  };

  const columns: Column[] = [
    {
      headerName: 'Created From',
      field: 'transaction_number',
      colId: 'transaction_number',
      hide: type === 'incoming_shipment',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <RenderLinkByDocType
            key={params?.node?.data?.id}
            doc_type_id={'Wms::WarehouseTransaction'}
            id={params?.node?.data?.id}
            value={params?.node?.data?.transaction_number}
            extra_fields={{}}
            colDef={undefined}
          />
        );
      },
      maxWidth: 150,
      editable: false,
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      colId: 'product_name',
      cellEditor: 'SearchDocTypeEditor',
      editable: false,
      maxWidth: 300,
    },
    {
      headerName: 'Cust. Product Code',
      field: 'product_code',
      colId: 'product_code',
      columnType: 'String',
      editable: false,
    },
    {
      hide: type === 'receipt' || type === 'goods_transfer',
      headerName: 'Location',
      field: 'to_location',
      colId: 'to_location',
      editable: false,
      valueFormatter: (params: any) => params.value?.node_name,
      maxWidth: 300,
      filter: 'agSetColumnFilter',
      keyCreator: (params: any) => params.value?.node_name,
      filterParams: {
        convertValuesToStrings: true,
      },
    },
    {
      hide: type === 'delivery' || type === 'goods_transfer',
      headerName: 'Location',
      field: 'from_location',
      colId: 'from_location',
      editable: false,
      valueFormatter: (params: any) => params.value?.node_name,
      maxWidth: 300,
      filter: 'agSetColumnFilter',
      keyCreator: (params: any) => params.value?.node_name,
      filterParams: {
        convertValuesToStrings: true,
      },
    },
    {
      headerName: 'Packaging Type',
      field: 'packing_type',
      colId: 'packing_type',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Qty',
      field: 'qty',
      colId: 'qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
    },
    {
      headerName: 'Available Qty',
      field: 'available_qty',
      colId: 'available_qty',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      hide: type === 'delivery',
    },
    {
      headerName: 'Dimensions (L X B X H)',
      field: 'dimensions',
      colId: 'dimensions',
      columnType: 'String',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <DimensionsWithUOM
            length={params?.data?.length}
            breadth={params?.data?.breadth}
            height={params?.data?.height}
            dimension_unit={params?.data?.dimension_unit}
          />
        );
      },
    },
    {
      headerName: 'Gross Vol',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
      },
    },
    {
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
          />
        );
      },
    },
    {
      headerName: 'Vol. Weight',
      field: 'volumetric_weight',
      colId: 'volumetric_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.volumetric_weight} />;
      },
    },
    {
      headerName: 'Ch. Weight',
      field: 'chargeable_weight',
      colId: 'chargeable_weight',
      columnType: 'Float',
      editable: false,
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.chargeable_weight} />;
      },
    },
    {
      headerName: 'Batch #',
      field: 'batch_number',
      colId: 'batch_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Serial #',
      field: 'serial_number',
      colId: 'serial_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Properties',
      field: 'product_property',
      colId: 'product_property',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return <Button onClick={() => openPropertyDrawer(params)}>Show Property</Button>;
      },
      editable: false,
    },
    {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'String',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: false,
    },
    {
      headerName: 'Container #',
      field: 'container_number',
      colId: 'container_number',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Customs Ref #',
      field: 'customs_ref_code',
      colId: 'customs_ref_code',
      columnType: 'String',
      editable: false,
    },

    {
      headerName: 'Lot #',
      field: 'lot_number',
      colId: 'lot_number',
      columnType: 'String',
      editable: false,
    },
  ];

  return (
    <>
      <ProductPropertyDrawer
        key={showProductPropertyDrawer}
        setShowProductPropertyDrawer={setShowProductPropertyDrawer}
        showProductPropertyDrawer={showProductPropertyDrawer}
        params={productPropertyParams}
        editable={false}
      />

      <BaseTable
        rowData={tableData || []}
        reportName={type || 'products'}
        height={250}
        columns={columns}
        reportConfig={{
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          stopEditingWhenCellsLoseFocus: true,
          defaultColDef: {
            resizable: true,
          },
          animateRows: true,
        }}
      />
    </>
  );
};

export default WMSProducts;
