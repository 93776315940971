import {
  Card,
  Form,
  Drawer,
  message,
  Radio,
  DrawerFooter,
  FormRenderer,
  Layout,
  AppHelmet,
  Button,
  Space,
  Skeleton,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { getFinalOptions } from '../orderManagement/ProductOrderItem';
import {
  WEIGHT_UNIT_KGS,
  batteryTypes,
  coolingTypes,
  oceanPackageTypes,
  tempRange,
} from '../reports/constants';
import { observer } from 'mobx-react-lite';
import { ErpNextCompanyConfigDataContext } from 'network';
import {
  ERPNextCompanyConfigData,
  ErpNextCompanyConfigDataContextType,
} from '../shipment/components/DetailLayout/Estimates/ShipmentEstimateLayout';
import {
  TEMPERATURE_UNIT_CELSIUS,
  TEMPERATURE_UNIT_FAHRENHEIT,
  getUNDGNumberOptions,
  packingGroup,
} from 'network/baseConstants';
import { FormOutlined } from '@ant-design/icons';
import { GET_PRODUCT, UPSERT_WMS_PRODUCT } from 'operations/graphql/item';
import { useLazyQuery, useMutation } from '@apollo/client';
import { pick, omit } from 'lodash';
import { QtyUomTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';
import { ProductPackagingOptionsValue } from 'common/models/ProductPackagingOptions';
import { FormRendererField } from '../../../common/baseConstants';
import { errorMessageHandlerGraphQLString, useApplicationContentContext } from '../../../common';
import { useParams } from 'wouter';
import { ItemValue } from 'common/models/Item';

const CreateProductForm = React.memo(function CreateProductForm(props: any): JSX.Element {
  const [upsertWmsProduct, { data }] = useMutation(UPSERT_WMS_PRODUCT);
  const { setVisible, product, id, form, hideHeaderFooter = false } = props;
  const handleRadioClick = (rowIndex: number) => {
    const oldPackaginOption = form.getFieldValue('product_packaging_options');
    const newOption = oldPackaginOption.map((option: any, index: number) => {
      return {
        ...option,
        is_primary_packaging: index === rowIndex,
      };
    });
    form.setFieldsValue({ product_packaging_options: newOption });
  };
  useEffect(() => {
    if (data?.upsert_wms_product?.id) {
      if (!!id) message.success('Product Updated Successfully');
      else message.success('Product Created Successfully');
      if (setVisible) setVisible(false);
      props.onSuccess && props.onSuccess();
      props.onClose && props.onClose();
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, setVisible]);

  const dimension_unit = [
    { value: 'cms', label: 'cms' },
    { value: 'meter', label: 'meter' },
    { value: 'inches', label: 'inches' },
  ];

  const weight_unit = [
    { value: 'kgs', label: 'kgs' },
    { value: 'tons', label: 'tons' },
    { value: 'lbs', label: 'lbs' },
  ];

  const convertToMtr = (uom: string, value: number) => {
    if (uom === 'meter') return value;
    else if (uom === 'inches') return value * 0.0254;
    return value * 0.01;
  };

  const recalculateTotal = (data: any) => {
    const length = convertToMtr(data.dimension_unit, data.length || 0);
    const breadth = convertToMtr(data.dimension_unit, data.breadth || 0);
    const height = convertToMtr(data.dimension_unit, data.height || 0);
    const gross_volume = length * breadth * height;
    return {
      gross_volume,
    };
  };
  const columns: any[] = [
    {
      headerName: 'Is Primary Packaging',
      field: 'is_primary_packaging',
      colId: 'is_primary_packaging',
      pinned: 'left',
      cellRenderer: function radioRenderer(params: any) {
        if (params.node.isRowPinned()) return <></>;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Radio
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
              onChange={(e) => {
                params.data.is_primary_packaging = e.target.checked;
                handleRadioClick(params.rowIndex);
              }}
              checked={params.data?.is_primary_packaging || false}
            ></Radio>
          </div>
        );
      },
      editable: false,
    },
    {
      headerName: 'Package Type',
      required: true,
      field: 'packing_type',
      colId: 'packing_type',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        openSearch: false,
        options: getFinalOptions(oceanPackageTypes),
      },
      editable: (o: any) => !o.node.isRowPinned(),
    },
    {
      headerName: 'Dimension Unit',
      field: 'dimension_unit',
      colId: 'dimension_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: dimension_unit,
      },
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          force: true,
          columns: ['length', 'breadth', 'height'],
          rowNodes: [params.node],
        });
      },
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.dimension_unit || 'cms';
      },
      editable: (o: any) => !o.node.isRowPinned(),
    },
    {
      headerName: 'Length',
      field: 'length',
      colId: 'length',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.length}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.length || 0;
      },
      editable: (o: any) => !o.node.isRowPinned(),
    },
    {
      headerName: 'Width',
      field: 'breadth',
      colId: 'breadth',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o: any) => !o.node.isRowPinned(),
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.breadth}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.breadth || 0;
      },
    },
    {
      headerName: 'Height',
      field: 'height',
      colId: 'height',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o: any) => !o.node.isRowPinned(),
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.height}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.height || 0;
      },
    },
    {
      headerName: 'Gross Volume',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o: any) => !o.node.isRowPinned(),
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
      },
      valueGetter: (params: any) => {
        return params.data.gross_volume || 0;
      },
    },
    {
      headerName: 'Weight Unit',
      field: 'weight_unit',
      colId: 'weight_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: weight_unit,
        openSearch: false,
      },
      editable: (o: any) => !o.node.isRowPinned(),
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          force: true,
          columns: ['gross_weight', 'net_weight', 'tare_weight'],
          rowNodes: [params.node],
        });
      },
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.weight_unit || 'kgs';
      },
    },
    {
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      onCellValueChanged: (params: any) => {
        const data = params.data;
        const netWeight = parseFloat(data.net_weight);
        const grossWeight = parseFloat(data.gross_weight);
        if (!Number.isNaN(grossWeight) && !Number.isNaN(netWeight)) {
          if (data.gross_weight - data.net_weight >= 0) {
            data.tare_weight = data.gross_weight - data.net_weight;
            params.api.refreshCells({
              columns: ['tare_weight'],
              rowNodes: [params.node],
              force: true,
            });
          }
        }
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.gross_weight || 0;
      },
      editable: (o: any) => !o.node.isRowPinned(),
    },
    {
      headerName: 'Net Weight',
      field: 'net_weight',
      colId: 'net_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      onCellValueChanged: (params: any) => {
        const data = params.data;
        const netWeight = parseFloat(data.net_weight);
        const grossWeight = parseFloat(data.gross_weight);
        if (!Number.isNaN(grossWeight) && !Number.isNaN(netWeight)) {
          if (data.gross_weight - data.net_weight >= 0) {
            data.tare_weight = data.gross_weight - data.net_weight;
            params.api.refreshCells({
              columns: ['tare_weight'],
              rowNodes: [params.node],
              force: true,
            });
          }
        }
      },
      editable: (o: any) => !o.node.isRowPinned(),
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.net_weight}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.net_weight || 0;
      },
    },
    {
      headerName: 'Tare Weight',
      field: 'tare_weight',
      colId: 'tare_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.tare_weight}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.data.tare_weight || 0;
      },
    },
  ];
  const form_fields: { [key: string]: FormRendererField } = {
    basic_detail_section: {
      field_name: 'basic_detail_section',
      field_type: 'Section Break',
      label: 'Basic Detail',
    },
    product_name: {
      field_name: 'product_name',
      field_type: 'Text',
      label: 'Product Name',
      col_span: 12,
      field_required: true,
      component_props: {
        placeholder: 'Enter Product Name',
      },
    },
    basic_detail_row_break: {
      field_name: 'basic_detail_row_break',
      field_type: 'Row Break',
    },
    gst_hsn_code: {
      field_name: 'gst_hsn_code',
      field_type: 'Search',
      label: 'HSN Code',
      col_span: 12,
      component_props: {
        data_type: 'globalSearch',
        doc_type: 'ERP::HsnSearch',
        onChange: (params: any) => {
          //ToDo
        },
      },
    },
    country_of_origin: {
      field_name: 'country_of_origin',
      field_type: 'Search',
      label: 'Country Of Origin',
      col_span: 12,
      component_props: {
        data_type: 'EnumEditor',
        autoFocus: false,
        openSearch: false,
        options: getFinalOptions(props?.list_of_countries || []),
      },
    },
    customer_name_search: {
      field_name: 'customer_name_search',
      field_type: 'Search',
      label: 'Customer Name',
      col_span: 12,
      field_required: true,
      component_props: {
        data_type: 'Company',
        searchProps: {
          is_customer: true,
        },
      },
    },
    customer_ref_code: {
      field_name: 'customer_ref_code',
      field_type: 'Text',
      label: `Customer's Product Ref Code`,
      col_span: 12,
    },
    product_properties_section: {
      field_name: 'product_properties_section',
      field_type: 'Section Break',
      label: 'Product Properties',
    },
    is_temp_controlled: {
      field_name: 'is_temp_controlled',
      field_type: 'Boolean',
      label: 'Temp Controlled',
      col_span: 4,
    },
    is_perishable: {
      field_name: 'is_perishable',
      field_type: 'Boolean',
      label: 'Perishable',
      col_span: 4,
    },
    is_hazardous: {
      field_name: 'is_hazardous',
      field_type: 'Boolean',
      label: 'Hazardous',
      col_span: 3,
    },
    is_stackable: {
      field_name: 'is_stackable',
      field_type: 'Boolean',
      label: 'Stackable',
      col_span: 3,
    },
    is_tiltable: {
      field_name: 'is_tiltable',
      field_type: 'Boolean',
      label: 'Tilt-able',
      col_span: 3,
    },
    is_palletisable: {
      field_name: 'is_palletisable',
      field_type: 'Boolean',
      label: 'Palletisable',
      col_span: 3,
    },
    is_battery: {
      field_name: 'is_battery',
      field_type: 'Boolean',
      label: 'Battery Inside',
      col_span: 4,
    },
    product_properties_title_hazardous: {
      field_name: 'product_properties_title',
      field_type: 'Typography',
      col_span: 24,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      component_props: {
        title: 'Hazardous',
        level: 5,
        style: { color: '#0a4fca' },
      },
    },
    un_number: {
      field_name: 'un_number',
      field_type: 'Search',
      label: 'UNDG Number',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      field_required: true,
      component_props: {
        data_type: 'EnumEditor',
        openSearch: false,
        autoFocus: false,
        options: getUNDGNumberOptions(),
      },
    },
    un_imo_class: {
      field_name: 'un_imo_class',
      field_type: 'Text',
      label: 'UN IMO Class',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      field_required: true,
    },
    proper_shipping_name: {
      field_name: 'proper_shipping_name',
      field_type: 'Text',
      label: 'Proper Shipping Name',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
    },
    technical_name: {
      field_name: 'technical_name',
      field_type: 'Text',
      label: 'Technical Name',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
    },
    packaging_group: {
      field_name: 'packaging_group',
      field_type: 'Search',
      label: 'Packaging Group',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      component_props: {
        data_type: 'EnumEditor',
        openSearch: false,
        autoFocus: false,
        options: packingGroup,
      },
    },
    net_weight: {
      field_name: 'net_weight',
      field_type: 'InputWithUnit',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      noStyle: true,
      component_props: {
        qty_key: 'net_weight',
        unit_key: 'net_weight_unit',
        label: 'Net Weight',
        options: [{ key: WEIGHT_UNIT_KGS, name: WEIGHT_UNIT_KGS }],
        disabledUnitKey: true,
        inputNumberProps: { min: 0 },
      },
    },
    flash_point: {
      field_name: 'flash_point',
      field_type: 'InputWithUnit',
      col_span: 12,
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      noStyle: true,
      component_props: {
        qty_key: 'flash_point',
        unit_key: 'flash_point_unit',
        label: 'Flash Point',
        options: [
          { key: TEMPERATURE_UNIT_CELSIUS, name: TEMPERATURE_UNIT_CELSIUS },
          { key: TEMPERATURE_UNIT_FAHRENHEIT, name: TEMPERATURE_UNIT_FAHRENHEIT },
        ],
      },
    },
    ems_number: {
      field_name: 'ems_number',
      field_type: 'Text',
      label: 'EMS Number',
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      col_span: 12,
    },
    emergency_contact_name: {
      field_name: 'emergency_contact_name',
      field_type: 'Text',
      label: 'Emergency Contact Person',
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      col_span: 12,
    },
    emergency_contact_number: {
      field_name: 'emergency_contact_number',
      field_type: 'Text',
      label: 'Emergency Contact Number',
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      col_span: 12,
    },
    is_radioactive: {
      field_name: 'is_radioactive',
      field_type: 'Boolean',
      label: 'Radio Active',
      dependencies: 'is_hazardous',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_hazardous: true,
        },
      },
      col_span: 12,
    },
    product_properties_title_temp_control: {
      field_name: 'product_properties_title',
      field_type: 'Typography',
      col_span: 24,
      dependencies: 'is_temp_controlled',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_temp_controlled: true,
        },
      },
      component_props: {
        title: 'Temperature Controlled',
        level: 5,
        style: { color: '#0a4fca' },
      },
    },
    temperature_range: {
      field_name: 'temperature_range',
      field_type: 'Radio',
      label: 'Temparature Range',
      col_span: 12,
      dependencies: 'is_temp_controlled',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_temp_controlled: true,
        },
      },
      // noStyle: true,
      component_props: {
        title: 'Temperature Range',
        options: tempRange,
      },
    },
    cooling_type: {
      field_name: 'cooling_type',
      field_type: 'Search',
      field_required: true,
      label: 'Type of Cooling',
      col_span: 12,
      dependencies: 'is_temp_controlled',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_temp_controlled: true,
        },
      },
      component_props: {
        data_type: 'EnumEditor',
        openSearch: false,
        autoFocus: false,
        options: coolingTypes,
      },
    },
    product_properties_title_battery: {
      field_name: 'product_properties_title',
      field_type: 'Typography',
      col_span: 24,
      dependencies: 'is_battery',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_battery: true,
        },
      },
      component_props: {
        title: 'Battery Inside',
        level: 5,
        style: { color: '#0a4fca' },
      },
    },
    battery_type: {
      field_name: 'battery_type',
      field_required: true,
      field_type: 'Search',
      label: 'Battery Type',
      col_span: 12,
      dependencies: 'is_battery',
      dependent_rules: {
        effect: 'SHOW',
        condition: {
          is_battery: true,
        },
      },
      component_props: {
        data_type: 'EnumEditor',
        openSearch: false,
        autoFocus: false,
        options: batteryTypes,
      },
    },
    barcode_section: {
      field_name: 'barcode_section',
      field_type: 'Section Break',
      label: 'Bar codes',
    },
    isbn_number: {
      field_name: 'isbn_number',
      field_type: 'Text',
      label: 'ISBN #',
      col_span: 12,
    },
    ean_number: {
      field_name: 'ean_number',
      field_type: 'Text',
      label: 'EAN #',
      col_span: 12,
    },
    upc_number: {
      field_name: 'upc_number',
      field_type: 'Text',
      label: 'UPC #',
      col_span: 12,
    },
    mpn_number: {
      field_name: 'mpn_number',
      field_type: 'Text',
      label: 'MPN #',
      col_span: 12,
    },
    product_packaging_options_section: {
      field_name: 'product_packaging_options_section',
      field_type: 'Section Break',
      label: 'Packaging Options Details',
    },
    product_packaging_options: {
      field_name: 'product_packaging_options',
      field_type: 'Table',
      col_span: 24,
      component_props: {
        reportName: 'product_packaging_options',
        columns: columns,
        height: '30vh',
        rowSelection: 'multiple',
        table_type: 'BaseTable',
        reportConfig: {
          defaultColDef: {
            editable: true,
            resizable: true,
          },
        },
        extra: {
          addBtn: {
            field_name: 'add_row',
            label: 'Add',
          },
          deleteBtn: {
            field_name: 'delete_row',
          },
        },
      },
    },
    description_section: {
      field_name: 'description_section',
      field_type: 'Section Break',
      label: 'Remarks',
    },
    description: {
      field_name: 'description',
      field_type: 'Text',
      label: 'Remarks',
      col_span: 24,
    },
  };
  const field_order = [
    'basic_detail_section',
    'product_name',
    'gst_hsn_code',
    'basic_detail_row_break',
    'country_of_origin',
    'customer_name_search',
    'customer_ref_code',
    'product_packaging_options_section',
    'product_packaging_options',
    'product_properties_section',
    'is_temp_controlled',
    'is_perishable',
    'is_hazardous',
    'is_stackable',
    'is_tiltable',
    'is_palletisable',
    'is_battery',
    'product_properties_title_temp_control',
    'temperature_range',
    'cooling_type',
    'product_properties_title_hazardous',
    'un_number',
    'un_imo_class',
    'proper_shipping_name',
    'technical_name',
    'packaging_group',
    'net_weight',
    'flash_point',
    'ems_number',
    'emergency_contact_name',
    'emergency_contact_number',
    'is_radioactive',
    'product_properties_title_battery',
    'battery_type',
    'barcode_section',
    'isbn_number',
    'ean_number',
    'upc_number',
    'mpn_number',
    'description_section',
    'description',
  ];

  const onValuesChange = (changedValues: any, _allValues: any, form: any, table_details = {}) => {
    if ('gridRef' in table_details && 'node' in table_details && !changedValues?.gross_volume) {
      const { node }: any = table_details;
      const newData = node.data;
      const qty = recalculateTotal(node.data);
      newData.gross_volume = qty.gross_volume;
      node.setData(newData);
    }
    if ('is_temp_controlled' in changedValues) {
      form.setFieldsValue({
        cooling_type: _allValues['cooling_type'] || 'Active',
      });
    }
  };

  const getProductPackagingOptionsPayload = (packaging_options: any[]) => {
    const product_packaging_options = (packaging_options || []).map((packaging: any) => {
      return {
        ...packaging,
        dimension_unit: packaging?.dimension_unit || 'cms',
        weight_unit: packaging?.weight_unit || 'kgs',
      };
    });
    return product_packaging_options;
  };

  return (
    <FormRenderer
      fields={form_fields}
      form={form}
      field_order={field_order}
      form_style={{
        height: '100%',
        margin: 'none',
      }}
      primary_btn_label="Create Product"
      form_title={'Create a New Product'}
      onBack={() => {
        window.history.back();
      }}
      onValuesChange={onValuesChange}
      initial_values={{
        product_name: product?.product_name,
        gst_hsn_code: product?.gst_hsn_code,
        country_of_origin: product?.country_of_origin,
        isbn_number: product?.isbn_number,
        ean_number: product?.ean_number,
        upc_number: product?.upc_number,
        mpn_number: product?.mpn_number,
        customer_name_search: product?.customer,
        customer_ref_code: product?.customer_ref_code,
        description: product?.description,
        product_packaging_options: product?.product_packaging_options?.map((option: any) =>
          omit(option, '__typename')
        ) || [{ is_primary_packaging: true }],
        net_weight_unit: WEIGHT_UNIT_KGS,
        ...pick(product?.product_property, [
          'is_perishable',
          'is_temp_controlled',
          'is_hazardous',
          'is_battery',
          'un_number',
          'un_imo_class',
          'proper_shipping_name',
          'technical_name',
          'packaging_group',
          'net_weight',
          'flash_point',
          'flash_point_unit',
          'ems_number',
          'is_palletisable',
          'is_radioactive',
          'is_stackable',
          'is_tiltable',
          'temperature_unit',
          'cooling_type',
          'battery_type',
          'temperature_range',
          'emergency_contact_name',
          'emergency_contact_number',
        ]),
      }}
      onFinish={(values) => {
        let check = true;
        (values?.product_packaging_options || []).forEach((e: ProductPackagingOptionsValue) => {
          check = check && (e?.packing_type ? true : false);
        });

        if (!check) {
          message.error('Products Packaging Type is required');
          return;
        }
        const payload = {
          ...omit(values, [
            'is_perishable',
            'is_temp_controlled',
            'is_hazardous',
            'is_battery',
            'un_number',
            'un_imo_class',
            'proper_shipping_name',
            'technical_name',
            'packaging_group',
            'net_weight',
            'net_weight_unit',
            'flash_point',
            'flash_point_unit',
            'ems_number',
            'is_palletisable',
            'is_radioactive',
            'is_stackable',
            'is_tiltable',
            'temperature_unit',
            'cooling_type',
            'battery_type',
            'customer_name_search',
            'temperature_range',
            'emergency_contact_name',
            'emergency_contact_number',
          ]),
          id: id,
          customer_id: values?.customer_name_search?.id,
          product_property: {
            net_weight_unit: values?.net_weight_unit?.name,
            ...pick(values, [
              'is_perishable',
              'is_temp_controlled',
              'is_hazardous',
              'is_battery',
              'un_number',
              'un_imo_class',
              'proper_shipping_name',
              'technical_name',
              'packaging_group',
              'net_weight',
              'flash_point',
              'flash_point_unit',
              'ems_number',
              'is_palletisable',
              'is_radioactive',
              'is_stackable',
              'is_tiltable',
              'temperature_unit',
              'cooling_type',
              'battery_type',
              'temperature_range',
              'emergency_contact_name',
              'emergency_contact_number',
            ]),
          },
          product_packaging_options: getProductPackagingOptionsPayload(
            values?.product_packaging_options
          ),
        };
        upsertWmsProduct({ variables: { product: payload } });
      }}
      hideHeaderFooter={hideHeaderFooter}
    />
  );
});

const CreateProductFormInternal = observer(function ChargesLayoutInternal(props: any) {
  const [loading, setLoading] = useState(true);
  const [configData, setConfigData] = useState<ERPNextCompanyConfigData | undefined>(undefined);
  const { getErpConfigData } = props;

  useEffect(() => {
    async function fetchErpData() {
      const promises: Promise<any>[] = [getErpConfigData()];
      const result = await Promise.all(promises);
      if (result[0].error) message.error(errorMessageHandlerGraphQLString(result[0].error));
      setConfigData(result[0].data);
      setLoading(false);
    }
    fetchErpData();
  }, [getErpConfigData]);

  if (loading) return <Card loading={loading} />;

  if (!configData) return <div>Error while loading config data.</div>;
  const uom = configData.unit_of_measurements;

  return <CreateProductForm {...props} uom={uom} />;
});

export default function CreateProductFormWrapper(props: any) {
  const { config_data } = useApplicationContentContext();
  if (!config_data) return <div>Error while loading config data.</div>;
  const list_of_countries = config_data?.list_of_countries;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <ErpNextCompanyConfigDataContext.Consumer>
      {({ getErpConfigData }: ErpNextCompanyConfigDataContextType) => {
        return (
          <CreateProductFormInternal
            {...props}
            list_of_countries={list_of_countries}
            getErpConfigData={getErpConfigData}
          />
        );
      }}
    </ErpNextCompanyConfigDataContext.Consumer>
  );
}

export function CreateProductFormDrawer(props: any) {
  const { visible, setVisible, isVisible, id } = props;
  const [open, setOpen] = useState<boolean>(isVisible);

  const { onSuccess } = props;
  const [form] = Form.useForm();
  const getTitle = () => {
    return id ? 'Edit Product' : 'Create a New Product';
  };
  const onClose = () => {
    setVisible && setVisible(false);
    setOpen && setOpen(false);
    form.resetFields();
    !!onSuccess && onSuccess();
  };
  return (
    <Drawer
      width="70%"
      title={
        <span>
          <FormOutlined />
          &nbsp;&nbsp;{getTitle()}
        </span>
      }
      placement="right"
      onClose={onClose}
      open={visible || open}
      closable={true}
      destroyOnClose={true}
      bodyStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
      footer={
        <DrawerFooter
          saveText={id ? 'Update Product' : 'Create Product'}
          closeText="Cancel"
          onClose={onClose}
          onSave={form.submit}
        />
      }
    >
      <CreateProductFormWrapper {...props} form={form} hideHeaderFooter={true} />
    </Drawer>
  );
}

export function CreateProductFormLayout(props: any) {
  const { onClose, externalLink } = props;

  const [getProduct, { data, loading, error }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: 'no-cache',
  });
  const params = useParams<{ id: string }>();
  const id = props?.id || params?.id;
  const { Header, Content, Footer } = Layout;
  const [form] = Form.useForm();
  const [product, setProduct] = useState<ItemValue>();
  useEffect(() => {
    if (id !== 'new')
      getProduct({
        variables: {
          id: id,
        },
      });
  }, [getProduct, id]);

  useEffect(() => {
    if (error) message.error(error?.message);
    if (data) setProduct(data?.get_product);
  }, [error, data]);

  const getTitle = () => {
    return id ? 'Edit Product' : 'Create a New Product';
  };

  return (
    <>
      {loading && <Skeleton />}
      {product && !loading && (
        <Layout style={{ minHeight: '100vh' }}>
          <AppHelmet titleTemplate={`#${product?.product_name || ''} | Product | Master [WMS]`} />
          <Header
            style={{
              backgroundColor: 'white',
              padding: '0 16px',
              display: 'flex',
              alignItems: 'center',
              zIndex: 1,
              width: '100%',
              position: 'fixed',
              borderBottom: '1px solid #ddd',
            }}
          >
            <div>
              <FormOutlined style={{ fontSize: '18px' }} /> &nbsp;{' '}
              <span style={{ fontWeight: '24px', fontSize: '16px' }}>{getTitle()}</span>
            </div>
          </Header>
          <Content
            style={{
              padding: '0 16px',
              marginTop: 74,
              marginBottom: 64,
            }}
          >
            {product && !loading && (
              <CreateProductFormWrapper
                product={product}
                form={form}
                hideHeaderFooter={true}
                {...props}
                id={id}
              />
            )}
          </Content>
          <Footer
            style={{
              padding: '16px',
              position: 'fixed',
              bottom: 0,
              width: externalLink ? '85%' : '100%',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid #ddd',
            }}
          >
            <Space>
              <Button type="primary" htmlType="submit" onClick={form.submit}>
                {id ? 'Update Product' : 'Create Product'}
              </Button>
              <Button type="default" onClick={onClose}>
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      )}
    </>
  );
}
