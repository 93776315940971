import React from 'react';
import { Row, Col, Form, FormInstance } from '@shipmnts/pixel-hub';
import ExportClearanceTable, { ExportClearanceTableValue } from './ExportClearanceTable';
import { CreateShipmentFormBRValue } from 'operations/modules/reports/components/CreateShipmentFormContent';

const customsValidator = (value: ExportClearanceTableValue[]) => {
  try {
    const errorRow: number[] = [];
    if (value)
      value.forEach((vl: ExportClearanceTableValue, index: number) => {
        if ((vl.document_date && !vl.document_number) || !vl.country) {
          errorRow.push(index + 1);
        }
      });
    if (errorRow.length > 0) {
      throw new Error();
    }
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
const invoiceValidator = (value: ExportClearanceTableValue[]) => {
  try {
    const errorRow: number[] = [];
    if (value)
      value.forEach((vl: ExportClearanceTableValue, index: number) => {
        if (vl.document_date && !vl.document_number) {
          errorRow.push(index + 1);
        }
      });
    if (errorRow.length > 0) {
      throw new Error();
    }
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
const ExportClearanceDetails = ({
  bookingRequest,
  form,
  showInvoiceDetails = true,
  showExportCustomDetails = true,
  showImportCustomDetails = true,
  skipServiceCheck = false,
  type,
}: {
  bookingRequest?: CreateShipmentFormBRValue;
  form: FormInstance;
  showInvoiceDetails?: boolean;
  showExportCustomDetails?: boolean;
  showImportCustomDetails?: boolean;
  skipServiceCheck?: boolean;
  type?: string;
}) => {
  return (
    <Row>
      <Col span={24}>
        {showExportCustomDetails && (
          <Form.Item
            name="shipping_bill_details"
            rules={[
              {
                validator: (rule, value) => customsValidator(value),
                message: 'Customs Document Number and Country Required',
              },
            ]}
          >
            <ExportClearanceTable
              tableHeader="Export Custom Declaration Details"
              label="Customs Document"
              bookingRequest={bookingRequest}
              type="CUSTOM"
              tradeType="export"
              form={form}
              formType={type}
            />
          </Form.Item>
        )}
        {showImportCustomDetails && (
          <Form.Item dependencies={[['services', 'destination', 'clearance']]}>
            {({ getFieldValue }) => {
              const enabled =
                skipServiceCheck || getFieldValue(['services', 'destination', 'clearance']);
              if (enabled)
                return (
                  <Form.Item
                    name="import_custom_details"
                    rules={[
                      {
                        validator: (rule, value) => customsValidator(value),
                        message: 'Customs Document Number and Country Required',
                      },
                    ]}
                  >
                    <ExportClearanceTable
                      tableHeader="Import Custom Declaration Details"
                      label="Customs Document"
                      bookingRequest={bookingRequest}
                      type="CUSTOM"
                      tradeType="import"
                      form={form}
                      formType={type}
                    />
                  </Form.Item>
                );
              else return <></>;
            }}
          </Form.Item>
        )}
        {showInvoiceDetails && (
          <Form.Item
            name="shipment_invoices"
            rules={[
              {
                validator: (rule, value) => invoiceValidator(value),
                message: 'Invoice Number Required',
              },
            ]}
          >
            <ExportClearanceTable
              tableHeader="Commercial Invoice Details"
              label="Invoice"
              form={form}
            />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

export default ExportClearanceDetails;
