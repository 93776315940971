import { useMutation } from '@apollo/client';
import { Button, Form, Modal, message } from 'antd';
import { INVITE_CUSTOMER } from 'network/graphql/company';
import { CompanyValue } from 'network/models/Company';
import React, { useEffect, useState } from 'react';
import { GlobalSearch } from '@shipmnts/pixel-hub';

type Props = {
  company: CompanyValue;
};

const SendInvite = (props: Props) => {
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inviteCustomer, { data, loading, error }] = useMutation(INVITE_CUSTOMER);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnSubmit = (values: any) => {
    const { user } = values;
    if (!user) {
      return message.error('Please select a user');
    }
    if (!user.email) {
      return message.error('Please update user email before proceeding');
    }
    if (!user.mobile_number) {
      return message.error('Please update user phone number before proceeding');
    }

    return inviteCustomer({
      variables: {
        user_contact_id: user.id,
        company_id: props.company.id,
      },
    });
  };

  useEffect(() => {
    if (data && !error) {
      message.success(`Connection request sent successfully to ${props?.company?.registered_name}`);
      setIsModalOpen(false);
    }
  }, [data, error, props.company]);

  return (
    <Form form={form} onFinish={handleOnSubmit}>
      <Button onClick={showModal} size="small">
        Send Invite
      </Button>
      <Modal
        title={`Send Connection Request to ${props?.company?.registered_name} on Platform`}
        open={isModalOpen}
        onOk={form.submit}
        okText={'Send Connection Request'}
        onCancel={handleCancel}
        okButtonProps={{
          loading: loading,
        }}
        cancelButtonProps={{
          loading: loading,
        }}
      >
        <Form.Item name={'user'}>
          <GlobalSearch
            doc_type="Network::UserContact"
            extraProps={{
              company: props.company,
              showSelf: false,
              suggestions: {
                [props.company.registered_name]: props.company.contacts,
              },
            }}
          />
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default SendInvite;
