import React, { useCallback, useEffect, useState } from 'react';
import { SearchDocTypeEditor, FloatEditor, BaseTable } from '@shipmnts/pixel-hub';
import { QtyUomTypeRenderer } from 'common';
import { Column } from 'operations/models/Report';
import { compact as _compact } from 'lodash';
import { StockLedgerValue } from 'operations/models/StockLedger';
import { FETCH_STOCK_SUMMARY } from '../graphql/stockLedger';
import { useLazyQuery } from '@apollo/client';
import { GridOptions } from '@ag-grid-community/core';
import StockLedgerFilter from './StockLedgerFilter';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { generatePayload } from './StockLedgerFilterHelper';

interface StockSummaryTableProps {
  gridRef?: React.MutableRefObject<GridOptions<any> | undefined>;
  rowSelection?: 'multiple' | 'single';
  transaction?: WarehouseTransactionValue;
  purposeOfTransfer?: string;
}

const StockSummaryTable = (props: StockSummaryTableProps) => {
  const { rowSelection, gridRef, transaction, purposeOfTransfer } = props;
  const [fetchStockSummary, { error, loading, data }] = useLazyQuery(FETCH_STOCK_SUMMARY);
  const [stockLedgerItems, setStockLedgerItems] = useState<StockLedgerValue[]>([]);
  const initialFormValue =
    purposeOfTransfer === 'put_away'
      ? {
          filters: {
            warehouse_unit_id: [transaction?.target_warehouse?.default_receiving_location],
          },
        }
      : {};

  const getHeight = () => {
    if (!stockLedgerItems) {
      return undefined;
    }
    let detailPanelHeight = 5 * 39 + 160;

    if ((stockLedgerItems || []).length < 6) {
      detailPanelHeight = (stockLedgerItems || []).length * 39 + 160;
    }

    return detailPanelHeight;
  };
  useEffect(() => {
    fetchStockSummary({
      variables: {
        transaction_id: transaction?.id,
        ...generatePayload(initialFormValue),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStockSummary, transaction]);

  useEffect(() => {
    if (data?.fetch_stock_summary) {
      setStockLedgerItems(data?.fetch_stock_summary);
    }
  }, [data, error]);

  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Product Name',
        field: 'product_name',
        colId: 'product_name',
        cellEditor: 'SearchDocTypeEditor',
        editable: false,
        maxWidth: 300,
        cellStyle: { 'background-color': '#f2f2f2' },
      },
      {
        headerName: 'Cust. Product Code',
        field: 'product_code',
        colId: 'product_code',
        columnType: 'String',
        editable: false,
        cellStyle: { 'background-color': '#f2f2f2' },
      },
      {
        headerName: 'Location',
        field: 'warehouse_unit',
        colId: 'warehouse_unit',
        editable: false,
        valueFormatter: (params: any) => params.value?.node_name,
        maxWidth: 300,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params.value?.node_name,
        filterParams: {
          convertValuesToStrings: true,
        },
        cellStyle: { 'background-color': '#f2f2f2' },
      },
      {
        headerName: 'Available Qty',
        field: 'available_qty',
        colId: 'available_qty',
        columnType: 'Float',
        cellEditor: 'FloatEditor',
        editable: false,
        cellStyle: { 'background-color': '#f2f2f2' },
      },
      {
        headerName: 'Qty',
        field: 'qty',
        colId: 'qty',
        columnType: 'Float',
        cellEditor: 'FloatEditor',
        editable: (o) => !o.node.isRowPinned(),
        valueSetter: (params) => {
          if (params.newValue > params.data.available_qty) return false;
          params?.node?.setData({
            ...params.data,
            qty: params.newValue,
            gross_volume: params?.data?.per_piece_volume * params.newValue,
            gross_weight: params?.data?.per_pack_weight * params.newValue,
          });
          return true;
        },
      },
      {
        headerName: 'Gross Vol',
        field: 'gross_volume',
        colId: 'gross_volume',
        columnType: 'Float',
        cellEditor: 'FloatEditor',
        editable: false,
        cellRenderer: (params: any) => {
          if (params.node.isRowPinned()) return <></>;
          return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
        },
        cellStyle: { 'background-color': '#f2f2f2' },
      },
      {
        headerName: 'Gross Weight',
        field: 'gross_weight',
        colId: 'gross_weight',
        columnType: 'Float',
        cellEditor: 'FloatEditor',
        editable: false,
        cellRenderer: (params: any) => {
          if (params.node.isRowPinned()) return <></>;
          return (
            <QtyUomTypeRenderer
              uom={params?.data?.weight_unit || 'kgs'}
              qty={params?.data?.gross_weight}
            />
          );
        },
        cellStyle: { 'background-color': '#f2f2f2' },
      },
      {
        headerName: 'Receipt #',
        field: 'receipt_number',
        colId: 'receipt_number',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Batch #',
        field: 'batch_number',
        colId: 'batch_number',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Serial #',
        field: 'serial_number',
        colId: 'serial_number',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Invoice #',
        field: 'invoice_number',
        colId: 'String',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Container #',
        field: 'container_number',
        colId: 'container_number',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Customs Ref #',
        field: 'customs_ref_code',
        colId: 'customs_ref_code',
        columnType: 'String',
        editable: false,
      },

      {
        headerName: 'Lot #',
        field: 'lot_number',
        colId: 'lot_number',
        columnType: 'String',
        editable: false,
      },
    ]);

    return columnDefs;
  }, []);

  const allColumnsDefs = getColumns() || [];

  const components = {
    FloatEditor,
    SearchDocTypeEditor,
  };

  const transformInput = (payload: any) => {
    const { filters, sort } = payload;
    const newSort = [...sort];
    if (filters?.order_by) {
      const orderBy = filters.order_by;
      delete filters.order_by;
      if (orderBy.includes('lifo'))
        newSort.push({ direction: 'desc', field_id: 'transaction_date' });
      if (orderBy.includes('fifo'))
        newSort.push({ direction: 'asc', field_id: 'transaction_date' });
    }
    return {
      ...payload,
      sort: newSort,
    };
  };

  const fetchLedgers = (all_values: any) => {
    // Doing this just to handle lifo fifo as filter
    const newValues = transformInput(all_values);
    fetchStockSummary({
      variables: {
        transaction_id: transaction?.id,
        ...newValues,
      },
    });
  };

  return (
    <>
      <StockLedgerFilter
        initialFormValue={initialFormValue}
        onFilterChange={fetchLedgers}
        filters={[
          {
            label: 'Product',
            name: 'product_id',
            field_type: 'Search',
            filter_options: '{"doc_type": "Wms::Product"}',
            operator: 'in',
            is_quick_filter: true,
          },
          {
            label: 'Location',
            name: 'warehouse_unit_id',
            field_type: 'Search',
            filter_options: '{"doc_type":"Wms::WarehouseUnit"}',
            operator: 'in',
            is_quick_filter: true,
          },
          {
            label: 'Customer',
            name: 'customer_company_id',
            field_type: 'Search',
            filter_options: '{"doc_type":"Network::Company"}',
            operator: 'in',
            is_quick_filter: true,
          },
          {
            label: 'Order By',
            name: 'order_by',
            field_type: 'Dropdown',
            filter_options:
              '{"values" : {"lifo": "lifo", "fifo": "fifo"}, "display_hash": {"lifo": "LIFO", "fifo": "FIFO"}}',
            is_quick_filter: true,
            operator: 'in',
          },
          {
            label: 'Received At',
            name: 'transaction_date',
            field_type: 'DateTime',
            operator: 'dateRange',
            is_quick_filter: true,
          },
          {
            label: 'Exp Date',
            name: 'exp_date',
            field_type: 'DateTime',
            operator: 'is',
          },
          {
            label: 'Mfg Date',
            name: 'mfg_date',
            field_type: 'DateTime',
            operator: 'is',
          },
        ]}
        sorts={[
          { id: 'transaction_date', label: 'Received At', field_type: 'Date' },
          { id: 'available_qty', label: 'Available Qty', field_type: 'Float' },
        ]}
      />
      {!loading && (
        <BaseTable
          rowData={stockLedgerItems || []}
          reportName=""
          height={getHeight() || '200px'}
          columns={allColumnsDefs}
          gridRef={gridRef}
          rowSelection={rowSelection}
          checkbox_always_visible={false}
          allMapping={true}
          reportConfig={{
            components: components,
            suppressCellFocus: false,
            suppressLastEmptyLineOnPaste: true,
            rowHeight: 40,
            defaultColDef: {
              resizable: true,
              editable: true,
            },
            enableRangeSelection: true,
            enableCellChangeFlash: true,
            animateRows: true,
            stopEditingWhenCellsLoseFocus: true,
          }}
        />
      )}
    </>
  );
};

export default StockSummaryTable;
