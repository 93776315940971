import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { FETCH_EMAIL_TEMPLATES } from '../../graphQL/emailTemplates';
import {
  Layout,
  message,
  ActivityContextProvider,
  LinkedParentType,
  UploadedDocumentType,
  Attachment,
} from '@shipmnts/pixel-hub';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import SendEmailWithDocumentPdf from './components/SendEmailWithDocumentPdf';
import { EmailTemplate } from './commonTypeDefs';

const { Content } = Layout;
interface EmailTemplatesContextType {
  fetchingEmailTemplates: boolean;
  templates?: EmailTemplate[];
  fetchEmailTemplatesError?: ApolloError;
  fetchTemplates?: () => void;
}

export interface EmailProps {
  title: string;
  action_name?: string;
  resource_ids: string[];
  fetchDocumentParents?: LinkedParentType[];
  companies_roles_mapping?: any[];
  onClose?: () => void;
  showEmail?: boolean | undefined;
  showMarkDown?: boolean | undefined;
  docTypeId?: string;
  initialAttachments?: UploadedDocumentType[];
  customContent?: string;
  fetchDocuments?: () =>
    | Promise<{
        error: any;
        documents: Array<Attachment>;
      }>
    | undefined;
  defaultEmails?: {
    recipient_emails?: string[];
    cc_emails?: string[];
  };
}

export const EmailTemplatesContext = React.createContext<EmailTemplatesContextType>({
  fetchingEmailTemplates: false,
  templates: undefined,
  fetchEmailTemplatesError: undefined,
  fetchTemplates: undefined,
});

export interface EmailDrawerContextType {
  setVisible: (visible: boolean) => void;
  setEmailProps: (emailProps: EmailProps) => void;
  templates?: EmailTemplate[];
}

export const EmailDrawerContext = React.createContext<EmailDrawerContextType>({
  setVisible: () => {
    console.log('visible');
  },
  setEmailProps: () => {
    console.log('set Email Props');
  },
});

// Todp remove email temaplates from this file for mfe
export const useEmailTemplatesContext = () => useContext(EmailTemplatesContext);
export const useEmailDrawerContext = () => useContext(EmailDrawerContext);

export interface ApplicationContentContextType {
  config_data: any;
  configDataError: any;
  fetchingConfigData: boolean;
  fetchConfigData?: () => void;
}

export const ApplicationContentContext = React.createContext<ApplicationContentContextType>({
  config_data: undefined,
  configDataError: undefined,
  fetchConfigData: undefined,
  fetchingConfigData: false,
});
export const useApplicationContentContext = () => useContext(ApplicationContentContext);

export interface ApplicationLayoutContentProps {
  children: React.ReactNode;
}
const ApplicationLayoutContent = React.memo(function ApplicationLayoutContent(
  props: ApplicationLayoutContentProps
): JSX.Element {
  const { children } = props;
  const {
    loading: fetchingEmailTemplates,
    error: fetchEmailTemplatesError,
    data: emailTemplates,
    refetch: fetchTemplates,
  } = useQuery(FETCH_EMAIL_TEMPLATES, {
    variables: { filters: [], sort: [] },
  });
  const [templateData, setTemplateData] = useState<EmailTemplate[]>([]);
  const [configData, setConfigData] = useState<any>(undefined);
  const [fetchingConfigData, setFetchingConfigData] = useState<boolean>(false);
  const [configDataError, setConfigDataError] = useState<string | undefined>();
  const [visible, setVisible] = useState<boolean>(false);
  const [emailProps, setEmailProps] = useState<EmailProps>({
    title: '',
    action_name: '',
    resource_ids: [],
    fetchDocumentParents: [],
    companies_roles_mapping: [],
    onClose: () => {
      console.log('onClose');
    },
    showEmail: undefined,
    showMarkDown: undefined,
    docTypeId: undefined,
    fetchDocuments: undefined,
  });

  useEffect(() => {
    if (fetchEmailTemplatesError) {
      message.error(fetchEmailTemplatesError.message);
      return;
    }
    if (emailTemplates?.email_templates) {
      setTemplateData(emailTemplates?.email_templates?.data);
    }
  }, [fetchingEmailTemplates, fetchEmailTemplatesError, emailTemplates]);

  const fetchConfigData = useCallback(async () => {
    setFetchingConfigData(true);
    try {
      const response = await axios.get(`${process.env.GLOBAL_API_URL}/config_data`);
      if (response?.data?.config_data) {
        setConfigData(response.data.config_data);
      }
    } catch (e) {
      console.error(e);
      const error: any = e;
      setConfigDataError(error?.message);
      setFetchingConfigData(false);
    }
    setFetchingConfigData(false);
  }, []);

  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  return (
    <Layout hasSider style={{ height: '100%' }}>
      <Content>
        <EmailDrawerContext.Provider
          value={{
            setVisible,
            setEmailProps,
            templates: emailTemplates?.email_templates?.data,
          }}
        >
          <ActivityContextProvider>
            <SendEmailWithDocumentPdf
              templates={templateData}
              fetchingEmailTemplates={fetchingEmailTemplates}
              fetchEmailTemplatesError={fetchEmailTemplatesError}
              fetchTemplates={fetchTemplates}
              visible={visible}
              onClose={emailProps?.onClose}
              title={emailProps?.title}
              action_name={emailProps?.action_name}
              resource_ids={emailProps?.resource_ids}
              fetchDocumentParents={emailProps?.fetchDocumentParents}
              companies_roles_mapping={emailProps?.companies_roles_mapping}
              showEmail={emailProps?.showEmail}
              showMarkdown={emailProps?.showMarkDown}
              docTypeId={emailProps.docTypeId}
              initialAttachments={emailProps?.initialAttachments}
              customContent={emailProps?.customContent}
              fetchDocuments={emailProps?.fetchDocuments}
              defaultEmails={emailProps?.defaultEmails}
            />
            <ApplicationContentContext.Provider
              value={{
                config_data: configData,
                configDataError,
                fetchingConfigData,
                fetchConfigData,
              }}
            >
              <EmailTemplatesContext.Provider
                value={{
                  fetchingEmailTemplates,
                  templates: emailTemplates?.email_templates?.data,
                  fetchEmailTemplatesError,
                  fetchTemplates,
                }}
              >
                {children}
              </EmailTemplatesContext.Provider>
            </ApplicationContentContext.Provider>
          </ActivityContextProvider>
        </EmailDrawerContext.Provider>
      </Content>
    </Layout>
  );
});

export default ApplicationLayoutContent;
