import {
  Collapse,
  Descriptions,
  Timeline,
  Typography,
  Tooltip,
  Form,
  message,
  Modal,
  Input,
  getDateFromUnix,
  getDateTimeFromUnix,
} from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { useShipmentDetail } from './ShipmentDetailLayout';
import { EditOutlined } from '@shipmnts/pixel-hub';
import { get as _get, startCase as _startCase, upperCase as _upperCase } from 'lodash';
import {
  collapsibleCard,
  descriptionsProps,
  getPanelHeader,
  getPortName,
  getStuffingAddress,
} from './Common/common';
import { Link } from 'wouter';
import { ShipmentValue } from 'operations/models/Shipment';
import { CompanyAccountValue } from 'operations/models/CompanyAccount';
import { UPDATE_HOUSE_SHIPMENT_FIELDS, UPDATE_SHIPMENT_FIELDS } from './Containers/graphql';
import { useMutation } from '@apollo/client';
import { BOOKING_THROUGH_TYPES, SHIPMENT_TYPE_HOUSE } from '../../constants';
import { BOOKING_DIRECT_KEY } from 'operations/modules/reports/constants';
import { useSession } from 'common';
import { RoutingLegValue } from 'operations/models/RoutingLeg';

import { CustomIcon } from '@shipmnts/pixel-hub';
const { Panel } = Collapse;
const { Text } = Typography;
const { TextArea } = Input;

function getBookingOptions(current_company: CompanyAccountValue) {
  if (current_company)
    return [
      { key: BOOKING_DIRECT_KEY, name: current_company.display_name },
      ...BOOKING_THROUGH_TYPES,
    ];
  return BOOKING_THROUGH_TYPES;
}

export const UpdateRemarks = (props: {
  shipment: ShipmentValue;
  refetchShipments: () => void;
  showRemarksUpdate: boolean;
  setShowRemarksUpdate: (visible: boolean) => void;
}) => {
  const { shipment, refetchShipments, showRemarksUpdate, setShowRemarksUpdate } = props;
  const [form] = Form.useForm();
  const [remarks, setRemarks] = useState(shipment.remarks);
  const [
    updateHouseShipment,
    {
      data: updateHouseShipmentData,
      loading: updateHouseShipmentLoading,
      error: updateHouseShipmentError,
    },
  ] = useMutation(UPDATE_HOUSE_SHIPMENT_FIELDS);

  const [
    updateShipment,
    { data: updateShipmentData, loading: updateShipmentLoading, error: updateShipmentError },
  ] = useMutation(UPDATE_SHIPMENT_FIELDS);

  useEffect(() => {
    if (updateHouseShipmentError) {
      message.error(updateHouseShipmentError.message);
    } else if (updateHouseShipmentData?.update_house_shipment) {
      message.success('Updated Remarks successfully!');
      setShowRemarksUpdate(false);
      refetchShipments();
    }
  }, [
    updateHouseShipmentData,
    updateHouseShipmentLoading,
    updateHouseShipmentError,
    refetchShipments,
    setShowRemarksUpdate,
  ]);

  useEffect(() => {
    if (updateShipmentError) {
      message.error(updateShipmentError.message);
    } else if (updateShipmentData?.update_shipment) {
      message.success('Updated Remarks successfully!');
      setShowRemarksUpdate(false);
      refetchShipments();
    }
  }, [
    updateShipmentData,
    updateShipmentLoading,
    updateShipmentError,
    refetchShipments,
    setShowRemarksUpdate,
  ]);

  return (
    <Modal
      open={showRemarksUpdate}
      onCancel={() => setShowRemarksUpdate(false)}
      title="Update Remarks"
      onOk={form.submit}
      okText={'Update Remarks'}
    >
      <Form
        form={form}
        initialValues={{ remarks }}
        onFinish={(values) => {
          if (shipment.shipment_type === SHIPMENT_TYPE_HOUSE) {
            updateHouseShipment({
              variables: {
                id: shipment.id,
                shipment: {
                  remarks: values.remarks,
                },
              },
            });
          } else {
            updateShipment({
              variables: {
                id: shipment.id,
                shipment: {
                  remarks: values.remarks,
                },
              },
            });
          }
        }}
      >
        <Form.Item label="Remarks" name="remarks">
          <TextArea
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function LeftSider(props: {
  onClose?: () => void;
  externalLink?: boolean;
  collapsed: boolean;
  setCollapsed: (val: boolean) => void;
  routing_legs: RoutingLegValue[];
}) {
  const { routing_legs } = props;
  const { shipment, refetchShipments } = useShipmentDetail();
  const [showRemarksUpdate, setShowRemarksUpdate] = useState<boolean>(false);

  const user = useSession();
  if (!shipment) {
    return <></>;
  }

  const tradeType = _get(shipment, 'trade_type', '');
  const freightType = _get(shipment, 'freight_type');
  const shipmentCharacteristics = ` ${_startCase(`${freightType}`)} ${_startCase(`${tradeType}`)}`;
  const oceanTransportOrderId = _get(shipment, 'ocean_transport_order_id');
  const bookingNumber = shipment.getOtoBookingFromShipment()?.booking_number;
  const bookingRequests = _get(shipment, 'booking_requests', []);

  const getRoutingDetails = () => {
    const first_vessel_leg = routing_legs[0];
    const last_vessel_leg = routing_legs.slice(-1)[0];
    const origin_terminal = _get(first_vessel_leg, 'origin.terminal.name');
    const destination_terminal = _get(last_vessel_leg, 'destination.terminal.name');

    const routingDetails: { [key: string]: string }[] = [];
    if (shipment.stuffing_location) {
      routingDetails.push({
        'Stuffing Location': getStuffingAddress(shipment.stuffing_location, freightType),
      });
    }
    if (
      shipment.place_of_carrier_receipt &&
      shipment.place_of_carrier_receipt?.id !== shipment.port_of_loading?.id
    ) {
      routingDetails.push({
        'Place of Carrier Receipt': getPortName(shipment.place_of_carrier_receipt, freightType),
        ATD: shipment.atd_pcr_date ? getDateTimeFromUnix(shipment.atd_pcr_date) : '-',
      });
    }
    if (shipment.port_of_loading) {
      const obj: { [key: string]: string } = {
        'Port of Loading': getPortName(shipment.port_of_loading, freightType),
        'Stuffing Type': shipment?.stuffing_type ? _upperCase(shipment?.stuffing_type) : '-',
        Terminal: origin_terminal || '-',
        'Vessel - Voyage': [shipment.vessel_name, shipment.voyage_number]
          .filter((x) => !!x && x !== '')
          .join(' / '),
      };
      if (shipment.atd_pol_date) {
        obj['ATD'] = getDateTimeFromUnix(shipment.atd_pol_date);
      } else if (shipment.estimated_time_of_departure) {
        obj['ETD'] = getDateTimeFromUnix(shipment.estimated_time_of_departure);
      }
      routingDetails.push(obj);
    }
    if (shipment.port_of_discharge) {
      routingDetails.push({
        'Port of Discharge': getPortName(shipment.port_of_discharge, freightType),
        'Destuffing Type': shipment?.destuffing_type ? _upperCase(shipment.destuffing_type) : '-',
        Terminal: shipment.port_of_discharge_terminal?.name || destination_terminal || '-',
        ETA: shipment.estimated_time_of_arrival
          ? getDateTimeFromUnix(shipment.estimated_time_of_arrival)
          : '-',
        ATA: shipment.actual_time_of_arrival
          ? getDateTimeFromUnix(shipment.actual_time_of_arrival)
          : '-',
      });
    }
    if (
      shipment.place_of_carrier_delivery &&
      shipment.place_of_carrier_delivery?.id !== shipment.port_of_discharge?.id
    ) {
      routingDetails.push({
        'Port of Carrier Delivery': getPortName(shipment.place_of_carrier_delivery, freightType),
        ATA: shipment.actual_time_of_arrival_pocd
          ? getDateTimeFromUnix(shipment.actual_time_of_arrival_pocd)
          : '-',
      });
    }
    if (shipment.destuffing_location) {
      routingDetails.push({
        'Destuffing Location': getStuffingAddress(shipment.destuffing_location, freightType),
      });
    }
    if (shipment.final_place_of_delivery) {
      routingDetails.push({
        FPOD: shipment.final_place_of_delivery_print || '-',
        ETA: shipment.estimated_time_of_arrival_at_fpod
          ? getDateTimeFromUnix(shipment.estimated_time_of_arrival_at_fpod)
          : '-',
      });
    }
    return routingDetails;
  };

  const bookingOptions = getBookingOptions(user.company_account);
  const displayBookedThroughValue = (bookingOptions || []).find(
    (option) => option.key === shipment.business_type
  )?.name;
  const basicDetails: { [key: string]: string | React.ReactNode } = {
    'Logistic Product': shipmentCharacteristics,
    Incoterm: shipment.incoterms || '-',
    'Business Booked Through': (displayBookedThroughValue || '-').toLocaleUpperCase(),
  };
  basicDetails['Remarks'] = (
    <>
      <Text ellipsis={shipment?.remarks ? { tooltip: shipment?.remarks } : undefined}>
        {shipment?.remarks || '-'}
      </Text>
      <EditOutlined onClick={() => setShowRemarksUpdate(true)} />
    </>
  );
  if (shipment.getContainerSummary())
    basicDetails['Container(s)#'] = shipment.getContainerSummary();
  if (shipment.master_shipment?.id) {
    if (shipment?.isRoadConsolShipment()) {
      basicDetails['Customer Order'] = (
        <Link to={`~/view/shipment/${shipment.master_shipment_id}`}>
          {shipment.master_shipment?.job_number}
        </Link>
      );
    } else {
      basicDetails['Master Job'] = (
        <Link to={`~/view/shipment/${shipment.master_shipment_id}`}>
          {shipment.master_shipment?.job_number}
        </Link>
      );
    }
  }
  if (bookingRequests && bookingRequests.length > 0)
    basicDetails['Customer Orders#'] = (
      <div>
        <a href={`${process.env.OPERATIONS_URL}/view/booking_request/${bookingRequests[0].id}`}>
          {bookingRequests[0].shipment_booking_number}
        </a>
        {bookingRequests.length - 1 > 0 && (
          <Tooltip
            color={'white'}
            title={
              <>
                {bookingRequests
                  .filter((br) => br.id !== bookingRequests[0].id)
                  .map((br, id) => (
                    <Link key={br.id} to={`~/view/booking_request/${br.id}`}>
                      {br.shipment_booking_number}
                      {id !== bookingRequests.length - 1 && ', '}
                    </Link>
                  ))}
              </>
            }
          >
            <span
              style={{
                border: '1px solid #586988',
                borderRadius: ' 50%',
                marginLeft: '6px',
                padding: '1px',
                color: '#586988',
              }}
            >
              <>{`+${bookingRequests.length - 1}`}</>
            </span>
          </Tooltip>
        )}
      </div>
    );

  if (shipment && shipment.load_type) basicDetails['Load Type'] = _upperCase(shipment.load_type);

  const bookingDetails: { [key: string]: string | React.ReactNode } = {
    'Carrier Name': _get(shipment, 'carrier.name', '-'),
  };

  if (bookingNumber) {
    bookingDetails['Booking Number#'] = oceanTransportOrderId ? (
      <Link to={`~/view/booking_order/${oceanTransportOrderId}`}>{bookingNumber}</Link>
    ) : (
      <strong>{bookingNumber}</strong>
    );
  }

  const routingDetails: { [key: string]: string }[] = getRoutingDetails();

  const commodityDetailsData: { [key: string]: string | null | undefined } = {
    'Total No. of Packages#': `${_get(shipment, 'total_number_of_packages', 0) || 0}`,
    'Commodity Description': _get(shipment, 'commodity_description'),
    'Gross Weight': `${shipment.gross_weight || 0} ${shipment.weight_unit}`,
    'Gross Volume': `${shipment.gross_volume || 0} ${shipment.volume_unit}`,
  };
  if (shipment?.freight_type === 'air')
    commodityDetailsData['Chargeable Weight'] = shipment?.chargeable_weight
      ? shipment.chargeable_weight.toString()
      : '-';
  const createdByUser = shipment.getUserByName('created_by');

  const comodityDetails = {
    icon: <CustomIcon icon="Info" />,
    header: 'Commodity Details',
    data: commodityDetailsData,
  };

  // extract the name of sales person.
  let sales_person_name = '';
  if (shipment?.sales_agent) {
    sales_person_name = shipment.sales_agent?.name;
  } else {
    sales_person_name = '-';
  }

  const creationDetailsData: { [key: string]: string | null | undefined } = {
    'Created by': `${_get(createdByUser, 'user.first_name', '')}
    ${_get(createdByUser, 'user.last_name', '')}`,
    'Created at': shipment.created_at ? getDateFromUnix(shipment.created_at) : '-',
    'Job Date': shipment.job_date ? getDateFromUnix(shipment.job_date) : '-',
    'Job Execution Date': shipment.job_execution_date
      ? getDateFromUnix(shipment.job_execution_date)
      : '-',
    'Sales Person': sales_person_name,
  };
  const creationDetails = {
    icon: <CustomIcon icon="Info" />,
    header: 'Creation Details',
    data: creationDetailsData,
  };

  return (
    <>
      <Collapse
        bordered={false}
        expandIconPosition={'end'}
        className="collapsible-cards"
        defaultActiveKey={[
          'Basic Details',
          'Booking Details',
          'Parties Involved',
          'Commodity Details',
          'Creation Details',
          'Routing Details',
        ]}
      >
        {/* Booking Details */}
        {shipment.shipment_type !== 'general' &&
          collapsibleCard({
            icon: <CustomIcon icon="ShipIconNew" />,
            header: 'Booking Details',
            data: bookingDetails,
          })}
        {/* Routing Detials */}
        {shipment.shipment_type !== 'general' && (
          <Panel
            header={getPanelHeader(<CustomIcon icon="Route" />, 'Routing Details')}
            key={'Routing Details'}
          >
            <Timeline>
              {routingDetails.map((item, index) => (
                <Timeline.Item key={index} style={{ paddingBottom: '0px' }}>
                  <Descriptions {...descriptionsProps}>
                    {Object.keys(item).map((key) => (
                      <Descriptions.Item key={1} style={{ paddingBottom: '3px' }} label={key}>
                        {item[key] || '-'}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </Timeline.Item>
              ))}
            </Timeline>
          </Panel>
        )}
        {/* Commodity Details */}
        {shipment.shipment_type !== 'general' && collapsibleCard({ ...comodityDetails })}
        {/* Creation Details */}
        {shipment.shipment_type !== 'general' && collapsibleCard({ ...creationDetails })}
      </Collapse>
      {showRemarksUpdate && (
        <UpdateRemarks
          showRemarksUpdate={showRemarksUpdate}
          setShowRemarksUpdate={setShowRemarksUpdate}
          refetchShipments={() => {
            if (refetchShipments) refetchShipments();
          }}
          shipment={shipment}
        />
      )}
    </>
  );
}

export default LeftSider;
