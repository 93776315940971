import React, { useEffect, useRef, useState } from 'react';
import {
  ShipmentEstimateStoreContextProvider,
  ShipmentEstimateStore,
  ShipmentEstimateStoreValue,
  useShipmentEstimateStore,
  ShipmentEstimateValue,
} from 'operations/models/ShipmentEstimate';
import ShipmentEstimateLayout from './ShipmentEstimateLayout';
import { useSession } from 'common';
import { getEnv } from 'mobx-state-tree';
import { FormInstance } from 'rc-field-form';
import { observer } from 'mobx-react-lite';
import {
  pick as _pick,
  isEqual as _isEqual,
  debounce as _debounce,
  groupBy as _groupBy,
  sumBy as _sumBy,
} from 'lodash';
import {
  useErpNextCompanyConfigDataContext,
  ERPNextCompanyConfigData,
  fetchItemList,
} from 'network';
import { Form } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { ITEM_CHARGE_MAPPING } from 'operations/modules/shipment/constants';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';
import { erpnextApis } from 'network';
import { uuid4 } from '@sentry/utils';
import { SessionDataValue } from 'operations/models/SessionData';

interface ShipmentEstimateFormWrapperProps {
  shipment: any;
  displayMode: string;
  form?: FormInstance;
}

const makeDefaultRow = () => {
  return {
    uom: 'chargeable_weight',
    buy_exchange_rate: 1,
    buy_terms: 'prepaid',
    sell_exchange_rate: 1,
    buy_status: 'unbilled',
    sell_status: 'unbilled',
    sell_terms: 'prepaid',
    quantity: 1,
    tag: 'freight_charge',
  };
};

const getInitalItem = async (
  erpConfigData?: ERPNextCompanyConfigData,
  isFreightForwarding?: boolean,
  shipment?: ShipmentValue,
  session?: SessionDataValue,
  containerGroup?: any
) => {
  if (!isFreightForwarding || shipment?.inquiry_option?.id) return [];
  const freightType = shipment?.freight_type || '';
  const tradeType = shipment?.trade_type || '';

  const mapping =
    ITEM_CHARGE_MAPPING[freightType as keyof typeof ITEM_CHARGE_MAPPING]?.[
      tradeType as 'export' | 'import'
    ];
  if (!mapping) return [];

  const item = erpConfigData?.charges_settings.find(
    (charge) => charge.reference_name === mapping
  )?.item;
  if (!item) return [];

  try {
    const subdomain = session?.company_account?.subdomain;

    const itemDetails = await fetchItemList.searchItemListByNameApi(item, subdomain);

    const item_data =
      'response' in itemDetails && itemDetails.response?.data?.message
        ? itemDetails?.response?.data?.message?.[0]
        : undefined;
    if (!item_data) return [];

    const exchangeRateData = await erpnextApis.fetchExchangeRate(
      item_data.default_currency || session?.company_account?.default_currency,
      session?.company_account?.default_currency
    );
    const exchangeRate = exchangeRateData?.data?.message || 1;

    const rows: any = [];
    Object.keys(containerGroup).forEach((key: string) => {
      const qty = _sumBy(containerGroup?.[key], 'quantity') || 1;
      const containerTypeCode = containerGroup?.[key]?.[0]?.container_type_code || '22G0';
      const containerType = containerGroup?.[key]?.[0]?.container_type;
      const row: any = makeDefaultRow();
      row['item'] = item_data?.name;
      row['tag'] = item_data?.shipment_tags;
      row['quantity'] = qty;
      row['uom'] = item_data?.stock_uom;
      row['equipment_type'] = freightType === FREIGHT_TYPE_OCEAN ? containerTypeCode : '';
      row['equipment_name'] = freightType === FREIGHT_TYPE_OCEAN ? containerType : '';
      row['buy_currency'] =
        item_data?.default_currency || session?.company_account?.default_currency;
      row['sell_currency'] =
        item_data?.default_currency || session?.company_account?.default_currency;
      row['buy_exchange_rate'] = exchangeRate || 1;
      row['sell_exchange_rate'] = exchangeRate || 1;
      row['uuid'] = uuid4();
      row['shipment_id'] = 'new';
      row['taxable'] = item_data?.taxable;
      row['taxability'] = item_data?.taxability;
      row['tax_percentage'] = item_data?.tax_percentage;
      row['item_description'] = item_data?.description;
      row['item_group'] = item_data?.item_group;
      rows.push(row);
    });

    return rows;
  } catch (error) {
    console.error('Error fetching initial item data:', error);
    return [];
  }
};

function ShipmentEstimateFormWrapper(props: ShipmentEstimateFormWrapperProps) {
  const { shipment, displayMode, form } = props;
  const { permissions } = useSession();
  const session = useSession();
  const { getErpConfigData } = useErpNextCompanyConfigDataContext();
  const [erpConfigData, setERPConfigData] = useState<ERPNextCompanyConfigData>();
  const isFreightForwarding = Form.useWatch('services')?.freight_forwarding;
  const shipmentContainerQuantity = Form.useWatch('shipment_container_quantity');
  const shipmentContainerGroup = _groupBy(shipmentContainerQuantity, 'container_type_code');
  const store = useRef<ShipmentEstimateStoreValue>(
    ShipmentEstimateStore.create(
      {
        estimates: [],
        to_be_synced: [],
        sync_state: 'saved',
      },
      {
        session,
        permissions,
        accounting_status: shipment?.accounting_status,
      }
    )
  );

  useEffect(() => {
    getErpConfigData()
      .then((data) => {
        setERPConfigData(data?.data);
      })
      .catch((error) => {
        console.error('Error fetching ERP config data:', error);
      });
  }, [getErpConfigData]); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (shipment?.inquiry_option?.id || !isFreightForwarding) return;
    if (erpConfigData && session && store.current) {
      getInitalItem(
        erpConfigData,
        isFreightForwarding,
        shipment,
        session,
        shipmentContainerGroup
      ).then((row) => {
        store.current.clearEstimates();
        store.current.addEstimates(row);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erpConfigData, isFreightForwarding, session, shipment, shipmentContainerQuantity]);

  useEffect(() => {
    getEnv(store.current).accounting_status = shipment?.accounting_status;
    store.current.updateEstimatePermissions();
    // eslint-disable-next-line
  }, [shipment?.accounting_status]);

  if (!store) return <div>Not Found</div>;
  return (
    <ShipmentEstimateStoreContextProvider value={{ store: store.current }}>
      <UpdateForm form={form} />
      <div style={{ height: 'calc(100% - 80px)', overflow: 'auto' }}>
        <Form.Item name={'shipment_estimates'}>
          <ShipmentEstimateLayout
            shipment={{
              ...shipment,
              shipment_containers: getShipmentContainerFromQuantity(shipmentContainerQuantity),
            }}
            displayMode={displayMode}
          />
        </Form.Item>
      </div>
    </ShipmentEstimateStoreContextProvider>
  );
}

const UpdateForm = observer(function UpdateForm(props: { form?: FormInstance }) {
  const { form } = props;

  const { store } = useShipmentEstimateStore();
  const current_Estimates = form?.getFieldValue?.('shipment_estimates');
  const debouncedUpdateEstimates = useRef(
    _debounce((newEstimates: any) => {
      store.startSyncing();
      if (!_isEqual(current_Estimates, newEstimates)) {
        form?.setFieldsValue?.({ shipment_estimates: newEstimates });
      }
      store.updateSyncSuccess();
    }, 200)
  ).current;

  useEffect(() => {
    const new_estimates: ShipmentEstimateValue[] = [];
    store.estimates.forEach((ele: ShipmentEstimateValue) => {
      const est = _pick(ele, [
        'id',
        'item',
        'buy_branch_id',
        'sell_branch_id',
        'supplier_company_id',
        'customer_company_id',
        'uom',
        'quantity',
        'buy_exchange_rate',
        'buy_rate',
        'buy_currency',
        'buy_terms',
        'sell_exchange_rate',
        'sell_rate',
        'sell_currency',
        'sell_terms',
        'sell_remarks',
        'remarks',
        'equipment_type',
        'equipment_name',
        'shipment_id',
        'tag',
        'uuid',
        'sequence',
        'tax_percentage',
        'taxable',
        'taxability',
      ]);
      new_estimates.push(est as ShipmentEstimateValue);
    });

    debouncedUpdateEstimates(new_estimates);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.sync_state, store?.estimates?.length]);

  return <></>;
});

function getShipmentContainerFromQuantity(shipmentContainerQuantity: any) {
  if (!shipmentContainerQuantity) return [];
  const result: any = [];
  shipmentContainerQuantity.forEach((sc: any) => {
    for (let x = 0; x < sc.quantity; x++) {
      result.push({
        container_type: sc.container_type,
        container_type_code: sc.container_type_code,
      });
    }
  });
  return result;
}

export default ShipmentEstimateFormWrapper;
