import React from 'react';
import {
  Col,
  EditOutlined,
  Form,
  GlobalSearch,
  message,
  Modal,
  TeamOutlined,
} from '@shipmnts/pixel-hub';
import { Action } from '../actionHelper/models';
import { PRIMARY_TYPE } from '../actionHelper/constants';
import { getRoleOptions, useSession } from 'common';
import { RowNode } from '@ag-grid-community/core';
import { TEAM_ROLE_SALES } from 'network/baseConstants';
import { BULK_UPSERT_TEAM_DETAILS } from 'operations/graphql/bulkActions';
import { useMutation } from '@apollo/client';

/**
 * Generates the bulk action configuration for upserting team members based on the reference type.
 * Maps available roles to action components for upserting of team members.
 */
const getTeamsBulkAction = (reference_type: string): Action => {
  const allowed_roles = getRoleOptions(reference_type); // Fetch role options based on reference type.

  // Map each role to an action component for upsert a team member.
  const childComponents: Action[] = allowed_roles.map((role) => {
    return {
      key: role.value,
      type: PRIMARY_TYPE,
      displayComponent: role.label,
      icon: <EditOutlined />,
      description: role.label,
      performAction: upsertTeamMemberRenderer,
      isEnable: true,
      extraProps: { field_name: role.value, label: role.label, reference_type: reference_type },
    };
  });

  // Define the parent action for bulk upsert team members.
  return {
    key: 'updating_team_member',
    type: PRIMARY_TYPE,
    displayComponent: 'Update Team Details',
    icon: <TeamOutlined />,
    description: 'Update/Add Team Member',
    childComponents: childComponents,
    isEnable: true,
  };
};

/**
 * Renderer function to display the modal form for upsert a team member.
 * Uses provided action parameters to populate the form fields and handle submission.
 */
export const upsertTeamMemberRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      fieldName: extraProps.field_name,
      label: extraProps.label,
      reference_type: extraProps.reference_type,
    },
    component: UpsertTeamMember,
  };
};

/**
 * Functional component for the modal that handles upserting team member.
 */
const UpsertTeamMember = React.memo(function UpsertTeamMember(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  fieldName: string;
  label: string;
  reference_type: string;
}): JSX.Element {
  const { selectedNodes, onClose, onSuccess, fieldName, label, reference_type } = props;
  const [upsertTeamMember, { loading }] = useMutation(BULK_UPSERT_TEAM_DETAILS);
  const { company_account } = useSession();
  const [form] = Form.useForm();
  const hasBranchAccounts = !['Shipment::Shipment', 'SalesHub::Inquiry'].includes(reference_type);

  /**
   * Handles form submission, constructs mutation variables based on form values
   * and triggers the GraphQL mutation to upsert team member details.
   */
  const onFinish = async (values: any) => {
    const variables: {
      ids?: number[];
      reference_type?: string;
      input?: any;
    } = {
      ids: selectedNodes.map((n) => parseInt(n.data.id)),
      reference_type: reference_type, // Contextual Doc_types (e.g., Shipment::Shipmnet, Network::Company).
      input: {}, // Team member input payload.
    };

    variables.input['role'] = fieldName; // Set the team member role.

    // Set sales_person_id or user_contact_id based on the role field.
    if (fieldName === TEAM_ROLE_SALES) variables.input['sales_person_id'] = values[fieldName].id;
    else variables.input['user_contact_id'] = values[fieldName].id;

    // If branch accounts are selected, add them to the input payload.
    if (values.branch_accounts && values.branch_accounts.length > 0)
      variables.input['branch_account_ids'] = values.branch_accounts.map((b: any) => b.id);

    // Perform the mutation to upsert team member details.
    upsertTeamMember({
      variables: variables,
    })
      .then((res) => {
        if (res.errors) {
          message.error(res.errors[0].message);
          return;
        } else if (res?.data?.bulk_upsert_team_details) {
          message.success('Team Member Update successfully');
          onSuccess();
        }
      })
      .catch(() => {
        message.error('Error occurred while updating team member');
      });
  };

  return (
    <Modal
      title={`Update ${label} Details`}
      open={true}
      okText={'Update'}
      onOk={form.submit}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form layout="inline" form={form} requiredMark={false} onFinish={onFinish}>
        <Col span={24} style={{ marginBottom: '8px' }}>
          <Form.Item
            name={fieldName}
            label={label}
            labelCol={{ span: 8 }}
            rules={[{ required: true }]} // Required field validation.
          >
            {fieldName === TEAM_ROLE_SALES ? (
              <GlobalSearch doc_type="Network::SalesPerson" />
            ) : (
              <GlobalSearch
                doc_type="Network::UserContact"
                showCreation={false}
                extraProps={{ company: company_account.default_company }}
              />
            )}
          </Form.Item>
        </Col>
        {hasBranchAccounts && (
          <Col span={24}>
            <Form.Item name={'branch_accounts'} label={'Branch Accounts'} labelCol={{ span: 8 }}>
              <GlobalSearch doc_type="Network::BranchAccount" selectMode="multiple" />
            </Form.Item>
          </Col>
        )}
      </Form>
    </Modal>
  );
});

export default getTeamsBulkAction;
