import { useMutation } from '@apollo/client';
import { message, Modal } from '@shipmnts/pixel-hub';
import { DELINK_SHIPMENT } from 'operations/graphql/shipment';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect } from 'react';

interface DelinkHouseShipmentProps {
  shipment: ShipmentValue;
  onClose?: () => void;
  onSuccess?: () => void;
}
export default function DelinkHouseShipment(props: DelinkHouseShipmentProps) {
  const { shipment, onClose, onSuccess } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [delinkShipment, { data, error }] = useMutation(DELINK_SHIPMENT);

  useEffect(() => {
    if (error) {
      message.error(error.message);
      if (onClose) onClose();
      return;
    }
    if (data?.delink_house_shipment) {
      message.success('Delinked successfully!');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, error, onSuccess, onClose]);

  const config = {
    title: `Are you sure you want to de-link the house shipment ${shipment.job_number}?`,
    content: (
      <div style={{ background: '#FFFBE6', padding: '15px' }}>
        <p>Delinking this house shipment from consol will update the</p>
        <ul>
          <li>Gross weight of consol</li>
          <li>Chargeable weight of consol</li>
          <li>Gross volume of consol</li>
          <li>No of packages in consol</li>
        </ul>
      </div>
    ),
    className: 'custom-confirm-dialog',
    onOk: () => {
      delinkShipment({ variables: { id: shipment.id } });
    },
    okText: 'Confirm Delinking',
    cancelText: 'No',
    onCancel: () => {
      if (onClose) onClose();
    },
  };

  useEffect(() => {
    Modal.confirm(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <>{contextHolder}</>;
}
