import React, { useState } from 'react';
import { EditOutlined, SelectPipelineModal } from '@shipmnts/pixel-hub';
import { Action } from '../actionHelper/models';
import { PRIMARY_TYPE } from '../actionHelper/constants';
import { RowNode } from '@ag-grid-community/core';

const PIPELINE_ACTION = 'pipeline_id';
const PIPELINE_STATUS_ACTION = 'current_state_id';

/**
 * Generates a list of available pipeline actions (update pipeline or pipeline status)
 * based on the selected nodes. If all selected nodes share the same pipeline,
 * an additional action to update pipeline status is added.
 */
const getPipelineActions = (doc_type: string, selectedNodes: RowNode[]): Action[] => {
  const selectedData = selectedNodes.map((node) => node.data);

  // Check if all selected nodes share the same pipeline ID
  const pipelineID = selectedData[0]['blueprint_pipeline_resources_blueprint_pipeline_id']?.id;
  const hasSamePipeline =
    pipelineID &&
    selectedData.every(
      (data) => data['blueprint_pipeline_resources_blueprint_pipeline_id']?.id === pipelineID
    );

  const actions: Action[] = [];

  // Add action for updating pipeline
  actions.push({
    key: PIPELINE_ACTION,
    type: PRIMARY_TYPE,
    displayComponent: 'Update Pipeline',
    icon: <EditOutlined />,
    description: 'Update Pipeline',
    isEnable: true,
    performAction: pipelineActionsHelper,
    extraProps: { type: PIPELINE_ACTION, doc_type: doc_type },
  });

  // Add action for updating pipeline status if all selected nodes have the same pipeline
  if (hasSamePipeline) {
    actions.push({
      key: PIPELINE_STATUS_ACTION,
      type: PRIMARY_TYPE,
      displayComponent: 'Update Pipeline Status',
      icon: <EditOutlined />,
      description: 'Update Pipeline Status',
      isEnable: true,
      performAction: pipelineActionsHelper,
      extraProps: {
        type: PIPELINE_STATUS_ACTION,
        doc_type: doc_type,
        pipeline_id: pipelineID,
      },
    });
  }

  return actions;
};

/**
 * Helper function to initiate the pipeline or pipeline status update action.
 * This function sets up the necessary parameters and renders the modal for selecting pipeline actions.
 */
export const pipelineActionsHelper = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: {
    type: string;
    doc_type: string;
    pipeline_id?: string;
  },
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      type: extraProps.type,
      doc_type: extraProps.doc_type,
      pipeline_id: extraProps.pipeline_id,
    },
    component: PipelineActionsRenderer,
  };
};

/**
 * Component that renders the pipeline modal for selecting the new pipeline or status.
 * It handles the modal open/close state and passes the necessary props to the modal.
 */
const PipelineActionsRenderer = React.memo(function PipelineActionsRenderer(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  type: string;
  doc_type: string;
  pipeline_id?: string;
}): JSX.Element {
  const { onSuccess, onClose, type, doc_type, selectedNodes, pipeline_id = '' } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <SelectPipelineModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      doctype={doc_type}
      resource_ids={selectedNodes.map((node) => node.data.id)}
      onSuccess={() => {
        onSuccess();
        onClose();
      }}
      onClose={onClose}
      showOnlyPipelineStatus={type === PIPELINE_STATUS_ACTION}
      pipeline_id={`${pipeline_id}`}
      pipeline_name={''}
      current_state_id={''}
    />
  );
});

export default getPipelineActions;
