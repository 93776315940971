import { gql } from '@apollo/client';
import { containerRequestFields } from './containerRequest';
import { cargoFields } from './cargo';
import { routingLegFields } from './routingLeg';
import { shipmentContainerFields } from './shipmentContainer';
import { stuffingBufferServiceOrderFields } from './stuffingBufferServiceOrder';
import { salesPersonFields } from 'operations/graphql/salesPerson';
// handle here
export const oceanTransportOrderFields = gql`
  fragment oceanTransportOrderFields on OceanTransportOrderObjectType {
    id
    status
    booking_type
    load_type
    booking_number
    booking_date
    valid_till_date
    gate_open_date
    si_cutoff_date
    gate_close_date
    doc_cutoff_date
    is_independent
    is_amendment_pending
    house_shipment_ids
    master_shipment_id
    origin_detention_free_days
    origin_demurrage_free_days
    destination_detention_free_days
    destination_demurrage_free_days
    created_by {
      id
      first_name
      last_name
      email
    }
    remarks
    global_carrier {
      id
      name
      carrier_type
    }
    empty_pickup_location {
      id
      name
      unlocode
      city_code
      state_code
      country_code
    }
    empty_return_location {
      id
      name
      unlocode
      city_code
      state_code
      country_code
    }
    container_requests {
      ...containerRequestFields
    }
    cargos {
      ...cargoFields
    }
    shipments {
      id
      master_shipment_id
      shipment_documents {
        id
        document_status
      }
      shipment_type
    }
    shipment {
      id
      load_type
      master_shipment_id
      shipment_documents {
        id
        document_status
        document_type
      }
      shipment_type
    }
    shipment_containers {
      ...shipmentContainerFields
      last_action_status
      is_linked_with_shipments
      container_cargo_details {
        id
        shipment_id
        shipment_container_id
      }
    }
    cargo_ready_date
    routing_legs {
      ...routingLegFields
    }
    voyage_schedule_id
    terms_and_condition_description
    surveyor_company {
      id
      registered_name
    }
    terms_and_condition {
      id
      name
    }
    surveyor_address {
      id
      print_address
    }
    sales_person {
      ...salesPersonFields
    }
    vendor_company {
      id
      registered_name
      logo
      status
    }
    vendor_address {
      id
      name
      print_address
      company_id
    }
    customer_company {
      id
      registered_name
      logo
      status
    }
    customer_address {
      id
      name
      print_address
      company_id
    }
    service_type
    booked_by_branch {
      id
      name
    }
  }
  ${containerRequestFields}
  ${cargoFields}
  ${shipmentContainerFields}
  ${routingLegFields}
  ${salesPersonFields}
`;

export const changeRequestFields = gql`
  fragment changeRequestFields on ChangeRequestObjectType {
    id
    display_string
    requested_at
    requested_by {
      id
      first_name
      last_name
    }
    resolved_at
    resolved_by {
      id
      first_name
      last_name
    }
  }
`;

export const CREATE_OCEAN_TRANSPORT_ORDER = gql`
  mutation createOceanTransportOrder($ocean_transport_order: OceanTransportOrderInputType!) {
    create_ocean_transport_order(ocean_transport_order: $ocean_transport_order) {
      ...oceanTransportOrderFields
    }
  }
  ${oceanTransportOrderFields}
`;

export const GET_OCEAN_TRANSPORT_ORDER = gql`
  query ocean_transport_order($id: ID!) {
    ocean_transport_order(id: $id) {
      ...oceanTransportOrderFields
    }
  }
  ${oceanTransportOrderFields}
`;

export const GET_OTO_CHANGE_REQUESTS = gql`
  query ocean_transport_order($id: ID!) {
    ocean_transport_order(id: $id) {
      id
      status
      change_requests {
        ...changeRequestFields
      }
      is_amendment_pending
    }
  }
  ${changeRequestFields}
`;

export const CANCEL_OCEAN_TRANSPORT_ORDER = gql`
  mutation cancel_ocean_transport_order(
    $ocean_transport_order_id: ID!
    $cancelled_by: String!
    $cancellation_reason: String!
    $remarks: String
    $cancel_allocated_resources: Boolean!
  ) {
    cancel_ocean_transport_order(
      ocean_transport_order_id: $ocean_transport_order_id
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
      remarks: $remarks
      cancel_allocated_resources: $cancel_allocated_resources
    ) {
      id
      status
      remarks
      container_requests {
        ...containerRequestFields
      }
    }
  }
  ${containerRequestFields}
`;

export const UPDATE_OCEAN_TRANSPORT_ORDER = gql`
  mutation update_ocean_transport_order(
    $ocean_transport_order: UpdateOceanTransportOrderInputType!
  ) {
    update_ocean_transport_order(ocean_transport_order: $ocean_transport_order) {
      ...oceanTransportOrderFields
      dg_indexing_date
    }
  }
  ${oceanTransportOrderFields}
`;

export const UPDATE_OCEAN_TRANSPORT_ORDER_STATUS = gql`
  mutation update_ocean_transport_order_status(
    $id: ID!
    $action: OceanTransportOrderActionsEnum!
    $payload: OceanTransportOrderStatusUpdateInputType!
  ) {
    update_ocean_transport_order_status(id: $id, action: $action, payload: $payload) {
      ...oceanTransportOrderFields
    }
  }
  ${oceanTransportOrderFields}
`;

export const ALLOCATE_OCEAN_TRANSPORT_ORDERS = gql`
  mutation allocate_ocean_transport_orders(
    $booking_request_id: ID!
    $ocean_transport_orders: [AllocationResourceInputType!]!
    $remarks: String
  ) {
    allocate_ocean_transport_orders(
      booking_request_id: $booking_request_id
      ocean_transport_orders: $ocean_transport_orders
      remarks: $remarks
    ) {
      id
      remarks
      container_requests {
        ...containerRequestFields
      }
      ocean_transport_orders {
        ...oceanTransportOrderFields
      }
    }
  }
  ${oceanTransportOrderFields}
  ${containerRequestFields}
`;

export const CREATE_OCEAN_TRANSPORT_ORDER_FROM_SHIPMENT = gql`
  mutation create_ocean_transport_order_from_shipment(
    $ocean_transport_order: OceanTransportOrderInputType!
    $shipment_id: ID!
  ) {
    create_ocean_transport_order_from_shipment(
      ocean_transport_order: $ocean_transport_order
      shipment_id: $shipment_id
    ) {
      message
      shipment {
        id
      }
      ocean_transport_order {
        id
      }
    }
  }
`;

export const CREATE_OCEAN_TRANSPORT_ORDER_FROM_BOOKING_REQUEST = gql`
  mutation create_ocean_transport_order_from_booking_request(
    $ocean_transport_order: OTOFromBRInputType!
    $booking_request_id: ID!
    $stuffing_buffer_service_order: UpdateStuffingBookingServiceOrderInputType
  ) {
    create_ocean_transport_order_from_booking_request(
      ocean_transport_order: $ocean_transport_order
      booking_request_id: $booking_request_id
      stuffing_buffer_service_order: $stuffing_buffer_service_order
    ) {
      booking_request {
        id
        container_requests {
          ...containerRequestFields
        }
        ocean_transport_orders {
          ...oceanTransportOrderFields
        }
        stuffing_buffer_service_orders {
          ...stuffingBufferServiceOrderFields
        }
      }
    }
  }
  ${containerRequestFields}
  ${oceanTransportOrderFields}
  ${stuffingBufferServiceOrderFields}
`;

export const validateMergeFields = gql`
  fragment validateMergeFields on MessageListObjectType {
    messages {
      messages
      resource_name
      resource_type
      sub_group
    }
  }
`;

export const DEALLOCATE_BOOKING_REQUEST = gql`
  mutation deallocate_ocean_transport_order(
    $ocean_transport_order_id: ID!
    $booking_request_id: ID!
  ) {
    deallocate_ocean_transport_order(
      ocean_transport_order_id: $ocean_transport_order_id
      booking_request_id: $booking_request_id
    ) {
      ocean_transport_order {
        ...oceanTransportOrderFields
      }
    }
  }
  ${oceanTransportOrderFields}
`;

export const UPDATE_OCEAN_TRANSPORT_ORDER_ALLOCATION = gql`
  mutation update_ocean_transport_order_allocation(
    $id: ID!
    $booking_requests: [AllocationResourceInputType!]!
  ) {
    update_ocean_transport_order_allocation(id: $id, booking_requests: $booking_requests) {
      id
      container_requests {
        ...containerRequestFields
      }
      shipment_containers {
        ...shipmentContainerFields
      }
      booking_requests {
        id
        load_type
        status
        ocean_transport_orders {
          id
        }
        container_requests {
          ...containerRequestFields
        }
      }
    }
  }
  ${containerRequestFields}
  ${shipmentContainerFields}
`;

export const VALIDATE_MERGE_BOOKINGS = gql`
  mutation validate_merge_bookings($ocean_transport_order_ids: [ID!]!) {
    validate_merge_bookings(ocean_transport_order_ids: $ocean_transport_order_ids) {
      ...validateMergeFields
    }
  }
  ${validateMergeFields}
`;

export const VALIDATE_CONTAINERS_FOR_MERGE = gql`
  mutation validate_containers_for_merge($shipment_container_ids: [ID!]!) {
    validate_containers_for_merge(shipment_container_ids: $shipment_container_ids) {
      ...validateMergeFields
    }
  }
  ${validateMergeFields}
`;

export const VALIDATE_SHIPMENT_CREATION = gql`
  mutation validate_shipment_creation($shipment_container_ids: [ID!]!, $shipment_type: String!) {
    validate_shipment_creation(
      shipment_container_ids: $shipment_container_ids
      shipment_type: $shipment_type
    ) {
      messages
      warnings
      ocean_transport_orders {
        id
        booking_number
      }
    }
  }
`;

export const MERGE_BOOKINGS = gql`
  mutation merge_bookings(
    $target_ocean_transport_order_id: ID!
    $ocean_transport_order_ids: [ID!]!
  ) {
    merge_bookings(
      target_ocean_transport_order_id: $target_ocean_transport_order_id
      ocean_transport_order_ids: $ocean_transport_order_ids
    ) {
      message
    }
  }
`;

export const SPLIT_BOOKING = gql`
  mutation split_booking(
    $shipment_container_ids: [ID!]!
    $ocean_transport_order: OceanTransportOrderInputType!
  ) {
    split_booking(
      shipment_container_ids: $shipment_container_ids
      ocean_transport_order: $ocean_transport_order
    ) {
      message
    }
  }
`;

export const SPLIT_OTO_FOR_ROLLOVER_SHUTOUT = gql`
  mutation split_oto_for_rollover_shutout(
    $ocean_transport_order: OceanTransportOrderInputType!
    $routing_update: RoutingUpdateInputType!
  ) {
    split_oto_for_rollover_shutout(
      ocean_transport_order: $ocean_transport_order
      routing_update: $routing_update
    ) {
      message
    }
  }
`;

export const CREATE_SHIPMENT_FROM_OTO = gql`
  mutation create_shipment_from_oto(
    $shipment_container_ids: [ID!]!
    $shipment: ShipmentFromOtoInputType!
    $shipment_document_master: ShipmentDocumentInput!
    $shipment_document_house: ShipmentDocumentInput
    $ocean_transport_order_id: ID!
    $throw_error_on_credit_fail: Boolean
  ) {
    create_shipment_from_oto(
      shipment_container_ids: $shipment_container_ids
      shipment: $shipment
      shipment_document_master: $shipment_document_master
      shipment_document_house: $shipment_document_house
      ocean_transport_order_id: $ocean_transport_order_id
      throw_error_on_credit_fail: $throw_error_on_credit_fail
    ) {
      id
    }
  }
`;

export const CREATE_CONSOL_SHIPMENT_FROM_OTO = gql`
  mutation create_consol_shipment_from_oto(
    $shipment_container_ids: [ID!]!
    $shipment: ConsolShipmentFromOtoInputType!
    $shipment_document_master: ShipmentDocumentInput!
    $ocean_transport_order_id: ID!
  ) {
    create_consol_shipment_from_oto(
      shipment_container_ids: $shipment_container_ids
      shipment: $shipment
      shipment_document_master: $shipment_document_master
      ocean_transport_order_id: $ocean_transport_order_id
    ) {
      id
    }
  }
`;

export const UPDATE_OTO_FOR_ROLLOVER_SHUTOUT = gql`
  mutation update_oto_for_rollover_shutout(
    $ocean_transport_order: UpdateOceanTransportOrderInputType!
    $routing_update: RoutingUpdateInputType!
  ) {
    update_oto_for_rollover_shutout(
      ocean_transport_order: $ocean_transport_order
      routing_update: $routing_update
    ) {
      ...oceanTransportOrderFields
    }
  }
  ${oceanTransportOrderFields}
`;

export const SPLIT_UPDATE_OTO_WARNING = gql`
  mutation split_update_oto_warning(
    $ocean_transport_order: OceanTransportOrderInputType!
    $shipment_container_ids: [ID!]
    $action: SplitUpdateOtoWarningActionsEnum!
  ) {
    split_update_oto_warning(
      ocean_transport_order: $ocean_transport_order
      shipment_container_ids: $shipment_container_ids
      action: $action
    ) {
      warnings
    }
  }
`;

export const MARK_AMENDMENT_DONE = gql`
  mutation mark_amendment_done($id: ID!, $resolved_remarks: String, $send_email: Boolean) {
    mark_amendment_done(id: $id, resolved_remarks: $resolved_remarks, send_email: $send_email) {
      id
      change_requests {
        ...changeRequestFields
      }
      is_amendment_pending
    }
  }
  ${changeRequestFields}
`;
