import React from 'react';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { get as _get, pick, startCase } from 'lodash';
import { collapsibleCard } from '../../Common/common';
import { CustomIcon } from '@shipmnts/pixel-hub';
import {
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
} from 'operations/modules/shipment/constants';
import { LOAD_TYPE_FCL, LOAD_TYPE_FTL_BREAK_BULK } from 'operations/baseConstants';
import { ShipmentValue } from 'operations/models/Shipment';

function getTitle(shipment?: ShipmentValue) {
  if (shipment?.isConsolShipment()) return 'Order Details';
  if (shipment?.shipment_type === 'general') return 'Vehicle Details';
  return 'Trip Details';
}
const GENERAL_SHIPMENT_KEYS = [
  'Vehicle License Plate #',
  'Vehicle Type',
  'Vehicle Chassis Number',
  'Model',
  'Make',
  'Max Weight Capacity',
];

const makeRoundOffNumber = (value: any) => {
  if (!value) return value;
  return parseFloat(value.toFixed(3));
};

export default function TripDetails() {
  const { shipment } = useShipmentDetail();
  let tripDetail: { [key: string]: string | React.ReactNode } = {};
  if (
    ((!shipment?.isConsolShipment() && !shipment?.split_from_order_id) ||
      (!shipment.isHouseShipment() && shipment?.split_from_order_id)) &&
    shipment?.shipment_type
  ) {
    tripDetail['Vehicle License Plate #'] = shipment?.vehicle?.vehicle_license_plate_number || '';
    tripDetail['Transporter Name'] = shipment?.vehicle?.company?.registered_name || '';

    tripDetail['Vehicle Type'] = startCase(shipment?.vehicle?.vehicle_category || '');
    tripDetail['Driver Details'] =
      (shipment?.driver_name || '') + ' - ' + (shipment?.driver_contact_number || '');
  }
  if (
    [MOVEMENT_MODE_CONTAINER_STUFFING, MOVEMENT_MODE_CONTAINER_DESTUFFING].includes(
      shipment?.movement_mode || ''
    )
  ) {
    tripDetail['Container Qty'] = shipment?.getContainerSummary();
  }
  tripDetail['Commodity'] = _get(shipment, 'commodity_description');
  const unit =
    shipment?.load_type === LOAD_TYPE_FCL
      ? 'Containers'
      : shipment?.load_type !== LOAD_TYPE_FTL_BREAK_BULK
      ? shipment?.weight_unit
      : '';
  const allocationPendingQuantity = _get(shipment, 'allocation_pending_quantity');
  if (shipment?.isRoadCustomerOrder()) {
    tripDetail['Ordered Quantity'] = `${makeRoundOffNumber(
      _get(shipment, 'ordered_quantity')
    )} ${unit}`;
    tripDetail['Allocated Quantity'] = `${makeRoundOffNumber(
      _get(shipment, 'allocated_quantity')
    )} ${unit}`;
    tripDetail['Pending Quantity'] = `${makeRoundOffNumber(
      allocationPendingQuantity && allocationPendingQuantity > 0 ? allocationPendingQuantity : 0
    )} ${unit}`;
    if (allocationPendingQuantity && allocationPendingQuantity < 0) {
      tripDetail['Overload Quantity'] = `${makeRoundOffNumber(
        Math.abs(allocationPendingQuantity)
      )} ${unit}`;
    }
  }

  tripDetail['Gross Wt'] = `${makeRoundOffNumber(shipment?.gross_weight || 0)} ${
    shipment?.weight_unit || ''
  }`;
  tripDetail['No of Packages'] = `${_get(shipment, 'total_number_of_packages', 0) || 0}`;
  if (!shipment?.isRoadShipment()) {
    const carrierName = _get(shipment, 'carrier.name');
    tripDetail['Carrier'] = carrierName || '-';
    tripDetail['Carrier Ref #'] = shipment?.getOtoBookingFromShipment()?.booking_number || '';
  }

  if (shipment?.shipment_type === 'general') {
    tripDetail['Vehicle Chassis Number'] =
      shipment?.vehicle?.vehicle_chassis_number?.join(', ') || '-';
    tripDetail['Model'] = shipment?.vehicle?.model || '-';
    tripDetail['Make'] = shipment?.vehicle?.make || '-';
    if (!!shipment?.vehicle?.max_weight_capacity && shipment?.vehicle?.max_weight_capacity > 0)
      tripDetail['Max Weight Capacity'] =
        shipment?.vehicle?.max_weight_capacity + ' ' + (shipment?.vehicle?.weight_unit || 'kgs');
    tripDetail = pick(tripDetail, GENERAL_SHIPMENT_KEYS);
  }

  return (
    <div>
      {collapsibleCard({
        icon: <CustomIcon icon="ShipIconNew" />,
        header: getTitle(shipment),
        data: tripDetail,
      })}
    </div>
  );
}
