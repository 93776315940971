import React, { useCallback } from 'react';
import { startCase as _startCase } from 'lodash';
import {
  AddressCompanySearch,
  Checkbox,
  Col,
  Collapse,
  Form,
  Radio,
  Row,
} from '@shipmnts/pixel-hub';

import { useSession } from 'common';

import { useInquiryForm } from './InquiryForm';
import {
  BUSINESS_RECEIVED_THROUGH_OPTION,
  BUSINESS_THROUGH_DIRECT_OPTION,
  INQUIRY_ADDITIONAL_PARTIES,
} from 'sales_hub/utils/constants';
import { PartyValue } from 'common/components/CustomInputs/AddressCompanySearch/AddressCompanySearch';

const InquiryPartyDetails = () => {
  const session = useSession();
  const { form, customer, businessReceivedThrogh, inquiry } = useInquiryForm();

  // will prefill agent, on change of customer if needed.
  const handleCompanyChange = useCallback(
    (value: any) => {
      const businessReceivedThrough = form.getFieldValue('business_received_through');
      if (businessReceivedThrough === BUSINESS_THROUGH_DIRECT_OPTION && value) {
        form.setFieldValue('billing_party', value);
      }

      if (
        value?.party_company?.sales_partner?.country_of_incorporation &&
        session?.company_account?.country_of_incorporation
      ) {
        if (
          value.party_company.sales_partner.country_of_incorporation ===
          session.company_account.country_of_incorporation
        ) {
          form.setFieldValue('business_received_through', 'subagent');
        } else {
          form.setFieldValue('business_received_through', 'agent_nomination');
        }
        form.setFieldValue('billing_party', {
          party_company: value.party_company.sales_partner,
        });
      }
    },
    [session, form]
  );

  // checking if both parties are same
  const isSameParty = useCallback((party1?: PartyValue | null, party2?: PartyValue | null) => {
    if (party1 && party2) return party1?.party_company?.id === party2?.party_company?.id;
    else return false;
  }, []);

  const getUpdatedPartyValue = useCallback(
    (party?: PartyValue | null, value?: PartyValue | null) => {
      return {
        ...(party || null),
        party_company: value?.party_company,
        party_address: value?.party_address,
      };
    },
    []
  );

  return (
    <div style={{ marginBottom: '1.5em' }}>
      <h3 className="inq-form-section-title">{'Party Details'}</h3>
      <Row gutter={{ lg: 16 }}>
        <Col xs={24} sm={12} lg={6}>
          <Form.Item
            name="business_received_through"
            label="Business Received Through"
            rules={[{ required: true }]}
            tooltip="Source of business generated - allows you to classify self generated or business referred from an overseas nomination or a third-party subagent"
          >
            <Radio.Group
              // disabled={editInquiryDisabled}
              onChange={(e) => {
                const value = e.target.value;
                if (value === 'direct') {
                  form.setFieldValue('billing_party', customer);
                }
              }}
              options={BUSINESS_RECEIVED_THROUGH_OPTION.map((opt) => ({
                value: opt.value,
                label: opt.label || _startCase(opt.value),
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={9}>
          <Form.Item noStyle shouldUpdate={true}>
            <Form.Item name="customer" label="Customer / Lead" rules={[{ required: true }]}>
              <AddressCompanySearch
                companySearchProps={{
                  searchProps: { is_lead: true },
                  // disabled: editInquiryDisabled,
                  showCreditStatusTag: true,
                }}
                onChange={handleCompanyChange}
                // addressSearchProps={{
                //   disabled: editInquiryDisabled,
                // }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
        {businessReceivedThrogh !== BUSINESS_THROUGH_DIRECT_OPTION && (
          <Col xs={24} sm={12} lg={8}>
            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) => {
                const businessReceivedThrough = getFieldValue('business_received_through');
                const disabled = businessReceivedThrough === 'direct'; // || editInquiryDisabled;
                return (
                  <Form.Item
                    name="billing_party"
                    label="Agent Company"
                    rules={[{ required: true }]}
                    dependencies={['business_received_through', 'customer']}
                    tooltip="Agent through which you received customer."
                  >
                    <AddressCompanySearch
                      companySearchProps={{
                        disabled: disabled,
                      }}
                      addressSearchProps={{ disabled: disabled }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
      </Row>
      <Collapse
        defaultActiveKey={(inquiry?.shipment_parties?.length || 0) > 0 ? 'additional_parties' : ''}
      >
        <Collapse.Panel
          header={'Shipper and Consignee'}
          key="additional_parties"
          style={{ backgroundColor: 'white', borderRadius: '8px' }}
        >
          <Row gutter={16}>
            {INQUIRY_ADDITIONAL_PARTIES.map((party, index) => {
              return (
                <Col key={index} span={8}>
                  <Form.Item
                    // rules={[{ required: true }]}
                    name={['shipment_parties', party]}
                    label={_startCase(party)}
                  >
                    <AddressCompanySearch />
                  </Form.Item>
                  <Form.Item name={`${party}_same_as_customer`} noStyle valuePropName="checked">
                    <Checkbox
                      onChange={(e) => {
                        const partyVal = form?.getFieldValue(['shipment_parties', party]);
                        if (!e.target.checked) {
                          if (isSameParty(customer, partyVal)) {
                            form?.setFieldValue(
                              ['shipment_parties', party],
                              getUpdatedPartyValue(partyVal, null)
                            );
                          }
                        } else {
                          form?.setFieldValue(
                            ['shipment_parties', party],
                            getUpdatedPartyValue(partyVal, customer)
                          );
                        }
                        form?.setFieldValue(`${party}_same_as_customer`, e.target.checked);
                      }}
                    >
                      Same As Customer
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default InquiryPartyDetails;
