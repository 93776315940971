import { ContractValue } from 'src/packages/rate_management/models/Contract';
import { PerformAction } from '../models';
import ExpireContractValidityModal from 'src/packages/rate_management/components/ExpireContractValidityModal';
import { ShowWarning } from '@shipmnts/pixel-hub';

interface ContractActionType {
  contract: ContractValue;
  extraProps?: any;
}

export const expireOnRenderer = (payload: ContractActionType): PerformAction => {
  const { contract } = payload;
  return {
    actionParams: {
      contract,
    },
    component: ExpireContractValidityModal,
  };
};

export const showWarning = (payload: ContractActionType): PerformAction => {
  const { extraProps } = payload;
  return {
    actionParams: extraProps,
    component: ShowWarning,
  };
};
