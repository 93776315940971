/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import SalesPerson from './SalesPerson';
import UserContact from './UserContact';
import BranchAccount from './BranchAccount';

const Team = types.model({
  id: types.identifier,
  sales_person: types.maybe(types.maybeNull(types.late((): IAnyModelType => SalesPerson))),
  branch_accounts: types.maybe(
    types.maybeNull(types.array(types.late((): IAnyModelType => BranchAccount)))
  ),
  role: types.maybe(types.string),
  user_contact: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserContact))),
  reference_type: types.maybe(types.string),
  reference_id: types.maybe(types.string),
});

export interface TeamValue extends Instance<typeof Team> {}
export interface TeamIn extends SnapshotIn<typeof Team> {}
export interface TeamOut extends SnapshotOut<typeof Team> {}
export interface TeamPayloadType extends Partial<TeamValue> {
  sales_person_id?: string;
  branch_account_ids?: string[];
  reference_id?: string;
  reference_type?: string;
  _destroy?: boolean;
}

export default Team;
