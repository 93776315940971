import { Form, FormInstance } from '@shipmnts/pixel-hub';
import RoutingDetails from 'operations/components/RoutingDetails/RoutingDetails';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect, useRef } from 'react';
import {
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_AIRPORT_PICKUP,
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
} from '../../../constants';
import { ShipmentRouteValue } from 'operations/models/ShipmentRoute';
import { getUpdatedRoutingDetails } from './helpers';

interface RoadRoutingDetailsProps {
  value?: ShipmentValue | ShipmentRouteValue;
  form?: FormInstance;
  allowAddNew?: boolean;
  docType?: 'route' | 'shipment';
}
export default function RoadRoutingDetails(props: RoadRoutingDetailsProps) {
  const { form, value, allowAddNew = true, docType = 'shipment' } = props;
  const routing_details_ref = useRef<{ runValidation: () => boolean; addHop: () => void }>();
  const movementMode = Form.useWatch('movement_mode', form);
  const airMovementMode = [MOVEMENT_MODE_AIRPORT_DELIVERY, MOVEMENT_MODE_AIRPORT_PICKUP].includes(
    movementMode
  );

  useEffect(() => {
    if (
      allowAddNew &&
      (movementMode === MOVEMENT_MODE_CONTAINER_STUFFING ||
        movementMode === MOVEMENT_MODE_CONTAINER_DESTUFFING) &&
      (form?.getFieldValue('routing_details')?.routing_legs?.length || 0) <= 1
    ) {
      routing_details_ref?.current?.addHop();
    }
  }, [movementMode, form, value, allowAddNew]);

  return (
    <Form.Item noStyle dependencies={['movement_mode']}>
      {({ getFieldValue }) => {
        const routingValue = getFieldValue('routing_details');
        if (!value?.id && docType === 'route' && routingValue) {
          const updatedRoutingValue = getUpdatedRoutingDetails(routingValue, movementMode);
          form?.setFieldValue('routing_details', updatedRoutingValue);
        }
        return (
          <Form.Item
            name="routing_details"
            noStyle
            rules={[
              {
                validator: (rule, value) => {
                  if (!routing_details_ref?.current?.runValidation()) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
          >
            <RoutingDetails
              ref={routing_details_ref}
              locationSearchType={
                airMovementMode
                  ? ['Airport', 'City']
                  : ['CFS', 'ICD', 'PortTerminal', 'Seaport', 'City']
              }
              startSequence={2}
              endSequence={3}
              routing_type="pickup"
              showTerminal={true}
              showWaitingTime={true}
              enableDragAndDrop={true}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
