import InquiryOptionApprove from 'sales_hub/components/InquiryOption/InquiryOptionApprove';
import { PerformAction } from '../models';
import {
  InquiryOptionDrawer,
  InquiryOptionDelete,
  ShareInquiry,
  InquiryLinkedResources,
} from 'sales_hub';

export const performEditInquiryOption = (payload: any): PerformAction => {
  const { inquiryOption, sessionData } = payload;

  return {
    actionParams: {
      id: inquiryOption.id,
      inquiryOptionId: inquiryOption.id,
      referenceType: 'SalesHub::InquiryOption',
      session: sessionData,
      inquiry: inquiryOption?.inquiry,
      isVisible: true,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: InquiryOptionDrawer,
  };
};
export const performDeleteInquiryOption = (payload: any): PerformAction => {
  const { inquiryOption, setVisible } = payload;

  return {
    actionParams: {
      id: inquiryOption.id,
      isVisible: true,
      setVisible: setVisible,
      refetchInquiry: inquiryOption?.refetchData,
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: InquiryOptionDelete,
  };
};

export const performInquiryOptionSharePDF = (payload: any): PerformAction => {
  const { inquiryOption } = payload;

  return {
    actionParams: {
      docTypeId: inquiryOption.inquiry.id,
      docType: 'SalesHub::Inquiry',
      doc: inquiryOption.inquiry,
      selectedQuote: inquiryOption.quotation_number,
    },
    component: ShareInquiry,
  };
};

export const performDuplicateInquiryOption = (payload: any): PerformAction => {
  const { inquiryOption, sessionData } = payload;
  return {
    actionParams: {
      id: inquiryOption.id,
      performDuplicate: true,
      inquiryOptionId: inquiryOption.id,
      referenceType: 'SalesHub::InquiryOption',
      session: sessionData,
      inquiry: inquiryOption?.inquiry,
      isVisible: true,
      title: 'Duplicate Quotation',

      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
    },
    component: InquiryOptionDrawer,
  };
};

export const performAmendInquiryOption = (payload: any): PerformAction => {
  const { inquiryOption, sessionData } = payload;

  return {
    actionParams: {
      id: inquiryOption.id,
      performDuplicate: true,
      inquiryOptionId: inquiryOption.id,
      referenceType: 'SalesHub::InquiryOption',
      session: sessionData,
      inquiry: inquiryOption?.inquiry,
      isVisible: true,
      disabledResource: false,
      title: 'Amend Quotation',
      onSuccess: (sendEmail: boolean) => {
        // if (sendEmail) {
        //   setEmailProps({
        //     title: `Send ${startCase(
        //       (actionName || '').replace('_and_update_booking', '')
        //     )} Confirmation Email`,
        //     action_name: 'shutout_notice',
        //     resource_ids: [container.id],
        //   });
        //   setVisible(true);
        // }
      },
      ChildrenComponent: InquiryOptionDrawer,
    },
    component: InquiryLinkedResources,
  };
};

export const performApproveInquiryOption = (payload: any): PerformAction => {
  const { inquiryOption, setVisible } = payload;

  return {
    actionParams: {
      id: inquiryOption.id,
      isVisible: true,
      setVisible: setVisible,
      inquiry: inquiryOption?.inquiry,
      refetchInquiry: inquiryOption?.refetchData,
      onSuccess: (sendEmail: boolean) => {
        //
      },
    },
    component: InquiryOptionApprove,
  };
};
