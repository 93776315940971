import React, { useCallback, useEffect, useState } from 'react';
import { FETCH_STOCK_LEDGER } from './graphql/stockLedger';
import { useLazyQuery } from '@apollo/client';
import {
  BaseTable,
  RenderLinkByDocType,
  SearchDocTypeEditor,
  Typography,
} from '@shipmnts/pixel-hub';
import StockLedgerFilter from './Components/StockLedgerFilter';
import { QtyUomTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';
import { compact as _compact } from 'lodash';
import { Column } from 'operations/models/Report';

const StockLedgerReport = (props: any) => {
  const [stockLedgerItems, setStockLedgerItems] = useState<any>({});

  const [fetchStockLedger, { error, loading, data }] = useLazyQuery(FETCH_STOCK_LEDGER);
  const staticRow = ['Opening', 'Closing', 'Total'];
  useEffect(() => {
    fetchStockLedger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStockLedger]);

  const fetchLedgers = (all_values: any) => {
    // Doing this just to handle lifo fifo as filter
    fetchStockLedger({
      variables: {
        ...all_values,
      },
    });
  };
  useEffect(() => {
    if (data?.fetch_stock_ledger) {
      console.log('data?.fetch_stock', data?.fetch_stock_ledger);
      setStockLedgerItems(data?.fetch_stock_ledger);
    }
  }, [data, error]);

  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Transaction #',
        field: 'transaction_number',
        colId: 'transaction_number',
        columnType: 'String',
        cellRenderer: (params: any) => {
          if (!staticRow.includes(params?.value))
            return (
              <RenderLinkByDocType
                key={params?.data?.warehouse_transaction?.id}
                doc_type_id={'Wms::WarehouseTransaction'}
                id={params?.data?.warehouse_transaction?.id}
                value={params?.data?.warehouse_transaction?.transaction_number}
                extra_fields={{}}
                colDef={undefined}
              />
            );
          else return <b>{params.value}</b>;
        },
      },
      {
        headerName: 'Transaction Date',
        field: 'transaction_date',
        colId: 'transaction_date',
        columnType: 'Date',
        editable: false,
      },
      {
        headerName: 'Product Name',
        field: 'product_name',
        colId: 'product_name',
        cellEditor: 'SearchDocTypeEditor',
        editable: false,
        maxWidth: 300,
        cellRenderer: (params: any) => {
          if (params.node.isRowPinned()) return <></>;
          return (
            <RenderLinkByDocType
              key={params?.data?.product?.id}
              doc_type_id={'Wms::Product'}
              id={params?.data?.product?.id}
              value={params?.data?.product?.product_name}
              extra_fields={{}}
              colDef={undefined}
            />
          );
        },
      },
      {
        headerName: 'Cust. Product Code',
        field: 'product_code',
        colId: 'product_code',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Location',
        field: 'warehouse_unit',
        colId: 'warehouse_unit',
        editable: false,
        valueFormatter: (params: any) => params.value?.node_name,
        maxWidth: 300,
        filter: 'agSetColumnFilter',
        keyCreator: (params: any) => params.value?.node_name,
        filterParams: {
          convertValuesToStrings: true,
        },
        cellRenderer: (params: any) => {
          if (params.node.isRowPinned()) return <></>;
          return (
            <RenderLinkByDocType
              key={params?.data?.warehouse_unit?.id}
              doc_type_id={'Wms::WarehouseUnit'}
              id={params?.data?.warehouse_unit?.id}
              value={params?.data?.warehouse_unit?.node_name}
              extra_fields={{}}
              colDef={undefined}
            />
          );
        },
      },
      {
        headerName: 'Qty',
        field: 'qty',
        colId: 'qty',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Balance',
        field: 'balance',
        colId: 'balance',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Gross Vol',
        field: 'gross_volume',
        colId: 'gross_volume',
        columnType: 'String',
        editable: false,
        cellRenderer: (params: any) => {
          return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
        },
      },
      {
        headerName: 'Gross Weight',
        field: 'gross_weight',
        colId: 'gross_weight',
        columnType: 'String',
        editable: false,
        cellRenderer: (params: any) => {
          return (
            <QtyUomTypeRenderer
              uom={params?.data?.weight_unit || 'kgs'}
              qty={params?.data?.gross_weight}
            />
          );
        },
      },
    ]);
    return columnDefs;
    // eslint-disable-next-line
  }, []);
  const allColumnsDefs = getColumns() || [];
  const components = {
    SearchDocTypeEditor,
  };
  return (
    <>
      <div
        style={{
          height: '56px',
          borderBottom: '1px solid #dadee7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ paddingLeft: '24px', marginRight: 'auto' }}>
          <Typography.Title level={5}>Stock Ledger</Typography.Title>
        </span>
      </div>
      <div className="main-report">
        <StockLedgerFilter
          onFilterChange={fetchLedgers}
          filters={[
            {
              label: 'Transaction Date',
              name: 'transaction_date',
              field_type: 'DateTime',
              operator: 'dateRange',
              is_quick_filter: true,
            },
            {
              label: 'Product',
              name: 'product_id',
              field_type: 'Search',
              filter_options: '{"doc_type": "Wms::Product"}',
              is_quick_filter: true,
              operator: 'in',
            },
            {
              label: 'Location',
              name: 'warehouse_unit_id',
              field_type: 'Search',
              filter_options: '{"doc_type":"Wms::WarehouseUnit"}',
              is_quick_filter: true,
              operator: 'in',
            },
            {
              label: 'Customer',
              name: 'customer_company_id',
              field_type: 'Search',
              filter_options: '{"doc_type":"Network::Company"}',
              is_quick_filter: true,
              operator: 'in',
            },
            {
              label: 'Exp Date',
              name: 'exp_date',
              field_type: 'DateTime',
              operator: 'is',
            },
            {
              label: 'Mfg Date',
              name: 'mfg_date',
              field_type: 'DateTime',
              operator: 'is',
            },
          ]}
          sorts={[]}
        />
        {!loading && (
          <BaseTable
            reportName="wms_stock_ledger_report"
            rowData={stockLedgerItems?.query_data || []}
            height={'65vh'}
            columns={allColumnsDefs}
            reportConfig={{
              components: components,
              pinnedTopRowData: [{ ...stockLedgerItems?.opening, hideRowIndex: true }],
              pinnedBottomRowData: [
                { ...stockLedgerItems?.total, hideRowIndex: true },
                { ...stockLedgerItems?.closing, hideRowIndex: true },
              ],
            }}
          />
        )}
      </div>
    </>
  );
};

export default StockLedgerReport;
