import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Drawer,
  Input,
  message,
  Row,
  Select,
  Form,
  Space,
} from '@shipmnts/pixel-hub';
import { CANCEL_ROAD_HOUSE_SHIPMENT, UPDATE_SHIPMENT_STATUS } from 'operations/graphql/shipment';
import { ShipmentValue } from 'operations/models/Shipment';
import { ShipmentDocumentValue } from 'operations/models/ShipmentDocument';
import {
  BOOKING_CANCELLATION_REASONS,
  BOOKING_CANCELLED_BY,
  CANCEL_SHIPMENT,
  LOAD_TYPE_LCL,
  REASONS_FOR_SHIPMENT_CANCELLATION,
  SHIPMENT_STATUS_PLANNED,
} from '../../constants';
import { startCase as _startCase } from 'lodash';
interface CancelShipmentDrawerProps {
  shipment: ShipmentValue;
  shipment_document_master: ShipmentDocumentValue;
  cancellationType: string;
  onCancel?: () => void;
  onSuccess?: () => void;
}
const { TextArea } = Input;
export default function CancelShipmentDrawer(props: CancelShipmentDrawerProps) {
  const { shipment, onCancel, onSuccess } = props;
  const [form] = Form.useForm();
  const isCancelling = false;

  const [updateShipmentStatus, { data, loading, error }] = useMutation(UPDATE_SHIPMENT_STATUS);
  const [
    cancelRoadHouseShipment,
    { data: roadHouse, loading: roadHouseLoading, error: roadHouseError },
  ] = useMutation(CANCEL_ROAD_HOUSE_SHIPMENT);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      message.error(error.message);
      return;
    }
    if (data?.update_shipment_state) {
      message.success('successfully cancelled');
      if (onSuccess) onSuccess();
    }
  }, [loading, error, data, onSuccess]);

  useEffect(() => {
    if (roadHouseLoading) {
      return;
    }
    if (roadHouseError) {
      message.error(roadHouseError.message);
      return;
    }
    if (roadHouse?.cancel_road_house_shipment) {
      message.success('successfully cancelled');
      if (onSuccess) onSuccess();
    }
  }, [roadHouseLoading, roadHouseError, roadHouse, onSuccess]);

  const onClose = () => {
    if (onCancel) onCancel();
  };
  const isPlannedShipment = shipment.status === SHIPMENT_STATUS_PLANNED;
  return (
    <Drawer
      title={'Cancel ' + isPlannedShipment ? 'Customer Order' : 'Shipment'}
      width={500}
      onClose={onClose}
      open={true}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button style={{ marginRight: '5px' }} onClick={onClose}>
            Close
          </Button>
          <Button
            type="primary"
            danger
            loading={isCancelling}
            disabled={isCancelling}
            onClick={form.submit}
            className="margin-right-sm"
          >
            {isCancelling
              ? `Cancelling Shipment..`
              : `Cancel ${isPlannedShipment ? 'Customer Order' : 'Shipment'}`}
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          if (shipment.isRoadHouseShipment()) {
            cancelRoadHouseShipment({
              variables: {
                id: shipment?.id,
                returned_from: values?.returned_from,
                cancellation_reason: values.cancellation_reason,
                cancelled_by: values?.cancelled_by,
              },
            });
          } else {
            updateShipmentStatus({
              variables: {
                id: shipment?.id,
                action: CANCEL_SHIPMENT,
                event_data: JSON.stringify({
                  reason_for_cancellation: isPlannedShipment
                    ? values.cancellation_reason.split('----')[1]
                    : values.cancellation_reason,
                  remarks: values.remarks,
                }),
              },
            });
          }
        }}
      >
        {!!shipment.is_linked_with_booking && shipment.load_type !== LOAD_TYPE_LCL && (
          <Row>
            <Form.Item>
              <Alert message="Cancelling Shipment will delink attached Booking(s)." banner />
            </Form.Item>
          </Row>
        )}
        <Row style={{ width: '100%' }}>
          <Form.Item
            initialValue={
              isPlannedShipment
                ? BOOKING_CANCELLATION_REASONS['shipper'][0]
                : REASONS_FOR_SHIPMENT_CANCELLATION[0].value
            }
            name="cancellation_reason"
            label={`Cancellation Reason`}
            style={{ width: '100%' }}
          >
            {isPlannedShipment ? (
              <Select showSearch allowClear={false} placeholder="Select Cancellation Reason">
                {BOOKING_CANCELLED_BY.map((cancelled_by: string) => (
                  <Select.OptGroup key={cancelled_by} label={_startCase(cancelled_by)}>
                    {BOOKING_CANCELLATION_REASONS[cancelled_by].map((option, index) => (
                      <Select.Option
                        key={`${cancelled_by}_${index}`}
                        value={`${cancelled_by}----${option}`}
                      >
                        {_startCase(option)}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            ) : (
              <Select style={{ width: '100%' }} options={REASONS_FOR_SHIPMENT_CANCELLATION} />
            )}
          </Form.Item>
          <Form.Item name="remarks" style={{ width: '100%' }} label="Remarks For Cancellation">
            <TextArea rows={5} style={{ width: '100%' }} />
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
}
