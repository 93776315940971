import React from 'react';
import { ContractValue } from 'src/packages/rate_management/models/Contract';
import { Action } from '../models';
import { NEGATIVE_TYPE, PRIMARY_TYPE, SECONDARY_TYPE } from '../constants';
import {
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  hasPermission,
  message,
} from '@shipmnts/pixel-hub';
import { ApolloClient } from '@apollo/client';
import { UPSERT_CONTRACT } from 'src/packages/rate_management/graphql/mutations';
import { expireOnRenderer, showWarning } from './contractActionRenderer';
import {
  CANCEL_CONTRACT_STATUS,
  CONTRACT_DOCTYPE_MAP,
  DRAFT_CONTRACT_STATUS,
  EXPIRE_CONTRACT_STATUS,
  PERMISSION_AMEND_CONTRACT,
  PERMISSION_EDIT_CONTRACT,
  SUBMIT_CONTRACT_STATUS,
} from 'src/packages/rate_management/constants';
import { SessionDataValue } from 'network/models/SessionData';

const getContractActions = (
  contract: ContractValue,
  navigate: any,
  sessionData: SessionDataValue,
  client: ApolloClient<object>,
  isDetailScreen: boolean
) => {
  const actions: Action[] = [];

  const { status, id, type } = contract;
  const permissions = sessionData.permissions;
  const doc_type = CONTRACT_DOCTYPE_MAP[type || ''];

  const hasEditPermission = hasPermission(permissions, {
    name: PERMISSION_EDIT_CONTRACT,
    docType: doc_type,
  });

  actions.push({
    key: 'edit_contract',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Contract',
    icon: <EditOutlined />,
    isEnable: status === DRAFT_CONTRACT_STATUS && !isDetailScreen && hasEditPermission,
    onClick: () => navigate(`~/form/contract/${id}`),
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'change_expiry',
    displayComponent: 'Change Expiry',
    isEnable: status === SUBMIT_CONTRACT_STATUS && hasEditPermission,
    icon: <ClockCircleOutlined />,
    performAction: expireOnRenderer,
  });

  actions.push({
    type: SECONDARY_TYPE,
    key: 'amend',
    displayComponent: 'Amend Contract',
    isEnable:
      status === SUBMIT_CONTRACT_STATUS &&
      hasPermission(permissions, {
        name: PERMISSION_AMEND_CONTRACT,
        docType: doc_type,
      }),
    icon: <CopyOutlined />,
    onClick: () => navigate(`~/form/contract/amend/${id}`),
  });

  actions.push({
    type: SECONDARY_TYPE,
    key: 'duplicate',
    displayComponent: 'Duplicate Contract',
    isEnable: hasEditPermission,
    icon: <CopyOutlined />,
    onClick: () => navigate(`~/form/contract/duplicate/${id}`),
  });

  actions.push({
    key: 'cancel_contract',
    type: NEGATIVE_TYPE,
    displayComponent: 'Cancel Contract',
    icon: <CloseOutlined />,
    isEnable:
      (status === DRAFT_CONTRACT_STATUS || status === SUBMIT_CONTRACT_STATUS) && hasEditPermission,
    performAction: showWarning,
    extraProps: {
      title: `Are you sure, you want to cancel ${contract.contract_number}?`,
      content: 'Once you cancel this contract, you will not be able to undo it.',
      onConfirm: async (onSuccess: (() => void) | undefined) => {
        const { data, errors } = await client.mutate({
          mutation: UPSERT_CONTRACT,
          variables: {
            contract_id: contract.id,
            contract: {
              status: CANCEL_CONTRACT_STATUS,
              type: contract.type,
              rate_type: contract.rate_type,
              freight_charge: contract.freight_charge,
              load_type: contract.load_type,
            },
          },
        });
        if (errors) {
          console.error(errors);
          message.error(errors[0].message);
        } else if (data) {
          message.success(`Contract ${contract?.contract_number} Cancelled Successfully!`);
          if (onSuccess) onSuccess();
        }
      },
    },
  });

  actions.push({
    key: 'expire_contract',
    type: NEGATIVE_TYPE,
    displayComponent: 'Expire Contract',
    icon: <ClockCircleOutlined />,
    isEnable: status === SUBMIT_CONTRACT_STATUS && hasEditPermission,
    performAction: showWarning,
    extraProps: {
      title: `Are you sure, you want to expire ${contract.contract_number}?`,
      content: 'Once you mark contract as expired, they will not be applied on any shipments.',
      onConfirm: async (onSuccess: (() => void) | undefined) => {
        const { data, errors } = await client.mutate({
          mutation: UPSERT_CONTRACT,
          variables: {
            contract_id: contract.id,
            contract: {
              status: EXPIRE_CONTRACT_STATUS,
              type: contract.type,
              rate_type: contract.rate_type,
              freight_charge: contract.freight_charge,
              load_type: contract.load_type,
            },
          },
        });
        if (errors) {
          console.error(errors);
          message.error(errors[0].message);
        } else if (data) {
          message.success(`Contract ${contract?.contract_number} marked as Expired Successfully!`);
          if (onSuccess) onSuccess();
        }
      },
    },
  });

  return actions;
};

export default getContractActions;
