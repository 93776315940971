import React, { useState } from 'react';

import {
  Badge,
  Button,
  CustomIcon,
  Drawer,
  Form,
  Icon,
  Input,
  Popover,
  SearchOutlined,
  SortForm,
  Space,
} from '@shipmnts/pixel-hub';
import {
  FiltersObjectType,
  generatePayload,
  getMoreFilters,
  getQuickFilters,
  SortsObjectType,
} from './StockLedgerFilterHelper';

interface StockLedgerFilterProp {
  initialFormValue?: Record<string, any>;
  filters?: FiltersObjectType[];
  sorts?: SortsObjectType[];
  onFilterChange?: (allValue: any) => void;
}

const SortFormContent = ({
  setShowSort,
  sorts,
}: {
  setShowSort: (value: boolean) => void;
  sorts: SortsObjectType[];
}) => {
  return (
    <div className="no-margin-form-item" style={{ minWidth: '650px' }}>
      <SortForm
        fields={{
          'Sort By': sorts,
        }}
        onClose={() => setShowSort(false)}
        withoutView
      />
      <div className="flex-row report-popover-footer">
        <Space
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button
            type="primary"
            onClick={() => setShowSort(false)}
            style={{
              marginLeft: '10px',
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    </div>
  );
};

const StockLedgerFilter = (props: StockLedgerFilterProp) => {
  const { initialFormValue = {}, filters = [], sorts = [], onFilterChange } = props;
  const [showSort, setShowSort] = useState(false);

  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [form] = Form.useForm();

  const moreFiltersCount =
    Object.values(Form.useWatch('filters', form) || []).filter(
      (value) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
    ).length || 0;

  const sortCount =
    (Form.useWatch('sort', form) || []).filter((value: { field_id: any }) => !!value.field_id)
      .length || 0;

  return (
    <Form
      form={form}
      layout="vertical"
      labelCol={{ span: 24 }}
      initialValues={initialFormValue}
      onValuesChange={(changedValues, _allValues) => {
        // Handle All Filter, Sorts, Search etc
        onFilterChange && onFilterChange(generatePayload(_allValues));
      }}
    >
      <Drawer
        title="More Filters"
        placement="right"
        closable={true}
        open={showMoreFilter}
        onClose={() => {
          setShowMoreFilter(false);
        }}
        width={'30%'}
      >
        {getMoreFilters(filters, form)}
      </Drawer>
      <Space>
        <Form.Item name={['quick_filters', 'search_text']} noStyle>
          <Input
            size="small"
            placeholder="Search By Any Text Field"
            prefix={
              <SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            }
          />
        </Form.Item>

        <Badge count={moreFiltersCount} className="view-filters-applied">
          <Button
            className={`view-action-button ${moreFiltersCount > 0 && 'view-filters-applied'}`}
            size="small"
            icon={<Icon component={() => <CustomIcon icon="NewFilterIcon" />} />}
            onClick={() => setShowMoreFilter(true)}
          >
            More Filters
          </Button>
        </Badge>
        <Form.Item name="sort" noStyle>
          <Popover
            overlayClassName="report-sort-button"
            open={showSort}
            title="Sort by"
            trigger="click"
            destroyTooltipOnHide
            onOpenChange={setShowSort}
            overlayInnerStyle={{ height: '400px' }}
            content={<SortFormContent setShowSort={setShowSort} sorts={sorts} />}
          >
            <Button
              className={`view-action-button ${sortCount > 0 && 'view-sort-applied'}`}
              size="small"
              icon={<Icon component={() => <CustomIcon icon="SortIcon" />} />}
            >
              {sortCount > 0 ? (
                <>
                  <span style={{ fontWeight: 500 }}>Sorted By </span>
                  {sortCount > 1 ? `${sortCount} fields` : `${sortCount} field`}
                </>
              ) : (
                'Sort'
              )}
            </Button>
          </Popover>
        </Form.Item>
      </Space>
      <br />
      <Space wrap style={{ margin: '10px 0px' }}>
        {getQuickFilters(filters)}
      </Space>

      {/* Keeping the more filters form items rendered, but hiding them visually */}
      <div style={{ display: 'none' }}>{getMoreFilters(filters, form)}</div>
    </Form>
  );
};

export default StockLedgerFilter;
