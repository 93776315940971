import { Col, Row, Tag } from '@shipmnts/pixel-hub';
import React from 'react';
import { uniq as _uniq } from 'lodash';
import { LOAD_TYPE_FCL } from 'sales_hub/utils/constants';
import { RateExplorerResourceType } from './RateExplorer.type';
import { isInquiryResource } from './helper';
interface SummaryCardProps {
  resource: RateExplorerResourceType;
}

const SummaryItem = (props: { label: string; value: string }) => {
  const { label, value } = props;
  return (
    <Col xs={24} sm={12} md={4} lg={4}>
      <Row gutter={[8, 4]}>
        <Col xs={10} sm={24} style={{ fontSize: '12px' }}>
          {label}
        </Col>
        <Col xs={14} sm={24} style={{ fontSize: '14px' }}>
          {value}
        </Col>
      </Row>
    </Col>
  );
};

export default function SummaryCard(props: SummaryCardProps) {
  const { resource } = props;
  const isFCL = resource.load_type === LOAD_TYPE_FCL;
  const isInquiry = isInquiryResource(resource);
  const SummaryItems = [
    {
      label: 'Port of Loading',
      value: resource.port_of_loading
        ? `${resource.port_of_loading.unlocode} - ${resource.port_of_loading.name}`
        : '-',
    },
    {
      label: 'Port of Discharge',
      value: resource.port_of_discharge
        ? `${resource.port_of_discharge.unlocode} - ${resource.port_of_discharge.name}`
        : '-',
    },
    {
      label: 'Commodity',
      value: _uniq(resource.cargos?.map((e) => e.commodity?.name)).join(', ') || '-',
    },
    {
      label: 'Load Type',
      value: resource.load_type?.toUpperCase() || '-',
    },
  ];
  if (isFCL && isInquiry) {
    SummaryItems.push({
      label: 'Containers',
      value: resource?.containerSummary || '-',
    });
  } else {
    SummaryItems.push({
      label: 'Chargeable Weight',
      value: '-',
    });
  }
  return (
    <>
      <Row justify="start" gutter={8}>
        {isInquiry && resource.business_vertical?.name && (
          <Col>
            <Tag color="default">{resource.business_vertical?.name}</Tag>
          </Col>
        )}
      </Row>
      <Row gutter={[8, 16]} justify="space-between" style={{ width: '100%' }}>
        {SummaryItems.map((e) => {
          return <SummaryItem key={e.label} label={e.label} value={e.value} />;
        })}
      </Row>
    </>
  );
}
