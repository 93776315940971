import React from 'react';
import { CustomIcon, StatusRenderer, useTrackerContext, Row } from '@shipmnts/pixel-hub';

const InquiryOptionStatusRenderer = (props: any) => {
  const id = props.data.id;
  const { setReferenceId } = useTrackerContext();
  const onClick = () => {
    setReferenceId(id);
  };

  if (props.node.rowPinned) return <span></span>;
  if (!props.node.data) return <></>;
  return (
    <Row style={{ fontSize: '14px', overflowWrap: 'break-word', position: 'relative' }}>
      <StatusRenderer {...props} />
      <CustomIcon
        icon="TrackerIcon"
        style={{ fontSize: '16px', cursor: 'pointer' }}
        onClick={onClick}
      />
    </Row>
  );
};

export default InquiryOptionStatusRenderer;
