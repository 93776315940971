import React, { useCallback, useEffect, useRef, useState } from 'react';
import Inquiry, { InquiryValue } from 'sales_hub/models/inquiry';
import { useMutation } from '@apollo/client';
import { ExclamationCircleFilled, Modal } from '@shipmnts/pixel-hub';
import { FETCH_EVENT_AND_MILESTONES } from 'common/components/Tracking/graphql/queries';
import { CREATE_COPY_ACTION } from 'sales_hub/utils/constants';
import { useQuery } from '@apollo/client';
import { Button, Drawer, Form, message, Checkbox } from '@shipmnts/pixel-hub';
import { useInquiryDetail } from '../InquiryDetail/InquiryDetailLayout';
import { UPDATE_TRACKING_EVENT } from 'common/components/Tracking/graphql/queries';
import { convertDateToUnix } from '../InquiryLostLayout';
import { get } from 'lodash';
import { UPSERT_INQUIRY_OPTION } from 'sales_hub/graphql/inquiryOption';
import { pick } from 'lodash';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';
import { DRAFT_STATUS, QuotationWithInquiryValue } from 'sales_hub/models/quotation';
import {
  getUpsertInquiryOptionsPayload,
  getInquiryOptionPayload as _getInquiryOptionPayload,
} from 'sales_hub/components/InquiryOption/InquiryOptionDrawer';
import { FormInstance } from '@shipmnts/pixel-hub';
import { getChargeableWeight, getNewEstimates } from 'sales_hub/utils/utils';
import InquiryOptionForm from 'sales_hub/components/InquiryOption/InquiryOptionForm';
import { RateExplorerData } from './RateExplorer.type';
import RateExplorerLayout from './RateExplorerLayout';

interface RateExplorerProp {
  inquiry: InquiryValue;
  onClose?: () => void;
  onSuccess?: () => void;
}

type StepsType = {
  id?: number;
  resource_number?: string;
  inquiryOption: any;
};

const QUOTE_FIELDS = [
  'port_of_loading',
  'port_of_discharge',
  'carrier',
  'booking_vendor',
  'vendor_rate_agreement_number',
  'quote_currency',
  'exchange_rate',
  'terms_and_cond',
  'terms_and_cond_desc',
  'carrier_product',
];

const getInquiryOptionPayload = (
  rateExploreData: RateExplorerData,
  inquiry: InquiryValue,
  filterForm?: FormInstance
) => {
  const PCR = filterForm?.getFieldValue('place_of_carrier_receipt');
  const PCD = filterForm?.getFieldValue('place_of_carrier_delivery');
  const obj = {
    inquiry,
    ...pick(rateExploreData, QUOTE_FIELDS),
    place_of_carrier_receipt: PCR ? rateExploreData?.place_of_carrier_receipt : undefined,
    place_of_carrier_delivery: PCD ? rateExploreData?.place_of_carrier_delivery : undefined,
    id: undefined,
    quotation_number: undefined,
    status: DRAFT_STATUS,
    chargeable_weight: getChargeableWeight(inquiry),
    shipment_estimates: getNewEstimates(rateExploreData?.shipment_estimates),
  };
  return obj;
};

export default function RateExplorer(props: RateExplorerProp) {
  const { inquiry, onClose, onSuccess } = props;

  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [upsertInquiryOption, { data: upsertData, error: upsertError, loading: upsertLoading }] =
    useMutation(UPSERT_INQUIRY_OPTION);

  const [open, setOpen] = useState(false);
  const [activeAction, setActiveAction] = useState<string>();
  const { inquiry: newInquiry, inquiry_global_id } = useInquiryDetail();
  const [isChecked, setIsChecked] = useState(
    newInquiry?.last_action_status === 'Pricing shared with Sales'
  );
  const [updateEvent] = useMutation(UPDATE_TRACKING_EVENT);
  const [steps, setSteps] = useState<StepsType[]>([]);
  const [current, setCurrent] = useState(0);
  const [createdInquiryOption, setCreatedInquiryOption] = useState<
    Record<string, QuotationWithInquiryValue>
  >({});

  const next = () => {
    setCurrent(current + 1);
  };

  const updateSteps = useCallback(
    (selectedItems: RateExplorerData[]) => {
      const newSteps = selectedItems.map((item) => ({
        id: item?.id,
        resource_number: item?.resource_number,
        inquiryOption: getInquiryOptionPayload(item, inquiry, filterForm),
      }));
      setSteps(newSteps);
    },
    [filterForm, inquiry]
  );

  useEffect(() => {
    if (upsertData && !upsertError) {
      const newInquiryOption = _getInquiryOptionPayload(
        upsertData?.upsert_inquiry_option
      ) as QuotationWithInquiryValue;

      setCreatedInquiryOption((prev) => {
        prev[current] = newInquiryOption;
        return prev;
      });
      message.success('Quotation  Created Sucessfully');

      if (activeAction === CREATE_COPY_ACTION) {
        if (onClose) onClose();
        if (onSuccess) onSuccess();
        setOpen(false);
      } else {
        next();
      }
      setActiveAction(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertData, upsertError]);

  const drawerButtons = [];

  if (current === steps.length - 1)
    drawerButtons.push(
      <Button
        type="primary"
        loading={upsertLoading}
        onClick={() => {
          setActiveAction(CREATE_COPY_ACTION);
          form.submit();
        }}
      >
        Finish
      </Button>
    );

  const inquiryOption: any = createdInquiryOption?.[current] || steps[current]?.inquiryOption;
  // const today = dayjs.unix(Date.now() / 1000);

  const estimatesRef = useRef<{
    getValue: () => ShipmentEstimateValue[];
    clearEstimates: () => void;
    upsertEstiamtes: (estimates: ShipmentEstimateValue[]) => void;
  }>();

  useEffect(() => {
    if (!activeAction) {
      if (inquiryOption) {
        const values = _getInquiryOptionPayload(inquiryOption);
        form.setFieldsValue({
          ...values,
          shipment_estimates: values?.shipment_estimates?.map((estimate: any) => {
            return { ...estimate, supplier_company_id: null, supplier_company: null };
          }),
        });
        if (!inquiryOption.terms_and_cond) form.resetFields(['terms_and_cond']); // resting for autofill default
      }
      if (inquiryOption?.shipment_estimates && estimatesRef?.current) {
        estimatesRef.current?.upsertEstiamtes(
          inquiryOption?.shipment_estimates?.map((estimate: any) => {
            return { ...estimate, supplier_company_id: null, supplier_company: null };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiryOption]);

  if (current < steps.length - 1)
    drawerButtons.push(
      <Button
        type="primary"
        loading={upsertLoading}
        onClick={() => {
          form.submit();
        }}
      >
        {`${!!inquiryOption.id ? 'Edit' : 'Create'} & Next(${current + 1}/${steps.length})`}
      </Button>
    );

  if (current > 0)
    drawerButtons.push(
      <Button
        style={{ marginLeft: '10px' }}
        type="primary"
        disabled={upsertLoading}
        onClick={() => {
          setCurrent(current - 1);
        }}
      >
        Previous
      </Button>
    );

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: 'Do you Want to Close?',
      okText: 'Yes',
      icon: (
        <ExclamationCircleFilled
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      onOk() {
        setOpen(false);
      },
    });
  };

  const { data: mileStoneData } = useQuery(FETCH_EVENT_AND_MILESTONES, {
    variables: {
      ids: Array.isArray(inquiry_global_id)
        ? inquiry_global_id?.map((i) => parseInt(i))
        : [parseInt(inquiry_global_id || '')],
      reference_type: 'SalesHub::InquiryGlobal',
      workflow_type: 'main',
    },
  });
  if (current === steps.length - 1)
    drawerButtons.push(
      <Checkbox
        style={{ marginLeft: '10px' }}
        checked={isChecked}
        disabled={newInquiry?.last_action_status !== 'Pricing Pending'}
        onChange={(e) => setIsChecked(e.target.checked)}
      >
        Mark as Pricing shared with sales
      </Checkbox>
    );
  return (
    <Modal
      centered
      destroyOnClose
      width={'90vw'}
      maskClosable={false}
      open={true}
      title={`Rate Explorer ${inquiry.inquiry_number}`}
      footer={[
        <div key="actions" style={{ textAlign: 'right' }}>
          <Button
            style={{ marginLeft: '3px' }}
            type="primary"
            key="copy_quote"
            disabled={steps.length === 0}
            onClick={() => {
              setOpen(true);
            }}
          >
            Create Quote(s)
          </Button>
        </div>,
      ]}
      onCancel={() => {
        if (onClose) onClose();
      }}
    >
      <RateExplorerLayout resource={inquiry} onSelection={updateSteps} />
      <Drawer
        width={'80%'}
        title={
          inquiryOption?.id
            ? `Edit ${inquiryOption?.quotation_number}`
            : `Copying from ${steps[current]?.resource_number}`
        }
        placement="right"
        onClose={() => {
          setCurrent(0);
          showConfirm();
        }}
        open={open}
        footer={drawerButtons}
      >
        <>
          {inquiryOption && (
            <InquiryOptionForm
              inquiry={Inquiry.create(inquiryOption.inquiry)}
              inquiryOption={_getInquiryOptionPayload({
                ...inquiryOption,
                shipment_estimates: inquiryOption?.shipment_estimates?.map((estimate: any) => {
                  return { ...estimate, supplier_company_id: null, supplier_company: null };
                }),
              })}
              form={form}
              estimatesRef={estimatesRef}
              onFinish={(formValues: any) => {
                const upsertPayload = getUpsertInquiryOptionsPayload(
                  formValues,
                  estimatesRef,
                  inquiryOption,
                  undefined
                );
                const events = get(mileStoneData, 'fetch_tracking_events', []);
                const eventsToBeEdited = events.filter(
                  (o: { name: string }) => o.name === 'Pricing shared with Sales'
                );
                if (isChecked && newInquiry?.last_action_status === 'Pricing Pending') {
                  updateEvent({
                    variables: {
                      ids: [parseInt(eventsToBeEdited[0]?.id)],
                      input: {
                        actual_date: convertDateToUnix(new Date().toISOString()),
                        event_data: {},
                      },
                    },
                  });
                }
                upsertInquiryOption({
                  variables: {
                    inquiry_id: inquiryOption?.inquiry?.id,
                    inquiry_option: upsertPayload,
                  },
                });
              }}
            />
          )}
        </>
      </Drawer>
    </Modal>
  );
}
