import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Drawer,
  Modal,
  CreditLimitCheckWrapper,
  CreditLimitCheckContext,
} from '@shipmnts/pixel-hub';

import { MessageValue, AttachmentValue } from 'operations/models/Message';
import CreateShipmentFromMessage from 'operations/modules/message/components/CreateShipmentFromMessage';
import { CreateShipmentFromDataValue } from 'operations/modules/message/helpers/messageHelper';
import {
  ResourceDataValue,
  CompanyMappingMessageValue,
  ChargeMappingMessageValue,
} from 'operations/modules/message/messageResourceDataModel';
import DiscardMessageForm from 'operations/modules/message/components/DiscardMessageForm';
import AssignUser from 'operations/modules/message/components/AssignUser';
import { SHIPMENT_TYPE_CONSOL, SHIPMENT_TYPE_HOUSE } from 'operations/modules/shipment/constants';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { CompanyValue } from 'operations/models/Company';
import { useSession } from 'common';
import { ApolloError } from '@apollo/client';
export const CREATE_SHIPMENT_ACTION = 'create_shipment';
export const DISCARD_MESSAGE_ACTION = 'discard_message';
export const ASSIGN_TO_ACTION = 'assign_to';
export type actionTypeForInboxMessage =
  | typeof CREATE_SHIPMENT_ACTION
  | typeof DISCARD_MESSAGE_ACTION
  | typeof ASSIGN_TO_ACTION
  | undefined;

const InboxMessageActionDrawer = observer(function InboxMessageActionDrawer(props: {
  message: MessageValue;
  activeAction: actionTypeForInboxMessage | null;
  shipment?: ResourceDataValue;
  activeShipmentIndex: number;
  lastShipmentIndex: number;
  setActiveAction: (value: actionTypeForInboxMessage) => void;
  onClose: () => void;
  onSuccess?: () => void;
  onBack?: () => void;
  onNext?: (values: CreateShipmentFromDataValue) => void;
  onCreateShipmentFormSubmit: (
    values: CreateShipmentFromDataValue,
    throw_error_on_credit_fail?: boolean
  ) => void;
  shipmentInitialValue?: CreateShipmentFromDataValue;
  masterShipmentInitialValue?: CreateShipmentFromDataValue;
  senderCompany?: CompanyValue | null;
  companyMappingFormValues?: { [key: string]: ShipmentPartyValue };
  loading?: boolean;
  companyMapping?: CompanyMappingMessageValue[];
  tenantMapping?: ChargeMappingMessageValue[];
  attachments?: AttachmentValue[];
  onModalSuccess?: () => void;
  error?: ApolloError | undefined;
}): JSX.Element {
  const {
    activeAction,
    shipment,
    setActiveAction,
    onClose,
    onCreateShipmentFormSubmit,
    loading,
    activeShipmentIndex,
    lastShipmentIndex,
    shipmentInitialValue,
    masterShipmentInitialValue,
    companyMappingFormValues,
    senderCompany,
    onBack,
    onSuccess,
    message,
    companyMapping,
    tenantMapping,
    attachments,
    error,
  } = props;
  const onDiscard = useCallback(() => {
    setActiveAction(DISCARD_MESSAGE_ACTION);
  }, [setActiveAction]);
  const sessionData = useSession();
  const renderActionForm = useMemo(() => {
    switch (activeAction) {
      case CREATE_SHIPMENT_ACTION:
        if (!shipment) return null;
        return (
          <CreditLimitCheckWrapper sessionData={sessionData}>
            <CreditLimitCheckContext.Consumer>
              {(contextProps: any) => {
                return (
                  <CreateShipmentFromMessage
                    key={shipment.id.value}
                    shipment={shipment}
                    shipmentInitialValue={shipmentInitialValue}
                    masterShipmentInitialValue={masterShipmentInitialValue}
                    companyMappingFormValues={companyMappingFormValues}
                    senderCompany={senderCompany}
                    onFormSubmit={onCreateShipmentFormSubmit}
                    onClose={onClose}
                    onBack={onBack}
                    onDiscard={onDiscard}
                    loading={loading}
                    companyMapping={companyMapping}
                    tenantMapping={tenantMapping}
                    attachments={attachments}
                    activeShipmentIndex={activeShipmentIndex}
                    lastShipmentIndex={lastShipmentIndex}
                    message={message}
                    showCreditPopupWithAction={contextProps?.showCreditPopupWithAction}
                    error={error}
                  />
                );
              }}
            </CreditLimitCheckContext.Consumer>
          </CreditLimitCheckWrapper>
        );
      case DISCARD_MESSAGE_ACTION:
        return (
          <DiscardMessageForm messageId={message.id} onClose={onClose} onSuccess={onSuccess} />
        );
      case ASSIGN_TO_ACTION:
        if (!onSuccess) return <></>;
        return <AssignUser message={message} onSuccess={onSuccess} onClose={onClose} />;
      default:
        return <span />;
    }
  }, [
    activeAction,
    shipment,
    sessionData,
    message,
    onClose,
    onSuccess,
    shipmentInitialValue,
    masterShipmentInitialValue,
    companyMappingFormValues,
    senderCompany,
    onCreateShipmentFormSubmit,
    onBack,
    onDiscard,
    loading,
    companyMapping,
    tenantMapping,
    attachments,
    activeShipmentIndex,
    lastShipmentIndex,
    error,
  ]);

  const getCreateShipmentTitle = useCallback(() => {
    if (SHIPMENT_TYPE_CONSOL === shipment?.shipment_type?.value) {
      return `Create Consol Shipment - ${message.id}`;
    } else if (SHIPMENT_TYPE_HOUSE === shipment?.shipment_type?.value) {
      return `Create House Shipment - ${message.id}`;
    }
    return `Create Shipment - ${message.id}`;
  }, [shipment, message]);

  const titleMapping: { [key: string]: string } = {
    [CREATE_SHIPMENT_ACTION]: getCreateShipmentTitle(),
    [DISCARD_MESSAGE_ACTION]: `Discard Message - ${message.id}`,
    [ASSIGN_TO_ACTION]: `Assign To`,
  };

  if (!activeAction) return <span />;

  return (
    <>
      <Modal
        title={titleMapping[activeAction]}
        open={activeAction === ASSIGN_TO_ACTION}
        onCancel={onClose}
        footer={null}
        width="450px"
      >
        {renderActionForm}
      </Modal>
      <Drawer
        title={titleMapping[activeAction]}
        width={[CREATE_SHIPMENT_ACTION].includes(activeAction) ? '85%' : '50%'}
        open={activeAction === CREATE_SHIPMENT_ACTION || activeAction === DISCARD_MESSAGE_ACTION}
        onClose={onClose}
      >
        {renderActionForm}
      </Drawer>
    </>
  );
});

export default InboxMessageActionDrawer;
