import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Drawer, Typography } from '@shipmnts/pixel-hub';
import Spinner from 'src/components/Spinner';
import { FETCH_WMS_TRANSACTION } from '../graphql/warehouseTransaction';
import ChangeEventsTimeline from 'operations/modules/shipment/components/DetailLayout/AttachedHouse/ChangeEventTimeline';

interface WarehouseTransactionTimelineProp {
  transaction_id: string;
  onClose: () => void;
}

const WarehouseTransactionTimeline = React.memo(function WarehouseTransactionTimeline(
  props: WarehouseTransactionTimelineProp
) {
  const { Text } = Typography;

  const { transaction_id, onClose } = props;

  const { error, data, loading, refetch } = useQuery(FETCH_WMS_TRANSACTION, {
    variables: { id: transaction_id },
  });
  const [events, setEvents] = useState<any>([]);

  useEffect(() => {
    if (data?.get_wms_transaction) {
      setEvents(data?.get_wms_transaction?.warehouse_transaction_events);
    }
  }, [data, error, loading]);
  if (!data && error) {
    return (
      <div className="align-center">
        Unable to fetch timeline. &nbsp;
        <Button type="primary" onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    );
  }

  if (loading)
    return (
      <Drawer open={true}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="small" tip="Fetching Timeline" />;
        </div>
      </Drawer>
    );

  if (events && events.length > 0)
    return (
      <Drawer
        title={'Warehouse Transaction Timeline'}
        className="bl-status-drawer"
        open={true}
        onClose={onClose}
      >
        <ChangeEventsTimeline change_events={events} />
      </Drawer>
    );
  return (
    <Drawer open={true} onClose={onClose}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Text style={{ textAlign: 'center' }} type="danger">
          No Events to Display
        </Text>{' '}
      </div>
    </Drawer>
  );
});

export default WarehouseTransactionTimeline;
