/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import CompanyAccount from './CompanyAccount';
import UserAccount from './UserAccount';

const ConnectionRequest = types.model({
  id: types.identifier,
  from_company_account: types.maybe(types.late((): IAnyModelType => CompanyAccount)),
  to_company_account: types.maybe(types.late((): IAnyModelType => CompanyAccount)),
  requested_by_user: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
  status: types.maybe(types.string),
});

export interface ConnectionRequestValue extends Instance<typeof ConnectionRequest> {}
export interface ConnectionRequestIn extends SnapshotIn<typeof ConnectionRequest> {}
export interface ConnectionRequestOut extends SnapshotOut<typeof ConnectionRequest> {}

export default ConnectionRequest;
