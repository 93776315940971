import React, { useEffect } from 'react';
import { Card, Row, Col, Input, Form } from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import ShipmentBasicDetails from 'operations/modules/shipment/components/ShipmentBasicDetails';
import ShipmentDocumentDetails from 'operations/modules/shipment/components/ShipmentDocumentDetails';
import ExportClearanceDetails from 'operations/modules/shipment/components/ExportClearanceDetails';
import { DrawerFooter, dayjsGenerateConfig } from '@shipmnts/pixel-hub';
import {
  BOOKING_THROUGH_TYPES,
  BUSINESS_TYPE_DIRECT,
  BOOKING_THROUGH_OVERSEAS_AGENT,
  ROW_GUTTER,
  SHIPMENT_TYPE_DIRECT,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_HOUSE,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_EXPORT,
  DOCUMENT_TYPE_MASTER,
  DOCUMENT_TYPE_HOUSE,
  BL_TYPE_ARRAY,
  RELEASE_TYPE_ARRAY,
  RELEASE_TYPE_RFS,
  CHARGE_TERMS,
  CONSIGNEE_TYPE_ACTUAL_CONSIGNEE,
  CONSIGNEE_TYPE_TO_ORDER,
  CONSIGNEE_TYPE_TO_ORDER_BANK,
  BUSINESS_TYPE_SUBAGENT,
  TradeType,
} from 'operations/modules/shipment/constants';
import {
  CLEARANCE_SERVICE,
  CARGO_INSURANCE_SERVICE,
  EMPTY_CONTAINER_INSURANCE_SERVICE,
} from 'operations/modules/booking/constants';
import { CreateShipmentType } from 'operations/modules/reports/components/ContainerReports/ContainerActions/ContainerActionDrawer';
import { Dayjs } from '@shipmnts/pixel-hub';
import { LocationValue } from 'operations/models/Location';
import { ExportClearanceTableValue } from 'operations/modules/shipment/components/ExportClearanceTable';
import DestinationTransportDetail from 'operations/modules/shipment/components/DestinationTransportDetail';
import { errorMessageHandlerGraphQL } from 'common';

import { CompanyValue } from 'operations/models/Company';
import { CarrierValue } from 'operations/models/Carrier';
import { AddressValue } from 'operations/models/Address';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import MiscellaneousServiceOrder, {
  MiscellaneousServiceOrderValue,
} from 'operations/models/MiscellaneousServiceOrder';
import { SessionDataValue } from 'operations/models/SessionData';
import { useSession } from 'common';
import { ApolloError } from '@apollo/client';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';
import { isStateTreeNode } from 'mobx-state-tree';
import { constants as networkConstants } from 'network';
import { getJsonFromError } from '../../../helpers/ruleEngineHelper';
import ShipmentServiceDetails, {
  ShipmentServiceRenderType,
} from 'operations/modules/shipment/components/ShipmentServiceDetails';
import { InquiryOptionValue } from 'operations/models/InquiryOption';

export interface PartyValue {
  id?: string;
  name?: string;
  party_company?: CompanyValue;
  party_address?: AddressValue;
  print_address?: string | null;
}
export interface CreateShipmentFormBRValue {
  id: string;
  consignee_company?: CompanyValue | null;
  consignee_address?: AddressValue | null;
  quotation_number?: string | null;
  inquiry_option?: InquiryOptionValue | null;
  is_external_quotation_number?: boolean | null;
  incoterm?: string | null;
  remarks?: string | null;
  load_type?: string | null;
  miscellaneous_service_orders: MiscellaneousServiceOrderValue[];
  port_of_loading: LocationValue;
  port_of_discharge: LocationValue;
  place_of_carrier_receipt: LocationValue;
  place_of_carrier_delivery: LocationValue;
  trade_type: TradeType;
}

export interface CreateShipmentFormCommonProps {
  shipmentType: CreateShipmentType;
  customer: PartyValue;
  shipper?: PartyValue | null;
  branch_account_id?: string;
  carrier?: CarrierValue;
  onClose: () => void;
  bookingRequests?: CreateShipmentFormBRValue[];
  tradeType?: TradeType;
}

interface CreateShipmentFormContentProps extends CreateShipmentFormCommonProps {
  onFormSubmit: (values: CreateShipmentFormValue, throw_error_on_credit_fail?: boolean) => void;
  error?: ApolloError;
  loading?: boolean;
  showCreditPopupWithAction?: any;
}

export interface CreateShipmentFormValue {
  shipment_type?: CreateShipmentType;
  job_date?: Dayjs;
  business_type?: string;
  shipping_line_service_type?: string;
  involved_branch_id?: string;
  customer?: PartyValue;
  billing_party?: PartyValue;
  party?: { [key: string]: ShipmentPartyValue };
  incoterms?: string;
  master?: DocumentDetailFormValue;
  house?: DocumentDetailFormValue;
  destination_clearance?: boolean;
  shipping_bill_details?: ExportClearanceTableValue[];
  shipment_invoices?: ExportClearanceTableValue[];
  final_place_of_delivery?: LocationValue;
  final_place_of_delivery_print?: string;
  remarks?: string;
  import_custom_details?: ExportClearanceTableValue[];
}

interface ShipmentInitialValue {
  bookingRequests?: CreateShipmentFormBRValue[];
  sessionData: SessionDataValue;
  shipmentType: CreateShipmentType;
  customer: PartyValue;
  shipper?: PartyValue | null;
  branch_account_id?: string;
}

export interface DocumentDetailFormValue {
  shipment_document?: {
    bl_type?: string;
    release_type?: string;
    charge_terms?: {
      freight_terms: 'prepaid' | 'collect' | undefined;
      other_charges_terms: 'prepaid' | 'collect' | undefined;
    };
    origin_agent_same_as_shipper?: boolean;
    destination_agent_same_as_consignee?: boolean;
    shipment_number?: string;
    document_status?: string;
    shipment_date?: Dayjs;
    document_status_event_date?: Dayjs;
  };
  consignee_party_name?: string;
  party?: {
    [key: string]: ShipmentPartyValue | undefined;
  };
}

const BUSINESS_TYPE_LABEL_MAPPING: { [key: string]: string } = {
  [BUSINESS_TYPE_DIRECT]: 'Customer ( same as Billing Party )',
  [BUSINESS_TYPE_SUBAGENT]: 'Customer ( same as Subagent )',
  [BOOKING_THROUGH_OVERSEAS_AGENT]: 'Customer ( same as Overseas Agent )',
};

export const shipment_document_initial_value = {
  shipment_document: {
    bl_type: BL_TYPE_ARRAY[0].key,
    release_type: RELEASE_TYPE_ARRAY[0].key,
    charge_terms: {
      freight_terms: CHARGE_TERMS[0].key,
      other_charges_terms: CHARGE_TERMS[0].key,
    },
  },
  consignee_party_name: CONSIGNEE_TYPE_ACTUAL_CONSIGNEE.key,
};

const services: ShipmentServiceRenderType[] = [
  {
    name: ['freight_forwarding'],
    render: false,
  },
  {
    name: ['origin', 'clearance'],
  },
  {
    name: ['destination', 'clearance'],
  },
  {
    name: ['destination', 'transport'],
    component: DestinationTransportDetail,
  },
  {
    name: ['cargo_insurance'],
  },
  {
    name: ['empty_container_insurance'],
  },
];

const shipment_initial_value = ({
  bookingRequests,
  sessionData,
  branch_account_id,
  shipmentType,
  customer,
  shipper,
}: ShipmentInitialValue) => {
  let origin_clearance_agent, cargo_insurance_agent, empty_container_insurance_agent;
  if (bookingRequests && bookingRequests.length > 0) {
    const default_company_id = sessionData?.company_account?.default_company?.id || '';
    const misc_services = bookingRequests[0].miscellaneous_service_orders.map((service) =>
      isStateTreeNode(service) ? service : MiscellaneousServiceOrder.create(service)
    );

    origin_clearance_agent = misc_services
      .find((service) => service.name === CLEARANCE_SERVICE)
      ?.getVendorCompany(default_company_id);
    cargo_insurance_agent = misc_services
      .find((service) => service.name === CARGO_INSURANCE_SERVICE)
      ?.getVendorCompany(default_company_id);
    empty_container_insurance_agent = misc_services
      .find((service) => service.name === EMPTY_CONTAINER_INSURANCE_SERVICE)
      ?.getVendorCompany(default_company_id);
  }

  const getDefaultCustomLocation = (port1?: any, port2?: any) => {
    if (port1 && port1.is_customs_location) return port1;
    else if (port2 && port2.is_customs_location) return port2;
    return undefined;
  };
  const locationDefaultValue = getDefaultCustomLocation(
    bookingRequests?.[0]?.place_of_carrier_receipt,
    bookingRequests?.[0]?.port_of_loading
  );

  const selectedCountry =
    bookingRequests?.[0]?.place_of_carrier_receipt?.country_code ||
    bookingRequests?.[0]?.port_of_loading?.country_code;

  return {
    shipment_type: shipmentType,
    customer,
    billing_party: { ...customer },
    job_date: dayjsGenerateConfig.getNow(),
    business_type: BOOKING_THROUGH_TYPES[0].key,
    shipping_bill_details: [
      {
        _id: 'cr_0',
        document_number: undefined,
        document_date: undefined,
        country: selectedCountry,
        custom_clearance_location: locationDefaultValue,
      },
    ],
    ...(shipmentType !== SHIPMENT_TYPE_HOUSE
      ? {
          master: {
            ...shipment_document_initial_value,
            party: {
              shipper,
              consignee: {
                party_company:
                  bookingRequests && bookingRequests.length > 0
                    ? bookingRequests[0].consignee_company
                    : null,
                party_address:
                  bookingRequests && bookingRequests.length > 0
                    ? bookingRequests[0].consignee_address
                    : null,
              },
            },
          },
        }
      : {}),
    house: {
      ...shipment_document_initial_value,
      ...(shipmentType === SHIPMENT_TYPE_HOUSE &&
      bookingRequests &&
      bookingRequests.length > 0 &&
      bookingRequests[0].load_type === LOAD_TYPE_LCL
        ? {
            shipment_document: {
              ...shipment_document_initial_value.shipment_document,
              release_type: RELEASE_TYPE_RFS,
            },
          }
        : {}),
      party: {
        shipper,
        consignee: {
          party_company:
            bookingRequests && bookingRequests.length > 0
              ? bookingRequests[0].consignee_company
              : null,
          party_address:
            bookingRequests && bookingRequests.length > 0
              ? bookingRequests[0].consignee_address
              : null,
        },
      },
    },
    party: {
      origin_clearance_agent: {
        party_company: origin_clearance_agent,
      },
      cargo_insurance_agent: {
        party_company: cargo_insurance_agent,
      },
      empty_container_insurance_agent: {
        party_company: empty_container_insurance_agent,
      },
    },
    services: {
      freight_forwarding: true,
      origin: {
        clearance: !!origin_clearance_agent,
      },
      destination: {
        clearance: false,
        transport: false,
      },
      cargo_insurance: !!cargo_insurance_agent,
      empty_container_insurance: !!empty_container_insurance_agent,
    },
    quotation_number:
      bookingRequests && bookingRequests.length > 0 ? bookingRequests[0]?.quotation_number : '',
    inquiry_option_id:
      bookingRequests && bookingRequests.length > 0 ? bookingRequests[0]?.inquiry_option?.id : null,
    is_external_quotation_number:
      bookingRequests && bookingRequests.length > 0
        ? bookingRequests[0]?.is_external_quotation_number
        : null,
    incoterms: bookingRequests && bookingRequests.length > 0 ? bookingRequests[0]?.incoterm : '',
    involved_branch_id: (sessionData?.branch_accounts || []).find(
      (br) => br?.default_address?.id === branch_account_id
    )?.id,
    remarks: (bookingRequests || [])
      .map((br: { id: string; remarks?: string | null }) => br.remarks || '')
      .filter(Boolean)
      .join(', '),
  };
};

const CreateShipmentFormContent = React.memo(function CreateShipmentFormContent(
  props: CreateShipmentFormContentProps
) {
  const {
    shipmentType,
    customer,
    shipper,
    branch_account_id,
    carrier,
    bookingRequests,
    onClose,
    onFormSubmit,
    error,
    loading,
    tradeType,
    showCreditPopupWithAction,
  } = props;
  const sessionData: SessionDataValue = useSession();
  const graphQLErrors = error ? error.graphQLErrors : error;
  const [form] = Form.useForm();
  if (
    customer?.party_company?.sales_partner?.country_of_incorporation &&
    sessionData?.company_account?.country_of_incorporation
  ) {
    if (
      customer?.party_company?.sales_partner?.country_of_incorporation ===
      sessionData.company_account.country_of_incorporation
    ) {
      form?.setFieldValue('business_type', 'subagent');
    } else {
      form?.setFieldValue('business_type', 'agent_nomination');
    }
    form?.setFieldValue('customer', {
      party_company: customer?.party_company?.sales_partner,
      party_address: null,
    });
  }
  useEffect(() => {
    const graphQLErrors = error ? error.graphQLErrors : error;

    if (
      graphQLErrors &&
      sessionData.isFeatureEnabled(networkConstants.CREDIT_CONTROL_FEATURE) &&
      showCreditPopupWithAction
    ) {
      const data = getJsonFromError(graphQLErrors);
      const values = form.getFieldsValue();
      if (data)
        showCreditPopupWithAction({
          data: data,
          onSucess: () => {
            onFormSubmit(values, false);
          },
        });
    }
  }, [error, form, onFormSubmit, sessionData, showCreditPopupWithAction]);

  return (
    <Form
      form={form}
      name="create_master_shipment"
      layout="vertical"
      initialValues={shipment_initial_value({
        bookingRequests,
        customer,
        shipper,
        branch_account_id,
        sessionData,
        shipmentType,
      })}
      onValuesChange={(changedValues, allValues) => {
        const formValues: CreateShipmentFormValue = {};
        if (
          shipmentType !== SHIPMENT_TYPE_DIRECT &&
          changedValues.hasOwnProperty('final_place_of_delivery')
        ) {
          const fpod = changedValues.final_place_of_delivery;
          const finalPlaceOfDeliveryPrintValue = fpod
            ? [fpod.city, fpod.state].filter(Boolean).join(', ')
            : undefined;
          formValues['final_place_of_delivery_print'] = finalPlaceOfDeliveryPrintValue;
        }

        if (
          changedValues?.master?.consignee_party_name ||
          changedValues?.house?.consignee_party_name
        ) {
          const document_type = changedValues.hasOwnProperty('master')
            ? DOCUMENT_TYPE_MASTER
            : DOCUMENT_TYPE_HOUSE;
          const consignee_party =
            changedValues[document_type].consignee_party_name === CONSIGNEE_TYPE_TO_ORDER.key
              ? CONSIGNEE_TYPE_TO_ORDER
              : CONSIGNEE_TYPE_TO_ORDER_BANK;
          formValues[document_type] = {
            party: {
              [consignee_party.key]: {
                party_details: consignee_party.name,
              } as ShipmentPartyValue,
            },
          };
        }
        if (changedValues?.business_type) {
          const shipper = (
            changedValues.business_type === BUSINESS_TYPE_DIRECT ? allValues.customer : undefined
          ) as ShipmentPartyValue;

          let key: 'master' | 'house' | undefined;
          if (shipmentType === SHIPMENT_TYPE_DIRECT) {
            key = 'master';
          } else if (shipmentType === SHIPMENT_TYPE_BACK_TO_BACK) {
            key = 'house';
          }
          if (key) {
            // TODO: fix issue when changing type from Direct -> Subagent -> Direct, shipper address not getting filled.
            formValues[key] = {
              ...(allValues?.[key] || {}),
              ...formValues[key],
              party: {
                ...(allValues?.[key]?.party || {}),
                ...(formValues?.[key]?.party || {}),
                shipper,
              },
            };
          }
          formValues['billing_party'] =
            changedValues.business_type === BUSINESS_TYPE_DIRECT
              ? { ...allValues.customer }
              : undefined;
        }
        if (Object.keys(formValues).length > 0) {
          form.setFieldsValue(formValues);
        }
      }}
      onFinish={(values) => onFormSubmit(values)}
    >
      {graphQLErrors &&
        !getJsonFromError(graphQLErrors) &&
        errorMessageHandlerGraphQL(graphQLErrors)}
      <Form.Item noStyle name="shipment_type">
        <span />
      </Form.Item>

      <Card title="Basic Details" className="custom-card">
        <ShipmentBasicDetails shipmentType={shipmentType} form={form} />
      </Card>

      <Card title="Party Details" className="custom-card" style={{ marginTop: '16px' }}>
        <Form.Item noStyle dependencies={['business_type']}>
          {({ getFieldValue }) => {
            const business_type = getFieldValue('business_type');
            const customer_label =
              BUSINESS_TYPE_LABEL_MAPPING[business_type || BUSINESS_TYPE_DIRECT];

            return (
              <Row gutter={ROW_GUTTER}>
                <Col span={12} className="custom-margin-form-item">
                  <Form.Item
                    name="customer"
                    required
                    rules={[{ required: true }]}
                    label={customer_label}
                  >
                    <AddressCompanySearch
                      customerSearchProps={{
                        disabled: true,
                        trigger: 'create_shipment',
                      }}
                      addressSearchProps={{
                        searchProps: { entity_type: 'billing' },
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {business_type === BUSINESS_TYPE_DIRECT ? (
                    <Form.Item
                      name="billing_party"
                      required
                      rules={[{ required: true }]}
                      label="Billing Party"
                      noStyle
                    >
                      <span />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="billing_party"
                      required
                      rules={[{ required: true }]}
                      label="Billing Party"
                    >
                      <AddressCompanySearch
                        customerSearchProps={{
                          disabled: getFieldValue('business_type') === BUSINESS_TYPE_DIRECT,
                          trigger: 'create_shipment',
                        }}
                        addressSearchProps={{
                          disabled: getFieldValue('business_type') === BUSINESS_TYPE_DIRECT,
                          searchProps: { entity_type: 'billing' },
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item noStyle name="quotation_number">
                    <span />
                  </Form.Item>
                  <Form.Item noStyle name="inquiry_option_id">
                    <span />
                  </Form.Item>
                  <Form.Item noStyle name="is_external_quotation_number">
                    <span />
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
      </Card>
      {(shipmentType === SHIPMENT_TYPE_BACK_TO_BACK || shipmentType === SHIPMENT_TYPE_HOUSE) && (
        <Card title="HBL Instructions" className="custom-card" style={{ marginTop: '16px' }}>
          <ShipmentDocumentDetails
            freightType={FREIGHT_TYPE_OCEAN}
            tradeType={tradeType ? tradeType : TRADE_TYPE_EXPORT}
            shipmentType={shipmentType}
            documentType={DOCUMENT_TYPE_HOUSE}
            form={form}
          />
        </Card>
      )}

      {shipmentType !== SHIPMENT_TYPE_HOUSE && (
        <Card title="MBL Instructions" className="custom-card" style={{ marginTop: '16px' }}>
          <ShipmentDocumentDetails
            freightType={FREIGHT_TYPE_OCEAN}
            tradeType={tradeType ? tradeType : TRADE_TYPE_EXPORT}
            shipmentType={shipmentType}
            documentType={DOCUMENT_TYPE_MASTER}
            form={form}
            carrier={carrier}
          />
        </Card>
      )}
      <ShipmentServiceDetails
        services={services}
        shipmentType={shipmentType}
        colSpan={12}
        freightType={FREIGHT_TYPE_OCEAN}
        form={form}
      />

      <Card title="Custom Clearance Details" className="custom-card" style={{ marginTop: '16px' }}>
        <ExportClearanceDetails
          bookingRequest={
            bookingRequests
              ? { ...bookingRequests[0], trade_type: tradeType || 'export' }
              : undefined
          }
          form={form}
        />
      </Card>

      <Card title="Other Details" className="custom-card" style={{ margin: '16px 0 44px' }}>
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Card>
      <div
        style={{
          position: 'absolute',
          bottom: '24px',
          width: '98%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          background: '#fff',
          padding: '7px',
          margin: '-22px',
        }}
      >
        <DrawerFooter
          saveText="Create Shipment"
          loading={loading}
          onClose={onClose}
          disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}
        />
      </div>
    </Form>
  );
});

export default CreateShipmentFormContent;
