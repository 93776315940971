import RoadRoutingDetails from '../ShipmentForm/RoadRoutingDetails/RoadRoutingDetails';
import BasicDetails from './BasicDetails';

export const getShipmentRouteComponent = (props: any) => {
  const { shipmentRoute, form, type } = props;

  return [
    {
      title: 'Basic Details',
      component: BasicDetails,
      props: {},
      isVisible: true,
    },

    {
      title: 'Routing Details',
      component: RoadRoutingDetails,
      props: { value: shipmentRoute, form, allowAddNew: type !== 'update', docType: 'route' },
      isVisible: true,
    },
  ];
};
