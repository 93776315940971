import React from 'react';

import { Form, message } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';

import InquiryOptionFormContent from 'sales_hub/components/InquiryOption/InquiryOptionFormContent';
import { InquiryValue } from 'sales_hub/models/inquiry';
import { DRAFT_STATUS, QuotationValue } from 'sales_hub/models/quotation';
import { getChargeableWeight } from 'sales_hub/utils/utils';
import { isNil } from 'lodash';
import { baseHelper, useSession } from 'common';

interface InquiryOptionFormProp {
  form: FormInstance;
  inquiryOption?: QuotationValue | Record<string, any>;
  onFinish: any;
  inquiry: InquiryValue;
  estimatesRef: any;
  disabledResource?: boolean;
}

function InquiryOptionForm(props: InquiryOptionFormProp) {
  const { form, inquiryOption, onFinish, inquiry, estimatesRef, disabledResource } = props;

  let disabled = inquiryOption ? inquiryOption?.status !== DRAFT_STATUS : false;
  const { getExchangeRate } = baseHelper;
  const session = useSession();

  if (!isNil(disabledResource)) disabled = disabledResource; // overriding disabled if set

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        // taking initial values from inquiryOption or inquiry
        port_of_loading: inquiry?.port_of_loading,
        port_of_discharge: inquiry?.port_of_discharge,
        place_of_carrier_delivery: inquiry?.place_of_carrier_delivery,
        place_of_carrier_receipt: inquiry?.place_of_carrier_receipt,
        destination_port_free_days: inquiry?.destination_port_free_days,
        origin_carrier_free_days: inquiry?.origin_carrier_free_days,
        destination_carrier_free_days: inquiry?.destination_carrier_free_days,
        chargeable_weight: getChargeableWeight(inquiry),
        exchange_rate: 1,
        validity: [inquiryOption?.valid_from, inquiryOption?.valid_to],
        ...inquiryOption,
      }}
      scrollToFirstError={true}
      disabled={disabled}
      onFinish={onFinish}
      onValuesChange={(changedValues, _allValues) => {
        // to update transit days
        if (
          ['estimated_time_of_arrival', 'estimated_time_of_departure'].some(
            (field) => field in changedValues
          )
        ) {
          const ETA = form.getFieldValue('estimated_time_of_arrival');
          const ETD = form.getFieldValue('estimated_time_of_departure');
          if (ETA && ETD) form.setFieldValue('transit_days', `${ETA?.diff(ETD, 'days')} Days`);
        }
        if ('terms_and_cond' in changedValues) {
          form.setFieldsValue({
            terms_and_cond_desc: changedValues.terms_and_cond?.content,
          });
        }
        if ('quote_currency' in changedValues) {
          getExchangeRate(changedValues?.quote_currency, session?.company_account?.default_currency)
            .then((r) => {
              if (r !== undefined)
                form.setFieldsValue({
                  exchange_rate: r,
                });
            })
            .catch((err) => {
              message.error(err?.message);
            });
        }
      }}
    >
      <InquiryOptionFormContent
        disabled={disabled}
        inquiry={inquiry}
        estimatesRef={estimatesRef}
        form={form}
        id={inquiryOption?.id}
      />
    </Form>
  );
}

export default InquiryOptionForm;
