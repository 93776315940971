import React from 'react';

import { Button } from '@shipmnts/pixel-hub';
import { ContactsOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { DetailsCard } from 'common';
import { useCompanyView } from '../CompanyView';
import { WithPermission, ConditionalPermissions, LinkRender } from '@shipmnts/pixel-hub';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { ContactForm } from 'network';

interface AddContactProps {
  refetch?: () => void;
}

const AddContact: React.FC<AddContactProps> = ({ refetch }) => {
  const [visible, setVisible] = React.useState(false);
  const { company } = useCompanyView();
  return (
    <>
      <Button
        size="small"
        icon={<PlusOutlined />}
        disabled={company?.is_disabled}
        onClick={() => {
          setVisible(true);
        }}
      >
        {'Add Contact'}
      </Button>
      <ContactForm
        setVisible={setVisible}
        visible={visible}
        company={company}
        onSuccess={() => {
          refetch && refetch();
        }}
      />
    </>
  );
};

export const ContactsCard = () => {
  const { company, loading, is_customer, is_vendor, refetch } = useCompanyView();
  const data = company?.contacts;
  const [visible, setVisible] = React.useState(false);
  const [editContact, setEditContact] = React.useState<any>();

  let permission: ConditionalPermissions | undefined = undefined;
  if (is_customer || is_vendor)
    permission = {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    };

  const headingStyle = {
    display: 'block',
    opacity: 0.6,
    fontSize: '12px',
    fontWeight: '600',
    color: '#000000D9',
  };

  return (
    <>
      <DetailsCard
        style={{ width: '100%', marginInline: 'auto' }}
        loading={loading}
        header={{ title: 'Contacts', icon: <ContactsOutlined /> }}
        Action={
          <WithPermission permission={permission}>
            <AddContact refetch={refetch} />
          </WithPermission>
        }
      >
        {data && data.length > 0 ? (
          <>
            {data?.map((contact, idx) => {
              return (
                <div
                  key={contact?.id}
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    alignItems: 'start',
                    gridTemplateColumns: '20% 20% 20% 20%',
                    borderBottom: idx !== data.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                    padding: '1em',
                    gap: '1em',
                  }}
                >
                  <span style={{ color: '#0A4FCA', fontWeight: '500', wordWrap: 'break-word' }}>
                    {/* Just to make fullname pretty */}
                    <span style={{ ...headingStyle }}>Name</span>
                    <div className="contact-link-name" style={{ cursor: 'pointer' }}>
                      {
                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        <LinkRender
                          value={contact}
                          data={contact}
                          value_field={'name'}
                          id_field={'id'}
                          doc_type_id={'Network::UserContact'}
                        />
                      }
                    </div>
                  </span>
                  <section style={{ wordWrap: 'break-word' }}>
                    <span style={headingStyle}>Business Email</span>
                    <span>{contact?.email || '-'}</span>
                  </section>
                  <section style={{ wordWrap: 'break-word' }}>
                    <span style={headingStyle}>Mobile #</span>
                    <span>{contact?.mobile_number || '-'}</span>
                  </section>
                  <section style={{ wordWrap: 'break-word' }}>
                    <span style={headingStyle}>Departments</span>
                    <span>
                      {(contact?.department || []).length > 0
                        ? contact?.department?.join(', ')
                        : '-'}
                    </span>
                  </section>
                  <WithPermission permission={permission}>
                    <EditOutlined
                      style={{
                        color: '#0A4FCA',
                        fontWeight: '500',
                        fontSize: '18px',
                        textAlign: 'right',
                        alignSelf: 'center',
                        justifySelf: 'end',
                      }}
                      onClick={() => {
                        setEditContact(contact);
                        setVisible(true);
                      }}
                    />
                  </WithPermission>
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
          >{`No Contacts Created Yet.`}</div>
        )}
      </DetailsCard>
      <ContactForm
        key={editContact?.id}
        setVisible={setVisible}
        visible={visible}
        contact={editContact}
        company={company}
        onSuccess={() => {
          refetch && refetch();
        }}
      />
    </>
  );
};
