import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { get as _get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DetailsCard } from 'common';
import { erpNextAxiosCall } from 'network/utils/erpnextApiHelper';
import { Button, Drawer, Modal, message } from '@shipmnts/pixel-hub';
import { SupplierBankAccountsData } from 'network/models/Company';
import SupplierBankAccountForm from 'network/components/SupplierBankAccountForm';
import { useSession } from 'common';
import { useCompanyView } from '../CompanyView';
import { PERMISSION_SUPPLIER_CREATE_EDIT, PERMISSION_SUPPLIER_VIEW } from 'network/permissions';
import { ConditionalPermissions, hasPermission, WithPermission } from '@shipmnts/pixel-hub';

const AddBankAccount = ({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button disabled={disabled} onClick={onClick} icon={<PlusOutlined />} size="small">
      {' '}
      Add Bank Account{' '}
    </Button>
  );
};

export const BankAccountsCard = ({ loading }: { loading?: boolean; erpnextConfigData?: any }) => {
  const permission: ConditionalPermissions = {
    name: PERMISSION_SUPPLIER_CREATE_EDIT,
    docType: 'Network::Company',
  };

  const session = useSession();

  const { company, accounts_data, is_vendor } = useCompanyView();
  // states
  const [accounts, setAccounts] = useState<SupplierBankAccountsData[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerAccountData, setDrawerAccountData] = useState<SupplierBankAccountsData>({
    bank: '',
    account_name: '',
    bank_account_no: '',
    address_name: '',
    swift_number: '',
    iban: '',
    branch_code: '',
    beneficiary_code: '',
  });

  const get_bank_accounts = async () => {
    try {
      const url = `/api/method/shipmnts.controllers.network_company.get_bank_accounts`;
      const fields = [
        'name',
        'account_name',
        'bank_account_no',
        'bank',
        'beneficiary_code',
        'branch_code',
        'iban',
        'swift_number',
      ];
      const response: any = await erpNextAxiosCall({
        action: 'post',
        url,
        payload: {
          supplier_name: _get(company, 'registered_name'),
          fields: fields,
        },
      });
      const response_data = response?.response?.data?.message;
      if (response_data?.errorMsg) message.error(response_data.errorMsg);
      setAccounts(
        (response_data?.supplier_accounts || []).map(
          (acc: SupplierBankAccountsData, idx: number) => {
            acc['actions'] = { idx: idx };
            return acc;
          }
        )
      );
    } catch (error) {
      console.error(error);
      message.error('Network Error');
    }
  };

  const deleteBankAccount = async (account?: SupplierBankAccountsData) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this account?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          const url = `/api/method/shipmnts.controllers.network_company.delete_bank_account`;
          const response: any = await erpNextAxiosCall({
            action: 'post',
            url,
            payload: {
              name: _get(account, 'name'),
            },
          });
          const response_data = response?.response?.data?.message || [];
          if (response_data.success) {
            const updatedAccounts = accounts
              .filter((acc, idx) => account?.actions?.idx !== acc?.actions?.idx)
              .map((acc, idx) => {
                acc['actions'] = { idx: idx };
                return acc;
              });
            setAccounts(updatedAccounts);
          } else if (response_data.errorMsg) {
            message.error(response_data.errorMsg);
          }
        } catch (error) {
          message.error('Network Error');
          console.error(error);
        }
      },
    });
  };
  const headingStyle = { display: 'block', opacity: 0.6, fontSize: '12px', fontWeight: '600' };

  useEffect(() => {
    get_bank_accounts().then(() => setAccountsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WithPermission permission={{ name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' }}>
      <DetailsCard
        header={{ title: 'Bank Accounts' }}
        loading={loading || accountsLoading}
        style={{ width: '100%' }}
        Action={
          <WithPermission permission={permission}>
            <AddBankAccount
              disabled={company?.is_disabled || !is_vendor}
              onClick={() => {
                setDrawerAccountData({
                  bank: '',
                  account_name: '',
                  bank_account_no: '',
                  address_name: '',
                  swift_number: '',
                  iban: '',
                  branch_code: '',
                  beneficiary_code: '',
                });
                setShowDrawer(true);
              }}
            />
          </WithPermission>
        }
      >
        <>
          {accounts && accounts?.length > 0 ? (
            <>
              {accounts?.map((account, idx) => {
                const data = {
                  'Bank Account #': account?.bank_account_no || '-',
                  'Beneficiary Bank': account?.bank || '-',
                  'Beneficiary Code': account?.beneficiary_code || '-',
                  'Beneficiary Address': account?.address_name || '-',
                  'Branch Code (IFSC)': account?.branch_code || '-',
                  'Swift Code': account?.swift_number || '-',
                  IBAN: account?.iban || '-',
                };
                return (
                  <div
                    key={account?.bank_account_no}
                    style={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      alignItems: 'start',
                      gridTemplateColumns: '12% 12% 10% 14% 12% 10% 14%',
                      borderBottom:
                        idx !== accounts.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                      padding: '1em',
                      gap: '1em',
                    }}
                  >
                    {Object.entries(data).map(([label, value]) => {
                      return (
                        <section key={label} style={{ wordWrap: 'break-word' }}>
                          <span style={headingStyle}>{label}</span>
                          <span>{value}</span>
                        </section>
                      );
                    })}
                    <section style={{ textAlign: 'right', alignSelf: 'center' }}>
                      <Button
                        onClick={() => {
                          setDrawerAccountData(account);
                          setShowDrawer(true);
                        }}
                        icon={<EditOutlined />}
                        style={{ color: '#4E89FF' }}
                        disabled={
                          !hasPermission(session.permissions, permission) ||
                          company?.is_disabled ||
                          !is_vendor
                        }
                        type="text"
                      />
                      <Button
                        onClick={() => deleteBankAccount(account)}
                        icon={<DeleteOutlined />}
                        disabled={
                          !hasPermission(session.permissions, permission) ||
                          company?.is_disabled ||
                          !is_vendor
                        }
                        type="text"
                        danger
                      />
                    </section>
                  </div>
                );
              })}
            </>
          ) : (
            <div
              style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
            >{`No Bank Accounts Added Yet.`}</div>
          )}
          {showDrawer && company && (
            <Drawer
              open={showDrawer}
              onClose={() => {
                setShowDrawer(false);
              }}
              maskClosable={false}
              rootStyle={{ minWidth: '300px' }}
              width={'30%'}
              bodyStyle={{ paddingTop: '8px' }}
              footerStyle={{ padding: '0px', height: '0px' }}
              title={drawerAccountData.actions ? 'Edit Bank Account' : 'Add Bank Account'}
            >
              <SupplierBankAccountForm
                company={company}
                companyAccountsData={accounts_data}
                accounts={accounts}
                setAccounts={setAccounts}
                drawerAccountData={drawerAccountData}
                setShowDrawer={setShowDrawer}
              />
            </Drawer>
          )}
        </>
      </DetailsCard>
    </WithPermission>
  );
};
