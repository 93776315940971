import React, { useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Col, ExclamationCircleOutlined, Form, message, Modal } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { BULK_UPSERT_INQUIRY_FIELD } from 'operations/graphql/bulkActions';

/**
 * Renderer function to configure parameters and modal component for editing inquiry fields.
 * It sets up actions, form components, and fields for bulk inquiry updates.
 */
const editInquiryFieldRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: {
    field_name?: string;
    label?: string;
    Komponent?: React.ElementType;
    KomponentProps?: any;
    actionValue?: any;
  },
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      fieldName: extraProps.field_name,
      label: extraProps.label,
      Komponent: extraProps.Komponent,
      KomponentProps: extraProps.KomponentProps,
      actionValue: extraProps.actionValue,
    },
    component: EditInquiryField,
  };
};

/**
 * Modal component for handling bulk updates of inquiry fields.
 * If `actionValue` is provided, it immediately prompts a confirmation modal and executes the mutation.
 * If no `actionValue` is provided, it renders a form for manual input.
 */
const EditInquiryField = React.memo(function EditInquiryField(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  fieldName: string;
  label: string;
  Komponent: React.ElementType;
  KomponentProps?: any;
  actionValue?: any;
}): JSX.Element {
  const {
    selectedNodes,
    onClose,
    onSuccess,
    fieldName,
    label,
    Komponent,
    KomponentProps,
    actionValue,
  } = props;

  // Mutation hook for updating the inquiry fields in bulk
  const [updateEvent, { loading }] = useMutation(BULK_UPSERT_INQUIRY_FIELD);

  /**
   * If `actionValue` is provided, trigger a confirmation modal and execute the update call.
   * This ensures the user is warned before applying bulk changes to inquiries.
   */
  useEffect(() => {
    if (actionValue) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Are you sure?',
        content: 'This action will affect all selected Inquiries.',
        okText: 'Yes',
        onOk() {
          updateCall({ ids: selectedNodes.map((n) => parseInt(n.data.id)), input: actionValue });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionValue]);

  const updateCall = (values: any) => {
    updateEvent({
      variables: values,
    })
      .then((res) => {
        if (res.errors) {
          message.error(res.errors[0].message);
          return;
        } else if (res?.data?.bulk_upsert_inquiry_field) {
          message.success('Update successfully');
          onSuccess();
        }
      })
      .catch(() => {
        message.error('Error occurred while updating');
      });
  };

  /**
   * Form submission handler that prepares the variables for the mutation.
   * It checks for the field value, verifies if it's an object with an `id` property, and sends the correct value.
   */
  const onFinish = async (values: any) => {
    const variables: {
      ids?: number[];
      input?: any;
    } = { ids: selectedNodes.map((n) => parseInt(n.data.id)), input: {} };

    if (values[fieldName]) {
      // If the value is an object with an `id`, use the `id`, otherwise use the value directly
      if (values[fieldName]?.id) {
        variables.input[fieldName] = values[fieldName].id;
      } else {
        variables.input[fieldName] = values[fieldName];
      }
    } else {
      return;
    }

    updateCall(variables);
  };

  const [form] = Form.useForm();

  return (
    <>
      {!actionValue && (
        <Modal
          title={`Update ${label}`}
          open={true}
          okText={'Update'}
          onOk={form.submit}
          onCancel={onClose}
          confirmLoading={loading}
        >
          <Form layout="inline" form={form} requiredMark={false} onFinish={onFinish}>
            <Col span={24}>
              <Form.Item name={fieldName} label={label} rules={[{ required: true }]}>
                <Komponent {...KomponentProps} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      )}
    </>
  );
});

export default editInquiryFieldRenderer;
