import { axiosInstance as axios } from 'common';

export interface ParamsObj {
  user_id: string;
  allowed_cost_centers: BranchObj[];
  allowed_doctypes: PermissionObj[];
  tenant_id: string;
  superset_dashboard_id: string | undefined | null;
  fin_database: string;
  company_condition?: string;
}

interface UrlParams {
  fin_database: string;
  fin_cost_centers_condition: string;
  doctype_permissions: string;
  user_id: string;
  tenant_id: string;
  company_condition: string;
}

interface PermissionObj {
  doc_type: string;
}
interface BranchObj {
  erp_cost_center_id: string;
}

export const get_dashboard_token = async (dashboardParams: ParamsObj) => {
  const payload = {
    resources: [
      {
        type: 'dashboard',
        id: dashboardParams?.superset_dashboard_id,
      },
    ],
    rls: [],
  };
  try {
    const response = await axios.post(
      `${process.env.ALEX_URL}${process.env.DASHBOARD_TOKEN_ENDPOINT}`,
      payload
    );
    return response.data.token;
  } catch (error) {
    console.log(
      'DASHBOARD TOKEN ERROR -> ',
      error,
      `${process.env.ALEX_URL}${process.env.DASHBOARD_TOKEN_ENDPOINT}`
    );
  }
  return '';
};
export const generate_url_params = (dashboardParams: ParamsObj) => {
  const allowed_cost_centers: string[] = (dashboardParams.allowed_cost_centers || []).map(
    (branch_account: BranchObj) => branch_account.erp_cost_center_id
  );
  const allowed_doctypes: string[] = (dashboardParams.allowed_doctypes || []).map(
    (permission: PermissionObj) => `${permission.doc_type}`
  );
  const fin_cost_centers_condition =
    allowed_cost_centers.length > 0
      ? `and gle.cost_center IN (${allowed_cost_centers
          .map((costCenter) => `'${costCenter}'`)
          .join(',')})`
      : '';
  const url_params: UrlParams = {
    fin_database: dashboardParams.fin_database,
    fin_cost_centers_condition,
    doctype_permissions: JSON.stringify(allowed_doctypes),
    user_id: dashboardParams.user_id,
    tenant_id: dashboardParams.tenant_id,
    company_condition: dashboardParams.company_condition || '',
  };
  return url_params;
};
