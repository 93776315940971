import {
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_AIR,
  SERVICE_TYPE_DESTINATION,
  SERVICE_TYPE_ORIGIN,
  BUSINESS_TYPE_DIRECT,
  SHIPMENT_TYPE_HOUSE,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_DIRECT,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  RELEASE_TYPE_RFS,
  RELEASE_TYPE_SOB,
  BL_TYPE_EXPRESS,
  BL_TYPE_ORIGINAL,
  SERVICE_TYPE_FREIGHT,
  CUSTOMER_ORDER_TYPE,
  FREIGHT_TYPE_ROAD,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_CROSS_TRADE,
} from '../shipment/constants';

import { startCase as _startCase } from 'lodash';
export const PERMISSION_UNRELEASE_DOCUMENT = 'unrelease';
export const PERMISSION_RELEASE_DOCUMENT = 'release';
export const SIDEBAR_WIDTH = 200;
export const DEFAULT_DASHBOARD_REPORT_HEIGHT = 'calc(100vh - 288px)';

export const STATUS_ALL_REPORTS = 'all';
export const STATUS_BOOKING_ORDER_DASHBOARD = 'booking_order_dashboard';
export const STATUS_BOOKING_RECEIVED_SUMMARY = 'booking_received_summary';
export const STATUS_BOOKING_ALLOCATED_SUMMARY = 'booking_allocated_summary';
export const OTO_DASHBOARD_REPORTS = [
  STATUS_BOOKING_ORDER_DASHBOARD,
  // STATUS_BOOKING_RECEIVED_SUMMARY,
  STATUS_BOOKING_ALLOCATED_SUMMARY,
];
export const STATUS_BOOKING_REQUEST_SUMMARY = 'customer_order_summary';
export const STATUS_REQUESTED = 'requested';
export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_EXPIRED = 'expired';
export const STATUS_EXPIRING_SOON = 'expiring';
export const STATUS_CANCELLED = 'cancelled';

export const STATUS_PICKUP_PENDING = 'pickup_pending';
export const STATUS_ACTIVE = 'active';
export const STATUS_COMPLETED = 'completed';
export const STATUS_OFFLOADED = 'offloaded';

export const STATUS_PRE_ALERT_PENDING = 'pre_alert_pending';
export const STATUS_ACTIVE_CONTAINERS = 'active';
export const STATUS_DSR_CONTAINERS = 'dsr';

export const STATUS_SHIPMENT_CREATED = 'shipment_created';

export const ACTION_ROLLOVER_UPDATE_BOOKING = 'rollover_and_update_booking';
export const ACTION_SHUTOUT_UPDATE_BOOKING = 'shutout_and_update_booking';

export const DOCUMENT_STATUS_PENDING_CREATION = 'pending_creation';
export const DOCUMENT_STATUS_DRAFT = 'draft';
export const DOCUMENT_STATUS_DRAFT_RECEIVED = 'draft_received';
export const DOCUMENT_STATUS_DRAFT_APPROVED = 'draft_approved';
export const DOCUMENT_STATUS_EXECUTED = 'executed';
export const DOCUMENT_STATUS_GIVEN_TO_CUSTOMER = 'given_to_customer';
export const DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN = 'surrendered_at_origin';
export const DOCUMENT_STATUS_RECEIVED_BY_SHIPPER = 'received_by_shipper';
export const DOCUMENT_STATUS_BL_AUDITED = 'bl_audited';
export const DOCUMENT_STATUS_RECEIVED_AT_DESTINATION = 'received_at_destination';
export const DOCUMENT_STATUS_SI_FILED = 'si_filed';
export const DOCUMENT_STATUS_FIRST_DRAFT_SHARED = 'first_draft_shared';
export const DOCUMENT_STATUS_CANCELLED = 'cancelled';
export const DOCUMENT_STATUS_HANDOVER_SET_PRINTED = 'handover_set_printed';
export const DOCUMENT_STATUS_POD_RECEIVED = 'proof_of_delivery_received';
export const SHIPMENT_DOCUMENT_TYPE_MASTER = 'master';
export const SHIPMENT_DOCUMENT_TYPE_HOUSE = 'house';
export const DOCUMENT_STATUS_MAPPING: { [key: string]: string[] } = {
  [FREIGHT_TYPE_AIR]: [
    DOCUMENT_STATUS_PENDING_CREATION,
    DOCUMENT_STATUS_DRAFT,
    DOCUMENT_STATUS_HANDOVER_SET_PRINTED,
  ],
  [FREIGHT_TYPE_OCEAN]: [
    DOCUMENT_STATUS_PENDING_CREATION,
    DOCUMENT_STATUS_DRAFT,
    DOCUMENT_STATUS_DRAFT_APPROVED,
    DOCUMENT_STATUS_EXECUTED,
    DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
    DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
    DOCUMENT_STATUS_CANCELLED,
  ],
};

export const DOCUMENT_STATUS_DISPLAY_NAME_MAPPING: { [key: string]: string } = {
  [DOCUMENT_STATUS_DRAFT]: 'In Progress',
  [DOCUMENT_STATUS_EXECUTED]: 'Released',
};

export const ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE: { [key: string]: string[] } = {
  [FREIGHT_TYPE_AIR]: [DOCUMENT_STATUS_PENDING_CREATION],
  [FREIGHT_TYPE_OCEAN]: [
    DOCUMENT_STATUS_PENDING_CREATION,
    DOCUMENT_STATUS_DRAFT,
    DOCUMENT_STATUS_DRAFT_APPROVED,
    DOCUMENT_STATUS_EXECUTED,
    DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
    DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  ],
};

export const ALLOWED_DOCUMENT_STATUS_MAPPING_ON_CREATE_IMPORT: { [key: string]: string[] } = {
  [FREIGHT_TYPE_AIR]: [DOCUMENT_STATUS_PENDING_CREATION],
  [FREIGHT_TYPE_OCEAN]: [
    DOCUMENT_STATUS_PENDING_CREATION,
    DOCUMENT_STATUS_DRAFT,
    DOCUMENT_STATUS_DRAFT_RECEIVED,
    DOCUMENT_STATUS_DRAFT_APPROVED,
    DOCUMENT_STATUS_EXECUTED,
    DOCUMENT_STATUS_GIVEN_TO_CUSTOMER,
    DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN,
    DOCUMENT_STATUS_RECEIVED_AT_DESTINATION,
  ],
};
export const document_status_to_display_name_mapping: { [key: string]: string } = {
  [DOCUMENT_STATUS_PENDING_CREATION]: 'Pending Creation',
  [DOCUMENT_STATUS_DRAFT]: 'In Progress',
  [DOCUMENT_STATUS_EXECUTED]: 'Released',
  [DOCUMENT_STATUS_CANCELLED]: 'Cancelled',
  [DOCUMENT_STATUS_DRAFT_APPROVED]: 'Draft Approved',
  [DOCUMENT_STATUS_DRAFT_RECEIVED]: 'Draft Received',
  [DOCUMENT_STATUS_GIVEN_TO_CUSTOMER]: 'Given to Customer',
  [DOCUMENT_STATUS_SURRENDERED_AT_ORIGIN]: 'Surrendered At Origin',
  [DOCUMENT_STATUS_RECEIVED_AT_DESTINATION]: 'Received At Destination',
};

export const REASONS_FOR_SHIPMENT_CANCELLATION = [
  { label: 'No Space Available', value: 'No Space Available' },
  { label: 'Increase In Rates', value: 'Increase In Rates' },
  { label: 'Cargo Not Ready', value: 'Cargo Not Ready' },
  { label: 'Improper Document', value: 'Improper Document' },
  { label: 'Split BL', value: 'Split BL' },
  {
    label: 'Container Shutout and Vessel Not Confirmed',
    value: 'Container Shutout and Vessel Not Confirmed',
  },
  { label: 'Branch Code Wrongfully Created', value: 'Branch Code Wrongfully Created' },
  { label: 'Merge BL', value: 'Merge BL' },
  { label: 'Change House BL to Direct BL', value: 'Change House BL to Direct BL' },
  { label: 'Other', value: 'Other' },
];

export const STATUSES_MAPPING: { [key: string]: 'default' | 'success' | 'error' | 'warning' } = {
  [STATUS_REQUESTED]: 'default',
  [STATUS_CONFIRMED]: 'success',
  [STATUS_EXPIRED]: 'error',
};

export const PIVOT_CUSTOMER = 'customer_company.registered_name';
export const PIVOT_VOYAGE = 'vessel_voyage';
export const PIVOT_PORT_OF_LOADING = 'port_of_loading.name';
export const PIVOT_PORT_OF_DISCHARGE = 'port_of_discharge.name';
export const PIVOT_SALES_PERSION = 'sales_person';
export const PIVOT_CARRIER = 'global_carrier.name';
export const PIVOT_TEMPERATURE = 'temperature';
export const PIVOT_POCR = 'place_of_carrier_receipt.name';
export const PIVOT_CONTAINER_TYPE = 'container_type';
export const PIVOT_COMMODITY = 'commodity';
export const PIVOT_HOUSE_SHIPMEMT = 'house_shipment';
export const FILTER_ETD = 'estimated_time_of_departure';
export const FILTER_CREATED_BY = 'created_by';
export const FILTER_COMMODITY = 'commodity';
export const FILTER_BRANCH = 'branch';
export const FILTER_VESSEL = 'vessel_id';
export const FILTER_VOYAGE_NUMBER = 'voyage_number';
export const FILTER_VESSEL_VOYAGE_NUMBER = 'voyage_schedule_id';
export const FILTER_PICKUP_DATE = 'pickup_date';

export const ACTION_BY_SHIPPER = 'shipper';
export const ACTION_BY_SHIPPING_LINE = 'shipping_line';
export const ACTION_BY_OPERATION = 'operational';
export const ACTION_BY_OVERSEAS_AGENT = 'overseas_agent';
export const OTHER_REASON = 'other_reason';

export const OFFLOAD_BY = [ACTION_BY_SHIPPER, ACTION_BY_OPERATION, OTHER_REASON];

export const OFFLOAD_REASONS: { [key: string]: string[] } = {
  [ACTION_BY_SHIPPER]: ['Order Cancelled', 'Cargo Not Ready', 'Order Postponed'],
  [ACTION_BY_OPERATION]: ['Container Damage', 'Container Malfunction '],
  [OTHER_REASON]: ['Other'],
};

export const SHUTOUT_BY = [ACTION_BY_SHIPPER, ACTION_BY_SHIPPING_LINE, ACTION_BY_OPERATION];

export const SHUTOUT_BY_REASONS: { [key: string]: string[] } = {
  [ACTION_BY_SHIPPER]: [
    'Delayed Factory Stuffing',
    'Transporter Failure',
    'Documents Not Ready',
    'Change of Destination',
    'Custom Hold',
    'Order Cancel',
    'BL Draft Not Submitted',
    'DGD Not Indexed',
    'Document Not Submitted In Time',
    'Rollover',
  ],
  [ACTION_BY_SHIPPING_LINE]: [
    'Space Not Available',
    'Container Malfunction',
    'Operational Constraints',
    'Rollover',
    'Shutout',
  ],
  [ACTION_BY_OPERATION]: ['Transporter Delay', 'Document Handover Delay', 'CHA Delay'],
  [OTHER_REASON]: ['Other'],
};

export const ROLLOVER_BY_REASONS: { [key: string]: string[] } = {
  ...SHUTOUT_BY_REASONS,
  [ACTION_BY_SHIPPER]: ['Cargo Not Ready ', ...SHUTOUT_BY_REASONS[ACTION_BY_SHIPPER]],
  [ACTION_BY_OVERSEAS_AGENT]: [
    'Delayed Factory Stuffing',
    'Transporter Failure',
    'Documents Not Ready',
    'Change of Destination',
    'Custom Hold',
    'Order Cancel',
    'BL Draft Not Submitted',
    'DGD Not Indexed',
    'Document Not Submitted In Time',
  ],
  [OTHER_REASON]: ['Other'],
};

export const BOOKING_CANCELLED_BY = [
  ACTION_BY_SHIPPER,
  ACTION_BY_SHIPPING_LINE,
  ACTION_BY_OPERATION,
  ACTION_BY_OVERSEAS_AGENT,
  OTHER_REASON,
];

export const BOOKING_CANCELLATION_REASONS: { [key: string]: string[] } = {
  [ACTION_BY_SHIPPER]: [
    'Order cancelled',
    'Cargo not ready',
    'Order postponed',
    'Improper Documents',
  ],
  [ACTION_BY_SHIPPING_LINE]: ['Space not available', 'Rates Revised'],
  [ACTION_BY_OPERATION]: ['Customer black listed', 'Credit Exceeded'],
  [ACTION_BY_OVERSEAS_AGENT]: [
    'Delayed Factory Stuffing',
    'Transporter Failure',
    'Documents Not Ready',
    'Change of Destination',
    'Custom Hold',
    'Order Cancel',
    'BL Draft Not Submitted',
    'DGD Not Indexed',
    'Document Not Submitted In Time',
  ],
  [OTHER_REASON]: ['Other'],
};

export const statusTagColor = {
  [STATUS_REQUESTED]: 'yellow',
  [STATUS_CONFIRMED]: 'green',
  [STATUS_EXPIRED]: 'red',
  [STATUS_CANCELLED]: 'red',
};

export const GATE_PASS_REQUESTED = 'requested';
export const GATE_PASS_CONFIRMED = 'confirmed';
export const GATE_PASS_REJECTED = 'rejected';

export const gatePassStatuses = [GATE_PASS_REQUESTED, GATE_PASS_CONFIRMED, GATE_PASS_REJECTED];

export const REQUESTED_AGEING = 6;

export const MESSAGE_REPORT_TYPE_INBOX = 'inbox';
export const MESSAGE_REPORT_TYPE_OUTBOX = 'outbox';

export const ACTION_CREATE_SHIPMENT_FROM_MESSAGE = 'createe_shipment_from_message';
export const ACTION_DISCARD_MESSAGE = 'discard_message';

export const TRADE_TYPE = [
  { key: TRADE_TYPE_EXPORT, name: _startCase(TRADE_TYPE_EXPORT) },
  { key: TRADE_TYPE_IMPORT, name: _startCase(TRADE_TYPE_IMPORT) },
];

export const CANCEL_SHIPMENT = 'cancel_shipment';

export const MAWB_ADDITIONAL_PARTIES = [
  { key: 'notify_party_1', name: 'Notify Party 1' },
  { key: 'notify_party_2', name: 'Notify Party 2' },
  { key: 'notify_party_3', name: 'Notify Party 3' },
  { key: 'delivery_agent', name: 'Delivery Agent' },
];

export const companyTypeMap = {
  address: 'company',
  branch_account: 'company_account',
};

export const PERISHABLE_TYPE_FRUIT = { key: 'fruits_vegetables', name: 'Fruit & Vegetables' };
export const PERISHABLE_TYPE_MEAT = { key: 'meat', name: 'Meat' };
export const PERISHABLE_TYPE_SEA_FOOD = { key: 'sea_food', name: 'Sea Food / Fish' };
export const PERISHABLE_TYPE_FLOWER = { key: 'cut_flower', name: 'Cut Flowers' };
export const PERISHABLE_TYPE_MANGO = { key: 'mango', name: 'Mango' };

export const PERISHABLE_TYPES = [
  PERISHABLE_TYPE_FRUIT,
  PERISHABLE_TYPE_MEAT,
  PERISHABLE_TYPE_SEA_FOOD,
  PERISHABLE_TYPE_FLOWER,
  PERISHABLE_TYPE_MANGO,
];

export const MAX_HOPS_POSSIBLE = 100;
export const LOCATION_TAG_POL = { key: 'port_of_loading', name: 'Port Of Loading' };
export const LOCATION_TAG_POD = { key: 'port_of_discharge', name: 'Port Of Discharge' };

export const LOCATION_TAG_PCR = {
  key: 'place_of_carrier_receipt',
  name: 'Place of carrier receipt',
};

export const LOCATION_TAG_PCD = {
  key: 'place_of_carrier_delivery',
  name: 'Place of carrier delivery',
};
export const LOCATION_TAG_OTHER = { key: 'other', name: 'Other' };

export const SHIPMENT_TYPE_GENERAL = 'general';
export const CLEARANCE_SHIPMENT = 'clearance';
export const SHIPMENT_TYPE_MASTER = 'Master';

export const SHIPMENT_TYPE = [
  { key: SHIPMENT_TYPE_DIRECT, name: _startCase(SHIPMENT_TYPE_DIRECT) },
  { key: SHIPMENT_TYPE_CONSOL, name: `${_startCase(SHIPMENT_TYPE_CONSOL)} Master` },
  { key: SHIPMENT_TYPE_BACK_TO_BACK, name: _startCase(SHIPMENT_TYPE_BACK_TO_BACK) },
  { key: SHIPMENT_TYPE_HOUSE, name: `Consol ${_startCase(SHIPMENT_TYPE_HOUSE)}` },
];

export const ONLY_MASTER_SHIPMENT_TYPES = [
  { key: SHIPMENT_TYPE_DIRECT, name: _startCase(SHIPMENT_TYPE_DIRECT) },
  { key: SHIPMENT_TYPE_CONSOL, name: `${_startCase(SHIPMENT_TYPE_CONSOL)} Master` },
  { key: SHIPMENT_TYPE_BACK_TO_BACK, name: _startCase(SHIPMENT_TYPE_BACK_TO_BACK) },
];

export const ONLY_HOUSE_SHIPMENT_TYPES = [
  { key: SHIPMENT_TYPE_BACK_TO_BACK, name: _startCase(SHIPMENT_TYPE_BACK_TO_BACK) },
  { key: SHIPMENT_TYPE_HOUSE, name: `Consol ${_startCase(SHIPMENT_TYPE_HOUSE)}` },
];

export const NATURE_OF_CARGO_TYPES = [
  { key: 'is_general', name: 'General Cargo' },
  { key: 'is_perishable', name: 'Perishable' },
  { key: 'is_temp_controlled', name: 'Temperature Controlled' },
  { key: 'is_hazardous', name: 'Hazardous Cargo' },
  { key: 'is_battery', name: 'Battery Inside' },
];

export const PARTIES_KEYS = [
  'customer',
  'shipper',
  'consignee',
  'issuing_carrier_agent',
  'notify_party_1',
  'notify_party_2',
  'notify_party_3',
  'notify_party_4',
  'notify_party_1_house',
  'notify_party_2_house',
  'notify_party_3_house',
  'notify_party_4_house',
  'origin_agent',
  'destination_agent',
  'delivery_agent',
  'origin_clearance_agent',
  'destination_clearance_agent',
  'origin_transport_agent',
  'destination_transport_agent',
  'origin_palletization_agent',
  'origin_fumigation_agent',
  'issuing_agent',
  'to_order',
  'to_order_bank',
  'subagent',
  'house_subagent',
  'overseas_agent',
  'house_overseas_agent',
  'delivery_order_in_favour_of',
  'freight_forwarding_agent',
];
export const responsive = {
  xs: { span: 22, offset: 1 },
  sm: { span: 20, offset: 2 },
  md: { span: 18, offset: 3 },
  lg: { span: 16, offset: 4 },
  xl: { span: 16, offset: 4 },
  xxl: { span: 16, offset: 4 },
};

export const HTML_TAB = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;

export const PORTS_KEYS = [
  'port_of_loading',
  'port_of_discharge',
  'place_of_carrier_receipt',
  'place_of_carrier_delivery',
  'destination_port_of_clearance',
  'origin_port_of_clearance',
  'routes_and_place_of_transhipment',
];

export const CARGO_TYPE_AIR_LOOSE = { key: 'loose', name: 'Loose' };
export const CARGO_TYPE_AIR_ULD = { key: 'uld', name: 'ULD' };
export const CARGO_TYPES_AIR = [CARGO_TYPE_AIR_LOOSE, CARGO_TYPE_AIR_ULD];

export const LOAD_TYPE_LCL = 'lcl';
export const LOAD_TYPE_FCL = 'fcl';

export const MOVEMENT_TYPE_DPD = 'dpd';
export const MOVEMENT_TYPE_CFS = 'cfs';
export const MOVEMENT_TYPE_DPD_CFS = 'dpd-cfs';
export const MOVEMENT_TYPE_ICD = 'icd';

export const MOVEMENT_TYPES = [
  { key: MOVEMENT_TYPE_DPD, name: MOVEMENT_TYPE_DPD.toUpperCase() },
  { key: MOVEMENT_TYPE_DPD_CFS, name: MOVEMENT_TYPE_DPD_CFS.toUpperCase() },
  { key: MOVEMENT_TYPE_CFS, name: MOVEMENT_TYPE_CFS.toUpperCase() },
];

export const SHIPMENT_STATUS_PLANNED = 'shipment_planned';
export const SHIPMENT_STATUS_EXECUTED = 'executed';
export const SHIPMENT_STATUS_CREATED = 'shipment_created';
export const SHIPMENT_STATUS_CANCELLED = 'shipment_cancelled';
export const SHIPMENT_STATUS_BACK_TO_TOWN = 'shipment_back_to_town';

export const FIELD_TYPE_TEMPLATE = 'template';

export const LOAD_TYPE = [
  { key: LOAD_TYPE_FCL, name: LOAD_TYPE_FCL.toUpperCase() },
  { key: LOAD_TYPE_LCL, name: LOAD_TYPE_LCL.toUpperCase() },
];

export const BOOKING_DIRECT_KEY = 'direct';

export const BOOKING_THROUGH_SUBAGENT = { key: 'subagent', name: 'Subagent' };

export const MOVEMENT_TYPE_LCL_LCL = 'LCL/LCL';
export const MOVEMENT_TYPE_FCL_LCL = 'FCL/LCL';
export const MOVEMENT_TYPE_LCL_FCL = 'LCL/FCL';
export const MOVEMENT_TYPE_FCL_FCL = 'FCL/FCL';

export const PRECARRIAGE_MODE_ROAD = 'ROAD';
export const PRECARRIAGE_MODE_RAIL = 'RAIL';

export const FREIGHT_AMOUNT_PREPAID = 'prepaid'; // TODO: Change it in HBL too
export const FREIGHT_AMOUNT_COLLECT = 'collect'; // TODO: Change it in HBL too
export const FREIGHT_AMOUNT_AS_AGREED = 'AS AGREED';
export const FREIGHT_AMOUNT_RATED = 'RATED';

export const FREIGHT_PAYABLE_ORIGIN = 'ORIGIN';
export const FREIGHT_PAYABLE_DESTINATION = 'DESTINATION';
export const FREIGHT_PAYABLE_ELSEWHERE = 'ELSEWHERE';

export const BL_TYPE_NON_NEGOTIABLE = 'NON-NEGOTIABLE';

export const SHOW_LOGO_INSIDE_KEY = 'issuing_agent';
export const SHOW_LOGO_TOP_RIGHT_KEY = 'top_right';

export const SHOW_LOGO_INSIDE_NAME = 'Show logo in issuing agent';
export const SHOW_LOGO_TOP_RIGHT_NAME = 'Show logo in top right';

export const NO_LOGO_INSIDE_NAME = 'Space for logo in issuing agent';
export const NO_LOGO_TOP_RIGHT_NAME = 'Space for logo in top right';

export const SHOW_LETTERHEAD_INSIDE_KEY = 'issuing_agent';
export const SHOW_LETTERHEAD_TOP_RIGHT_KEY = 'top_right';

export const SHOW_LETTERHEAD_INSIDE_NAME = 'Show letterhead in issuing agent';
export const SHOW_LETTERHEAD_TOP_RIGHT_NAME = 'Show letterhead in top right';

// export const MOVEMENT_TYPE_DIRECT = { key: 'direct_port_delivery', name: 'Direct Port Delivery' };
// export const MOVEMENT_TYPE_CFS = { key: 'cfs', name: 'Normal CFS' };
// export const MOVEMENT_TYPE_BLOCK = { key: 'block', name: 'N-Block' };
// export const MOVEMENT_TYPES_OCEAN = [MOVEMENT_TYPE_DIRECT, MOVEMENT_TYPE_CFS, MOVEMENT_TYPE_BLOCK];

export const SHOW_RATE = { key: 'show_rate', name: 'Show rate' };
export const HIDE_RATE = { key: 'hide_rate', name: 'Hide rate' };
export const SHOW_AS_AGREED = { key: 'show_as_agreed', name: 'Show as agreed' };

export const RATE_PRINT_OPTIONS = [SHOW_RATE, HIDE_RATE, SHOW_AS_AGREED];

export const FREIGHT_TERMS = [
  { key: FREIGHT_AMOUNT_PREPAID, name: 'PP-prepaid' },
  { key: FREIGHT_AMOUNT_COLLECT, name: 'CC-collect' },
];

export const AUTHORISED_SIGNATORY = 'Authorised Signatory';
export const AS_AGENT_OF_CARRIER = 'As Agent For Liner';
export const AS_AGENT = 'As Agent';

export const SIGNING_AUTHORITY_TYPES = [
  { key: AUTHORISED_SIGNATORY, name: AUTHORISED_SIGNATORY },
  { key: AS_AGENT_OF_CARRIER, name: AS_AGENT_OF_CARRIER },
  { key: AS_AGENT, name: AS_AGENT },
];

export const STUFFING_TYPE_FACTORY = 'factory';
export const STUFFING_TYPE_CFS = 'cfs';
export const STUFFING_TYPE_ICD = 'icd';

export const STUFFING_TYPES_OCEAN = [
  { key: STUFFING_TYPE_CFS, name: STUFFING_TYPE_CFS.toUpperCase() },
  { key: STUFFING_TYPE_ICD, name: STUFFING_TYPE_ICD.toUpperCase() },
];

export const MOVEMENT_TYPE = [
  { key: MOVEMENT_TYPE_LCL_LCL, name: MOVEMENT_TYPE_LCL_LCL },
  { key: MOVEMENT_TYPE_FCL_LCL, name: MOVEMENT_TYPE_FCL_LCL },
  { key: MOVEMENT_TYPE_LCL_FCL, name: MOVEMENT_TYPE_LCL_FCL },
  { key: MOVEMENT_TYPE_FCL_FCL, name: MOVEMENT_TYPE_FCL_FCL },
];

export const FREIGHT_AMOUNT_SAME_AS_FREIGHT_CHARGE = 'Same As Freight Charge';

export const FREIGHT_AMOUNT = [
  { key: FREIGHT_AMOUNT_SAME_AS_FREIGHT_CHARGE, name: FREIGHT_AMOUNT_SAME_AS_FREIGHT_CHARGE },
  { key: FREIGHT_AMOUNT_AS_AGREED, name: FREIGHT_AMOUNT_AS_AGREED },
  { key: FREIGHT_AMOUNT_RATED, name: FREIGHT_AMOUNT_RATED },
];

export const FREIGHT_PAYABLE = [
  { key: FREIGHT_PAYABLE_ORIGIN, name: FREIGHT_PAYABLE_ORIGIN },
  { key: FREIGHT_PAYABLE_DESTINATION, name: FREIGHT_PAYABLE_DESTINATION },
  { key: FREIGHT_PAYABLE_ELSEWHERE, name: FREIGHT_PAYABLE_ELSEWHERE },
];

export const SERVICE_TYPES = [
  { label: _startCase(SERVICE_TYPE_FREIGHT), value: SERVICE_TYPE_FREIGHT },
  { label: _startCase(SERVICE_TYPE_ORIGIN), value: SERVICE_TYPE_ORIGIN },
  { label: _startCase(SERVICE_TYPE_DESTINATION), value: SERVICE_TYPE_DESTINATION },
];

export const PRECARRIAGE_MODE = [
  { key: PRECARRIAGE_MODE_ROAD, name: PRECARRIAGE_MODE_ROAD },
  { key: PRECARRIAGE_MODE_RAIL, name: PRECARRIAGE_MODE_RAIL },
  { key: '', name: 'NA' },
];

export const SHOW_LOGO = [
  { key: SHOW_LOGO_INSIDE_KEY, name: SHOW_LOGO_INSIDE_NAME },
  { key: SHOW_LOGO_TOP_RIGHT_KEY, name: SHOW_LOGO_TOP_RIGHT_NAME },
];

export const NO_LOGO = [
  { key: SHOW_LOGO_INSIDE_KEY, name: NO_LOGO_INSIDE_NAME },
  { key: SHOW_LOGO_TOP_RIGHT_KEY, name: NO_LOGO_TOP_RIGHT_NAME },
];

export const SHOW_LETTERHEAD = [
  { key: SHOW_LETTERHEAD_INSIDE_KEY, name: SHOW_LETTERHEAD_INSIDE_NAME },
  { key: SHOW_LETTERHEAD_TOP_RIGHT_KEY, name: SHOW_LETTERHEAD_TOP_RIGHT_NAME },
];

export const BL_TYPE_OLD = [
  { key: BL_TYPE_ORIGINAL, name: BL_TYPE_ORIGINAL },
  { key: BL_TYPE_NON_NEGOTIABLE, name: BL_TYPE_NON_NEGOTIABLE },
  { key: BL_TYPE_EXPRESS, name: BL_TYPE_EXPRESS },
];

export const MBL_RELEASE_TYPE_ARRAY = [
  {
    key: RELEASE_TYPE_SOB,
    name: 'Shipped On Board',
  },
  {
    key: RELEASE_TYPE_RFS,
    name: 'Received For Shipment',
  },
];

export const DUE_CARRIER_CHARGE = 'Due Carrier';

export const FREIGHT_TYPE = [
  { key: FREIGHT_TYPE_AIR, name: 'Air' },
  { key: FREIGHT_TYPE_OCEAN, name: 'Ocean' },
];

export const SHIPPER_TYPE_ORIGIN = { key: 'origin_agent', name: 'Origin Agent' };
export const SHIPPER_TYPE_ACTUAL_SHIPPER = { key: 'actual_shipper', name: 'Actual Shipper' };
export const SHIPPER_TYPES = [SHIPPER_TYPE_ORIGIN, SHIPPER_TYPE_ACTUAL_SHIPPER];

export const packingGroup = [
  { key: 'group_1', name: 'Group I' },
  { key: 'group_2', name: 'Group II' },
  { key: 'group_3', name: 'Group III' },
];

export const AIR_CRAFT_TYPE_PASSENGER = { key: 'passenger', name: 'Passenger ' };
export const AIR_CRAFT_TYPE_CARGO = { key: 'cargo', name: 'Cargo ' };

export const airCraftTypes = [AIR_CRAFT_TYPE_PASSENGER, AIR_CRAFT_TYPE_CARGO];

export const WEIGHT_UNIT_KGS = { key: 'kgs', name: 'kgs' };
export const WEIGHT_UNIT_TON = { key: 'mts', name: 'mts' };
export const WEIGHT_UNIT_LBS = { key: 'lbs', name: 'lbs' };

export const weightUnits = [WEIGHT_UNIT_KGS, WEIGHT_UNIT_TON, WEIGHT_UNIT_LBS];

export const floatingPointPrecisionValues = [
  { key: '2', name: '2 digits' },
  { key: '3', name: '3 digits' },
];
export const VOLUME_UNIT_CBM = { key: 'cbm', name: 'cbm' };
export const VOLUME_UNIT_CFT = { key: 'cft', name: 'cft' };

export const volumeUnits = [VOLUME_UNIT_CBM, VOLUME_UNIT_CFT];

export const commodityTypes = [
  { label: 'General', value: 'general' },
  { label: 'Hazardous', value: 'hazardous' },
  { label: 'Temperature', value: 'temperature' },
  { label: 'Battery', value: 'battery' },
];

export const BATTERY_TYPE_LI_ON = { key: 'li_on', value: 'LI-on' };
export const BATTERY_TYPE_LI_POLYMER = { key: 'li_polymer', value: 'LI-polymer' };
export const BATTERY_TYPE_NI_CAD = { key: 'ni_Cad', value: 'Ni-Cad' };
export const BATTERY_TYPE_LEAD_ACID = { key: 'lead_acid', value: 'Lead Acid' };
export const BATTERY_TYPE_ALKALINE = { key: 'alkaline', value: 'Alkaline' };

export const batteryTypes = [
  BATTERY_TYPE_LI_ON,
  BATTERY_TYPE_LI_POLYMER,
  BATTERY_TYPE_NI_CAD,
  BATTERY_TYPE_LEAD_ACID,
  BATTERY_TYPE_ALKALINE,
];

export const TEMP_RANGE_MINUS_20_TO_20 = { key: 'minus_twenty_to_twenty', name: '-20 to 20' };
export const TEMP_RANGE_2_TO_8 = { key: 'two_to_eight', name: '2 to 8' };
export const TEMP_RANGE_2_TO_25 = { key: 'two_to_twenty_five', name: '2 to 25' };
export const TEMP_RANGE_15_TO_25 = { key: 'fifteen_to_twenty_five', name: '15 to 25' };
export const TEMP_RANGE_MINUS_80 = { key: 'minus_eighty', name: '-80' };
export const TEMP_RANGE_MINUS_25_TO_MINUS_15 = {
  key: 'minus_twenty_five_to_minus_fifteen',
  name: '-25 to -15',
};

export const tempRange = [
  TEMP_RANGE_MINUS_20_TO_20,
  TEMP_RANGE_2_TO_8,
  TEMP_RANGE_2_TO_25,
  TEMP_RANGE_15_TO_25,
  TEMP_RANGE_MINUS_80,
  TEMP_RANGE_MINUS_25_TO_MINUS_15,
];

export const COOLING_TYPE_ACTIVE = { key: 'active', value: 'Active' };
export const COOLING_TYPE_PASSIVE = { key: 'passive', value: 'Passive' };
export const COOLING_TYPE_FROZEN = { key: 'frozen', value: 'Frozen' };
export const COOLING_TYPE_DRY_ICE = { key: 'dry_ice', value: 'Dry Ice' };

export const coolingTypes = [
  COOLING_TYPE_ACTIVE,
  COOLING_TYPE_PASSIVE,
  COOLING_TYPE_FROZEN,
  COOLING_TYPE_DRY_ICE,
];

export const draftApprovalStatusOptions = [
  { name: 'Appoved', key: 'true' },
  { name: 'Pending', key: 'false' },
];

export const DOCUMENT_TYPE_MAWB = 'mawb';
export const DOCUMENT_TYPE_NEW_MAWB = 'new_mawb';
export const DOCUMENT_TYPE_HAWB = 'hawb';
export const REPORT_TYPE_DUPLICATE_MAWB = 'duplicate_mawb';
export const REPORT_TYPE_DUPLICATE_HAWB = 'duplicate_hawb';
export const DOCUMENT_TYPE_NEW_HAWB = 'new_hawb';
export const DOCUMENT_TYPE_AIR_CARGO_MANIFEST = 'air_cargo_manifest';
export const DOCUMENT_TYPE_NEW_AIR_CARGO_MANIFEST = 'new_air_cargo_manifest';
export const DOCUMENT_TYPE_ANNEXURE_1 = 'annexure_1';
export const DOCUMENT_TYPE_ANNEXURE_2 = 'annexure_2';
export const DOCUMENT_TYPE_DGD = 'dgd';
export const DOCUMENT_TYPE_LIFTING_ADVICE = 'lifting_advice';
export const DOCUMENT_TYPE_NON_HAZ = 'non_haz';
export const DOCUMENT_TYPE_CONSOL_GENERAL_MANIFEST = 'consol_general_manifest';
export const DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST = 'new_consol_general_manifest';
export const DOCUMENT_TYPE_HBL = 'new_hbl';
export const DOCUMENT_TYPE_STUFFING_CONFIRMATION = 'stuffing_confirmation';
export const DOCUMENT_TYPE_LOADING_CONFIRMATION = 'loading_confirmation';
export const DOCUMENT_TYPE_MBL = 'new_mbl';
export const DOCUMENT_TYPE_OLD_HBL = 'old_hbl';
export const DOCUMENT_TYPE_AWB_LABEL = 'awb_label';
export const DOCUMENT_TYPE_NEW_ANNEXURE_ONE = 'new_annexure_1';
export const DOCUMENT_TYPE_NEW_ANNEXURE_TWO = 'new_annexure_2';
export const DOCUMENT_TYPE_NEW_AWB_LABEL = 'new_awb_label';
export const DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE = 'air_freight_certificate';
export const DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE = 'ocean_freight_certificate';
export const DOCUMENT_TYPE_SEA_FREIGHT_MANIFEST = 'sea_freight_manifest';
export const DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE = 'cargo_arrival_notice';
export const DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE = 'air_cargo_arrival_notice';
export const DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER = 'transport_delivery_order';
export const DOCUMENT_TYPE_SEA_CARGO_MANIFEST = 'sea_cargo_manifest';
export const DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER = 'ocean_delivery_order';
export const DOCUMENT_TYPE_AIR_DELIVERY_ORDER = 'air_delivery_order';
export const DOCUMENT_TYPE_PROOF_OF_DELIVERY = 'proof_of_delivery';
export const DOCUMENT_TYPE_PRODUCT_LABEL = 'product_label';
export const DOCUMENT_TYPE_CONSIGNMENT_NOTE = 'consignment_note';
export const DOCUMENT_TYPE_IMPORT_SECURITY_FILING = 'import_security_filing';
export const DOCUMENT_TYPE_ANNEXURE_C = 'annexure_c';
export const DOCUMENT_TYPE_VGM_CERTIFICATE = 'vgm_certificate';

export const DOCUMENT_TYPES_DISPLAY_STRINGS = {
  [DOCUMENT_TYPE_MAWB]: 'MAWB',
  [DOCUMENT_TYPE_NEW_MAWB]: 'New MAWB',
  [DOCUMENT_TYPE_HAWB]: 'HAWB',
  [DOCUMENT_TYPE_NEW_HAWB]: 'New HAWB',
  [DOCUMENT_TYPE_AWB_LABEL]: 'Awb Label',
  [DOCUMENT_TYPE_AIR_CARGO_MANIFEST]: 'Air cargo manifest',
  [DOCUMENT_TYPE_NEW_AIR_CARGO_MANIFEST]: 'Air cargo manifest',
  [DOCUMENT_TYPE_ANNEXURE_1]: 'Annexure 1',
  [DOCUMENT_TYPE_ANNEXURE_2]: 'Annexure 2',
  [DOCUMENT_TYPE_DGD]: 'DGD',
  [DOCUMENT_TYPE_LIFTING_ADVICE]: 'Lifting advice',
  [DOCUMENT_TYPE_NON_HAZ]: 'Non Haz declaration',
  [DOCUMENT_TYPE_CONSOL_GENERAL_MANIFEST]: 'Consol general manifest',
  [DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST]: 'New Consol general manifest',
  [DOCUMENT_TYPE_MBL]: 'MBL / SI',
  [DOCUMENT_TYPE_HBL]: 'HBL',
  [DOCUMENT_TYPE_OLD_HBL]: 'HBL',
  [DOCUMENT_TYPE_LOADING_CONFIRMATION]: 'Loading Confirmation',
  [DOCUMENT_TYPE_STUFFING_CONFIRMATION]: 'Stuffing Confirmation',
  [DOCUMENT_TYPE_NEW_ANNEXURE_ONE]: 'Annexure 1',
  [DOCUMENT_TYPE_NEW_ANNEXURE_TWO]: 'Annexure 2',
  [DOCUMENT_TYPE_NEW_AWB_LABEL]: 'AWB Label',
  [DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE]: 'Freight Certificate',
  [DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE]: 'Freight Certificate',
  [DOCUMENT_TYPE_SEA_FREIGHT_MANIFEST]: 'Sea Freight Manifest',
  [DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE]: 'Cargo Arrival Notice',
  [DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE]: 'Cargo Arrival Notice',
  [DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER]: 'Transport Delivery Order',
  [DOCUMENT_TYPE_SEA_CARGO_MANIFEST]: 'Sea Cargo Manifest',
  [DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER]: 'Delivery Order',
  [DOCUMENT_TYPE_AIR_DELIVERY_ORDER]: 'Delivery Order',
  [DOCUMENT_TYPE_PROOF_OF_DELIVERY]: 'Proof of Delivery',
  [DOCUMENT_TYPE_PRODUCT_LABEL]: 'POD Label',
  [DOCUMENT_TYPE_CONSIGNMENT_NOTE]: 'Consigment Note',
  [DOCUMENT_TYPE_ANNEXURE_C]: 'Annexure C',
  [DOCUMENT_TYPE_VGM_CERTIFICATE]: 'VGM Certificate',
};

export const SHIPMENT_DOCUMENTS_RULES = {
  [FREIGHT_TYPE_AIR]: {
    [TRADE_TYPE_IMPORT]: {
      [CUSTOMER_ORDER_TYPE]: [DOCUMENT_TYPE_PROOF_OF_DELIVERY, DOCUMENT_TYPE_PRODUCT_LABEL],
      [SHIPMENT_TYPE_DIRECT]: [
        DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST,
        DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
        DOCUMENT_TYPE_PROOF_OF_DELIVERY,
        DOCUMENT_TYPE_PRODUCT_LABEL,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_CONSOL]: [
        DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST,
        DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [
        DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_NEW_CONSOL_GENERAL_MANIFEST,
        DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
        DOCUMENT_TYPE_PROOF_OF_DELIVERY,
        DOCUMENT_TYPE_PRODUCT_LABEL,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_HOUSE]: [
        DOCUMENT_TYPE_AIR_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_AIR_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_AIR_DELIVERY_ORDER,
        DOCUMENT_TYPE_PROOF_OF_DELIVERY,
        DOCUMENT_TYPE_PRODUCT_LABEL,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
    },
    [TRADE_TYPE_EXPORT]: {
      [CUSTOMER_ORDER_TYPE]: [DOCUMENT_TYPE_PROOF_OF_DELIVERY, DOCUMENT_TYPE_PRODUCT_LABEL],
      [SHIPMENT_TYPE_DIRECT]: [
        DOCUMENT_TYPE_NEW_MAWB,
        DOCUMENT_TYPE_PROOF_OF_DELIVERY,
        DOCUMENT_TYPE_PRODUCT_LABEL,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_CONSOL]: [DOCUMENT_TYPE_NEW_MAWB, DOCUMENT_TYPE_IMPORT_SECURITY_FILING],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [
        DOCUMENT_TYPE_NEW_MAWB,
        DOCUMENT_TYPE_NEW_HAWB,
        DOCUMENT_TYPE_PROOF_OF_DELIVERY,
        DOCUMENT_TYPE_PRODUCT_LABEL,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_HOUSE]: [
        DOCUMENT_TYPE_NEW_HAWB,
        DOCUMENT_TYPE_PROOF_OF_DELIVERY,
        DOCUMENT_TYPE_PRODUCT_LABEL,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
    },
  },
  [FREIGHT_TYPE_OCEAN]: {
    [TRADE_TYPE_IMPORT]: {
      [SHIPMENT_TYPE_DIRECT]: [
        DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_CONSOL]: [
        DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [
        DOCUMENT_TYPE_HBL,
        DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
      [SHIPMENT_TYPE_HOUSE]: [
        DOCUMENT_TYPE_HBL,
        DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_CARGO_ARRIVAL_NOTICE,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_OCEAN_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
    },
    [TRADE_TYPE_EXPORT]: {
      [SHIPMENT_TYPE_DIRECT]: [
        DOCUMENT_TYPE_MBL,
        DOCUMENT_TYPE_STUFFING_CONFIRMATION,
        DOCUMENT_TYPE_LOADING_CONFIRMATION,
        DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_CONSIGNMENT_NOTE,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
        DOCUMENT_TYPE_ANNEXURE_C,
        DOCUMENT_TYPE_VGM_CERTIFICATE,
      ],
      [SHIPMENT_TYPE_CONSOL]: [
        DOCUMENT_TYPE_MBL,
        DOCUMENT_TYPE_SEA_FREIGHT_MANIFEST,
        DOCUMENT_TYPE_SEA_CARGO_MANIFEST,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
        DOCUMENT_TYPE_VGM_CERTIFICATE,
      ],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [
        DOCUMENT_TYPE_MBL,
        DOCUMENT_TYPE_HBL,
        DOCUMENT_TYPE_STUFFING_CONFIRMATION,
        DOCUMENT_TYPE_LOADING_CONFIRMATION,
        DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_SEA_CARGO_MANIFEST,
        DOCUMENT_TYPE_SEA_FREIGHT_MANIFEST,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
        DOCUMENT_TYPE_VGM_CERTIFICATE,
      ],
      [SHIPMENT_TYPE_HOUSE]: [
        DOCUMENT_TYPE_HBL,
        DOCUMENT_TYPE_STUFFING_CONFIRMATION,
        DOCUMENT_TYPE_LOADING_CONFIRMATION,
        DOCUMENT_TYPE_OCEAN_FREIGHT_CERTIFICATE,
        DOCUMENT_TYPE_TRANSPORT_DELIVERY_ORDER,
        DOCUMENT_TYPE_IMPORT_SECURITY_FILING,
      ],
    },
  },
  [FREIGHT_TYPE_ROAD]: {
    [TRADE_TYPE_IMPORT]: {
      [SHIPMENT_TYPE_DIRECT]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_HOUSE]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
    },
    [TRADE_TYPE_EXPORT]: {
      [SHIPMENT_TYPE_DIRECT]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_HOUSE]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
    },
    [TRADE_TYPE_DOMESTIC]: {
      [SHIPMENT_TYPE_DIRECT]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_HOUSE]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
    },
    [TRADE_TYPE_CROSS_TRADE]: {
      [SHIPMENT_TYPE_DIRECT]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_HOUSE]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
      [SHIPMENT_TYPE_BACK_TO_BACK]: [DOCUMENT_TYPE_CONSIGNMENT_NOTE],
    },
  },
};
export const docDependencyRule = {
  [DOCUMENT_TYPE_NEW_MAWB]: [DOCUMENT_TYPE_NEW_HAWB],
};

export const NEW_DOCUMENTS = [DOCUMENT_TYPE_ANNEXURE_C, DOCUMENT_TYPE_VGM_CERTIFICATE];
export const ALLOWED_MULTIPLE_DOCUMENTS = [DOCUMENT_TYPE_ANNEXURE_C];

export const IATA_LOGO =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Iata_official_logo.png/240px-Iata_official_logo.png';

export const BUSINESS_TYPE_NOMINATION = 'agent_nomination';

export const BUSINESS_TYPES = [
  { key: BUSINESS_TYPE_DIRECT, name: _startCase(BUSINESS_TYPE_DIRECT) },
  { key: BUSINESS_TYPE_NOMINATION, name: _startCase(BUSINESS_TYPE_NOMINATION) },
];

export const CUSTOMS_DOCUMENT_TYPE_SB = 'shipping_bill';
export const CUSTOMS_DOCUMENT_TYPE_BE = 'bill_of_entry_bill';
export const CUSTOMS_DOCUMENT_TYPES = [
  { name: _startCase(CUSTOMS_DOCUMENT_TYPE_SB), key: CUSTOMS_DOCUMENT_TYPE_SB },
  { name: _startCase(CUSTOMS_DOCUMENT_TYPE_BE), key: CUSTOMS_DOCUMENT_TYPE_BE },
];

export const INPUT_COMPONENT = 'INPUT_COMPONENT';
export const INPUTNUMBER_COMPONENT = 'INPUTNUMBER_COMPONENT';
export const SELECT_COMPONENT = 'SELECT_COMPONENT';
export const DATE_COMPONENT = 'DATE_COMPONENT';
export const BLANK_COMPONENT = 'BLANK_COMPONENT';
export const VESSEL_SEARCH_COMPONENT = 'VESSEL_SEARCH_COMPONENTs';

export const SERVICE_CLEARANCE = 'clearance';
export const SERVICE_TRANSPORT = 'transport';
export const SERVICE_PALLETIZATION = 'palletization';
export const SERVICE_FUMIGATION = 'fumigation';
export const SERVICE_CARGO_INSURANCE = 'cargo_insurance';
export const SERVICE_EMPTY_CONTAINER_INSURANCE = 'empty_container_insurance';
export const SERVICE_TYPE_ORIGIN_CLEARANCE = 'origin_custom_clearance';
export const SERVICE_TYPE_DESTINATION_CLEARANCE = 'destination_custom_clearance';
export const SERVICE_TYPE_ORIGIN_TRANSPORT = 'origin_transport';
export const SERVICE_TYPE_DESTINATION_TRANSPORT = 'destination_transport';
export const SERVICE_EMPTY_WAREHOUSE = 'warehouse';
export const SERVICE_EMPTY_LIVE_TRACKING = 'live_tracking';

export const DESTINATION_SERVICES = [SERVICE_CLEARANCE, SERVICE_TRANSPORT];
export const ORIGIN_SERVICES = [
  SERVICE_CLEARANCE,
  SERVICE_TRANSPORT,
  SERVICE_PALLETIZATION,
  SERVICE_FUMIGATION,
];

export const EXTRA_SERVICES_AIR = [SERVICE_CARGO_INSURANCE];
export const EXTRA_SERVICES_OCEAN = [SERVICE_CARGO_INSURANCE, SERVICE_EMPTY_CONTAINER_INSURANCE];

export const DESTINATION_SERVICES_FOR_CHECKBOX = [
  { key: SERVICE_CLEARANCE, name: _startCase(SERVICE_CLEARANCE) },
  { key: SERVICE_TRANSPORT, name: _startCase(SERVICE_TRANSPORT) },
];

export const ORIGIN_SERVICES_FOR_CHECKBOX = [
  ...DESTINATION_SERVICES_FOR_CHECKBOX,
  { key: SERVICE_PALLETIZATION, name: _startCase(SERVICE_PALLETIZATION) },
  { key: SERVICE_FUMIGATION, name: _startCase(SERVICE_FUMIGATION) },
];

export const SHIPMENT_SERVICES = {
  [SERVICE_TYPE_ORIGIN]: ORIGIN_SERVICES,
  [SERVICE_TYPE_DESTINATION]: DESTINATION_SERVICES,
};

export const AIR_PLANNED_OR_HOUSE_SHIPMENT_CANCELLED_BY = [
  { key: 'shipper', name: 'Shipper' },
  { key: 'consignee', name: 'Consignee' },
];

export const SHIPMENT_CANCELLED_BY = [
  { key: 'airline', name: 'Airline' },
  { key: 'overseas_agent', name: 'Overseas Agent' },
  ...AIR_PLANNED_OR_HOUSE_SHIPMENT_CANCELLED_BY,
];

export const SHIPMENT_RETURNED_FROM = [
  { key: 'origin', name: 'Origin' },
  { key: 'destination', name: 'Destination' },
];

export const AIR_PLANNED_CANCELLATION_REASONS = [
  { key: 'Increase In Rates', name: 'Increase In Rates' },
  { key: 'Cargo Not Ready', name: 'Cargo Not Ready' },
  { key: 'Cargo Rejected', name: 'Cargo Rejected' },
  { key: 'Other', name: 'Other' },
];

export const BACK_TO_TOWN_REASONS = [
  { key: 'Cargo Rejected', name: 'Cargo Rejected' },
  { key: 'Cargo Not Collected', name: 'Cargo Not Collected' },
  { key: 'Cargo Damaged', name: 'Cargo Damaged' },
  { key: 'Other', name: 'Other' },
];

export const AIR_CANCELLATION_REASONS = [
  { key: 'No Space Available', name: 'No Space Available' },
  ...AIR_PLANNED_CANCELLATION_REASONS,
];

export const SURRENDER_LOCATION_ORIGIN = 'origin';
export const SURRENDER_LOCATION_DESTINATION = 'destination';
export const SURRENDER_LOCATIONS = [
  { name: _startCase(SURRENDER_LOCATION_ORIGIN), key: SURRENDER_LOCATION_ORIGIN },
  { name: _startCase(SURRENDER_LOCATION_DESTINATION), key: SURRENDER_LOCATION_DESTINATION },
];
export const EVENT_IMPORT_LINE_NUMBER = 'import_line_number';
export const EVENT_BANK_RELEASE_ORDER = 'bank_release_order';
export const EVENT_CLEARANCE = 'origin_clearance';

export const DEFAULT_LABEL_IGM = 'Import Manifest';
export const DEFAULT_LABEL_ICD_IGM = 'Inland Import Manifest';
export const DEFAULT_LABEL_BOE = 'Import Customs Declaration';
export const DEFAULT_LABEL_AMS = 'Advance Manifest';

export const ULD_TYPE_AKE = { label: 'AKE', value: 'AKE', type: 'container' };
export const ULD_TYPE_DPE = { label: 'DPE', value: 'DPE', type: 'container' };
export const ULD_TYPE_ALF = { label: 'ALF', value: 'ALF', type: 'container' };
export const ULD_TYPE_AMA = { label: 'AMA', value: 'AMA', type: 'container' };
export const ULD_TYPE_AMF = { label: 'AMF', value: 'AMF', type: 'container' };
export const ULD_TYPE_AAP = { label: 'AAP', value: 'AAP', type: 'container' };

export const ULD_TYPE_RAU = { label: 'RAU', value: 'RAU', type: 'environtainer' };
export const ULD_TYPE_RKN = { label: 'RKN', value: 'RKN', type: 'environtainer' };
export const ULD_TYPE_RAP = { label: 'RAP', value: 'RAP', type: 'environtainer' };

export const ULD_TYPE_PLA = { label: 'PLA', value: 'PLA', type: 'pallet' };
export const ULD_TYPE_PNA = { label: 'PNA', value: 'PNA', type: 'pallet' };
export const ULD_TYPE_PMC = { label: 'PMC', value: 'PMC', type: 'pallet' };
export const ULD_TYPE_PQP = { label: 'PQP', value: 'PQP', type: 'pallet' };
export const ULD_TYPE_PEB = { label: 'PEB', value: 'PEB', type: 'pallet' };

export const LOOSE_TYPE_EXTRA = { key: 'extra', Name: 'Extra' };

export const ULD_TYPES = [
  ULD_TYPE_AKE,
  ULD_TYPE_DPE,
  ULD_TYPE_ALF,
  ULD_TYPE_AMA,
  ULD_TYPE_AMF,
  ULD_TYPE_AAP,
  ULD_TYPE_RAU,
  ULD_TYPE_RKN,
  ULD_TYPE_RAP,
  ULD_TYPE_PLA,
  ULD_TYPE_PNA,
  ULD_TYPE_PMC,
  ULD_TYPE_PQP,
  ULD_TYPE_PEB,
];

export const PACKAGE_TYPE_BOX = { name: 'Box', key: 'box' };
export const PACKAGE_TYPE_PALLET = { name: 'Pallet', key: 'pallet' };
export const PACKAGE_TYPE_DRUM = { name: 'Drum', key: 'drum' };
export const PACKAGE_TYPE_BAG = { name: 'Bag', key: 'bag' };
export const PACKAGE_TYPE_ROLL = { name: 'Roll', key: 'roll' };
export const PACKAGE_TYPE_CARTON = { name: 'Carton', key: 'carton' };
export const PACKAGE_TYPE_PACKAGES = { name: 'Packages', key: 'packages' };
export const PACKAGE_TYPE_CRATE = { name: 'Crate', key: 'crate' };
export const PACKAGE_TYPE_SKID = { name: 'Skid', key: 'skid' };
export const PACKAGE_TYPE_CASE = { name: 'Case', key: 'case' };
export const PACKAGE_TYPE_BARREL = { name: 'Barrel', key: 'barrel' };
export const PACKAGE_TYPE_BALE = { name: 'Bale', key: 'bale' };
export const PACKAGE_TYPE_BUNDLE = { name: 'Bundle', key: 'bundle' };
export const PACKAGE_TYPE_BULK = { name: 'Bulk', key: 'bulk' };

export const packageTypes = [
  PACKAGE_TYPE_BOX,
  PACKAGE_TYPE_PALLET,
  PACKAGE_TYPE_DRUM,
  PACKAGE_TYPE_BAG,
  PACKAGE_TYPE_ROLL,
  PACKAGE_TYPE_CARTON,
  PACKAGE_TYPE_PACKAGES,
  PACKAGE_TYPE_CRATE,
  PACKAGE_TYPE_SKID,
  PACKAGE_TYPE_CASE,
  PACKAGE_TYPE_BARREL,
  PACKAGE_TYPE_BALE,
  PACKAGE_TYPE_BUNDLE,
  PACKAGE_TYPE_BULK,
];

export const oceanPackageTypes = [
  { name: 'Aerosol', key: 'Aerosol' },
  { name: 'Ammo Pack', key: 'Ammo Pack' },
  { name: 'Ampoule,non-protected', key: 'Ampoule,non-protected' },
  { name: 'Ampoule,protected', key: 'Ampoule,protected' },
  { name: 'Atomizer', key: 'Atomizer' },
  { name: 'Bag', key: 'Bag' },
  { name: 'PP Bag', key: 'PP Bag' },
  { name: 'Bag, large', key: 'Bag, large' },
  { name: 'Bag, paper', key: 'Bag, paper' },
  { name: 'Bag, paper, multi-wall', key: 'Bag, paper, multi-wall' },
  { name: 'Bag,paper,multiwall,H2O resis', key: 'Bag,paper,multiwall,H2O resis' },
  { name: 'Bag, plastic', key: 'Bag, plastic' },
  { name: 'Bag, plastics film', key: 'Bag, plastics film' },
  { name: 'Bag, textile', key: 'Bag, textile' },
  { name: 'Bag, textile, sift proof', key: 'Bag, textile, sift proof' },
  { name: 'Bag, textile, water resistant', key: 'Bag, textile, water resistant' },
  { name: 'Bag,text,no inner coat/liner', key: 'Bag,text,no inner coat/liner' },
  { name: 'Bag, woven plastic', key: 'Bag, woven plastic' },
  { name: 'Bag, woven plastic, sift proof', key: 'Bag, woven plastic, sift proof' },
  { name: 'Bag, woven plastic, water resistant', key: 'Bag, woven plastic, water resistant' },
  { name: 'Bag,woven plstc,no inner coat/lin', key: 'Bag,woven plstc,no inner coat/lin' },
  { name: 'Bale', key: 'Bale' },
  { name: 'Bale,compressed', key: 'Bale,compressed' },
  { name: 'Bale,non-compressed', key: 'Bale,non-compressed' },
  { name: 'Balloon,non-protected', key: 'Balloon,non-protected' },
  { name: 'Balloon,protected', key: 'Balloon,protected' },
  { name: 'Bar', key: 'Bar' },
  { name: 'Barge', key: 'Barge' },
  { name: 'Barrel', key: 'Barrel' },
  { name: 'Barrel, wooden', key: 'Barrel, wooden' },
  { name: 'Barrel, wooden, bung type', key: 'Barrel, wooden, bung type' },
  { name: 'Barrel, wooden, removable head', key: 'Barrel, wooden, removable head' },
  { name: 'Bars,inbundle/bunch/truss', key: 'Bars,inbundle/bunch/truss' },
  { name: 'Basin', key: 'Basin' },
  { name: 'Basket or hamper', key: 'Basket or hamper' },
  { name: 'Basket, with handle, cardboard', key: 'Basket, with handle, cardboard' },
  { name: 'Basket, with handle, plastic', key: 'Basket, with handle, plastic' },
  { name: 'Basket, with handle, wooden', key: 'Basket, with handle, wooden' },
  { name: 'Beam', key: 'Beam' },
  { name: 'Bin', key: 'Bin' },
  { name: 'Bing Chest', key: 'Bing Chest' },
  { name: 'Blocks', key: 'Blocks' },
  { name: 'Board', key: 'Board' },
  { name: 'Board,inbundle/bunch/truss', key: 'Board,inbundle/bunch/truss' },
  { name: 'Bobbin', key: 'Bobbin' },
  { name: 'Bolt', key: 'Bolt' },
  { name: 'Bottle', key: 'Bottle' },
  { name: 'Bottle, Gas', key: 'Bottle, Gas' },
  { name: 'Bottle,non-protected,bulbous', key: 'Bottle,non-protected,bulbous' },
  { name: 'Bottle,non-protected,cylindrical', key: 'Bottle,non-protected,cylindrical' },
  { name: 'Bottle,protectedbulbous', key: 'Bottle,protectedbulbous' },
  { name: 'Bottle,protectedcylindrical', key: 'Bottle,protectedcylindrical' },
  { name: 'Bottlecrate,bottlerack', key: 'Bottlecrate,bottlerack' },
  { name: 'Box', key: 'Box' },
  { name: 'Box, aluminium', key: 'Box, aluminium' },
  { name: 'Box,CHEP,Eurobox', key: 'Box,CHEP,Eurobox' },
  { name: 'Box, fibreboard', key: 'Box, fibreboard' },
  { name: 'Box, for liquids', key: 'Box, for liquids' },
  { name: 'Box, natural wood', key: 'Box, natural wood' },
  { name: 'Box, plastic', key: 'Box, plastic' },
  { name: 'Box, plastic, expanded', key: 'Box, plastic, expanded' },
  { name: 'Box, plastic, solid', key: 'Box, plastic, solid' },
  { name: 'Box, plywood', key: 'Box, plywood' },
  { name: 'Box, reconstituted wood', key: 'Box, reconstituted wood' },
  { name: 'Box, steel', key: 'Box, steel' },
  { name: 'Box, with inner container', key: 'Box, with inner container' },
  { name: 'Box, wooden, natural wood, ordinary', key: 'Box, wooden, natural wood, ordinary' },
  { name: 'Box,woodn,nat. wood,siftproof wall', key: 'Box,woodn,nat. wood,siftproof wall' },
  { name: 'Bucket', key: 'Bucket' },
  { name: 'Bulk', key: 'Bulk' },
  { name: 'Bulk Bag', key: 'Bulk Bag' },
  { name: 'Bulk,gas(at1031mbarand15 C)', key: 'Bulk,gas(at1031mbarand15 C)' },
  { name: 'Bulk,liq gas', key: 'Bulk,liq gas' },
  { name: 'Bulk,sol,fine partcls-powder', key: 'Bulk,sol,fine partcls-powder' },
  { name: 'Bulk,solid,granular particles-grain', key: 'Bulk,solid,granular particles-grain' },
  { name: 'Bulk,solid,granular partcls-nodules', key: 'Bulk,solid,granular partcls-nodules' },
  { name: 'Bunch', key: 'Bunch' },
  { name: 'Bundle', key: 'Bundle' },
  { name: 'Butt', key: 'Butt' },
  { name: 'Cabinet', key: 'Cabinet' },
  { name: 'Cage', key: 'Cage' },
  { name: 'Cage,CHEP', key: 'Cage,CHEP' },
  { name: 'Cage, roll', key: 'Cage, roll' },
  { name: 'Can', key: 'Can' },
  { name: 'Can Case', key: 'Can Case' },
  { name: 'Can, with handle and spout', key: 'Can, with handle and spout' },
  { name: 'Can,cylindrical', key: 'Can,cylindrical' },
  { name: 'Can,rectangular', key: 'Can,rectangular' },
  { name: 'Canister', key: 'Canister' },
  { name: 'Canvas', key: 'Canvas' },
  { name: 'Car Load, Rail', key: 'Car Load, Rail' },
  { name: 'Carboy', key: 'Carboy' },
  { name: 'Carboy,non-protected', key: 'Carboy,non-protected' },
  { name: 'Carboy,protected', key: 'Carboy,protected' },
  { name: 'Carrier', key: 'Carrier' },
  { name: 'Carton', key: 'Carton' },
  { name: 'Case', key: 'Case' },
  { name: 'Case, isothermic', key: 'Case, isothermic' },
  { name: 'Case, skeleton', key: 'Case, skeleton' },
  { name: 'Case, with pallet base', key: 'Case, with pallet base' },
  { name: 'Case, with pallet base, cardboard', key: 'Case, with pallet base, cardboard' },
  { name: 'Case, with pallet base, metal', key: 'Case, with pallet base, metal' },
  { name: 'Case, with pallet base, plastic', key: 'Case, with pallet base, plastic' },
  { name: 'Case, with pallet base, wooden', key: 'Case, with pallet base, wooden' },
  { name: 'Cask', key: 'Cask' },
  { name: 'Cheeses', key: 'Cheeses' },
  { name: 'Chest', key: 'Chest' },
  { name: 'Churn', key: 'Churn' },
  { name: 'Coffer', key: 'Coffer' },
  { name: 'Coffin', key: 'Coffin' },
  { name: 'Coil', key: 'Coil' },
  { name: 'Cmp Pack,glass rcpt', key: 'Cmp Pack,glass rcpt' },
  { name: 'Cmp Pack,glass rcpt in alum drum', key: 'Cmp Pack,glass rcpt in alum drum' },
  { name: 'Cmp Pack,glass rcpt,exnd plstc pck', key: 'Cmp Pack,glass rcpt,exnd plstc pck' },
  { name: 'Cmp Pack,glass rcpt in fibre drum', key: 'Cmp Pack,glass rcpt in fibre drum' },
  { name: 'Cmp Pack,glass rcpt in fibreb box', key: 'Cmp Pack,glass rcpt in fibreb box' },
  { name: 'Cmp Pack,glass rcpt in plywd drum', key: 'Cmp Pack,glass rcpt in plywd drum' },
  { name: 'Cmp Pack,glass rcpt, sld plstc pck', key: 'Cmp Pack,glass rcpt, sld plstc pck' },
  { name: 'Cmp Pack,glass rcpt,steel crt box', key: 'Cmp Pack,glass rcpt,steel crt box' },
  { name: 'Cmp Pack,glass rcpt in steel drum', key: 'Cmp Pack,glass rcpt in steel drum' },
  { name: 'Cmp Pack,glass rcpt,wickwork hamp', key: 'Cmp Pack,glass rcpt,wickwork hamp' },
  { name: 'Cmp Pack,glass rcpt in wooden box', key: 'Cmp Pack,glass rcpt in wooden box' },
  { name: 'Cmp Pack,plastic rcpt', key: 'Cmp Pack,plastic rcpt' },
  { name: 'Cmp Pack,plastic rcpt, alum crate', key: 'Cmp Pack,plastic rcpt, alum crate' },
  { name: 'Cmp Pack,plastic rcpt in alum drum', key: 'Cmp Pack,plastic rcpt in alum drum' },
  { name: 'Cmp Pack,plastic rcpt,fibre drum', key: 'Cmp Pack,plastic rcpt,fibre drum' },
  { name: 'Cmp Pack,plastic rcpt in fireb box', key: 'Cmp Pack,plastic rcpt in fireb box' },
  { name: 'Cmp Pack,plastic rcpt,plstc drum', key: 'Cmp Pack,plastic rcpt,plstc drum' },
  { name: 'Cmp Pack,plastic rcpt in plywd box', key: 'Cmp Pack,plastic rcpt in plywd box' },
  { name: 'Cmp Pack,plastic rcpt,plywd drum', key: 'Cmp Pack,plastic rcpt,plywd drum' },
  { name: 'Cmp Pack,plstc rcpt,solid plstc box', key: 'Cmp Pack,plstc rcpt,solid plstc box' },
  { name: 'Cmp Pack,plstc rcpt,steel crate box', key: 'Cmp Pack,plstc rcpt,steel crate box' },
  { name: 'Cmp Pack,plstc rcpt,steel drum', key: 'Cmp Pack,plstc rcpt,steel drum' },
  { name: 'Cmp Pack,plstc rcpt,in wooden box', key: 'Cmp Pack,plstc rcpt,in wooden box' },
  { name: 'Cones', key: 'Cones' },
  { name: 'CONEX - Container Express', key: 'CONEX - Container Express' },
  { name: 'ctr,not specif transport equipment', key: 'ctr,not specif transport equipment' },
  { name: 'Container, Commercial Highway Lift', key: 'Container, Commercial Highway Lift' },
  {
    name: 'Container, MAC-ISO Light Weight 8x8x20 Foot Air',
    key: 'Container, MAC-ISO Light Weight 8x8x20 Foot Air',
  },
  { name: 'Container, Navy Cargo Transporter', key: 'Container, Navy Cargo Transporter' },
  { name: 'Containers of Bulk Cargo', key: 'Containers of Bulk Cargo' },
  { name: 'Core', key: 'Core' },
  { name: 'Cover', key: 'Cover' },
  { name: 'Cradle', key: 'Cradle' },
  { name: 'Crate', key: 'Crate' },
  { name: 'Crate, beer', key: 'Crate, beer' },
  { name: 'Crate, bulk, cardboard', key: 'Crate, bulk, cardboard' },
  { name: 'Crate, bulk, plastic', key: 'Crate, bulk, plastic' },
  { name: 'Crate, bulk, wooden', key: 'Crate, bulk, wooden' },
  { name: 'Crate, framed', key: 'Crate, framed' },
  { name: 'Crate, fruit', key: 'Crate, fruit' },
  { name: 'Crate, milk', key: 'Crate, milk' },
  { name: 'Crate, multiple layer, cardboard', key: 'Crate, multiple layer, cardboard' },
  { name: 'Crate, multiple layer, plastic', key: 'Crate, multiple layer, plastic' },
  { name: 'Crate, multiple layer, wooden', key: 'Crate, multiple layer, wooden' },
  { name: 'Crate, shallow', key: 'Crate, shallow' },
  { name: 'Creel', key: 'Creel' },
  { name: 'Cube', key: 'Cube' },
  { name: 'Cup', key: 'Cup' },
  { name: 'Cylinder', key: 'Cylinder' },
  { name: 'Demijohn,non-protected', key: 'Demijohn,non-protected' },
  { name: 'Demijohn,protected', key: 'Demijohn,protected' },
  { name: 'Dispenser', key: 'Dispenser' },
  { name: 'Double-length Rack', key: 'Double-length Rack' },
  { name: 'Double-length Skid', key: 'Double-length Skid' },
  { name: 'Double-length Tote Bin', key: 'Double-length Tote Bin' },
  { name: 'Drum', key: 'Drum' },
  { name: 'Drum, aluminium', key: 'Drum, aluminium' },
  { name: 'Drum, aluminium, non-removable head', key: 'Drum, aluminium, non-removable head' },
  { name: 'Drum, aluminium, removable head', key: 'Drum, aluminium, removable head' },
  { name: 'Drum, fibre', key: 'Drum, fibre' },
  { name: 'Drum, iron', key: 'Drum, iron' },
  { name: 'Drum, plastic', key: 'Drum, plastic' },
  { name: 'Drum, plastic, non-removable head', key: 'Drum, plastic, non-removable head' },
  { name: 'Drum, plastic, removable head', key: 'Drum, plastic, removable head' },
  { name: 'Drum, plywood', key: 'Drum, plywood' },
  { name: 'Drum, steel', key: 'Drum, steel' },
  { name: 'Drum, steel, non-removable head', key: 'Drum, steel, non-removable head' },
  { name: 'Jerrican,plstc,no remov head (DG)', key: 'Jerrican,plstc,no remov head (DG)' },
  { name: 'Drum, steel, removable head', key: 'Drum, steel, removable head' },
  { name: 'Dry Bulk', key: 'Dry Bulk' },
  { name: 'Duffle Bag', key: 'Duffle Bag' },
  { name: 'Engine Container', key: 'Engine Container' },
  { name: 'Envelope', key: 'Envelope' },
  { name: 'Filmpack', key: 'Filmpack' },
  { name: 'Firkin', key: 'Firkin' },
  { name: 'Flask', key: 'Flask' },
  { name: 'Flo-bin', key: 'Flo-bin' },
  { name: 'Foodtainer', key: 'Foodtainer' },
  { name: 'Footlocker', key: 'Footlocker' },
  { name: 'Forward Reel', key: 'Forward Reel' },
  { name: 'Frame', key: 'Frame' },
  { name: 'Garments on Hangers', key: 'Garments on Hangers' },
  { name: 'Girder', key: 'Girder' },
  { name: 'Girders,inbundle/bunch/truss', key: 'Girders,inbundle/bunch/truss' },
  { name: 'Half-standard Rack', key: 'Half-standard Rack' },
  { name: 'Half-Standard Tote Bin', key: 'Half-Standard Tote Bin' },
  { name: 'Hamper', key: 'Hamper' },
  { name: 'Heads of Beef', key: 'Heads of Beef' },
  { name: 'Hogshead', key: 'Hogshead' },
  { name: 'Hopper Truck', key: 'Hopper Truck' },
  { name: 'Household Goods Containers, Wood', key: 'Household Goods Containers, Wood' },
  { name: 'Ingot', key: 'Ingot' },
  { name: 'Ingots,inbundle/bunch/truss', key: 'Ingots,inbundle/bunch/truss' },
  { name: 'Intermediate bulk container', key: 'Intermediate bulk container' },
  { name: 'Intermediate bulk ctr,alum', key: 'Intermediate bulk ctr,alum' },
  { name: 'Intermediate bulk ctr,alum,liquid', key: 'Intermediate bulk ctr,alum,liquid' },
  { name: 'Int bulk ctr,alum,liqu,press>10', key: 'Int bulk ctr,alum,liqu,press>10' },
  { name: 'Int bulk ctr,compos,flex plstc,liq', key: 'Int bulk ctr,compos,flex plstc,liq' },
  { name: 'Int bulk ctr,compos,flex plstc,pres', key: 'Int bulk ctr,compos,flex plstc,pres' },
  { name: 'Int bulk ctr,compos,flex plstc,sol', key: 'Int bulk ctr,compos,flex plstc,sol' },
  { name: 'Int bulk ctr,compos,rigid plstc,sol', key: 'Int bulk ctr,compos,rigid plstc,sol' },
  { name: 'Int bulk ctr,compos,rigd plstc,pres', key: 'Int bulk ctr,compos,rigd plstc,pres' },
  { name: 'Int bulk ctr,compos,rigd plstc,sol', key: 'Int bulk ctr,compos,rigd plstc,sol' },
  { name: 'Intermediate bulk container, metal', key: 'Intermediate bulk container, metal' },
  { name: 'Int bulk ctr,metal,liquid', key: 'Int bulk ctr,metal,liquid' },
  { name: 'Int bulk ctr,metal,press 10kpa', key: 'Int bulk ctr,metal,press 10kpa' },
  { name: 'Int bulk ctr,nat wood,inner liner', key: 'Int bulk ctr,nat wood,inner liner' },
  { name: 'Int bulk ctr,paper,multiwall', key: 'Int bulk ctr,paper,multiwall' },
  { name: 'Int bulk ctr,papr,multiwall,H2O res', key: 'Int bulk ctr,papr,multiwall,H2O res' },
  { name: 'Int bulk ctr,plstc film', key: 'Int bulk ctr,plstc film' },
  { name: 'Int bulk ctr,plywood,inner liner', key: 'Int bulk ctr,plywood,inner liner' },
  { name: 'Int bulk ctr,recnst wood,inn liner', key: 'Int bulk ctr,recnst wood,inn liner' },
  { name: 'Int Bulk ctr,rigid plstc,stand,liq', key: 'Int Bulk ctr,rigid plstc,stand,liq' },
  { name: 'Int Bulk ctr,rigid plstc,stand,pres', key: 'Int Bulk ctr,rigid plstc,stand,pres' },
  { name: 'Int bulk ctr,rigid plstc,stand,sol', key: 'Int bulk ctr,rigid plstc,stand,sol' },
  { name: 'Int Bulk ctr,rig plstc,stru eq,liq', key: 'Int Bulk ctr,rig plstc,stru eq,liq' },
  { name: 'Int Bulk ctr,rig plstc,stru eq,pres', key: 'Int Bulk ctr,rig plstc,stru eq,pres' },
  { name: 'Int Bulk ctr,rig plstc,stru eq,sol', key: 'Int Bulk ctr,rig plstc,stru eq,sol' },
  { name: 'Intermediate bulk container, steel', key: 'Intermediate bulk container, steel' },
  { name: 'Int Bulk ctr,steel,liquid', key: 'Int Bulk ctr,steel,liquid' },
  { name: 'Int Bulk ctr,steel,press>10kpa', key: 'Int Bulk ctr,steel,press>10kpa' },
  { name: 'Int Bulk ctr,text,no out coat/liner', key: 'Int Bulk ctr,text,no out coat/liner' },
  { name: 'Int Bulk ctr,text,coated', key: 'Int Bulk ctr,text,coated' },
  { name: 'Int Bulk ctr,text,coated and liner', key: 'Int Bulk ctr,text,coated and liner' },
  { name: 'Int Bulk ctr,text,liner', key: 'Int Bulk ctr,text,liner' },
  { name: 'Int Bulk ctr,woven plstc,coated', key: 'Int Bulk ctr,woven plstc,coated' },
  { name: 'Int Bulk ctr,woven plstc,coated/lin', key: 'Int Bulk ctr,woven plstc,coated/lin' },
  { name: 'Int Bulk ctr,woven plstc w/ liner', key: 'Int Bulk ctr,woven plstc w/ liner' },
  { name: 'IntBulk ctr,wov plstc,w/o coat/lin', key: 'IntBulk ctr,wov plstc,w/o coat/lin' },
  {
    name: 'Intermodal Trailer/Container Load (Rail)',
    key: 'Intermodal Trailer/Container Load (Rail)',
  },
  { name: 'Jar', key: 'Jar' },
  { name: 'Jerrican, plastic', key: 'Jerrican, plastic' },
  { name: 'Jerrican,plstc,no remov head', key: 'Jerrican,plstc,no remov head' },
  { name: 'Jerrican, plastic, removable head', key: 'Jerrican, plastic, removable head' },
  { name: 'Jerrican, steel', key: 'Jerrican, steel' },
  { name: 'Jerrican, steel, non-removable head', key: 'Jerrican, steel, non-removable head' },
  { name: 'Jerrican, steel, removable head', key: 'Jerrican, steel, removable head' },
  { name: 'Jerrican,cylindrical', key: 'Jerrican,cylindrical' },
  { name: 'Jerrican,rectangular', key: 'Jerrican,rectangular' },
  { name: 'Jug', key: 'Jug' },
  { name: 'Jutebag', key: 'Jutebag' },
  { name: 'Keg', key: 'Keg' },
  { name: 'Kit', key: 'Kit' },
  { name: 'Knockdown Rack', key: 'Knockdown Rack' },
  { name: 'Knockdown Tote Bin', key: 'Knockdown Tote Bin' },
  { name: 'Lift Van', key: 'Lift Van' },
  { name: 'Lifts', key: 'Lifts' },
  { name: 'Liner Bag Dry', key: 'Liner Bag Dry' },
  { name: 'Liner Bag Liquid', key: 'Liner Bag Liquid' },
  { name: 'Liquid Bulk', key: 'Liquid Bulk' },
  { name: 'Log', key: 'Log' },
  { name: 'Logs,inbundle/bunch/truss', key: 'Logs,inbundle/bunch/truss' },
  { name: 'Loose', key: 'Loose' },
  { name: 'Lot', key: 'Lot' },
  { name: 'Lug', key: 'Lug' },
  { name: 'Mat', key: 'Mat' },
  { name: 'Matchbox', key: 'Matchbox' },
  { name: 'MILVAN - Military Van', key: 'MILVAN - Military Van' },
  { name: 'Mixed Type Pack', key: 'Mixed Type Pack' },
  { name: 'MSCVAN - Military Sealift Command Van', key: 'MSCVAN - Military Sealift Command Van' },
  { name: 'Multiplybag', key: 'Multiplybag' },
  { name: 'Multi-Roll Pack', key: 'Multi-Roll Pack' },
  {
    name: 'Multiwall Container Secured to Warehouse Pallet',
    key: 'Multiwall Container Secured to Warehouse Pallet',
  },
  { name: 'Mutually defined', key: 'Mutually defined' },
  { name: 'Nest', key: 'Nest' },
  { name: 'Net', key: 'Net' },
  { name: 'Net, tube, plastic', key: 'Net, tube, plastic' },
  { name: 'Net, tube, textile', key: 'Net, tube, textile' },
  { name: 'Noil', key: 'Noil' },
  { name: 'Not available', key: 'Not available' },
  { name: 'On Hanger or Rack in Boxes', key: 'On Hanger or Rack in Boxes' },
  { name: 'On Own Wheel', key: 'On Own Wheel' },
  { name: 'Overwrap', key: 'Overwrap' },
  { name: 'Package', key: 'Package' },
  { name: 'Package, display, cardboard', key: 'Package, display, cardboard' },
  { name: 'Package, display, metal', key: 'Package, display, metal' },
  { name: 'Package, display, plastic', key: 'Package, display, plastic' },
  { name: 'Package, display, wooden', key: 'Package, display, wooden' },
  { name: 'Package, flow', key: 'Package, flow' },
  { name: 'Package, paper wrapped', key: 'Package, paper wrapped' },
  { name: 'Package, show', key: 'Package, show' },
  { name: 'Packed - not otherwise specified', key: 'Packed - not otherwise specified' },
  { name: 'Packet', key: 'Packet' },
  { name: 'Pail', key: 'Pail' },
  { name: 'Pallet', key: 'Pallet' },
  { name: 'Pallet, box', key: 'Pallet, box' },
  { name: 'Pallet,modul,collar 80cm*100cm', key: 'Pallet,modul,collar 80cm*100cm' },
  { name: 'Pallet,modul,collar 80cm*120cm', key: 'Pallet,modul,collar 80cm*120cm' },
  { name: 'Parcel', key: 'Parcel' },
  { name: 'Pieces', key: 'Pieces' },
  { name: 'Pims', key: 'Pims' },
  { name: 'Pipe', key: 'Pipe' },
  { name: 'Pipe Rack', key: 'Pipe Rack' },
  { name: 'Pipeline', key: 'Pipeline' },
  { name: 'Pipes, in bundle/bunch/truss', key: 'Pipes, in bundle/bunch/truss' },
  { name: 'Pitcher', key: 'Pitcher' },
  { name: 'Plank', key: 'Plank' },
  { name: 'Planks,inbundle/bunch/truss', key: 'Planks,inbundle/bunch/truss' },
  { name: 'Plate', key: 'Plate' },
  { name: 'Plates,inbundle/bunch/truss', key: 'Plates,inbundle/bunch/truss' },
  { name: 'Platform', key: 'Platform' },
  { name: 'Pot', key: 'Pot' },
  { name: 'Pouch', key: 'Pouch' },
  { name: 'Private Vehicle', key: 'Private Vehicle' },
  { name: 'Quarter of Beef', key: 'Quarter of Beef' },
  { name: 'Rack', key: 'Rack' },
  { name: 'Rack, clothing hanger', key: 'Rack, clothing hanger' },
  { name: 'Rail (Semiconductor)', key: 'Rail (Semiconductor)' },
  { name: 'Receptacle, glass', key: 'Receptacle, glass' },
  { name: 'Receptacle, metal', key: 'Receptacle, metal' },
  { name: 'Receptacle, plastic', key: 'Receptacle, plastic' },
  { name: 'Receptacle, plastic wrapped', key: 'Receptacle, plastic wrapped' },
  { name: 'Rednet', key: 'Rednet' },
  { name: 'Reel', key: 'Reel' },
  { name: 'Reverse Reel', key: 'Reverse Reel' },
  { name: 'Ring', key: 'Ring' },
  { name: 'Rod', key: 'Rod' },
  { name: 'Rods,inbundle/bunch/truss', key: 'Rods,inbundle/bunch/truss' },
  { name: 'Roll', key: 'Roll' },
  { name: 'Sachet', key: 'Sachet' },
  { name: 'Sack', key: 'Sack' },
  { name: 'Sack, multi-wall', key: 'Sack, multi-wall' },
  { name: 'Sea-chest', key: 'Sea-chest' },
  { name: 'SEAVAN - Sea Van', key: 'SEAVAN - Sea Van' },
  { name: 'Set', key: 'Set' },
  { name: 'Sheet', key: 'Sheet' },
  { name: 'Sheet, plastic wrapping', key: 'Sheet, plastic wrapping' },
  { name: 'Sheetmetal', key: 'Sheetmetal' },
  { name: 'Sheets,inbundle/bunch/truss', key: 'Sheets,inbundle/bunch/truss' },
  { name: 'Shook', key: 'Shook' },
  { name: 'Shrinkwrapped', key: 'Shrinkwrapped' },
  { name: 'Side of Beef', key: 'Side of Beef' },
  { name: 'Skid', key: 'Skid' },
  { name: 'Skid, elevating or lift truck', key: 'Skid, elevating or lift truck' },
  { name: 'Sleeve', key: 'Sleeve' },
  { name: 'Slip Sheet', key: 'Slip Sheet' },
  { name: 'Spin Cylinders', key: 'Spin Cylinders' },
  { name: 'Spindle', key: 'Spindle' },
  { name: 'Spool', key: 'Spool' },
  { name: 'Suitcase', key: 'Suitcase' },
  { name: 'Tank', key: 'Tank' },
  { name: 'Tank Car', key: 'Tank Car' },
  { name: 'Tank Truck', key: 'Tank Truck' },
  { name: 'Tank,cylindrical', key: 'Tank,cylindrical' },
  { name: 'Tank,rectangular', key: 'Tank,rectangular' },
  { name: 'Tea-chest', key: 'Tea-chest' },
  { name: 'Tierce', key: 'Tierce' },
  { name: 'Tin', key: 'Tin' },
  { name: 'Tote Bin', key: 'Tote Bin' },
  { name: 'Tote Can', key: 'Tote Can' },
  { name: 'Tray', key: 'Tray' },
  { name: 'Tray, one layer no cover, cardboard', key: 'Tray, one layer no cover, cardboard' },
  { name: 'Tray, one layer no cover, plastic', key: 'Tray, one layer no cover, plastic' },
  { name: 'Tray,1 layer no cover,polystyrene', key: 'Tray,1 layer no cover,polystyrene' },
  { name: 'Tray, one layer no cover, wooden', key: 'Tray, one layer no cover, wooden' },
  { name: 'Tray,2 layer no cover,cardbrd', key: 'Tray,2 layer no cover,cardbrd' },
  { name: 'Tray,2 layer no cover,plstc tray', key: 'Tray,2 layer no cover,plstc tray' },
  { name: 'Tray, two layers no cover, wooden', key: 'Tray, two layers no cover, wooden' },
  { name: 'Triwall Box', key: 'Triwall Box' },
  { name: 'Truck', key: 'Truck' },
  { name: 'Trunk', key: 'Trunk' },
  { name: 'Trunk and Chest', key: 'Trunk and Chest' },
  { name: 'Trunk, Salesmen Sample', key: 'Trunk, Salesmen Sample' },
  { name: 'Truss', key: 'Truss' },
  { name: 'Tub', key: 'Tub' },
  { name: 'Tube', key: 'Tube' },
  { name: 'Tube, with nozzle', key: 'Tube, with nozzle' },
  { name: 'Tube,collapsible', key: 'Tube,collapsible' },
  { name: 'Tubes,inbundle/bunch/truss', key: 'Tubes,inbundle/bunch/truss' },
  { name: 'Tun', key: 'Tun' },
  { name: 'Unpacked', key: 'Unpacked' },
  { name: 'Vacuum-packed', key: 'Vacuum-packed' },
  { name: 'Van Pack', key: 'Van Pack' },
  { name: 'Vat', key: 'Vat' },
  { name: 'Vehicles', key: 'Vehicles' },
  { name: 'Vial', key: 'Vial' },
  { name: 'Wheeled Carrier', key: 'Wheeled Carrier' },
  { name: 'Wickerbottle', key: 'Wickerbottle' },
  { name: 'Wrapped', key: 'Wrapped' },
  { name: 'Drum, wooden', key: 'Drum, wooden' },
  { name: 'Bag, super bulk', key: 'Bag, super bulk' },
  { name: 'Int Bulk ctr,rigid plstc', key: 'Int Bulk ctr,rigid plstc' },
  { name: 'Receptacle, fibre', key: 'Receptacle, fibre' },
  { name: 'Receptacle, paper', key: 'Receptacle, paper' },
  { name: 'Receptacle, wooden', key: 'Receptacle, wooden' },
  { name: 'Pallet,modul,collar 80cm*60cm', key: 'Pallet,modul,collar 80cm*60cm' },
  { name: 'Pallet, shrinkwrapped', key: 'Pallet, shrinkwrapped' },
  { name: 'Pallet, 100cms * 110cms', key: 'Pallet, 100cms * 110cms' },
  { name: 'Clamshell', key: 'Clamshell' },
  { name: 'Cone', key: 'Cone' },
  { name: 'Capsule', key: 'Capsule' },
  { name: 'Card', key: 'Card' },
  { name: 'Cartridge', key: 'Cartridge' },
  { name: 'Bag, flexible container', key: 'Bag, flexible container' },
  { name: 'Pack,cardboard,w/bottle grip holes', key: 'Pack,cardboard,w/bottle grip holes' },
  { name: 'Unpacked unpackaged, single unit', key: 'Unpacked unpackaged, single unit' },
  { name: 'Unpackd multple units', key: 'Unpackd multple units' },
  { name: 'Pen', key: 'Pen' },
  { name: 'Punnet', key: 'Punnet' },
  { name: 'Slab', key: 'Slab' },
  { name: 'Case, steel', key: 'Case, steel' },
  { name: 'Envelope, steel', key: 'Envelope, steel' },
  { name: 'Tub, with lid', key: 'Tub, with lid' },
  { name: 'Uncaged', key: 'Uncaged' },
  { name: 'Vanpack', key: 'Vanpack' },
  { name: 'Intermediate bulk ctr,composite', key: 'Intermediate bulk ctr,composite' },
  { name: 'Int bulk ctr,fibreboard', key: 'Int bulk ctr,fibreboard' },
  { name: 'Int bulk ctr,flex', key: 'Int bulk ctr,flex' },
  { name: 'Int bulk ctr,metal,other no steel', key: 'Int bulk ctr,metal,other no steel' },
  { name: 'Int bulk ctr,natural wood', key: 'Int bulk ctr,natural wood' },
  { name: 'Int bulk ctr,plywood', key: 'Int bulk ctr,plywood' },
  { name: 'Int bulk ctr,reconstituted wood', key: 'Int bulk ctr,reconstituted wood' },
  { name: 'Cart, flatbed', key: 'Cart, flatbed' },
  { name: 'Tray,w/ horizont stacked flat itms', key: 'Tray,w/ horizont stacked flat itms' },
  { name: 'Tray,rigd,lid stack-CENTS14482:2002', key: 'Tray,rigd,lid stack-CENTS14482:2002' },
  { name: 'Vehicle', key: 'Vehicle' },
  { name: 'Unit', key: 'Unit' },
  { name: 'Octabin', key: 'Octabin' },
  { name: 'Drum, plastic, non-removable head (DG)', key: 'Drum, plastic, non-removable head (DG)' },
  { name: 'Drum, plastic, removable head (DG)', key: 'Drum, plastic, removable head (DG)' },
  { name: 'Bag,paper,multiwall,H2O resis (DG)', key: 'Bag,paper,multiwall,H2O resis (DG)' },
  { name: 'Bag, gunny', key: 'Bag, gunny' },
  { name: 'Bag, jumbo', key: 'Bag, jumbo' },
  { name: 'Bag, polybag', key: 'Bag, polybag' },
  { name: 'Bag, tote', key: 'Bag, tote' },
  { name: 'Ball', key: 'Ball' },
  { name: 'Belt', key: 'Belt' },
  { name: 'Bulk, scrap metal', key: 'Bulk, scrap metal' },
  { name: 'Bundle, wooden', key: 'Bundle, wooden' },
  { name: 'Case, car', key: 'Case, car' },
  { name: 'Case, wooden', key: 'Case, wooden' },
  { name: 'Container, flexible', key: 'Container, flexible' },
  { name: 'Container, gallon', key: 'Container, gallon' },
  { name: 'Container, metal', key: 'Container, metal' },
  { name: 'Container, outer', key: 'Container, outer' },
  { name: 'Crate, metal', key: 'Crate, metal' },
  { name: 'Crate, wooden', key: 'Crate, wooden' },
  { name: 'Flexibag', key: 'Flexibag' },
  { name: 'Hanger', key: 'Hanger' },
  { name: 'Luggage', key: 'Luggage' },
  { name: 'Pallet, AS 4068-1993', key: 'Pallet, AS 4068-1993' },
  { name: 'Pallet, CHEP 100 cmx120 cm', key: 'Pallet, CHEP 100 cmx120 cm' },
  { name: 'Pallet, CHEP 40cmx60 cm', key: 'Pallet, CHEP 40cmx60 cm' },
  { name: 'Pallet, CHEP 80 cmx120 cm', key: 'Pallet, CHEP 80 cmx120 cm' },
  { name: 'Pallet, ISO T11', key: 'Pallet, ISO T11' },
  { name: 'Pallet, triwall', key: 'Pallet, triwall' },
  { name: 'Pallet, wooden', key: 'Pallet, wooden' },
  { name: 'Pan', key: 'Pan' },
  { name: 'Piece', key: 'Piece' },
  { name: 'Platform,unspecif weigh/dimens', key: 'Platform,unspecif weigh/dimens' },
  { name: 'Tablet', key: 'Tablet' },
  { name: 'Tank container, generic', key: 'Tank container, generic' },
  { name: 'Tyre', key: 'Tyre' },
  { name: 'Drum, steel, removable head (DG)', key: 'Drum, steel, removable head (DG)' },
  { name: 'Container, NOS', key: 'Container, NOS' },
  {
    name: 'Jerrican, steel, non-removable head (DG)',
    key: 'Jerrican, steel, non-removable head (DG)',
  },
  { name: 'Flexitank', key: 'Flexitank' },
  { name: 'Drum, steel, non-removable head (DG)', key: 'Drum, steel, non-removable head (DG)' },
];

export const BASIS_TYPE_CHARGEABLE_WEIGHT = {
  name: 'Chargeable Weight',
  key: 'chargeable_weight',
};

export const BASIS_TYPE_GROSS_WEIGHT = {
  name: 'Gross Weight',
  key: 'gross_weight',
};

export const BASIS_TYPE_SHIPMENT = {
  name: 'Shipment',
  key: 'shipment',
};

export const BASIS_TYPE_GROSS_VOLUME = {
  name: 'Gross Volume',
  key: 'gross_volume',
};

export const BASIS_TYPE_PACKAGES = {
  name: 'Package',
  key: 'total_number_of_packages',
};

export const BASIS_TYPE_ULD = {
  name: 'Uld',
  key: 'uld',
};

export const BASIS_TYPE_CONTAINER = {
  name: 'Container',
  key: 'container',
};

export const BASIS_TYPE_NET_WEIGHT = {
  name: 'Net Weight',
  key: 'net_weight',
};

export const BASIS_TYPE_NET_WEIGHT_MTS = {
  name: 'Net Weight (MTS)',
  key: 'net_weight_mts',
};

export const BASIS_TYPE_TOTAL_ASSESSABLE_VALUE = {
  name: 'Total Assessable Value',
  key: 'total_assessable_value',
};

export const BASIS_TYPE_TOTAL_INVOICE_VALUE = {
  name: 'Total Invoice Value',
  key: 'total_invoice_value',
};

export const BASIS_TYPE_TOTAL_DUTY_VALUE = {
  name: 'Total Duty Value',
  key: 'total_duty_value',
};

export const BASIS_TYPES = [
  BASIS_TYPE_CHARGEABLE_WEIGHT,
  BASIS_TYPE_GROSS_WEIGHT,
  BASIS_TYPE_SHIPMENT,
];

export const RATE_CLASS_MINIMUM = { key: 'M', name: 'Minimum' };
export const RATE_CLASS_QUANTITY = { key: 'Q', name: 'Quantity' };
export const RATE_CLASS_NORMAL = { key: 'N', name: 'Normal' };
export const RATE_CLASS_ULD_FLATE_RATE = { key: 'U', name: 'ULD Flat rate' };
export const RATE_CLASS_RATE_PER_KILOGRAM = { key: 'K', name: 'Rate Per Kilogram' };
export const RATE_CLASS_SPECIFIC_COMMODITY_RATE = { key: 'C', name: 'Specific Commodity Rate' };
export const RATE_CLASS_BASIC_CHARGE = { key: 'B', name: 'Basic Charge' };
export const RATE_CLASS_CLASS_RATE_REDUCTION = { key: 'R', name: 'Class Rate Reduction' };
export const RATE_CLASS_CLASS_RATE_SURCHARGE = { key: 'S', name: 'Class Rate Surcharge' };
export const RATE_CLASS_INTERNATIONAL_PRIORITY_SERVICE_RATE = {
  key: 'P',
  name: 'International Priority Service Rate',
};
export const RATE_CLASS_UNIT_LOAD_DEVICE_ADDITIONAL_INFO = {
  key: 'X',
  name: 'Unit Load Device Additional Information',
};
export const RATE_CLASS_UNIT_LOAD_DEVICE_ADDITIONAL_RATE = {
  key: 'E',
  name: 'Unit Load Device Additional Rate',
};
export const RATE_CLASS_UNIT_LOAD_DEVICE_BASIC_CHARGE = {
  key: 'U',
  name: 'Unit Load Device Basic Charge',
};
export const RATE_CLASS_UNIT_LOAD_DEVICE_DISCOUNT = { key: 'Y', name: 'Unit Load Device Discount' };

export const LOOSE_RATE_CLASS_TYPE = [
  RATE_CLASS_MINIMUM,
  RATE_CLASS_NORMAL,
  RATE_CLASS_QUANTITY,
  RATE_CLASS_RATE_PER_KILOGRAM,
  RATE_CLASS_SPECIFIC_COMMODITY_RATE,
  RATE_CLASS_BASIC_CHARGE,
  RATE_CLASS_CLASS_RATE_REDUCTION,
  RATE_CLASS_CLASS_RATE_SURCHARGE,
  RATE_CLASS_INTERNATIONAL_PRIORITY_SERVICE_RATE,
];

export const UNIT_LOAD_DEVICE_CLASS_TYPE = [
  RATE_CLASS_UNIT_LOAD_DEVICE_ADDITIONAL_INFO,
  RATE_CLASS_UNIT_LOAD_DEVICE_ADDITIONAL_RATE,
  RATE_CLASS_UNIT_LOAD_DEVICE_BASIC_CHARGE,
  RATE_CLASS_UNIT_LOAD_DEVICE_DISCOUNT,
];

export const SHIPMENT_NUMBER_LABEL = [
  {
    is_master: true,
    freight_type: 'air',
    label: 'MAWB',
  },
  {
    is_master: false,
    freight_type: 'air',
    label: 'HAWB',
  },
  {
    is_master: true,
    freight_type: 'ocean',
    label: 'MBL',
  },
  {
    is_master: false,
    freight_type: 'ocean',
    label: 'HBL',
  },
];

export const DOCUMENT_DISPLAY_MAPPING: { [key: string]: string } = {
  [DOCUMENT_TYPE_HBL]: 'HBL',
  [DOCUMENT_TYPE_MBL]: 'MBL',
  [DOCUMENT_TYPE_NEW_MAWB]: 'MAWB',
  [DOCUMENT_TYPE_NEW_HAWB]: 'HAWB',
};

export const actionNameMap: {
  [key: string]: { buttonLabel: string; messageLabel: string; loadingLabel: string };
} = {
  [DOCUMENT_TYPE_NEW_MAWB]: {
    buttonLabel: 'Reopen MAWB',
    messageLabel: 'Reopened',
    loadingLabel: 'Reopening!',
  },
  [DOCUMENT_TYPE_NEW_HAWB]: {
    buttonLabel: 'Reopen HAWB',
    messageLabel: 'Reopened',
    loadingLabel: 'Reopening!',
  },
  [DOCUMENT_TYPE_HBL]: {
    buttonLabel: 'Unrelease HBL',
    messageLabel: 'Unreleased',
    loadingLabel: 'Unreleasing!',
  },
  [DOCUMENT_TYPE_MBL]: {
    buttonLabel: 'Unrelease MBL',
    messageLabel: 'Unreleased',
    loadingLabel: 'Unreleasing!',
  },
  [DOCUMENT_TYPE_CONSIGNMENT_NOTE]: {
    buttonLabel: 'Unrelease Consignment Note',
    messageLabel: 'Unreleased',
    loadingLabel: 'Unreleasing!',
  },
};

export const ULD_RATE_CLASS_TYPE = [...LOOSE_RATE_CLASS_TYPE, ...UNIT_LOAD_DEVICE_CLASS_TYPE];

export const BOOKING_STATUS_PROCESSED = 'processed';
export const BOOKING_STATUS_CANCELLED = 'cancelled';
export const BOOKING_STATUS_CONFIRMED = 'confirmed';
export const MBL_TAG = 'Master bill of lading';
export const PERMISSION_QUOTATION = 'view';
export const PERMISSION_QUOTES_TAB = 'Quotes Tab';
export const PERMISSION_SHIPMENTS = 'Shipments';
export const PERMISSION_BOOKINGS = 'Bookings';
export const PERMISSION_MIS = 'MIS';
export const PERMISSION_INVOICE_APPROVAL = 'Invoice Approval';
export const PERMISSION_RESERVED_AWB_STOCKS = 'reserve';
export const PERMISSION_AWB_STOCK_SUMMARY = 'summary';
export const PERMISSION_FREIGHT_BUY = 'freight_buy';
export const PERMISSION_FREIGHT_SELL = 'freight_sell';
export const PERMISSION_REBATE_BUY = 'rebate_buy';
export const PERMISSION_REBATE_SELL = 'rebate_sell';
export const PERMISSION_FREIGHT_CONTRACT_PERISHABLE = 'Freight Contract Perishable';
export const PERMISSION_CANCEL_SHIPMENT = 'cancel';
export const PERMISSION_PORT_HANDLING = 'Port Handling';
export const PERMISSION_ACCOUNTING_STATUS = 'accounting_status';
export const PERMISSION_ACCOUNTING_CLOSE = 'accounting_close';
export const PERMISSION_BILLING_CLOSE = 'billing_close';
export const PERMISSION_REOPEN_JOB = 'accounting_reopen';
export const PERMISSION_REOPEN_BILLING = 'billing_reopen';
export const PERMISSION_ESTIMATES = 'Estimates';
export const PERMISSION_ESTIMATES_TOTAL = 'margin';
export const PERMISSION_SHIPMENT_EDIT = 'Edit Shipment';
export const PERMISSION_ISSUE_DO_WITH_BRO = 'issue_do_with_bro';
export const PERMISSION_STOCK_MANAGER = 'Stock Manager';
export const PERMISSION_BL_DRAFT_APPROVE = 'draft_approve';
export const PERMISSION_CANCEL_INQUIRY = 'admin';
export const PERMISSION_BUY_ESTIMATE = 'buy';
export const PERMISSION_SELL_ESTIMATE = 'sell';
export const PERMISSOIN_ADMIN = 'admin';
export const PERMISSION_DELETE_DOCUMENT = 'delete';
export const PORT_TYPE_AIRPORT = 'Airport';
export const PORT_TYPE_SEAPORT = 'Seaport';
export const PORT_TYPE_PORT_TERMINAL = 'PortTerminal';
export const PORT_TYPE_ICD = 'ICD';
export const PROOF_OF_DELIVERY_TAG = 'Proof of delivery';
export const PERMISSION_APPROVER = 'approver';
