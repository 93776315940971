import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Drawer, Input, message, Row, Form } from '@shipmnts/pixel-hub';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { WAREHOUSE_TRANSACTION_ACTIONS } from '../graphql/warehouseTransaction';

interface CancelWMSTransactionDrawerProps {
  transaction: WarehouseTransactionValue;
  onCancel?: () => void;
  onSuccess?: () => void;
}
const { TextArea } = Input;
export default function CancelWMSTransactionDrawer(props: CancelWMSTransactionDrawerProps) {
  const { transaction, onCancel, onSuccess } = props;
  const [form] = Form.useForm();

  const [cancelTransaction, { data, loading, error }] = useMutation(WAREHOUSE_TRANSACTION_ACTIONS);
  const onClose = () => {
    if (onCancel) onCancel();
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      message.error(error.message);
      onClose && onClose();
      return;
    }
    if (data?.warehouse_transaction_actions) {
      message.success('Transaction Successfully Cancelled');
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data, onSuccess]);

  const renderFooter = () => {
    return (
      <div className="drawer-footer">
        <Button style={{ marginRight: '5px' }} onClick={onClose}>
          Close
        </Button>
        <Button type="primary" danger onClick={form.submit} className="margin-right-sm">
          Cancel Transaction
        </Button>
      </div>
    );
  };
  return (
    <Drawer
      title={'Cancel Transaction'}
      width={500}
      onClose={onClose}
      open={true}
      footer={renderFooter()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          cancelTransaction({
            variables: {
              id: transaction?.id,
              event_data: {
                remarks: values.remarks,
              },
              action: 'cancel',
            },
          });
        }}
      >
        <Row style={{ width: '100%' }}>
          <Form.Item
            name="remarks"
            required
            rules={[{ required: true }]}
            style={{ width: '100%' }}
            label="Remarks For Cancellation"
          >
            <TextArea rows={5} style={{ width: '100%' }} />
          </Form.Item>
        </Row>
        <Row className="margin-top-lg"></Row>
      </Form>
    </Drawer>
  );
}
