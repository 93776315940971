import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, message } from '@shipmnts/pixel-hub';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'wouter';

import {
  getGenericInitialValue,
  getInitialRoutingDetails,
  getRoutingLegsPayload,
  getRoutingNodePayload,
} from '../ShipmentForm/helpers';
import FormLayout from '../ShipmentForm/FormLayout';
import { FETCH_SHIPMENT_ROUTE, UPSERT_SHIPMENT_ROUTE } from 'operations/graphql/shipmentRoute';
import ShipmentRoute, { ShipmentRouteValue } from 'operations/models/ShipmentRoute';
import { getShipmentRouteComponent } from './helpers';
import { omit } from 'lodash';
import { FREIGHT_TYPE_ROAD } from '../../constants';

function useQuerySearch(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

// create -> form/route/new
// update -> form/route/id

export default function CreateRoute() {
  const params = useParams<{ id: string }>();
  const [shipmentRoute, setShipmentRoute] = useState<ShipmentRouteValue>();
  const [form] = Form.useForm();
  const query = useQuerySearch();
  const { 1: navigate } = useLocation();
  const { id: shipmentRouteId } = params;
  const queryTradeType = query.get('trade_type');
  const freigthType = query.get('freight_type');
  const [components, setComponents] = useState<any>();

  // graphql
  const [
    upsertShipmentRoute,
    {
      data: upsertShipmentRouteData,
      loading: upsertShipmentRouteLoading,
      error: upsertShipmentRouteError,
    },
  ] = useMutation(UPSERT_SHIPMENT_ROUTE);

  const [fetchRouting, { data: fetchRoutingData }] = useLazyQuery(FETCH_SHIPMENT_ROUTE);

  // fetching data
  useEffect(() => {
    if (shipmentRouteId !== 'new') {
      fetchRouting({ variables: { id: shipmentRouteId } });
    }
  }, [fetchRouting, shipmentRouteId]);

  // setting data
  useEffect(() => {
    if (fetchRoutingData?.get_shipment_route)
      setShipmentRoute(ShipmentRoute.create(fetchRoutingData.get_shipment_route));
  }, [fetchRoutingData]);

  useEffect(() => {
    if (upsertShipmentRouteLoading) return;
    if (upsertShipmentRouteError) {
      message.error(upsertShipmentRouteError.message);
    }
    if (upsertShipmentRouteData?.upsert_shipment_route) {
      message.success(`Route created successfully`);
      navigate('~/workspace?doc_type=Shipment::Route');
    }
  }, [
    upsertShipmentRouteData,
    upsertShipmentRouteLoading,
    upsertShipmentRouteError,
    navigate,
    shipmentRoute,
  ]);

  useEffect(() => {
    setComponents(
      getShipmentRouteComponent({
        form,
        shipmentRoute: shipmentRoute,
        type: shipmentRouteId === 'new' ? 'create' : 'update',
      })
    );
  }, [freigthType, form, shipmentRoute, shipmentRouteId]);

  useEffect(() => {
    if (shipmentRouteId !== 'new') {
      if (shipmentRoute) {
        form.setFieldsValue({
          ...shipmentRoute,
          routing_details: getInitialRoutingDetails(shipmentRoute.routing_legs, FREIGHT_TYPE_ROAD),
        });
      }
    } else {
      form.setFieldsValue(
        getGenericInitialValue(undefined, {
          trade_type: queryTradeType,
          freight_type: freigthType,
        })
      );
    }
  }, [form, shipmentRoute, shipmentRouteId, queryTradeType, freigthType]);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const routingLegs = getRoutingLegsPayload(values, null);
        const routingNodes = getRoutingNodePayload(values);

        const payload = {
          ...values,
          routing_legs: routingLegs?.length && routingLegs.length > 0 ? routingLegs : undefined,
          routing_nodes: routingNodes?.length && routingNodes.length > 0 ? routingNodes : undefined,
        };
        upsertShipmentRoute({
          variables: {
            route: {
              id: shipmentRouteId === 'new' ? undefined : shipmentRouteId,
              ...omit(payload, ['routing_details']),
            },
          },
        });
      }}
      layout="vertical"
      style={{
        height: '100%',
      }}
      disabled={!!shipmentRoute?.is_disabled}
      requiredMark={true}
      onValuesChange={async (changedValues) => {
        const formValues: any = {};

        if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
      }}
    >
      <FormLayout
        form={form}
        components={components}
        formTitle={
          <>
            {shipmentRouteId === 'new' ? 'Create' : 'Update'} {'Route'}
            {shipmentRoute?.name && ` ${shipmentRoute?.name}`}
          </>
        }
        loading={upsertShipmentRouteLoading}
        formOnSubmitText={`${shipmentRouteId === 'new' ? 'Create' : 'Update'}`}
        onBack={() => {
          navigate('~/workspace?doc_type=Shipment::Route');
        }}
      />
    </Form>
  );
}
