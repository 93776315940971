import React, { useMemo } from 'react';
import { compact as _compact } from 'lodash';
import {
  ADDRESS_LOCATION_TYPES,
  ADDRESS_TYPE_OPTION,
  AddressLocationSearch,
  Col,
  Form,
  GlobalSearch,
  LOCATION_CFS_TYPE_OPTION,
  LOCATION_CITY_TYPE_OPTION,
  LOCATION_ICD_TYPE_OPTION,
  Row,
} from '@shipmnts/pixel-hub';
import { FREIGHT_TYPE_AIR, FREIGHT_TYPE_ROAD } from 'sales_hub/utils/constants';
import { useInquiryForm } from './InquiryForm';

import {
  CONTAINER_STUFFING_NODE,
  CONTAINER_DESTUFFING_NODE,
  DELIVERY_NODE,
  PCD_NODE,
  PCR_NODE,
  PICKUP_NODE,
  POD_NODE,
  POL_NODE,
  shouldShow,
  getDefaultNodeType,
} from './helper';

const InquiryRoutingDetails = () => {
  const { freightType, form, customer } = useInquiryForm();

  // move these to InquiryForm if want to use in other sections
  const serviceType = Form.useWatch('service_type', form);
  const movementMode = Form.useWatch('movement_mode', form);

  // constants
  const portType = useMemo(
    () => (freightType === FREIGHT_TYPE_AIR ? 'Airport' : 'Seaport'),
    [freightType]
  );

  const isRoad = useMemo(() => freightType === FREIGHT_TYPE_ROAD, [freightType]);

  const service = useMemo(
    () => (isRoad ? movementMode : serviceType),
    [isRoad, serviceType, movementMode]
  );

  return (
    <>
      <h3 className="inq-form-section-title">{'Routing'}</h3>
      <Row gutter={{ lg: 16 }}>
        {shouldShow(freightType, service, PICKUP_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} noStyle>
              {({ getFieldValue }) => {
                const shipper = getFieldValue(['shipment_parties', 'shipper']);
                const defaultNodeType =
                  getDefaultNodeType(freightType, service, PICKUP_NODE) || ADDRESS_TYPE_OPTION;
                return (
                  <Form.Item name={PICKUP_NODE} label={'Pickup Location'}>
                    <AddressLocationSearch
                      showRemarksFor={[LOCATION_CITY_TYPE_OPTION]}
                      onlyShow={
                        isRoad
                          ? [...ADDRESS_LOCATION_TYPES]
                          : [ADDRESS_TYPE_OPTION, LOCATION_CITY_TYPE_OPTION]
                      }
                      companies={_compact([customer?.party_company, shipper?.party_company])}
                      defaultType={defaultNodeType}
                      key={defaultNodeType}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, PCR_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type', 'originCustomClearance']} noStyle>
              {() => {
                return (
                  <Form.Item name={PCR_NODE} label={'Place of Carrier Receipt'}>
                    <AddressLocationSearch
                      onlyShow={[LOCATION_ICD_TYPE_OPTION, LOCATION_CFS_TYPE_OPTION]}
                      defaultType={LOCATION_ICD_TYPE_OPTION}
                      onChange={(value) => {
                        const origin_custom_clearance = form.getFieldValue([
                          'services',
                          'origin_custom_clearance',
                        ]);

                        if (origin_custom_clearance && value?.location?.is_customs_location) {
                          form.setFieldValue('originCustomClearance', value?.location);
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, POL_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} label="Port of Loading" noStyle>
              {() => {
                return (
                  <Form.Item
                    name="port_of_loading"
                    label="Port of Loading"
                    rules={[{ required: true }]}
                    key={portType}
                  >
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{ type: [portType] }}
                      onChange={(value) => {
                        const origin_custom_clearance = form.getFieldValue([
                          'services',
                          'origin_custom_clearance',
                        ]);

                        const pcr = form.getFieldValue(PCR_NODE); // PCR has higher precedence
                        if (origin_custom_clearance && value?.is_customs_location && !pcr) {
                          form.setFieldValue('originCustomClearance', value);
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, CONTAINER_STUFFING_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} noStyle>
              {({ getFieldValue }) => {
                const consignee = getFieldValue(['shipment_parties', 'consignee']);
                const shipper = getFieldValue(['shipment_parties', 'shipper']);
                return (
                  <Form.Item name={CONTAINER_STUFFING_NODE} label={'Container Stuffing Location'}>
                    <AddressLocationSearch
                      onlyShow={[...ADDRESS_LOCATION_TYPES]}
                      defaultType={ADDRESS_TYPE_OPTION}
                      companies={_compact([
                        customer?.party_company,
                        shipper?.party_company,
                        consignee?.party_company,
                      ])}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, CONTAINER_DESTUFFING_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} noStyle>
              {({ getFieldValue }) => {
                const consignee = getFieldValue(['shipment_parties', 'consignee']);
                const shipper = getFieldValue(['shipment_parties', 'shipper']);
                return (
                  <Form.Item
                    name={CONTAINER_DESTUFFING_NODE}
                    label={'Container Detuffing Location'}
                  >
                    <AddressLocationSearch
                      onlyShow={[...ADDRESS_LOCATION_TYPES]}
                      defaultType={ADDRESS_TYPE_OPTION}
                      companies={_compact([
                        customer?.party_company,
                        shipper?.party_company,
                        consignee?.party_company,
                      ])}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, POD_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} noStyle>
              {() => {
                return (
                  <Form.Item
                    name="port_of_discharge"
                    label="Port of Discharge"
                    rules={[{ required: true }]}
                    key={portType}
                  >
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{ type: [portType] }}
                      onChange={(value) => {
                        const destination_custom_clearance = form.getFieldValue([
                          'services',
                          'destination_custom_clearance',
                        ]);
                        const pcd = form.getFieldValue(PCD_NODE); // PCD has higher precidency
                        if (destination_custom_clearance && value?.is_customs_location && !pcd) {
                          form.setFieldValue('destinationCustomClearance', value);
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, PCD_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} noStyle>
              {() => {
                return (
                  <Form.Item name={PCD_NODE} label={'Place of Carrier Delivery'}>
                    <AddressLocationSearch
                      onlyShow={[LOCATION_ICD_TYPE_OPTION, LOCATION_CFS_TYPE_OPTION]}
                      defaultType={LOCATION_ICD_TYPE_OPTION}
                      onChange={(value) => {
                        const destination_custom_clearance = form.getFieldValue([
                          'services',
                          'destination_custom_clearance',
                        ]);

                        if (destination_custom_clearance && value?.location?.is_customs_location) {
                          form.setFieldValue('destinationCustomClearance', value?.location);
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {shouldShow(freightType, service, DELIVERY_NODE) && (
          <Col xs={24} sm={12} lg={6}>
            <Form.Item dependencies={['service_type']} noStyle>
              {({ getFieldValue }) => {
                const consignee = getFieldValue(['shipment_parties', 'consignee']);
                const defaultNodeType =
                  getDefaultNodeType(freightType, service, DELIVERY_NODE) || ADDRESS_TYPE_OPTION;
                return (
                  <Form.Item name={DELIVERY_NODE} label={'Delivery Location'}>
                    <AddressLocationSearch
                      showRemarksFor={[LOCATION_CITY_TYPE_OPTION]}
                      onlyShow={
                        isRoad
                          ? [...ADDRESS_LOCATION_TYPES]
                          : [ADDRESS_TYPE_OPTION, LOCATION_CITY_TYPE_OPTION]
                      }
                      companies={_compact([customer?.party_company, consignee?.party_company])}
                      defaultType={defaultNodeType}
                      key={defaultNodeType}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default InquiryRoutingDetails;
