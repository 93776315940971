import React, { useEffect, useRef } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useSession } from 'common';
import {
  get_dashboard_token,
  generate_url_params,
  ParamsObj,
} from 'src/packages/analytics/utils/dashboard_helper';
import '../packages/analytics/styles.css';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD } from 'src/graphQL/query';

export interface DashboardObj {
  id: string;
  superset_dashboard_id?: string;
  title?: string;
  primary_doctype?: string;
  customURLParams?: any;
}

const Dashboard = (props: DashboardObj) => {
  const user = useSession();
  const { id, customURLParams } = props;
  const { data: dashboardDetails } = useQuery(GET_DASHBOARD, {
    variables: {
      id,
    },
  });
  const supersetContainerRef = useRef<HTMLDivElement>(null);
  const embed = (dashboardParams: ParamsObj) => {
    try {
      embedDashboard({
        id: dashboardDetails.get_dashboard.superset_dashboard_id,
        supersetDomain: process.env.SUPERSET_URL || '',
        mountPoint: supersetContainerRef.current as HTMLElement,
        fetchGuestToken: async () => {
          return get_dashboard_token(dashboardParams);
        },
        dashboardUiConfig: {
          hideTitle: false,
          filters: {
            expanded: false,
            visible: true,
          },
          urlParams: generate_url_params(dashboardParams),
        },
      });
    } catch (error) {
      throw new Error('Unable to load dashboard. Please Contact Support');
    }
  };
  useEffect(() => {
    if (!dashboardDetails) return;
    const dashboardParams: ParamsObj = {
      user_id: user.id,
      allowed_cost_centers: user.branch_accounts?.toJSON() || [],
      allowed_doctypes: user.role.permissions?.toJSON() || [],
      tenant_id: user.tenant_id || '',
      superset_dashboard_id: dashboardDetails.get_dashboard.superset_dashboard_id,
      fin_database: user.company_account?.fin_database,
      ...(customURLParams || {}),
    };
    embed(dashboardParams);
    const container = supersetContainerRef.current;
    if (container && container.children[0]) {
      const iframe = container.children[0] as HTMLIFrameElement;
      iframe.style.width = '100%';
      iframe.style.height = '800px';
      iframe.style.border = 'none';
      // iframe.classList.add('superset-iframe-container');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardDetails, id]);
  return <div ref={supersetContainerRef}></div>;
};

export default Dashboard;
