import React, { useState } from 'react';
import { DetailsCard } from 'common';
import { WithPermission, Button, Form } from '@shipmnts/pixel-hub';
import { useCompanyView } from '../CompanyView';
import { PERMISSION_SUPPLIER_CREATE_EDIT, PERMISSION_SUPPLIER_VIEW } from 'network/permissions';
import { SectionEditorModal } from '../SectionEditorModal';
import SupplierLedgerDetailsForm from 'network/components/SupplierLedgerDetailsForm';
import { getFormInitialValues } from 'network/components/companyDetailView/SupplierLedgerDetails';

export const SupplierLedgerCard = ({
  loading,
  erpnextConfigData,
}: {
  loading?: boolean;
  erpnextConfigData?: any;
}) => {
  const { company, accounts_data, is_vendor } = useCompanyView();
  const [editMode, setEditMode] = useState(false);

  const data = {
    'Default Payment Terms': accounts_data?.payable_payment_terms || '-',
    'Billing Currency': accounts_data?.default_currency || '-',
    'Tax Withholding Category': accounts_data?.payable_tax_withholding_category,
    MSME: accounts_data?.is_msme ? 'Yes' : 'No',
  };

  return (
    <WithPermission permission={{ name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' }}>
      {is_vendor ? (
        <DetailsCard
          header={{ title: 'Supplier Ledger' }}
          loading={loading}
          style={{ width: '100%', height: '100%' }}
          Action={
            <WithPermission
              permission={{ name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' }}
            >
              <Button
                disabled={company?.is_disabled}
                onClick={() => !!!company?.is_disabled && setEditMode(true)}
                size="small"
              >
                {'Edit'}
              </Button>
            </WithPermission>
          }
        >
          <>
            <>
              {Object.entries(data)
                .filter(([_, value]) => value)
                .map(([label, value]) => {
                  return (
                    <div
                      key={label}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '1em',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: '800px',
                          opacity: '.6',
                          width: '50%',
                        }}
                      >
                        {label}
                      </span>
                      <span
                        style={{
                          width: '40%',
                        }}
                      >
                        {value}
                      </span>
                    </div>
                  );
                })}
            </>
            {accounts_data?.payable_accounts && accounts_data?.payable_accounts?.length > 0 && (
              <>
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    marginBlock: '1em .5em',
                    opacity: 0.6,
                  }}
                >
                  Non-standard Payable accounts
                </div>
                <div
                  style={{
                    display: 'grid',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '10%',
                      marginBottom: '.25em',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        display: 'block',
                        opacity: 0.6,
                        fontSize: '12px',
                        fontWeight: '600',
                        width: '45%',
                      }}
                    >
                      Company
                    </span>
                    <span
                      style={{
                        display: 'block',
                        opacity: 0.6,
                        fontSize: '12px',
                        fontWeight: '600',
                        width: '45%',
                      }}
                    >
                      Account
                    </span>
                  </div>
                  {accounts_data?.payable_accounts?.map((account) => {
                    return (
                      <div
                        key={account?.company}
                        style={{
                          display: 'flex',
                          gap: '10%',
                          marginBottom: '1em',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: '800px',
                            opacity: '.6',
                            width: '45%',
                          }}
                        >
                          {account?.company}
                        </span>
                        <span
                          style={{
                            width: '45%',
                          }}
                        >
                          {account?.account}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        </DetailsCard>
      ) : (
        <WithPermission
          permission={{ name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' }}
        >
          <DetailsCard
            header={{ title: 'Supplier Ledger' }}
            loading={loading}
            style={{ width: '100%', height: '100%' }}
            Action={<></>}
          >
            <div
              style={{
                marginInline: 'auto',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                size="small"
                disabled={company?.is_disabled || company?.company_group === 'Self'}
                style={{ marginBlock: '2em 1em' }}
                onClick={() => setEditMode(true)}
              >
                {'Create Ledger'}
              </Button>
            </div>
          </DetailsCard>
        </WithPermission>
      )}
      {editMode && company && (
        <SectionEditorModal
          title="Supplier Ledger"
          show={editMode}
          setShow={setEditMode}
          initialValues={getFormInitialValues(company, accounts_data)}
        >
          {({ form }) => {
            form.setFieldsValue({ id: company.id, is_vendor: true });
            return (
              <>
                <Form.Item noStyle name="is_vendor">
                  <span />
                </Form.Item>
                <SupplierLedgerDetailsForm
                  company={company}
                  erpnextConfigData={erpnextConfigData}
                />
              </>
            );
          }}
        </SectionEditorModal>
      )}
    </WithPermission>
  );
};
