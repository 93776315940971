import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { message, Popconfirm } from '@shipmnts/pixel-hub';
import { APPROVE_INQUIRY_OPTION } from 'sales_hub/graphql/inquiryOption';

interface InquiryOptionApproveProps {
  id: string;
  isVisible?: boolean;
  visible?: boolean;
  onSuccess?: () => void;
  setIsVisible?: (isVisible: boolean) => void;
  refetchInquiry?: () => void;
  inquiry?: Record<string, any>;
}

function InquiryOptionApprove(props: InquiryOptionApproveProps) {
  // Props
  const { id, onSuccess, visible, refetchInquiry } = props;

  // States
  const [open, setOpen] = useState<boolean | undefined>(true);

  // Queries & Mutations
  const [approveInquiryOption, { data, error }] = useMutation(APPROVE_INQUIRY_OPTION);

  // Effects
  useEffect(() => {
    if (error) {
      message.error('Unable to Approve');
      setOpen(false);
    }

    if (data && !error) {
      message.success('Quotation Approved');
      if (onSuccess) onSuccess();
      if (refetchInquiry) refetchInquiry();
    }
  }, [data, error, onSuccess, refetchInquiry]);

  if (!id) return <></>;
  return (
    <>
      {visible && (
        <Popconfirm
          placement="topLeft"
          title={'Do you want to approve quotation?'}
          okText="Yes"
          open={open}
          onConfirm={() => {
            approveInquiryOption({
              variables: {
                inquiry_option_id: id,
              },
            });
          }}
          onCancel={() => {
            setOpen(false);
          }}
        ></Popconfirm>
      )}
    </>
  );
}

export default InquiryOptionApprove;
