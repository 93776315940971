import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Action } from 'operations/modules/actionHelper/models';
import { PRIMARY_TYPE } from 'operations/modules/actionHelper/constants';
import { ColorPickerTool, EditOutlined, GlobalSearch, StopOutlined } from '@shipmnts/pixel-hub';
import editCompanyFieldRenderer from './CompanyBulkActionRenderer';
import getPipelineActions from '../pipelineBulkActionHelper';
import getTeamsBulkAction from '../teamsBulkActionHelper';
import { CHURNED_CUSTOMER } from 'common/baseConstants';
import { isCustomer } from 'common';

/**
 * Generates a list of bulk actions that can be performed on selected companies.
 *
 * @param {RowNode[]} selectedNodes - The nodes selected from the data grid.
 * @returns {Action[]} - Array of actions available for the selected companies.
 */
const getCompanyBulkActions = (selectedNodes: RowNode[]) => {
  if (selectedNodes.length === 0) return [];

  const actions: Action[] = [];
  const selectedData = selectedNodes.map((node) => node.data);
  const isCustomerType = selectedData.every((data) => isCustomer(data.company_type));
  const isNotDisabled = selectedData.every((data) => !data.is_disabled);

  // Adds an action to edit the company's color code.
  actions.push(
    companyFieldEditAction('color_code', 'Edit Colour', ColorPickerTool, {
      value: '#FFFFFF',
    })
  );

  // If all selected companies are enabled, add additional actions
  if (isNotDisabled) {
    // Adds a bulk action related to team assignments.
    actions.push(getTeamsBulkAction('Network::Company'));

    // Adds pipeline-related bulk actions for the selected companies.
    getPipelineActions('Network::Company', selectedNodes).forEach((action: Action) =>
      actions.push(action)
    );

    // Adds an action to assign a collaborator.
    actions.push(
      companyFieldEditAction('collaborator_id', 'Add Collaborator', GlobalSearch, {
        doc_type: 'Network::UserContact',
      })
    );

    // If all selected companies are customers, add a "Mark as Churned" action.
    if (isCustomerType) {
      actions.push(
        performCompanyAction('company_stage', 'Mark as Churned', {
          company_stage: CHURNED_CUSTOMER,
        })
      );
    }
  }

  return actions;
};

/**
 * Creates an action for editing a specific field of the company.
 *
 * @param {string} field_name - The field to be edited.
 * @param {string} label - The label to display for the action.
 * @param {React.ElementType} Komponent - The component used for editing.
 * @param {any} KomponentProps - Additional props for the component.
 * @returns {Action} - The action object that acts as payload for bulk mutation. (For actions where form is not required)
 */
const companyFieldEditAction = (
  field_name: string,
  label: string,
  Komponent: React.ElementType,
  KomponentProps: any = {}
): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <EditOutlined />,
    description: label,
    performAction: editCompanyFieldRenderer,
    isEnable: true,
    extraProps: {
      field_name: field_name,
      label: label,
      Komponent: Komponent,
      KomponentProps: KomponentProps,
    },
  };
};

/**
 * Creates a bulk action to perform a company-level action, such as changing the company's stage.
 *
 * @param {string} field_name - The field name associated with the action.
 * @param {string} label - The label to display for the action.
 * @param {any} actionValue - The value to be set when performing the action.
 * @returns {Action} - The action object that acts as payload for bulk mutation. (For actions where form is not required)
 */
const performCompanyAction = (field_name: string, label: string, actionValue: any): Action => {
  return {
    key: field_name,
    type: PRIMARY_TYPE,
    displayComponent: label,
    icon: <StopOutlined />,
    description: label,
    performAction: editCompanyFieldRenderer,
    isEnable: true,
    extraProps: {
      field_name: field_name,
      label: label,
      actionValue: actionValue,
    },
  };
};

export default getCompanyBulkActions;
