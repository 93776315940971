// In Rate Search, Resource can be either Quote, Job / Shipment, Contract or Spot rate.
// Considering, these entities will be passed to this component in a same/fixed format.

import React, { useMemo } from 'react';
import { RateExplorerData, RateExplorerResourceType } from './RateExplorer.type';
import {
  ArrowLeftOutlined,
  Col,
  Divider,
  Drawer,
  getDateFromUnix,
  Row,
  Tag,
} from '@shipmnts/pixel-hub';
import {
  DESTINATION_CUSTOM_SERVICES,
  ORIGIN_CUSTOM_SERVICES,
  RATES_RESOURCE_TYPE_MAP,
} from 'sales_hub/utils/constants';
import { isInquiryResource } from './helper';
import { RateTable } from './RateTable';

const getSummary = (resourceItem?: RateExplorerData, isCustoms = false) => {
  const { carrier, valid_from, valid_to, created_at } = resourceItem || {};
  const summary: { [key: string]: any } = {};

  summary['Carrier'] = (!isCustoms ? carrier?.name : null) || '-';
  summary['Valid From'] = !!valid_from ? getDateFromUnix(valid_from) : '-';
  summary['Valid Till'] = !!valid_to ? getDateFromUnix(valid_to) : '-';
  summary['Created At'] = !!created_at ? getDateFromUnix(created_at) : '-';

  return summary;
};

const getRoutingDetails = (resourceItem?: RateExplorerData, isCustoms = false) => {
  const {
    port_of_loading,
    port_of_discharge,
    transit_days,
    voyage_number,
    estimated_time_of_departure,
    place_of_carrier_receipt: pcr,
    place_of_carrier_delivery: pcd,
  } = resourceItem || {};
  const data: { [key: string]: any } = {};

  // only return PCR & PCD for customs inquiry...
  if (isCustoms) {
    data['Place of Carrier Receipt'] = !!pcr ? pcr.unlocode + ' - ' + pcr.name : '-';
    data['Place of Carrier Delivery'] = !!pcd ? pcd?.unlocode + ' - ' + pcd.name : '-';
    return data;
  }

  // For non-custom inquiry's resources
  data['Port of Loading'] = !!port_of_loading
    ? port_of_loading?.unlocode + ' - ' + port_of_loading?.name
    : '-';
  data['Port of Discharge'] = port_of_discharge
    ? port_of_discharge.unlocode + ' - ' + port_of_discharge.name
    : '-';
  data['Voyage #'] = voyage_number || '-';
  data['Trasit Time'] = transit_days || '-';
  data['ETD'] = estimated_time_of_departure ? getDateFromUnix(estimated_time_of_departure) : '-';

  return data;
};

const ResourceDetails = (props: {
  resource: RateExplorerResourceType;
  resourceItem: RateExplorerData;
  onClose?: () => void;
  open?: boolean;
}) => {
  const { resource, onClose, resourceItem, open = false } = props;
  const resourceType = useMemo(
    () =>
      !!resourceItem?.resource_type ? RATES_RESOURCE_TYPE_MAP[resourceItem.resource_type] : null,
    [resourceItem]
  );

  const isCustoms = useMemo(() => {
    if (!isInquiryResource(resource)) return false;
    return resource?.services?.some((service) =>
      [ORIGIN_CUSTOM_SERVICES, DESTINATION_CUSTOM_SERVICES].includes(service)
    );
  }, [resource]);

  const summary = useMemo(() => getSummary(resourceItem, isCustoms), [resourceItem, isCustoms]);
  const routingData = useMemo(
    () => getRoutingDetails(resourceItem, isCustoms),
    [resourceItem, isCustoms]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={'75%'}
      closeIcon={false} // CONFIRM: placement
      title={
        <React.Fragment>
          <Row gutter={8} align={'middle'}>
            <Col>
              <ArrowLeftOutlined style={{ fontSize: '16px' }} onClick={onClose} />
            </Col>
            <Col>
              <h3 style={{ margin: 0 }}>{resourceItem?.resource_number}</h3>
            </Col>
            <Col>
              {resourceType && (
                <Tag bordered={false} color="blue" style={{ borderRadius: '20px' }}>
                  {resourceType}
                </Tag>
              )}
            </Col>
          </Row>
          {Object.keys(summary).length > 0 ? (
            <Row gutter={8} style={{ marginTop: '1em', fontWeight: '400' }}>
              {Object.entries(summary).map(([label, value], index) => (
                <Col span={6} key={index}>
                  <div style={{ color: '#586988' }}>{label}</div>
                  <div>{value}</div>
                </Col>
              ))}
            </Row>
          ) : (
            <></>
          )}
        </React.Fragment>
      }
      styles={{ body: { padding: 0 } }}
    >
      <div style={{ paddingInline: '1.5em' }}>
        <h3>Charges</h3>
        <RateTable estimates={resourceItem.shipment_estimates} />
      </div>
      {Object.keys(routingData).length > 0 ? (
        <React.Fragment>
          <Divider />
          <div style={{ paddingInline: '1.5em' }}>
            <h3 style={{ marginBlock: '0 0.5em' }}>Routing</h3>
            <Row gutter={8}>
              {Object.entries(routingData).map(([label, value], index) => (
                <Col span={6} key={index} style={{ marginBottom: '1em' }}>
                  <div style={{ color: '#586988', fontWeight: '400' }}>{label}</div>
                  <div>{value}</div>
                </Col>
              ))}
            </Row>
          </div>
        </React.Fragment>
      ) : (
        <></>
      )}
      <Divider />
      <div style={{ paddingInline: '1.5em' }}>
        <h3 style={{ marginTop: 0 }}>Terms & Conditions</h3>
        <div dangerouslySetInnerHTML={{ __html: resourceItem?.terms_and_cond_desc || '' }} />
      </div>
    </Drawer>
  );
};

export default ResourceDetails;
