import React from 'react';
import {
  TagsOutlined,
  CustomIcon,
  FilePdfOutlined,
  EditOutlined,
  FolderAddOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@shipmnts/pixel-hub';
import {
  cancelTransactionRenderer,
  enableDisableWmsProductRenderer,
  performAddChild,
  performCreateReceiptAction,
  performCreateGoodsTransferAction,
  performDeleteWarehouseUnit,
  performEditWarehouseUnit,
  performEditWmsProduct,
  performPrintTransaction,
  reopenTransactionRenderer,
  submitTransactionRenderer,
} from './WMSActionRenderer';
import {
  NEGATIVE_TYPE,
  PRIMARY_TYPE,
  SECONDARY_TYPE,
  TERTIARY_TYPE,
} from '../actionHelper/constants';

import { Action } from '../actionHelper/models';
import { SessionDataValue } from 'operations/models/SessionData';
import { ItemValue } from 'common/models/Item';
import {
  CANCELLED,
  STATUS_DRAFT,
  STATUS_SUBMITTED,
  TRANSACTION_TYPE_OUTGOING,
  TRANSACTION_TYPE_RECEIPT,
  WarehouseTransactionValue,
} from 'operations/models/WarehouseTransaction';

const checkEnabled = (transaction: WarehouseTransactionValue) => {
  if (transaction?.status === CANCELLED) return false;
  if (!!transaction?.warehouse_transaction_products) {
    for (const product of transaction.warehouse_transaction_products || []) {
      for (const lp of product.linked_products || []) {
        if (lp.warehouse_transaction.status !== CANCELLED) return false;
      }
    }
  }
  return true;
};

const createReceipt = (
  transaction: WarehouseTransactionValue,
  nextTransaction: any,
  sessionData: SessionDataValue
): Action => {
  const total_available_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.available_qty,
      0
    ) || 0;

  return {
    key: 'create_goods_receipt',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Receipt',
    icon: <TagsOutlined />,
    isEnable:
      transaction?.status !== CANCELLED &&
      transaction?.status !== STATUS_DRAFT &&
      total_available_qty !== 0,
    extraProps: {
      nextTransaction: nextTransaction,
    },
    performAction: performCreateReceiptAction,
    description: 'Create Receipt',
  };
};

const createGoodsTransfer = (
  transaction: WarehouseTransactionValue,
  nextTransaction: string,
  purposeOfTransfer: string
): Action => {
  return {
    key: 'create_goods_transfer',
    type: PRIMARY_TYPE,
    displayComponent: purposeOfTransfer,
    icon: <TagsOutlined />,
    isEnable:
      transaction?.type === TRANSACTION_TYPE_RECEIPT ||
      transaction?.type === TRANSACTION_TYPE_OUTGOING
        ? transaction?.status === STATUS_SUBMITTED
        : true,
    extraProps: {
      nextTransaction: nextTransaction,
      purposeOfTransfer: purposeOfTransfer,
    },
    performAction: performCreateGoodsTransferAction,
    description: purposeOfTransfer,
  };
};

const createOutgoingShipment = (
  transaction: WarehouseTransactionValue,
  nextTransaction: any,
  sessionData: SessionDataValue
): Action => {
  const total_available_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.available_qty,
      0
    ) || 0;
  return {
    key: 'create_outgoing_shipment',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Outgoing Shipment',
    icon: <TagsOutlined />,
    isEnable:
      transaction?.status !== CANCELLED &&
      transaction?.status !== STATUS_DRAFT &&
      total_available_qty !== 0,
    extraProps: {
      nextTransaction: nextTransaction,
    },
    performAction: performCreateReceiptAction,
    description: 'Create Outgoing Shipment',
  };
};

const createDelivery = (
  transaction: WarehouseTransactionValue,
  nextTransaction: any,
  sessionData: SessionDataValue
): Action => {
  const total_available_qty =
    transaction?.warehouse_transaction_products?.reduce(
      (acc: any, curr: any) => acc + curr.available_qty,
      0
    ) || 0;
  return {
    key: 'create_delivery',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Delivery',
    icon: <TagsOutlined />,
    isEnable:
      transaction?.status !== CANCELLED &&
      transaction?.status !== STATUS_DRAFT &&
      total_available_qty !== 0,
    extraProps: {
      nextTransaction: nextTransaction,
    },
    performAction: performCreateReceiptAction,
    description: 'Create Delivery',
  };
};

export const cancelTransaction = (transaction: WarehouseTransactionValue): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'cancel_transaction',
    icon: <CustomIcon icon="FileTimesIcon" />,
    displayComponent: 'Cancel Transaction',
    isEnable: checkEnabled(transaction),
    performAction: cancelTransactionRenderer,
  };
};

export const reopenTransaction = (transaction: WarehouseTransactionValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'reopen_transaction',
    icon: <TagsOutlined />,
    displayComponent: 'Reopen Transaction',
    isEnable: transaction.status !== STATUS_DRAFT && checkEnabled(transaction),
    performAction: reopenTransactionRenderer,
  };
};

export const submitTransaction = (transaction: WarehouseTransactionValue): Action => {
  return {
    type: PRIMARY_TYPE,
    key: 'submit_transaction',
    icon: <TagsOutlined />,
    displayComponent: 'Submit Transaction',
    isEnable: transaction?.status === STATUS_DRAFT,
    performAction: submitTransactionRenderer,
  };
};

const printTransaction = (docType: any): Action => {
  return {
    key: 'print_order',
    type: SECONDARY_TYPE,
    displayComponent: 'Share',
    isEnable: true,
    icon: <FilePdfOutlined />,
    extraProps: { docType: docType },
    performAction: performPrintTransaction,
    description: 'Share Transaction',
  };
};

const addChildUnit = (warehouseUnit: any): Action => {
  return {
    key: 'add_child_unit',
    type: PRIMARY_TYPE,
    displayComponent: 'Add Child',
    isEnable: true,
    icon: <FolderAddOutlined />,
    extraProps: { warehouseUnit: warehouseUnit },
    performAction: performAddChild,
    description: 'Add Child',
  };
};

const editWarehouseUnit = (warehouseUnit: any): Action => {
  return {
    key: 'edit_child_unit',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    isEnable: true,
    icon: <EditOutlined />,
    extraProps: { warehouseUnit: warehouseUnit },
    performAction: performEditWarehouseUnit,
    description: 'Edit',
  };
};

export const enableDisableWmsProduct = (wmsProduct: any): Action => {
  return {
    type: !wmsProduct?.disabled ? NEGATIVE_TYPE : TERTIARY_TYPE,
    key: 'enable_disable',
    displayComponent: !wmsProduct?.disabled ? 'Disable' : 'Enable',
    icon: !wmsProduct?.disabled ? <DeleteOutlined /> : <CheckCircleOutlined />,
    isEnable: true,
    performAction: enableDisableWmsProductRenderer,
  };
};

const editWmsProduct = (warehouseUnit: any): Action => {
  return {
    key: 'edit_child_unit',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit',
    isEnable: true,
    icon: <EditOutlined />,
    performAction: performEditWmsProduct,
    description: 'Edit',
  };
};

export const deleteWarehouseUnit = (warehouseUnit: any): Action => {
  return {
    type: NEGATIVE_TYPE,
    key: 'delete_warehouse_unit',
    displayComponent: 'Delete',
    icon: <DeleteOutlined />,
    isEnable: !warehouseUnit?.child_unit_ids?.length && warehouseUnit?.node_type !== 'warehouse',
    extraProps: { warehouseUnit: warehouseUnit },
    performAction: performDeleteWarehouseUnit,
  };
};

export const createTransactionAction = (transaction: any, sessionData: SessionDataValue) => {
  if (transaction.type === 'incoming_shipment') {
    return [createReceipt(transaction, 'receipt', sessionData)];
  } else if (transaction.type === 'receipt') {
    const actions = [printTransaction('Wms::Receipt')];
    if (transaction?.target_warehouse?.is_two_step) {
      actions.push(createGoodsTransfer(transaction, 'goods_transfer', 'Put-Away'));
    } else
      actions.push(
        createOutgoingShipment(transaction, 'outgoing_shipment', sessionData),
        createDelivery(transaction, 'delivery', sessionData)
      );
    return actions;
  } else if (transaction.type === 'outgoing_shipment') {
    return [
      createGoodsTransfer(transaction, 'goods_transfer', 'Picking'),
      createDelivery(transaction, 'delivery', sessionData),
    ];
  } else if (transaction.type === 'goods_transfer') {
    if (transaction?.purpose_of_transfer === 'Picking')
      return [createDelivery(transaction, 'delivery', sessionData)];
    else return [];
  }
  return [printTransaction('Wms::Delivery')];
};

export const getWmsTransactionActions = (transaction: any, sessionData: SessionDataValue) => {
  return [
    createTransactionAction(transaction, sessionData),
    submitTransaction(transaction),
    cancelTransaction(transaction),
    reopenTransaction(transaction),
  ].flat();
};

export const getWmsWarehouseUnitActions = (warehouseUnit: any, sessionData: SessionDataValue) => {
  return [
    addChildUnit(warehouseUnit),
    editWarehouseUnit(warehouseUnit),
    deleteWarehouseUnit(warehouseUnit),
  ].flat();
};

export const getWmsProductActions = (wmsProduct: ItemValue, sessionData: SessionDataValue) => {
  return [editWmsProduct(wmsProduct), enableDisableWmsProduct(wmsProduct)].flat();
};
