import React from 'react';

import { Skeleton, AlignLeftOutlined, FormOutlined, Card } from '@shipmnts/pixel-hub';
import { CardPropTypes } from './types';

export const DetailsCard = (props: CardPropTypes) => {
  const { children, style, header, loading, Action, className } = props;

  const getHeading = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
        {(header?.icon && typeof header.icon === 'function' ? <header.icon /> : <></>) || (
          <AlignLeftOutlined />
        )}
        <h4 style={{ margin: 0, color: '#586988', fontWeight: '600px' }}>{header?.title}</h4>
      </div>
    );
  };

  const getAction = () => {
    return (
      <>
        {!!Action ? (
          typeof Action === 'function' ? (
            <Action />
          ) : (
            Action
          )
        ) : (
          <FormOutlined style={{ cursor: 'pointer' }} />
        )}
      </>
    );
  };

  return (
    <Card
      className={`details-card ${className}`}
      title={getHeading()}
      extra={getAction()}
      size="small"
      style={{
        width: '300px',
        ...style,
      }}
    >
      <Skeleton active loading={loading}>
        <div className="custom-scrollbar details-card-child">{children}</div>
      </Skeleton>
    </Card>
  );
};
