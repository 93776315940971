import React, { useEffect, useMemo } from 'react';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  message,
  Modal,
  StopOutlined,
} from '@shipmnts/pixel-hub';
import { Action } from '../actionHelper/models';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../actionHelper/constants';

import { RowNode } from '@ag-grid-community/core';

import { BULK_DISABLE_RESOURCE_TYPE } from 'operations/graphql/bulkActions';
import { useMutation } from '@apollo/client';
import { startCase } from 'lodash';

const DISABLE_ACTION = 'disable';
const ENABLE_ACTION = 'enable';

/**
 * Determines the correct action (enable/disable) based on the selected row data.
 * Returns an action object for the UI to render, or null if no action is needed.
 */
const getDoctypeEnableDisableHelperAction = (
  doc_type: string,
  selectedNodes: RowNode[]
): Action | null => {
  const selectedData = selectedNodes.map((node) => node.data);

  // Determine if all selected nodes are either enabled or disabled
  const isEnable = selectedData.every((data) => data.enable === true || data.is_disabled === false);
  const isDisable = selectedData.every(
    (data) => data.enable === false || data.is_disabled === true
  );

  // If all nodes are enabled, create an action to disable them
  if (isEnable) {
    return {
      key: DISABLE_ACTION,
      type: NEGATIVE_TYPE,
      displayComponent: 'Disable',
      icon: <DeleteOutlined />,
      description: 'Disable this resource',
      isEnable: true,
      performAction: doctypeEnableDisableHelper,
      extraProps: { type: DISABLE_ACTION, selectedNodes: selectedNodes, doc_type: doc_type },
    };
  }
  // If all nodes are disabled, create an action to enable them
  else if (isDisable) {
    return {
      key: ENABLE_ACTION,
      type: PRIMARY_TYPE,
      displayComponent: 'Enable',
      icon: <StopOutlined />,
      description: 'Enable this resource',
      isEnable: true,
      performAction: doctypeEnableDisableHelper,
      extraProps: { type: ENABLE_ACTION, doc_type: doc_type },
    };
  }

  // Return null if no action is needed
  return null;
};

/**
 * Helper function to initiate enabling/disabling resources.
 * This function sets up the action parameters and renders the appropriate component.
 */
export const doctypeEnableDisableHelper = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      type: extraProps.type,
      doc_type: extraProps.doc_type,
    },
    component: SetDoctypeEnableDisableValue,
  };
};

/**
 * Custom hook to handle the bulk enabling/disabling of resources using BULK_DISABLE_RESOURCE_TYPE mutation.
 * Provides an update function that can be used by components.
 */
const useUpdateDisableOnResource = () => {
  const [updateDisableOnResource] = useMutation(BULK_DISABLE_RESOURCE_TYPE);

  // Handles the mutation and success/error feedback for enabling/disabling resources
  const handleUpdate = async (ids: string[], is_disabled: boolean, doc_type: string) => {
    try {
      const { data, errors } = await updateDisableOnResource({
        variables: { ids, is_disabled, doc_type },
      });

      if (errors) {
        message.error(errors[0]?.message || 'Error occurred during the update.');
        return false;
      }

      if (data?.bulk_disable_resource_type) {
        message.success('Updated successfully.');
        return true;
      }

      return false;
    } catch (error) {
      message.error('Error occurred while updating details.');
      return false;
    }
  };

  return { handleUpdate };
};

/**
 * Displays a confirmation modal before performing the action (enable/disable).
 * Triggers the provided onConfirm callback if the action is confirmed.
 */
const confirmAction = (type: string, onConfirm: () => Promise<void>, onCancel: () => void) => {
  Modal.confirm({
    icon: <ExclamationCircleOutlined />,
    title: 'Are you sure?',
    content: `Are you sure you want to ${startCase(type)}?`,
    onOk() {
      return onConfirm();
    },
    onCancel() {
      onCancel();
    },
  });
};

/**
 * A memoized component to handle the enabling/disabling of resources.
 * Once the modal is shown, it confirms the action and performs the update via GraphQL mutation.
 */
const SetDoctypeEnableDisableValue = React.memo(function SetDoctypeEnableDisableValue(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  type: string;
  doc_type: string;
}): JSX.Element {
  const { onSuccess, onClose, type, doc_type, selectedNodes } = props;
  const { handleUpdate } = useUpdateDisableOnResource();
  const [showModal, setShowModal] = React.useState(true);

  // Memoize selected node IDs to prevent unnecessary recalculations on re-renders
  const selectedNodeIds = useMemo(() => selectedNodes.map((node) => node.data.id), [selectedNodes]);

  const handleConfirm = async () => {
    const is_disabled = type === DISABLE_ACTION;
    const success = await handleUpdate(selectedNodeIds, is_disabled, doc_type);

    if (success) {
      onSuccess();
    } else onClose();
  };

  useEffect(() => {
    if (showModal) {
      confirmAction(type, handleConfirm, onClose);
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, type]);

  return <></>;
});

export default getDoctypeEnableDisableHelperAction;
