import React, { useState, useImperativeHandle, useCallback, useMemo } from 'react';
import VoyageSchedulePortCallRow from './VoyageSchedulePortCallRow';
import { Button, Timeline, message } from '@shipmnts/pixel-hub';
import { EnvironmentOutlined, PlusOutlined } from '@shipmnts/pixel-hub';
import {
  RoutingLegValue,
  ROUTING_TYPE_MAIN_CARRIAGE,
  MODE_OF_TRANSIT_SEA,
} from 'operations/models/RoutingLeg';
import { RoutingNodeValue, cutoffDateFields } from 'operations/models/RoutingNode';
import {
  RoutingNodesHashValue,
  getNewRoutingNode,
  RoutingDetailsValue,
} from 'operations/components/RoutingDetails/RoutingDetails';
import { getInitialNodes } from 'operations/components/RoutingDetails/helpers';

export const startSequence = 0.5;
export const endSequence = 10.5;
export interface PortCallDetailsProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  isEdit?: boolean;
  hideAddRemovePortCall?: boolean;
}

declare type RoutingLegCallback = (
  key: string,
  value: RoutingLegValue[keyof RoutingLegValue],
  route_order: number
) => void;
declare type RoutingNodeCallback = (
  key: string,
  value: RoutingNodeValue[keyof RoutingNodeValue],
  id: string
) => void;

export const isAddCutoffChecked = (payload: { [key: string]: any }) => {
  if (!payload) return false;
  return cutoffDateFields.some((f: string) => payload[f]);
};

const getDefaultnodeCutoffRequired = (routing_nodes: RoutingNodesHashValue, isEdit?: boolean) => {
  let nodeCutoffRequired = {};
  Object.values(routing_nodes).forEach((rn: RoutingNodeValue, index: number) => {
    nodeCutoffRequired = {
      ...nodeCutoffRequired,
      [rn.id || rn._id || '']: isAddCutoffChecked(rn) || (index === 0 && !isEdit),
    };
  });
  return nodeCutoffRequired;
};

const _VoyageSchedulePortCallDetails = React.forwardRef(function VoyageSchedulePortCallDetails(
  props: PortCallDetailsProps,
  ref
) {
  const { onChange, value, isEdit, hideAddRemovePortCall } = props;
  const [errorsPresent, setErrorsPresent] = useState(false);
  const initialNodes = getInitialNodes('main_carriage');
  const initialRoutingLegs = useMemo(
    () => [
      {
        routing_type: 'main_carriage',
        origin_id: initialNodes.origin._id,
        destination_id: undefined,
        mode_of_transit: 'sea',
        sequence_number: 1,
      } as RoutingLegValue,
    ],
    [initialNodes.origin._id]
  );
  const initialRoutingNode = useMemo(
    () =>
      ({
        [initialNodes.origin._id || '']: initialNodes.origin,
      } as RoutingNodesHashValue),
    [initialNodes.origin]
  );
  const routing_legs: Array<RoutingLegValue> = value?.routing_legs || initialRoutingLegs,
    routing_nodes = value?.routing_nodes || initialRoutingNode;

  const [nodeCutoffRequired, setNodeCutoffRequired] = useState<{ [key: string]: boolean }>(
    getDefaultnodeCutoffRequired(routing_nodes, isEdit)
  );

  const onRoutingLegFieldChange: RoutingLegCallback = (key, value, route_order) => {
    const new_routing_legs = routing_legs;
    new_routing_legs[route_order] = { ...new_routing_legs[route_order], [key]: value };
    if (onChange) onChange({ routing_legs: [...new_routing_legs], routing_nodes });
  };

  const onRoutingNodeFieldChange: RoutingNodeCallback = (key, value, id) => {
    const new_routing_nodes = routing_nodes;
    new_routing_nodes[id] = { ...new_routing_nodes[id], [key]: value };
    if (onChange) onChange({ routing_legs, routing_nodes: { ...new_routing_nodes } });
  };

  useImperativeHandle(ref, () => ({
    runValidation() {
      let errorsPresent = false;
      if (routing_legs.length === 0) errorsPresent = true;
      routing_legs.forEach((rl, i) => {
        if (!rl.estimated_time_of_departure && i === 0) errorsPresent = true;
      });
      Object.values(routing_nodes).forEach(
        (rn: { [key: string]: RoutingNodeValue[keyof RoutingNodeValue] }) => {
          if (!rn.location) errorsPresent = true;
          if (!errorsPresent) {
            if (nodeCutoffRequired[rn.id || rn._id || '']) {
              const general_cutoff_present = Boolean(rn.gate_close_date && rn.gate_open_date);
              const reefer_cutoff_present = Boolean(
                rn.reefer_gate_close_date && rn.reefer_gate_open_date
              );
              if (!general_cutoff_present && !reefer_cutoff_present) {
                message.error('General/Reefer Gate open and Gate close cutoff are required.');
                errorsPresent = true;
              }
              const arr = ['si_cutoff_date', 'doc_cutoff_date'];
              arr.forEach((f: string) => {
                if (!rn[f]) errorsPresent = true;
              });
            }
          }
        }
      );
      setErrorsPresent(errorsPresent);
      return errorsPresent;
    },
  }));

  const onChangeNodeCutOffRequired = useCallback(
    (nodeId: string, required: boolean) => {
      const newNodeCutoffRequired = {
        ...nodeCutoffRequired,
        [nodeId]: required,
      };
      setNodeCutoffRequired(newNodeCutoffRequired);
    },
    [nodeCutoffRequired]
  );

  const addPortCall = useCallback(
    async (addAtIndex: number) => {
      const new_node = getNewRoutingNode();
      const routing_type = ROUTING_TYPE_MAIN_CARRIAGE;
      const new_routing_legs = routing_legs;
      const old_rl = new_routing_legs[addAtIndex - 1];
      let new_rl: RoutingLegValue | undefined;
      if (addAtIndex === 0) {
        const previous_routing_leg = new_routing_legs[0];
        new_rl = {
          origin_id: new_node._id,
          destination_id: previous_routing_leg.origin_id,
          estimated_time_of_departure: undefined,
          routing_type,
          mode_of_transit: MODE_OF_TRANSIT_SEA,
          sequence_number: (startSequence + previous_routing_leg.sequence_number) / 2,
        } as RoutingLegValue;
      } else {
        const next_routing_leg = new_routing_legs[addAtIndex] || {
          sequence_number: endSequence,
        };
        new_rl = {
          origin_id: new_node._id,
          destination_id: old_rl.destination_id,
          estimated_time_of_arrival: undefined,
          mode_of_transit: MODE_OF_TRANSIT_SEA,
          sequence_number: (old_rl.sequence_number + next_routing_leg.sequence_number) / 2,
        } as RoutingLegValue;
        old_rl.destination_id = new_node._id;
      }
      if (new_rl) new_routing_legs.splice(addAtIndex, 0, new_rl);
      if (onChange)
        onChange({
          routing_legs: [...new_routing_legs],
          routing_nodes: { ...routing_nodes, [new_node._id || '']: new_node },
        });
    },
    [onChange, routing_legs, routing_nodes]
  );

  const removeHop = useCallback(
    (route_order: number) => {
      if (routing_legs.length === 1) {
        message.error('Cannot remove all port calls');
        return;
      }
      const routing_leg = routing_legs.splice(route_order, 1)[0];
      if (route_order !== 0) {
        routing_legs[route_order - 1] = {
          ...routing_legs[route_order - 1],
          estimated_time_of_arrival: routing_leg.estimated_time_of_arrival,
          destination_id: routing_leg.destination_id,
        };
      }
      if (routing_leg.origin_id) delete routing_nodes[routing_leg.origin_id];
      if (onChange)
        onChange({
          routing_legs: [...routing_legs],
          routing_nodes: { ...routing_nodes },
        });
    },
    [onChange, routing_legs, routing_nodes]
  );
  const resetCutOff = useCallback(
    (rn_id: string) => {
      let routing_node = { ...routing_nodes[rn_id] };
      cutoffDateFields.forEach((f) => {
        routing_node = { ...routing_node, [f]: undefined };
      });
      routing_nodes[rn_id] = routing_node;
      if (onChange) onChange({ routing_legs, routing_nodes: { ...routing_nodes } });
    },
    [onChange, routing_legs, routing_nodes]
  );
  return (
    <Timeline>
      {isEdit && (
        <Timeline.Item
          dot={<EnvironmentOutlined style={{ fontSize: '32px' }} />}
          key={`add_port_call_first`}
        >
          <Button icon={<PlusOutlined />} onClick={() => addPortCall(0)} size="small">
            Add Port Call
          </Button>
        </Timeline.Item>
      )}
      {routing_legs.map((routingLeg, index) => {
        const node = routing_nodes[routingLeg.origin_id || ''];
        return (
          <React.Fragment key={index + '_edge'}>
            <Timeline.Item dot={<EnvironmentOutlined style={{ fontSize: '32px' }} />}>
              <VoyageSchedulePortCallRow
                key={`${index}_${routingLeg.origin_id || ''}`}
                routingLeg={routingLeg}
                index={index}
                onRoutingLegFieldChange={onRoutingLegFieldChange}
                onRoutingNodeFieldChange={onRoutingNodeFieldChange}
                node={node}
                removeHop={removeHop}
                resetCutOff={resetCutOff}
                errorsPresent={errorsPresent}
                addCutoffChecked={isAddCutoffChecked(node) || (index === 0 && !isEdit)}
                onChangeNodeCutOffRequired={onChangeNodeCutOffRequired}
                hideAddRemovePortCall={hideAddRemovePortCall}
              />
            </Timeline.Item>
            {isEdit && index !== routing_legs.length - 1 && !hideAddRemovePortCall && (
              <Timeline.Item dot={<EnvironmentOutlined style={{ fontSize: '32px' }} />}>
                <Button icon={<PlusOutlined />} onClick={() => addPortCall(index + 1)} size="small">
                  Add Port Call
                </Button>
              </Timeline.Item>
            )}
          </React.Fragment>
        );
      })}
      {!hideAddRemovePortCall && (
        <Timeline.Item
          dot={<EnvironmentOutlined style={{ fontSize: '32px' }} />}
          key={`add_port_call_last`}
          className="ant-timeline-item-last"
          style={{ padding: 0 }}
        >
          <Button
            icon={<PlusOutlined />}
            onClick={() => addPortCall(routing_legs.length)}
            size="small"
          >
            Add Port Call
          </Button>
        </Timeline.Item>
      )}
    </Timeline>
  );
});

const VoyageSchedulePortCallDetails = React.memo(_VoyageSchedulePortCallDetails);
export default VoyageSchedulePortCallDetails;
