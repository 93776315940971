import React, { useState } from 'react';
import { collapsibleCard } from '../../Common/common';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { get as _get, clone, startCase } from 'lodash';
import { Button, Popover, Tooltip, Typography, getDateFromUnix } from '@shipmnts/pixel-hub';
import { EditOutlined, useCustomSiderContext } from '@shipmnts/pixel-hub';
import { UpdateRemarks } from '../../LeftSider';
import { Link } from 'wouter';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { SHIPMENT_STATUS_PLANNED } from 'operations/modules/reports/constants';
import { FREIGHT_TYPE_ROAD } from 'operations/modules/shipment/constants';

const { Text } = Typography;

const CreationDetails = () => {
  const { shipment, refetchShipments } = useShipmentDetail();
  const { collapsed, setCollapsed } = useCustomSiderContext();
  const [showRemarksUpdate, setShowRemarksUpdate] = useState<boolean>(false);
  if (!shipment) return <></>;
  const createdByUser = shipment.getUserByName('created_by');
  const bookingRequests = _get(shipment, 'booking_requests', []);

  const creationDetailsData: { [key: string]: string | null | undefined | React.ReactNode } = {
    'Created by': `${_get(createdByUser, 'user.first_name', '')}
    ${_get(createdByUser, 'user.last_name', '')}`,
    'Created at': shipment.created_at ? getDateFromUnix(shipment.created_at) : '-',
  };

  if (shipment.isShipment()) {
    creationDetailsData['Job Date'] = shipment.job_date ? getDateFromUnix(shipment.job_date) : '-';
  }

  creationDetailsData['Job Execution Date'] = shipment.job_execution_date
    ? getDateFromUnix(shipment.job_execution_date)
    : '-';

  creationDetailsData['Job Execution Date'] = shipment.job_execution_date
    ? getDateFromUnix(shipment.job_execution_date)
    : '-';

  const teams: any = clone(shipment.teams) ?? [];
  if (shipment.sales_agent) {
    teams.push({
      role: 'sales',
      sales_person: shipment.sales_agent,
    });
  }

  creationDetailsData['Team Member(s)'] = (
    <div>
      {teams.length
        ? teams.map((team: any, index: number) => {
            return (
              <Tooltip title={startCase(team.role)} key={team.id} trigger={['hover', 'click']}>
                <Text>
                  {team?.user_contact?.name || team?.sales_person?.name}
                  {index !== teams?.length - 1 && ', '}
                </Text>
              </Tooltip>
            );
          })
        : '-'}
    </div>
  );

  creationDetailsData['Tickets'] = shipment?.tickets ? (
    <Typography.Text
      className="trigger"
      onClick={() => {
        setCollapsed(!collapsed);
      }}
      style={{
        cursor: 'pointer',
        color: '#0A4FCA',
      }}
    >
      {shipment?.tickets?.length}
    </Typography.Text>
  ) : (
    '-'
  );
  if (shipment?.isf_filed_by) {
    creationDetailsData['ISF Filed by'] = startCase(shipment?.isf_filed_by);
  }
  creationDetailsData['Remarks'] = (
    <>
      <Typography.Text ellipsis={{ tooltip: true }}>{shipment?.remarks || '-'}</Typography.Text>
      <EditOutlined onClick={() => setShowRemarksUpdate(true)} style={{ color: '#0a4fca' }} />
    </>
  );
  if (shipment.status !== SHIPMENT_STATUS_PLANNED && shipment.freight_type !== FREIGHT_TYPE_ROAD) {
    creationDetailsData['Other Job #'] = shipment.other_job_number;
  }
  if (!!shipment?.inquiry_number) {
    creationDetailsData['Inquiry #'] = (
      <a
        target="_blank"
        href={`/view/rfq/${shipment?.inquiry_option?.inquiry?.inquiry_global_id}`}
        rel="noreferrer"
      >
        {shipment?.inquiry_number}
      </a>
    );
  }

  if (shipment.master_shipment?.id) {
    if (shipment?.isRoadConsolShipment()) {
      creationDetailsData['Customer Order'] = (
        <Link to={`~/view/shipment/${shipment.master_shipment_id}`}>
          {shipment.master_shipment?.job_number}
        </Link>
      );
    } else {
      creationDetailsData['Master Job'] = (
        <Link to={`~/view/shipment/${shipment.master_shipment_id}`}>
          {shipment.master_shipment?.job_number}
        </Link>
      );
    }
  }

  if (shipment?.trip_customer_order?.id && shipment?.isRoadSplitShipment()) {
    creationDetailsData['Customer Order'] = (
      <Link to={`~/view/shipment/${shipment.trip_customer_order?.id}`}>
        {shipment.trip_customer_order?.shipment_booking_number}
      </Link>
    );
  }

  if (shipment.job_number && shipment.shipment_booking_number && !shipment.isRoadShipment()) {
    creationDetailsData['Customer Order(s)'] = shipment.shipment_booking_number;
  }

  if (shipment?.linked_shipment?.job_number) {
    creationDetailsData['Linked Shipments'] = (
      <Link to={`~/view/shipment/${shipment.linked_shipment.id}`}>
        {shipment.linked_shipment.job_number}
      </Link>
    );
  }
  const childLinkedShipments = shipment?.child_linked_shipments;

  if (childLinkedShipments && childLinkedShipments.length > 0) {
    creationDetailsData['Linked Shipments'] = (
      <div>
        <Link to={`~/view/shipment/${childLinkedShipments[0].id}`}>
          {childLinkedShipments[0].job_number}
        </Link>
        {childLinkedShipments.length > 1 && (
          <Popover
            content={
              <>
                {childLinkedShipments.map((cs, index) => {
                  if (index === 0) return <></>;
                  return (
                    <>
                      <Link key={cs.id} to={`~/view/shipment/${cs.id}`}>
                        {cs.job_number}
                      </Link>
                      {index !== childLinkedShipments.length - 1 && <span>{', '}</span>}
                    </>
                  );
                })}
              </>
            }
            trigger={['hover', 'click']}
          >
            <Button
              type="link"
              style={{
                display: 'inherit',
                padding: '0',
                margin: '0',
                fontSize: 'inherit',
              }}
            >
              + {childLinkedShipments.length - 1} more
            </Button>
          </Popover>
        )}
      </div>
    );
  }

  if (bookingRequests && bookingRequests.length > 0) {
    creationDetailsData['Customer Order(s)'] = (
      <div>
        <Link to={`~/view/booking_request/${bookingRequests[0].id}`}>
          {bookingRequests[0].shipment_booking_number}
        </Link>
        {bookingRequests.length > 1 && (
          <Popover
            content={
              <>
                {bookingRequests.map((br, index) => {
                  if (index === 0) return <></>;
                  return (
                    <>
                      <Link key={br.id} to={`~/view/booking_request/${br.id}`}>
                        {br.shipment_booking_number}
                      </Link>
                      {index !== bookingRequests.length - 1 && <span>{', '}</span>}
                    </>
                  );
                })}
              </>
            }
            trigger={['hover', 'click']}
          >
            <Button
              type="link"
              style={{
                display: 'inherit',
                padding: '0',
                margin: '0',
                fontSize: 'inherit',
              }}
            >
              + {bookingRequests.length - 1} more
            </Button>
          </Popover>
        )}
      </div>
    );
  }
  const creationDetails = {
    icon: <CustomIcon icon="Info" />,
    header: 'Creation Details',
    data: creationDetailsData,
  };
  return (
    <div>
      {collapsibleCard({ ...creationDetails })}
      {showRemarksUpdate && (
        <UpdateRemarks
          showRemarksUpdate={showRemarksUpdate}
          setShowRemarksUpdate={setShowRemarksUpdate}
          refetchShipments={() => {
            if (refetchShipments) refetchShipments();
          }}
          shipment={shipment}
        />
      )}
    </div>
  );
};

export default CreationDetails;
