import { message, Popconfirm } from '@shipmnts/pixel-hub';
import { REMOVE_WAREHOUSE_UNIT } from './graphql/warehouseUnit';
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';

interface DeleteWarehouseUnitProps {
  parent: any;
  isVisible?: boolean;
  visible?: boolean;
  onSuccess?: () => void;
  setIsVisible?: (isVisible: boolean) => void;
  refetchWarehouseUnit?: () => void;
}
function DeleteWarehouseUnit(props: DeleteWarehouseUnitProps) {
  const { parent, onSuccess, visible, refetchWarehouseUnit } = props;
  const [open, setOpen] = useState<boolean | undefined>(true);
  const [removeUnit, { data, error }] = useMutation(REMOVE_WAREHOUSE_UNIT);

  useEffect(() => {
    if (error) message.error('Unable to delete');

    if (data && !error) {
      message.success('Node Deleted Successfully');
      if (onSuccess) onSuccess();
      if (refetchWarehouseUnit) refetchWarehouseUnit();
    }
  }, [data, error, onSuccess, refetchWarehouseUnit]);

  if (!parent?.id) return <></>;
  return (
    <>
      {visible && (
        <Popconfirm
          placement="topLeft"
          title={'Do you want to delete Location?'}
          okText="Yes"
          open={open}
          onConfirm={() => {
            removeUnit({
              variables: {
                id: parent?.id,
              },
            });
          }}
          onCancel={() => {
            setOpen(false);
          }}
        ></Popconfirm>
      )}
    </>
  );
}

export default DeleteWarehouseUnit;
