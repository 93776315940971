import {
  DocumentsList,
  UploadDocumentModal,
  UploadDocumentPayload,
  UploadedDocumentType,
  addDocumentParents,
  deleteUploadedDocument,
  documentsStore,
  message,
  uploadDocument,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import React, { forwardRef, useCallback, useImperativeHandle, useReducer } from 'react';

const UploadDoc = forwardRef(function UploadDoc(props: any, ref): JSX.Element {
  const sessionData = useSession();
  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  const uploadDoc = useCallback(async (doc: UploadDocumentPayload) => {
    dispatch({ type: 'uploading', payload: { doc } });
    const { response, error } = await uploadDocument(doc, process.env.DOCGEN_URL || '');
    if (error) {
      dispatch({ type: 'upload_failure', payload: { doc, error: error.message } });
    } else if (response) {
      const uploaded_document = response?.data?.document;
      dispatch({ type: 'upload_success', payload: { doc: uploaded_document } });
    }
  }, []);

  const deleteDoc = useCallback(
    async (doc: UploadedDocumentType) => {
      dispatch({ type: 'deleting', payload: { doc } });
      const { response, error } = await deleteUploadedDocument(
        doc.id,
        sessionData.id,
        process.env.DOCGEN_URL || ''
      );
      if (error) {
        message.error(error.message);
        dispatch({ type: 'delete_failure', payload: { doc } });
      } else if (response) {
        dispatch({ type: 'remove_document', payload: { doc: doc } });
      }
    },
    [sessionData.id]
  );

  const {
    uploadingDocuments = [],
    errors: uploadingError = {},
    documents = [],
  } = documentsStoreValue;

  const updateDocs = useCallback(
    async (shipment_id: string) => {
      if (documents.length > 0) {
        const { response, error } = await addDocumentParents(
          documents.map((d) => d.id),
          [{ parent_type: 'shipment', parent_id: shipment_id }],
          sessionData.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
        } else if (response) {
          dispatch({ type: 'reset_state' });
        }
      }
    },
    [sessionData.id, documents]
  );

  useImperativeHandle(ref, () => ({ updateDocs, uploadDoc }));
  return (
    <>
      <div>
        <UploadDocumentModal onUpload={uploadDoc} sessionData={sessionData} />{' '}
        <DocumentsList
          uploadingDocuments={uploadingDocuments}
          uploadedDocuments={documents}
          uploadingError={uploadingError}
          onUpload={uploadDoc}
          onDelete={deleteDoc}
          sessionData={sessionData}
        />
      </div>
    </>
  );
});
export default UploadDoc;
