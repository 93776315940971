import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Drawer, Form, Table, Typography, message, Skeleton, Alert } from '@shipmnts/pixel-hub';
import { DrawerFooter, Dayjs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { useMutation, useQuery } from '@apollo/client';
import {
  FETCH_SHIPMENT_CONTAINERS,
  UPDATE_CONTAINER_PICKUP_DETAILS,
} from 'operations/modules/booking/graphql/shipmentContainer';
import { get } from 'lodash';
import { errorMessageHandlerGraphQL, errorMessageHandlerGraphQLString } from 'common';
import {
  ShipmentContainerValue,
  validateContainerNumber,
} from 'operations/models/ShipmentContainer';
import ContainerDetailsForm from './ContainerForm';
import { getContainerSetting } from 'operations/modules/reports/oldCellRenderers';
import { STATUS_PICKUP_PENDING } from 'operations/modules/reports/constants';
import { useEmailDrawerContext } from 'common';
import { pick as _pick, cloneDeep as _cloneDeep } from 'lodash';

import { EVENT_EMPTY_PICKED_UP } from 'operations/modules/actionHelper/constants';
import { oceanPackageTypesFlatArray } from 'operations/baseConstants';
import { FREIGHT_TYPE_ROAD } from 'operations/modules/shipment/constants';
const { Text } = Typography;

const checkCargoDetails = (sc: ShipmentContainerValue) => {
  if (!sc || !sc.container_cargo_details) {
    return true;
  }
  return sc.container_cargo_details.every((cargo_det) => {
    if (cargo_det?.type_of_package) {
      const validPackageType = oceanPackageTypesFlatArray.some(
        (oceanPackage) => oceanPackage.value === cargo_det.type_of_package
      );
      return validPackageType;
    } else {
      return true;
    }
  });
};

const validateContainerDetails = (containers: ShipmentContainerValue[]) => {
  let validationError = false;
  const errorContainerNumber = containers
    .map((c) => {
      if (!c.is_non_iso_container && c.container_number) {
        try {
          validateContainerNumber(c.container_number);
        } catch (e) {
          return e;
        }
      }
      return false;
    })
    // eslint-disable-next-line array-callback-return
    .filter((val) => {
      if (val) {
        return String(val);
      }
      return null;
    });
  if (errorContainerNumber[0]) {
    return 'Container Number Wrong';
  }
  let counter = 0;
  let invalidPackageError = false;
  (containers || []).forEach((sc) => {
    const isPresent =
      sc.carrier_seal_number ||
      sc.verified_gross_mass ||
      sc.cargo_net_weight ||
      sc.commercial_invoice_number ||
      sc.purchase_order_number ||
      sc.remarks;
    if (isPresent && !sc.container_number) {
      validationError = true;
    } else if (!(sc.container_number || isPresent)) {
      counter++;
    }

    if (!invalidPackageError) {
      const allCargoValid = checkCargoDetails(sc);
      if (!allCargoValid) {
        invalidPackageError = true;
      }
    }
  });
  if (counter === containers.length) {
    return 'Please enter atleast one container details';
  }
  if (invalidPackageError) return 'Invalid Package Type selected';

  return validationError ? 'Container Number Required' : '';
};

const pickedupContainersColumns = [
  {
    title: 'Container Type',
    dataIndex: 'container_type',
    render: function renderContainerType(text: string, record: ShipmentContainerValue) {
      const { container_type_code, container_settings, container_type } = record;
      const containerSettings = getContainerSetting({
        container_settings,
        container_type_code,
      });
      return containerSettings ? `${container_type} (${containerSettings})` : container_type;
    },
  },
  {
    title: 'Container Number',
    dataIndex: 'container_number',
  },
  {
    title: 'Booking Number',
    dataIndex: 'booking_number',
  },
  {
    title: 'S/L Seal Number',
    dataIndex: 'carrier_seal_number',
  },
];
type Props = {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess: (value: ShipmentContainerValue[], sendEmail: boolean) => void;
};

const AddContainerDetails = (props: Props) => {
  const { onSuccess, shipment, onClose } = props;
  const [form] = Form.useForm();

  // States
  const [validationError, setValidationError] = useState<string | undefined>();
  const [sendEmail, setSendEmail] = useState(false);
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const gridRef = useRef<any>();
  const [pickedupContainers, setPickedupContainers] = useState<any>();
  const [pickupPendingContainers, setPickupPendingContainers] = useState<any>();

  //   Queries/Mutations
  const { data, loading, error } = useQuery(FETCH_SHIPMENT_CONTAINERS, {
    variables: {
      shipment_ids: [shipment.id],
    },
  });
  const [
    updateDetails,
    { data: updateContainerResp, loading: updateContainerLoading, error: updateContainerError },
  ] = useMutation(UPDATE_CONTAINER_PICKUP_DETAILS);

  useEffect(() => {
    if (updateContainerError) {
      console.error(updateContainerError);
      message.error(errorMessageHandlerGraphQLString(updateContainerError));
    }
    if (updateContainerResp && updateContainerResp.update_container_pickup_details) {
      message.success('Container picked up successfully');
      onSuccess(updateContainerResp.update_container_pickup_details.shipment_containers, sendEmail);
      onClose();
    }
  }, [updateContainerResp, updateContainerError, onClose, onSuccess, sendEmail]);

  // let pickedupContainers;
  useEffect(() => {
    let containers: ShipmentContainerValue[] = get(data, 'fetch_shipment_containers', []);
    containers = _cloneDeep(containers);
    if (!shipment.isLcl() && shipment.freight_type !== FREIGHT_TYPE_ROAD) {
      containers = containers.filter((c) => (c.ocean_transport_order_ids || []).length > 0);
    }
    setPickedupContainers(containers.filter((c) => c.status !== STATUS_PICKUP_PENDING));
    setPickupPendingContainers(containers.filter((s) => s.status === STATUS_PICKUP_PENDING));
  }, [data, shipment]);

  // Handlers
  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const onSubmit = () => {
    const containers: any = [];
    gridRef.current.api.forEachNode((node: any) => {
      containers.push(node.data);
    });
    const errorMessage = validateContainerDetails(containers);
    if (errorMessage) {
      setValidationError(errorMessage);
    } else {
      setValidationError(undefined);
      const payload = {
        shipment_containers: containers
          .filter(
            (c: { container_number: string; pickup_date: Dayjs }) =>
              c.container_number && c.pickup_date
          )
          .map(
            (c: { container_number: string; pickup_date: Dayjs; container_cargo_details: [] }) => ({
              ..._pick(c, [
                'id',
                'is_non_iso_container',
                'container_number',
                'carrier_seal_number',
                'cargo_gross_weight',
                'verified_gross_mass',
                'cargo_net_weight',
                'pickup_date',
                'commercial_invoice_number',
                'purchase_order_number',
                'remarks',
              ]),
              pickup_date: c.pickup_date,
              container_cargo_details: c.container_cargo_details.map((ccd: any) =>
                (({ __typename, ...otherFields }) => otherFields)(ccd)
              ),
            })
          ),
      };
      updateDetails({ variables: payload }).then((res) => {
        if (sendEmail) {
          const event_ids = res?.data?.update_container_pickup_details?.shipment_containers.map(
            (container: ShipmentContainerValue) =>
              container.tracking_events?.find((event) => event.name === EVENT_EMPTY_PICKED_UP)?.id
          );
          setEmailProps({
            action_name: 'tracking_event_notification_bulk',
            docTypeId: 'Shipment::TrackingEvent',
            resource_ids: event_ids,
            fetchDocumentParents: [
              {
                parent_type: 'tracking_event',
                parent_ids: event_ids,
              },
            ],
            title: 'Send Container Picked Up Notification',
          });
          setVisible(true);
        }
      });
    }
  };

  if (loading) {
    return (
      <Drawer key="update_pickup_details" width={'60%'} visible onClose={onClose}>
        <Skeleton paragraph={{ rows: 4 }} loading={loading} active />
      </Drawer>
    );
  }

  if (error) {
    return (
      <Drawer key="update_pickup_details" width={'60%'} visible onClose={onClose}>
        <Alert
          message="Unable to fetch Container Details."
          description={errorMessageHandlerGraphQL(error)}
          type="error"
          showIcon
          closable
        />
      </Drawer>
    );
  }

  return (
    <Drawer
      key="update_pickup_details"
      title={<div style={{ display: 'flex', alignItems: 'center' }}>Add Containers Details</div>}
      width={'80%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Submit"
          closeText="Cancel"
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Pickup Confirmation"
          loading={updateContainerLoading}
          onClose={closeDrawer}
          onSave={form.submit}
        />
      }
    >
      <Alert
        message="Paste container details in bulk / Enter the details in the table"
        style={{ marginBottom: '20px' }}
      />
      {validationError && <Alert message={validationError} type="error" showIcon closable />}
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <div style={pickedupContainers?.length > 0 ? {} : { margin: '16px 0 44px' }}>
          <ContainerDetailsForm
            otoContainers={pickupPendingContainers}
            gridRef={gridRef}
            shipment={shipment}
          />
        </div>
        {pickedupContainers.length > 0 && (
          <div style={{ margin: '16px 0 44px' }}>
            <Text style={{ fontSize: '16px', fontWeight: 500 }}>Pickedup Containers</Text>
            <Table
              style={{ marginTop: '16px' }}
              dataSource={pickedupContainers}
              columns={pickedupContainersColumns}
              pagination={false}
              rowKey={(record) => record.id || ''}
              size="small"
            />
          </div>
        )}
      </Form>
    </Drawer>
  );
};

export default AddContainerDetails;
