/** 
  PURPOSE: to store surcharges related to a contract.

  CONFIRM: values of charge_terms 
*/

import Location, { LocationValue } from 'common/models/Location';
import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { ContractCharge, ContractChargeValue } from './ContractCharge';

export const ContractSurcharge = types
  .model({
    id: types.identifier,
    charge_name: types.maybeNull(types.string),
    charge_basis: types.maybeNull(types.string),
    charge_terms: types.maybeNull(types.enumeration(['prepaid', 'collect'])),
    ports_of_discharge: types.optional(types.array(Location), []),
    currency: types.maybeNull(types.string),
    fixed_charge: types.maybeNull(types.number),
    remarks: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    _destroy: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setChargeName: (value: string) => (self.charge_name = value),
    setChargeBasis: (value: string) => {
      self.charge_basis = value;
      if (value === 'shipment') {
        self.contract_charges?.forEach((charge) => charge.setDestroy(true));
      } else {
        self.contract_charges?.forEach((charge) => charge.setDestroy(false));
        self.fixed_charge = null;
      }
    },
    setChargeTerms: (value: 'prepaid' | 'collect') => (self.charge_terms = value),
    setCurrency: (value: string) => (self.currency = value),
    setFixedCharge: (value: number) => (self.fixed_charge = value),
    setRemarks: (value: string) => (self.remarks = value),
    addPOD: (value: LocationValue) => {
      self.ports_of_discharge.push(value);
    },
    removeAllPOD: () => {
      self.ports_of_discharge.clear();
    },
    addContractCharge: (charge: ContractChargeValue) => {
      self.contract_charges.push(charge);
    },
    setChargeByContainerType: (type: string, amount: number) => {
      const charge = self.contract_charges.find((charge) => charge.container_type === type);
      if (charge && !charge.isDestroyed()) {
        charge.setChargeAmount(amount);
      } else {
        const charge = ContractCharge.create({
          id: 'new',
          container_type: type,
          charge_amount: amount,
          end_weight: null,
        });
        self.contract_charges.push(charge);
      }
    },
    setDestroy: (value: boolean) => (self._destroy = value),
    isDestroy: () => self._destroy,
  }))
  .views((self) => ({
    getChargeName: () => self.charge_name,
    getChargeBasis: () => self.charge_basis,
    getChargeTerms: () => self.charge_terms,
    getCurrency: () => self.currency,
    getFixedCharge: () => self.fixed_charge,
    getRemarks: () => self.remarks,
    getAllPOD: () => self.ports_of_discharge,
    getChargeByContainerType: (type: string) => {
      const charge = self.contract_charges.find(
        (charge) => charge.container_type === type && !charge.isDestroyed()
      );
      return charge ? charge.charge_amount : null;
    },
    getPayload: (removeIds = false) => {
      const surcharge = getSnapshot(self);
      const { contract_charges, ports_of_discharge, ...rest } = surcharge;
      return {
        ...rest,
        contract_charges: self.contract_charges.map((charge) => charge.getPayload()),
        port_of_discharge_ids: self.ports_of_discharge.map((location) => location.id),
        id: self.id === 'new' || removeIds ? null : self.id,
        _destroy: surcharge._destroy ? true : undefined,
      };
    },
  }));

export type ContractSurchargeValue = Instance<typeof ContractSurcharge>;

export default ContractSurcharge;
