import {
  Button,
  DeleteOutlined,
  Flex,
  Popconfirm,
  Select,
  Table,
  TableProps,
  Tag,
} from '@shipmnts/pixel-hub';
import { useSession } from 'common/utils/SessionContext';
import { isEqual, pick, startCase, uniq } from 'lodash';
import {
  TEAM_ROLE_CREDIT_CONTROLLER,
  TEAM_ROLE_DOCUMENTATION,
  TEAM_ROLE_OPTIONS,
  TEAM_ROLE_SALES,
} from 'network/baseConstants';
import { TeamValue } from 'network/commonTypeDefs';
import { SALES_PERSON_DISABLED_FEATURE } from 'operations/baseConstants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GlobalSearch } from '@shipmnts/pixel-hub';
interface TableFromProps {
  form?: any;
  editable?: boolean;
  value?: Array<TeamValue>;
  onChange?: (value: Array<TeamValue>) => void;
  reference_type: string;
  reference_id?: string;
  setAllowSubmit?: (value: boolean) => void;
  fetchedTeamFromCustomer?: Array<TeamValue> | undefined;
}

const REFERENCES_TYPE_WITH_MULTIPLE_TEAM_ROLE = ['Network::Company'];
const SALES_PERSON_DISABLED_DOCTYPES = ['Shipment::Shipment', 'SalesHub::Inquiry'];

const mapValueToTableData = (value: Array<TeamValue>, reference_type: string): Array<TeamValue> => {
  return value?.map((value) => {
    let recordDisabled = false;
    if (value.role === 'sales' && reference_type === 'Shipment::Shipment') recordDisabled = true;
    if (value.preFilled) recordDisabled = false;
    return {
      ...value,
      isViewOnly: recordDisabled,
    };
  });
};

export const getRoleOptions = (reference_type: string, editable = true) => {
  let options = TEAM_ROLE_OPTIONS;
  if (editable) {
    if (reference_type === 'SalesHub::Inquiry') {
      options = options.filter(
        (role) => ![TEAM_ROLE_CREDIT_CONTROLLER, TEAM_ROLE_DOCUMENTATION].includes(role.value)
      );
    } else if (reference_type === 'Shipment::Shipment') {
      options = options.filter((role) => ![TEAM_ROLE_SALES].includes(role.value));
    }
  }
  return options;
};

const TeamFormTable = (props: TableFromProps) => {
  const {
    form,
    editable = true,
    value,
    reference_type,
    reference_id,
    setAllowSubmit,
    onChange,
    fetchedTeamFromCustomer,
  } = props;
  const [tableData, setTableData] = useState<Array<TeamValue>>([
    ...mapValueToTableData(value ?? [], reference_type),
    ...mapValueToTableData(fetchedTeamFromCustomer ?? [], reference_type),
  ]);
  const [duplicateRows, setDuplicateRows] = useState<Array<number>>([]);
  const session = useSession();

  const isSalesPersonDisabled = useMemo(
    () => session?.isFeatureEnabled(SALES_PERSON_DISABLED_FEATURE),
    [session]
  );

  const { company_account } = session;

  const addRow = useCallback(() => {
    setTableData((prev) => [
      ...prev,
      {
        reference_type,
        reference_id,
      } as TeamValue,
    ]);
    onChange &&
      onChange([
        ...tableData,
        ...[
          {
            reference_type,
            reference_id,
          } as any,
        ],
      ]);
  }, [reference_id, reference_type, tableData, onChange]);

  const removeTableRow = (index: number) => {
    const newTableData = tableData.filter((_, i) => i !== index);
    setTableData(newTableData);
    onChange && onChange(newTableData);
  };

  const handleChanges = useCallback(
    (key: any, index: number, value: any) => {
      //todo-team remove any
      const newData: any = [...tableData];
      newData[index][key] = value;
      setTableData(newData);
      onChange && onChange(newData);
    },
    [onChange, tableData]
  );

  useEffect(() => {
    setAllowSubmit && setAllowSubmit(!duplicateRows.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateRows]);

  useEffect(() => {
    if (!isEqual(value, tableData) || value?.length === 0) {
      const newTableData: any = mapValueToTableData(value ?? [], reference_type);
      if (editable) {
        const presentRoles = uniq(value?.map((data) => data.role));
        getRoleOptions(reference_type, editable).forEach((option) => {
          if (!presentRoles.includes(option.value)) {
            newTableData.push({
              role: option.value,
            });
          }
        });
      }
      setTableData(newTableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (editable) {
      form?.validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, editable]);

  const areEqual = useCallback((obj1: TeamValue, obj2: TeamValue) => {
    if (obj1.role !== obj2.role || !obj1.role || !obj2.role) return false;
    if (!isEqual(obj1.user_contact, obj2.user_contact) || !obj1.user_contact || !obj2.user_contact)
      return false;
    if (
      !isEqual(obj1.branch_accounts, obj2.branch_accounts) ||
      !obj1.branch_accounts ||
      !obj2.branch_accounts
    )
      return false;
    if (obj1.role === 'sales') {
      if (
        !isEqual(obj1.sales_person, obj2.sales_person) ||
        !obj1.sales_person ||
        !obj2.sales_person
      )
        return false;
    }
    return true;
  }, []);

  const getDuplicateRows = useCallback(
    (arr: Array<TeamValue>) => {
      const duplicateRows: Array<number> = [];
      for (let i = 0; i < arr.length; i++) {
        const duplicate = arr.find(
          (item: TeamValue, index: number) => index !== i && areEqual(arr[i], item)
        );
        if (duplicate) {
          duplicateRows.push(i + 1);
        }
      }
      return duplicateRows;
    },
    [areEqual]
  );

  useEffect(() => {
    setDuplicateRows(getDuplicateRows(tableData));
  }, [getDuplicateRows, tableData]);

  const getColumns = useCallback(() => {
    const columnDefs: TableProps<TeamValue>['columns'] = [
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        hidden: !editable,
        width: '5%',
        render(value, record, index) {
          if (duplicateRows.includes(index + 1)) {
            return (
              <Flex gap={5}>
                <span>{index + 1}</span>
                <Tag color="red">Duplicate</Tag>
              </Flex>
            );
          }
          return index + 1;
        },
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: '20%',
        render(value, record, index) {
          if (!editable || record.isViewOnly) return startCase(value);
          return (
            <Select
              options={getRoleOptions(reference_type, editable)}
              value={value}
              onChange={(value) => {
                handleChanges('role', index, value);
              }}
              style={{ width: '100%' }}
              popupMatchSelectWidth={150}
            />
          );
        },
      },
      {
        title: 'Team Member',
        dataIndex: 'team_member',
        key: 'team_member',
        width: '25%',
        render(value, record, index) {
          if (record.role === TEAM_ROLE_SALES) {
            if (!editable || record.isViewOnly)
              return <span>{record.sales_person?.name ?? '-'}</span>;
            return (
              <GlobalSearch
                doc_type="Network::SalesPerson"
                value={record.sales_person}
                onChange={(value) => {
                  handleChanges('sales_person', index, value);
                }}
                disabled={
                  !record.role ||
                  (SALES_PERSON_DISABLED_DOCTYPES.includes(reference_type) && isSalesPersonDisabled)
                }
              />
            );
          }
          if (!editable || record.isViewOnly) return <span>{record.user_contact?.name}</span>;
          return (
            <GlobalSearch
              doc_type="Network::UserContact"
              value={record.user_contact as any}
              onChange={(value) => {
                handleChanges('user_contact', index, value);
              }}
              disabled={!record.role}
              showCreation={false}
              extraProps={{ company: company_account.default_company }}
            />
          );
        },
      },
      {
        title: 'Handling Branch',
        dataIndex: 'branch_accounts',
        key: 'branch_accounts',
        width: '45%',
        render(value, record, index) {
          if (!editable || record.isViewOnly) {
            return (
              <div>
                {value?.map((v: any) => (
                  <Tag key={v.id}>{v.name}</Tag>
                ))}
              </div>
            );
          }
          return (
            <GlobalSearch
              doc_type="Network::BranchAccount"
              value={value}
              onChange={(value) => {
                handleChanges(
                  'branch_accounts',
                  index,
                  (value as any).map((v: any) => pick(v, ['id', 'name']))
                );
              }}
              selectMode="multiple"
              disabled={!editable}
            />
          );
        },
        hidden: reference_type === 'Shipment::Shipment' || reference_type === 'SalesHub::Inquiry',
      },
      {
        hidden:
          !editable ||
          !(
            REFERENCES_TYPE_WITH_MULTIPLE_TEAM_ROLE.includes(reference_type) ||
            getRoleOptions(reference_type, editable).length > tableData.length
          ),
        width: '5%',
        render(value, record, index) {
          if (record.isViewOnly) return <></>;
          return (
            <>
              {record.role ? (
                <Popconfirm
                  key={index}
                  title={`Are you sure?`}
                  onConfirm={() => removeTableRow(index)}
                >
                  <Button key={index} danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              ) : (
                <Button key={index} danger onClick={() => removeTableRow(index)}>
                  <DeleteOutlined />
                </Button>
              )}
            </>
          );
        },
      },
    ];

    return columnDefs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateRows, editable, handleChanges, reference_type]);

  return (
    <div className="team-table">
      <Table
        columns={getColumns()}
        dataSource={tableData}
        pagination={false}
        scroll={editable ? { x: 'max-content', y: 300 } : {}}
        style={{ width: '100%' }}
      />
      {editable &&
        (REFERENCES_TYPE_WITH_MULTIPLE_TEAM_ROLE.includes(reference_type) ||
          getRoleOptions(reference_type, editable).length > tableData.length) && (
          <Button style={{ marginTop: '10px' }} onClick={addRow}>
            Add Row
          </Button>
        )}
    </div>
  );
};

export default TeamFormTable;
