import React, { useState } from 'react';
import { DetailsCard } from 'common';
import { countries, isCustomer, isVendor } from 'common';
import { WithPermission } from '@shipmnts/pixel-hub';
import { useCompanyView } from '../CompanyView';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { SectionEditorModal } from '../SectionEditorModal';
import CompanyFormBasicDetails from 'network/components/CompanyFormBasicDetails';
import { getFormInitialValues } from 'network/components/companyDetailView/CompanyOverviewDetails';
import { Button } from '@shipmnts/pixel-hub';
import { getPrimarySalesPersonFromTeams } from 'common/helpers/helper';

export const CompanyDetailsCard = () => {
  const { company, loading, is_lead, is_customer } = useCompanyView();
  const [editMode, setEditMode] = useState(false);

  // figuring out the permissions
  let editPermission = undefined;
  if (isCustomer(company?.company_type) || isVendor(company?.company_type))
    editPermission = {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    };

  const data = company
    ? {
        'Country Of Incorporation': countries.find(
          (country) => country.code === company?.country_of_incorporation
        )?.name,
        'Company Identification #': company?.company_identification_number,
        'Company Group': company?.company_group,
        'Company Type': company?.entity_type,
        'Tax ID': company?.tax_registration_number,
        'Sales Person':
          is_lead && !is_customer ? getPrimarySalesPersonFromTeams(company?.teams)?.name : null,
        'Domain URL': company?.domain,
      }
    : {};

  return (
    <DetailsCard
      className="responsive-card-15"
      loading={loading}
      header={{ title: 'Company Details' }}
      style={{ width: '330px' }}
      Action={
        <WithPermission permission={editPermission}>
          <Button
            disabled={company?.is_disabled || company?.company_group === 'Self'}
            onClick={() => !!!company?.is_disabled && setEditMode(true)}
            size="small"
          >
            {'Edit'}
          </Button>
        </WithPermission>
      }
    >
      <>
        {Object.entries(data)
          .filter(([_, value]) => value)
          .map(([label, value]) => {
            return (
              <div
                key={label}
                style={{ display: 'flex', gap: '10%', marginBottom: '1em', alignItems: 'center' }}
              >
                <span
                  style={{
                    fontWeight: '800px',
                    opacity: '.6',
                    width: '45%',
                  }}
                >
                  {label}
                </span>
                <span
                  style={{
                    width: '45%',
                    wordWrap: 'break-word',
                  }}
                >
                  {value}
                </span>
              </div>
            );
          })}

        {editMode && company && (
          <SectionEditorModal
            title="Company Details"
            show={editMode}
            setShow={setEditMode}
            initialValues={getFormInitialValues(company)}
          >
            {({ form }) => (
              <CompanyFormBasicDetails
                form={form}
                company_id={company.id}
                company={company}
                is_customer={is_customer}
                is_lead={is_lead}
              />
            )}
          </SectionEditorModal>
        )}
      </>
    </DetailsCard>
  );
};
