import React, { useEffect, useState } from 'react';
import { AddressCompanySearch } from 'common';
import {
  Row,
  Col,
  Input,
  Form,
  Radio,
  Select,
  DatePicker,
  GlobalSearch,
  message,
} from '@shipmnts/pixel-hub';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  LOAD_TYPE_LCL,
  BOOKING_TYPE_SELF,
  LOAD_TYPE_FCL,
  BOOKING_TYPE_VENDOR,
} from 'operations/baseConstants';
import { CarrierType } from 'operations/models/Carrier';
import { BOOKING_TYPE_MAP } from 'common/baseConstants';
import { FormInstance } from 'antd/es/form/Form';
import { SHIPPING_LINE_SERVICE_TYPES, TRADE_TYPE_IMPORT } from '../../constants';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import BookingOrderNewDesign from 'operations/modules/booking/components/BookingOrderForm/BookingOrderNewDesign';
import { InquiryOptionValue } from 'operations/models/InquiryOption';
import { getIsSelectedCarrierMatchingWithVoyageSchedule } from 'operations/modules/helpers';
import { useApolloClient } from '@apollo/client';

const ROW_GUTTER = 16;

const CarrierBooking = React.memo(function CarrierBooking(props: {
  disabledFields?: Array<string>;
  form: FormInstance;
  inquiryOption?: InquiryOptionValue;
}): JSX.Element {
  const { disabledFields, form, inquiryOption } = props;
  const bookingParty = Form.useWatch('booking_party', form);
  const loadType = Form.useWatch('load_type', form);
  const tradeType = Form.useWatch('trade_type', form);
  const [disableBookingParty, setDisableBookingParty] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    setDisableBookingParty(!!bookingParty);
  }, [bookingParty]);

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={24 / 4}>
          <Form.Item name="booking_type" label="Booking Type" required={true}>
            <Radio.Group
              disabled={loadType !== LOAD_TYPE_FCL || tradeType === TRADE_TYPE_IMPORT}
              optionType="button"
            >
              {Object.entries(BOOKING_TYPE_MAP).map(([key, value], index) => (
                <Radio
                  key={index}
                  value={key}
                  disabled={loadType !== LOAD_TYPE_FCL || tradeType === TRADE_TYPE_IMPORT}
                >
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24 / 4}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="booking_party"
            label="Booking Party"
          >
            <AddressCompanySearch
              companySearchProps={{ disabled: disableBookingParty }}
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24 / 4}>
          <Form.Item noStyle dependencies={['load_type', 'booking_type']}>
            {({ getFieldValue }) => {
              const loadType = getFieldValue('load_type');
              const bookingType = getFieldValue('booking_type');
              const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
              if (loadType === LOAD_TYPE_LCL) carrier_types.push('coloader');
              return (
                <Form.Item
                  required={bookingType === BOOKING_TYPE_SHIPPING_LINE}
                  rules={[
                    {
                      required: bookingType === BOOKING_TYPE_SHIPPING_LINE,
                    },
                  ]}
                  name="carrier"
                  label={loadType === LOAD_TYPE_LCL ? 'Carrier/Co-loader' : 'Carrier'}
                >
                  <GlobalSearch
                    doc_type="Global::Carrier"
                    disabled={disabledFields?.includes('carrier')}
                    searchProps={{ carrier_type: carrier_types }}
                    onChange={async (value) => {
                      const voyage_schedule_id = form.getFieldValue('voyage_schedule_id');
                      if (voyage_schedule_id) {
                        const isMatch = await getIsSelectedCarrierMatchingWithVoyageSchedule(
                          client,
                          voyage_schedule_id,
                          value?.id
                        );
                        if (!isMatch) {
                          form.setFieldValue('voyage_schedule_id', null);
                          message.info(
                            'Carrier changed, please select matching voyage schedule again'
                          );
                        }
                      }
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['confirmed_booking', 'load_type', 'booking_type']}>
          {({ getFieldValue }) => {
            const confirmed_booking = getFieldValue('confirmed_booking');
            const bookingType = getFieldValue('booking_type');
            const required = bookingType === BOOKING_TYPE_SHIPPING_LINE && confirmed_booking;
            if (bookingType === BOOKING_TYPE_SELF) return <></>;
            return (
              <Col span={24 / 4}>
                <Form.Item
                  required={required}
                  rules={[
                    {
                      required,
                    },
                  ]}
                  name="booking_number"
                  label="Booking Number"
                >
                  <Input placeholder="Booking number" />
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>
        <Col span={24 / 4}>
          <Form.Item
            rules={[
              {
                required: inquiryOption?.inquiry?.load_type !== LOAD_TYPE_LCL,
              },
            ]}
            name="booking_date"
            label="Booking Date"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Service Type"
            name={'service_type'}
          >
            <Select placeholder="Select Port to Port, ICD to Port...">
              {SHIPPING_LINE_SERVICE_TYPES.map((option: RadioOptionProps, index: number) => (
                <Select.Option key={index} value={option.key}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['load_type', 'booking_type']}>
          {({ getFieldValue }) => {
            const loadType = getFieldValue('load_type');
            const bookingType = getFieldValue('booking_type');
            const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
            if (loadType === LOAD_TYPE_LCL) carrier_types.push('coloader');
            if (bookingType === BOOKING_TYPE_VENDOR) {
              return (
                <Col span={24 / 4}>
                  <Form.Item name="vendor" label="Booking Vendor">
                    <AddressCompanySearch
                      addressSearchProps={{
                        showAddBranchAction: true,
                      }}
                    />
                  </Form.Item>
                </Col>
              );
            }
            return <></>;
          }}
        </Form.Item>
        <Col span={24 / 4}>
          <Form.Item name="sales_person" label="Order By">
            <GlobalSearch doc_type="Network::SalesPerson" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.confirmed_booking !== currentValues.confirmed_booking ||
            prevValues.routing_details !== currentValues.routing_details ||
            prevValues.booking_type !== currentValues.booking_type
          }
        >
          {({ getFieldValue }) => {
            const confirmed_booking = getFieldValue('confirmed_booking');
            const bookingType = getFieldValue('booking_type');
            return (
              <BookingOrderNewDesign
                form={form}
                bookingType={bookingType}
                loadType={loadType}
                areCutoffRequired={confirmed_booking && bookingType === BOOKING_TYPE_SHIPPING_LINE}
                tradeType={tradeType}
              />
            );
          }}
        </Form.Item>
        <Col span={24 / 4}>
          {/* {load_type === LOAD_TYPE_FCL && ( */}
          <Form.Item name="contract_number" label="RA Number">
            <Input placeholder="RA number" />
          </Form.Item>
          {/* )} */}
        </Col>
      </Row>
    </>
  );
});

export default CarrierBooking;
