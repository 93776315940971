import React from 'react';
import { useParams } from 'wouter';
import Dashboard from './Dashboard';
import { useSession } from 'common';
import { Result } from '@shipmnts/pixel-hub';

const DashboardRouter = () => {
  const params = useParams<{ id: string }>();
  const user = useSession();
  const dashboard_id = params.id;

  return user.company_account?.fin_database && dashboard_id ? (
    <Dashboard id={dashboard_id} />
  ) : (
    <div className="centered fullscreen">
      <Result status="500" title="Unable to fetch your information." />
    </div>
  );
};

export default DashboardRouter;
