import { Col, DocumentUpload, Form, Row } from '@shipmnts/pixel-hub';
import React from 'react';
import { useInquiryForm } from './InquiryForm';

const InquiryDocumentUpload = ({ documentsStoreValue, dispatch }: any) => {
  const { inquiry } = useInquiryForm();
  return (
    <>
      <h3 className="inq-form-section-title">{'Document Upload'}</h3>
      <Row>
        <Col span={24}>
          <Form.Item label="">
            <DocumentUpload
              data={{ id: inquiry?.id }}
              parentType="inquiry"
              documentsStoreValue={documentsStoreValue}
              dispatch={dispatch}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default InquiryDocumentUpload;
