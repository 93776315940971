import React, { Suspense, useEffect, useState } from 'react';
import { Route, Link, Switch, Router } from 'wouter';
// import Home from './components/Home';
import { Result, Button, FallbackSkeleton, ReportContext, TaskForm } from '@shipmnts/pixel-hub';
import { AppWrapper, DOC_TYPE_METHODS } from 'common';
import {
  OperationsViewLayout,
  OperationsFormLayout,
  BookingOrderView,
  ShipmentDetailLayout,
  BookingRequestView,
  VoyageScheduleForm,
  AwbStockModal,
  ActionRendererReport,
  DocumentPrintPreview,
  ActionRenderer,
  BulkActionDrawer,
  WMSDetailLayoutWrapper,
  CreateProductFormDrawer,
  WarehouseTreeLayout,
  CreateWarehouseUnit,
} from 'operations';
import { CreateVehicleDrawer, CreateDriverDrawer } from 'common';
import {
  CompanyForm,
  CompanyViewWrapper,
  NetworkFormLayout,
  NetworkViewLayout,
  TemplateDetailLayout,
  ContactViewWrapper,
  ContactForm,
} from 'network';

import {
  CreateInquiryDrawer,
  CreateLeadDrawer,
  InquiryDetailLayout,
  InquiryOptionDetailLayout,
  SalesHubViewLayout,
} from 'sales_hub';
import HandleLinkFromFinance from './HandleLinkFromFinance';
import RedirectToFinance from 'common/components/RedirectToFinance';
import { WorkspaceLayout, WorkspaceLayoutAnotherWrapper } from '@shipmnts/pixel-hub';
import OMSDetailLayoutWrapper from 'operations/modules/orderManagement/OMS/OMSDetailLayout';
import {
  CombinedPropertyIconRenderer,
  CompanyAddressRenderer,
  CompanyContactsRenderer,
  ContainerStatusRenderer,
  CreditStatusRenderer,
  DimensionsWithUOM,
  EawbMessageDataRenderer,
  EawbMessageTypeRenderer,
  Link_map,
  QtyUomTypeRenderer,
  ShipmentDocumentStatusRenderer,
} from 'common/report_manager/components/Renderer/cellRenderer';
import {
  ServiceManagementFormLayout,
  ServiceManagementViewLayout,
  TicketForm,
  TicketDetailLayout,
} from 'service_management';
import { useLocationWithConfirmation } from 'common/utils/hooks';
import { CreateProductFormLayout } from 'operations/modules/productCatalogue/CreateProductForm';
import { CreateWarehouseFormLayout } from 'operations/modules/warehouseManagement/CreateWarehouseUnit';
import StockLedgerReport from 'operations/modules/warehouseManagement/StockLedgerReport';
import RateManagementLayout from './packages/rate_management/components/RateManagementLayout';
import LandingPage from './components/LandingPage';
import DashboardRouter from './components/DashboardRouter';

const BaseLayout = () => {
  const [reportManager, setReportManager] = useState({});
  useEffect(() => {
    // Update pixel data as needed
    setReportManager({
      doc_type_to_layout: {
        'Shipment::OceanTransportOrder': BookingOrderView,
        'Shipment::Shipment': ShipmentDetailLayout,
        'Shipment::BookingRequest': BookingRequestView,
        'Network::VoyageSchedule': VoyageScheduleForm,
        'Network::Company': CompanyViewWrapper,
        'SalesHub::InquiryGlobal': InquiryDetailLayout,
        'SalesHub::InquiryOption': InquiryOptionDetailLayout,
        'Network::Template': TemplateDetailLayout,
        'OrderManagement::ProductOrderGlobal': OMSDetailLayoutWrapper,
        'Wms::WarehouseTransaction': WMSDetailLayoutWrapper,
        'Wms::IncomingShipment': WMSDetailLayoutWrapper,
        'Wms::Receipt': WMSDetailLayoutWrapper,
        'Wms::OutgoingShipment': WMSDetailLayoutWrapper,
        'Wms::Delivery': WMSDetailLayoutWrapper,
        'Wms::Product': CreateProductFormLayout,
        'Wms::WarehouseUnit': CreateWarehouseFormLayout,
        'Network::UserContact': ContactViewWrapper,
        'ServiceManagement::Ticket::Ticket': TicketDetailLayout,
      },
      create_model_map: {
        AwbStockModal: AwbStockModal,
        CreateVehicleDrawer: CreateVehicleDrawer,
        CreateDriverDrawer: CreateDriverDrawer,
        CreateInquiryModal: CreateInquiryDrawer,
        CreateLeadDrawer: CreateLeadDrawer,
        CreateProduct: CreateProductFormDrawer,
        CreateWarehouse: CreateWarehouseUnit,
        TicketForm: TicketForm,
        TaskForm: TaskForm,
        ContactForm: ContactForm,
      },
      dependent_component: {
        ActionRendererReport: ActionRendererReport,
        DocumentPrintPreview: DocumentPrintPreview,
        ActionRenderer: ActionRenderer,
        BulkActionDrawer: BulkActionDrawer,
        CompanyForm: CompanyForm,
      },
      cell_renders: {
        shipment_document_status_renderer: ShipmentDocumentStatusRenderer,
        credit_status_render: CreditStatusRenderer,
        container_status_render: ContainerStatusRenderer,
        eawb_message_type_renderer: EawbMessageTypeRenderer,
        eawb_message_data_renderer: EawbMessageDataRenderer,
        qty_uom_type_renderer: QtyUomTypeRenderer,
        dimensions_with_uom: DimensionsWithUOM,
        combined_property_icon_renderer: CombinedPropertyIconRenderer,
        companies_contact_renderer: CompanyContactsRenderer,
        companies_address_renderer: CompanyAddressRenderer,
      },
      DOC_TYPE_METHODS: DOC_TYPE_METHODS,
      LINK_MAP: Link_map,
    });
  }, []);
  return (
    <Router hook={useLocationWithConfirmation}>
      <ReportContext.Provider value={reportManager}>
        <AppWrapper>
          <Switch>
            <Route path={'/'}>
              {/* <Home /> */}
              <LandingPage />
            </Route>

            <Route path="/form" nest>
              <Suspense fallback={<FallbackSkeleton />}>
                <NetworkFormLayout />
              </Suspense>
              <Suspense fallback={<FallbackSkeleton />}>
                <OperationsFormLayout />
              </Suspense>
              <Suspense fallback={<FallbackSkeleton />}>
                <ServiceManagementFormLayout />
              </Suspense>
              <Suspense fallback={<FallbackSkeleton />}>
                <RateManagementLayout />
              </Suspense>
            </Route>

            <Route path="/view" nest>
              <Suspense fallback={<FallbackSkeleton />}>
                <NetworkViewLayout />
              </Suspense>
              <Suspense fallback={<FallbackSkeleton />}>
                <OperationsViewLayout />
              </Suspense>
              <Suspense fallback={<FallbackSkeleton />}>
                <SalesHubViewLayout />
              </Suspense>
              <Suspense fallback={<FallbackSkeleton />}>
                <ServiceManagementViewLayout />
              </Suspense>
            </Route>

            <Route path="/warehouse/tree">
              <Suspense fallback={<FallbackSkeleton />}>
                <WarehouseTreeLayout />
              </Suspense>
            </Route>
            <Route path="/warehouse/stock_ledger">
              <Suspense fallback={<FallbackSkeleton />}>
                <StockLedgerReport />
              </Suspense>
            </Route>

            <Route path="/workspace/:workspace_id" nest>
              <Suspense fallback={<FallbackSkeleton />}>
                <WorkspaceLayout />
              </Suspense>
            </Route>

            <Route path="/workspace">
              <Suspense fallback={<FallbackSkeleton />}>
                <WorkspaceLayoutAnotherWrapper />
              </Suspense>
            </Route>

            <Route path="/handle_link_from_finance/:doctype/:name">
              <HandleLinkFromFinance />
            </Route>

            <Route path="/fin">
              <Suspense fallback={<FallbackSkeleton />}>
                <RedirectToFinance />
              </Suspense>
            </Route>

            <Route path="/dashboard/:id">
              <DashboardRouter />
            </Route>

            <Route>
              <Result
                status={404}
                title="Page Not Found"
                extra={
                  <Button type="primary">
                    <Link to="/">Go Home</Link>
                  </Button>
                }
              />
            </Route>
          </Switch>
        </AppWrapper>
      </ReportContext.Provider>
    </Router>
  );
};

export default BaseLayout;
