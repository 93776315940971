/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  CustomerCreditStatusTag,
  CreditLimitCheckWrapper,
  CreditLimitCheckContext,
} from '@shipmnts/pixel-hub';

import { constants } from 'network';

import CompanySearch from 'common/components/CustomInputs/CompanySearch/CompanySearch';
import {
  CompanyValue,
  COMPANY_STATUS_LEGAL_DISPUTE,
  COMPANY_STATUS_ARRAY,
} from 'common/models/Company';
import { useSession } from 'common/utils/SessionContext';

export type highlightType = { registered_name?: string };

export interface SelectValue {
  value: string;
  label: string;
  key: string;
}
interface RunCreditRulesProps {
  actor_id: string;
  trigger: string;
  actor_type: string;
  customer_name: string;
}

export interface CustomerSearchProps {
  value?: CompanyValue | undefined | Array<CompanyValue>;
  onChange?: (value: CompanyValue | undefined | Array<CompanyValue>) => void;
  trigger?: string;
  disabled?: boolean;
  selectMode?: 'multiple';
}

interface CustomerSearchInternalProps extends CustomerSearchProps {
  runCreditRules: (props: RunCreditRulesProps) => Promise<boolean>;
}

export const CustomerSearch = (props: CustomerSearchInternalProps): JSX.Element => {
  const { value, runCreditRules, trigger, disabled, selectMode, onChange } = props;

  const [companyInternal, setCompanyInternal] = useState(value);
  const [validation, setValidation] = useState<any>(undefined);
  const sessionData = useSession();
  const validateCreditLimitCustomRules = useCallback(
    async (value: string) => {
      let companyData = companyInternal;
      if (value && companyData) {
        if (Array.isArray(companyData))
          companyData = companyData.find((company) => company.id === value);
        let passed = true;
        if (trigger && companyData?.registered_name && runCreditRules) {
          passed = await runCreditRules({
            actor_id: value,
            trigger: trigger,
            actor_type: 'Company',
            customer_name: companyData?.registered_name,
          });
        }
        if (passed) {
          setValidation(undefined);
        } else {
          const error = {
            validateStatus: 'error',
            validateHelp: 'Credit Rule failed for this customer',
          };
          setValidation(error);
        }
      }
    },
    [companyInternal, runCreditRules, setValidation, trigger]
  );

  const validateOnHold = useCallback(
    (value: string) => {
      let validation: any = { validateStatus: undefined, validateHelp: undefined };
      let companyData = companyInternal;
      if (value && companyData) {
        if (Array.isArray(companyData))
          companyData = companyData.find((company) => company.id === value);
        if (companyData?.status === COMPANY_STATUS_LEGAL_DISPUTE) {
          validation = {
            validateStatus: 'error',
            validateHelp:
              'Customer is in legal dispute, cant create booking/shipment, please contact the credit controller',
          };
        } else {
          validation = { validateStatus: 'success', validateHelp: undefined };
        }
        setValidation(validation);
        try {
          if (validation.validateStatus === 'error') {
            throw new Error(validation.validateHelp);
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    [companyInternal, setValidation]
  );
  const validateCreditLimit = useCallback(
    (value: string) => {
      sessionData?.isFeatureEnabled(constants.CREDIT_CONTROL_FEATURE)
        ? validateCreditLimitCustomRules(value)
        : validateOnHold(value);
    },
    [sessionData, validateCreditLimitCustomRules, validateOnHold]
  );

  useEffect(() => {
    if (companyInternal && !Array.isArray(companyInternal)) validateCreditLimit(companyInternal.id);
  }, [companyInternal, validateCreditLimit]);

  useEffect(() => {
    setCompanyInternal(value);
  }, [value]);

  const onChangeInternal = (value: CompanyValue | undefined | Array<CompanyValue>) => {
    setCompanyInternal(value);
    if (onChange) onChange(value);
  };

  const showCreditStatusTag = !!trigger && companyInternal && !Array.isArray(companyInternal);

  return (
    <>
      <Row gutter={8}>
        <Col span={showCreditStatusTag ? 16 : 24}>
          <CompanySearch
            disabled={disabled}
            selectMode={selectMode}
            onChange={onChangeInternal}
            value={value}
            searchProps={{ is_customer: true, is_lead: true, status: COMPANY_STATUS_ARRAY }}
            errorMessage={validation?.validateHelp}
          />
        </Col>
        {showCreditStatusTag && companyInternal && !Array.isArray(companyInternal) && (
          <Col span={8}>
            {
              // FIXME
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              <CustomerCreditStatusTag companyInternal={companyInternal} />
            }
          </Col>
        )}
      </Row>
    </>
  );
};
// );

const CustomerSearchWithCreditCheck = (props: CustomerSearchProps) => {
  const sessionData = useSession();
  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <CreditLimitCheckContext.Consumer>
        {(contextProps: any) => {
          return <CustomerSearch {...props} runCreditRules={contextProps.runCreditRules} />;
        }}
      </CreditLimitCheckContext.Consumer>
    </CreditLimitCheckWrapper>
  );
};

export default CustomerSearchWithCreditCheck;
