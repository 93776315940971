import React, { forwardRef } from 'react';
import RoutingDetails, {
  RoutingDetailsValue,
  RoutingNodesHashValue,
  getNewRoutingNode,
} from 'operations/components/RoutingDetails/RoutingDetails';
import { Checkbox, Collapse } from '@shipmnts/pixel-hub';
import {
  set as _set,
  get as _get,
  difference as _difference,
  omit as _omit,
  range as _range,
} from 'lodash';
import { RoutingLegValue, ROUTING_TYPE_MAIN_CARRIAGE } from 'operations/models/RoutingLeg';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { get_legs_by_routing_type } from 'operations/modules/helpers';
import { FreightType } from 'operations/modules/shipment/constants';
import { getInitialNodes } from 'operations/components/RoutingDetails/helpers';
const { Panel } = Collapse;

interface BookingRoutingDetailsProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  disableNodes?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  bookingType?: string | null;
  isReeferContainer?: boolean;
  allowVoyageScheduleSearch?: boolean;
  onlyTranshipmentHopEditable?: boolean;
  showPickupSection?: boolean;
  showDeliverySection?: boolean;
  freightType?: FreightType;
}

const BookingRoutingDetailsComp = forwardRef(function BookingRoutingDetails(
  props: BookingRoutingDetailsProps,
  ref
) {
  const {
    value,
    onChange,
    disableNodes,
    globalCarrierId,
    isReeferContainer,
    bookingType,
    allowVoyageScheduleSearch = true,
    onlyTranshipmentHopEditable,
    showPickupSection = false,
    showDeliverySection = false,
    freightType = 'ocean',
  } = props;
  const showPickup = get_legs_by_routing_type(value?.routing_legs || [], 'pickup').length > 0;
  const showPreCarriage =
    get_legs_by_routing_type(value?.routing_legs || [], 'pre_carriage').length > 0;
  const showOnCarriage =
    get_legs_by_routing_type(value?.routing_legs || [], 'on_carriage').length > 0;
  const showDelivery = get_legs_by_routing_type(value?.routing_legs || [], 'delivery').length > 0;

  const initialNodes = getInitialNodes(ROUTING_TYPE_MAIN_CARRIAGE);

  const all_routing_legs: Array<RoutingLegValue> = value?.routing_legs || [
      {
        routing_type: 'main_carriage',
        origin_id: initialNodes.origin._id,
        destination_id: initialNodes.destination._id,
        mode_of_transit: freightType === 'ocean' ? 'sea' : freightType,
        sequence_number: 2.1,
      } as RoutingLegValue,
    ],
    all_routing_nodes = value?.routing_nodes || {
      [initialNodes.origin._id || '']: initialNodes.origin,
      [initialNodes.destination._id || '']: initialNodes.destination,
    };
  const updatePickupDestination = (
    pickupLegs: Array<RoutingLegValue>,
    preLegs: Array<RoutingLegValue>
  ) => {
    const newPickupLegs = [
      ..._set(pickupLegs, `${pickupLegs.length - 1}.destination_id`, _get(preLegs, '0.origin_id')),
    ];
    return newPickupLegs;
  };

  const updatePreCarriageDestination = (
    preLegs: Array<RoutingLegValue>,
    mainLegs: Array<RoutingLegValue>
  ) => {
    const newPreCarriageLegs = [
      ..._set(preLegs, `${preLegs.length - 1}.destination_id`, _get(mainLegs, '0.origin_id')),
    ];
    return newPreCarriageLegs;
  };

  const updateOnCarriageOrigin = (
    onLegs: Array<RoutingLegValue>,
    mainLegs: Array<RoutingLegValue>
  ) => {
    const newOnCarriageLegs = [
      ..._set(onLegs, '0.origin_id', _get(mainLegs, `${mainLegs.length - 1}.destination_id`)),
    ];
    return newOnCarriageLegs;
  };

  const updateDeliveryOrigin = (
    deliveryLegs: Array<RoutingLegValue>,
    onLegs: Array<RoutingLegValue>
  ) => {
    const newDeliveryLegs = [
      ..._set(deliveryLegs, '0.origin_id', _get(onLegs, `${onLegs.length - 1}.destination_id`)),
    ];
    return newDeliveryLegs;
  };

  const getRoutingNodesFromLegs = (
    routing_legs: Array<RoutingLegValue>,
    routing_nodes: RoutingNodesHashValue
  ) => {
    const used_nodes_from_legs: Array<string> = [];
    routing_legs.forEach((rl) => {
      if (rl?.origin_id && !used_nodes_from_legs.includes(rl.origin_id))
        used_nodes_from_legs.push(rl.origin_id);
      if (rl.destination_id && !used_nodes_from_legs.includes(rl.destination_id))
        used_nodes_from_legs.push(rl.destination_id);
    });
    const unused_nodes = _difference(Object.keys(routing_nodes), used_nodes_from_legs);
    const new_routing_nodes = _omit(routing_nodes, unused_nodes);
    return new_routing_nodes;
  };

  const onPickupChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onPreCarriageChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onMainCarriageChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange)
      onChange({
        routing_legs: [
          ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
          ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
          ...routingLegs,
          ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
          ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
        ],
        routing_nodes: {
          ...all_routing_nodes,
          ...routingNodes,
        },
      });
  };

  const onOnCarriageChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
        ...routingLegs,
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onDeliveryChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...routingLegs,
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const pickupRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'pickup');
  const preCarriageRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'pre_carriage');
  const mainCarriageRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'main_carriage');
  const onCarriageRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'on_carriage');
  const onDeliveryRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'delivery');

  return (
    <Collapse
      activeKey={[
        showPickup ? 'pickup' : '',
        showPreCarriage ? 'pre_carriage' : '',
        'main_carriage',
        showOnCarriage ? 'on_carriage' : '',
        showDelivery ? 'delivery' : '',
      ]}
      bordered={false}
      className="routing-detail-custom-collapse"
    >
      {showPickupSection && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showPickup}
              disabled={disableNodes || onlyTranshipmentHopEditable}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  onPickupChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  const origin_node = getNewRoutingNode({
                    tags: ['place_of_carrier_receipt'],
                  } as RoutingNodeValue);
                  const pre_legs = updatePickupDestination(
                    [
                      {
                        routing_type: 'pickup',
                        origin_id: origin_node._id,
                        sequence_number: 0.1,
                      } as RoutingLegValue,
                    ],
                    get_legs_by_routing_type(all_routing_legs, 'pre_carriage')
                  );
                  onPickupChange({
                    routing_legs: pre_legs,
                    routing_nodes: { [origin_node._id || '']: origin_node },
                  });
                }
              }}
            >
              Pickup
            </Checkbox>
          }
          key="pickup"
        >
          {showPickup && (
            <RoutingDetails
              ref={ref}
              value={{
                routing_legs: pickupRoutingLegs,
                routing_nodes: all_routing_nodes,
              }}
              locationSearchType={['Airport', 'CFS', 'ICD', 'PortTerminal', 'Seaport', 'City']}
              startSequence={0}
              endSequence={1}
              onChange={onPickupChange}
              routing_type="pickup"
              showTerminal={freightType === 'air' ? false : true}
              disableNodes={
                disableNodes ? _range(pickupRoutingLegs.length + 1) : [pickupRoutingLegs.length]
              }
              disableRoutingLegs={
                onlyTranshipmentHopEditable ? _range(pickupRoutingLegs.length + 1) : []
              }
              disableAddRemoveTranshipmentHop={onlyTranshipmentHopEditable || disableNodes}
            />
          )}
        </Panel>
      )}
      {freightType === 'ocean' && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showPreCarriage}
              disabled={disableNodes || onlyTranshipmentHopEditable}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  onPreCarriageChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  const origin_node = getNewRoutingNode({
                    tags: ['place_of_carrier_receipt'],
                  } as RoutingNodeValue);
                  const pre_legs = updatePreCarriageDestination(
                    [
                      {
                        routing_type: 'pre_carriage',
                        origin_id: origin_node._id,
                        sequence_number: 1.1,
                      } as RoutingLegValue,
                    ],
                    get_legs_by_routing_type(all_routing_legs, 'main_carriage')
                  );
                  onPreCarriageChange({
                    routing_legs: pre_legs,
                    routing_nodes: { [origin_node._id || '']: origin_node },
                  });
                }
              }}
            >
              Pre carriage
            </Checkbox>
          }
          key="pre_carriage"
        >
          {showPreCarriage && (
            <RoutingDetails
              startSequence={1}
              endSequence={2}
              routing_type="pre_carriage"
              value={{
                routing_legs: preCarriageRoutingLegs,
                routing_nodes: all_routing_nodes,
              }}
              onChange={onPreCarriageChange}
              locationSearchType={['ICD', 'City', 'Seaport']}
              ref={ref}
              disableNodes={
                disableNodes
                  ? _range(preCarriageRoutingLegs.length + 1)
                  : [preCarriageRoutingLegs.length]
              }
              disableRoutingLegs={
                onlyTranshipmentHopEditable ? _range(preCarriageRoutingLegs.length + 1) : []
              }
              disableAddRemoveTranshipmentHop={onlyTranshipmentHopEditable || disableNodes}
            />
          )}
        </Panel>
      )}

      <Panel
        collapsible={'disabled'}
        forceRender
        showArrow={false}
        header="Main Carriage"
        key="main_carriage"
      >
        <RoutingDetails
          startSequence={2}
          endSequence={3}
          routing_type="main_carriage"
          value={{
            routing_legs: mainCarriageRoutingLegs,
            routing_nodes: all_routing_nodes,
          }}
          onChange={onMainCarriageChange}
          locationSearchType={freightType === 'ocean' ? ['Seaport'] : ['Airport']}
          ref={ref}
          disableNodes={disableNodes ? _range(mainCarriageRoutingLegs.length + 1) : []}
          disableRoutingLegs={
            onlyTranshipmentHopEditable ? [0, mainCarriageRoutingLegs.length] : []
          }
          disableAddRemoveTranshipmentHop={disableNodes}
          validateVesselVoyage={props.validateVesselVoyage}
          showTerminal={freightType === 'air' ? false : true}
          freightType={freightType}
          globalCarrierId={globalCarrierId}
          isReeferContainer={isReeferContainer}
          bookingType={bookingType}
          allowVoyageScheduleSearch={allowVoyageScheduleSearch}
        />
      </Panel>
      {freightType !== 'air' && (
        <Panel
          collapsible={'disabled'}
          forceRender
          showArrow={false}
          header={
            <Checkbox
              checked={showOnCarriage}
              disabled={disableNodes || onlyTranshipmentHopEditable}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  onOnCarriageChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  const destination_node = getNewRoutingNode({
                    tags: ['place_of_carrier_delivery'],
                  } as RoutingNodeValue);
                  const on_legs = updateOnCarriageOrigin(
                    [
                      {
                        routing_type: 'on_carriage',
                        destination_id: destination_node._id,
                        sequence_number: 3.1,
                      } as RoutingLegValue,
                    ],
                    get_legs_by_routing_type(all_routing_legs, 'main_carriage')
                  );
                  onOnCarriageChange({
                    routing_legs: on_legs,
                    routing_nodes: { [destination_node._id || '']: destination_node },
                  });
                }
              }}
            >
              On Carriage
            </Checkbox>
          }
          key="on_carriage"
        >
          {showOnCarriage && (
            <RoutingDetails
              startSequence={3}
              endSequence={4}
              routing_type="on_carriage"
              value={{
                routing_legs: onCarriageRoutingLegs,
                routing_nodes: all_routing_nodes,
              }}
              onChange={onOnCarriageChange}
              locationSearchType={['ICD', 'City', 'Seaport']}
              ref={ref}
              disableNodes={disableNodes ? _range(onCarriageRoutingLegs.length + 1) : [0]}
              disableRoutingLegs={
                onlyTranshipmentHopEditable ? _range(onCarriageRoutingLegs.length + 1) : []
              }
              disableAddRemoveTranshipmentHop={onlyTranshipmentHopEditable || disableNodes}
            />
          )}
        </Panel>
      )}
      {showDeliverySection && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showDelivery}
              disabled={disableNodes || onlyTranshipmentHopEditable}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  onDeliveryChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  const destination_node = getNewRoutingNode({
                    tags: ['place_of_carrier_delivery'],
                  } as RoutingNodeValue);
                  const delviery_legs = updateDeliveryOrigin(
                    [
                      {
                        routing_type: 'delivery',
                        destination_id: destination_node._id,
                        sequence_number: 4.1,
                      } as RoutingLegValue,
                    ],
                    get_legs_by_routing_type(all_routing_legs, 'on_carriage')
                  );
                  onDeliveryChange({
                    routing_legs: delviery_legs,
                    routing_nodes: { [destination_node._id || '']: destination_node },
                  });
                }
              }}
            >
              Delivery
            </Checkbox>
          }
          key="delivery"
        >
          {showDelivery && (
            <RoutingDetails
              ref={ref}
              value={{
                routing_legs: onDeliveryRoutingLegs,
                routing_nodes: all_routing_nodes,
              }}
              locationSearchType={['Airport', 'CFS', 'ICD', 'PortTerminal', 'Seaport', 'City']}
              startSequence={0}
              endSequence={1}
              onChange={onDeliveryChange}
              routing_type="delivery"
              showTerminal={freightType === 'air' ? false : true}
              disableNodes={disableNodes ? _range(onDeliveryRoutingLegs.length + 1) : [0]}
              disableRoutingLegs={
                onlyTranshipmentHopEditable ? _range(onDeliveryRoutingLegs.length + 1) : []
              }
              disableAddRemoveTranshipmentHop={onlyTranshipmentHopEditable || disableNodes}
            />
          )}
        </Panel>
      )}
    </Collapse>
  );
});

const BookingRoutingDetails = React.memo(BookingRoutingDetailsComp);

export default BookingRoutingDetails;
