import { Col, Form, Input, Radio, Row, Select } from '@shipmnts/pixel-hub';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import React from 'react';
import { ROW_GUTTER } from '../../constants';
import { MOVEMENT_MODES, ROAD_LOAD_TYPES, TRADE_TYPES } from '../ShipmentForm/constants';

interface BasicDetailsProps {
  hide?: boolean;
}
export default function BasicDetails(props: BasicDetailsProps) {
  const { hide } = props;

  return (
    <Row gutter={ROW_GUTTER}>
      <Form.Item noStyle>
        <Col span={6}>
          <Form.Item rules={[{ required: !hide }]} name="name" label="Route Name">
            <Input placeholder="Enter Route Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[{ required: !hide }]} name="trade_type" label="Trade Type">
            <Radio.Group>
              {TRADE_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[{ required: !hide }]} name="load_type" label="Load Type">
            <Radio.Group>
              {ROAD_LOAD_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Form.Item>
      <Col span={6}>
        <Form.Item rules={[{ required: !hide }]} name={'movement_mode'} label="Movement Mode">
          <Select placeholder="Movement Mode">
            {MOVEMENT_MODES.map((movementMode) => (
              <Select.Option key={movementMode.key} value={movementMode.key}>
                {movementMode.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
