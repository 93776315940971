import {
  LOAD_TYPE_FCL,
  LOAD_TYPE_FTL_BREAK_BULK,
  LOAD_TYPE_FTL_BULK,
  LOAD_TYPE_FTL_LIQUID,
  LOAD_TYPE_LTL,
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_CFS_YARD,
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_PORT_ICD,
} from 'operations/baseConstants';
import {
  ASSET_TYPE_HIRE,
  ASSET_TYPE_OWN,
  CARGO_TYPE_BREAK_BULK,
  CARGO_TYPE_BULK,
  CARGO_TYPE_CONTAINER,
  CARGO_TYPE_LIQUID,
  FREIGHT_TYPE_AIR,
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_AIRPORT_PICKUP,
  MOVEMENT_MODE_PICKUP_AND_DROP,
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
  TRADE_TYPE_CROSS_TRADE,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP,
} from '../../constants';

export const FREIGHT_TYPES = [
  {
    key: FREIGHT_TYPE_AIR,
    name: 'Air',
  },
  { key: FREIGHT_TYPE_OCEAN, name: 'Ocean' },
  { key: FREIGHT_TYPE_ROAD, name: 'Road' },
];

export const TRADE_TYPES = [
  {
    key: TRADE_TYPE_IMPORT,
    name: 'Import',
  },
  {
    key: TRADE_TYPE_EXPORT,
    name: 'Export',
  },
  {
    key: TRADE_TYPE_DOMESTIC,
    name: 'Domestic',
  },
  {
    key: TRADE_TYPE_CROSS_TRADE,
    name: 'Cross Trade',
  },
];

export const CARGO_TYPES = [
  {
    key: CARGO_TYPE_CONTAINER,
    name: 'Container',
  },
  {
    key: CARGO_TYPE_BREAK_BULK,
    name: 'Break Bulk',
  },
  {
    key: CARGO_TYPE_LIQUID,
    name: 'Liquid',
  },
  {
    key: CARGO_TYPE_BULK,
    name: 'Bulk',
  },
];

export const ROAD_LOAD_TYPES = [
  {
    key: LOAD_TYPE_FCL,
    name: 'FCL',
  },
  {
    key: LOAD_TYPE_FTL_BREAK_BULK,
    name: 'FTL - Break Bulk',
  },
  {
    key: LOAD_TYPE_FTL_BULK,
    name: 'FTL - Bulk',
  },
  {
    key: LOAD_TYPE_FTL_LIQUID,
    name: 'FTL - Liquid',
  },
  {
    key: LOAD_TYPE_LTL,
    name: 'LTL',
  },
];

export const ASSET_TYPES = [
  {
    key: ASSET_TYPE_OWN,
    name: 'Own',
  },
  {
    key: ASSET_TYPE_HIRE,
    name: 'Hire',
  },
];

export const MOVEMENT_MODES = [
  { key: MOVEMENT_MODE_CONTAINER_STUFFING, name: 'Container Stuffing' },
  { key: MOVEMENT_MODE_CONTAINER_DESTUFFING, name: 'Container Destuffing' },
  { key: MOVEMENT_MODE_AIRPORT_DELIVERY, name: 'Airport Delivery' },
  { key: MOVEMENT_MODE_AIRPORT_PICKUP, name: 'Airport Pickup' },
  { key: MOVEMENT_MODE_PICKUP_AND_DROP, name: 'Pickup and Drop' },
  { key: MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP, name: 'Container Pickup and Drop' },
];

export const LOCATION_TYPES = [
  { key: LOCATION_TYPE_PORT_ICD, name: 'Port/ICD' },
  { key: LOCATION_TYPE_CFS_YARD, name: 'CFS/Yard' },
  { key: LOCATION_TYPE_CITY, name: 'City' },
  { key: LOCATION_TYPE_ADDRESS, name: 'Address' },
];
