import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';

const OTOBookingNumber = React.memo(function OTOBookingNumber(
  props: ICellRendererParams
): JSX.Element {
  const bookingNumber = props.value;
  const cellRendererParams = props?.colDef?.cellRendererParams;
  const id = props?.data?.[cellRendererParams?.idField];

  return (
    <span className="centered">
      {id && cellRendererParams?.redirectToBase ? (
        <a
          href={`${cellRendererParams?.redirectToBase}/${id}`}
          target="_blank"
          key={id}
          style={{ cursor: 'pointer' }}
          rel="noreferrer"
        >
          {bookingNumber}
        </a>
      ) : (
        <span>{bookingNumber}</span>
      )}
    </span>
  );
});

export default OTOBookingNumber;
