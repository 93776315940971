import {
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
  MOVEMENT_MODE_PICKUP_AND_DROP,
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_AIRPORT_PICKUP,
} from 'operations/modules/shipment/constants';

const getRoutingData = (company: any, address: any, location: any) => {
  return {
    company: company,
    address: address,
    location: location,
  };
};

export const getUpdatedRoutingDetails = (routingValue: any, movementMode: string) => {
  const updatedRouting = { ...routingValue };
  const originNode = updatedRouting?.routing_legs?.[0];
  const routingLegsLength = updatedRouting?.routing_legs?.length || 0;
  const destinationNode = updatedRouting?.routing_legs?.[routingLegsLength - 1];
  const originId = originNode?.origin_id;
  const destinationId = destinationNode?.destination_id;

  updatedRouting.routing_nodes[originId] = {
    ...updatedRouting.routing_nodes[originId],
    ...getRoutingData(undefined, undefined, undefined),
  };
  updatedRouting.routing_nodes[destinationId] = {
    ...updatedRouting.routing_nodes[destinationId],
    ...getRoutingData(undefined, undefined, undefined),
  };
  if (
    [MOVEMENT_MODE_CONTAINER_STUFFING, MOVEMENT_MODE_CONTAINER_DESTUFFING].includes(movementMode)
  ) {
    const transhipmentId = originNode?.destination_id;
    updatedRouting.routing_nodes[transhipmentId] = {
      ...updatedRouting.routing_nodes[transhipmentId],
      ...getRoutingData(undefined, undefined, undefined),
    };
  }

  if (movementMode === MOVEMENT_MODE_PICKUP_AND_DROP) {
    updatedRouting.routing_nodes[originId] = {
      ...updatedRouting.routing_nodes[originId],
      locationType: 'City',
    };
    updatedRouting.routing_nodes[destinationId] = {
      ...updatedRouting.routing_nodes[destinationId],
      locationType: 'City',
    };
  } else if (movementMode === MOVEMENT_MODE_CONTAINER_STUFFING) {
    const transhipmentId = originNode?.destination_id;
    updatedRouting.routing_nodes[transhipmentId] = {
      ...updatedRouting.routing_nodes[transhipmentId],
      locationType: 'Address',
    };
    updatedRouting.routing_nodes[originId] = {
      ...updatedRouting.routing_nodes[originId],
      locationType: 'CFS',
    };
    updatedRouting.routing_nodes[destinationId] = {
      ...updatedRouting.routing_nodes[destinationId],
      locationType: 'Seaport',
    };
  } else if (movementMode === MOVEMENT_MODE_CONTAINER_DESTUFFING) {
    const transhipmentId = originNode?.destination_id;
    updatedRouting.routing_nodes[transhipmentId] = {
      ...updatedRouting.routing_nodes[transhipmentId],
      locationType: 'Address',
    };

    updatedRouting.routing_nodes[originId] = {
      ...updatedRouting.routing_nodes[originId],
      locationType: 'Seaport',
    };
    updatedRouting.routing_nodes[destinationId] = {
      ...updatedRouting.routing_nodes[destinationId],
      locationType: 'CFS',
    };
  } else if (movementMode === MOVEMENT_MODE_AIRPORT_DELIVERY) {
    updatedRouting.routing_nodes[originId] = {
      ...updatedRouting.routing_nodes[originId],
      locationType: 'City',
    };
    updatedRouting.routing_nodes[destinationId] = {
      ...updatedRouting.routing_nodes[destinationId],
      locationType: 'Airport',
    };
  } else if (movementMode === MOVEMENT_MODE_AIRPORT_PICKUP) {
    updatedRouting.routing_nodes[originId] = {
      ...updatedRouting.routing_nodes[originId],
      locationType: 'Airport',
    };
    updatedRouting.routing_nodes[destinationId] = {
      ...updatedRouting.routing_nodes[destinationId],
      locationType: 'City',
    };
  }
  return updatedRouting;
};
