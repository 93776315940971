import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  Spin,
  Row,
  Col,
  Table,
  message,
  Drawer,
  Select,
  Form,
  dayjsGenerateConfig,
  DatePicker,
  CompanySearch,
} from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQL } from 'common';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { VALIDATE_CONTAINERS_OFFLOAD } from 'operations/modules/booking/graphql/shipmentContainer';
import { showErrorMessages } from 'operations/modules/reports/helpers/containerActionsHelper';
import { OFFLOAD_BY, OFFLOAD_REASONS, STATUS_ACTIVE } from 'operations/modules/reports/constants';
import { LOCATION_TYPE_ICD, LOCATION_TYPE_CFS } from 'operations/models/Location';
import { OFFLOAD_CONTAINERS } from 'operations/modules/reports/graphql/containerReport';
import { COMPANY_STATUS_ARRAY } from 'operations/models/Company';
import { disableFutureDate } from '@shipmnts/pixel-hub';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { BookingRequestBasicInfo } from '../BookingRequestView/BookingRequestBasicInfo';
import { GlobalSearch } from '@shipmnts/pixel-hub';
import { startCase as _startCase } from 'lodash';

const ROW_GUTTER = 16;

const containerColumns = [
  {
    title: 'Container Number',
    dataIndex: 'container_number',
  },
  {
    title: 'Container Type',
    dataIndex: 'container_type',
  },
];

const OffloadContainers = React.memo(function OffloadContainers(props: {
  bookingRequest: BookingRequestValue;
  onClose: () => void;
  onSuccess?: (container_ids: string[], sendEmail: boolean) => void;
}): JSX.Element {
  const { bookingRequest, onClose, onSuccess } = props;

  const [createShipment, setCreateShipment] = useState<boolean>(false);
  const containers = useMemo(
    () =>
      bookingRequest.shipmentContainers.filter((container) => container.status === STATUS_ACTIVE),
    [bookingRequest.shipmentContainers]
  );
  const [selectedContainerIds, setSelectedContainerIds] = useState(
    containers.map((container) => container.id)
  );
  const [disableOffloadContainer, setDisableOffloadContainer] = useState<boolean>(true);
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  const [
    validateContainersOffload,
    { data: validateData, loading: validating, error: validationError },
  ] = useMutation(VALIDATE_CONTAINERS_OFFLOAD);

  useEffect(() => {
    if (!validationError && validateData?.validate_containers_offload) {
      if (validateData?.validate_containers_offload?.messages.length > 0) {
        showErrorMessages(
          validateData.validate_containers_offload.messages,
          'Cannot Mark Containers as offloaded'
        );
      } else {
        if (validateData?.validate_containers_offload.create_shipment_needed) {
          setCreateShipment(true);
        } else {
          setCreateShipment(false);
        }

        setDisableOffloadContainer(false);
      }
    }
  }, [validateData, validationError]);

  const [offloadContainer, { data, loading, error }] = useMutation(OFFLOAD_CONTAINERS);

  useEffect(() => {
    if (!error && data?.offload_containers) {
      message.success('Offloaded Containers Successfully!');
      onClose();
      const events = (data.offload_containers || [])
        .map((c: any) => {
          return c.tracking_events
            .sort(
              (a: { sequence_number: number }, b: { sequence_number: number }) =>
                b.sequence_number - a.sequence_number
            )
            .find((event: any) => event.name === 'Empty Offloaded');
        })
        .filter((event: any) => event != null);
      if (onSuccess) {
        onSuccess(
          events.map((e: any) => e.id),
          sendEmail
        );
      }
    }
  }, [data, error, onSuccess, onClose, sendEmail, selectedContainerIds, containers]);

  const [form] = Form.useForm();

  const saveText = createShipment
    ? 'Mark Offload And Create New Shipment'
    : 'Mark Offload And Add To Existing Shipment';

  const triggerValidate = useCallback(() => {
    const billing_party = form.getFieldValue('billing_party');
    if (billing_party?.id && selectedContainerIds.length > 0)
      validateContainersOffload({
        variables: {
          shipment_container_ids: selectedContainerIds,
          customer_company_id: billing_party?.id,
        },
      });
    else {
      setCreateShipment(false);
      setDisableOffloadContainer(true);
    }
  }, [selectedContainerIds, form, validateContainersOffload]);

  useEffect(triggerValidate, [triggerValidate, selectedContainerIds]);

  // On Mounting
  useEffect(() => {
    form.setFieldValue('billing_party', bookingRequest.customerCompany);
    if (selectedContainerIds.length > 0) triggerValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      title={'Offload / Cancel'}
      width={'60%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText={saveText}
          loading={loading}
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Offload Confirmation"
          onClose={onClose}
          disabled={disableOffloadContainer}
          onSave={form.submit}
        />
      }
    >
      <Form
        name="container_offload"
        form={form}
        layout="vertical"
        onFinish={(values: any) => {
          const payload = {
            shipment_container_ids: selectedContainerIds,
            offloaded_at: values.offloaded_at.unix(),
            cancelled_by: values.offload_reason.split('----')[0],
            cancellation_reason: values.offload_reason.split('----')[1],
            return_location_id: values.empty_return_location.id,
            return_expected_at: values.empty_return_by.unix(),
            customer_company_id: values.billing_party?.id,
            ...(createShipment ? { job_date: values.job_date.unix() } : {}),
          };
          offloadContainer({ variables: payload });
        }}
        initialValues={{
          billing_party: { ...bookingRequest.customerCompany },
          offloaded_at: dayjsGenerateConfig.getNow(),
          job_date: dayjsGenerateConfig.getNow(),
        }}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues?.billing_party) {
            triggerValidate();
          }
        }}
      >
        <Spin spinning={validating}>
          <BookingRequestBasicInfo bookingRequest={bookingRequest} />
          {error && errorMessageHandlerGraphQL(error)}
          <div className="ant-descriptions-title" style={{ padding: '10px' }}>
            Select Containers to offload
          </div>
          <Table
            rowSelection={{
              selectedRowKeys: selectedContainerIds,
              onChange: (selectedRowKeys: any) => setSelectedContainerIds(selectedRowKeys),
            }}
            dataSource={containers?.slice()}
            columns={containerColumns}
            pagination={false}
            rowKey={(record) => record.id || ''}
            size="small"
          />
          <div className="ant-descriptions-title" style={{ padding: '10px' }}>
            Offload Details
          </div>
          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="offloaded_at"
                label="Offload Date"
              >
                <DatePicker style={{ width: '100%' }} disabledDate={disableFutureDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Select reason to offload"
                name="offload_reason"
              >
                <Select showSearch allowClear={false} placeholder="Select offload reason">
                  {OFFLOAD_BY.map((cancelled_by: string) => (
                    <Select.OptGroup key={cancelled_by} label={_startCase(cancelled_by)}>
                      {OFFLOAD_REASONS[cancelled_by].map((option, index) => (
                        <Select.Option
                          key={`${cancelled_by}_${index}`}
                          value={`${cancelled_by}----${option}`}
                        >
                          {_startCase(option)}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="ant-descriptions-title" style={{ padding: '10px' }}>
            Empty Return Details
          </div>
          <Row gutter={ROW_GUTTER}>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                name="empty_return_by"
                label="Empty Return By"
              >
                <DatePicker showTime style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Empty Return Location"
                name="empty_return_location"
              >
                <GlobalSearch
                  doc_type="Global::Location"
                  searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="ant-descriptions-title" style={{ padding: '10px' }}>
            New Shipment Details
          </div>
          <Row gutter={ROW_GUTTER}>
            <Col span={12} className="custom-margin-form-item">
              <Form.Item
                name="billing_party"
                required
                rules={[{ required: true }]}
                label="Billing Party"
              >
                <CompanySearch
                  searchProps={{
                    status: COMPANY_STATUS_ARRAY,
                    is_customer: true,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {createShipment && (
                <Form.Item required rules={[{ required: true }]} name="job_date" label="Job Date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Spin>
      </Form>
    </Drawer>
  );
});

export default OffloadContainers;
