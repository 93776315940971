import React, { useState } from 'react';
import { InquiryValue } from 'sales_hub/models/inquiry';
import { QuotationValue, WON_STATUS } from 'sales_hub/models/quotation';

import { getCreateResourceUrl } from '../WinInquiryLayout';
import { Button, Drawer, message, TrackerContextProvider } from '@shipmnts/pixel-hub';
import InquiryOptionTable from 'sales_hub/components/InquiryOption/InquiryOptionTable';
import { startCase } from 'lodash';

import { useSession } from 'common';

interface createResourceByInquiryProp {
  inquiry: InquiryValue;
  resourceType: string;
  shipmentType?: string;
  onSuccess?: () => void;
  onClose?: () => void;
  visible?: boolean;
}

function CreateResourceByInquiry(props: createResourceByInquiryProp) {
  const { inquiry, resourceType, shipmentType, visible, onClose } = props;
  const [wonQuoteId, setWonQuoteId] = useState<string>();
  const session = useSession();
  const isValidUrl = (url: string | undefined | null): boolean => {
    if (!url) {
      if (onClose) onClose();
      return false;
    }
    return true;
  };
  const openUrl = (url: string | undefined | null) => {
    if (isValidUrl(url)) {
      window.open(url || '');
      if (onClose) onClose();
    }
  };

  const won_inquiry_options: QuotationValue[] =
    inquiry?.inquiry_options?.filter((ip) => ip.status === WON_STATUS) || [];

  if (won_inquiry_options.length < 2 && visible) {
    const url = getCreateResourceUrl(
      resourceType,
      won_inquiry_options[0]?.id,
      inquiry,
      session,
      shipmentType
    );
    if (isValidUrl(url)) openUrl(url);
  }
  if (visible && won_inquiry_options.length >= 2) {
    return (
      <Drawer
        title="Select Quotation"
        placement="right"
        width={'70%'}
        footer={[
          <div key="create" style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => {
                if (!wonQuoteId) message.error('Please select quotation');
                const url = getCreateResourceUrl(
                  resourceType,
                  wonQuoteId,
                  inquiry,
                  session,
                  shipmentType
                );
                if (url) openUrl(url);
              }}
            >
              Create {startCase(resourceType)}
            </Button>
          </div>,
        ]}
        onClose={() => {
          if (onClose) onClose();
        }}
        open={true}
      >
        <TrackerContextProvider referenceType="SalesHub::InquiryOption">
          <InquiryOptionTable
            removeColumns={['actions']}
            inquiry={inquiry}
            inquiryOptionIds={(won_inquiry_options || []).map((ele) => ele.id)}
            rowSelection="single"
            rowSelectionChange={(ele) => {
              const id = ele.api?.getSelectedRows()?.[0]?.id;
              setWonQuoteId(id);
            }}
          />
        </TrackerContextProvider>
      </Drawer>
    );
  }

  return <></>;
}

export default CreateResourceByInquiry;
