import React from 'react';

import { WarningOutlined } from '@shipmnts/pixel-hub';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Space, Tooltip } from '@shipmnts/pixel-hub';
import { observer } from 'mobx-react-lite';

export const ItemCellRenderer = observer(function ItemCellRenderer(
  props: ICellRendererParams
): JSX.Element {
  let showMarginWarning = false;

  if (
    props.data?.total_sell_amount &&
    props.data?.total_buy_amount &&
    props.data?.total_sell_amount < props.data?.total_buy_amount
  ) {
    showMarginWarning = true;
  }

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.value}</div>
      <Space size={4} style={{ float: 'right', marginLeft: 'auto' }}>
        {showMarginWarning && (
          <Tooltip title="Sell Amount is less than buy amount">
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        )}
      </Space>
    </div>
  );
});
