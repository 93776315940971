import { gql } from '@apollo/client';
import { cargoFields, routingLegFields } from 'operations/modules/booking/graphql';
import { routingLocationObjectType } from 'operations/modules/booking/graphql/voyageSchedule';
import { invoiceAddressFields, minimumCompanyFields } from './shipmentEstimate';
import { trackingEventFields } from './trackingEvent';
import { salesPersonFields } from './salesPerson';
import { containerSettingsFields } from 'operations/modules/booking/graphql/containerRequest';
import { oceanTransportOrderFields } from 'operations/modules/booking/graphql';
export const globalPortFields = gql`
  fragment globalPortFields on PortObjectType {
    id
    name
    type
    iata_code
    unlocode
    customs_code
    address
    parent_id
    city {
      name
      code
    }
    country {
      name
      code
    }
    country_code
  }
`;

export const globalLocationFields = gql`
  fragment globalLocationFields on LocationObjectType {
    id
    name
    type
    iata_code
    unlocode
    customs_code
    address
    parent_id
    city {
      name
      code
    }
    country {
      name
      code
    }
    country_code
  }
`;
export const finalPlaceOfDeliveryFields = gql`
  fragment finalPlaceOfDeliveryFields on CityObjectType {
    code
    name
    state_code
    state_name
    country_code
    country_name
    id
  }
`;

export const stuffingLocationFields = gql`
  fragment stuffingLocationFields on StuffingLocationObject {
    company {
      ...minimumCompanyFields
    }
    company_address {
      ...invoiceAddressFields
    }
    port {
      ...globalPortFields
    }
  }
  ${minimumCompanyFields}
  ${invoiceAddressFields}
  ${globalPortFields}
`;

export const chargeTermFields = gql`
  fragment chargeTermFields on ChargeTermObject {
    destination_charges_terms
    due_agent_charges_terms
    due_carrier_terms
    freight_terms
    origin_charges_terms
    other_charges_terms
  }
`;

export const stockOrderItemFields = gql`
  fragment stockOrderItemFields on StockOrderItemObject {
    awb_number
    id
    next_actions
    shipment_document_id
    status
    stock_order {
      id
      supplier_company {
        id
        registered_name
      }
      billing_branch {
        id
      }
    }
  }
`;

export const shipmentDocumentFields = gql`
  fragment shipmentDocumentFields on ShipmentDocumentObject {
    bl_type
    document_id
    document_status
    eawb_status
    document_type
    shipment_id
    shipment_number
    shipment_date
    house_number_sequence_id
    id
    release_type
    charge_terms {
      ...chargeTermFields
    }
    stock_order_item {
      ...stockOrderItemFields
    }
    shipper_on_document
    consignee_on_document
    is_surrendered
    surrender_location
    si_filing_date
    email_activities {
      id
      purpose
    }
  }
  ${chargeTermFields}
  ${stockOrderItemFields}
`;

export const packagesFields = gql`
  fragment packagesFields on ShipmentPackageObject {
    id
    shipment_id
    master_shipment_id
    gross_volume
    height
    length
    total_number_of_packages
    per_piece_weight
    type_of_package
    width
    dimension_unit
  }
`;

export const shipmentServicesFields = gql`
  fragment shipmentServicesFields on ServiceObject {
    destination {
      clearance
      transport
    }
    freight_forwarding
    cargo_insurance
    empty_container_insurance
    origin {
      clearance
      fumigation
      palletization
      transport
    }
  }
`;

export const globalCarrierFields = gql`
  fragment globalCarrierFields on CarrierObjectType {
    id
    name
    carrier_type
    iata_carrier_code
    standard_alpha_carrier_code
    iata_awb_prefix
  }
`;

export const shipmentCustomDetailsFields = gql`
  fragment shipmentCustomDetailsFields on ShipmentCustomDetailObject {
    custom_document_date
    custom_document_number
    trade_type
    country
    id
    shipment_id
    assessable_value
    invoice_value
    duty_amount
    custom_clearance_location {
      id
      name
      type
      iata_code
      unlocode
      customs_code
      address
      is_customs_location
      country_code
    }
    shipping_bill_type
    invoice_value
    fob_value
    cif_value
  }
`;
export const shipmentInvoicesFields = gql`
  fragment shipmentInvoicesFields on ShipmentInvoiceObject {
    id
    invoice_date
    invoice_number
    shipment_id
  }
`;

export const shipmentPropertiesFields = gql`
  fragment shipmentPropertiesFields on ShipmentPropertiesObject {
    is_general
    is_hazardous
    is_temp_controlled
    is_battery
    is_perishable
    hazardous_details {
      un_number
      un_imo_class
      proper_shipping_name
      packaging_group_type
      air_craft_type
      is_radioactive
    }
    temp_controlled_details {
      temperature_range
      cooling_type
    }
    battery_details {
      battery_type
    }
  }
`;

export const productRatingFields = gql`
  fragment productRatingFields on ShipmentProductRatingObject {
    id
    cargo_type
    chargeable_basis
    chargeable_weight
    gross_weight
    quantity
    rate_class
    uld_numbers
    uld_type
    commodity_item_number
    rate_per_charge
    shipment_id
    commission_percentage
    shipment_estimate {
      buy_transaction_identifier
    }
  }
`;

export const OceanVesselFields = gql`
  fragment OceanVesselFields on OceanVesselObjectType {
    flagId: flag_id
    imo
    mmsi
    name
    vesselType: vessel_type
    is_frequent
  }
`;

export const branchAccountFields = gql`
  fragment branchAccountFields on BranchAccount {
    id
    name
    entity_type
    default_address {
      ...invoiceAddressFields
    }
    address_types
    city {
      name
      code
    }
    state {
      name
      code
    }
    country {
      name
      code
    }
    company_account_id
    postal_code
    print_address
    state_tax_code
    tax_registration_number
    tax_registration_type
    erp_cost_center_id
  }
  ${invoiceAddressFields}
`;

export const shipmentEventFields = gql`
  fragment shipmentEventFields on ShipmentEventObject {
    id
    event_by {
      id
      first_name
      last_name
    }
    event_number
    event_data
    event_date
    event_type

    location {
      ... on PortObjectType {
        ...globalPortFields
      }
    }
    name
    shipment_id
  }
  ${globalPortFields}
`;

export const shipmentPartyFields = gql`
  fragment shipmentPartyFields on ShipmentPartyObject {
    id
    name
    party_address {
      ...invoiceAddressFields
    }
    party_company {
      ...minimumCompanyFields
    }
    party_details
    shipment_id
    party_referenceable_id
    party_referenceable_type
  }
  ${minimumCompanyFields}
  ${invoiceAddressFields}
`;

// only added what is needed for now
export const userAccountFields = gql`
  fragment userAccountFields on UserAccount {
    id
    first_name
    last_name
    email
    username
  }
`;

export const shipmentUsersFields = gql`
  fragment shipmentUsersFields on ShipmentUserObject {
    id
    role
    user_id
    user_type
    user {
      ...userAccountFields
    }
  }
  ${userAccountFields}
`;

export const routingDetailsFields = gql`
  fragment routingDetailsFields on ShipmentRoutingDetailObject {
    id
    carrier {
      ...globalCarrierFields
    }
    estimated_time_of_arrival
    actual_time_of_arrival
    estimated_time_of_departure
    location {
      ...globalPortFields
    }
    location_tags
    mode_of_transport
    route_order
    routing_type
    voyage_date
    voyage_number
  }
  ${globalCarrierFields}
  ${globalPortFields}
`;

const containerSettingsFragmentFields = gql`
  fragment containerSettingsFragmentFields on ContainerSettingsObjectType {
    is_active_reefer
    temperature
    temperature_unit
    ventilation_requested
    air_flow
    air_flow_unit
    humidity_control_requested
    relative_humidity_percent
    vent_setting
    additional_instructions
    genset_requested
    controlled_atmosphere_requested
    oxygen_level_percent
    nitrogen_level_percent
    carbon_dioxide_level_percent
    length
    width
    height
    lbh_unit
  }
`;

export const containerMinimumFields = gql`
  fragment containerMinimumFields on ShipmentContainerObject {
    id
    container_type_code
    container_type
    maximum_cargo_weight
    maximum_cargo_volume
    container_number
    is_non_iso_container
    carrier_seal_number
    cargo_gross_weight
    verified_gross_mass
    weight_slip_number
    cargo_net_weight
    commercial_invoice_number
    purchase_order_number
    last_action_status
    shipper_seal_number
    customs_seal_number
    booking_number
    job_number
    container_cargo_details {
      id
      gross_weight
      net_weight
    }
    container_settings {
      ...containerSettingsFields
    }
    ocean_transport_order_ids
  }
  ${containerSettingsFields}
`;

export const shipmentFields = gql`
  fragment shipmentFields on ShipmentObject {
    id
    direct_port_delivery
    pickup_address_print
    accounting_status
    transporter_name
    status
    created_at
    updated_at
    next_actions
    business_type
    booking_status
    shipment_booking_number
    is_linked_with_booking
    commodity_description
    is_from_consol_helper
    consol_type
    voyage_number
    booking_type
    current_voyage_number
    vessel_name
    movement_type
    destuffing_type
    tickets {
      id
      title
      ticket_number
      priority
      pipeline {
        id
        name
      }
      current_state {
        id
        name
      }
      references {
        id
        data
        reference_id
        reference_type
      }
    }
    customer_company {
      ...minimumCompanyFields
    }
    customer_address {
      id
      name
      company_id
      city_name
      entity_type
      print_address
    }
    cargo_type
    movement_mode
    vehicle_category
    vehicle_license_plate_number
    trip_distance
    driver_name
    driver_contact_number
    sales_agent {
      ...salesPersonFields
    }
    quotation_number
    other_job_number
    inquiry_number
    purchase_order_number
    preferred_carriers {
      id
      name
      carrier_type
    }
    sales_order_number
    is_cargo_ready_date_appliable
    is_purchase_order_number_appliable
    is_sales_order_number_appliable
    cargo_ready_date
    target_shipping_date
    target_delivery_date
    received_for_shipment_by_shipping_line_date
    received_for_shipment_by_forwarder_date
    shipped_on_board_date
    consignee_party_name
    stuffing_type
    stuffing_location {
      ...stuffingLocationFields
    }
    cargos {
      ...cargoFields
    }
    destuffing_type
    destuffing_location {
      ...stuffingLocationFields
    }
    movement_type
    movement_location {
      ...stuffingLocationFields
    }
    final_place_of_delivery {
      ...finalPlaceOfDeliveryFields
    }
    final_place_of_delivery_print
    shipment_documents {
      ...shipmentDocumentFields
    }
    shipment_packages {
      ...packagesFields
    }
    services {
      ...shipmentServicesFields
    }
    carrier {
      ...globalCarrierFields
    }
    shipment_custom_details {
      ...shipmentCustomDetailsFields
    }
    shipment_invoices {
      ...shipmentInvoicesFields
    }
    shipment_properties {
      ...shipmentPropertiesFields
    }
    shipment_product_ratings {
      ...productRatingFields
    }
    ocean_vessel {
      ...OceanVesselFields
    }
    current_vessel {
      ...OceanVesselFields
    }
    carrier_product
    document_dispatch_date
    estimated_time_of_arrival
    eta_status
    linked_shipment_id
    linked_shipment {
      id
      job_number
      port_of_discharge {
        ...globalPortFields
      }
      carrier {
        ...globalCarrierFields
      }
    }
    link_shipment_json
    child_linked_shipments {
      id
      job_number
    }
    eta_tsp1
    eta_tsp2
    etd_tsp1
    etd_tsp2
    estimated_time_of_arrival_at_fpod
    actual_time_of_arrival
    actual_time_of_arrival_pocd
    destination_last_inward_date
    pocd_last_inward_date
    estimated_time_of_departure
    atd_pol_date
    freight_type
    gross_volume
    gross_weight
    net_weight
    chargeable_weight
    volumetric_weight
    incoterms
    letter_of_credit_number
    letter_of_credit_date
    destination_carrier_free_days
    destination_port_free_days
    origin_carrier_free_days
    is_external_quotation_number
    involved_branch {
      ...branchAccountFields
    }
    accessible_by_branches {
      id
      name
      erp_cost_center_id
    }
    job_number
    job_date
    load_type
    shipping_line_service_type
    master_shipment_id
    master_shipment {
      id
      job_number
      shipment_documents {
        ...shipmentDocumentFields
      }
      routing_legs {
        ...routingLegFields
      }
    }
    trip_customer_order {
      id
      shipment_booking_number
    }
    port_of_discharge {
      ...globalPortFields
    }
    port_of_loading {
      ...globalPortFields
    }
    tsp_1 {
      ...globalPortFields
    }
    tsp_2 {
      ...globalPortFields
    }
    place_of_carrier_receipt {
      ...globalPortFields
    }
    place_of_carrier_delivery {
      ...globalPortFields
    }
    port_of_discharge_terminal {
      ...globalPortFields
    }
    port_of_loading_terminal {
      ...globalPortFields
    }
    place_of_carrier_delivery_terminal {
      ...globalPortFields
    }
    shipment_events {
      ...shipmentEventFields
    }
    shipment_parties {
      ...shipmentPartyFields
    }
    shipment_type
    shipment_users {
      ...shipmentUsersFields
    }
    shipment_routing_details {
      ...routingDetailsFields
    }
    commodity_description
    ocean_transport_order {
      id
      empty_pickup_location {
        ...routingLocationObjectType
      }
    }
    ocean_transport_orders {
      id
      status
      booking_type
      load_type
      booking_number
      booking_date
      valid_till_date
      global_carrier {
        id
        name
        carrier_type
      }
      vendor_company {
        id
        registered_name
      }
      customer_company {
        id
        registered_name
      }
      customer_address {
        id
        name
      }
      empty_pickup_location {
        id
        name
        unlocode
        city_code
        state_code
        country_code
      }
      container_requests {
        id
        quantity
        quantity_fulfilled
        container_type_code
        container_type
      }
      shipment_containers {
        id
        container_number
      }
      gate_open_date
      gate_close_date
      si_cutoff_date
      doc_cutoff_date
      origin_detention_free_days
      origin_demurrage_free_days
      destination_detention_free_days
      destination_demurrage_free_days
      voyage_schedule_id
      service_type
    }
    trade_type
    volume_unit
    credit_status
    weight_unit
    total_number_of_packages
    remarks
    delivery_order_number
    ocean_transport_order_id
    routing_legs {
      ...routingLegFields
    }
    inquiry_option {
      id
      quotation_number
      inquiry {
        id
        inquiry_global_id
      }
      carrier {
        ...globalCarrierFields
      }
    }
    shipment_containers {
      ...containerMinimumFields
      status
      remarks
    }
    house_shipments {
      id
      status
    }
    any_house_linked_with_lcl_booking
    last_action_status
    last_comment
    last_status_update
    tracking_events {
      ...trackingEventFields
    }
    lfd_at_carrier
    lfd_at_empty_return
    lfd_at_pod
    lfd_at_pocd
    lfd_at_cfs
    job_execution_date
    forwarding_invoice_status
    accounting_status
    atd_pcr_date
    container_tracking_events_summary {
      picked_up_containers
      pickup_pending_containers
      loaded_containers
      gated_in_containers
      late_gated_in_containers
      gated_out_containers
      empty_returned_containers
      demurrage_containers
      detention_containers
    }
    vehicle {
      id
      ownership_type
      vehicle_category
      vehicle_chassis_number
      vehicle_fuel_type
      vehicle_insurance_amount
      vehicle_insurance_coverage_type
      vehicle_insurance_date
      vehicle_insurance_expiry_date
      vehicle_insurance_number
      vehicle_license_plate_number
      vehicle_registration_date
      vehicle_registration_expiry_date
      vehicle_status
      model
      max_weight_capacity
      make
      weight_unit
      company {
        ...minimumCompanyFields
      }
    }
    driver {
      id
      driver_name
      driver_contact_number
      driver_license_number
    }
    ordered_quantity
    allocated_quantity
    allocation_pending_quantity
    shipment_manifests {
      id
      location_type
      manifest_number
      trade_type
      manifest_date
      country
      line_number
      subline_number
      inward_date
    }
    isf_filed_by
    priority
    color_code
    teams {
      id
      role
      branch_accounts {
        id
        name
      }
      user_contact {
        id
        first_name
        last_name
        name
      }
      sales_person {
        ...salesPersonFields
      }
      reference_id
      reference_type
    }
    split_from_order_id
    pocd_last_inward_date
    merged_by_user {
      department
      email
      first_name
      id
      name
      last_name
      mobile_number
    }
    split_by_user {
      department
      email
      first_name
      id
      name
      last_name
      mobile_number
    }
    merged_at
    split_at
    primary_ocean_transport_order {
      id
      booking_number
      service_type
      booking_date
    }
  }
  ${minimumCompanyFields}
  ${salesPersonFields}
  ${stuffingLocationFields}
  ${finalPlaceOfDeliveryFields}
  ${shipmentDocumentFields}
  ${packagesFields}
  ${cargoFields}
  ${shipmentServicesFields}
  ${globalCarrierFields}
  ${shipmentCustomDetailsFields}
  ${shipmentInvoicesFields}
  ${shipmentPropertiesFields}
  ${productRatingFields}
  ${OceanVesselFields}
  ${branchAccountFields}
  ${globalPortFields}
  ${shipmentEventFields}
  ${shipmentPartyFields}
  ${shipmentUsersFields}
  ${routingDetailsFields}
  ${containerMinimumFields}
  ${trackingEventFields}
  ${routingLegFields}
  ${routingLocationObjectType}
`;

export const FETCH_CONTAINERS_FOR_CONSOL = gql`
  query fetch_containers_for_consol($shipment_id: ID!) {
    fetch_containers_for_consol(shipment_id: $shipment_id) {
      ...containerMinimumFields
      eway_bill_no
      eway_bill_validity
      container_settings {
        ...containerSettingsFragmentFields
      }
    }
  }
  ${containerMinimumFields}
  ${containerSettingsFragmentFields}
`;

export const FETCH_CARGOS_FOR_CONSOL = gql`
  query fetch_cargos_for_consol($shipment_id: ID!) {
    fetch_cargos_for_consol(shipment_id: $shipment_id) {
      ...cargoFields
      split_from_cargo_id
    }
  }
  ${cargoFields}
`;

export const FETCH_LOADS_FROM_SAME_CUSTOMER = gql`
  query fetch_loads_from_same_customer($shipment_id: ID!) {
    fetch_loads_from_same_customer(shipment_id: $shipment_id) {
      ...shipmentFields
      cargos {
        ...cargoFields
      }
      shipment_containers {
        ...containerMinimumFields
        container_settings {
          ...containerSettingsFragmentFields
        }
      }
    }
  }
  ${cargoFields}
  ${containerMinimumFields}
  ${shipmentFields}
  ${containerSettingsFragmentFields}
`;

export const FETCH_ALL_HOUSE_LOADS = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      shipment_type
      house_shipments {
        id
        cargos {
          ...cargoFields
        }
        shipment_containers {
          ...containerMinimumFields
          eway_bill_no
          eway_bill_validity
          container_settings {
            ...containerSettingsFragmentFields
          }
        }
        shipment_documents {
          id
          document_type
          shipment_number
          document_status
        }
      }
    }
  }
  ${cargoFields}
  ${containerMinimumFields}
  ${containerSettingsFragmentFields}
`;

export const shipmentDocumentWithShipmentFields = gql`
  fragment shipmentDocumentWithShipmentFields on ShipmentDocumentObject {
    bl_type
    document_id
    document_status
    eawb_status
    document_type
    shipment_id
    shipment_number
    shipment_date
    house_number_sequence_id
    id
    release_type
    shipment {
      ...shipmentFields
    }
    charge_terms {
      ...chargeTermFields
    }
    stock_order_item {
      ...stockOrderItemFields
    }
    shipper_on_document
    consignee_on_document
    is_surrendered
    surrender_location
    si_filing_date
    email_activities {
      id
      purpose
    }
  }
  ${shipmentFields}
  ${chargeTermFields}
  ${stockOrderItemFields}
`;

export const SHIPMENT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;

export const HOUSE_SHIPMENTS = gql`
  query shipments($master_shipment_id: ID!) {
    shipments(master_shipment_id: $master_shipment_id) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;

export const SHIPMENT_WITH_HOUSE_SHIPMENTS = gql`
  query shipmentWithHouseShipments($shipmentId: ID!) {
    shipment(id: $shipmentId) {
      ...shipmentFields
      house_shipments {
        ...shipmentFields
      }
      trips {
        ...shipmentFields
        house_shipments {
          id
          status
          job_number
          shipment_documents {
            ...shipmentDocumentFields
          }
        }
      }
    }
  }
  ${shipmentFields}
  ${shipmentDocumentFields}
`;

export const GENERATE_SHIPMENT_PUBLIC_LINK = gql`
  mutation generateShipmentPublicLink($id: ID!) {
    generate_shipment_public_link(id: $id) {
      link
    }
  }
`;

export const CANCEL_AIR_SHIPMENT_REQUEST = gql`
  mutation cancelAirShipmentRequest(
    $id: ID!
    $returned_from: ReturnedFromEnumType
    $cancelled_by: CancelledByEnumType
    $cancellation_reason: String
    $back_to_town: Boolean
    $action_for_houses: CancellationActionForHouses
  ) {
    cancel_air_shipment(
      id: $id
      returned_from: $returned_from
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
      back_to_town: $back_to_town
      action_for_houses: $action_for_houses
    ) {
      shipment {
        id
      }
    }
  }
`;

export const CANCEL_ROAD_HOUSE_SHIPMENT = gql`
  mutation cancelRoadHouseShipment(
    $id: ID!
    $returned_from: ReturnedFromEnumType
    $cancelled_by: CancelledByEnumType
    $cancellation_reason: String
  ) {
    cancel_road_house_shipment(
      id: $id
      returned_from: $returned_from
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
    ) {
      id
    }
  }
`;

export const UPDATE_ROUTING_DETAILS = gql`
  mutation updateRouting(
    $selected_containers: [AllocateContainerInputType!]!
    $oto_args: OceanTransportOrderInputType!
    $old_shipment_id: ID
    $selected_oto_id: ID
    $only_update_routing: Boolean!
    $routing_update: RoutingUpdateInputType
  ) {
    update_routing(
      selected_containers: $selected_containers
      oto_args: $oto_args
      old_shipment_id: $old_shipment_id
      selected_oto_id: $selected_oto_id
      only_update_routing: $only_update_routing
      routing_update: $routing_update
    ) {
      message
      new_shipment_id
    }
  }
`;

export const CREATE_PLANNED_SHIPMENT = gql`
  mutation createPlannedShipment(
    $planned_shipment: CreateShipmentInput
    $shipment_document_master: ShipmentDocumentInput
    $stock_order: SingleStockOrderInput
    $throw_error_on_credit_fail: Boolean
  ) {
    create_planned_shipment(
      planned_shipment: $planned_shipment
      shipment_document_master: $shipment_document_master
      stock_order: $stock_order
      throw_error_on_credit_fail: $throw_error_on_credit_fail
    ) {
      id
      ocean_transport_orders {
        ...oceanTransportOrderFields
      }
    }
  }
  ${oceanTransportOrderFields}
`;

export const SPLIT_SHIPMENT = gql`
  mutation splitShipment(
    $customer_order_id: ID!
    $source_booking_id: ID
    $job_date: String
    $shipment_container_ids: [String!]
    $shipment_type: String
    $requested_containers: [AllocateContainerInputType!]
    $action: String!
  ) {
    split_shipment(
      customer_order_id: $customer_order_id
      source_booking_id: $source_booking_id
      job_date: $job_date
      shipment_container_ids: $shipment_container_ids
      shipment_type: $shipment_type
      requested_containers: $requested_containers
      action: $action
    ) {
      id
    }
  }
`;

export const DEALLOCATE_OCEAN_TRANSPORT_ORDER_FROM_SHIPMENT = gql`
  mutation deallocate_ocean_transport_order_from_shipment(
    $ocean_transport_order_id: ID!
    $shipment_id: ID!
  ) {
    deallocate_ocean_transport_order_from_shipment(
      ocean_transport_order_id: $ocean_transport_order_id
      shipment_id: $shipment_id
    ) {
      shipment {
        ...shipmentFields
      }
    }
  }
  ${shipmentFields}
`;

export const CHANGE_BOOKING_ALLOCATION = gql`
  mutation change_booking_allocation(
    $requested_crs: [ChangeAllocationInputType!]!
    $container_qty: [ContainerRequestInputType!]
    $shipment_id: String!
  ) {
    change_booking_allocation(
      requested_crs: $requested_crs
      container_qty: $container_qty
      shipment_id: $shipment_id
    ) {
      message
    }
  }
`;

export const MERGE_SHIPMENT = gql`
  mutation merge_shipment($from_shipment_id: ID!, $to_shipment_id: ID!) {
    merge_shipment(from_shipment_id: $from_shipment_id, to_shipment_id: $to_shipment_id) {
      message
    }
  }
`;

export const ALLOCATE_JOB_NUMBER = gql`
  mutation allocate_job_number(
    $id: ID!
    $shipment_type: ShipmentTypeEnum!
    $consol_type: ConsolTypeEnum
  ) {
    allocate_job_number(id: $id, shipment_type: $shipment_type, consol_type: $consol_type) {
      id
      shipment_type
    }
  }
`;

export const DELINK_SHIPMENT = gql`
  mutation delinkShipment($id: ID!) {
    delink_house_shipment(id: $id) {
      shipment {
        ...shipmentFields
      }
      house_shipments {
        ...shipmentFields
      }
    }
  }
  ${shipmentFields}
`;

export const DELETE_SHIPMENT = gql`
  mutation deleteShipment($id: ID!) {
    delete_shipment(id: $id) {
      message
    }
  }
`;

export const UPDATE_SHIPMENT_STATUS = gql`
  mutation updateShipmentStatus(
    $id: ID!
    $action: ShipmentStateActionsEnumType!
    $event_data: JSON
  ) {
    update_shipment_state(id: $id, action: $action, event_data: $event_data) {
      id
      shipment_events {
        ...shipmentEventFields
      }
      status
    }
  }
  ${shipmentEventFields}
`;

export const BULK_UPDATE_SHIPMENT_FIELD = gql`
  mutation bulk_update_shipment_field($ids: [ID!]!, $input: BulkShipmentUpdateFieldsInputType) {
    bulk_update_shipment_field(ids: $ids, input: $input) {
      id
      job_number
      lfd_at_pocd
      lfd_at_carrier
      lfd_at_empty_return
      lfd_at_pod
      color_code
    }
  }
`;

export const GENERATE_DELIVERY_ORDER_NUMBER = gql`
  mutation generate_delivery_order_number($shipment_id: ID) {
    generate_delivery_order_number(shipment_id: $shipment_id) {
      id
      delivery_order_number
    }
  }
`;

export const CONVERT_B2B_TO_CONSOL = gql`
  mutation convert_b2b_to_consol(
    $shipment_id: ID!
    $consol_type: ConsolTypeEnum!
    $house_load_type: LoadTypeEnum!
  ) {
    convert_b2b_to_consol(
      shipment_id: $shipment_id
      consol_type: $consol_type
      house_load_type: $house_load_type
    ) {
      shipment {
        ...shipmentFields
      }
      house_shipments {
        ...shipmentFields
      }
    }
  }
  ${shipmentFields}
`;

export const CONVERT_B2B_TO_DIRECT = gql`
  mutation convert_back_to_back_to_direct($shipment_id: ID!) {
    convert_back_to_back_to_direct(shipment_id: $shipment_id) {
      id
      shipment_type
      shipment_documents {
        ...shipmentDocumentFields
      }
    }
  }
  ${shipmentDocumentFields}
`;

export const CONVERT_DIRECT_TO_B2B = gql`
  mutation convert_direct_to_back_to_back(
    $shipment_id: ID!
    $house_document_shipment_number: String
    $house_document_shipment_date: String
    $bl_status: String
    $document_status_event_date: String
  ) {
    convert_direct_to_back_to_back(
      shipment_id: $shipment_id
      house_document_shipment_number: $house_document_shipment_number
      house_document_shipment_date: $house_document_shipment_date
      bl_status: $bl_status
      document_status_event_date: $document_status_event_date
    ) {
      shipment {
        id
        shipment_type
        involved_branch {
          ...branchAccountFields
        }
        shipment_properties {
          ...shipmentPropertiesFields
        }
        shipment_parties {
          ...shipmentPartyFields
        }
        shipment_documents {
          ...shipmentDocumentFields
        }
      }
    }
  }
  ${shipmentDocumentFields}
  ${branchAccountFields}
  ${shipmentPartyFields}
  ${shipmentPropertiesFields}
`;

export const SHIPMENT_FOR_PRE_ALERT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      final_place_of_delivery {
        id
        name
        country_name
      }
      job_number
      freight_type
      shipment_type
      house_shipments {
        id
        final_place_of_delivery {
          id
          name
          country_name
        }
        job_number
        shipment_type
        shipment_documents {
          id
          document_type
          document_status
          eawb_status
          document_id
          shipment_number
          bl_type
        }
      }
      trips {
        id
        final_place_of_delivery {
          id
          name
          country_name
        }
        job_number
        shipment_type
        shipment_documents {
          id
          document_type
          document_status
          eawb_status
          document_id
          shipment_number
          bl_type
        }
      }
      shipment_documents {
        id
        document_type
        document_status
        eawb_status
        document_id
        shipment_number
        bl_type
      }
      shipment_parties {
        id
        name
        party_address {
          id
          name
        }
        party_company {
          id
          registered_name
          global_company_account {
            id
          }
        }
        party_details
        party_referenceable_id
        party_referenceable_type
      }
      customer_company {
        id
        registered_name
        global_company_account {
          id
        }
      }
      customer_address_id
    }
  }
`;

export const CARGO_WITH_TRIPS = gql`
  query cargo_with_trips($id: ID!) {
    shipment(id: $id) {
      cargos {
        ...cargoFields
        trips {
          id
          job_number
          shipment_documents {
            id
            document_type
            document_id
            shipment_number
            shipment_date
            shipment_id
          }
        }
      }
      house_shipments {
        cargos {
          ...cargoFields
        }
      }
    }
  }
  ${cargoFields}
`;

export const minimumShipmentField = gql`
  fragment minimumShipmentField on ShipmentObject {
    id
    job_number
    shipment_type
    freight_type
    trade_type
    load_type
    accounting_status
    last_action_status
    customer_company {
      id
      registered_name
    }
    routing_legs {
      ...routingLegFields
    }
    split_from_order_id
    shipment_documents {
      ...shipmentDocumentFields
    }
    allocation_pending_quantity
    shipment_containers {
      ...containerMinimumFields
      container_settings {
        ...containerSettingsFragmentFields
      }
    }
    shipment_booking_number
  }
  ${routingLegFields}
  ${shipmentDocumentFields}
  ${containerMinimumFields}
  ${containerSettingsFragmentFields}
`;

export const FETCH_CARGO = gql`
  query fetch_cargo($id: ID!) {
    fetch_cargo(id: $id) {
      id
      shipment {
        ...minimumShipmentField
      }
      allocation_pending_quantity
    }
  }
  ${minimumShipmentField}
`;

export const FETCH_BULK_SHIPMENT = gql`
  query shipments($id: [ID!]!) {
    shipments(id: $id) {
      ...minimumShipmentField
      cargos {
        ...cargoFields
      }
      shipment_containers {
        ...containerMinimumFields
        container_settings {
          ...containerSettingsFragmentFields
        }
      }
    }
  }
  ${cargoFields}
  ${containerMinimumFields}
  ${minimumShipmentField}
  ${containerSettingsFragmentFields}
`;

export const FETCH_BULK_CONTAINER = gql`
  query containers($id: [ID!]!) {
    containers(id: $id) {
      id
      container_cargo_details {
        shipment_id
        shipment {
          ...minimumShipmentField
        }
      }
    }
  }
  ${routingLegFields}
  ${shipmentDocumentFields}
  ${containerMinimumFields}
  ${containerSettingsFragmentFields}
  ${minimumShipmentField}
`;
