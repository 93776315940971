import { useLazyQuery } from '@apollo/client';
import { Col, Form, FormInstance, Input, Row, DatePicker } from '@shipmnts/pixel-hub';
import { VesselSearch } from 'common';
import { SHIPMENT } from 'operations/graphql/shipment';
import { convertToDayJs } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import React, { useEffect, useState } from 'react';
import {
  FreightType,
  MOVEMENT_MODE_AIRPORT_DELIVERY,
  MOVEMENT_MODE_AIRPORT_PICKUP,
  MOVEMENT_MODE_PICKUP_AND_DROP,
  MOVEMENT_MODE_CONTAINER_STUFFING,
  MOVEMENT_MODE_CONTAINER_DESTUFFING,
  ROW_GUTTER,
  MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP,
} from '../../constants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface LinkShipmentDetailsProps {
  shipment: ShipmentValue;
  form: FormInstance<any>;
  disableShipmentSearch: boolean;
  hide?: boolean;
}

export default function LinkShipmentDetails(props: LinkShipmentDetailsProps) {
  const { form, disableShipmentSearch = false, shipment } = props;
  const shipmentFromSearch = Form.useWatch('linked_to_shipment', form);
  const movementMode = Form.useWatch('movement_mode', form) || shipment?.movement_mode;
  const [fetchLinkedShipment, { data: linkedShipmentData }] = useLazyQuery(SHIPMENT);
  const [searchFreightType, setSearchFreightType] = useState<FreightType | undefined>(undefined);
  const [searchTradeType, setSearchTradeType] = useState<string | undefined>(undefined);
  const [voyageNumberLabel, setVoyageNumberLabel] = useState<string>('Voyage Number');
  const linkedShipmentId = Form.useWatch('linked_shipment_id', form);
  const isOceanMovementMode = [
    MOVEMENT_MODE_CONTAINER_DESTUFFING,
    MOVEMENT_MODE_CONTAINER_STUFFING,
  ].includes(movementMode || '');
  const isAirMovementMode = [MOVEMENT_MODE_AIRPORT_DELIVERY, MOVEMENT_MODE_AIRPORT_PICKUP].includes(
    movementMode || ''
  );
  const isDoorMovementMode = [
    MOVEMENT_MODE_PICKUP_AND_DROP,
    MOVEMENT_MODE_CONTAINER_PICKUP_AND_DROP,
  ].includes(movementMode);

  const updateFormWithLinkShipment = (
    form: FormInstance<any>,
    linkedShipmentData: ShipmentValue
  ) => {
    form?.setFieldsValue({
      ocean_vessel: linkedShipmentData?.ocean_vessel,
      carrier: linkedShipmentData?.carrier,
      lfd_at_empty_return: convertToDayJs(linkedShipmentData.lfd_at_empty_return),
      estimated_time_of_arrival: convertToDayJs(linkedShipmentData.estimated_time_of_arrival),
      estimated_time_of_departure: convertToDayJs(linkedShipmentData.estimated_time_of_departure),
      voyage_number: linkedShipmentData.voyage_number,
      ocean_transport_order: {
        valid_till_date: convertToDayJs(linkedShipmentData?.ocean_transport_order?.valid_till_date),
        booking_number: linkedShipmentData?.ocean_transport_order?.booking_number,
        gate_close_date: convertToDayJs(linkedShipmentData.ocean_transport_order?.gate_close_date),
      },
      lfd_at_pod: convertToDayJs(linkedShipmentData.lfd_at_pod),
    });
  };

  useEffect(() => {
    if (
      movementMode === MOVEMENT_MODE_CONTAINER_STUFFING ||
      movementMode === MOVEMENT_MODE_CONTAINER_DESTUFFING
    ) {
      setSearchFreightType('ocean');
    } else if (
      movementMode === MOVEMENT_MODE_AIRPORT_DELIVERY ||
      movementMode === MOVEMENT_MODE_AIRPORT_PICKUP
    ) {
      setSearchFreightType('air');
    } else {
      setSearchFreightType(undefined);
    }

    if (
      movementMode === MOVEMENT_MODE_AIRPORT_DELIVERY ||
      movementMode === MOVEMENT_MODE_CONTAINER_STUFFING
    ) {
      setSearchTradeType('export');
    } else if (movementMode === MOVEMENT_MODE_PICKUP_AND_DROP) {
      setSearchTradeType(undefined);
    } else {
      setSearchTradeType('import');
    }
    setVoyageNumberLabel(isAirMovementMode ? 'Flight Number' : 'Voyage Number');
  }, [movementMode, isAirMovementMode]);

  useEffect(() => {
    if (linkedShipmentId) {
      fetchLinkedShipment({
        variables: { id: linkedShipmentId },
      });
    }
  }, [fetchLinkedShipment, linkedShipmentId]);

  useEffect(() => {
    if (linkedShipmentData?.shipment) {
      form.setFieldValue('linked_to_shipment', linkedShipmentData?.shipment);
      updateFormWithLinkShipment(form, linkedShipmentData?.shipment);
    }
  }, [form, linkedShipmentData]);

  return (
    <Row gutter={ROW_GUTTER}>
      {
        <Col span={8}>
          <Form.Item name={'linked_shipment_id'} noStyle />
          <Form.Item name="linked_to_shipment" label="Linked to an Existing Shipment">
            <GlobalSearch
              doc_type="Shipment::Shipment"
              disabled={disableShipmentSearch}
              onChange={(value) => {
                if (value) {
                  form?.setFieldsValue({
                    ocean_vessel: value.ocean_vessel,
                    carrier: value.carrier,
                    lfd_at_empty_return: convertToDayJs(value.lfd_at_empty_return),
                    estimated_time_of_arrival: convertToDayJs(value.estimated_time_of_arrival),
                    estimated_time_of_departure: convertToDayJs(value.estimated_time_of_departure),
                    voyage_number: value.voyage_number,
                    ocean_transport_order: {
                      valid_till_date: convertToDayJs(value.ocean_transport_order?.valid_till_date),
                      booking_number: value.ocean_transport_order?.booking_number,
                      gate_close_date: convertToDayJs(value.ocean_transport_order?.gate_close_date),
                    },
                    lfd_at_pod: convertToDayJs(value.lfd_at_pod),
                  });
                  if (movementMode === MOVEMENT_MODE_PICKUP_AND_DROP)
                    value.freight_type === 'air'
                      ? setVoyageNumberLabel('Flight Number')
                      : setVoyageNumberLabel('Voyage Number');
                } else {
                  form?.setFieldsValue({
                    ocean_vessel: undefined,
                    carrier: undefined,
                    lfd_at_empty_return: undefined,
                    estimated_time_of_arrival: undefined,
                    estimated_time_of_departure: undefined,
                    voyage_number: undefined,
                    ocean_transport_order: {
                      valid_till_date: undefined,
                      booking_number: undefined,
                      gate_close_date: undefined,
                    },
                    lfd_at_pod: undefined,
                  });
                }
              }}
              searchProps={{
                freight_type: searchFreightType,
                trade_type: searchTradeType,
              }}
            />
          </Form.Item>
        </Col>
      }
      {
        <Col span={8}>
          <Form.Item name="carrier" label={'Carrier'}>
            <GlobalSearch
              doc_type="Global::Carrier"
              searchProps={{
                carrier_type:
                  movementMode === MOVEMENT_MODE_PICKUP_AND_DROP
                    ? ['ocean', 'air']
                    : searchFreightType === 'ocean'
                    ? ['ocean', 'nvocc']
                    : ['air'],
              }}
              disabled={!!shipmentFromSearch}
            />
          </Form.Item>
        </Col>
      }

      {[
        MOVEMENT_MODE_CONTAINER_DESTUFFING,
        MOVEMENT_MODE_CONTAINER_STUFFING,
        MOVEMENT_MODE_PICKUP_AND_DROP,
      ].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name={['ocean_transport_order', 'booking_number']} label="Carrier Ref #">
            <Input disabled={!!shipmentFromSearch} placeholder="Enter Carrier Reference Number" />
          </Form.Item>
        </Col>
      )}
      {(isOceanMovementMode || isDoorMovementMode) && (
        <Col span={8}>
          <Form.Item label="Vessel" name="ocean_vessel">
            <VesselSearch disabled={!!shipmentFromSearch} />
          </Form.Item>
        </Col>
      )}
      {
        <Col span={8}>
          <Form.Item name="voyage_number" label={voyageNumberLabel}>
            <Input disabled={!!shipmentFromSearch} placeholder="Enter Voyage Number" />
          </Form.Item>
        </Col>
      }
      {isOceanMovementMode && (
        <Col span={8}>
          <Form.Item
            name={['ocean_transport_order', 'valid_till_date']}
            label="Carrier Validity Date"
          >
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_CONTAINER_DESTUFFING].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name="lfd_at_pod" label="LFD Port">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_CONTAINER_DESTUFFING].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name="lfd_at_empty_return" label="LFD Empty Return">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[
        MOVEMENT_MODE_CONTAINER_DESTUFFING,
        MOVEMENT_MODE_AIRPORT_PICKUP,
        MOVEMENT_MODE_PICKUP_AND_DROP,
      ].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name="estimated_time_of_arrival" label="ETA at Port">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[
        MOVEMENT_MODE_CONTAINER_STUFFING,
        MOVEMENT_MODE_AIRPORT_DELIVERY,
        MOVEMENT_MODE_PICKUP_AND_DROP,
      ].includes(movementMode) && (
        <Col span={8}>
          <Form.Item name="estimated_time_of_departure" label="ETD at Port">
            <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      )}
      {[MOVEMENT_MODE_CONTAINER_STUFFING].includes(movementMode) && (
        <>
          <Col span={8}>
            <Form.Item
              name={['ocean_transport_order', 'gate_close_date']}
              label="Gate Close Cutoff"
            >
              <DatePicker disabled={!!shipmentFromSearch} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
}
