/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Layout,
  Button,
  Tabs,
  Card,
  Skeleton,
  Result,
  Tooltip,
  Typography,
  Tag,
  TabsProps,
  UploadedDocuments,
} from '@shipmnts/pixel-hub';
import { PageHeader, useActivityContext } from '@shipmnts/pixel-hub';
import { AppHelmet, CustomIcon } from '@shipmnts/pixel-hub';
import { useQuery } from '@apollo/client';
import { useSession, BreadCrumbsMapRender } from 'common';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
import { AmendmentDisableDetail } from 'operations/models/BookingRequest';
import { SessionDataValue } from 'operations/models/SessionData';
import { GET_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/graphql/oceanTransportOrder';
import { observer } from 'mobx-react-lite';
import { isLiveReeferCargo } from 'operations/models/Cargo';
import { STATUS_CANCELLED } from 'operations/modules/reports/constants';
import BookingOrderOverview from './BookingOrderOverview';
import ContainerDetailsCard from '../BookingRequestView/ContainerDetailsCard';
import CargoDetailsCard from '../BookingRequestView/CargoDetailsCard';
import RoutingDetailsCard from '../BookingRequestView/RoutingDetailsCard';
import OtherDetailsCard from '../BookingRequestView/OtherDetailsCard';
import CutoffEmptyPickupDetailsCard from './CutoffEmptyPickupDetailsCard';
import { BOOKING_TYPE_OCEAN_TRANSPORT_ORDER } from 'operations/modules/booking/constants';
import { startCase as _startCase } from 'lodash';
import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';
import { STATUS_STUFFING_ORDER_CARTED_IN } from 'operations/constants';
import { ActionRenderer } from 'operations/modules/actionHelper/ActionRenderer';
import { useParams, Link } from 'wouter';
const { Content, Header } = Layout;
const { Paragraph } = Typography;

const BookingOrderViewContent = observer(function BookingOrderViewContent(props: {
  oceanTransportOrder: OceanTransportOrderValue;
  sessionData: SessionDataValue;
  refetchData: () => void;
}): JSX.Element {
  const { oceanTransportOrder, sessionData } = props;
  const isLiveReefer = useMemo(
    () => (oceanTransportOrder.cargos ? isLiveReeferCargo(oceanTransportOrder.cargos) : false),
    [oceanTransportOrder.cargos]
  );

  const allSections = [
    'overview',
    'routing_details',
    'allocated_booking_requests',
    'container_requests',
    'cargos',
    'cutoff_details',
    'other_details',
  ];
  const amendmentDisableDetails = allSections.reduce(
    (
      obj: {
        [key: string]: AmendmentDisableDetail;
      },
      section: string
    ) => {
      obj[section] = oceanTransportOrder.amendmentDisableDetails(section);
      return obj;
    },
    {}
  );
  const isLinkedWithShipments = Boolean(
    oceanTransportOrder.shipments && oceanTransportOrder.shipments.length > 0
  );
  return (
    <>
      <BookingOrderOverview
        oceanTransportOrder={oceanTransportOrder}
        disabled={amendmentDisableDetails?.overview?.isDisable}
        disableReason={amendmentDisableDetails?.overview?.disableReason}
        onUpdate={(oto) => oceanTransportOrder.setBasicDetails(oto)}
      />
      <RoutingDetailsCard
        validateVesselVoyage={oceanTransportOrder.load_type === LOAD_TYPE_FCL}
        parent_id={oceanTransportOrder.id}
        parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
        routingLegs={oceanTransportOrder.routing_legs}
        globalCarrierId={oceanTransportOrder?.global_carrier?.id}
        voyageScheduleId={oceanTransportOrder?.voyage_schedule_id}
        isReeferContainer={oceanTransportOrder?.container_requests?.[0]?.is_reefer_container}
        bookingType={oceanTransportOrder?.booking_type}
        onUpdate={(routing_legs) => oceanTransportOrder.setRoutingLegs(routing_legs)}
        disabled={amendmentDisableDetails?.routing_details?.isDisable}
        disableReason={amendmentDisableDetails?.routing_details?.disableReason}
        onlyTranshipmentHopEditable={
          !amendmentDisableDetails?.routing_details?.isDisable &&
          amendmentDisableDetails?.routing_details?.blReleased
        }
      />
      {/* {oceanTransportOrder.isFullyUnallocated ? (
        <Card id="allocated_booking_requests" title="Customer Orders">
          <div style={{ textAlign: 'center' }}>
            <ActionRenderer
              id={oceanTransportOrder.id}
              data={oceanTransportOrder}
              doc_type_id="Shipment::OceanTransportOrder"
              refetchData={refetchData}
              selectedActions={[createAndAllocateBr(oceanTransportOrder)]}
            />
          </div>
        </Card>
      ) : (
        <AllocatedBookingRequestsView
          bookingRequests={oceanTransportOrder.booking_requests || []}
          oceanTransportOrder={oceanTransportOrder}
          disabled={amendmentDisableDetails?.allocated_booking_requests?.isDisable}
          disableReason={amendmentDisableDetails?.allocated_booking_requests?.disableReason}
          refetchData={refetchData}
        />
      )} */}
      {oceanTransportOrder?.load_type === LOAD_TYPE_FCL && (
        <ContainerDetailsCard
          parent_id={oceanTransportOrder.id}
          parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
          containerRequests={oceanTransportOrder?.container_requests || []}
          onUpdate={(ocean_transport_order) => {
            const oto = ocean_transport_order as OceanTransportOrderValue;
            oceanTransportOrder.setContainerRequests(oto.container_requests);
            oceanTransportOrder.updateShipmentContainers(oto.shipment_containers);
          }}
          isLiveReefer={isLiveReefer}
          disabled={amendmentDisableDetails?.container_requests?.isDisable}
          disableReason={amendmentDisableDetails?.container_requests?.disableReason}
          disableAdd={isLinkedWithShipments}
          disableFields={amendmentDisableDetails?.container_requests?.disableFields}
        />
      )}
      <CargoDetailsCard
        parent_id={oceanTransportOrder.id}
        parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
        cargos={oceanTransportOrder.cargos}
        onUpdate={(cargos) => {
          oceanTransportOrder.setCargos(cargos);
        }}
        load_type={oceanTransportOrder.load_type}
        disabled={amendmentDisableDetails?.cargos?.isDisable}
        disableReason={amendmentDisableDetails?.cargos?.disableReason}
        disableCargoAddRemove={
          oceanTransportOrder.booking_requests?.[0]?.load_type === LOAD_TYPE_LCL &&
          oceanTransportOrder.booking_requests?.[0]?.stuffing_buffer_service_orders?.[0]?.status ===
            STATUS_STUFFING_ORDER_CARTED_IN
        }
      />
      <CutoffEmptyPickupDetailsCard
        oto={oceanTransportOrder}
        disabled={amendmentDisableDetails?.cutoff_details?.isDisable}
        disableReason={amendmentDisableDetails?.cutoff_details?.disableReason}
      />
      <OtherDetailsCard
        parent_id={oceanTransportOrder.id}
        parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
        remarks={oceanTransportOrder?.remarks}
        disabled={amendmentDisableDetails?.other_details?.isDisable}
        disableReason={amendmentDisableDetails?.other_details?.disableReason}
        terms_and_condition={oceanTransportOrder?.terms_and_condition}
        terms_and_condition_description={oceanTransportOrder?.terms_and_condition_description}
        booking_type={oceanTransportOrder?.booking_type}
      />
      <Card id="attachements" title="Uploaded Documents">
        <UploadedDocuments
          sessionData={sessionData as any} // TODO: Change the type in communication
          parent_id={oceanTransportOrder.id}
          parent_type={BOOKING_TYPE_OCEAN_TRANSPORT_ORDER}
          deleteDisabled={oceanTransportOrder?.status === STATUS_CANCELLED}
          docgen_url={process.env.DOCGEN_URL || ''}
          initialUploadDocumentTags={['Booking confirmation']}
        />
      </Card>
    </>
  );
});

const BookingOrderView = React.memo(function BookingOrderView(props: {
  id?: string;
  onClose?: () => void;
  externalLink?: boolean;
}): JSX.Element {
  const created_booking_order = window.history?.state?.booking_order;
  const params = useParams<{ id: string }>();
  const id = props?.id || params?.id;
  const sessionData = useSession();
  const {
    loading: fetching,
    error,
    data,
    refetch,
  } = useQuery(GET_OCEAN_TRANSPORT_ORDER, {
    variables: { id },
  });
  const [oceanTransportOrder, setOceanTransportOrder] = useState<
    OceanTransportOrderValue | undefined
  >(
    created_booking_order
      ? OceanTransportOrder.create(created_booking_order, { sessionData })
      : undefined
  );

  const onClose = props?.onClose;
  const externalLink = props?.externalLink;
  const loading = fetching && !oceanTransportOrder;
  const { setActivityProps } = useActivityContext();

  const didMountRef = useRef(false);

  const [activeTab, setActiveTab] = useState('overview');

  const scrollFunction = useCallback(() => {
    const content = document.getElementById('booking_request_content');
    if (content)
      [
        'overview',
        'routing_details',
        'allocated_booking_requests',
        'service_orders',
        'container_requests',
        'cargos',
        'attachements',
      ].every((key) => {
        const elem = document.getElementById(key);
        if (elem) {
          const bounding = elem.getBoundingClientRect();
          if (bounding.bottom >= content.offsetTop) {
            setActiveTab(key);
            return false;
          }
        }
        return true;
      });
  }, []);

  useEffect(() => {
    if (!didMountRef.current) {
      const content = document.getElementById('booking_request_content');
      if (content) content.addEventListener('scroll', scrollFunction);
    }
    didMountRef.current = true;
    if (!error && data?.ocean_transport_order) {
      setOceanTransportOrder(
        OceanTransportOrder.create(data?.ocean_transport_order, { sessionData })
      );
    }
  }, [data, error, scrollFunction, sessionData]);

  if (error || data?.ocean_transport_order === null) {
    let errorCode: 500 | 404 = 500,
      title = 'Something went wrong while fetching the booking order.',
      subTitle = error ? error.message : '',
      button = (
        <Button onClick={() => refetch()} type="primary">
          Retry
        </Button>
      );
    if (
      (error && error.message.includes('Shipment::OceanTransportOrder for given id Not found')) ||
      data?.ocean_transport_order === null
    ) {
      errorCode = 404;
      title = `Booking Order #${id} does not exist`;
      subTitle = '';
      button = (
        <Button type="primary">
          <Link to="/">Go Home</Link>
        </Button>
      );
    }
    return <Result status={errorCode} title={title} subTitle={subTitle} extra={button} />;
  }
  const getTab = () => {
    let tabs: TabsProps['items'] = [];

    tabs = [
      {
        label: 'Overview',
        key: 'overview',
      },
      {
        label: 'Routing Details',
        key: 'routing_details',
      },
      {
        label: 'Cargo Details',
        key: 'cargos',
      },
      {
        label: 'Uploaded Documents',
        key: 'attachements',
      },
    ];
    if (oceanTransportOrder?.load_type === LOAD_TYPE_FCL) {
      tabs.push({ label: 'Container Requests', key: 'container_requests' });
    }
    return tabs;
  };

  return (
    <Layout style={{ height: '100%' }}>
      <Layout>
        <AppHelmet>
          <title>{`#${id} | Booking Order`}</title>
        </AppHelmet>
        <Header className="component-background" style={{ padding: 0, height: 'auto' }}>
          {!onClose && (
            <div style={{ marginTop: '10px', marginLeft: '24px' }}>
              <BreadCrumbsMapRender />
            </div>
          )}
          <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active>
            <PageHeader
              onBack={onClose}
              style={{ paddingTop: '3px' }}
              title={oceanTransportOrder?.global_carrier?.name}
              extra={[
                <Button
                  key="booking_order_view_activity"
                  size={'small'}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    oceanTransportOrder?.id &&
                      setActivityProps({
                        visible: true,
                        resource_id: oceanTransportOrder?.id,
                        resource_type: 'Shipment::OceanTransportOrder',
                        showDrawer: externalLink,
                      });
                  }}
                >
                  Activity
                  <span style={{ marginLeft: '5px', display: 'flex' }}>
                    <CustomIcon icon="ActivityIcon" />
                  </span>
                </Button>,
                oceanTransportOrder ? (
                  <>
                    <ActionRenderer
                      id={oceanTransportOrder.id}
                      doc_type_id="Shipment::OceanTransportOrder"
                      isDetailScreen
                      refetchData={refetch}
                    />
                  </>
                ) : (
                  <></>
                ),
              ]}
              tags={[
                <Tag color="blue" key="load_type_trade_type">
                  {oceanTransportOrder?.load_type?.toUpperCase()}{' '}
                </Tag>,
                <Tag color="purple" key="load_type_booking_type">
                  {_startCase(oceanTransportOrder?.booking_type || '')}
                </Tag>,
              ]}
              footer={
                <Tabs
                  activeKey={activeTab}
                  onTabClick={(key: string) => {
                    const elem = document.getElementById(key);
                    const content = document.getElementById('booking_request_content');
                    if (elem && content) {
                      elem.scrollIntoView({ behavior: 'smooth' });
                    }
                    setActiveTab(key);
                  }}
                  items={getTab()}
                ></Tabs>
              }
            >
              <Paragraph ellipsis type="secondary">
                {oceanTransportOrder?.booking_number || oceanTransportOrder?.id}
                {externalLink && (
                  <span style={{ marginLeft: '10px' }}>
                    <Tooltip title="Open Detail Screen">
                      <a href={`/view/booking_order/${id}`} target="_blank" rel="noreferrer">
                        <CustomIcon icon="ExternalLinkIcon" />
                      </a>
                    </Tooltip>
                  </span>
                )}
              </Paragraph>
            </PageHeader>
          </Skeleton>
        </Header>
        <Content id="booking_request_content" className="view-content">
          {loading && (
            <>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active />
              </Card>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active />
              </Card>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={loading} active />
              </Card>
            </>
          )}
          {oceanTransportOrder && (
            <BookingOrderViewContent
              oceanTransportOrder={oceanTransportOrder}
              sessionData={sessionData}
              refetchData={refetch}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
});

export default BookingOrderView;
