import React, { useState } from 'react';
import {
  Form,
  SingleQuickFilter,
  Checkbox,
  FormInstance,
  FilterValueWrapper,
  Row,
  Col,
  dayjs,
} from '@shipmnts/pixel-hub';
import { startCase } from 'lodash';

type FieldTypes =
  | 'String'
  | 'Integer'
  | 'Float'
  | 'Boolean'
  | 'DateTime'
  | 'Date'
  | 'Dropdown'
  | 'Search';
export interface FiltersObjectType {
  name: string;
  label?: string;
  is_quick_filter?: boolean;
  field_type: FieldTypes;
  filter_options?: string;
  operator?: string;
}

export interface SortsObjectType {
  id: string;
  field_type: FieldTypes;
  label: string;
}

interface getFormItemProp {
  form: FormInstance;
  filter: FiltersObjectType;
}

const GetFormItem: React.FC<getFormItemProp> = (props) => {
  const { filter, form } = props;
  const [show, setShow] = useState<boolean>(!!form.getFieldValue(['filters', filter.name])); // Initialize any state needed
  return (
    <>
      <Checkbox
        checked={show}
        onChange={(e) => {
          const checked = e.target.checked;
          if (!checked) {
            form.setFieldValue(['filters', filter.name], undefined);
          }
          setShow(e.target.checked);
        }}
      >
        {filter.label || startCase(filter.name)}
      </Checkbox>
      {show && (
        <div style={{ margin: '10px 0px' }}>
          <Form.Item
            name={['filters', filter.name]}
            labelCol={{ span: 0 }}
            rules={[{ required: true }]}
            required={true}
          >
            <FilterValueWrapper
              key={filter.name}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              field={{ id: filter.name, ...filter, label: filter.label || startCase(filter.name) }}
              operator={filter.operator || 'in'}
            />
          </Form.Item>
        </div>
      )}
    </>
  );
};

const generateFilters = (dataField: any) => {
  const filter: any = {};
  if (!dataField) return filter;
  Object.keys(dataField)?.forEach((key) => {
    const value = dataField[key];
    if (Array.isArray(value)) {
      filter[key] = value.map((e) =>
        dayjs.isDayjs(e) ? e?.unix() : typeof e === 'object' && !!e ? e?.id : e
      );
    } else if (dayjs.isDayjs(value)) filter[key] = value?.unix();
    else if (typeof value === 'object' && value !== null) filter[key] = value?.id;
    else filter[key] = value;
  });
  return filter;
};

export const generatePayload = (data: any) => {
  return {
    filters: generateFilters({ ...data?.quick_filters, ...data?.filters }),
    sort: (data.sort || []).map((sortField: any) => ({
      direction: sortField.direction,
      field_id: sortField.field_id,
    })),
  };
};

export function getMoreFilters(filters: FiltersObjectType[], form: FormInstance) {
  return (
    <Row gutter={32}>
      {filters.map((filter) => (
        <Col key={filter.name} span={24}>
          <GetFormItem key={filter.name} form={form} filter={filter} />
        </Col>
      ))}
    </Row>
  );
}

export function getQuickFilters(filters: FiltersObjectType[]) {
  return [
    filters
      .filter((filter) => filter?.is_quick_filter)
      .map((filter) => (
        <Form.Item noStyle name={['quick_filters', filter.name]} key={filter.name}>
          <SingleQuickFilter
            key={filter.name}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            field={{ id: filter.name, ...filter, label: filter.label || startCase(filter.name) }}
            operator={filter.operator || 'in'}
          />
        </Form.Item>
      )),
  ];
}
