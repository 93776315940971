import { Col, DatePicker, Form, FormInstance, GlobalSearch } from '@shipmnts/pixel-hub';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';
import {
  getCarriageWiseRoutingLegs,
  ROUTING_TYPE_MAIN_CARRIAGE,
} from 'operations/models/RoutingLeg';
import React from 'react';
import { getCutoffDateDisableFunction } from '../../helpers/CutoffDateHelper';
import { LOCATION_TYPE_CFS, LOCATION_TYPE_ICD } from 'operations/models/Location';
import { TRADE_TYPE_EXPORT } from 'operations/modules/shipment/constants';
import { dayjs, Dayjs } from '@shipmnts/pixel-hub';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';

interface BookingOrderNewDesignProps {
  form?: FormInstance<any>;
  loadType: string | undefined;
  bookingType: string | undefined | null;
  tradeType?: string | undefined | null;
  colSpan?: number;
  areCutoffRequired?: boolean;
  disableEmptyPickupLocation?: boolean;
  isForwarding?: boolean;
}

const cutoffDateDisable = getCutoffDateDisableFunction();

const BookingOrderDetails = ({
  bookingType,
  loadType,
  colSpan,
  disableEmptyPickupLocation,
  areCutoffRequired,
  isForwarding,
}: BookingOrderNewDesignProps) => {
  const showEmptyReturnLocation =
    bookingType !== BOOKING_TYPE_VENDOR && bookingType !== BOOKING_TYPE_SHIPPING_LINE;

  return (
    <>
      <Col span={colSpan ?? 24 / 4}>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.confirmed_booking !== currentValues.confirmed_booking ||
            prevValues.routing_details !== currentValues.routing_details ||
            prevValues.trade_type !== currentValues.trade_type ||
            prevValues.freight_type !== currentValues.freight_type ||
            prevValues.booking_type !== currentValues.booking_type
          }
        >
          {({ getFieldValue }) => {
            const routing_legs = getFieldValue('routing_details')?.routing_legs;
            const trade_type = getFieldValue('trade_type');
            const freight_type = getFieldValue('freight_type');
            const booking_type = getFieldValue('booking_type');
            const carriageWiseRoutingLegs = routing_legs
              ? getCarriageWiseRoutingLegs(routing_legs)
              : {};
            const etd =
              carriageWiseRoutingLegs?.[ROUTING_TYPE_MAIN_CARRIAGE]?.[0]
                ?.estimated_time_of_departure;
            const estimated_time_of_departure = etd;

            if (
              trade_type === TRADE_TYPE_EXPORT &&
              freight_type === FREIGHT_TYPE_OCEAN &&
              loadType !== LOAD_TYPE_LCL
            ) {
              return (
                <Form.Item
                  rules={[
                    {
                      required:
                        booking_type === BOOKING_TYPE_SHIPPING_LINE &&
                        freight_type === FREIGHT_TYPE_OCEAN &&
                        trade_type === TRADE_TYPE_EXPORT &&
                        isForwarding,
                    },
                  ]}
                  name="valid_till_date"
                  label="Validity Date"
                >
                  <DatePicker
                    disabledDate={(current: Dayjs) =>
                      cutoffDateDisable['valid_till_date'](current, {
                        estimated_time_of_departure,
                      })
                    }
                    minDate={dayjs()}
                    style={{ width: '100%' }}
                    defaultOpenValue={dayjs()}
                  />
                </Form.Item>
              );
            }
            return <></>;
          }}
        </Form.Item>
      </Col>
      <Form.Item noStyle dependencies={['trade_type', 'freight_type', 'booking_type']}>
        {({ getFieldValue }) => {
          const trade_type = getFieldValue('trade_type');
          const freight_type = getFieldValue('freight_type');
          const booking_type = getFieldValue('booking_type');

          if (freight_type === FREIGHT_TYPE_OCEAN && trade_type === TRADE_TYPE_EXPORT) {
            return (
              <>
                <Col span={colSpan ?? 24 / 4}>
                  <Form.Item
                    rules={[
                      {
                        required:
                          booking_type === BOOKING_TYPE_SHIPPING_LINE &&
                          freight_type === FREIGHT_TYPE_OCEAN &&
                          trade_type === TRADE_TYPE_EXPORT &&
                          isForwarding,
                      },
                    ]}
                    name="empty_pickup_location"
                    label="Empty Pickup Location"
                  >
                    <GlobalSearch
                      doc_type="Global::Location"
                      disabled={disableEmptyPickupLocation}
                      searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          } else return <></>;
        }}
      </Form.Item>

      {showEmptyReturnLocation && (
        <Col span={colSpan ?? 24 / 4}>
          <Form.Item
            required={areCutoffRequired}
            rules={[{ required: areCutoffRequired }]}
            name="empty_return_location"
            label="Empty Return Location"
          >
            <GlobalSearch
              doc_type="Global::Location"
              searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  );
};

export default BookingOrderDetails;
