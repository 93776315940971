import React from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Col, Form, message, Modal } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { BULK_UPDATE_TICKET_FIELD } from 'operations/graphql/bulkActions';

/**
 * Renders a form modal to edit a ticket field for the selected nodes.
 * This function sets up the parameters and references the modal component.
 */
const editTicketFieldRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: {
    field_name: string;
    label: string;
    Komponent: React.ElementType;
    KomponentProps?: any;
  },
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      fieldName: extraProps.field_name,
      label: extraProps.label,
      Komponent: extraProps.Komponent,
      KomponentProps: extraProps.KomponentProps,
    },
    component: EditTicketField,
  };
};

/**
 * Modal component to handle the bulk update of a ticket field.
 * It displays a form where users can modify a specified field for multiple selected tickets.
 * After submission, the mutation `BULK_UPDATE_TICKET_FIELD` is triggered.
 */
const EditTicketField = React.memo(function EditTicketField(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  fieldName: string;
  label: string;
  Komponent: React.ElementType;
  KomponentProps?: any;
}): JSX.Element {
  const { selectedNodes, onClose, onSuccess, fieldName, label, Komponent, KomponentProps } = props;

  // Mutation to update the ticket field in bulk
  const [updateEvent, { loading }] = useMutation(BULK_UPDATE_TICKET_FIELD);

  /**
   * Handles the form submission and triggers the mutation.
   * If the field being updated is `raised_by_company_id`, it ensures that `raised_by_user_id` is reset.
   */
  const onFinish = async (values: any) => {
    const variables: {
      ids?: number[];
      input?: any;
    } = { ids: selectedNodes.map((n) => parseInt(n.data.id)), input: {} };

    // Check if the field value is present, if so, set it in the input variables
    if (values[fieldName]) {
      if (values[fieldName]?.id) {
        // Use the `id` if it exists (e.g., for associations)
        variables.input[fieldName] = values[fieldName].id;
      } else {
        // Otherwise, use the direct value
        variables.input[fieldName] = values[fieldName];
      }
    } else {
      return;
    }

    // If updating `raised_by_company_id`, reset `raised_by_user_id`
    if (fieldName === 'raised_by_company_id') {
      variables.input['raised_by_user_id'] = null;
    }

    // Perform the mutation to update the tickets
    updateEvent({
      variables: variables,
    })
      .then((res) => {
        if (res.errors) {
          message.error(res.errors[0].message);
          return;
        } else if (res?.data?.bulk_update_ticket_field) {
          message.success('Tickets updated successfully');
          onSuccess(); // Callback to indicate success
        }
      })
      .catch(() => {
        message.error('Error occurred while updating tickets');
      });
  };

  const [form] = Form.useForm(); // Form instance for handling submission

  return (
    <Modal
      title={`Update ${label}`}
      open={true}
      okText={'Update'}
      onOk={form.submit}
      onCancel={onClose}
      confirmLoading={loading}
    >
      {/* Form layout with inline fields to update the selected ticket field */}
      <Form layout="inline" form={form} requiredMark={false} onFinish={onFinish}>
        <Col span={24}>
          <Form.Item name={fieldName} label={label} rules={[{ required: true }]}>
            {/* Dynamically rendered component for editing the field (e.g., Select, Input) */}
            <Komponent {...KomponentProps} />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
});

export default editTicketFieldRenderer;
