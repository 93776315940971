import React, { useCallback, useMemo } from 'react';
import { chunk } from 'lodash';
import { Checkbox, Col, Form, GlobalSearch, Row } from '@shipmnts/pixel-hub';
import { FREIGHT_TYPE_ROAD, SERVICES } from 'sales_hub/utils/constants';
import { useInquiryForm } from './InquiryForm';
import {
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_CFS,
  LOCATION_TYPE_ICD,
  LOCATION_TYPE_SEAPORT,
} from 'sales_hub/models/Location';
import { PCD_NODE, PCR_NODE, POD_NODE, POL_NODE } from './helper';

const InquiryServices = ({ values }: { values?: string[] }) => {
  const { freightType, form } = useInquiryForm();

  // NOTE: filter out transport services in pure transport inquiry
  // as it is automatically part of it
  const services = useMemo(
    () =>
      SERVICES.filter(
        (service) =>
          freightType !== FREIGHT_TYPE_ROAD ||
          !['origin_transport', 'destination_transport'].includes(service.value)
      ),
    [freightType]
  );

  // callback to change routing nodes, based on new value of custom location
  const onChangeofCustomsLocation = useCallback(
    (val: any, type?: 'origin' | 'destination') => {
      const isPortSelected = [LOCATION_TYPE_AIRPORT, LOCATION_TYPE_SEAPORT].includes(val?.type);

      // if port is selected, we set POL / POD
      if (isPortSelected) {
        form.setFieldValue(type === 'origin' ? POL_NODE : POD_NODE, val);
      }

      // if port is selected, we set PCR / PCD
      if ([LOCATION_TYPE_ICD, LOCATION_TYPE_CFS].includes(val?.type)) {
        form.setFieldValue(type === 'origin' ? PCR_NODE : PCD_NODE, {
          type: 'CFS',
          location: val,
        });
      }
    },
    [form]
  );

  return (
    <div style={{ marginBottom: '1.5em' }}>
      <h3 className="inq-form-section-title">{'Services'}</h3>
      {chunk(services, 4).map((serviceChunk, index) => {
        return (
          <Row gutter={{ lg: 16 }} key={index}>
            {serviceChunk.map((service, index) => (
              <React.Fragment key={index}>
                <Col xs={24} sm={12} lg={6} key={index}>
                  <Form.Item
                    key={service.value}
                    name={['services', service.value]}
                    noStyle
                    valuePropName="checked"
                    initialValue={values?.includes(service.value)}
                  >
                    <Checkbox name={service.value}>{service.label}</Checkbox>
                  </Form.Item>
                  {service.value === 'origin_custom_clearance' && (
                    <Form.Item dependencies={[['services', 'origin_custom_clearance']]} noStyle>
                      {({ getFieldValue }) => {
                        const origin_custom_clearance = getFieldValue([
                          'services',
                          'origin_custom_clearance',
                        ]);
                        return (
                          <Form.Item
                            name="originCustomClearance"
                            style={{ marginBlock: '.5em' }}
                            hidden={!origin_custom_clearance}
                          >
                            <GlobalSearch
                              doc_type="Global::Location"
                              extraProps={{ placeholder: 'Origin Custom Clearance' }}
                              searchProps={{ is_customs_location: true }}
                              onChange={(val) => onChangeofCustomsLocation(val, 'origin')}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  )}
                  {service.value === 'destination_custom_clearance' && (
                    <Form.Item
                      dependencies={[['services', 'destination_custom_clearance']]}
                      noStyle
                    >
                      {({ getFieldValue }) => {
                        const destination_custom_clearance = getFieldValue([
                          'services',
                          'destination_custom_clearance',
                        ]);
                        return (
                          <Form.Item
                            name="destinationCustomClearance"
                            style={{ marginBlock: '.5em' }}
                            hidden={!destination_custom_clearance}
                          >
                            <GlobalSearch
                              doc_type="Global::Location"
                              extraProps={{ placeholder: 'Destination Custom Clearance' }}
                              searchProps={{ is_customs_location: true }}
                              onChange={(val) => onChangeofCustomsLocation(val, 'destination')}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  )}
                </Col>
              </React.Fragment>
            ))}
          </Row>
        );
      })}
    </div>
  );
};

export default InquiryServices;
