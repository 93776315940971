import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'wouter';
import { FETCH_CONTRACT } from '../graphql/query';
import BaseContract from './BaseContract';
import { Skeleton } from '@shipmnts/pixel-hub';

const CreateContract = () => {
  const { id } = useParams<{ id: string }>();

  // queries
  const {
    data: contractData,
    loading,
    refetch,
  } = useQuery(FETCH_CONTRACT, {
    variables: { id },
    skip: id === 'new',
  });

  return (
    <Skeleton active loading={loading}>
      <BaseContract contract={contractData?.get_contract} refetchContract={refetch} />
    </Skeleton>
  );
};

export default CreateContract;
