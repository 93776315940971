import React from 'react';
import { Form, Input, Row, Col, Checkbox, Select } from '@shipmnts/pixel-hub';

import india_states from 'network/regional/IN/states';
import { FormInstance } from '@shipmnts/pixel-hub';
import { LocationValue } from 'network/models/Location';
import { useSession } from 'common';
import {
  INVOICE_TYPE_SEZ,
  INVOICE_TYPE_REGULAR,
  INVOICE_TYPE_EXPORT_IMPORT,
  INVOICE_TYPE_UNREGISTERED,
} from 'network/regional/IN/constants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

export interface AddressFormProps {
  country_code?: string;
  fieldName?: string | number;
  is_customer_or_supplier?: boolean;
  form: FormInstance;
  address_id?: string;
  is_lead?: boolean;
  renderedFromCompanySearch?: boolean;
}

export interface AddressFormNullableValues {
  city_id?: string | null;
  state_name?: string | null;
  gstin?: string | null;
}
export interface AddressFormValues extends AddressFormNullableValues {
  id?: string;
  name?: string;
  is_billing?: boolean;
  address_line_1?: string;
  address_line_2?: string;
  city?: LocationValue;
  postal_code?: string;
  print_address?: string;
  preferred_invoice_type?: string;
  entity_type?: string;
  tan_number?: string;
  attn?: string;
}

const ROW_GUTTER = 32;

const updatePrintAddress = (
  form: FormInstance,
  fieldName?: string | number,
  updateState?: boolean
) => {
  const al1 = form.getFieldValue(fieldName ? [fieldName, 'address_line_1'] : 'address_line_1');
  const updatePrintAddress = al1 && al1 !== '';
  if (!updatePrintAddress && !updateState) return;

  const al2 = form.getFieldValue(fieldName ? [fieldName, 'address_line_2'] : 'address_line_2');
  const city = form.getFieldValue(fieldName ? [fieldName, 'city'] : 'city');
  const attn = form.getFieldValue(fieldName ? [fieldName, 'attn'] : 'attn');
  let print_address = `${al1}`;
  if (al2) print_address = print_address + `\n${al2}`;
  if (city) print_address = print_address + `\n${city.name}`;
  const formValues: { print_address?: string; state_name?: string } = {};
  if (attn) print_address = print_address + `\n${attn}`;
  if (updatePrintAddress) {
    formValues.print_address = print_address;
  }
  if (updateState && city?.state) {
    formValues.state_name = city?.state;
  }
  if (fieldName) {
    // Have to do this since form.setFieldsValues({[`${fieldName}.print_address`]: print_address}) is not working
    form.setFieldsValue({
      [fieldName]: { ...form.getFieldValue(fieldName), ...formValues },
    });
  } else {
    form.setFieldsValue({ ...formValues });
  }
};

const AddressFormContent = React.memo(function AddressForm(props: AddressFormProps) {
  const {
    country_code,
    fieldName,
    is_customer_or_supplier,
    form,
    address_id,
    is_lead = !is_customer_or_supplier,
    renderedFromCompanySearch,
  } = props;
  const is_new = !address_id;

  const is_billing_field_name = fieldName ? [fieldName, 'is_billing'] : 'is_billing';
  const address_line_1_field_name = fieldName ? [fieldName, 'address_line_1'] : 'address_line_1';
  const address_line_2_field_name = fieldName ? [fieldName, 'address_line_2'] : 'address_line_2';
  const city_field_name = fieldName ? [fieldName, 'city'] : 'city';
  const session = useSession();
  const tenant_country_code = session.company_account?.default_company?.country_of_incorporation;

  const setFormValues = (formValues: Record<string, any>) => {
    if (fieldName)
      form.setFieldsValue({
        [fieldName]: { ...form.getFieldValue(fieldName), ...formValues },
      });
    else form.setFieldsValue({ ...formValues });
  };

  const resetGstin = () => {
    if (
      ![INVOICE_TYPE_SEZ, INVOICE_TYPE_REGULAR].includes(
        form.getFieldValue(
          fieldName ? [fieldName, 'preferred_invoice_type'] : 'preferred_invoice_type'
        )
      )
    ) {
      setFormValues({ gstin: null });
    }
  };

  if (is_customer_or_supplier && is_new) setFormValues({ is_billing: true });
  if (!is_customer_or_supplier && is_new) setFormValues({ is_billing: false });
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name={fieldName ? [fieldName, 'name'] : 'name'} label="Address title">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item valuePropName="checked" name={is_billing_field_name} label="">
            <Checkbox
              disabled={
                renderedFromCompanySearch ||
                (!is_new && form.getFieldValue(is_billing_field_name)) ||
                // in case company is being created as a lead or contact,
                // address can't be of type billing
                is_lead ||
                !is_customer_or_supplier
              }
            >
              Is Billing Address?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item noStyle dependencies={[is_billing_field_name]}>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name={address_line_1_field_name}
                  label="Address line 1"
                  required={getFieldValue(is_billing_field_name)}
                  rules={getFieldValue(is_billing_field_name) ? [{ required: true }] : []}
                >
                  <Input onBlur={() => updatePrintAddress(form, fieldName)} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name={address_line_2_field_name} label="Address line 2">
            <Input onBlur={() => updatePrintAddress(form, fieldName)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item noStyle dependencies={[is_billing_field_name]}>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name={city_field_name}
                  label="City"
                  required={getFieldValue(is_billing_field_name)}
                  rules={getFieldValue(is_billing_field_name) ? [{ required: true }] : []}
                >
                  <GlobalSearch
                    doc_type="Global::Location"
                    onBlur={() => {
                      updatePrintAddress(form, fieldName, true);
                    }}
                    //
                    searchProps={{
                      type: ['City'],
                      country_code: country_code ? [country_code] : undefined,
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item noStyle dependencies={[is_billing_field_name]}>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name={fieldName ? [fieldName, 'state_name'] : 'state_name'}
                  label="State"
                  required={country_code === 'IN' && getFieldValue(is_billing_field_name)}
                  rules={
                    country_code === 'IN' && getFieldValue(is_billing_field_name)
                      ? [{ required: true }]
                      : []
                  }
                >
                  {country_code === 'IN' ? (
                    <Select
                      filterOption={(input, option): boolean => {
                        return (
                          `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      showSearch
                      allowClear
                    >
                      {india_states.map((state) => (
                        <Select.Option key={state.state_name} value={state.state_name}>
                          {state.state_name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            name={fieldName ? [fieldName, 'postal_code'] : 'postal_code'}
            label="Postal Code"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item noStyle dependencies={[is_billing_field_name]}>
            {({ getFieldValue }) => {
              if (tenant_country_code === 'IN' && getFieldValue(is_billing_field_name)) {
                return (
                  <Form.Item
                    name={fieldName ? [fieldName, 'tan_number'] : 'tan_number'}
                    label="Tax Deduction ID (TAN)"
                  >
                    <Input />
                  </Form.Item>
                );
              }
              return null;
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={24} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Form.Item name={fieldName ? [fieldName, 'attn'] : 'attn'} label="Attn.">
            <Input onChange={() => updatePrintAddress(form, fieldName)} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col span={24} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Form.Item
            name={fieldName ? [fieldName, 'print_address'] : 'print_address'}
            label="Print Address"
            required={!is_lead || is_customer_or_supplier}
            rules={[{ required: !is_lead || is_customer_or_supplier }]}
            dependencies={[address_line_1_field_name, address_line_2_field_name, city_field_name]}
          >
            <Input.TextArea style={{ width: '100%' }} rows={3} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item noStyle dependencies={[is_billing_field_name]}>
            {({ getFieldValue }) => {
              if (tenant_country_code === 'IN' && getFieldValue(is_billing_field_name)) {
                return (
                  <Form.Item
                    name={
                      fieldName ? [fieldName, 'preferred_invoice_type'] : 'preferred_invoice_type'
                    }
                    required
                    rules={[{ required: true }]}
                    label="Preferred Invoice Type"
                  >
                    {/* TODO: defaultActiveFirstOption is not working */}
                    <Select defaultActiveFirstOption onChange={resetGstin}>
                      {[
                        INVOICE_TYPE_REGULAR,
                        INVOICE_TYPE_SEZ,
                        INVOICE_TYPE_EXPORT_IMPORT,
                        INVOICE_TYPE_UNREGISTERED,
                      ].map((invoice_type) => (
                        <Select.Option key={invoice_type} value={invoice_type}>
                          {invoice_type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }
              return null;
            }}
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            noStyle
            dependencies={[
              is_billing_field_name,
              fieldName ? [fieldName, 'preferred_invoice_type'] : 'preferred_invoice_type',
            ]}
          >
            {({ getFieldValue }) => {
              if (
                tenant_country_code === 'IN' &&
                country_code === 'IN' &&
                getFieldValue(is_billing_field_name)
              )
                return (
                  <Form.Item
                    required={[INVOICE_TYPE_SEZ, INVOICE_TYPE_REGULAR].includes(
                      getFieldValue(
                        fieldName ? [fieldName, 'preferred_invoice_type'] : 'preferred_invoice_type'
                      )
                    )}
                    rules={[
                      {
                        required: [INVOICE_TYPE_SEZ, INVOICE_TYPE_REGULAR].includes(
                          getFieldValue(
                            fieldName
                              ? [fieldName, 'preferred_invoice_type']
                              : 'preferred_invoice_type'
                          )
                        ),
                      },
                    ]}
                    hidden={
                      ![INVOICE_TYPE_SEZ, INVOICE_TYPE_REGULAR].includes(
                        getFieldValue(
                          fieldName
                            ? [fieldName, 'preferred_invoice_type']
                            : 'preferred_invoice_type'
                        )
                      )
                    }
                    name={fieldName ? [fieldName, 'gstin'] : 'gstin'}
                    label="GSTIN"
                  >
                    <Input />
                  </Form.Item>
                );
              return null;
            }}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});

export default AddressFormContent;
