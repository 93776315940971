import { gql } from '@apollo/client';

export const userContactFields = gql`
  fragment userContactFields on UserContactObjectType {
    id
    first_name
    last_name
    email
    title
    department
    mobile_number
    is_disabled
    is_internal
    dial_code
    created_at
    updated_at
    pretty_display
    name
  }
`;

export const salesPersonFields = gql`
  fragment salesPersonFields on SalesPersonObjectType {
    id
    name
    employee
    enable
    user_contact {
      ...userContactFields
    }
  }
  ${userContactFields}
`;

export const GET_SALES_PERSON_FOR_CUSTOMER = gql`
  query get_sales_person_for_customer($customer_company_id: ID!, $branch_id: ID) {
    get_sales_person_for_customer(
      customer_company_id: $customer_company_id
      branch_id: $branch_id
    ) {
      ...salesPersonFields
    }
  }
  ${salesPersonFields}
`;
