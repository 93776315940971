import { getFirstMainHopIndex } from 'operations/models/RoutingLeg';
import { containerSettingsMap } from 'operations/baseConstants';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails/RoutingDetails';

export const checkVoyageResetOnValuesChange = (props: any) => {
  const { changedValues, allValues, previousValue } = props;
  let currentValue = { ...previousValue };
  let shouldReset = false;
  if (changedValues.routing_details) {
    const allRoutingLegs = allValues.routing_details?.routing_legs;
    const firstMainHopIndex = getFirstMainHopIndex(allRoutingLegs);
    if (firstMainHopIndex >= 0) {
      const routingLeg = allRoutingLegs?.[firstMainHopIndex];
      const location_id =
        changedValues.routing_details?.routing_nodes?.[
          routingLeg?.origin_id
        ]?.location?.id?.toString();
      const previosLocationId = previousValue?.mainCarriage?.locationId;
      const previousVessel = previousValue?.mainCarriage?.vessel;
      if (location_id !== previosLocationId && (previosLocationId || location_id)) {
        currentValue = {
          ...currentValue,
          mainCarriage: { ...currentValue?.mainCarriage, locationId: location_id },
        };
        if (allValues?.voyage_schedule_id) shouldReset = true;
      }
      if (routingLeg.vessel?.imo !== previousVessel && (routingLeg.vessel?.imo || previousVessel)) {
        currentValue = {
          ...currentValue,
          mainCarriage: { ...currentValue?.mainCarriage, vessel: routingLeg.vessel?.imo },
        };
        if (allValues?.voyage_schedule_id) shouldReset = true;
      }
    }
  }
  if (changedValues.container_requests) {
    const isChangedContainerTypeReefer = Object.keys(containerSettingsMap.reefer).includes(
      allValues.container_requests?.[0]?.container_type_code || ''
    );
    if (isChangedContainerTypeReefer !== previousValue?.isFirstContainerTypeReefer) {
      currentValue = {
        ...currentValue,
        isFirstContainerTypeReefer: isChangedContainerTypeReefer,
      };
      if (allValues?.voyage_schedule_id) shouldReset = true;
    }
  }
  if (changedValues.carrier && changedValues.carrier?.id !== previousValue?.carrierId) {
    currentValue = {
      ...currentValue,
      carrierId: changedValues.carrier?.id,
    };
    if (allValues?.voyage_schedule_id) shouldReset = true;
  }
  return { currentValue, shouldReset };
};

export const getRoutingDetailsOnVoyageReset = (
  routing_details?: RoutingDetailsValue,
  cb?: (routing_details: RoutingDetailsValue, voyage_schedule_id: undefined) => void
) => {
  const new_routing_legs = routing_details?.routing_legs || [];
  const routing_nodes = routing_details?.routing_nodes || {};
  const routeOrder = getFirstMainHopIndex(new_routing_legs);
  if (routeOrder >= 0) {
    new_routing_legs[routeOrder] = {
      ...new_routing_legs[routeOrder],
      voyage_number: undefined,
    };
    if (cb) cb({ routing_legs: [...new_routing_legs], routing_nodes }, undefined);
  }
};

export const getDefaultPreviousValue = (initial_ocean_transport_order: { [key: string]: any }) => {
  const isFirstContainerTypeReefer = Object.keys(containerSettingsMap.reefer).includes(
    initial_ocean_transport_order?.container_requests?.[0]?.container_type_code || ''
  );
  let mainCarriage: {
    locationId?: string;
    vessel?: string;
  } = {};
  const allRoutingLegs = initial_ocean_transport_order?.routing_details?.routing_legs;
  const routeOrder = getFirstMainHopIndex(allRoutingLegs);
  if (routeOrder >= 0) {
    const routingLeg = allRoutingLegs?.[routeOrder];
    const locationId =
      initial_ocean_transport_order.routing_details?.routing_nodes?.[
        routingLeg?.origin_id
      ]?.location?.id?.toString();
    mainCarriage = { locationId, vessel: routingLeg.vessel?.imo };
  }
  return {
    isFirstContainerTypeReefer,
    mainCarriage,
    carrier_id: initial_ocean_transport_order?.carrier?.id,
  };
};
