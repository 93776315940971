import React, { useEffect } from 'react';
import { message, Button } from '@shipmnts/pixel-hub';
import { CompanyValue } from 'network/models/Company';
import { UPSERT_COMPANY } from '../graphql/company';
import { useMutation } from '@apollo/client';
import {
  PERMISSION_DISABLE_COMPANY,
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { WithPermission } from '@shipmnts/pixel-hub';

const DisableCompany = React.memo(function RenameCompanyModal(props: {
  company?: CompanyValue;
  flag: boolean;
  setCompany: (company: CompanyValue) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
}): JSX.Element {
  const { company, flag, setCompany, icon, disabled } = props;

  const [upsertCompany, { data: upsertCompanyData, error: upsertCompanyError, loading }] =
    useMutation(UPSERT_COMPANY);

  useEffect(() => {
    if (upsertCompanyError) {
      message.error({
        content: upsertCompanyError?.graphQLErrors.map((e) => e.message),
        key: 'upsert_company',
      });
    }
    if (!upsertCompanyError && upsertCompanyData?.upsert_company) {
      message.success({
        content: `Company ${!flag ? 'Disabled' : 'Enabled'}`,
        key: 'upsert_company',
        duration: 2,
      });
      setCompany(upsertCompanyData?.upsert_company?.company);
    }
  }, [flag, upsertCompanyData, upsertCompanyError, setCompany]);

  const setCompanyDisable = () => {
    const payload = {
      id: company?.id,
      is_disabled: flag,
    };
    upsertCompany({ variables: { company: payload } });
  };

  return (
    <WithPermission
      key={flag ? 'disable' : 'enable'}
      permission={{
        AND: [
          {
            OR: [
              { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
              { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
            ],
          },
          { name: PERMISSION_DISABLE_COMPANY, docType: 'Network::Company' },
        ],
      }}
    >
      <Button
        loading={loading}
        icon={icon}
        danger={flag}
        disabled={disabled || company?.company_group === 'Self'}
        onClick={setCompanyDisable}
        size="small"
      >
        {flag ? 'Disable' : 'Enable'}
      </Button>
    </WithPermission>
  );
});

export default DisableCompany;
