import { routingLegFields } from 'operations/modules/booking/graphql';
import { gql } from '@apollo/client';

export const shipmentRouteFields = gql`
  fragment shipmentRouteFields on RouteObjectType {
    id
    name
    load_type
    created_at
    movement_mode
    trade_type
    routing_legs {
      ...routingLegFields
    }
    is_disabled
  }
  ${routingLegFields}
`;

export const FETCH_SHIPMENT_ROUTE = gql`
  query get_shipment_route($id: ID!) {
    get_shipment_route(id: $id) {
      ...shipmentRouteFields
    }
  }
  ${shipmentRouteFields}
`;

export const UPSERT_SHIPMENT_ROUTE = gql`
  mutation upsertShipmentRoute($route: RouteInputType!) {
    upsert_shipment_route(route: $route) {
      ...shipmentRouteFields
    }
  }
  ${shipmentRouteFields}
`;
