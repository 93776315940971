import React from 'react';

import { UploadedDocuments } from '@shipmnts/pixel-hub';
import { DetailsCard, useSession } from 'common';
import { useContactView } from '../ContactView';

export const DocumentsTab = () => {
  const { contact, loading } = useContactView();
  const session = useSession();

  return (
    <>
      <div
        style={{
          width: '70%',
          marginInline: 'auto',
          marginBlock: '1em 2em',
        }}
      >
        <DetailsCard
          loading={loading}
          header={{ title: 'Documents' }}
          style={{ marginInline: 'auto', width: '100%', marginTop: '1em' }}
          Action={<></>}
        >
          {contact ? (
            <UploadedDocuments
              sessionData={session}
              parent_id={contact.id}
              parent_type={'user_contact'}
              docgen_url={process.env.DOCGEN_URL || ''}
            />
          ) : (
            <></>
          )}
        </DetailsCard>
      </div>
    </>
  );
};
