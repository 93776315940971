import {
  AddressCompanySearch,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  DatePicker,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import {
  CARRIER_TYPE_AIR,
  CARRIER_TYPE_COLOADER,
  CARRIER_TYPE_NVOCC,
  CARRIER_TYPE_OCEAN,
  CarrierType,
} from 'operations/models/Carrier';
import { ShipmentValue } from 'operations/models/Shipment';
import {
  BL_TYPE_ARRAY,
  ROW_GUTTER,
  SHIPMENT_TYPE_BACK_TO_BACK,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  FREIGHT_TYPE_OCEAN,
} from 'operations/modules/shipment/constants';
import React from 'react';
import { LABEL_JSON } from '../../helpers/labelKeyHelper';
import ShipmentNumber from '../ShipmentNumber';
import { LOAD_TYPE_FCL, LOAD_TYPE_LCL } from 'operations/baseConstants';
import { RadioOptionProps } from 'operations/commonTypeDefs';

interface BasicDetailsProps {
  shipment?: ShipmentValue;
  freightType: string;
  loadType: string;
  tradeType: string;
  shipmentType: string;
  disableHash?: any;
  form: FormInstance;
  hide?: boolean;
}

export default function ShipmentDetails(props: BasicDetailsProps) {
  const { form, shipmentType } = props;
  const freightType = Form.useWatch('freight_type', form);
  const carrier = Form.useWatch('carrier', form);
  const tradeType = Form.useWatch('trade_type', form);
  const loadType = Form.useWatch('load_type', form);
  const carrierSearchProp: {
    carrier_type?: Array<CarrierType>;
  } = {
    carrier_type:
      freightType === FREIGHT_TYPE_OCEAN
        ? [CARRIER_TYPE_OCEAN, CARRIER_TYPE_NVOCC, CARRIER_TYPE_COLOADER]
        : [CARRIER_TYPE_AIR],
  };
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={8}>
          <Form.Item name="carrier" label={loadType === LOAD_TYPE_LCL ? 'Co-loader' : 'Carrier'}>
            <GlobalSearch doc_type="Global::Carrier" searchProps={carrierSearchProp} />
          </Form.Item>
        </Col>
        {freightType === FREIGHT_TYPE_OCEAN && (
          <>
            <Col span={8}>
              <Form.Item name={'booking_number'} label="Booking #">
                <Input placeholder="Enter Carrier Booking #" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'booking_date'} label="Booking Date">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="MBL Type" name={['master', 'shipment_document', 'bl_type']}>
                <Radio.Group>
                  {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                    <Radio key={index} value={option.key}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={8}>
          <ShipmentNumber
            form={form}
            carrier={carrier}
            label={LABEL_JSON.shipment_number['master'][freightType]}
            documentType={'master'}
            freightType={freightType}
          />
        </Col>
        <Col span={8}>
          <Form.Item
            name={['master', 'shipment_document', 'shipment_date']}
            label={LABEL_JSON.document_date['master'][freightType]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        {shipmentType === SHIPMENT_TYPE_BACK_TO_BACK && (
          <>
            {freightType === FREIGHT_TYPE_OCEAN && (
              <Col span={8}>
                <Form.Item label="HBL Type" name={['house', 'shipment_document', 'bl_type']}>
                  <Radio.Group>
                    {BL_TYPE_ARRAY.map((option: RadioOptionProps, index: number) => (
                      <Radio key={index} value={option.key}>
                        {option.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <ShipmentNumber
                form={form}
                carrier={carrier}
                label={LABEL_JSON.shipment_number['house'][freightType]}
                documentType={'house'}
                freightType={freightType}
              />
            </Col>
            <Col span={8}>
              <Form.Item
                name={['house', 'shipment_document', 'shipment_date']}
                label={LABEL_JSON.document_date['house'][freightType]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={8}>
          <Form.Item name={['party', 'origin_agent']} label="Forwarder">
            <AddressCompanySearch
              addressSearchProps={{
                showAddBranchAction: true,
              }}
            />
          </Form.Item>
        </Col>
        {tradeType === TRADE_TYPE_EXPORT && freightType === FREIGHT_TYPE_OCEAN && (
          <>
            {loadType === LOAD_TYPE_FCL && (
              <>
                <Col span={8}>
                  <Form.Item name={'form13_cutoff_date'} label="Form 13 Cutoff Date">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'vgm_cutoff_date'} label="VGM Cutoff Date">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'gate_close_date'} label="Gate Close Cutoff Date">
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={8}>
              <Form.Item name={'doc_cutoff_date'} label="Doc Cutoff">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </>
        )}
        {tradeType === TRADE_TYPE_IMPORT &&
          freightType === FREIGHT_TYPE_OCEAN &&
          loadType === LOAD_TYPE_FCL && (
            <>
              <Col span={8}>
                <Form.Item name="lfd_at_pod" label="POD Last Free Date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="lfd_at_empty_return" label="Empty Return Last Free Date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="lfd_at_cfs" label="CFS Last Free Date">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </>
          )}
      </Row>
    </>
  );
}
