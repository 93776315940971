import React, { useState } from 'react';
import EditInquiryDrawer from './EditInquiryDrawer';
import { InquiryValue } from 'sales_hub/models/inquiry';
import InquiryOptionDrawer from '../InquiryOption/InquiryOptionDrawer';
import { omit as _omit } from 'lodash';
import { Modal, WarningOutlined } from '@shipmnts/pixel-hub';
import { dayjs } from '@shipmnts/pixel-hub';

function AmendInquiry(props: any) {
  const [inquiryOption, setInquiryOption] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  return (
    <>
      <Modal
        title={
          <div>
            <span style={{ marginRight: '8px' }}>
              <WarningOutlined style={{ color: 'orange' }} />
            </span>
            Amend Quote
          </div>
        }
        open={modalOpen}
        onCancel={() => {
          props.onSuccess();
        }}
        okText={`Yes`}
        onOk={() => {
          setOpen(true);
        }}
      >
        Changes done in inquiry may impact the quote. Would you like to amend the quote as well ?
      </Modal>
      {open && !!inquiryOption && (
        <InquiryOptionDrawer
          disabledResource={false}
          performDuplicate={true}
          inquiryOption={inquiryOption}
          inquiry={inquiryOption.inquiry}
          inquiryOptionId={inquiryOption?.id}
          isVisible={open}
          setIsVisible={setOpen}
          onClose={props.onSuccess}
          title={'Amend Quote'}
          onSuccess={props.onSuccess}
        />
      )}
      <EditInquiryDrawer
        {...props}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onSuccess={() => {
          //Without This it Make the action component undefined which doesnt open inquiryoptiondrawer
        }}
        onClose={props.onSuccess}
        onUpdate={(inquiry: InquiryValue) => {
          const wonInquiryOption = (inquiry?.inquiry_options || [])
            .filter((e) => e.status === 'won')
            .sort((a, b) => ((a.updated_at ?? 0) < (b.updated_at ?? 0) ? 1 : -1));
          const latestInquiryOption = wonInquiryOption.length > 0 ? wonInquiryOption[0] : null;
          if (!!latestInquiryOption) {
            setInquiryOption({
              ..._omit(latestInquiryOption, [
                'place_of_carrier_delivery',
                'place_of_carrier_receipt',
                'destination_port_free_days',
                'origin_carrier_free_days',
                'destination_carrier_free_days',
                'chargeable_weight',
              ]),
              valid_to: latestInquiryOption?.valid_to
                ? dayjs.unix(latestInquiryOption?.valid_to)
                : null,
              valid_from: latestInquiryOption?.valid_from
                ? dayjs.unix(latestInquiryOption?.valid_from)
                : null,
              estimated_time_of_arrival: latestInquiryOption?.estimated_time_of_arrival
                ? dayjs.unix(latestInquiryOption?.estimated_time_of_arrival)
                : null,
              estimated_time_of_departure: latestInquiryOption?.estimated_time_of_departure
                ? dayjs.unix(latestInquiryOption?.estimated_time_of_departure)
                : null,
            });
            setModalOpen(true);
          } else {
            props.onSuccess && props.onSuccess();
            props.onClose && props.onClose();
          }
        }}
        title={'Amend Inquiry'}
        updateButtonTitle={'Amend & Next'}
        isAmend={true}
      />
    </>
  );
}

export default AmendInquiry;
