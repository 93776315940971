import { CustomIcon, Tag } from '@shipmnts/pixel-hub';
import React, { useState } from 'react';
import WarehouseTransactionTimeline from './WarehouseTransactionTimeline';
import {
  STATUS_PENDING,
  STATUS_RECEIVED,
  STATUS_ISSUED,
  STATUS_PARTIALLY_DELIVERED,
  STATUS_PARTIALLY_RECEIVED,
  STATUS_PARTIALLY_ISSUED,
  STATUS_FULLY_RECEIVED,
  STATUS_FULLY_DELIVERED,
  STATUS_FULLY_ISSUED,
  STATUS_DELIVERED,
  CANCELLED,
  STATUS_SUBMITTED,
} from 'operations/models/WarehouseTransaction';
import { upperCase as _upperCase } from 'lodash';

const WarehouseTransactionTimlinedTag = (props: any) => {
  const { transaction } = props;
  const [open, setOpen] = useState<boolean>(false);
  const getTagsColor = (status: string | undefined) => {
    if (!status) return 'blue';
    if ([STATUS_PENDING, STATUS_RECEIVED, STATUS_ISSUED].includes(status)) return 'purple';
    else if (
      [STATUS_PARTIALLY_DELIVERED, STATUS_PARTIALLY_RECEIVED, STATUS_PARTIALLY_ISSUED].includes(
        status
      )
    )
      return 'gold';
    else if (
      [
        STATUS_FULLY_RECEIVED,
        STATUS_FULLY_DELIVERED,
        STATUS_FULLY_ISSUED,
        STATUS_DELIVERED,
        STATUS_SUBMITTED,
      ].includes(status)
    )
      return 'green';
    else if ([CANCELLED].includes(status)) return 'red';
    return 'blue';
  };
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ fontSize: '12px', color: '#cc933e' }}>
          <Tag color={getTagsColor(transaction?.status)}>
            {_upperCase(transaction?.status || '')}
          </Tag>
        </div>{' '}
        <span style={{ marginLeft: '2px' }}>
          <CustomIcon icon="TrackerIcon" onClick={() => setOpen(!open)} />
        </span>
      </div>

      {open && (
        <WarehouseTransactionTimeline
          transaction_id={transaction.id}
          onClose={() => setOpen(!open)}
        />
      )}
    </div>
  );
};

export default WarehouseTransactionTimlinedTag;
