import React, { useEffect } from 'react';

import './style.css';
import { useMutation } from '@apollo/client';
import { Button, Drawer, Form, message, Space } from '@shipmnts/pixel-hub';
import { CustomIcon } from '@shipmnts/pixel-hub';

import { createInquiryParamsFromForm } from 'sales_hub/components/Inquiry/common';
import InquiryForm from 'sales_hub/components/Inquiry/InquiryForm/InquiryForm';
import { UPDATE_INQUIRY } from 'sales_hub/graphql/inquiry';
import { InquiryValue } from 'sales_hub/models/inquiry';
import { isSalesPersonPresentInTeam } from 'common';

type Props = {
  onSuccess?: () => void;
  inquiry?: InquiryValue;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onUpdate?: (inquiry: InquiryValue) => void;
  onClose?: () => void;
  title?: string;
  updateButtonTitle?: string;
  isAmend?: boolean;
};

const EditInquiryDrawer = (props: Props) => {
  // Context
  const [form] = Form.useForm();

  // Queries/Mutations
  const [updateInquiry, { data: updateInquiryData, error: updateInquiryError, loading }] =
    useMutation(UPDATE_INQUIRY);

  // Effects
  // On form Sucesss
  useEffect(() => {
    if (updateInquiryData?.update_inquiry) {
      message.success(`Updated RFQ #${props?.inquiry?.inquiry_number}`);
      form.resetFields();
      props.setIsVisible(false);
      if (props.onSuccess) props.onSuccess();
      if (props.onUpdate) props.onUpdate(updateInquiryData?.update_inquiry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInquiryData]);
  // On error
  useEffect(() => {
    if (updateInquiryError) message.error('Error Occured');
  }, [updateInquiryError]);

  // Constants and Methods

  const { inquiry } = props;
  const onFinish = (values: any) => {
    const inquiryPayload = createInquiryParamsFromForm(values, inquiry);
    if (!isSalesPersonPresentInTeam(inquiryPayload.teams)) {
      message.error('Please enter sales person details in team');
      return;
    }
    updateInquiry({
      variables: {
        id: inquiry?.id,
        inquiry: inquiryPayload,
      },
    });
  };

  const onClose = () => {
    if (props.setIsVisible) props.setIsVisible(false);
    if (props.onSuccess) props.onSuccess();
    if (props.onClose) props.onClose();
    form.resetFields();
  };

  return (
    <Drawer
      title={
        <div style={{ display: 'flex' }}>
          <CustomIcon icon="CompassIcon" height={24} width={24} style={{ paddingRight: '5px' }} />
          {`${props.title || 'Edit Inquiry'} ${props.inquiry?.inquiry_number}`}
        </div>
      }
      width={'75%'}
      onClose={onClose}
      open={props.isVisible}
      styles={{ body: { paddingBottom: 80 } }}
      footer={
        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={form.submit}
            loading={loading}
            disabled={loading}
          >
            {props.updateButtonTitle || 'Edit Inquiry'}
          </Button>
        </Space>
      }
      destroyOnClose={true}
    >
      <InquiryForm
        isAmend={props?.isAmend}
        form={form}
        inquiry={props.inquiry}
        onFinish={onFinish}
        noTabs
      />
    </Drawer>
  );
};

export default EditInquiryDrawer;
