import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { convertToDayJs, Timeline } from '@shipmnts/pixel-hub';

import { uniqueId as _uniqueId } from 'lodash';
import { RoutingNodeValue, RoutingNodeTag } from 'operations/models/RoutingNode';

import { LocationType, LocationValue } from 'operations/models/Location';
import {
  RoutingLegRoutingType,
  RoutingLegValue,
  MODE_OF_TRANSIT_RAIL,
} from 'operations/models/RoutingLeg';
import _mapValues from 'lodash/mapValues';
import { BOOKING_TYPE_SELF } from 'operations/baseConstants';
import { VesselValue } from 'operations/models/Vessel';
import { FreightType } from 'operations/modules/shipment/constants';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { RoutingLegCallback, RoutingNodeCallback, getInitialNodes } from './helpers';
import RoutingLegRender from './RoutingLegRender';

// ToDo import this from common/model/Routing node
export interface RoutingNodesHashValue {
  [id: string]: RoutingNodeValue;
}

export interface RoutingDetailsValue {
  routing_legs: Array<RoutingLegValue>;
  routing_nodes: RoutingNodesHashValue;
}

export interface RoutingDetailsProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  locationSearchType?: Array<LocationType>;
  showTerminal?: boolean;
  disableNodes?: number[];
  disableVoyageUpdate?: boolean;
  disableRoutingLegs?: number[];
  disableAddRemoveTranshipmentHop?: boolean;
  routing_type?: RoutingLegRoutingType;
  startSequence: number;
  endSequence: number;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  isReeferContainer?: boolean;
  bookingType?: string | null;
  allowVoyageScheduleSearch?: boolean;
  freightType?: FreightType;
  showWaitingTime?: boolean;
  enableDragAndDrop?: boolean;
  showDpd?: boolean;
}

export const getNewRoutingNode = (params?: Partial<RoutingNodeValue>): RoutingNodeValue => {
  return { _id: _uniqueId('rn_'), ...(params || {}) } as RoutingNodeValue;
};

export const createRoutingValues = (params: {
  place_of_carrier_receipt?: LocationValue | null;
  port_of_loading?: LocationValue | null;
  port_of_discharge?: LocationValue | null;
  place_of_carrier_delivery?: LocationValue | null;
  main_carriage_vessel?: VesselValue | null;
  main_carriage_voyage_number?: string | null;
  estimated_time_of_departure?: number | null;
  estimated_time_of_arrival?: number | null;
}) => {
  const {
    place_of_carrier_receipt,
    port_of_loading,
    port_of_discharge,
    place_of_carrier_delivery,
    main_carriage_vessel,
    main_carriage_voyage_number,
    estimated_time_of_departure,
    estimated_time_of_arrival,
  } = params;
  const pol_node = getNewRoutingNode({
    tags: ['port_of_loading'] as Array<RoutingNodeTag>,
    location: port_of_loading,
  } as RoutingNodeValue);
  const pod_node = getNewRoutingNode({
    tags: ['port_of_discharge'] as Array<RoutingNodeTag>,
    location: port_of_discharge,
  } as RoutingNodeValue);
  const nodes = { [pol_node._id || '']: pol_node, [pod_node._id || '']: pod_node };
  let pre_carriage_leg: RoutingLegValue[] = [];
  let on_carriage_leg: RoutingLegValue[] = [];
  if (place_of_carrier_receipt) {
    const pcr_node = getNewRoutingNode({
      tags: ['place_of_carrier_receipt'] as Array<RoutingNodeTag>,
      location: place_of_carrier_receipt,
    } as RoutingNodeValue);
    pre_carriage_leg = [
      {
        routing_type: 'pre_carriage',
        origin_id: pcr_node._id,
        destination_id: pol_node._id,
        mode_of_transit: MODE_OF_TRANSIT_RAIL,
        sequence_number: 1.1,
      } as RoutingLegValue,
    ];
    nodes[pcr_node._id || ''] = pcr_node;
  }
  if (place_of_carrier_delivery) {
    const pcd_node = getNewRoutingNode({
      tags: ['place_of_carrier_delivery'] as Array<RoutingNodeTag>,
      location: place_of_carrier_delivery,
    } as RoutingNodeValue);
    on_carriage_leg = [
      {
        routing_type: 'on_carriage',
        origin_id: pod_node._id,
        destination_id: pcd_node._id,
        mode_of_transit: MODE_OF_TRANSIT_RAIL,
        sequence_number: 1.1,
      } as RoutingLegValue,
    ];
    nodes[pcd_node._id || ''] = pcd_node;
  }
  return {
    routing_legs: [
      ...pre_carriage_leg,
      {
        routing_type: 'main_carriage',
        origin_id: pol_node._id,
        destination_id: pod_node._id,
        mode_of_transit: 'sea',
        sequence_number: 2.1,
        vessel: main_carriage_vessel,
        voyage_number: main_carriage_voyage_number,
        estimated_time_of_departure: convertToDayJs(estimated_time_of_departure),
        estimated_time_of_arrival: convertToDayJs(estimated_time_of_arrival),
      } as RoutingLegValue,
      ...on_carriage_leg,
    ],
    routing_nodes: nodes,
  };
};

const RoutingDetails = forwardRef(function RoutingDetails(props: RoutingDetailsProps, ref) {
  const {
    value,
    onChange,
    showTerminal,
    routing_type,
    locationSearchType,
    startSequence,
    endSequence,
    disableNodes,
    disableRoutingLegs,
    disableAddRemoveTranshipmentHop,
    validateVesselVoyage,
    globalCarrierId,
    isReeferContainer,
    bookingType,
    showWaitingTime = false,
    allowVoyageScheduleSearch = true,
    enableDragAndDrop = false,
    disableVoyageUpdate,
    showDpd = false,
  } = props;
  const [errorsPresent, setErrorsPresent] = useState(false);

  const validateRoutingNode = useCallback(() => {
    let foundError = false;
    _mapValues(value?.routing_nodes, (rn) => {
      if (!rn.location && !rn.address) {
        setErrorsPresent(true);
        foundError = true;
      }
    });
    if (validateVesselVoyage) {
      _mapValues(value?.routing_legs, (rl: RoutingLegValue, index: string) => {
        if (
          (index === '0' && rl.hasOwnProperty('vessel') && !rl?.vessel) ||
          (index === '0' && rl.hasOwnProperty('vessel') && !rl?.voyage_number) ||
          (index === '0' &&
            rl.hasOwnProperty('estimated_time_of_departure') &&
            !rl?.estimated_time_of_departure)
        ) {
          setErrorsPresent(true);
          foundError = true;
        }
      });
    }
    if (!foundError) {
      setErrorsPresent(false);
    }
    return foundError;
  }, [validateVesselVoyage, value]);

  useImperativeHandle(ref, () => ({
    runValidation() {
      let foundError = false;
      _mapValues(value?.routing_nodes, (rn) => {
        if (!rn.location && !rn.address) {
          setErrorsPresent(true);
          foundError = true;
        }
      });
      if (validateVesselVoyage && bookingType !== BOOKING_TYPE_SELF) {
        _mapValues(value?.routing_legs, (rl: RoutingLegValue, index: string) => {
          if (
            (index === '0' && !rl?.vessel) ||
            (index === '0' && !rl?.voyage_number) ||
            (index === '0' && !rl?.estimated_time_of_departure)
          ) {
            setErrorsPresent(true);
            foundError = true;
          }
        });
      }
      return foundError;
    },
    addHop,
  }));

  useEffect(() => {
    validateRoutingNode();
  }, [validateRoutingNode]);

  const initialNodes = getInitialNodes(routing_type);
  const routing_legs: Array<RoutingLegValue> = value?.routing_legs || [
      {
        routing_type,
        origin_id: initialNodes.origin._id,
        destination_id: initialNodes.destination._id,
        mode_of_transit: routing_type === 'main_carriage' ? 'sea' : undefined,
        sequence_number: startSequence + 0.1,
      } as RoutingLegValue,
    ],
    routing_nodes: RoutingNodesHashValue = value?.routing_nodes || {
      [initialNodes.origin._id || '']: initialNodes.origin,
      [initialNodes.destination._id || '']: initialNodes.destination,
    };

  const onRoutingLegFieldChange: RoutingLegCallback = (key, value, route_order) => {
    const new_routing_legs = routing_legs;
    new_routing_legs[route_order] = {
      ...new_routing_legs[route_order],
      [key]: value ? value : null,
    };
    if (onChange) onChange({ routing_legs: [...new_routing_legs], routing_nodes });
  };

  const onRoutingNodeFieldChange: RoutingNodeCallback = (key, value, id) => {
    const new_routing_nodes = routing_nodes;
    if (key === 'location_type') {
      new_routing_nodes[id] = {
        ...new_routing_nodes[id],
        location: null,
        terminal: null,
        address: null,
        company: undefined,
      };
    } else if (key === 'location') {
      new_routing_nodes[id] = {
        ...new_routing_nodes[id],
        location: value ? value : null,
        terminal: null,
      };
    } else if (key === 'company_address') {
      new_routing_nodes[id] = {
        ...new_routing_nodes[id],
        address: value ? value.party_address : null,
        company: value ? value.party_company : null,
      };
    } else {
      new_routing_nodes[id] = { ...new_routing_nodes[id], [key]: value ? value : null };
    }
    if (onChange) onChange({ routing_legs, routing_nodes: { ...new_routing_nodes } });
  };

  const addHop = () => {
    const last_routing_leg = routing_legs.splice(-1)[0];
    const new_routing_legs = routing_legs;
    let hop_tags: Array<RoutingNodeTag> = [];
    if (routing_type === 'main_carriage') hop_tags = ['transhipment'];
    else hop_tags = ['hop'];
    const new_node = getNewRoutingNode({ tags: hop_tags } as RoutingNodeValue);
    new_routing_legs.push({
      ...last_routing_leg,
      destination_id: new_node._id,
      estimated_time_of_arrival: undefined,
      actual_time_of_arrival: undefined,
      routing_type,
    });
    new_routing_legs.push({
      sequence_number: (last_routing_leg.sequence_number + endSequence) / 2,
      origin_id: new_node._id,
      destination_id: last_routing_leg.destination_id,
      estimated_time_of_arrival: last_routing_leg.estimated_time_of_arrival,
      actual_time_of_arrival: last_routing_leg.actual_time_of_arrival,
      routing_type,
      mode_of_transit: last_routing_leg?.mode_of_transit,
    } as RoutingLegValue);
    if (onChange)
      onChange({
        routing_legs: [...new_routing_legs],
        routing_nodes: { ...routing_nodes, [new_node._id || '']: new_node },
      });
  };

  const removeHop = (route_order: number) => {
    const routing_leg = routing_legs.splice(route_order, 1)[0];
    routing_legs[route_order - 1] = {
      ...routing_legs[route_order - 1],
      estimated_time_of_arrival: routing_leg.estimated_time_of_arrival,
      actual_time_of_arrival: routing_leg.actual_time_of_arrival,
      destination_id: routing_leg.destination_id,
    };
    if (routing_leg.origin_id) delete routing_nodes[routing_leg.origin_id];
    if (onChange)
      onChange({
        routing_legs: [...routing_legs],
        routing_nodes: { ...routing_nodes },
      });
  };

  // Convert routing legs to a flat array of routing nodes
  const getRoutingNodesFromRoutingLegs = () => {
    return routing_legs.flatMap((leg, index) => {
      const incoming_edge = routing_legs[index - 1];
      const nodesValues: any[] = [];
      nodesValues.push({
        node_id: leg?.origin_id,
        node: leg?.origin,
        estimated_time_of_arrival: incoming_edge?.estimated_time_of_arrival,
        estimated_time_of_departure: leg?.estimated_time_of_departure,
      });
      if (index === routing_legs.length - 1) {
        nodesValues.push({
          node_id: leg?.destination_id,
          node: leg?.destination,
          estimated_time_of_arrival: leg?.estimated_time_of_arrival,
        });
      }
      return nodesValues;
    });
  };

  // Convert a flat array of routing nodes back to routing legs
  const getRoutingLegFromNodes = (flatArray: any): RoutingLegValue[] => {
    const newLegs: RoutingLegValue[] = [];
    for (let i = 0; i < flatArray.length - 1; i++) {
      newLegs.push({
        origin_id: flatArray[i]?.node_id,
        destination_id: flatArray[i + 1]?.node_id,
        id: undefined,
        origin: flatArray[i]?.node,
        destination: flatArray[i + 1]?.node,
        estimated_time_of_arrival: flatArray[i + 1]?.estimated_time_of_arrival,
        actual_time_of_arrival: undefined,
        estimated_time_of_departure: flatArray[i]?.estimated_time_of_departure,
        actual_time_of_departure: undefined,
        sequence_number: i,
        driver_contact: undefined,
        mode_of_transit: undefined,
        routing_type: undefined,
        vessel: undefined,
        voyage_number: undefined,
        wagon_number: undefined,
        voyage_schedule_id: undefined,
        global_carrier: undefined,
      });
    }
    return newLegs;
  };

  const onDragEnd = (result: { source: any; destination: any }) => {
    const { destination, source } = result;
    if (!destination) return;

    const nodesArray = getRoutingNodesFromRoutingLegs();
    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const item = nodesArray[sourceIndex];
    const updatedNodesArray = [...nodesArray];
    updatedNodesArray.splice(sourceIndex, 1);
    updatedNodesArray.splice(destinationIndex, 0, item);

    // Reconstruct routing legs from the updated flat nodes array
    const newRoutingLegs = getRoutingLegFromNodes(updatedNodesArray);
    if (onChange)
      onChange({
        routing_legs: newRoutingLegs,
        routing_nodes,
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'droppable'} direction="vertical">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Timeline pending={false}>
              {routing_legs.map((routing_leg, index) => {
                const previous_routing_leg = routing_legs[index - 1];

                return (
                  <Timeline.Item key={index} dot={<></>}>
                    <RoutingLegRender
                      key={index}
                      routing_leg={routing_leg}
                      previous_routing_leg={previous_routing_leg}
                      routing_nodes={routing_nodes}
                      index={index}
                      total_legs={routing_legs.length}
                      addHop={addHop}
                      removeHop={removeHop}
                      routing_type={routing_type}
                      onRoutingLegFieldChange={onRoutingLegFieldChange}
                      onRoutingNodeFieldChange={onRoutingNodeFieldChange}
                      showTerminal={showTerminal}
                      locationSearchType={locationSearchType}
                      errorsPresent={errorsPresent}
                      disableNodes={disableNodes}
                      disableRoutingLegs={disableRoutingLegs}
                      disableAddRemoveTranshipmentHop={disableAddRemoveTranshipmentHop}
                      validateVesselVoyage={validateVesselVoyage}
                      globalCarrierId={globalCarrierId}
                      isReeferContainer={isReeferContainer}
                      bookingType={bookingType}
                      allowVoyageScheduleSearch={allowVoyageScheduleSearch}
                      showWaitingTime={showWaitingTime}
                      enableDragAndDrop={enableDragAndDrop}
                      disableVoyageUpdate={disableVoyageUpdate}
                      showDpd={showDpd}
                    />
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const CompRoutingDetails = React.memo(RoutingDetails);

export default CompRoutingDetails;
