import { gql } from '@apollo/client';
import { shipmentFields, shipmentServicesFields } from 'operations/graphql/shipment';
import { cargoFields, routingLegFields } from 'operations/modules/booking/graphql';

export const VALIDATE_CONSOL_FROM_HOUSES = gql`
  mutation validate_consol_from_houses($house_shipment_ids: [ID!]!, $consol_type: ConsolTypeEnum!) {
    validate_consol_from_houses(
      house_shipment_ids: $house_shipment_ids
      consol_type: $consol_type
    ) {
      messages
      warnings
      house_shipments {
        id
        trade_type
        freight_type
        gross_weight
        gross_volume
        involved_branch_id
        services {
          ...shipmentServicesFields
        }
        port_of_loading {
          id
          name
          type
          unlocode
          city {
            id
            name
            code
            state_code
            country_code
          }
          country {
            code
            name
          }
        }
        routing_legs {
          ...routingLegFields
        }
        cargos {
          ...cargoFields
        }
      }
      ocean_transport_orders {
        id
        booking_number
      }
      shipment_containers_id
    }
  }
  ${routingLegFields}
  ${shipmentServicesFields}
  ${cargoFields}
`;

export const CONSOL_FROM_HOUSES_WARNINGS = gql`
  mutation consol_from_houses_warnings(
    $house_shipment_ids: [ID!]!
    $shipment: CreateShipmentInput!
    $master_shipment_id: ID
  ) {
    consol_from_houses_warnings(
      house_shipment_ids: $house_shipment_ids
      shipment: $shipment
      master_shipment_id: $master_shipment_id
    ) {
      warnings
    }
  }
`;

export const CREATE_CONSOL_FROM_HOUSES = gql`
  mutation create_consol_from_houses(
    $house_shipment_ids: [ID!]!
    $shipment: CreateShipmentInput!
    $shipment_document_master: ShipmentDocumentInput
    $stock_order: SingleStockOrderInput
  ) {
    create_consol_from_houses(
      house_shipment_ids: $house_shipment_ids
      shipment: $shipment
      shipment_document_master: $shipment_document_master
      stock_order: $stock_order
    ) {
      shipment {
        id
        job_number
      }
    }
  }
`;

export const UPDATE_SHIPMENT_CREDIT_STATUS = gql`
  mutation update_shipment_credit_status(
    $id: ID!
    $requested_by_id: ID
    $reason: String
    $remarks: String
    $action: ShipmentCreditStateActionsEnumType!
  ) {
    update_shipment_credit_status(
      id: $id
      requested_by_id: $requested_by_id
      reason: $reason
      remarks: $remarks
      action: $action
    ) {
      credit_status
      credit_events {
        id
        event_date
      }
    }
  }
`;

export const ALLOCATE_BOOKING = gql`
  mutation allocate_booking(
    $allocate_ocean_transport_orders: [AllocationResourceInputType!]!
    $shipment_id: ID!
  ) {
    allocate_booking(
      allocate_ocean_transport_orders: $allocate_ocean_transport_orders
      shipment_id: $shipment_id
    ) {
      message
    }
  }
`;

export const ALLOCATE_SHIPMENT_REPORT = gql`
  query allocate_shipment_report($ocean_transport_order_id: ID!, $carrier_id: ID) {
    allocate_shipment_report(
      ocean_transport_order_id: $ocean_transport_order_id
      carrier_id: $carrier_id
    ) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;
