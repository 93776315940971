import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useParams } from 'wouter';
import { FETCH_CONTRACT } from '../graphql/query';
import { Skeleton } from '@shipmnts/pixel-hub';
import BaseContract from './BaseContract';

const DuplicateContract = (props: { amend?: boolean }) => {
  const { id } = useParams<{ id: string }>();

  // queries
  const [fetchContract, { data: contractData, loading, refetch }] = useLazyQuery(FETCH_CONTRACT);

  // effects
  useEffect(() => {
    fetchContract({ variables: { id } });
  }, [id, fetchContract]);

  return (
    <Skeleton active loading={loading}>
      <BaseContract
        contract={
          contractData?.get_contract
            ? {
                ...contractData.get_contract,
                amended_from_id: props?.amend ? id : null,
                id: 'new',
              }
            : null
        }
        refetchContract={refetch}
      />
    </Skeleton>
  );
};

export default DuplicateContract;
