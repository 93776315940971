import React, { useEffect } from 'react';
import { Button, Input, message, Modal, Form } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';

import { WAREHOUSE_TRANSACTION_ACTIONS } from '../graphql/warehouseTransaction';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';

interface ReopenTransactionModalProps {
  visible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  transaction?: WarehouseTransactionValue;
}
export default function ReopenTransactionModal(props: ReopenTransactionModalProps) {
  const { transaction, visible, onClose, onSuccess } = props;
  const [form] = Form.useForm();

  const [reopenTransaction, { data, loading, error }] = useMutation(WAREHOUSE_TRANSACTION_ACTIONS);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      if (onClose) onClose();
      message.error(error.message);
    }
    if (data?.warehouse_transaction_actions) {
      message.success('Transaction Reopened');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error, onSuccess]);

  return (
    <Modal
      open={visible}
      title="Reopen For Amendment"
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          Reopen
        </Button>,
      ]}
      onCancel={() => {
        if (onClose) onClose();
      }}
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          reopenTransaction({
            variables: {
              id: transaction?.id,
              event_data: { remarks: values.remarks },
              action: 'reopen',
            },
          });
        }}
        layout="vertical"
      >
        <Form.Item name="remarks" label="Remarks For Reopen" rules={[{ required: true }]} required>
          <Input.TextArea style={{ width: '100%' }} rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
