/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const UserAccount = types.model({
  id: types.identifier,
  first_name: types.string,
  last_name: types.maybe(types.maybeNull(types.string)),
  email: types.maybe(types.maybeNull(types.string)),
  dashboard_id: types.maybe(types.maybeNull(types.string)),
});

export interface UserAccountValue extends Instance<typeof UserAccount> {}
export interface UserAccountIn extends SnapshotIn<typeof UserAccount> {}
export interface UserAccountOut extends SnapshotOut<typeof UserAccount> {}

export default UserAccount;
