import { message, dayjs } from '@shipmnts/pixel-hub';
import { startCase as _startCase, omit as _omit, pick, omit } from 'lodash';
import {
  CANCELLED,
  STATUS_DELIVERED,
  STATUS_DRAFT,
  STATUS_SUBMITTED,
  STATUS_FULLY_DELIVERED,
  STATUS_FULLY_ISSUED,
  STATUS_FULLY_RECEIVED,
  STATUS_ISSUED,
  STATUS_PARTIALLY_DELIVERED,
  STATUS_PARTIALLY_ISSUED,
  STATUS_PARTIALLY_RECEIVED,
  STATUS_PENDING,
  STATUS_RECEIVED,
  TRANSACTION_TYPE_INCOMING,
  TRANSACTION_TYPE_DELIVERY,
  TRANSACTION_TYPE_OUTGOING,
  TRANSACTION_TYPE_RECEIPT,
  WarehouseTransactionValue,
  TRANSACTION_TYPE_GTO,
} from 'operations/models/WarehouseTransaction';

export const freight_type = [
  { key: 'air_import', value: 'Air Import', default: true },
  { key: 'air_export', value: 'Air Export' },
  { key: 'ocean_import', value: 'Ocean Import' },
  { key: 'ocean_export', value: 'Ocean Export' },
  { key: 'road_import', value: 'Road Import' },
  { key: 'road_export', value: 'Road Export' },
];

export const dimension_unit = [
  { value: 'cms', label: 'cms' },
  { value: 'inches', label: 'inches' },
  { value: 'meter', label: 'meter' },
];

export const weight_unit = [
  { value: 'kgs', label: 'kgs' },
  { value: 'tons', label: 'tons' },
  { value: 'lbs', label: 'lbs' },
];

export const transactionTypeToDocType: Record<string, string> = {
  incoming_shipment: 'Wms::IncomingShipment',
  receipt: 'Wms::Receipt',
  outgoing_shipment: 'Wms::OutgoingShipment',
  delivery: 'Wms::Delivery',
  goods_transfer: 'Wms::GoodsTransfer',
};

const typeToTitleMapping: Record<string, string> = {
  incoming_shipment: 'Incoming Shipment',
  receipt: 'Receipt Note',
  delivery: 'Delivery',
  outgoing_shipment: 'Outgoing Shipment',
  goods_transfer: 'Goods Transfer',
};

export const convertToKgs = (uom: string, value: number) => {
  if (uom === 'lbs') return value * 0.453;
  else if (uom === 'tons') return value * 1000;
  return value;
};

export const convertToMtr = (uom: string, value: number) => {
  if (uom === 'meter') return value;
  else if (uom === 'inches') return value * 0.0254;
  return value * 0.01;
};

export const TRANSACTION_STATUS_COLOR_HASH = {
  [STATUS_DRAFT]: 'primary_blue',
  [STATUS_SUBMITTED]: 'green',
  [STATUS_PENDING]: 'gray',
  [STATUS_PARTIALLY_RECEIVED]: 'dark_yellow',
  [STATUS_FULLY_RECEIVED]: 'green',
  [STATUS_RECEIVED]: 'gray',
  [STATUS_FULLY_DELIVERED]: 'green',
  [STATUS_PARTIALLY_DELIVERED]: 'dark_yellow',
  [STATUS_FULLY_ISSUED]: 'green',
  [STATUS_PARTIALLY_ISSUED]: 'dark_yellow',
  [STATUS_ISSUED]: 'gray',
  [STATUS_DELIVERED]: 'green',
  [CANCELLED]: 'red',
};

export const TRANSACTION_STATUS_DISPLAY_HASH = {
  [STATUS_DRAFT]: 'Draft',
  [STATUS_SUBMITTED]: 'Submitted',
  [STATUS_PENDING]: 'Pending',
  [STATUS_PARTIALLY_RECEIVED]: 'Partially Received',
  [STATUS_FULLY_RECEIVED]: 'Fully Received',
  [STATUS_RECEIVED]: 'Received',
  [STATUS_FULLY_DELIVERED]: 'Fully Delivered',
  [STATUS_PARTIALLY_DELIVERED]: 'Partially Delivered',
  [STATUS_FULLY_ISSUED]: 'Fully Issued',
  [STATUS_PARTIALLY_ISSUED]: 'Partially Issued',
  [STATUS_ISSUED]: 'Issued',
  [STATUS_DELIVERED]: 'Delivered',
  [CANCELLED]: 'Cancelled',
};

export const GTO_PURPOSE_OF_TRANS = [
  { key: 'Put-Away', value: 'Put-Away' },
  { key: 'Picking', value: 'Picking' },
];

export const recalculateTotal = (data: any) => {
  const gross_volume = data?.gross_volume || getGrossVolume(data);
  const volumetric_weight_kgs = (Math.pow(10, 6) * gross_volume) / 6000;
  const gross_weight = data?.per_pack_weight
    ? (data.per_pack_weight || 0) * (data.qty || 1)
    : data.gross_weight || 0;
  const gross_weight_kgs = convertToKgs(data.weight_unit, gross_weight);
  const chargeable_weight_kgs = Math.max(gross_weight_kgs, volumetric_weight_kgs);
  return {
    gross_weight,
    gross_volume,
    volumetric_weight: volumetric_weight_kgs,
    chargeable_weight: chargeable_weight_kgs,
  };
};

export const getGrossVolume = (data: any) => {
  const length = convertToMtr(data?.dimension_unit, data?.length || 1);
  const breadth = convertToMtr(data?.dimension_unit, data?.breadth || 1);
  const height = convertToMtr(data?.dimension_unit, data?.height || 1);
  const gross_volume = length * breadth * height * (data?.qty || 1);
  return gross_volume;
};

const getTransactionProductPayload = (products: any[], transaction: any, type: any) => {
  const newValue = products;
  const oldValue = (transaction?.warehouse_transaction_products || []).map((product: any) => {
    return {
      ...omit(product, ['__typename', 'product']),
      product_id: product.id,
    };
  });
  if (oldValue) {
    oldValue?.forEach((old: any) => {
      if (!newValue.find((c: any) => c.id === old.id))
        newValue.push({
          ...old,
          _destroy: true,
        });
    });
  }
  const warehouse_transaction_products = (newValue || []).map((product: any) => {
    return {
      ..._omit(product, [
        'product_property',
        'product_packaging_options',
        'from_location',
        'to_location',
        'damage_location',
        'warehouse_transaction',
        '__typename',
        'linked_to',
        'linked_products',
        'compare_id',
        'per_piece_volume',
        'balance',
      ]),
      product_property: { ..._omit(product?.product_property, ['__typename']) },
      from_location_id:
        type === TRANSACTION_TYPE_INCOMING || type === TRANSACTION_TYPE_RECEIPT
          ? null
          : product?.from_location?.id || null,
      to_location_id:
        type === TRANSACTION_TYPE_OUTGOING || type === TRANSACTION_TYPE_DELIVERY
          ? null
          : product?.to_location?.id || null,
      damage_location_id: type === TRANSACTION_TYPE_RECEIPT ? product?.damage_location?.id : null,
      packing_type: product?.packing_type?.packing_type || product?.packing_type,
      qty: product?.qty || 1,
      weight_unit: product?.weight_unit || 'kgs',
      dimension_unit: product?.dimension_unit || 'cms',
      invoice_date:
        product?.invoice_date && typeof product?.invoice_date === 'string'
          ? undefined
          : product?.invoice_date,
    };
  });
  return warehouse_transaction_products;
};

const getWarehouseTrnasactionProductInitialvalue = (products: any[]) => {
  return (products || []).map((product) => {
    return {
      ...omit(product, ['product']),
      product_packaging_options: product?.product?.product_packaging_options,
      product_id: product?.product?.id || product?.product_id,
    };
  });
};

const getTransactionPartiesPayload = (values: any, transaction_id: any, transaction: any) => {
  return [
    {
      id:
        !!transaction_id && transaction_id !== 'new'
          ? transaction?.shipment_parties?.find((e: any) => e.name === 'customer')?.id
          : null,
      name: 'customer',
      party_address_id: values?.customer?.party_address?.id,
      party_company_id: values?.customer?.party_company?.id,
    },
    {
      id:
        !!transaction_id && transaction_id !== 'new'
          ? transaction?.shipment_parties?.find((e: any) => e.name === 'shipper')?.id
          : null,
      name: 'shipper',
      party_address_id: values?.shipper?.party_address?.id,
      party_company_id: values?.shipper?.party_company?.id,
    },
    {
      id:
        !!transaction_id && transaction_id !== 'new'
          ? transaction?.shipment_parties?.find((e: any) => e.name === 'consignee')?.id
          : null,
      name: 'consignee',
      party_address_id: values?.consignee?.party_address?.id,
      party_company_id: values?.consignee?.party_company?.id,
    },
  ];
};

export const getFormTitle = (
  transaction_id: any,
  transaction: WarehouseTransactionValue | undefined,
  type: string
) => {
  if (!!transaction_id && transaction_id !== 'new')
    return `Edit ${typeToTitleMapping[type]} ${transaction?.transaction_number}`;
  else return `Create New ${typeToTitleMapping[type]}`;
};

export const getInitialValue = (
  transaction_id: any,
  transaction: WarehouseTransactionValue | undefined,
  type: string,
  session: any
) => {
  const value = {
    ...transaction,
    id: transaction_id === 'new' ? undefined : transaction_id,
    customer: pick(
      transaction?.shipment_parties?.find((e) => e.name === 'customer'),
      ['name', 'party_company', 'party_address']
    ),
    shipper: pick(
      transaction?.shipment_parties?.find((e) => e.name === 'shipper'),
      ['name', 'party_company', 'party_address']
    ),
    consignee: pick(
      transaction?.shipment_parties?.find((e) => e.name === 'consignee'),
      ['name', 'party_company', 'party_address']
    ),
    target_delivery_date: transaction?.target_date
      ? dayjs.unix(transaction?.target_date)
      : type === TRANSACTION_TYPE_OUTGOING
      ? dayjs.unix(Date.now() / 1000)
      : null,
    target_receipt_date: transaction?.transaction_date
      ? dayjs.unix(transaction?.transaction_date)
      : type === TRANSACTION_TYPE_INCOMING
      ? dayjs.unix(Date.now() / 1000)
      : null,
    origin_etd_date: transaction?.origin_etd_date ? dayjs.unix(transaction?.origin_etd_date) : null,
    destination_eta_date: transaction?.destination_eta_date
      ? dayjs.unix(transaction?.destination_eta_date)
      : null,
    transaction_date: transaction?.transaction_date
      ? dayjs.unix(transaction?.transaction_date)
      : dayjs.unix(Date.now() / 1000),
    transaction_by: transaction?.transaction_by || {
      ...session,
      id: session.id || session.user_contact_id,
    },
    custom_clearance_date: transaction?.custom_clearance_date
      ? dayjs.unix(transaction?.custom_clearance_date)
      : null,
    customer_ref_date: transaction?.customer_ref_date
      ? dayjs.unix(transaction?.customer_ref_date)
      : null,
    is_battery: transaction?.product_property?.is_battery,
    is_hazardous: transaction?.product_property?.is_hazardous,
    is_temp_controlled: transaction?.product_property?.is_temp_controlled,
    warehouse_transaction_products: getWarehouseTrnasactionProductInitialvalue(
      transaction?.warehouse_transaction_products || []
    ),
  };
  return value;
};

const REQUIRED_FIELDS_CONFIG: {
  [key: string]: string[];
} = {
  [TRANSACTION_TYPE_INCOMING]: ['product_name', 'qty'],
  [TRANSACTION_TYPE_OUTGOING]: ['product_name', 'qty'],
  [TRANSACTION_TYPE_RECEIPT]: ['product_name', 'packing_type', 'qty', 'to_location_id'],
  [TRANSACTION_TYPE_DELIVERY]: ['product_name', 'packing_type', 'qty', 'from_location_id'],
  [TRANSACTION_TYPE_GTO]: [
    'product_name',
    'packing_type',
    'qty',
    'to_location_id',
    'from_location_id',
  ],
};

const prettify = (value: string) => {
  if (value === 'to_location_id' || value === 'from_location_id') return 'Location';
  else return _startCase(value);
};

const checkRequiredFieldAndGenerateError = (payload: any, type: string) => {
  const requiredFields = REQUIRED_FIELDS_CONFIG?.[type] || [];
  if (Array.isArray(payload.warehouse_transaction_products)) {
    const missingFieldsMessages: string[] = [];
    const newPayload = (payload.warehouse_transaction_products || []).filter(
      (product: any) => !product._destroy
    );

    newPayload.forEach((product: any) => {
      const missingFields = requiredFields.filter(
        (field) => !(product.hasOwnProperty(field) && product[field])
      );
      if (missingFields.length > 0) {
        const formattedFields = missingFields.map((field) => prettify(field));
        missingFieldsMessages.push(formattedFields.join(', ') + ' is required.');
      }
    });
    if (missingFieldsMessages.length > 0) {
      missingFieldsMessages.forEach((errMsg) => message.error(errMsg));
      return true;
    }
  }
  return false;
};

export const validateAndGeneratePayload = (
  values: any,
  transaction_id: any,
  transaction: WarehouseTransactionValue | undefined,
  type: string
) => {
  // Write All your custom validations over here for all four transaction form
  const payload = {
    ..._omit(values, [
      'handling_service',
      'value_added_service',
      'target_receipt_date',
      'customer',
      'customer_ref_date',
      'target_delivery_date',
      'shipper',
      'consignee',
      'branch',
      'origin',
      'destination',
      'carrier',
      'received_transporter',
      'delivered_transporter',
      'transaction_by',
      'all_product_properties',
      'all_location',
      'all_from_location',
      'all_to_location',
      'damage_location',
      'target_warehouse',
      'is_battery',
      'is_hazardous',
      'is_temp_controlled',
    ]),
    id: !!transaction_id && transaction_id !== 'new' ? transaction_id : null,
    type: type,
    shipment_parties: getTransactionPartiesPayload(values, transaction_id, transaction),
    customer_company_id: values?.customer?.party_company?.id,
    customer_address_id: values?.customer?.party_address?.id,
    branch_id: values?.target_warehouse?.branch?.id,
    target_warehouse_id: values?.target_warehouse?.id,
    customer_ref_date: values.customer_ref_date?.unix() || null,
    target_date: values.target_receipt_date?.unix() || values.target_delivery_date?.unix() || null,
    carrier_id: values?.carrier?.id,
    received_transporter_id: values?.received_transporter?.id,
    delivered_transporter_id: values?.delivered_transporter?.id,
    origin_id: values?.origin?.id,
    destination_id: values?.destination?.id,
    origin_etd_date: values?.origin_etd_date?.unix() || null,
    destination_eta_date: values?.destination_eta_date?.unix() || null,
    transaction_date: values?.transaction_date?.unix() || null,
    custom_clearance_date: values?.custom_clearance_date?.unix() || null,
    transaction_by_id: values?.transaction_by?.id,
    product_property: {
      ...values?.product_property,
      is_battery: values?.is_battery,
      is_hazardous: values?.is_hazardous,
      is_temp_controlled: values?.is_temp_controlled,
    },
    warehouse_transaction_products: getTransactionProductPayload(
      values?.warehouse_transaction_products,
      transaction,
      type
    ),
    total_gross_wt: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.gross_weight),
      0
    ),
    total_volume_wt: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.volumetric_weight),
      0
    ),
    total_gross_volume: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.gross_volume),
      0
    ),
    total_chargeable_wt: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.chargeable_weight),
      0
    ),
    total_packages: values?.warehouse_transaction_products?.reduce(
      (total: any, e: any) => (total += e.qty),
      0
    ),
  };

  if ((payload?.warehouse_transaction_products?.length || 0) === 0) {
    message.error('Transaction must atleast have one product added.');
    return false;
  }
  const error = checkRequiredFieldAndGenerateError(payload, type);
  if (error) return false;

  return payload;
};
