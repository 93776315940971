import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  RequireHeaderWrapper,
  getGenericPayloadValue,
  updateDriverDetailsWithContact,
} from './helpers';
import { Column, useSession } from 'common';
import {
  RecordLoaderRender,
  FormTable,
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  SearchDocTypeEditor,
  Drawer,
  message,
  Button,
  DrawerFooter,
  CompanySearch,
} from '@shipmnts/pixel-hub';
import {
  GridOptions,
  CellValueChangedEvent,
  NewValueParams,
  ValueSetterParams,
  RowNode,
} from '@ag-grid-community/core';
import { CREATE_BULK_SHIPMENT_FROM_PLANNED } from './graphql';
import { useMutation } from '@apollo/client';
import { ShipmentValue } from 'operations/models/Shipment';
import { omit } from 'lodash';
import { ASSET_TYPE_HIRE, ASSET_TYPE_OWN, SHIPMENT_TYPE_BACK_TO_BACK } from '../../constants';
import allFields from './allFields';

type Props = {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess?: () => void;
};

function AssignVehicleFormTable(props: Props) {
  const { shipment, onClose, onSuccess } = props;
  const gridRef = useRef<GridOptions>();
  const sessionData = useSession();
  const defaultRowValues = {
    ownership_type: ASSET_TYPE_OWN,
    transporter: sessionData?.company_account?.default_company,
  };
  const [gridData, setGridData] = useState<any[]>([defaultRowValues]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  const [drawerOpen, setDrawerOpen] = useState(true);

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<any, any>) => {
    const updatedGridData: any[] = [];
    gridRef?.current?.api?.forEachNode((node: RowNode<any>, index: number) => {
      const updatedRowdata = { ...node.data };
      if (event.rowIndex === index) {
        if (event.newValue)
          updatedRowdata.errors = omit(updatedRowdata.errors, Object.keys(event.data));
      }

      updatedGridData.push(updatedRowdata);
    });

    setGridData(updatedGridData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    createBulkShipmentFromPlanned,
    {
      data: createBulkShipmentData,
      loading: createBulkShipmentLoading,
      error: createBulkShipmentError,
    },
  ] = useMutation(CREATE_BULK_SHIPMENT_FROM_PLANNED);

  const closeDrawer = () => {
    setDrawerOpen(false);
    if (onSuccess) onSuccess();
  };

  useEffect(() => {
    if (createBulkShipmentError) {
      message.error(createBulkShipmentError.message);
    } else if (createBulkShipmentData?.create_bulk_shipment_from_planned) {
      message.success('Vehicle Assigned Successfully');
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBulkShipmentData, createBulkShipmentLoading, createBulkShipmentError]);

  const columnDefs: Column[] = [];

  const requiredColumns: { [key: string]: string } = {
    ownership_type: 'Ownership Type',
    vehicle_details: 'Vehicle #',
    transporter: 'Transporter',
  };
  columnDefs.push(
    allFields.vehicle_details({
      headerComponent: () => <RequireHeaderWrapper text="Vehicle #" />,
      onCellValueChanged: (event: NewValueParams<any>) => {
        const transporter = event?.newValue?.company;
        if (transporter) event.data.transporter = transporter;
      },
    }),
    allFields.vehicle_ownership_type({
      onCellValueChanged: (event: NewValueParams<any>) => {
        const currentCompany = sessionData?.company_account?.default_company;
        if (event.newValue === ASSET_TYPE_OWN) {
          event.data.transporter = currentCompany;
        } else if (
          event.newValue === ASSET_TYPE_HIRE &&
          event.data.transporter === currentCompany
        ) {
          event.data.transporter = null;
        }
      },
    }),
    allFields.transporter({
      valueSetter: (params: ValueSetterParams<any>) => {
        params.data.transporter = params.newValue || params?.data?.vehicle_details?.company;
        return true;
      },
      cellEditorParams: (params: any) => {
        const value = params?.data?.transporter || params?.data?.vehicle_details?.company;
        return {
          CustomComponent: CompanySearch,
          componentProps: {
            doc_type: 'Network::Company',
            selectMode: 'single',
            value,
          },
        };
      },
    }),
    allFields.driver_details()
  );

  const components = {
    DateEditor,
    StringEditor,
    FloatEditor,
    EnumEditor,
    SearchDocTypeEditor,
    RecordLoaderRender,
  };

  const handleCreateHouseBulkShipment = () => {
    const payload: any[] = [];

    const updatedGridData: any[] = [];
    let errorOccured = false;
    gridData.forEach((gridRow) => {
      // if any cell set in current row then validate all
      let rowNotSet = true;
      Object.keys(gridRow).forEach((key) => {
        if (gridRow[key]) rowNotSet = false;
      });
      if (rowNotSet) return;

      // validate required fields
      const errors: { [key: string]: string } = {};
      Object.keys(requiredColumns).forEach((key) => {
        if (requiredColumns[key] && !gridRow[key]) {
          errors[key] = `${requiredColumns[key]} is required.`;
          errorOccured = true;
        }
      });

      updatedGridData.push({ ...gridRow, errors: errors });

      if (errorOccured) return;

      const vehicle = {
        ...(gridRow?.vehicle_details || {}),
        vehicle_details: gridRow.vehicle_details,
        company: gridRow.transporter,
      };

      const driver = updateDriverDetailsWithContact(gridRow.driver_details);
      const house_shipment_input = {
        vehicle: vehicle,
        driver: driver,
      };
      const payloadData = getGenericPayloadValue(house_shipment_input) || {};
      payload.push({
        ...omit(payloadData, ['shipment_invoices', 'shipment_parties', 'ocean_transport_order']),
        shipment_type: SHIPMENT_TYPE_BACK_TO_BACK,
      });
    });

    setGridData(updatedGridData);
    if (errorOccured) return;

    if (payload.length) {
      createBulkShipmentFromPlanned({
        variables: {
          shipments: payload,
          customer_order_id: shipment.id,
        },
      });
    }
  };

  return (
    <Drawer
      forceRender
      title={`Assign Vehicle`}
      width="80%"
      onClose={onClose}
      open={drawerOpen}
      footer={
        <DrawerFooter
          saveText="Assign Vehicle"
          closeText="Cancel"
          loading={createBulkShipmentLoading}
          onClose={onClose}
          onSave={handleCreateHouseBulkShipment}
        />
      }
    >
      <FormTable
        reportName={'assign_vehicle'}
        showCheckBoxOnHeader
        rowSelection="multiple"
        columns={columnDefs}
        onGridReady={(grid: any) => {
          grid.columnApi.resetColumnState();
          setTimeout(() => {
            gridRef?.current?.api?.startEditingCell({
              rowIndex: gridData?.length - 1,
              colKey: 'vehicle_details',
            });
          }, 500);
        }}
        gridRef={gridRef}
        rowData={gridData}
        allowProcessDataFromClipboard={true}
        newRowOnPaste={true}
        reportConfig={{
          onRowSelected: (event: any) => {
            const rowsSelected = gridRef.current?.api?.getSelectedRows();
            setSelectedRowsCount(rowsSelected?.length || 0);
          },
          components: components,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          suppressClipboardApi: true,
          defaultColDef: {
            editable: true,
            flex: 1,
            minWidth: 100,
            resizable: true,
            suppressMenu: true,
          },
          onCellValueChanged: onCellValueChanged,
          onRowDataUpdated: (params: any) => {
            const updatedRows: any[] = [];
            params.api.forEachNode((rowNode: any) => {
              updatedRows.push(rowNode.data);
            });
            setGridData(updatedRows);
          },
          enableRangeSelection: true,
        }}
        checkbox_always_visible={false}
      />
      <div style={{ marginTop: '10px', display: 'flex' }}>
        <Button
          size="small"
          style={{ marginRight: '16px' }}
          onClick={() => {
            gridRef.current?.api?.applyTransaction({ add: [defaultRowValues] });
            setTimeout(() => {
              gridRef?.current?.api?.startEditingCell({
                rowIndex: gridData?.length,
                colKey: 'vehicle_details',
              });
            }, 300);
          }}
        >
          {' '}
          Add Vehicle
        </Button>
        <Button
          danger
          disabled={selectedRowsCount === 0}
          size="small"
          onClick={() => {
            const sel = gridRef.current?.api?.getSelectedRows();
            gridRef.current?.api?.applyTransaction({ remove: sel });
            setSelectedRowsCount(0);
          }}
        >
          {' '}
          Delete Vehicle(s)
        </Button>
      </div>
    </Drawer>
  );
}

export default AssignVehicleFormTable;
