import React, { useEffect, useState } from 'react';
import { useSession } from 'common';
import { Select } from '@shipmnts/pixel-hub';
import Dashboard from './Dashboard';
import { gql, useQuery, useMutation } from '@apollo/client';
import { GET_DASHBOARDS } from '../graphQL/query';
import { LANDING_DASHBOARD } from 'network/constants';
import Spinner from './Spinner';

const { Option } = Select;

const UPDATE_SELF_USER_ACCOUNT = gql`
  mutation update_self_user_account($id: ID!, $user: UpdateSelfUserAccountInputType!) {
    update_self_user_account(id: $id, user: $user) {
      dashboard_id
    }
  }
`;

const LandingPage: React.FC = () => {
  const user = useSession();
  const { data: dashboardsData } = useQuery(GET_DASHBOARDS, {
    variables: {
      dashboard_type: LANDING_DASHBOARD,
    },
  });

  const [updateLandingDashboard] = useMutation(UPDATE_SELF_USER_ACCOUNT);
  const [currentDashboardId, setCurrentDashboardId] = useState(user?.dashboard_id);

  useEffect(() => {
    if (dashboardsData && dashboardsData.get_dashboards) {
      !currentDashboardId && setCurrentDashboardId(dashboardsData?.get_dashboards[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardsData]);

  const handleDefaultLandingDashboardChange = (dashboardId: string) => {
    setCurrentDashboardId(dashboardId);
    updateLandingDashboard({
      variables: {
        id: user.id,
        user: {
          dashboard_id: dashboardId,
        },
      },
    });
  };

  if (!dashboardsData || !currentDashboardId) {
    return <Spinner tip="Fetching your information" />;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          margin: '10px',
        }}
      >
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', marginLeft: '10px' }}>
          Hi, <b>{user.first_name}</b>
        </span>
        <Select
          style={{ width: '250px', marginRight: '10px' }}
          value={currentDashboardId}
          onChange={(value) => {
            handleDefaultLandingDashboardChange(value);
          }}
        >
          {(dashboardsData.get_dashboards || []).map((dashboard: any) => {
            return (
              <Option key={dashboard.id} value={dashboard.id}>
                {dashboard.title}
              </Option>
            );
          })}
        </Select>
      </div>

      {<Dashboard id={currentDashboardId} />}
    </>
  );
};

export default LandingPage;
