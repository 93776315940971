import { Button, Modal } from '@shipmnts/pixel-hub';
import React, { useCallback, useState } from 'react';

import { getNewEstimates } from 'sales_hub/utils/utils';
import { RateExplorerData, RateExplorerResourceType } from './RateExplorer.type';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';

import RateExplorerLayout from './RateExplorerLayout';

interface RateExplorerModelProp {
  resource: RateExplorerResourceType;
  addEstimate: (estimates: Partial<ShipmentEstimateValue>[]) => void;
  onSucess?: () => void;
  onClose?: () => void;
}

export default function RateExplorerModal(props: RateExplorerModelProp) {
  const { resource, addEstimate, onSucess, onClose } = props;
  const [open, setOpen] = useState(true);
  const [selectedItems, setSelectedItems] = useState<RateExplorerData[]>([]);

  const updateSelectedItems = useCallback((selectedItems: RateExplorerData[]) => {
    setSelectedItems(selectedItems);
  }, []);

  return (
    <Modal
      destroyOnClose
      open={open}
      width={'90%'}
      centered
      maskClosable={false}
      onCancel={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      closable={false}
      footer={[
        <div key={'actions'} style={{ textAlign: 'right' }}>
          <Button
            key="cancel"
            onClick={() => {
              setOpen(false);
              if (onClose) onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            key={'copy'}
            disabled={selectedItems.length === 0}
            style={{ marginLeft: '1em' }}
            onClick={() => {
              const estimates = selectedItems.reduce(
                (estimates: Partial<ShipmentEstimateValue>[], item) => {
                  return [...estimates, ...getNewEstimates(item.shipment_estimates)];
                },
                []
              );
              addEstimate(estimates);
              setOpen(false);
              if (onSucess) onSucess();
              if (onClose) onClose();
            }}
            type="primary"
          >
            Copy
          </Button>
        </div>,
      ]}
    >
      <RateExplorerLayout resource={resource} onSelection={updateSelectedItems} />
    </Modal>
  );
}
