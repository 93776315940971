import React, { useEffect, useMemo, useState } from 'react';
import { isArray as _isArray } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, ReportCellEditor, Space, Tag } from '@shipmnts/pixel-hub';

import './style.css';
import { DetailsCard } from 'common';

import { extractCustomFieldValues, extractStandardFieldValues, PAYLOAD_FIELD_MAP } from './helper';
import { SectionCardPropType } from './types';
import { DocFieldValue } from 'common/models/DocField';
import { GET_CUSTOM_FIELDS_FROM_DOC_TYPE } from 'common/utils/graphql';
import { EDIT_FIELD } from '../../utils/graphql';

const InlineEditableField = ({ value, InputComponent, active }: any) => {
  return active ? InputComponent : value;
};

export const SectionCard = ({
  record, // assuming, record will have custom_field_values prop in case of custom fields in given section
  section,
  doc_type_id,
  fieldsToIgnore, // array of field_ids to ignore
  form,
  onUpdate,
  loading,
  title,
  refetch,
  className,
  style,
}: SectionCardPropType) => {
  // get all fields
  const { data: fields, loading: fetchingFields } = useQuery(GET_CUSTOM_FIELDS_FROM_DOC_TYPE, {
    variables: { doc_type_id, section },
  });

  const [editFields, { data: editedData, loading: updating }] = useMutation(EDIT_FIELD);

  // reload card data on edit
  useEffect(() => {
    if (refetch) refetch();
  }, [refetch, editedData]);

  // is section card in edit mode??
  const [editMode, setEditMode] = useState(false);

  const [load, setLoad] = useState(false);

  // function to call when clicked Edit
  const enableEdit = () => {
    setLoad(true);
    setEditMode(true);
    setTimeout(() => setLoad(false), 500);
  };

  // used when onUpdate is not provided (field_id: value pairs)
  const buildEditFieldsPayload = (values: Record<string, any>) => {
    const payload: Record<string, any> = {};
    fields?.get_custom_fields_from_doc_type?.forEach((field: DocFieldValue) => {
      let value = values[field.db_column_name];
      if (field.type !== 'Blueprint::GlobalField') {
        const cellEditor = JSON.parse(field?.cell_editor || '{}');
        const recordField = cellEditor.args?.record_details_field;
        // if the field is not primitive (for fields like MultiSearch, Search)
        if (Object.keys(PAYLOAD_FIELD_MAP).includes(field.field_type))
          value = PAYLOAD_FIELD_MAP[field.field_type](value, recordField);
      }

      payload[field.id] = value;
    });

    return payload;
  };

  // constructing data to show
  const data = useMemo(() => {
    // remove fields to ignore
    const fieldsToShow = fields?.get_custom_fields_from_doc_type?.filter(
      (field: any) => !fieldsToIgnore?.includes(field?.id)
    );
    return {
      // extract standard fields
      ...extractStandardFieldValues(record, fieldsToShow),
      // extract custom fields
      ...extractCustomFieldValues(record?.custom_field_values, fieldsToShow),
    };
  }, [record, fields, fieldsToIgnore]);

  return (
    <DetailsCard
      header={{ title }}
      className={className}
      style={style}
      loading={loading || load || updating || fetchingFields}
      Action={
        editMode ? (
          <Space>
            <Button
              size="small"
              onClick={() => {
                setEditMode(false);
                form?.resetFields();
              }}
            >
              {'Cancel'}
            </Button>
            <Button
              size="small"
              onClick={() => {
                setEditMode(false);
                if (form) form.submit();
              }}
            >
              {'Save'}
            </Button>
          </Space>
        ) : (
          <Button size="small" onClick={enableEdit}>
            {'Edit'}
          </Button>
        )
      }
    >
      <Form
        form={form}
        onFinish={(values) => {
          const payload = buildEditFieldsPayload(values);
          editFields({
            variables: {
              changes: {
                id: record?.id,
                doc_type_id,
                fields_changes: JSON.stringify(payload),
              },
            },
          });
          if (refetch) refetch();
          if (onUpdate) onUpdate(payload);
          form?.resetFields();
        }}
        className="custom-scrollbar"
      >
        {data && Object.keys(data).length > 0 ? (
          Object.entries(data)
            .filter(([_, value]) => value)
            .map(([label, row]: [any, any]) => {
              return (
                <div
                  key={label}
                  style={{
                    display: 'flex',
                    gap: '10%',
                    marginBottom: '1em',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                  className="section-card-container"
                >
                  <span
                    style={{
                      fontWeight: '800px',
                      opacity: '.6',
                      width: '45%',
                    }}
                  >
                    {label}
                  </span>
                  <span
                    style={{
                      width: '45%',
                      wordWrap: 'break-word',
                    }}
                  >
                    <InlineEditableField
                      value={
                        <span style={{ lineHeight: '32px' }}>
                          {_isArray(row?.value)
                            ? row?.value?.map((val: any) => <Tag key={''}>{val}</Tag>)
                            : row?.value || '-'}
                        </span>
                      }
                      InputComponent={
                        <Form.Item
                          name={row?.fieldName}
                          style={{ margin: 0, padding: 0 }}
                          initialValue={row?.defaultValue}
                        >
                          <ReportCellEditor {...row?.extraProps} />
                        </Form.Item>
                      }
                      active={editMode}
                    />
                  </span>
                </div>
              );
            })
        ) : (
          <div style={{ marginInline: 'auto', padding: '2em', opacity: 0.4 }}>
            {'No fields available in this section'}
          </div>
        )}
      </Form>
    </DetailsCard>
  );
};
