import { gql } from '@apollo/client';
import { shipmentFields } from 'operations/graphql/shipment';

export const CREATE_SHIPMENT = gql`
  mutation create_shipment(
    $shipment: CreateShipmentInput
    $shipment_document_master: ShipmentDocumentInput
    $shipment_document_house: ShipmentDocumentInput
    $stock_order: SingleStockOrderInput
    $throw_error_on_credit_fail: Boolean
  ) {
    create_shipment(
      shipment: $shipment
      shipment_document_master: $shipment_document_master
      shipment_document_house: $shipment_document_house
      stock_order: $stock_order
      throw_error_on_credit_fail: $throw_error_on_credit_fail
    ) {
      shipment {
        ...shipmentFields
      }
      house_shipment {
        ...shipmentFields
      }
    }
  }
  ${shipmentFields}
`;

export const CREATE_PLANNED_SHIPMENT = gql`
  mutation create_planned_shipment(
    $planned_shipment: CreateShipmentInput
    $shipment_document_master: ShipmentDocumentInput
    $stock_order: SingleStockOrderInput
    $throw_error_on_credit_fail: Boolean
  ) {
    create_planned_shipment(
      planned_shipment: $planned_shipment
      shipment_document_master: $shipment_document_master
      stock_order: $stock_order
      throw_error_on_credit_fail: $throw_error_on_credit_fail
    ) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;

export const CREATE_HOUSE_SHIPMENT = gql`
  mutation create_house_shipment(
    $house_shipment: CreateHouseShipmentInput!
    $master_shipment_id: ID!
    $shipment_document_house: ShipmentDocumentInput
    $shipment_container_ids: [ID!]
  ) {
    create_house_shipment(
      house_shipment: $house_shipment
      master_shipment_id: $master_shipment_id
      shipment_document_house: $shipment_document_house
      shipment_container_ids: $shipment_container_ids
    ) {
      ...shipmentFields
      master_shipment {
        id
        total_number_of_packages
        gross_weight
        gross_volume
        volumetric_weight
        shipment_packages {
          ...packagesFields
        }
        shipment_product_ratings {
          ...productRatingFields
        }
      }
    }
  }
  ${shipmentFields}
`;

export const CREATE_HOUSE_SHIPMENT_BULK = gql`
  mutation create_house_shipment_bulk($house_shipments: [CreateHouseShipmentInputBulk!]!) {
    create_house_shipment_bulk(house_shipments: $house_shipments) {
      ...shipmentFields
      master_shipment {
        id
        total_number_of_packages
        gross_weight
        gross_volume
        volumetric_weight
        shipment_packages {
          ...packagesFields
        }
        shipment_product_ratings {
          ...productRatingFields
        }
      }
    }
  }
  ${shipmentFields}
`;

export const CREATE_BULK_SHIPMENT_FROM_PLANNED = gql`
  mutation create_bulk_shipment_from_planned(
    $shipments: [CreateBulkShipmentFromPlannedInputType!]!
    $customer_order_id: ID!
  ) {
    create_bulk_shipment_from_planned(
      shipments: $shipments
      customer_order_id: $customer_order_id
    ) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;

export const UPDATE_SHIPMENT_NEW = gql`
  mutation update_shipment_new(
    $id: ID!
    $shipment: CreateShipmentInput
    $shipment_document_master: ShipmentDocumentInput
    $shipment_document_house: ShipmentDocumentInput
  ) {
    update_shipment_new(
      id: $id
      shipment: $shipment
      shipment_document_master: $shipment_document_master
      shipment_document_house: $shipment_document_house
    ) {
      ...shipmentFields
    }
  }
  ${shipmentFields}
`;

export const ASSIGN_LOADS_TO_SHIPMENTS = gql`
  mutation assign_loads_to_shipments(
    $loads: [AssignLoadToShipmentInputType!]!
    $is_auto_generate_consignment_note: Boolean
    $is_add_new_house: Boolean
  ) {
    assign_loads_to_shipments(
      loads: $loads
      is_auto_generate_consignment_note: $is_auto_generate_consignment_note
      is_add_new_house: $is_add_new_house
    ) {
      id
      freight_type
      trade_type
      shipment_type
      master_shipment_id
      shipment_documents {
        id
        document_type
      }
      involved_branch {
        city {
          name
        }
      }
    }
  }
`;
