import React from 'react';
import { PrintPreviewDocType } from '@shipmnts/pixel-hub';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface PrintTransactionProp {
  docTypeId: string;
  docType: string;
  doc: ProductOrderValue;
  onClose?: () => void;
}

export default function PrintTransaction(props: PrintTransactionProp) {
  const { docType, docTypeId, doc, onClose } = props;

  if (!doc) return <></>;

  return (
    <PrintPreviewDocType
      docType={docType}
      resource_id={docTypeId}
      onClose={onClose}
      // emailProps={{
      //   templateActionName: `share_${doc?.type}_order`,
      //   fetchDocumentParents: [{ parent_ids: [doc.id], parent_type: 'product_order' }],
      //   companies_roles_mapping: doc.getAllPartiesRolesMapping(sessionData),
      // }}
    />
  );
}
