import { CustomIcon, getDateFromUnix, getDateTimeFromUnix } from '@shipmnts/pixel-hub';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import React from 'react';
import { collapsibleCard } from 'operations/modules/shipment/components/DetailLayout/Common/common';
import WarehouseTransactionTimlineRenderer from './WarehouseTransactionTimlinedTag';

interface WMSCreationDetailsProps {
  warehouseTransaction: WarehouseTransactionValue;
}

const WMSCreationDetails = (props: WMSCreationDetailsProps) => {
  const { warehouseTransaction } = props;
  const CreationDetails = [];
  CreationDetails.push({
    Status: <WarehouseTransactionTimlineRenderer transaction={warehouseTransaction} />,
  });
  CreationDetails.push({
    Warehouse: warehouseTransaction?.target_warehouse?.node_name,
  });
  if (warehouseTransaction?.type === 'incoming_shipment')
    CreationDetails.push({
      'Target Receipt Date': warehouseTransaction?.target_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.target_date)}</div>
      ) : null,
    });
  if (warehouseTransaction?.type === 'outgoing_shipment')
    CreationDetails.push({
      'Target Delivery Date': warehouseTransaction?.target_date ? (
        <div>{getDateTimeFromUnix(warehouseTransaction?.target_date)}</div>
      ) : null,
    });
  if (warehouseTransaction?.type === 'goods_transfer') {
    CreationDetails.push({
      'Transferred by': warehouseTransaction?.transaction_by
        ? [
            warehouseTransaction?.transaction_by?.first_name,
            warehouseTransaction?.transaction_by?.last_name,
          ]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    CreationDetails.push({
      'Transferred At': warehouseTransaction?.transaction_date
        ? getDateFromUnix(warehouseTransaction?.transaction_date)
        : null,
    });
    CreationDetails.push({
      Purpose: warehouseTransaction?.purpose_of_transfer,
    });
  } else {
    CreationDetails.push({
      'Created by': warehouseTransaction.created_by
        ? [warehouseTransaction.created_by.first_name, warehouseTransaction.created_by.last_name]
            .filter(Boolean)
            .join(' ')
        : '',
    });
    CreationDetails.push({
      'Created At': warehouseTransaction?.created_at
        ? getDateFromUnix(warehouseTransaction?.created_at)
        : null,
    });
  }

  return (
    <>
      {collapsibleCard({
        icon: <CustomIcon icon="Info" />,
        header: 'Creation Detail',
        data: CreationDetails,
      })}
    </>
  );
};

export default WMSCreationDetails;
