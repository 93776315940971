import React, { useCallback, useEffect, useRef } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import {
  Drawer,
  Row,
  Col,
  Form,
  message,
  GlobalViewLayout,
  DrawerFooter,
  Tag,
  Typography,
  Space,
  Flex,
  TabsProps,
  Tabs,
} from '@shipmnts/pixel-hub';
import { CompassOutlined } from '@ant-design/icons';
import { ShipmentValue } from 'operations/models/Shipment';
import { getGenericInitialValue } from 'operations/modules/orderManagement/CreateShipmentIMPEX/helpers';
import { useMutation } from '@apollo/client';
import { MERGE_SHIPMENT } from 'operations/graphql/shipment';
import { startCase } from 'lodash';

const { Text } = Typography;

const FilterSummary = ({ filters }: any) => {
  return (
    <Space size={'small'} direction="vertical">
      <Text>
        <strong>Filters:</strong>
      </Text>
      <Flex gap={5} wrap="wrap">
        {filters.map((filter: any, index: number) => {
          const values = filter.condition.values
            ? filter.condition.values
                .map((value: any) => startCase(value?.name || value?.registered_name || value))
                .join(', ')
            : filter.field_id === 'shipments_job_number'
            ? filter.condition.value
            : startCase(filter.condition.value);

          return (
            <>
              <Tag>
                <Space>
                  {startCase(filter.field_id.replace('shipments_', '').replace('_id', ''))}
                  <Tag color="success">{startCase(filter.condition.type)}</Tag>
                  <Text
                    ellipsis={{ tooltip: values }}
                    style={{ maxWidth: '120px', color: 'inherit' }}
                  >
                    <Text>{values}</Text>
                  </Text>
                </Space>
              </Tag>
            </>
          );
        })}
      </Flex>
    </Space>
  );
};

const getGlobalView = (shipment: ShipmentValue, fetchOrders = false) => {
  const globalView: any = {
    id: '1',
    name: 'Merge Shipment',
    color: '#eeeeee',
    doc_type_id: 'Shipment::Shipment',
    standard_report: true,
    definition: {
      sort: [],
      fields: [
        {
          id: shipment.shipment_type ? 'shipments_job_number' : 'shipments_shipment_booking_number',
          label: shipment.shipment_type ? 'Job Number' : 'Customer Order Number',
          width: 160,
          section: 'Basic',
          field_type: 'String',
          cell_renderer: {
            args: {
              id_field: 'id',
              doc_type_id: 'Shipment::Shipment',
            },
            function: 'link_render',
          },
          sortable: false,
          filterable: false,
          groupable: false,
          filter_options: '{}',
        },
        {
          id: 'shipments_port_of_discharge_id',
          label: 'Port Of Discharge',
          width: 160,
          section: 'Basic',
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'location_render' },
          sortable: false,
          filterable: false,
          groupable: false,
          filter_options: '{ "doc_type": "Global::Location" }',
        },
        {
          id: 'shipments_shipment_type',
          label: 'Shipment Type',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Dropdown',
          filter_options: '{ "values": ["consol", "direct", "back_to_back", "house", "general"] }',
        },
        {
          id: 'shipments_port_of_loading_id',
          label: 'Port Of Loading',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
        },
        {
          id: 'analytics_shipment_reports_shipper_id',
          label: 'Shipper',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
        },
        {
          id: 'analytics_shipment_reports_consignee_id',
          label: 'Consignee',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
        },
        {
          id: 'shipments_carrier_id',
          label: 'Shipping Line',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Global::Carrier"}',
        },
        {
          id: 'shipments_job_date',
          label: 'Job Date',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Date',
          cell_renderer: {},
          filter_options: '{}',
        },
        {
          id: 'shipments_customer_company_id',
          label: 'Customer',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'record_loader_render' },
          filter_options: '{"doc_type":"Network::Company"}',
        },
        {
          id: 'analytics_shipment_reports_origin_clearance_date',
          label: 'Origin Clearance Date',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'DateTime',
          cell_renderer: {},
          filter_options: '{}',
        },
        {
          id: 'shipments_port_of_discharge_id',
          label: 'Port Of Discharge',
          width: 160,
          section: 'Basic',
          sortable: false,
          filterable: false,
          groupable: false,
          field_type: 'Search',
          cell_renderer: { args: {}, function: 'location_render' },
          filter_options: '{ "doc_type": "Global::Location" }',
        },
      ],
      filters: [
        {
          field_id: 'shipments_port_of_discharge_id',
          condition: {
            type: 'in',
            values: [shipment?.port_of_discharge],
          },
        },
        {
          field_id: 'shipments_port_of_loading_id',
          condition: {
            type: 'in',
            values: [shipment?.port_of_loading],
          },
        },
        {
          field_id: 'shipments_customer_company_id',
          condition: {
            type: 'in',
            values: [shipment?.customer_company],
          },
        },
        {
          field_id: 'shipments_status',
          condition: {
            type: 'notIn',
            values: ['shipment_cancelled'],
          },
        },
        {
          field_id: 'shipments_accounting_status',
          condition: {
            type: 'notIn',
            values: ['closed', 'billing_closed'],
          },
        },
      ],
    },
    is_disabled: false,
    global: true,
  };

  if (shipment?.carrier) {
    globalView.definition.filters.push({
      field_id: 'shipments_carrier_id',
      condition: {
        type: 'in',
        values: [shipment?.carrier],
      },
    });
  }

  if (!shipment?.shipment_type || fetchOrders) {
    globalView.definition.filters.push({
      field_id: 'shipments_shipment_type',
      condition: {
        type: 'isNull',
        value: null,
      },
    });
  } else {
    globalView.definition.filters.push({
      field_id: 'shipments_shipment_type',
      condition: {
        type: 'equals',
        value: shipment?.shipment_type,
      },
    });
  }

  if (shipment?.job_number) {
    globalView.definition.filters.push({
      field_id: 'shipments_job_number',
      condition: {
        type: 'notEqual',
        value: shipment?.job_number,
      },
    });
  } else if (shipment?.shipment_booking_number) {
    globalView.definition.filters.push({
      field_id: 'shipments_shipment_booking_number',
      condition: {
        type: 'notEqual',
        value: shipment?.shipment_booking_number,
      },
    });
  }

  if (shipment?.voyage_number) {
    globalView.definition.filters.push({
      field_id: 'shipments_voyage_number',
      condition: {
        type: 'in',
        values: [shipment?.voyage_number],
      },
    });
  }
  if (shipment?.ocean_vessel) {
    globalView.definition.filters.push({
      field_id: 'shipments_ocean_vessel_id',
      condition: {
        type: 'in',
        values: [shipment?.ocean_vessel],
      },
    });
  }
  return globalView;
};

interface ShipmentListProps {
  shipment: ShipmentValue;
  gridRef: any;
  fetchOrders?: boolean;
  selectedTab: string; //taking it in props so that global view layout get re-rendered on tab change
  handleShipmentSelection: (shipment: ShipmentValue) => void;
}

const ShipmentList = React.memo(
  ({
    shipment,
    gridRef,
    fetchOrders = false,
    selectedTab,
    handleShipmentSelection,
  }: ShipmentListProps) => {
    const globalView = useRef(getGlobalView(shipment, fetchOrders));

    return (
      <Space direction="vertical" size={'middle'}>
        <FilterSummary filters={globalView.current.definition.filters} />
        <GlobalViewLayout
          key={selectedTab}
          gridRef={gridRef}
          doc_type_id={'Shipment::Shipment'}
          globalView={globalView.current}
          quick_filter_field_ids={[
            'analytics_shipment_reports_shipper_id',
            'analytics_shipment_reports_consignee_id',
            'shipments_carrier_id',
          ]}
          height="70vh"
          defaultFilters={[]}
          reportConfig={{
            rowSelection: 'single',
            onSelectionChanged: (params: any) => {
              const selectedRows = params.api.getSelectedRows();
              if (selectedRows.length > 0) {
                handleShipmentSelection(selectedRows[0]?.id);
              }
            },
          }}
          label="Shipment"
          searchPanelVisible={false}
        />
      </Space>
    );
  }
);

ShipmentList.displayName = 'ShipmentList';

const MergeShipment = React.memo(function CreateShipmentFromExportCustomerOrder(props: {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element {
  const { shipment, onClose, onSuccess } = props;
  const [form] = Form.useForm();
  const gridRef = useRef<GridOptions>();

  const [selectedTab, setSelectedTab] = React.useState('shipments');
  const [selectedShipmentId, setSelectedShipmentId] = React.useState<any>(null);

  const [mergeShipment, { data, error }] = useMutation(MERGE_SHIPMENT);

  const handleShipmentSelection = useCallback((selectedShipmentId: any) => {
    setSelectedShipmentId(selectedShipmentId);
  }, []);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (data?.merge_shipment?.message) {
      message.success('Shipment merged successfully');
      onClose();
      onSuccess();
    }
  }, [data, error, onClose, onSuccess]);

  useEffect(() => {
    form.setFieldsValue(getGenericInitialValue(shipment));
  }, [shipment, form]);

  const onSubmit = () => {
    const from_shipment_id = selectedShipmentId;
    const to_shipment_id = shipment?.id;
    if (!from_shipment_id) {
      message.error('Please Select Shipment to Merge');
      return;
    }
    mergeShipment({
      variables: {
        from_shipment_id,
        to_shipment_id,
      },
    });
  };

  const items: TabsProps['items'] = [
    {
      key: 'shipments',
      label: 'Shipments',
      children: (
        <ShipmentList
          shipment={shipment}
          gridRef={gridRef}
          selectedTab={selectedTab}
          handleShipmentSelection={handleShipmentSelection}
        />
      ),
    },
    {
      key: 'customer_orders',
      label: 'Customer Orders',
      children: (
        <ShipmentList
          shipment={shipment}
          gridRef={gridRef}
          selectedTab={selectedTab}
          handleShipmentSelection={handleShipmentSelection}
          fetchOrders={true}
        />
      ),
    },
  ];

  return (
    <Drawer
      placement="right"
      title={
        <div>
          <Row>
            <Col span={16}>
              <div
                style={{
                  color: '#353F52',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginBottom: '10px',
                }}
              >
                <CompassOutlined />
                <span style={{ marginLeft: '5px' }}>
                  <b>Merge Shipment</b>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      }
      onClose={onClose}
      open={true}
      headerStyle={{ padding: '16px 16px 0px 24px' }}
      width="80%"
      footer={
        <DrawerFooter
          saveText="Merge Shipment"
          closeText="Cancel"
          onClose={() => onClose && onClose()}
          onSave={onSubmit}
        />
      }
    >
      {shipment?.shipment_type ? (
        <Tabs
          defaultActiveKey="shipments"
          items={items}
          onChange={(key) => {
            setSelectedTab(key);
          }}
        />
      ) : (
        <ShipmentList
          shipment={shipment}
          gridRef={gridRef}
          selectedTab={selectedTab}
          handleShipmentSelection={handleShipmentSelection}
          fetchOrders={true}
        />
      )}
    </Drawer>
  );
});

export default MergeShipment;
