import React from 'react';
import { Row, Tag } from '@shipmnts/pixel-hub';
import { Link } from 'wouter';

import { containerSettingsMap, containerTypes } from 'operations/baseConstants';
import {
  startCase as _startCase,
  compact as _compact,
  sortBy as _sortBy,
  trim as _trim,
  invert as _invert,
  get as _get,
  uniq as _uniq,
} from 'lodash';
import { ICellRendererParams } from '@ag-grid-community/core';

import { CargoValue } from 'operations/models/Cargo';
import {
  ContainerQuantityType,
  ContainerRequestValue,
  ContainerSettingValue,
} from 'operations/models/ContainerRequest';
import { CarrierValue } from 'operations/models/Carrier';
import { ShipmentContainerValue, validContainers } from 'operations/models/ShipmentContainer';
import { OTOReportData } from './components/OTOReports/OTOReports';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { MasterHouseShipment } from './reportModels';
import {
  CREDIT_STATUS_DISPLAY,
  SHIPMENT_TYPE_BACK_TO_BACK,
  SHIPMENT_TYPE_CONSOL,
  SHIPMENT_TYPE_DIRECT,
} from '../shipment/constants';
import {
  COMPANY_STATUS_DISPLAY_NAMES,
  COMPANY_STATUS_HIGH_RISK,
  COMPANY_STATUS_MODERATE_RISK,
  COMPANY_STATUS_NO_CREDIT,
} from 'operations/models/Company';
import { COMPANY_STATUS_LEGAL_DISPUTE } from 'operations/baseConstants';

export const renderCommodity = (cargos: Array<CargoValue>) => {
  return cargos
    .map((cargo) => {
      return `${cargo.commodity_description}`;
    })
    .join(' , ');
};

export const renderUniqCommodity = (cargos: Array<CargoValue>) => {
  return _uniq(
    cargos.map((cargo) => {
      return `${cargo.commodity_description}`;
    })
  ).join(' , ');
};

export const renderContainerQuantityAndType = (
  container_requests: Array<ContainerRequestValue>,
  field: ContainerQuantityType
) => {
  const container_code_map = _invert(containerTypes);
  const response = (container_requests || [])
    .filter((cr) => cr[field] !== 0)
    .map((cr) => {
      return `${cr[field]} X ${cr.container_type ? container_code_map[cr.container_type] : ''}`;
    })
    .join(', ');

  return response;
};

export const renderCargoWtAndPackages = (cargos: CargoValue[]) => {
  const total_weight = cargos.reduce((sum, cargo) => {
    sum += cargo.gross_weight || 0;
    return sum;
  }, 0);
  const packages_hash: { [key: string]: number } = {};
  cargos.forEach((cargo) => {
    if (cargo.total_packages && cargo.package_type) {
      if (!packages_hash[cargo.package_type])
        packages_hash[cargo.package_type] = cargo.total_packages;
      else packages_hash[cargo.package_type] += cargo.total_packages;
    }
  });
  const packages_str = Object.keys(packages_hash)
    .map((pkg_type) => {
      return `${packages_hash[pkg_type]} ${pkg_type}`;
    })
    .join(', ');
  return `${total_weight} ${cargos?.[0]?.weight_unit} (${packages_str})`;
};

export const renderContainerQuantity = (
  container_requests: Array<ContainerRequestValue>,
  field: ContainerQuantityType
): number => {
  return container_requests.reduce(
    (sum: number, cr: ContainerRequestValue) => sum + (cr[field] || 0),
    0
  );
};

export const getContainerSetting = (props: {
  container_settings?: ContainerSettingValue;
  container_type_code?: string | null;
}) => {
  const { container_settings, container_type_code } = props;
  const is_reefer = Object.keys(containerSettingsMap.reefer).includes(container_type_code || '');
  const is_out_of_dimension = Object.keys(containerSettingsMap.out_of_dimension).includes(
    container_type_code || ''
  );
  let setting;

  if (is_reefer && container_settings?.is_active_reefer) {
    setting = `${
      container_settings.temperature !== undefined && container_settings.temperature !== null
        ? container_settings.temperature
        : ''
    } ${container_settings?.temperature_unit || ''}`;
    if (container_settings?.ventilation_requested)
      setting += `  Vent:${container_settings?.vent_setting || ''} ${
        container_settings.air_flow !== undefined && container_settings.air_flow !== null
          ? container_settings.air_flow
          : ''
      } ${_startCase(container_settings?.air_flow_unit || '')}`;
    if (container_settings?.humidity_control_requested)
      setting += `  Humidity:${
        container_settings.relative_humidity_percent !== undefined &&
        container_settings.relative_humidity_percent !== null
          ? container_settings.relative_humidity_percent
          : ''
      } %`;
  } else if (
    is_out_of_dimension &&
    (container_settings?.length || container_settings?.width || container_settings?.height)
  ) {
    setting = `${container_settings?.length || ''}x${container_settings?.width || ''}x${
      container_settings?.height || ''
    } ${container_settings?.lbh_unit || ''}`;
  }
  return setting;
};

export const renderContainerSettings = (container_requests: Array<ContainerRequestValue>) => {
  const containerSettingsArray = (container_requests || []).map((cr) => {
    const { container_settings, container_type_code } = cr;

    return getContainerSetting({ container_settings, container_type_code });
  });

  const containerSettings = _compact(containerSettingsArray).join(', ');

  return containerSettings || '';
};

export const renderContainerTemperature = (container_requests: Array<ContainerRequestValue>) => {
  const containerSettingsArray = (container_requests || []).map((cr) => {
    return getContainerTemperature(cr.container_type_code, cr.container_settings);
  });
  const containerSettings = _compact(containerSettingsArray).join(', ');

  return containerSettings || '-';
};

export const getContainerTemperature = (
  container_type_code?: string,
  container_settings?: ContainerSettingValue
) => {
  const is_reefer = Object.keys(containerSettingsMap.reefer).includes(container_type_code || '');
  let temperature;
  if (is_reefer && container_settings?.is_active_reefer)
    temperature = `${
      container_settings.temperature !== undefined && container_settings.temperature !== null
        ? container_settings.temperature
        : ''
    } ${container_settings?.temperature_unit || ''}`;
  return temperature;
};

export const renderPreferredCarriers = (preferred_carriers: Array<CarrierValue> | undefined) => {
  const response = (preferred_carriers || [])
    .map((cr) => {
      return cr.name || '';
    })
    .join(', ');
  return response || '';
};

export const calculateStatusWiseContainers = (
  shipment_containers: Array<ShipmentContainerValue>,
  key: 'allocated' | 'pickedup' | 'pickup_pending'
) => {
  const allocated: { [key: string]: number } = {},
    pickedup: { [key: string]: number } = {},
    pickup_pending: { [key: string]: number } = {};
  validContainers(shipment_containers).forEach((sc: ShipmentContainerValue) => {
    if (sc.container_type) {
      if (!allocated[sc.container_type]) allocated[sc.container_type] = 0;
      allocated[sc.container_type]++;

      if (sc.container_number) {
        if (!pickedup[sc.container_type]) pickedup[sc.container_type] = 0;
        pickedup[sc.container_type]++;
      } else {
        if (!pickup_pending[sc.container_type]) pickup_pending[sc.container_type] = 0;
        pickup_pending[sc.container_type]++;
      }
    }
  });

  const container_obj = { allocated, pickup_pending, pickedup };
  const container_code_map = _invert(containerTypes);
  const containers = container_obj[key];
  const status_wise_containers = Object.keys(containers)
    .map(
      (container_type) =>
        `${containers[container_type]}x ${container_code_map[container_type] || ''}`
    )
    .join(', ');

  return status_wise_containers;
};

export const renderRecentOtoStatus = (oceanTransportOrders: OTOReportData[]) => {
  const sorted_otos_by_valid_date = _sortBy(oceanTransportOrders, 'valid_till_date');
  const lastest_oto = sorted_otos_by_valid_date[0];
  return lastest_oto
    ? _trim(`${lastest_oto.status} ${lastest_oto.status_time_duration_in_words || ''}`)
    : '';
};

export const renderBookingRequestIdsOfShipment = (props: { value: BookingRequestValue[] }) => {
  const total_booking_requests = props?.value?.length;
  return (
    <Row>
      {props.value.map((br: BookingRequestValue, index: number) => {
        const { id, shipment_booking_number } = br;
        const text =
          index === total_booking_requests - 1
            ? shipment_booking_number
            : `${shipment_booking_number} ,`;
        return (
          <Link to={`~/view/booking_request/${id}`} key={id} style={{ cursor: 'pointer' }}>
            {text}
          </Link>
        );
      })}
    </Row>
  );
};

export const renderVoyageScheduleIds = (props: { value: string[] }) => {
  const total_voyage_schedule_portcalls = props.value.length;
  return (
    <Row>
      {props.value.map((id: string, index: number) => {
        const text = index === total_voyage_schedule_portcalls - 1 ? id : `${id} ,`;
        return (
          <Link to={`~/edit/voyage_schedule/${id}`} key={id} style={{ cursor: 'pointer' }}>
            {text}
          </Link>
        );
      })}
    </Row>
  );
};

export const getJobNumbersText = (props: { data?: MasterHouseShipment }) => {
  const master_shipment = props.data?.master_shipment;
  if (master_shipment?.job_number && master_shipment.shipment_type !== SHIPMENT_TYPE_CONSOL) {
    return master_shipment?.job_number || '';
  } else {
    return getHouseJobNumbersText({ data: { house_shipments: props.data?.house_shipments } });
  }
};

export const getHouseJobNumbersText = (props: { data?: MasterHouseShipment }) => {
  const house_shipments = props.data?.house_shipments || [];
  return house_shipments.map((val) => val.job_number).join(', ');
};

export const renderMBLNumber = (props: { data?: MasterHouseShipment }) => {
  const mbl_number = props.data?.master_shipment?.shipment_document_master?.shipment_number;
  const openMBL = (shipment_id: string, shipment_document_id: string) =>
    window.open(
      `${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment_id}/documents_latest/${shipment_document_id}`,
      '_self'
    );
  const shipment_document = props.data?.master_shipment?.shipment_document_master;
  const divStyle = shipment_document?.document_id ? { color: '#3B72D5', cursor: 'pointer' } : {};
  return (
    <Row>
      <div
        {...divStyle}
        key={shipment_document?.id}
        onClick={() => {
          if (shipment_document?.document_id)
            openMBL(shipment_document?.id, shipment_document?.document_id);
        }}
      >
        {mbl_number}
      </div>
    </Row>
  );
};

export const renderHBLNumbers = (props: { data?: MasterHouseShipment }) => {
  const hbl_numbers = (props.data?.house_shipments || [])
    .concat(props.data?.master_shipment || [])
    .filter((sh) => Boolean(sh?.shipment_document_house?.shipment_number));
  const openHBL = (shipment_id: string, shipment_document_id: string) =>
    window.open(
      `${process.env.SHIPMNTS_WEB_URL}/shipment/${shipment_id}/documents_latest/${shipment_document_id}`,
      '_self'
    );
  return (
    <Row>
      {hbl_numbers.map((sh, index: number) => {
        const shipment_document = sh.shipment_document_house;
        const divStyle = shipment_document.document_id
          ? { color: '#3B72D5', cursor: 'pointer' }
          : {};
        const text =
          index === hbl_numbers.length - 1
            ? shipment_document?.shipment_number
            : `${shipment_document?.shipment_number}, `;
        return (
          <div
            {...divStyle}
            key={shipment_document.id}
            onClick={() => {
              if (shipment_document.document_id) openHBL(sh.id, shipment_document.document_id);
            }}
          >
            {text}
          </div>
        );
      })}
    </Row>
  );
};

export const getHBLNumbersText = (props: { data?: MasterHouseShipment }) => {
  return (props.data?.house_shipments || [])
    .concat(props.data?.master_shipment || [])
    .filter((sh) => Boolean(sh?.shipment_document_house?.shipment_number))
    .map((sh) => sh?.shipment_document_house?.shipment_number)
    .join(', ');
};

export const getMBLNumberText = (props: { data?: MasterHouseShipment }) => {
  return props.data?.master_shipment?.shipment_document_master?.shipment_number;
};

export const renderLinkCell = (props: ICellRendererParams) => {
  const colDef = props?.colDef;
  const cellRendererParams = colDef?.cellRendererParams;
  const id = _get(props?.data, _get(cellRendererParams, 'idField'));
  const display = props?.value;
  if (id) {
    return (
      <span>
        <Link
          to={`${cellRendererParams?.redirectToBase}/${id}`}
          key={id}
          style={{ cursor: 'pointer' }}
        >
          {display}
        </Link>
      </span>
    );
  } else {
    return <span>{display}</span>;
  }
};

export const renderBookingRequestId = (props: ICellRendererParams) => {
  return <span className="centered">{renderLinkCell(props)}</span>;
};

export const isCargoHazardous = (cargos: CargoValue[]): string => {
  if (cargos) {
    const hazardousCargo = cargos.find((cargo: CargoValue) => {
      return cargo?.cargo_properties?.is_hazardous;
    });
    return hazardousCargo ? 'Hazardous' : 'Non Hazardous';
  } else {
    return '';
  }
};

export const renderHazardousCargo = (props: ICellRendererParams) => {
  const cellRendererParams = props?.colDef?.cellRendererParams;
  const cargos = _get(props?.data, _get(cellRendererParams, 'cargoField'));
  if (cargos) {
    const hazardousCargo = cargos.find((cargo: CargoValue) => {
      return cargo?.cargo_properties?.is_hazardous;
    });
    return (
      <div>
        {hazardousCargo ? (
          <>
            <span style={{ marginRight: '5px' }}>Hazardous</span>
            <img className="svg-small" src={'/assets/icons/hazardous.svg'} alt="hazardous" />
          </>
        ) : (
          <span>Non Hazardous</span>
        )}
      </div>
    );
  } else {
    return '';
  }
};

interface Redirtc extends ICellRendererParams {
  navigate: any;
}
export const redirectToShipmntsWeb = (props: Redirtc) => {
  const { navigate } = props;
  const colDef = props?.colDef;
  const cellRendererParams = colDef?.cellRendererParams;
  const shipmentId = _get(props?.data, _get(cellRendererParams, 'shipmentIdField'));
  const display = props?.value;
  if (shipmentId) {
    return (
      <span
        key={shipmentId}
        onClick={() => navigate(`~/view/shipment/${shipmentId}/documents`)}
        style={{ color: '#3B72D5', cursor: 'pointer' }}
      >
        {display}
      </span>
    );
  } else {
    return <span>{display}</span>;
  }
};

export const renderContainerTypes = (shipment_containers: ShipmentContainerValue[]) => {
  const containers = (shipment_containers || []).reduce(
    (obj: { [key: string]: number }, container) => {
      if (container.container_type_code) {
        if (!obj.hasOwnProperty(container.container_type_code))
          obj[container.container_type_code] = 1;
        else obj[container.container_type_code] += 1;
      }
      return obj;
    },
    {}
  );
  return Object.keys(containers)
    .map((key: string) => `${containers[key]} X ${key}`)
    .join(', ');
};

export const renderNotifyParty = (
  shipment_type: string,
  notify_party_1?: string,
  notify_party_1_house?: string
) => {
  let notifty_party = notify_party_1_house;
  if (
    [SHIPMENT_TYPE_DIRECT, SHIPMENT_TYPE_CONSOL].includes(shipment_type) ||
    (shipment_type === SHIPMENT_TYPE_BACK_TO_BACK && !Boolean(notify_party_1_house))
  ) {
    notifty_party = notify_party_1;
  }
  return notifty_party;
};

export const creditRiskStatusRenderer = (props: ICellRendererParams) => {
  const credit_status = props?.value || '';
  let color = 'default';
  if (
    [COMPANY_STATUS_HIGH_RISK, COMPANY_STATUS_LEGAL_DISPUTE, COMPANY_STATUS_NO_CREDIT].includes(
      credit_status
    )
  ) {
    color = 'error';
  } else if (COMPANY_STATUS_MODERATE_RISK === credit_status) {
    color = 'warning';
  }
  return <Tag color={color}>{COMPANY_STATUS_DISPLAY_NAMES[credit_status] || ''}</Tag>;
};

export const shipmentCreditStatusRenderer = (props: ICellRendererParams) => {
  const credit_status: string = props?.value || '';
  const display = CREDIT_STATUS_DISPLAY[credit_status] || {};

  return <Tag color={display['color'] || 'default'}>{display['label'] || ''}</Tag>;
};
