import { gql } from '@apollo/client';

export const UPSERT_CONTRACT = gql`
  mutation upsert_contract(
    $contract: ContractInputType!
    $contract_id: ID
    $check_duplicate: Boolean
  ) {
    # need ID outside??
    upsert_contract(
      contract: $contract
      contract_id: $contract_id
      check_duplicate: $check_duplicate
    ) {
      contract {
        id
        rate_type
        load_type
        applicability
      }
      duplicate_contract
    }
  }
`;
