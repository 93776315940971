/**
 * Actions for connection request:
 *      - accept connection req
 *      - reject connection req
 *      - block
 *      - remove
 *      - unblock
 */
import { SessionDataValue } from 'operations/models/SessionData';
import { Action } from '../models';
import { NEGATIVE_TYPE, SECONDARY_TYPE } from '../constants';
import React from 'react';
import {
  performAcceptConnectionRequest,
  performBlockConnectionRequest,
  performRejectConnectionRequest,
  performRemoveConnectionRequest,
  performUnBlockConnectionRequest,
} from './companyActionRenderer';
import { CustomIcon } from '@shipmnts/pixel-hub';

const acceptConnection = (company: any, sessionData?: any): Action => {
  const connectionRequest = company?.connection_request;
  const isIncomingReq =
    sessionData?.company_account?.id === connectionRequest?.to_company_account?.id;
  return {
    key: 'accept_connection',
    type: SECONDARY_TYPE,
    displayComponent: 'Accept Connection',
    isEnable: isIncomingReq && connectionRequest?.status === 'requested',
    icon: <CustomIcon height={15} width={15} icon="FileTimesIcon" />,
    performAction: performAcceptConnectionRequest,
    description: 'Accept Connections',
  };
};

const rejectConnection = (company: any): Action => {
  const connectionRequest = company?.connection_request;
  return {
    key: 'reject_connection',
    type: NEGATIVE_TYPE,
    displayComponent: 'Reject Connection',
    isEnable: connectionRequest?.status === 'requested',
    icon: <CustomIcon height={15} width={15} icon="FileTimesIcon" />,
    performAction: performRejectConnectionRequest,
    description: 'Reject Connections',
  };
};

const blockConnection = (company: any): Action => {
  const connectionRequest = company?.connection_request;
  return {
    key: 'block_connection',
    type: NEGATIVE_TYPE,
    displayComponent: 'Block Connection',
    isEnable: connectionRequest?.status && connectionRequest?.status !== 'blocked',
    icon: <CustomIcon height={15} width={15} icon="FileTimesIcon" />,
    performAction: performBlockConnectionRequest,
    description: 'Block Connections',
  };
};

const unBlockConnection = (company: any): Action => {
  const connectionRequest = company?.connection_request;
  return {
    key: 'unblock_connecion',
    type: SECONDARY_TYPE,
    displayComponent: 'Unblock Connection',
    isEnable: connectionRequest?.status === 'blocked',
    icon: <CustomIcon height={15} width={15} icon="FileTimesIcon" />,
    performAction: performUnBlockConnectionRequest,
    description: 'Unblock Connections',
  };
};

const removeConnection = (company: any): Action => {
  const connectionRequest = company?.connection_request;
  return {
    key: 'remove_connection',
    type: NEGATIVE_TYPE,
    displayComponent: 'Remove',
    isEnable: connectionRequest?.status === 'accepted',
    icon: <CustomIcon height={15} width={15} icon="FileTimesIcon" />,
    performAction: performRemoveConnectionRequest,
    description: 'Remove Connection',
  };
};

export const getConnectionRequestActions = (data: any, sessionData: SessionDataValue) => {
  return [
    acceptConnection(data, sessionData),
    rejectConnection(data),
    blockConnection(data),
    unBlockConnection(data),
    removeConnection(data),
  ];
};
