import { Form, InputNumber, Modal, Table, Typography } from '@shipmnts/pixel-hub';
import { useSession } from 'common';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { renderContainerSettings } from 'operations/modules/reports/oldCellRenderers';
import { TRADE_TYPE_EXPORT } from 'operations/modules/shipment/constants';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';
import React from 'react';
import { useLocation } from 'wouter';
const { Text } = Typography;

interface AssignContainerQtyProps {
  oto: OceanTransportOrderValue;
  onSuccess: () => void;
  onClose: () => void;
}

const columns: any[] = [
  {
    title: 'Container Type',
    dataIndex: 'container_type',
    width: '30%',
    render: function render(text: string) {
      return <Text className="primary-color">{text}</Text>;
    },
  },
  {
    title: 'Available Quantity',
    dataIndex: 'quantity_unfulfilled',
  },
  {
    title: 'Allocate Quantity',
    dataIndex: 'quantity_allocated',
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return (
        <Form.Item
          style={{ margin: 0 }}
          name={['container_requests', record.id || '']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            disabled={!record.quantity}
            min={0}
            max={record.quantity_unfulfilled || 0}
            precision={0}
            required
          />
        </Form.Item>
      );
    },
  },
  {
    title: 'Settings',
    dataIndex: 'container_settings',
    render: function render(text: string, record: ContainerRequestValue, index: number) {
      return <span>{renderContainerSettings([record])}</span>;
    },
  },
];

export default function AssignContainerQty(props: AssignContainerQtyProps) {
  const { oto, onSuccess, onClose } = props;
  const [form] = Form.useForm();
  const sessionData = useSession();
  const { 1: navigate } = useLocation();
  return (
    <Modal
      title="Allocate Container Qty To Shipment"
      open={true}
      style={{ width: '700px', height: '400px' }}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={(values: any) => {
          navigate(
            `~/form/new_shipment/new?load_type=fcl&from_oto=${oto.id}&freight_forwarding_shipment=true`,
            {
              state: {
                oceanTransportOrders: JSON.stringify([oto]),
                initialValue: JSON.stringify({
                  ...oto,
                  customer_company: null,
                  customer_address: null,
                  shipment_parties: [
                    {
                      name: 'origin_agent',
                      party_company: sessionData?.company_account?.default_company,
                    },
                    {
                      name: 'freight_forwarding_agent',
                      party_company: sessionData?.company_account?.default_company,
                    },
                  ],
                  sales_agent: oto.sales_person,
                  involved_branch: oto.booked_by_branch,
                  freight_type: FREIGHT_TYPE_OCEAN,
                  trade_type: TRADE_TYPE_EXPORT,
                  load_type: oto.load_type,
                  shipment_container_quantity: (oto.container_requests || []).map((cr) => ({
                    ...cr,
                    quantity: values.container_requests[cr.id],
                  })),
                }),
              },
            }
          );

          onSuccess && onSuccess();
        }}
        initialValues={{
          container_requests: oto.container_requests?.reduce((container_requests_value, cr) => {
            container_requests_value[cr.id] = cr.quantity;
            return container_requests_value;
          }, {}),
        }}
      >
        <Table
          dataSource={oto.container_requests}
          columns={columns}
          pagination={false}
          rowKey={(record: ContainerRequestValue) => record.id || ''}
          size="small"
        />
      </Form>
    </Modal>
  );
}
