import React, { useMemo } from 'react';
import { CompanyDetailsCard } from '../Cards/CompanyDetailsCard';
import { ProspectingDetailsCard } from '../Cards/ProspectingDetailsCard';
import { useCompanyView } from '../CompanyView';
import { TaskCard } from '../Cards/TaskCard';
import { TeamCard } from '../Cards/TeamCard';

export const OverviewTab = () => {
  const { is_customer, is_lead, company } = useCompanyView();
  const isSelfCompany = useMemo(() => company?.company_group === 'Self', [company]);
  return (
    <div className="responsive-card-grid">
      {!isSelfCompany && <ProspectingDetailsCard />}
      <CompanyDetailsCard />
      <TaskCard />
      {(is_customer || is_lead) && <TeamCard />}
    </div>
  );
};
