import { Col, Form, FormInstance, Input, InputNumber, Row } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';
import { FREIGHT_TYPE_AIR, FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';
import React from 'react';

interface CargoDetailsProps {
  form: FormInstance;
}

export const CargoDetailsClearance = (props: CargoDetailsProps) => {
  const { form } = props;
  const loadType = Form.useWatch('load_type', form);
  const freightType = Form.useWatch('freight_type', form);
  return (
    <>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="commodity_description" label="Commodity Description">
            <Input.TextArea rows={2} placeholder="Commodity Description" />
          </Form.Item>
        </Col>
        {freightType === FREIGHT_TYPE_AIR && (
          <>
            <Col span={6}>
              <Form.Item name={['shipment_product_ratings', 0, 'quantity']} label="No. of Pcs">
                <InputNumber style={{ width: '100%' }} min={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={['shipment_product_ratings', 0, 'chargeable_weight']}
                label="Chargeable Wt"
              >
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={['shipment_product_ratings', 0, 'gross_weight']} label="Gross Wt">
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
          </>
        )}
        {loadType === LOAD_TYPE_LCL && freightType === FREIGHT_TYPE_OCEAN && (
          <>
            <Col span={6}>
              <Form.Item name="total_number_of_packages" label="Total Pieces">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="gross_weight" label="Gross Weight">
                <InputNumber style={{ width: '100%' }} addonAfter="kgs" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="net_weight" label="Net Weight">
                <InputNumber style={{ width: '100%' }} addonAfter="kgs" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="gross_volume" label="Gross Volume">
                <InputNumber style={{ width: '100%' }} addonAfter="cbm" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="chargeable_weight" label="Chargeable Volume">
                <InputNumber style={{ width: '100%' }} addonAfter="cbm" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
