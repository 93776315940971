/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { MutableRefObject, useEffect, useState } from 'react';
import { Space, Button, message, Dropdown } from '@shipmnts/pixel-hub';
import { ShipmentValue } from 'operations/models/Shipment';
import { PlusOutlined } from '@shipmnts/pixel-hub';
import {
  ShipmentEstimateValue,
  useShipmentEstimateStore,
} from 'operations/models/ShipmentEstimate';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { SAVE_SHIPMENT_ESTIMATES } from 'operations/graphql/shipmentEstimate';
import { get as _get } from 'lodash';
import { errorMessageHandlerGraphQLString } from 'common';
import { CHARGE_AMOUNT_PREPAID } from 'operations/modules/shipment/constants';
import ShipmentEstimatesQuotationsModal from './ShipmentEstimatesQuotationsModal';
import AwbChargesModal from './AwbChargesModal';
import { GridOptions } from '@ag-grid-community/core';
import { addNewEstimateRow } from './helper';
import type { MenuProps } from '@shipmnts/pixel-hub';
import { RateExplorerModal, convertEstimateInQuoteCurrency } from 'sales_hub';

type MenuItem = Required<MenuProps>['items'][number];
const QUOTATION_MODAL = 'quotation';
const AWB_MODAL = 'awb';
const RATE_EXPLORER = 'rate_explorer';
interface EstimateActionsType {
  shipment: ShipmentValue;
  gridRef: MutableRefObject<GridOptions | undefined>;
}

export default function EstimateActions(props: EstimateActionsType) {
  const { shipment, gridRef } = props;
  const { store } = useShipmentEstimateStore();
  const [modalState, setModalState] = useState<string | undefined>(undefined);

  const shipment_document = shipment
    ? shipment.getShipmentDocument(shipment.getShipmentDocumentType())
    : undefined;

  const [saveEstimates, { data, error }] = useMutation(SAVE_SHIPMENT_ESTIMATES);
  const { company_account } = useSession();

  useEffect(() => {
    if (data?.save_shipment_estimates_new) {
      const estimates = _get(data, 'save_shipment_estimates_new', []);
      if (estimates.length > 0) {
        store.addEstimates(estimates);
        message.success('Item added successfully');
      }
    }
    if (error) {
      message.error(errorMessageHandlerGraphQLString(error));
    }
  }, [data, error, store]);

  const addItemsToEstimates = async (estimates: Partial<ShipmentEstimateValue>[]) => {
    const default_estimate: Partial<ShipmentEstimateValue> = {
      customer_company_id: shipment.customer_company?.id,
      buy_rate: 0,
      buy_exchange_rate: 1,
      buy_terms: CHARGE_AMOUNT_PREPAID,
      buy_currency: company_account.default_currency,
      sell_rate: 0,
      sell_exchange_rate: 1,
      sell_terms: CHARGE_AMOUNT_PREPAID,
      sell_currency: company_account.default_currency,
      shipment_id: shipment.id,
    };

    if (estimates.length > 0) {
      const newEstimates: Partial<ShipmentEstimateValue>[] = [];
      estimates.forEach((est) => {
        if (est) newEstimates.push({ ...default_estimate, ...est });
      });
      if (shipment.id !== 'new')
        await saveEstimates({
          variables: { shipment_estimates: newEstimates },
        });
    }
  };

  const getItems = () => {
    let items: MenuItem[] = [];

    // eslint-disable-next-line no-lone-blocks
    {
      shipment &&
      shipment.isExportShipment() &&
      shipment.isAirShipment() &&
      shipment_document &&
      shipment_document.document_id
        ? [
            (items = items.concat([
              {
                key: 'sales_quotation',
                onClick: () => {
                  setModalState(QUOTATION_MODAL);
                },
                label: 'Sales Quotation',
              },

              {
                key: 'awb',
                onClick: () => {
                  setModalState(AWB_MODAL);
                },
                label: 'AWB',
              },
            ])),
          ]
        : [
            items.push({
              key: 'sales_quotation',
              onClick: () => {
                setModalState(QUOTATION_MODAL);
              },
              label: 'Sales Quotation',
            }),
          ];
    }

    if (shipment.id !== 'new')
      items.push({
        key: 'rate_explorer ',
        onClick: () => {
          setModalState(RATE_EXPLORER);
        },
        label: 'Rate Explorer ',
      });

    return items;
  };

  return (
    <Space>
      <Button
        icon={<PlusOutlined />}
        disabled={shipment.isAccountingClosed()}
        onClick={() => addNewEstimateRow(store, shipment, gridRef, company_account)}
        size="small"
        ghost
        type="primary"
      >
        Add Charge
      </Button>
      <Dropdown
        menu={{ items: getItems() }}
        placement="topLeft"
        disabled={shipment.isAccountingClosed()}
      >
        <Button size="small" icon={<PlusOutlined />}>
          Get Items From
        </Button>
      </Dropdown>
      {modalState === QUOTATION_MODAL && (
        <ShipmentEstimatesQuotationsModal
          shipment={shipment}
          onClose={() => setModalState(undefined)}
          addItemsToEstimates={addItemsToEstimates}
        />
      )}
      {modalState === AWB_MODAL && (
        <AwbChargesModal
          shipment={shipment}
          onClose={() => setModalState(undefined)}
          addItemsToEstimates={addItemsToEstimates}
        />
      )}
      {modalState === RATE_EXPLORER && (
        <RateExplorerModal
          resource={shipment}
          onClose={() => setModalState(undefined)}
          addEstimate={(estimates) => {
            convertEstimateInQuoteCurrency(company_account.default_currency, estimates).then(
              (quote_estimates) => {
                const new_estimates = quote_estimates.map((est) => {
                  return {
                    ...est,
                    inquiry_option_id: undefined,
                    buy_status: undefined,
                    sell_status: undefined,
                    total_buy_amount: undefined,
                    total_sell_amount: undefined,
                    supplier_company: undefined,
                  };
                });
                if (new_estimates && new_estimates.length > 0)
                  addItemsToEstimates(new_estimates as Partial<ShipmentEstimateValue>[]);
              }
            );
          }}
        />
      )}
    </Space>
  );
}
