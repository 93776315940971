import { Drawer, DrawerFooter } from '@shipmnts/pixel-hub';

import React, { useRef } from 'react';
import { useLocation } from 'wouter';
import { GridOptions } from '@ag-grid-community/core';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { WarehouseTransactionProductValue } from 'operations/models/WarehouseTransactionProduct';
import { omit } from 'lodash';
import StockSummaryTable from './StockSummaryTable';

const FetchStockSummaryDrawer = (props: {
  transaction?: WarehouseTransactionValue;
  nextTransaction?: string;
  purposeOfTransfer?: string;
  setAllocatedProducts?: (value: WarehouseTransactionProductValue[]) => void;
  isVisible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  setIsVisible?: (value: boolean) => void;
}) => {
  const {
    transaction,
    purposeOfTransfer,
    // nextTransaction,
    onClose,
    setAllocatedProducts,
    isVisible,
    setIsVisible,
  } = props;
  const { 1: navigate } = useLocation();

  const closeDrawer = () => {
    onClose && onClose();
    setIsVisible && setIsVisible(false);
  };
  const onSaveDrawer = () => {
    const selectedProducts: any[] = [];
    gridRef?.current?.api?.getSelectedNodes().forEach((node: any) => {
      selectedProducts.push(node?.data);
    });
    if (selectedProducts.length === 0) {
      gridRef?.current?.api?.forEachNode((node: any) => {
        if (node?.data?.qty > 0) selectedProducts.push(node?.data);
      });
    }
    const products: any[] = selectedProducts.map((product) => {
      return {
        ...omit(product, ['branch', 'customer_company', 'product', 'warehouse_unit']),
        product_id: product?.product?.id,
        from_location: product?.warehouse_unit,
      };
    });
    if (!!setAllocatedProducts) setAllocatedProducts(products);
    else {
      const value = {
        ...transaction,
        purpose_of_transfer: purposeOfTransfer,
        warehouse_transaction_products: products,
      };
      navigate(`~/form/goods_transfer/new`, {
        state: {
          transaction: JSON.stringify(value),
        },
      });
    }
    closeDrawer();
  };

  const gridRef = useRef<GridOptions>();
  return (
    <Drawer
      title={'Stock Ledger'}
      width={'60%'}
      onClose={closeDrawer}
      open={isVisible}
      footer={<DrawerFooter saveText="Add Products" onSave={onSaveDrawer} onClose={closeDrawer} />}
    >
      <StockSummaryTable
        rowSelection={'multiple'}
        gridRef={gridRef}
        transaction={transaction}
        purposeOfTransfer={purposeOfTransfer}
      />
    </Drawer>
  );
};

export default FetchStockSummaryDrawer;
