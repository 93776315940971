import React from 'react';
import { collapsibleCard } from '../../Common/common';
import { useShipmentDetail } from '../../ShipmentDetailLayout';
import { get as _get } from 'lodash';
import { Link } from 'wouter';
import { CustomIcon } from '@shipmnts/pixel-hub';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';

const BookingDetails = () => {
  const { shipment } = useShipmentDetail();
  if (!shipment) {
    return <></>;
  }
  const oceanTransportOrderId = _get(shipment, 'ocean_transport_order_id');
  const bookingNumber = shipment.getOtoBookingFromShipment()?.booking_number;
  const commondityDesc = _get(shipment, 'commodity_description');
  const carrierProduct = _get(shipment, 'carrier_product');
  const bookingDetails: { [key: string]: string | React.ReactNode } = {
    'Carrier Name': _get(shipment, 'carrier.name', '-'),
  };

  if (bookingNumber) {
    bookingDetails['Booking Number#'] = oceanTransportOrderId ? (
      <Link to={`~/view/booking_order/${oceanTransportOrderId}`}>{bookingNumber}</Link>
    ) : (
      <strong>{bookingNumber}</strong>
    );
  }
  if (shipment.isAirShipment()) bookingDetails['Air Product'] = carrierProduct;
  if (commondityDesc) {
    bookingDetails['Commodity Description'] = commondityDesc;
  }
  bookingDetails['Gross Wt'] = (
    <strong>{`${shipment.gross_weight || 0} ${shipment.weight_unit}`}</strong>
  );
  if (shipment.isOceanShipment()) {
    bookingDetails['Gross Vol'] = (
      <span className="font-15">{`${shipment.gross_volume || 0} ${shipment.volume_unit}`}</span>
    );
    let allocatedQty = 0;
    shipment.ocean_transport_orders?.forEach((oto: OceanTransportOrderValue) => {
      (oto.container_requests || []).forEach((cr: ContainerRequestValue) => {
        allocatedQty += cr.quantity_fulfilled || 0;
      });
    });
    bookingDetails['Requested Qty'] = shipment.shipment_containers?.length || 0;
    bookingDetails['Allocated Qty'] = allocatedQty || 0;
    bookingDetails['Pending Qty'] = (shipment.shipment_containers?.length || 0) - allocatedQty;
  }
  if (shipment.isAirShipment()) {
    bookingDetails['Chargable Wt'] = (
      <span className="font-15">
        <strong>{`${shipment.chargeable_weight} ${shipment.weight_unit}`}</strong>
      </span>
    );
    bookingDetails['Volumetric Wt'] = (
      <span className="font-15">
        <strong> {`${shipment.volumetric_weight} ${shipment.weight_unit}`}</strong>
      </span>
    );
  }
  bookingDetails['No of Packages'] = _get(shipment, 'total_number_of_packages', 0);
  if (shipment.isFcl())
    bookingDetails['Containers'] = shipment.getContainerSummary('container_type');
  return (
    <>
      {shipment.shipment_type !== 'general' &&
        collapsibleCard({
          icon: <CustomIcon icon="ShipIconNew" />,
          header: 'Booking Details',
          data: bookingDetails,
        })}
    </>
  );
};

export default BookingDetails;
