import { gql } from '@apollo/client';

export const routingLegFields = gql`
  fragment routingLegFields on RoutingLegObjectType {
    id
    sequence_number
    estimated_time_of_arrival
    estimated_time_of_departure
    actual_time_of_arrival
    actual_time_of_departure
    mode_of_transit
    routing_type
    voyage_number
    vessel {
      flag_id
      imo
      mmsi
      name
      vessel_type
    }
    global_carrier {
      id
      carrier_type
      iata_awb_prefix
      iata_carrier_code
      name
      standard_alpha_carrier_code
      meta_information
    }
    origin {
      id
      tags
      location {
        id
        name
        unlocode
        city_code
        state_code
        country_code
        is_customs_location
        type
      }
      address {
        id
        name
        city_name
        print_address
      }
      company {
        id
        registered_name
      }
      terminal {
        id
        name
      }
      waiting_time
      waiting_time_unit
    }
    destination {
      id
      tags
      location {
        id
        name
        unlocode
        city_code
        state_code
        country_code
        is_customs_location
        type
      }
      address {
        id
        name
        city_name
        print_address
      }
      company {
        id
        registered_name
      }
      terminal {
        id
        name
      }
      waiting_time
      waiting_time_unit
    }
    voyage_schedule_id
  }
`;
