import { types, Instance, IAnyModelType } from 'mobx-state-tree';
import {
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
  TRADE_TYPE_DOMESTIC,
  TRADE_TYPE_CROSS_TRADE,
} from 'operations/modules/shipment/constants';
import RoutingLeg from './RoutingLeg';

const ShipmentRoute = types
  .model({
    id: types.identifier,
    name: types.maybe(types.maybeNull(types.string)),
    created_at: types.maybe(types.maybeNull(types.number)),
    load_type: types.maybeNull(types.string),
    trade_type: types.maybe(
      types.maybeNull(
        types.enumeration([
          TRADE_TYPE_EXPORT,
          TRADE_TYPE_IMPORT,
          TRADE_TYPE_DOMESTIC,
          TRADE_TYPE_CROSS_TRADE,
        ])
      )
    ),
    movement_mode: types.maybe(
      types.maybeNull(
        types.enumeration([
          'container_stuffing',
          'container_destuffing',
          'airport_delivery',
          'airport_pickup',
          'pickup_and_drop',
          'container_pickup_and_drop',
        ])
      )
    ),
    routing_legs: types.array(types.late((): IAnyModelType => RoutingLeg)),
    is_disabled: types.maybe(types.maybeNull(types.boolean)),
  })

  .actions((shipmentRoute) => ({}));

export type ShipmentRouteValue = Instance<typeof ShipmentRoute>;

export default ShipmentRoute;
