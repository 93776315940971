import { gql } from '@apollo/client';
import { productFields } from 'operations/graphql/item';
import { warehouseUnitFields } from './warehouseUnit';
import { branchAccountFields } from 'operations/graphql/shipment';
import { minimumCompanyFields } from 'network/graphql/company';
import { warehouseTransactionFields } from './warehouseTransaction';

export const stockLedgerFields = gql`
  fragment stockLedgerFields on StockLedgerSummaryObjectType {
    id
    branch {
      ...branchAccountFields
    }
    customer_company {
      ...minimumCompanyFields
    }
    product {
      ...productFields
    }
    warehouse_unit {
      ...warehouseUnitFields
    }
    product_code
    product_name
    packing_type
    qty
    balance
    available_qty
    gross_weight
    gross_volume
    invoice_number
    container_number
    serial_number
    customs_ref_code
    batch_number
    lot_number
    pallet_no
    country_of_origin
    shipment_document_number
    customer_ref_code
    exp_date
    mfg_date
    per_pack_weight
    per_piece_volume
    receipt_number
  }
  ${minimumCompanyFields}
  ${productFields}
  ${warehouseUnitFields}
  ${branchAccountFields}
`;

export const FETCH_STOCK_SUMMARY = gql`
  query fetch_stock_summary(
    $transaction_id: ID
    $filters: StockLedgerFilterType
    $sort: [StockLedgerSortType!]
  ) {
    fetch_stock_summary(transaction_id: $transaction_id, filters: $filters, sort: $sort) {
      ...stockLedgerFields
    }
  }
  ${stockLedgerFields}
`;

export const FETCH_STOCK_LEDGER = gql`
  query fetch_stock_ledger($filters: StockLedgerFilterType, $sort: [StockLedgerSortType!]) {
    fetch_stock_ledger(filters: $filters, sort: $sort) {
      total
      opening
      closing
      query_data {
        transaction_date
        warehouse_transaction {
          ...warehouseTransactionFields
        }
        product {
          ...productFields
        }
        warehouse_unit {
          ...warehouseUnitFields
        }
        customer_company {
          ...minimumCompanyFields
        }
        product_name
        product_code
        qty
        balance
        gross_volume
        gross_weight
      }
    }
  }
  ${minimumCompanyFields}
  ${warehouseTransactionFields}
  ${productFields}
  ${warehouseUnitFields}
`;
