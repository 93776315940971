import React, { useMemo } from 'react';
import { Row, Col, Radio, Select, Form, Input, DatePicker } from '@shipmnts/pixel-hub';
import { LOAD_TYPES } from 'operations/baseConstants';
import {
  SHIPMENT_TYPE_HOUSE,
  SHIPMENT_TYPE_CONSOL,
  CONSOLIDATION_TYPES,
  SELF_CONSOLIDATION_KEY,
  ShipmentType,
  SHIPPING_LINE_SERVICE_TYPES,
  BOOKING_THROUGH_TYPES,
  BUSINESS_TYPE_SUBAGENT,
  ROW_GUTTER,
} from 'operations/modules/shipment/constants';
import { AddressCompanySearch } from 'common';
import { RadioOptionProps } from 'operations/commonTypeDefs';
import { useSession } from 'common';
import { SessionDataValue } from 'operations/models/SessionData';
// import { SalesPerson } from 'common';
import { FormInstance } from '@shipmnts/pixel-hub';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const getConsolidationOptions = (sessionData: SessionDataValue) => {
  if (sessionData.company_account)
    return [
      ...CONSOLIDATION_TYPES,
      {
        key: SELF_CONSOLIDATION_KEY,
        name: `${sessionData.company_account.display_name}'s Consolidation`,
      },
    ];
  return CONSOLIDATION_TYPES;
};

const ShipmentBasicDetails = React.memo(function ShipmentBasicDetails(props: {
  shipmentType: ShipmentType;
  disableLoadType?: boolean;
  hideConsolType?: boolean;
  form?: FormInstance;
  hideServiceType?: boolean;
}): JSX.Element {
  const { shipmentType, hideConsolType, disableLoadType, form, hideServiceType = false } = props;
  const sessionData = useSession();
  const consolidation_types = useMemo(() => getConsolidationOptions(sessionData), [sessionData]);
  const businessType = Form.useWatch('business_type', form);

  return (
    <Row gutter={ROW_GUTTER}>
      <Col span={8}>
        <Form.Item required rules={[{ required: true }]} name="job_date" label="Job Date">
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      {shipmentType === SHIPMENT_TYPE_CONSOL && Boolean(hideConsolType) && (
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Consol Load Type"
            name="load_type"
          >
            <Radio.Group disabled={disableLoadType}>
              {LOAD_TYPES.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
      {shipmentType === SHIPMENT_TYPE_CONSOL && !Boolean(hideConsolType) && (
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Consolidation Type"
            name="consol_type"
          >
            <Radio.Group>
              {consolidation_types.map((option: RadioOptionProps, index: number) => (
                <Radio key={index} value={option.key}>
                  {option.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      )}
      {shipmentType !== SHIPMENT_TYPE_HOUSE && !hideServiceType && (
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            label="Shipping Line Service Type"
            name={['shipping_line_service_type']}
          >
            <Select>
              {SHIPPING_LINE_SERVICE_TYPES.map((option: RadioOptionProps, index: number) => (
                <Select.Option key={index} value={option.key}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.consol_type !== currentValues.consol_type
        }
      >
        {({ getFieldValue }) => {
          const consol_type = getFieldValue('consol_type');
          if (consol_type === SELF_CONSOLIDATION_KEY) return null;
          return (
            <Col span={8}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label="Business Booked Through"
                name="business_type"
              >
                <Radio.Group>
                  {BOOKING_THROUGH_TYPES.map((option: RadioOptionProps, index: number) => (
                    <Radio key={index} value={option.key}>
                      {option.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          );
        }}
      </Form.Item>
      {shipmentType === SHIPMENT_TYPE_CONSOL && (
        <>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.business_type !== currentValues.business_type
            }
          >
            {({ getFieldValue }) => {
              if (businessType !== BUSINESS_TYPE_SUBAGENT) return null;
              return (
                <Col span={8}>
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    name={['party', 'subagent']}
                    label="Subagent"
                  >
                    <AddressCompanySearch />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.consol_type !== currentValues.consol_type
            }
          >
            {({ getFieldValue }) => {
              const consolType = getFieldValue('consol_type');
              if (consolType === SELF_CONSOLIDATION_KEY) return null;
              return (
                <>
                  <Col span={8}>
                    <Form.Item
                      name="customer"
                      required
                      rules={[{ required: true }]}
                      label="Billing Party"
                    >
                      <AddressCompanySearch
                        customerSearchProps={{
                          trigger: 'create_shipment',
                        }}
                        addressSearchProps={{
                          searchProps: { entity_type: 'billing' },
                        }}
                        onChange={(value: any) => {
                          if (
                            value?.party_company?.sales_partner?.country_of_incorporation &&
                            sessionData?.company_account?.country_of_incorporation
                          ) {
                            if (
                              value.party_company.sales_partner.country_of_incorporation ===
                              sessionData.company_account.country_of_incorporation
                            ) {
                              form?.setFieldValue('business_type', 'subagent');
                              form?.setFieldValue(['party', 'subagent'], {
                                party_company: value?.sales_partner,
                              });
                            } else {
                              form?.setFieldValue('business_type', 'agent_nomination');
                              form?.setFieldValue(['master', 'party', 'destination_agent'], {
                                party_company: value?.sales_partner,
                              });
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sales_agent" label="Sales Person">
                      <GlobalSearch doc_type="Network::SalesPerson" />
                    </Form.Item>
                  </Col>
                </>
              );
            }}
          </Form.Item>
        </>
      )}
      {shipmentType !== SHIPMENT_TYPE_CONSOL && (
        <Col span={8}>
          <Form.Item
            name="involved_branch_id"
            required
            rules={[{ required: true }]}
            label="Booked By Branch"
          >
            <Select
              showSearch
              placeholder="Booked By Branch"
              disabled={shipmentType === SHIPMENT_TYPE_HOUSE}
            >
              {sessionData?.branch_accounts?.map((branch: { id: string; name: string }) => (
                <Select.Option key={branch.id} value={branch.id}>
                  {branch.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col span={8}>
        <Form.Item name="other_job_number" label="Other Job #">
          <Input placeholder="Other Job #" />
        </Form.Item>
      </Col>
    </Row>
  );
});

export default ShipmentBasicDetails;
